import React from "react";
import { SlideOut } from "./SlideOut";
import { Alert } from "components/Alert";
import { Button } from "components/Button";

import * as styles from "./Intercepted.module.scss";

const Intercepted = () => {
  return (
    <SlideOut>
      <Alert className={styles.alert} variant={`info`}>
        Your bet is being reviewed by a Trader
      </Alert>
      <div className={styles.footer}>
        <Button loading={true} variant={`info`} className={styles.button}>
          In Review
        </Button>
      </div>
    </SlideOut>
  );
};

export { Intercepted };
