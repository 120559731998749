import { setPanel, setModal, setMenu } from "utilities/UI/uiSlice";
import { message } from "utilities/message";
import {
  GROUP_USER,
  addToList,
  removeByGroup,
  requestStarted,
  requestFinished,
} from "store/loadingList";
import { updateBetslip } from "sections/Betting/Betslip/betslipSlice";

import { AUTH_LOGGED_OUT_SUCCESS } from "./Constants";
import { clearSelections } from "sections/Betting/Betslip/betslipSlice";
import {
  clearPicks,
  updatePickslip,
} from "sections/Pickems/PickSlip/pickslipSlice";
import { logout } from "utilities/Auth/authSlice";

// --
// Thunks

export const onLogoutSuccess = () => ({
  type: AUTH_LOGGED_OUT_SUCCESS,
});

export const onLogout = (redirect, cb) => async (dispatch) => {
  dispatch(addToList(GROUP_USER, "logout"));
  await dispatch(
    logout(() => {
      dispatch(clearSelections());
      dispatch(
        updatePickslip({
          contest: null,
          confirmContestId: null,
          pickAcceptedId: null,
        }),
      );
      dispatch(clearPicks());
      dispatch(setPanel("betslip"));
      dispatch(setMenu("none"));
      dispatch(updateBetslip({ pendingBetsCount: 0 }));
      dispatch(setModal(null));
      dispatch(removeByGroup(GROUP_USER));
      if (typeof cb === "function") {
        cb();
      }
      //to send event to segment
      dispatch(onLogoutSuccess());
    }),
  );
};

// --
// Actions

export const migrateProfile = () => async (_, getState) => {
  const { userId, token } = getState().auth;
  if (!userId || !token) {
    return;
  }

  await message("migrate-user-profile", {});
};
