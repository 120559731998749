import { useStaticQuery, graphql } from "gatsby";

export const BANNER_DISPLAY_SECONDS = "banner_display_seconds";
export const RESPONSIBLE_GAMBLING_MESSAGE = "responsible_gambling_message";

type ContentfulSettings = {
  allContentfulSettings: {
    nodes: Array<{
      key: string;
      value: string;
    }>;
  };
};

const useContentfulSettings = (): Record<string, string> => {
  const { allContentfulSettings }: ContentfulSettings = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          key
          value
        }
      }
    }
  `);

  return allContentfulSettings.nodes.reduce((settings, current) => {
    return {
      ...settings,
      [current.key]: current.value,
    };
  }, {});
};

export const useContentfulSetting = (key: string) => {
  const settings = useContentfulSettings();

  return settings[key];
};

export default useContentfulSettings;
