import moment from "moment";
import { collection, onSnapshot } from "firebase/firestore";
import { getFirestore } from "store/getFirebase";
import { dispatchAfter, dispatchNow } from "store";
import { messageTimedOut } from "utilities/UI/uiSlice";
import { logout, updateSession } from "utilities/Auth/authSlice";
import { fetchWithAppCheck as fetch } from "utilities/api/fetchWithAppCheck";

/**
 * Error types
 */
export const NO_CREDENTIALS_ERROR = "NO_CREDENTIALS_ERROR";
export const QUEUE_ERROR = "QUEUE_ERROR";
export const RESULT_ERROR = "RESULT_ERROR";

const MESSAGE_ID_MISSING = "MESSAGE_ID_MISSING";

const MESSAGE_TIMEOUT = 20; // seconds

interface IMessageOptions {
  authType: string;
  token: string;
  userId: string;
  db: any;
  email: string;
  password?: string;
  numResponses?: number;
}

/**
 * @name Message
 * @description Wraps a message in Auth headers, and then sends it to the api
 * returning a promise so follow on actions can be set up. Will only work if
 * the user is authed, otherwise it will reject. Returned object will.
 */
export class Message implements IMessageOptions {
  error: Error;
  messageModule: string;
  payload: Record<string, any>;
  watches = {};
  db: any;
  token: string;
  userId: string;
  email: string;
  password: string;
  sent: Promise<Record<string, any>>;
  authType: string;

  constructor(
    messageModule: string,
    payload: Record<string, any>,
    options: Partial<IMessageOptions> = {},
  ) {
    this.error = null;
    this.messageModule = messageModule;
    this.payload = payload;
    this.watches = {};
    const { token, userId, email, password } = options;
    this.token = token;
    this.userId = userId;
    this.email = email;
    this.password = password;
    const authType = (this.authType = options.authType || "token");

    this.sent = new Promise((resolve, reject) => {
      // Checked logged in state
      if (!token && !(email && password)) {
        this.error = new Error("Please log in before attempting this action.");
        console.error(
          `User '${this.userId}' attempted action '${this.messageModule}' without credentials`,
          this.error,
        );
        //The fact they are able to attempt this action when not logged in indicates
        // they are probably in an inconsistent auth state.
        // So log them out so they can start fresh
        dispatchNow(
          logout(() => {
            reject({
              type: NO_CREDENTIALS_ERROR,
              created: moment().toDate(),
              id: MESSAGE_ID_MISSING,
              body: {
                message: this.error.message,
              },
            });
          }),
        );
      } else {
        // Everything is alright, send message
        fetch(process.env.GATSBY_API_URI + "/messages/" + messageModule, {
          method: "POST",
          mode: "cors",
          headers: this.getHeaders(authType),
          body: JSON.stringify(payload),
        })
          .then((response) => {
            if (response.status === 202) {
              response.json().then((body) =>
                resolve({
                  created: moment().toDate(),
                  id: body.messageId,
                  watch: this.watch(body.messageId, options.numResponses),
                }),
              );
            } else if (response.status === 401) {
              response.json().then((body) => {
                dispatchNow(updateSession({ isGuest: false }));
                reject({
                  type: QUEUE_ERROR,
                  created: moment().toDate(),
                  id: body.messageId || MESSAGE_ID_MISSING,
                  body,
                });
              });
            } else {
              response.json().then((body) => {
                reject({
                  type: QUEUE_ERROR,
                  created: moment().toDate(),
                  id: body.messageId || MESSAGE_ID_MISSING,
                  body,
                });
              });
            }
          })
          .catch((error) => {
            console.error(
              `Infrastructure error when queuing message: ${error.message}`,
              error,
            );
            error.message =
              "We were unable to process your transaction. Please check your network connection and try again, or contact Support, if you continue to experience the issue";
            reject(error);
          });
      }
    });
  }

  /**
   * @name watch
   * @description Sets up a listener and then returns a promise that resolves
   * when one or more responses have been returned for a given message.
   * @param messageId - The message to watch for messages on.
   * @param messageNumber - The number of responses to wait for before returning. This
   * number defaults to 1. When it is > 1 it will return an array of responses.
   */
  watch(messageId: string, messageNumber = 1, timeout = MESSAGE_TIMEOUT) {
    let promiseFulfilled = false;

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!promiseFulfilled) {
          reject(new Error("Timed out waiting for response"));
          dispatchAfter(messageTimedOut());
        }
      }, timeout * 1000);

      const messagesRef = collection(
        getFirestore(),
        "users",
        this.userId,
        "messageResponses",
        messageId,
        "responses",
      );

      const unsub = onSnapshot(messagesRef, (snapshot) => {
        if (snapshot.docs.length < messageNumber) {
          return;
        }

        const messages = snapshot.docs.map((document) => document.data());

        if (messageNumber === 1 && snapshot.docs.length >= messageNumber) {
          promiseFulfilled = true;

          if (messages[0].status === "ERROR") {
            reject({
              type: RESULT_ERROR,
              created: messages[0].createdAt,
              body: {
                errors: messages[0].errors || [],
                code: messages[0].code,
                displayText: messages[0].displayText,
              },
            });
          }

          resolve(messages[0]);
        } else if (snapshot.docs.length >= messageNumber) {
          promiseFulfilled = true;
          resolve(messages);
        }

        unsub();
      });
    });
  }

  getHeaders(authType = "token") {
    let authValue = "Bearer " + this.token;
    if (authType === "basic") {
      const encoded = btoa(`${this.email}:${this.password}`);
      authValue += " Basic " + encoded;
    }
    return {
      Authorization: authValue,
    };
  }
}

export default Message;
