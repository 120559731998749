import React from "react";
import PropTypes from "prop-types";

import * as styles from "./PlayerIcon.module.scss";

import FranchiseIcon from "../FranchiseIcon";
import Flag from "../Flag";
import Icon from "../Icon";

const PlayerIcon = ({
  player,
  size = "40px",
  franchise,
  flag = true,
  inactive,
  shotCaller = false,
}) => {
  let icon = <Icon className="no-avatar" type="player" />;
  if (player && player.avatarUri && player.avatarUri.trim() !== "") {
    icon = (
      <img alt="Player Avatar" className="avatar" src={player.avatarUri} />
    );
  }

  let flagBlock = "";
  if (player && player.nationality && flag) {
    flagBlock = (
      <div className={styles.flag}>
        <Flag country={player.nationality} width="20px" height="20px" />
      </div>
    );
  }

  let franchiseBlock = "";
  if (franchise && franchise.iconUri) {
    franchiseBlock = (
      <div className={styles.franchise}>
        <FranchiseIcon franchise={franchise} size="20px" />
      </div>
    );
  }

  let warning = inactive ? (
    <div className={styles.warning}>
      <Icon type="warning" size={size} />
    </div>
  ) : (
    ""
  );

  let crown = shotCaller ? (
    <div className={styles.crown}>
      <Icon type="crown" size="8px" />
    </div>
  ) : (
    ""
  );

  if (player && player.id) {
    return (
      <div className={styles.container}>
        <div
          className={styles.icon}
          style={{ width: size, height: size }}
          title={player.name}
        >
          {icon}
          {warning}
        </div>

        {crown}
        {flagBlock}
        {franchiseBlock}
      </div>
    );
  } else {
    icon = (
      <img
        alt="Empty Player Avatar"
        className="no-player"
        src={
          `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAQAAAAHUWYVA` +
          `AABJklEQVR42u3RMQEAAAzCsOHf9ExwcKQSmpymigVABASIgAARECACAkRABASIgAAREC` +
          `ACAkRABASIgAARECACAkRABASIgAARECACAkRABASIgAARECACAkRABASIgAARECACAkR` +
          `ABASIgAARECACAkRABASIgAARECACAkRABASIgAARECACAkRABASIgAARECACAkRAgAiI` +
          `gAARECACAkRAgAiIgAARECACAkRAgAiIgAARECACAkRAgAiIgAARECACAkRAgAiIgAARE` +
          `CACAkRAgAiIgAARECACAkRAgAiIgAARECACAkRAgAiIgAARECACAkRAgAiIgAARECACAk` +
          `RAgAgIEAERECACAkRAgAgIEAERECACAkRAgAgIEAERECACAkTNHj1QAMnlJD2WAAAAAEl` +
          `FTkSuQmCC`
        }
      />
    );

    return (
      <div className={styles.container}>
        <div className={styles.icon} style={{ width: size, height: size }}>
          {icon}
        </div>
      </div>
    );
  }
};

PlayerIcon.propTypes = {
  player: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  franchise: PropTypes.object,
  flag: PropTypes.bool,
  inactive: PropTypes.bool,
  shotcaller: PropTypes.bool,
};

export default PlayerIcon;
