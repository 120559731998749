// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var centered = "Page-module--centered--35261";
export var emphasis = "Page-module--emphasis--56534";
export var full = "Page-module--full--6054a";
export var green = "#57d33b";
export var greenLink = "Page-module--green-link--71411";
export var grey = "var(--tone-mid)";
export var hideOnMobile = "Page-module--hideOnMobile--b0b87";
export var noSpacing = "Page-module--noSpacing--d8a1d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var page = "Page-module--page--24e03";
export var pageContent = "Page-module--pageContent--2f52b";
export var red = "#ff2741";
export var subTitle = "Page-module--subTitle--e42c6";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var withSpacing = "Page-module--withSpacing--4de11";
export var yellow = "#ffb100";