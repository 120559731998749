import { useCallback, useEffect, useState } from "react";

import { doMWDeposit } from "sections/Wallet/walletSlice";

import { isAppleWebView } from "utilities/display";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "utilities/AppWebMessagingBridge";
import { useSelector, useDispatch } from "hooks";
import { message } from "utilities/message";

export const useIsApplePayAvailable = (): boolean => {
  const [isAvailable, setIsAvailable] = useState(false);
  const appApplePayAvailable = useSelector(
    (state) => state.sections.wallet.appApplePayAvailable,
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      setIsAvailable(false);
      return;
    }

    if (process.env.GATSBY_ENABLE_APPLE_PAY === "false") {
      setIsAvailable(false);
      return;
    }

    if (isAppleWebView()) {
      setIsAvailable(appApplePayAvailable);
      return;
    }

    setIsAvailable(
      !!(window.ApplePaySession && ApplePaySession.canMakePayments()),
    );
  }, [appApplePayAvailable]);

  return isAvailable;
};

export const useApplePay = (): ((
  amount: number,
  onSuccess: () => void,
) => void) => {
  const [userId, token] = useSelector((state: any) => [
    state.auth?.userId,
    state.auth?.token,
  ]);
  const dispatch = useDispatch();
  const isAppleApp = isAppleWebView();

  return useCallback(
    (amount: number, onSuccess) => {
      if (isAppleApp) {
        // use native payments api if we are within iOS app
        sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_START_APPLE_PAY, {
          amount,
        });
        return;
      }

      const session = new ApplePaySession(1, {
        countryCode: "AU",
        currencyCode: "AUD",
        supportedNetworks: ["visa", "masterCard"],
        //supportsDebit is to disable credit cards
        //see: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaymerchantcapability
        merchantCapabilities: ["supports3DS", "supportsDebit"],
        total: {
          label: "Picklebet",
          type: "final",
          amount: `${amount}`,
        },
      });

      session.onvalidatemerchant = async (
        event: ApplePayJS.ApplePayValidateMerchantEvent,
      ) => {
        const [response] = await message("generate-apple-pay-session", {
          validationUrl: event.validationURL,
        });

        try {
          const merchantSession = JSON.parse(response.data?.merchantSession);

          // complete session verification
          session.completeMerchantValidation(merchantSession);
        } catch (error) {
          // this should technically not happen, but if it does, we should log it
          console.warn("Failed to initialise Apple Pay sesison.", error);
        }
      };

      session.onpaymentauthorized = async (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
      ) => {
        const response = await dispatch(
          doMWDeposit(
            null,
            null,
            JSON.stringify(event.payment.token.paymentData),
            amount,
            "",
            "Apple",
          ) as any,
        );

        const isSuccess = response === "success";

        const result: ApplePayJS.ApplePayPaymentAuthorizationResult = isSuccess
          ? { status: ApplePaySession.STATUS_SUCCESS }
          : { status: ApplePaySession.STATUS_FAILURE };

        if (isSuccess) {
          await onSuccess();
        }

        session.completePayment(result);
      };

      session.begin();
    },
    [userId, token],
  );
};
