import React, { useState } from "react";
import { Input } from "components/Input";
import { ReactComponent as ShowIcon } from "assets/icons/view-1.svg";
import { ReactComponent as HideIcon } from "assets/icons/view-off.svg";
import type { InputProps } from "components/Input";

import * as styles from "./PasswordInput.module.scss";

type PasswordInputProps = InputProps;

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props: PasswordInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const onClick = () => {
      setShowPassword(!showPassword);
    };
    const IconComponent = showPassword ? HideIcon : ShowIcon;
    return (
      <Input
        ref={ref}
        icon={<IconComponent className={styles.icon} onClick={onClick} />}
        type={showPassword ? "text" : "password"}
        {...props}
      />
    );
  },
);

PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
