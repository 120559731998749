import { doc, type FirestoreError, onSnapshot } from "firebase/firestore";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import type { User } from "./types";
import { useEffect, useState } from "react";

const useUser = (): [User | null, boolean, FirestoreError | null] => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  const userId = useSelector((state) => state.auth.userId);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setUser(null);
      return;
    }

    const userRef = doc(getFirestore(), "users", userId);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          setUser(doc.data() as User);
          setError(null);
        } else {
          setUser(null);
          setError(new Error("User not found") as FirestoreError);
        }
        setLoading(false);
      },
      (error) => {
        setError(error);
        setLoading(false);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [userId]);

  return [user, loading, error];
};

export default useUser;
