import { PageContent } from "components/Page";
import { Toggle } from "components/Toggle";
import React from "react";
import type { RaceOutcomeType } from "sections/Betting/Race/hooks/RacingTypes";
import { Competitor } from "../Competitor";
import { CompetitorsExoticOutcomes } from "./CompetitorsExoticOutcomes";
import { ExoticBetBuilder } from "./ExoticBetBuilder";
import { ExoticRunnersInPosition, ExoticSelections } from "./ExoticSelections";
import { ExoticCompetitorSkeleton } from "./ExoticSkeleton";
import type { ExoticComponentProps } from "./Exotics.types";
import {
  byNumericRunnerPosition,
  calculateQuinellaCombinations,
  filterByOutcomeType,
  getSelectedRunners,
  pickExoticMarkets,
} from "./Exotics.utils";
import { RunnerPositionToggleGroup } from "./RunnerPositionToggle";
import { useExoticOutcomeSelections } from "./useExoticOutcomeSelections";
import { useExoticsMarketsAndOutcomes } from "./useExoticsMarketsAndOutcomes";

import * as styles from "./Exotics.module.scss";

const getQuinellaOutcomes = pickExoticMarkets("QUINELLA");

export const Quinella = ({
  competitors,
  validCompetitorCount,
}: ExoticComponentProps) => {
  const {
    markets,
    getCompetitorOdds,
    outcomes,
    groupedOutcomes,
    race,
    loading,
  } = useExoticsMarketsAndOutcomes("QUINELLA");

  const {
    selectedOutcomes,
    setSelectedOutcomes,
    isBoxedActive,
    onBoxedChange,
  } = useExoticOutcomeSelections(groupedOutcomes, competitors, true);

  const getRunnerNumberFromOutcome = (outcomeType: RaceOutcomeType["type"]) =>
    getQuinellaOutcomes(markets, filterByOutcomeType(outcomeType)).flatMap(
      getSelectedRunners(selectedOutcomes),
    );

  const boxedQuinellaRunners = getRunnerNumberFromOutcome("RUNNER_TOP_2");

  return (
    <PageContent noSpacing>
      <div className={styles.exoticControls}>
        <Toggle label="Box" noSpacing checked disabled />

        <RunnerPositionToggleGroup
          className={styles.positionControl}
          isBoxed
          isBoxedActive={isBoxedActive}
          onBoxedChange={onBoxedChange}
        />
      </div>

      <ExoticCompetitorSkeleton isLoading={loading}>
        {competitors.map((competitor) => {
          return (
            <Competitor
              key={competitor.id}
              competitor={competitor}
              validCompetitorCount={validCompetitorCount}
              competitorOutcomes={() => (
                <CompetitorsExoticOutcomes
                  isBoxed
                  outcomes={outcomes
                    .filter(
                      (o) =>
                        o.competitorId === competitor.id &&
                        // since we don't support unboxed we only care about the
                        // top 2 markets within this context
                        o.type === "RUNNER_TOP_2",
                    )
                    .sort(byNumericRunnerPosition)}
                  currentSelections={selectedOutcomes}
                  setCurrentSelections={setSelectedOutcomes}
                  isScratched={competitor.scratched}
                  winMarketOdds={getCompetitorOdds(competitor.id)}
                />
              )}
            />
          );
        })}
      </ExoticCompetitorSkeleton>

      <ExoticBetBuilder
        event={race}
        markets={markets}
        competitors={competitors}
        selections={selectedOutcomes}
        setSelections={setSelectedOutcomes}
        combinations={calculateQuinellaCombinations([
          boxedQuinellaRunners,
          boxedQuinellaRunners,
        ])}
        errorMessage="Add a selection on 2+ runners"
      >
        <ExoticSelections>
          <ExoticRunnersInPosition
            position={1}
            outcomes={boxedQuinellaRunners}
          />
          <ExoticRunnersInPosition
            position={2}
            outcomes={boxedQuinellaRunners}
          />
        </ExoticSelections>
      </ExoticBetBuilder>
    </PageContent>
  );
};
