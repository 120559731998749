import { useWalletActions } from "hooks/useWallet";
import { useSelector } from "hooks/useSelector";
import { useContext, useEffect, useMemo } from "react";
import { FirebaseContext } from "context/Firebase";
import { isAndroidWebView } from "utilities/display";
import { useFiatCurrency } from "hooks/firestore";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "utilities/AppWebMessagingBridge";

type GooglePayHook = {
  isFinal: boolean;
  amount: number;
  loading: boolean;
  onSuccess?: (result: "success" | "failure") => void;
};

const useGooglePay = ({
  isFinal,
  loading,
  amount,
  onSuccess,
}: GooglePayHook) => {
  const currency = useFiatCurrency();

  const { doMWDeposit, setGoogleDepositStatus } = useWalletActions();

  const googleDepositStatus = useSelector(
    (state) => state.sections?.wallet?.googleDepositStatus,
  );

  const hasDeposited = googleDepositStatus === "success";

  // We reset the successful state when the user visits the page
  useEffect(() => {
    if (hasDeposited) {
      setGoogleDepositStatus("ready");
    }
  }, []);

  const { profile } = useContext(FirebaseContext);

  const country = profile?.country;

  const paymentRequest = useMemo(
    () =>
      ({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              // We add this logic to allow the loading of the gPay button in the webview
              // as the webview does not support the CRYPTOGRAM_3DS auth method
              // We however can assume that if the user has managed to get to this page, there the App has already sent the message to the webview
              // to enable the gPay button, so we can safely assume that the webview supports the CRYPTOGRAM_3DS auth method
              // see AppToWebEvents.tsx (Line 85)
              allowedAuthMethods: isAndroidWebView()
                ? ["PAN_ONLY", "CRYPTOGRAM_3DS"]
                : ["CRYPTOGRAM_3DS"],
              //Limit to mastercard only as there is no liability shift for Visa over GPay event with CRYPTOGRAM_3DS
              allowedCardNetworks: ["MASTERCARD"],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "merchantwarrior",
                gatewayMerchantId: process.env.GATSBY_MERCHANTWARRIOR_MERCHANT,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: process.env.GATSBY_GOOGLE_PAY_MERCHANT_ID,
          merchantName: "Picklebet",
        },
        transactionInfo: {
          totalPriceStatus: isFinal ? "FINAL" : "ESTIMATED",
          totalPriceLabel: "Total",
          totalPrice: `${amount}` || "0",
          currencyCode: currency,
          countryCode: country,
        },
      }) as google.payments.api.PaymentDataRequest,
    [amount],
  );

  const deposit = async (paymentToken?: string) => {
    if (loading) return;

    if (isAndroidWebView()) {
      sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_GOOGLE_PAY, {
        amount,
      });
    } else {
      await doMWDeposit(
        null,
        null,
        paymentToken,
        amount,
        "",
        "Google",
        (outcome: string) => {
          if (outcome === "success") {
            setGoogleDepositStatus("success");
            onSuccess && onSuccess("success");
          } else {
            onSuccess && onSuccess("failure");
          }
        },
      );
    }
  };

  return {
    hasDeposited,
    deposit,
    paymentRequest,
  };
};

export { useGooglePay };
