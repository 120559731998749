import React, { forwardRef } from "react";
import cx from "classnames";
import Icon from "../Icon";
import * as styles from "./HelpCenter.module.scss";
import { useFormik, useProfile, useSelector } from "hooks";

import * as Yup from "yup";
import { createATicket } from "utilities/api";
import { FormikProvider } from "formik";
import { StdForm, FormFieldText, FormButtonSubmit } from "../Forms";
import * as forms from "library/styles/form.module.scss";
import Input, { type IInputProps } from "../Input";
import { toast } from "hooks/ui/useToast";
import {
  WEB_TO_APP_MESSAGE_TYPES,
  sendAppMessage,
} from "utilities/AppWebMessagingBridge";
import { isWebView } from "utilities/display";

const CustomTextarea = forwardRef(
  (props: IInputProps | any, ref: React.Ref<HTMLTextAreaElement>) => {
    return <textarea ref={ref} {...props} />;
  },
);

type CreateATicketProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const CreateATicket = ({ visible, setVisible }: CreateATicketProps) => {
  const { token, loggedIn } = useSelector((state) => state.auth);
  const [profile] = useProfile();

  const openLinkExternallyOfTheApps = (href: string) => {
    if (isWebView()) {
      sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_OPEN_EXTERNALLY, {
        url: href,
      });
    } else {
      window.open(href, "_blank");
    }
  };

  const [form, context] = useFormik({
    validationSchema: Yup.object().shape({
      description: Yup.string().required("Description is required."),
      subject: Yup.string().required("Subject is required."),
      email: loggedIn
        ? null
        : Yup.string()
            .email("Email must be valid")
            .required("Email is required."),
    }),
    initialValues: {
      description: "",
      subject: "",
      email: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await createATicket({ token, payload: { ...values } });
      toast({
        description: "Your request has been submitted.",
        variant: "success",
      });
      setVisible(false);
      setSubmitting(false);
    },
    validateOnChange: true,
  });

  return (
    <FormikProvider value={context}>
      <StdForm form={form}>
        <div className={cx(styles.helpCenter, { [styles.visible]: visible })}>
          {form.isSubmitting && <span className={styles.loader}></span>}
          <div className={styles.header}>
            <div className={styles.title} onClick={() => setVisible(false)}>
              <div className={styles.titleText}>Picklebet Help Center</div>
              <div className={styles.icon}>
                <Icon type="close" />
              </div>
            </div>
          </div>
          <div className={styles.formContent}>
            <div className={forms.container}>
              <FormFieldText form={form} name="subject" label="Subject" />
              {!loggedIn && (
                <FormFieldText form={form} name="email" label="Email" />
              )}
              <Input
                type="text"
                form={form}
                name="description"
                label="Description"
                className={styles.textArea}
                onChange={(e) => {
                  form.setFieldValue("description", e.target.value);
                  form.setFieldTouched("description", true);
                }}
                value={form.values.description}
                Component={CustomTextarea}
                issue={
                  context?.touched.description && context?.errors.description
                    ? (context?.errors.description as any)
                    : null
                }
              />
              <div className={styles.uploadDocuments}>
                <div>
                  Need to{" "}
                  <div
                    className={"green_link"}
                    onClick={() =>
                      openLinkExternallyOfTheApps(
                        `https://${
                          process.env.GATSBY_ZENDESK
                        }/hc/en-us/requests/new?subject=${
                          form?.values?.subject
                        }&description=${
                          form?.values?.description
                        }&anonymous_requester_email=${
                          profile?.email ?? form?.values?.email
                        }`,
                      )
                    }
                  >
                    {"  "}Upload Documents?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <FormButtonSubmit form={form} />
          </div>
        </div>
      </StdForm>
    </FormikProvider>
  );
};
