import React from "react";
import type { IFormFieldProps, IFormFieldState } from "./FormFieldTypes";

export class FormFieldBase<
  T extends IFormFieldProps = IFormFieldProps,
> extends React.PureComponent<T> {
  private touchTimeout: any;

  render() {
    return null;
  }

  extractState(fieldName: string): IFormFieldState {
    const { form } = this.props;
    const value = form && this.toDisplayValue(form.getValue(fieldName) || "");
    const touched = form && form.getTouched(fieldName);
    const errors = form && (form.getError(fieldName) as string);
    const error =
      (errors as any) instanceof Array ? errors[0] : String(errors || "");
    return { value, touched, error };
  }

  getFieldState() {
    const fieldName = this.getFieldName();
    return this.extractState(fieldName);
  }

  getIssue() {
    const { touched, error } = this.getFieldState();
    const { forceError } = this.props;
    if (forceError) {
      return " ";
    }
    if (!touched) {
      return null;
    }
    return error || null;
  }

  getFieldName() {
    return this.props.name;
  }

  setValue(rawValue: any) {
    const { form, onChange } = this.props;
    const value = this.toOutputValue(rawValue);
    const fieldName = this.getFieldName();
    form.setFieldValue(fieldName, value);
    if (typeof onChange === "function") {
      onChange({ event, value });
    }
  }

  getValue() {
    return this.props.form.getValue(this.getFieldName());
  }

  protected toDisplayValue(value: string): string {
    return value;
  }

  protected toOutputValue(value: string): string {
    return value;
  }

  protected handleChange(event: any) {
    event.preventDefault();
    this.setValue(event.target.rawValue || event.target.value);
    this.touchTimeout = setTimeout(() => {
      this.setTouched();
    }, 0);
    // to handle legacy errors. We need to ensure we clear any errors manually on value change.
    this.props.form.setErrors([]);
  }

  protected handleFocus(event: any) {
    if (typeof this.props.onFocus === "function") {
      this.props.onFocus(event);
    }
  }

  protected handleBlur(event: any) {
    const { form, onBlur } = this.props;
    clearTimeout(this.touchTimeout);
    form.handleBlur(event);
    if (typeof onBlur === "function") {
      onBlur(event);
    }
  }

  protected setTouched() {
    const { form } = this.props;
    const fieldName = this.getFieldName();
    form.setFieldTouched(fieldName, true);
  }
}
