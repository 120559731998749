import { usePermissions } from "hooks";
import useRoute from "hooks/useRoute";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { PermissionReason } from "utilities/Auth/authSlice";

export type TabType =
  | "top4"
  | "win-place"
  | "same-race-multi"
  | "results"
  | "deductions"
  | "my-bets"
  | "exotics";

export type RaceRouteParams = {
  regionCode: string;
  meetingId: string;
  raceId: string;
  tab: TabType;
};

export const useRaceRoute = () => {
  const permissions = usePermissions();

  const [defaultRaceTab, setDefaultRaceTab] = useLocalStorage<TabType>(
    "defaultRaceTab",
    permissions.submitTop4Bet === PermissionReason.GRANTED
      ? "top4"
      : "win-place",
  );

  const [setRouteValue, route] = useRoute<RaceRouteParams>(
    "/racing/betting/race/:meetingId/:raceId(/:tab)(/)(?)(enable-experimental-features=:enableExperimentalFeatures)",
    { tab: defaultRaceTab },
  );

  useEffect(() => {
    if (["top4", "win-place", "same-race-multi"].includes(route.tab))
      setDefaultRaceTab(route.tab);
  }, [route.tab]);

  useEffect(() => {
    if (
      (permissions.submitTop4Bet !== PermissionReason.GRANTED &&
        route.tab === "top4") ||
      (permissions.submitSRMBet !== PermissionReason.GRANTED &&
        route.tab === "same-race-multi")
    ) {
      setRouteValue({ tab: "win-place" });
    }
  }, [permissions, route.tab]);

  return {
    setRouteValue,
    route,
  };
};
