import React from "react";
import ReactPlayer, { type ReactPlayerProps } from "react-player";

const defaultProps: ReactPlayerProps = {
  controls: true,
  volume: 0.4,
};

export const MediaPlayer = (props: ReactPlayerProps) => (
  <ReactPlayer {...defaultProps} {...props} />
);
export default MediaPlayer;
