import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import appConfig from "appConfig";

import * as styles from "./Flag.module.scss";
import { isAppleWebView } from "utilities/display";

const Flag = ({ country, height, width, className }) => (
  <div
    className={cx({ [styles.flag]: true, [className]: !!className })}
    style={{
      height: height,
      width: width,
    }}
  >
    <img
      alt={`${country} Flag`}
      src={`${
        isAppleWebView() ? appConfig.DOMAIN : ""
      }/flags/${country.toLowerCase()}.png`}
    />
  </div>
);

Flag.propTypes = {
  country: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Flag;
