import React from "react";
import * as df from "date-fns";

import type { IFormFieldProps, IFormFieldState } from "./FormFieldTypes";
import { FormFieldBase } from "./FormFieldBase";
import { FormFieldInteger } from "./FormFieldInteger";
import { FormFieldSelect } from "./FormFieldSelect";
import * as styles from "./FormFieldDate.module.scss";

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DATE_INVALID = new Date(1900, 0, 1);

interface IDateParts {
  day: number;
  month: number;
  year: number;
}

// fill month options
const MONTH_OPTIONS = [];
for (let value = 0; value < 12; value++) {
  const date = new Date(2000, value, 1);
  const label = df.format(date, "MMMM");
  MONTH_OPTIONS.push({ value, label });
}

export interface IFormFieldDateProps extends IFormFieldProps {
  dateFormat?: string;
  hideDays?: boolean;
}

export class FormFieldDate extends FormFieldBase<IFormFieldDateProps> {
  blurTimer: any;

  parseDate(dateStr: string): Date {
    const date = df.parse(dateStr, this.getFormat(), new Date());
    return date;
  }

  getFormat() {
    return this.props.dateFormat || DEFAULT_DATE_FORMAT;
  }

  getFieldState(): IDateParts & IFormFieldState {
    const state = super.getFieldState();
    const { value, touched } = state;
    let { error } = state;
    const values = this.dateValues();
    const subfieldNames = this.getSubfieldNames();
    for (const subfieldName of Object.values(subfieldNames)) {
      const subfieldState = this.extractState(subfieldName);
      if (!error && subfieldState.error) {
        error = subfieldState.error;
      }
    }
    return { value, touched, error, ...values };
  }

  dateValues(): IDateParts {
    const form = this.props.form;
    const fieldNames = this.getSubfieldNames();
    const { value } = super.getFieldState();
    const date = this.parseDate(value);

    let dayValue = this.props.hideDays ? "01" : form.getValue(fieldNames.day);
    let monthValue = form.getValue(fieldNames.month);
    let yearValue = form.getValue(fieldNames.year);
    if (
      form.hasField(fieldNames.day) === false ||
      form.hasField(fieldNames.month) === false ||
      form.hasField(fieldNames.year) === false
    ) {
      dayValue = String(df.getDate(date) || "");
      monthValue = String(df.getMonth(date) || "");
      yearValue = String(df.getYear(date) || "");
      setTimeout(() => {
        form.setFieldValue(fieldNames.day, dayValue);
        form.setFieldValue(fieldNames.month, monthValue);
        form.setFieldValue(fieldNames.year, yearValue);
      }, 1);
    }
    const day = parseInt(dayValue, 10) || 0;
    const month = parseInt(monthValue, 10) || 0;
    const year = parseInt(yearValue, 10) || 0;
    return { day, month, year };
  }

  getSubfieldNames() {
    const fieldName = this.getFieldName();
    return {
      day: `${fieldName}_day`,
      month: `${fieldName}_month`,
      year: `${fieldName}_year`,
    };
  }

  handleFieldChange(updatedDateParts: Partial<IDateParts>) {
    const dateValues: IDateParts = {
      ...this.dateValues(),
      ...updatedDateParts,
    };
    const { day, month, year } = dateValues;
    let newValue = "";
    if (year > 1000) {
      const dateStr =
        (year + "").padStart(4, "0") +
        "-" +
        (month + 1 + "").padStart(2, "0") +
        "-" +
        (day + "").padStart(2, "0");
      this.setTouched();
      try {
        const date = df.parse(dateStr, "yyyy-MM-dd", DATE_INVALID);
        if (date) {
          newValue = df.format(date, this.getFormat());
        }
      } catch (e) {
        /**/
      }
    }

    this.setTouched();
    this.setValue(newValue);
  }

  handleFieldFocus(e: Event) {
    clearTimeout(this.blurTimer);
    let allTouched = true;
    const { form } = this.props;
    const fieldNames = Object.values(this.getSubfieldNames());
    for (const fieldName of fieldNames) {
      if (
        Boolean(form.getTouched(fieldName) && form.getValue(fieldName)) ===
        false
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        allTouched = false;
      }
    }
    this.handleFocus(e);
  }

  handleFieldBlur(e: Event) {
    // a small delay when switching fields so that blurs don't occur
    clearTimeout(this.blurTimer);
    this.blurTimer = setTimeout(() => {
      clearTimeout(this.blurTimer);
      this.handleBlur.bind(this)(e);
    }, 100);
  }

  render() {
    const { error, touched } = this.getFieldState();
    const props = this.props;
    const form = props.form;
    const fieldNames = this.getSubfieldNames();
    const hasError = touched && Boolean(error);
    const displayError = error || "Input a valid date value.";
    const handleFieldBlur = this.handleFieldBlur.bind(this);
    const handleFieldFocus = this.handleFieldFocus.bind(this);
    const handleFieldChange = this.handleFieldChange.bind(this);
    return (
      <>
        {props.label && <div className={styles.label}>{props.label}</div>}
        <div className={styles.dates}>
          {!props.hideDays && (
            <FormFieldInteger
              form={form}
              name={fieldNames.day}
              forceError={hasError}
              placeholder="Day"
              className={styles.dateDay}
              onChange={(change) =>
                handleFieldChange({ day: parseInt(change.value, 10) })
              }
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur}
              gutterTop={props.gutterTop === true}
              gutterLeft={props.gutterLeft === true}
              gutterBottom={props.gutterBottom}
              gutterRight={true}
              maxLength={2}
            />
          )}
          <FormFieldSelect
            form={form}
            name={fieldNames.month}
            forceError={hasError}
            placeholder="Month"
            className={styles.dateMonth}
            options={MONTH_OPTIONS}
            onChange={(change) =>
              handleFieldChange({ month: parseInt(change.value, 10) })
            }
            onFocus={handleFieldFocus}
            onBlur={handleFieldBlur}
            gutterTop={props.gutterTop === true}
            gutterLeft={false}
            gutterBottom={props.gutterBottom}
            gutterRight={true}
          />
          <FormFieldInteger
            form={form}
            name={fieldNames.year}
            forceError={hasError}
            placeholder="Year"
            className={styles.dateYear}
            maxLength={4}
            onChange={(change) =>
              handleFieldChange({ year: parseInt(change.value, 10) })
            }
            onFocus={handleFieldFocus}
            onBlur={handleFieldBlur}
            gutterTop={props.gutterTop === true}
            gutterLeft={false}
            gutterBottom={props.gutterBottom}
            gutterRight={props.gutterRight === true}
          />
        </div>
        {hasError && <div className={styles.issues}>{displayError}</div>}
      </>
    );
  }
}
