import * as React from "react";
import { useDispatch, useSelector } from "hooks";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { setModal } from "utilities/UI/uiSlice";
import { SignUpForm } from "./SignUpForm";

type SignUpModalProps = {
  onComplete?: () => void;
};

const SignUpModal = ({ onComplete }: SignUpModalProps) => {
  const isOpen = useSelector((state) => state.utilities.ui.modal === "signUp");
  const dispatch = useDispatch();

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={() => dispatch(setModal(null))}
      title={"Sign Up"}
    >
      <SignUpForm onComplete={onComplete} />
    </FullScreenModal>
  );
};

export { SignUpModal };
