export const parseRecord = (record) => {
  const match = record.match(/(\d+)\/(\d+)\/(\d+)/);

  if (!match) {
    return false;
  }

  return {
    wins: match[1],
    draws: match[2],
    losses: match[3],
  };
};

export const parseStat = (stat) => {
  const match = stat.match(/(.+)\s?\((.*)%\)/);

  if (!match) {
    return false;
  }

  return {
    stat: match[1],
    percentage: match[2],
  };
};
