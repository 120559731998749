import React from "react";
import { Link } from "components/Link";
import { MoreInfo } from "components/MoreInfo";
import { EventStatus, type StatusEnum } from "components/layout/EventStatus";
import { CountryAndState } from "sections/Betting/components";
import { RaceIcon } from "../RaceIcon";
import * as styles from "./RaceRow.module.scss";
import type { RaceEventType } from "sections/Betting/Race/hooks/RacingTypes";
import { cva } from "class-variance-authority";
import cx from "classnames";
import {
  getRaceUrl,
  toTitleCaseWithExclusions,
} from "utilities/racingUtilities";

export enum RowTemplate {
  NEXT_TO_JUMP = "nextToJump",
  DAY = "day",
  RACE_MEETING = "raceMeeting",
}

const rowVariants = cva(styles.raceRow, {
  variants: {
    variant: {
      open: styles.open,
      closed: styles.closed,
      cancelled: styles.cancelled,
      interim: styles.interim,
      protest: styles.protest,
      final: styles.final,
      done: styles.done,
      jumped: styles.jumped,
      abandoned: styles.abandoned,
    },
  },
  defaultVariants: {
    variant: "open",
  },
});

type RaceRowProps = RaceEventType & {
  template: RowTemplate;
};

const RaceRow = (race: RaceRowProps) => {
  const {
    template,
    meetingId,
    venue,
    name,
    results,
    distance,
    number,
    status,
    scheduledStartTime,
    promotionIsAvailable,
    state,
    country,
    sport,
  } = race;

  const resultsArray = Object.values(results || {});
  const hasResults = resultsArray.length > 0;

  const subTitle = hasResults ? (
    resultsArray.join("  |  ")
  ) : (
    <span>{distance}</span>
  );

  const sportIconProps =
    template === RowTemplate.RACE_MEETING ? { number } : { sport };

  return (
    <div
      className={cx(rowVariants({ variant: race.status.toLowerCase() as any }))}
    >
      <Link
        to={getRaceUrl(
          template === RowTemplate.DAY ? "meeting" : "race",
          meetingId,
          race?.id,
        )}
      >
        <div className={styles.clamp}>
          <EventStatus
            // TODO: convert to a common enum, currently used in multiple places and not a part of betslip refactor
            status={status as unknown as StatusEnum}
            scheduledStartTime={scheduledStartTime}
          />
          <MoreInfo direction="horizontal" />
        </div>
        <div className={styles.title}>
          <RaceIcon
            {...sportIconProps}
            promotionIsAvailable={promotionIsAvailable}
          />
          <div>
            <div className={styles.heading}>
              {template !== RowTemplate.RACE_MEETING && (
                <div className={styles.number}>{`R${number}`}</div>
              )}
              {template !== RowTemplate.RACE_MEETING
                ? venue
                : toTitleCaseWithExclusions(name)}
            </div>
            <div className={styles.subHeading}>
              {template === RowTemplate.RACE_MEETING ? (
                subTitle
              ) : (
                <CountryAndState stateCode={state} countryCode={country} />
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RaceRow;
