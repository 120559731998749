// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clamp = "Outcome-module--clamp--cac9e";
export var container = "Outcome-module--container--8499a";
export var emphasis = "Outcome-module--emphasis--a8043";
export var green = "#57d33b";
export var greenLink = "Outcome-module--green-link--3bd5f";
export var grey = "var(--tone-mid)";
export var hasResult = "Outcome-module--hasResult--6a980";
export var icon = "Outcome-module--icon--b2832";
export var isVoid = "Outcome-module--isVoid--72ceb";
export var isWon = "Outcome-module--isWon--5237b";
export var marketName = "Outcome-module--marketName--4e353";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcome = "Outcome-module--outcome--154e5";
export var outcomeAndMarket = "Outcome-module--outcomeAndMarket--f2e35";
export var outcomeName = "Outcome-module--outcomeName--742cc";
export var pickIcon = "Outcome-module--pickIcon--561c4";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";