import React from "react";
import type { IInstruction } from "types/ContentTypes";

import * as styles from "./HUDInstructions.module.scss";

export class HUDInstructions extends React.Component<
  {
    instructions: IInstruction[];
    className?: string;
  },
  {
    index: number;
  }
> {
  state = { index: 0 };
  timeout: any;
  mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  setIndex(index: number) {
    if (!this.mounted) {
      return;
    }
    clearTimeout(this.timeout);
    this.setState({ index });
    this.timeout = setTimeout(() => this.setIndex(index + 1), 3000);
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timeout);
  }

  render() {
    const { instructions = [] } = this.props;
    const displayed = this.state.index;
    const index = displayed % instructions.length;
    return (
      <ol className={styles.instructionList}>
        {instructions.map((x, i) =>
          x.hidden ? null : (
            <li
              key={i}
              className={[
                i === index ? "active" : "inactive",
                x.selected ? "selected" : "",
              ].join(" ")}
            >
              {<span className={styles.count}>{i + 1}</span>}
              {x.text}
              {/* x.image && (
              <div
                className={styles.emptyImage}
                style={{
                  backgroundImage: `url('${this.info[index].image + '?set=' + setsDisplayed}')`
                }}
              />
            )*/}
            </li>
          ),
        )}
      </ol>
    );
  }
}
