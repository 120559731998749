import { dispatchNow } from "store";
import { PRIMARY_MESSAGE_TYPES } from "./";

export const sendAppMessage = (type: string, message: any) => {
  dispatchNow(wrapAndSendAppMessage(type, message));
};

const wrapAndSendAppMessage = (type: string, payload: unknown) => ({
  type: PRIMARY_MESSAGE_TYPES.WEB_TO_APP_MESSAGE,
  payload: JSON.stringify({
    type: type,
    payload: payload,
  }),
});
