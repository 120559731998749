// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "Button-module--button--2b8d1";
export var caution = "Button-module--caution--2c926";
export var center = "Button-module--center--6ed30";
export var emphasis = "Button-module--emphasis--c43f3";
export var error = "Button-module--error--c6756";
export var expand = "Button-module--expand--043e8";
export var form = "Button-module--form--6c612";
export var green = "#57d33b";
export var greenLink = "Button-module--green-link--79927";
export var grey = "var(--tone-mid)";
export var gutterBottom = "Button-module--gutterBottom--edc11";
export var gutterLeft = "Button-module--gutterLeft--72044";
export var gutterRight = "Button-module--gutterRight--c4ba3";
export var gutterTop = "Button-module--gutterTop--ab1a3";
export var information = "Button-module--information--69b98";
export var medium = "Button-module--medium--f7c98";
export var notchGap = "32px";
export var ok = "Button-module--ok--78185";
export var orange = "#f8662b";
export var outline = "Button-module--outline--04078";
export var primary = "Button-module--primary--24537";
export var prompt = "Button-module--prompt--199b0";
export var pullLeft = "Button-module--pullLeft--23e32";
export var pullRight = "Button-module--pullRight--3cb11";
export var red = "#ff2741";
export var secondary = "Button-module--secondary--925b7";
export var small = "Button-module--small--c8575";
export var success = "Button-module--success--0a274";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";