import React from "react";
import cx from "classnames";

import * as styles from "./Box.module.scss";

type BoxProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  title?: React.ReactNode | string;
  subTitle?: React.ReactNode | string;
  clamp?: React.ReactNode;
  className?: string;
  clampClassName?: string;
  childrenClassName?: string;
  hideHeader?: boolean;
  onClick?: () => void;
};

const Box = ({
  children,
  icon,
  title,
  subTitle,
  clamp,
  className,
  clampClassName,
  childrenClassName,
  hideHeader = false,
  onClick,
}: BoxProps) => {
  return (
    <div
      className={cx(
        styles.box,
        { [styles.hasChildren]: !!children },
        className,
      )}
      onClick={onClick}
    >
      {!hideHeader && (
        <div className={styles.header}>
          <div className={styles.icon}>{icon}</div>
          <div className={cx(styles.clamp, clampClassName)}>{clamp}</div>
          <div className={styles.titleAndSubtitle}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subTitle}</span>
          </div>
        </div>
      )}
      {children && <div className={childrenClassName}>{children}</div>}
    </div>
  );
};

export { Box };
