import React, { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";
import { usePageContext } from "hooks/usePageContext";
import { Link } from "library";
import CondensedMenuSubItems from "./CondensedMenuSubItems";
import { useIsClient, useOnClickOutside } from "usehooks-ts";
import { isTouchDevice } from "utilities/display";

import type { MenuItem } from "./types";

import * as styles from "./CondensedMenuItem.module.scss";
type CondensedMenuItemProps = {
  item: MenuItem;
};

const CondensedMenuItem = ({ item }: CondensedMenuItemProps) => {
  const { section } = usePageContext();
  const [hovering, setHovering] = useState(undefined);
  const isClient = useIsClient();
  const isTouch = isClient && isTouchDevice();
  const itemRef = useRef(null);

  useOnClickOutside(itemRef, () => {
    if (isTouch) setHovering(undefined);
  });

  const handleOnClick = () => {
    if (isTouch) setHovering(item.name);

    if (item.onClick) item.onClick();
  };

  const shouldExpandOnTap = isTouch && item.items?.length;

  return (
    <motion.li
      ref={itemRef}
      className={cx(styles.container, {
        [styles.active]: (item.sections || []).includes(section),
      })}
      onHoverStart={() => !isTouch && setHovering(item.name)}
      onHoverEnd={() => !isTouch && setHovering(undefined)}
    >
      {item.to && !shouldExpandOnTap ? (
        <Link className={styles.item} nolang={!!item.noLang} to={item.to}>
          <>{item.icon}</>
        </Link>
      ) : (
        <div className={styles.item} onClick={handleOnClick}>
          <>{item.icon}</>
        </div>
      )}

      <AnimatePresence>
        {item.items?.length && hovering === item.name ? (
          <CondensedMenuSubItems items={item.items} />
        ) : null}
      </AnimatePresence>
    </motion.li>
  );
};

export default CondensedMenuItem;
