import React from "react";
import type { MenuItem } from "./types";

import * as styles from "./CondensedMenu.module.scss";
import CondensedMenuItem from "./CondensedMenuItem";
import { useIsClient } from "usehooks-ts";

type CondensedMenuProps = {
  items: MenuItem[];
};

const CondensedMenu = ({ items }: CondensedMenuProps) => {
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <div className={styles.menu}>
      <ul className={styles.items}>
        {items.map((item, index) => (
          <CondensedMenuItem key={`${item.name}-${index}`} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default CondensedMenu;
