// extracted by mini-css-extract-plugin
export var backgroundColor = "var(--tone-dark)";
export var backgroundColorError = "#ff2741";
export var backgroundColorFocus = "var(--tone-light-t75)";
export var backgroundColorHover = "var(--tone-light-t75)";
export var backgroundColorSelected = "var(--tone-brand-bg)";
export var blue = "#0eb3f2";
export var borderRadius = "3px";
export var colorWhite = "var(--tone-white)";
export var controlPadding = "1.3rem 1.6rem";
export var defaultSelect = "Select-module--defaultSelect--d4c69";
export var emphasis = "Select-module--emphasis--9f0eb";
export var fontFamily = "\"Plus Jakarta Sans\",sans-serif";
export var fontSizeInput = "1.4rem";
export var green = "#57d33b";
export var greenLink = "Select-module--green-link--ee591";
export var grey = "var(--tone-mid)";
export var gutterBottom = "Select-module--gutterBottom--b4377";
export var gutterLeft = "Select-module--gutterLeft--4be78";
export var gutterRight = "Select-module--gutterRight--11505";
export var gutterTop = "Select-module--gutterTop--3f8a1";
export var inputFontOpacity = ".9";
export var inputFontWeight = "300";
export var label = "Select-module--label--a58eb";
export var labelContainer = "Select-module--labelContainer--57f89";
export var notchGap = "32px";
export var optionPadding = "1.6rem";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";