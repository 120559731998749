// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "RaceOutcomes-module--emphasis--a6add";
export var fav = "RaceOutcomes-module--fav--b778c";
export var flame = "RaceOutcomes-module--flame--d0a9f";
export var green = "#57d33b";
export var greenLink = "RaceOutcomes-module--green-link--0a43d";
export var grey = "var(--tone-mid)";
export var isAlsoFav = "RaceOutcomes-module--isAlsoFav--caf56";
export var isShowingShortForm = "RaceOutcomes-module--isShowingShortForm--886af";
export var isWinPlace = "RaceOutcomes-module--isWinPlace--3ffb8";
export var multi = "RaceOutcomes-module--multi--cbe9e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcomes = "RaceOutcomes-module--outcomes--99bbb";
export var outcomesContainer = "RaceOutcomes-module--outcomesContainer--e7376";
export var red = "#ff2741";
export var round = "RaceOutcomes-module--round--c1566";
export var scratching = "RaceOutcomes-module--scratching--f61f4";
export var square = "RaceOutcomes-module--square--9413e";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";