import React from "react";
import { PageMessage } from "components/PageMessage";
import { useContext } from "react";
import { RaceContext } from "sections/Betting/Race/Race";
import { ReactComponent as OddsUnavailable } from "components/assets/icon-empty-odds.svg";
import { ButtonGroup } from "components/ButtonGroup";
import { useIsMobile } from "hooks";
import { Button } from "components/Button";
import { useIsSRMAvailable } from "sections/Betting/Race/hooks/useIsSRMAvailable";

import * as styles from "./SRMUnavailable.module.scss";

type SRMUnavailableProps = {
  children: React.ReactNode;
};

export const SRMUnavailable = ({ children }: SRMUnavailableProps) => {
  const isMobile = useIsMobile();
  const { setRouteValue } = useContext(RaceContext);

  const [_, unavailableMessage] = useIsSRMAvailable();

  if (unavailableMessage) {
    return (
      <div className={styles.unavailable}>
        <PageMessage
          title="SRM Unavailable"
          image={<OddsUnavailable />}
          subTitle={unavailableMessage}
        >
          <ButtonGroup>
            <Button
              variant={`primary`}
              size={isMobile ? `md` : `default`}
              onClick={() => setRouteValue({ tab: "top4" })}
            >
              Go to Top 4
            </Button>
          </ButtonGroup>
        </PageMessage>
      </div>
    );
  }
  return children;
};
