import { useEffect } from "react";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import type { RaceMeetingType } from "sections/Betting/Race/hooks/useRaceMeetings";
import { useLocalStorage } from "usehooks-ts";

export enum OrderColumn {
  number = "number",
  win = "win",
  startingPosition = "startingPosition",
}

export const columnSelectors = {
  number: "number",
  win: `markets.${RaceMarketTypeTypes.Win}.outcome.odds`,
  startingPosition: "startingPosition",
};

export type ColumnSelectorsType = Record<string, string>;

export enum OrderType {
  asc = "asc",
  desc = "desc",
}

export type RaceOrder = {
  orderColumn: OrderColumn;
  orderType: OrderType;
};

export type OrderOptionsType = {
  label: string;
  value: OrderColumn;
};

export const useRaceOrder = (raceMeeting: RaceMeetingType) => {
  const [raceOrder, setRaceOrder] = useLocalStorage<RaceOrder>(
    "racesSortColumn",
    {
      orderColumn: OrderColumn.number,
      orderType: OrderType.asc,
    },
  );

  const orderOptions = [
    { label: "Runner", value: OrderColumn.number },
    { label: "Win Odds", value: OrderColumn.win },
  ];

  if (raceMeeting?.sport === "HORSE_RACING") {
    orderOptions.push({
      label: "Barrier",
      value: OrderColumn.startingPosition,
    });
  }

  useEffect(() => {
    if (
      raceMeeting?.sport !== "HORSE_RACING" &&
      raceOrder.orderColumn === OrderColumn.startingPosition
    ) {
      setRaceOrder({
        orderColumn: OrderColumn.number,
        orderType: OrderType.asc,
      });
    }
  }, [raceMeeting, raceOrder, setRaceOrder]);

  return {
    raceOrder,
    setRaceOrder,
    orderOptions,
  };
};
