import type { DragHandlers, DragElastic, BoundingBox } from "framer-motion";
import { useDisplayMode } from "./useDisplay";
import { useIsClient } from "usehooks-ts";

type DragOpts = {
  drag: boolean | "x" | "y";
  dragConstraints: Partial<BoundingBox>;
  dragElastic: DragElastic;
  onDragEnd: DragHandlers["onDragEnd"];
  dragSnapToOrigin: boolean;
  dragMomentum: boolean;
};

type DragGesture = DragOpts & {
  onDragEnd: DragHandlers["onDragEnd"];
  dragSnapToOrigin: boolean;
  dragMomentum: boolean;
};

export function useDragGesture(
  overrides: Partial<DragOpts>,
  onDragEnd: DragHandlers["onDragEnd"],
): Partial<DragGesture> {
  const isClient = useIsClient();
  const displayMode = useDisplayMode();
  const isTabletOrSmaller =
    isClient && ["mobile", "tablet"].includes(displayMode);

  if (!isTabletOrSmaller) return {};

  const dragOptions: DragGesture = {
    drag: isTabletOrSmaller ? "x" : false,
    dragConstraints: overrides.dragConstraints || { right: 0, left: 0 },
    dragMomentum: overrides.dragMomentum || false,
    dragElastic: overrides.dragElastic || 0.2,
    dragSnapToOrigin: overrides.dragSnapToOrigin || true,
    onDragEnd,
  };

  return dragOptions;
}
