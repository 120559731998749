import React from "react";
import cx from "classnames";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";
import { cva, type VariantProps } from "class-variance-authority";
import { caret, down, up, right, left } from "./Caret.module.scss";

type CaretProps = {
  className?: string;
} & VariantProps<typeof caretVariants>;

const caretVariants = cva(caret, {
  variants: {
    variant: {
      down: down,
      up: up,
      right: right,
      left: left,
    },
  },
  defaultVariants: {
    variant: "right",
  },
});

const Caret = ({ variant, className }: CaretProps) => {
  return (
    <span className={cx(caretVariants({ variant }), className)}>
      <ChevronDown />
    </span>
  );
};

export { Caret };
