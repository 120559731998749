import React from "react";
import { Box } from "components/Box";
import { Skeleton } from "components/Skeleton";

import * as styles from "./RacesByDaySkeleton.module.scss";
import { ReactComponent as SingleIcon } from "components/assets/bet-single.svg";
import { NextToJumpSkeleton } from "sections/Betting/Racing/components/RaceRow/RaceRowsSkeleton";

export const RacesByDaySkeleton = () => {
  return (
    <Box
      icon={<SingleIcon className={styles.icon} />}
      title={<Skeleton className={styles.title} mode="light" />}
      clamp={
        <Skeleton
          shape="rect"
          size="xs"
          mode="light"
          className={styles.headingClamp}
        />
      }
    >
      <NextToJumpSkeleton />
    </Box>
  );
};
