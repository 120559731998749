import { useDispatch, useSelector } from "hooks";
import { useLocation } from "hooks/useLocation";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  type Card,
  type InAppMessageType,
  setCards,
  showCustomIAM,
} from "store/Braze";
import { useInterval } from "usehooks-ts";
import { isWebView } from "utilities/display";

export const WebBrazeInitializer = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const isNotWebViewRef = useRef(!isWebView());
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeBraze = useCallback(() => {
    if (!window.analytics || !window.braze) {
      return;
    }

    window?.analytics?.ready(() => {
      setIsInitialized(true);

      if (
        window.braze?.isPushSupported() &&
        !window.braze?.isPushPermissionGranted()
      ) {
        window.braze?.requestPushPermission();
      }

      window.braze?.subscribeToContentCardsUpdates((updates: { cards: any }) =>
        dispatch(setCards(updates?.cards as Card[])),
      );

      window.braze?.subscribeToInAppMessage(
        (inAppMessage: InAppMessageType) => {
          if (inAppMessage.Af === "SLIDEUP") {
            window.braze?.logInAppMessageImpression(inAppMessage);
            showCustomIAM(inAppMessage);
          } else {
            window.braze?.showInAppMessage(inAppMessage);
          }
        },
      );

      window.braze?.openSession();
      window.braze?.requestContentCardsRefresh();
    });
  }, [dispatch]);

  // Initialize Braze on first load, and each time auth changes
  useEffect(() => {
    if (isNotWebViewRef.current && window.braze) {
      window.braze?.requestContentCardsRefresh();
    }
  }, [auth, location]);

  // Braze is loaded via segment, so we need to wait for it to be loaded.
  // We check every second
  useInterval(
    initializeBraze,
    isNotWebViewRef.current && isInitialized ? null : 1000,
  );

  return null;
};
