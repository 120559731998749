// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var competitor = "CompetitorRow-module--competitor--45f48";
export var emphasis = "CompetitorRow-module--emphasis--4c25b";
export var green = "#57d33b";
export var greenLink = "CompetitorRow-module--green-link--7d8cf";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var row = "CompetitorRow-module--row--55d93";
export var skeleton = "CompetitorRow-module--skeleton--1eb66";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";