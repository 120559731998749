import * as accountSlice from "sections/Account/accountSlice";
import { useActions } from "hooks";

// NOTE: not sure what is this and why is this here
export const useAccountActions = () => {
  const [
    change,
    changeClaimCode,
    claimPromo,
    clearIdentityDocumentErrors,
    closeAccount,
    cooldown,

    doSubmitVerification,
    identityDocumentSubmitted,
    loadMore,
    onClearState,
    onDismissAllNotifications,
    onDismissNotification,

    onUpdateBettingPreferences,
    requestLoadMore,
    resendEmailVerification,
    saveDepositLimit,
    setCopied,
    setDepositLimitAmount,
    setEmailUnsubscribe,
    setNotificationSettings,
    setResent,
    setUploading,
    submitPassword,
    trackEvent,
    uploadAvatar,
    validate,
    verificationError,
    removeAvatar,
  ] = useActions([
    accountSlice.change,
    accountSlice.changeClaimCode,
    accountSlice.claimPromo,
    accountSlice.clearIdentityDocumentErrors,
    accountSlice.closeAccount,
    accountSlice.cooldown,

    accountSlice.doSubmitVerification,
    accountSlice.identityDocumentSubmitted,
    accountSlice.loadMore,
    accountSlice.onClearState,
    accountSlice.onDismissAllNotifications,
    accountSlice.onDismissNotification,

    accountSlice.onUpdateBettingPreferences,
    accountSlice.requestLoadMore,
    accountSlice.resendEmailVerification,
    accountSlice.saveDepositLimit,
    accountSlice.setCopied,
    accountSlice.setDepositLimitAmount,
    accountSlice.setEmailUnsubscribe,
    accountSlice.setNotificationSettings,
    accountSlice.setResent,
    accountSlice.setUploading,
    accountSlice.submitPassword,
    accountSlice.trackEvent,
    accountSlice.uploadAvatar,
    accountSlice.validate,
    accountSlice.verificationError,
    accountSlice.removeAvatar,
  ]);

  return {
    change,
    changeClaimCode,
    claimPromo,
    clearIdentityDocumentErrors,
    closeAccount,
    cooldown,
    doSubmitVerification,
    identityDocumentSubmitted,
    loadMore,
    onClearState,
    onDismissAllNotifications,
    onDismissNotification,
    onUpdateBettingPreferences,
    requestLoadMore,
    resendEmailVerification,
    saveDepositLimit,
    setCopied,
    setDepositLimitAmount,
    setEmailUnsubscribe,
    setNotificationSettings,
    setResent,
    setUploading,
    submitPassword,
    trackEvent,
    uploadAvatar,
    validate,
    verificationError,
    removeAvatar,
  };
};
