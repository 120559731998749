// extracted by mini-css-extract-plugin
export var Accreditation = "Accreditation-module--Accreditation--57518";
export var blue = "#0eb3f2";
export var emphasis = "Accreditation-module--emphasis--efa8e";
export var green = "#57d33b";
export var greenLink = "Accreditation-module--green-link--de92b";
export var grey = "var(--tone-mid)";
export var logos = "Accreditation-module--logos--1e08b";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";