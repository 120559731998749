import superjson from "superjson";

type Result<T> = [T | null, Error | null];

const IS_SERVER = typeof window === "undefined";

// NOTE: we are going to call this every time when we attempt to write but run into quota exceeded error
const printLocalStorageSizes = (): void => {
  console.error("Quota exceeded. Current sizes of all keys in localStorage:");
  for (let i = 0; i < localStorage.length; i++) {
    const currentKey = localStorage.key(i);
    if (currentKey) {
      const itemSize = (localStorage.getItem(currentKey) || "").length;
      console.log(`Key: ${currentKey}, Size: ${itemSize} bytes`);
    }
  }
};

const setValue = <T>(key: string, value: T): Result<boolean> => {
  if (IS_SERVER) {
    return [false, new Error("Cannot write to localStorage on the server")];
  }

  try {
    const encodedValue = superjson.stringify(value);
    localStorage.setItem(key, encodedValue);
    return [true, null];
  } catch (error) {
    if (error instanceof DOMException && error.name === "QuotaExceededError") {
      printLocalStorageSizes();
    }
    return [false, error];
  }
};

const getValue = <T>(key: string, defaultValue?: T): Result<T> => {
  if (IS_SERVER) {
    return [
      defaultValue,
      new Error("Cannot read from localStorage on the server"),
    ];
  }

  try {
    const encodedValue = localStorage.getItem(key);
    if (encodedValue === null) {
      return [defaultValue, null];
    }
    const decodedValue = superjson.parse<T>(encodedValue);
    return [decodedValue, null];
  } catch (error) {
    return [defaultValue, error as Error];
  }
};

const removeValue = (key: string): Result<boolean> => {
  if (IS_SERVER) {
    return [false, new Error("Cannot remove from localStorage on the server")];
  }

  try {
    localStorage.removeItem(key);
    return [true, null];
  } catch (error) {
    return [false, error as Error];
  }
};

export default { setValue, getValue, removeValue };
