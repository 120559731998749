// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "PickemPick-module--caret--917ca";
export var clamp = "PickemPick-module--clamp--5955e";
export var container = "PickemPick-module--container--22eb0";
export var drawer = "PickemPick-module--drawer--f5e5f";
export var emphasis = "PickemPick-module--emphasis--c607a";
export var green = "#57d33b";
export var greenLink = "PickemPick-module--green-link--fc73f";
export var grey = "var(--tone-mid)";
export var icon = "PickemPick-module--icon--54ee2";
export var isLast = "PickemPick-module--isLast--f0033";
export var isVoid = "PickemPick-module--isVoid--cb559";
export var name = "PickemPick-module--name--1e064";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcomeIcon = "PickemPick-module--outcomeIcon--f5238";
export var pick = "PickemPick-module--pick--bfb54";
export var points = "PickemPick-module--points--5dfb7";
export var red = "#ff2741";
export var selection = "PickemPick-module--selection--6d5cf";
export var vs = "PickemPick-module--vs--e23d6";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";