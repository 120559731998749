import { useEffect } from "react";
import { useDocumentData as useDocumentDataWithoutLoading } from "react-firebase-hooks/firestore";
import { useLoading } from "hooks/ui/useLoading";
import type {
  FirestoreError,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import type { NullableMaybe } from "types/utilities";

const useDocumentData = <T = DocumentData>(
  docRef: NullableMaybe<DocumentReference<T>>,
): [T | undefined, boolean, FirestoreError | undefined] => {
  const key = docRef?.path;
  const [startLoading, endLoading] = useLoading(key ?? "");

  const [value, loading, error] = useDocumentDataWithoutLoading<T>(docRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      endLoading();
    }

    return () => {
      // stop loading when component is unmounted
      endLoading();
    };
  }, [loading, startLoading, endLoading]);

  return [value, loading, error];
};

export { useDocumentData };
