// extracted by mini-css-extract-plugin
export var avatar = "QuickBalance-module--avatar--7b60a";
export var balance = "QuickBalance-module--balance--5cd6c";
export var balanceWithoutPromo = "QuickBalance-module--balanceWithoutPromo--d7bc9";
export var blue = "#0eb3f2";
export var container = "QuickBalance-module--container--f3039";
export var emphasis = "QuickBalance-module--emphasis--1397a";
export var green = "#57d33b";
export var greenLink = "QuickBalance-module--green-link--6bbec";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var promo = "QuickBalance-module--promo--f3345";
export var red = "#ff2741";
export var text = "QuickBalance-module--text--4b4e0";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";