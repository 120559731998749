import useCountryAndState from "hooks/useCountryAndState";
import React from "react";
import { countryMap, ausStatesMap } from "utilities/location";

export const CountryAndState = ({
  countryCode,
  stateCode,
}: {
  countryCode?: string;
  stateCode?: string;
}) => {
  const mappedStateCode =
    stateCode && countryCode == "AU" ? ausStatesMap[stateCode] : stateCode;

  const country = countryCode ? countryMap[countryCode] : countryCode;
  const [_, state] = useCountryAndState(countryCode, mappedStateCode);

  if (countryCode == "GB") {
    return <span>{country}</span>;
  }

  if (countryCode === "AU") {
    return <span>{state}</span>;
  }

  return (
    <span>
      {stateCode && `${state}, `}
      {country}
    </span>
  );
};
