// extracted by mini-css-extract-plugin
export var BadgeCount = "BadgeCount-module--BadgeCount--ae6ed";
export var blue = "#0eb3f2";
export var caution = "BadgeCount-module--caution--b7c1c";
export var danger = "BadgeCount-module--danger--6aa12";
export var emphasis = "BadgeCount-module--emphasis--c77b2";
export var error = "BadgeCount-module--error--a823e";
export var green = "#57d33b";
export var greenLink = "BadgeCount-module--green-link--ef7c5";
export var grey = "var(--tone-mid)";
export var info = "BadgeCount-module--info--ed910";
export var loading = "BadgeCount-module--loading--a838b";
export var notchGap = "32px";
export var ok = "BadgeCount-module--ok--2e7b7";
export var orange = "#f8662b";
export var red = "#ff2741";
export var success = "BadgeCount-module--success--364c0";
export var warning = "BadgeCount-module--warning--bace9";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";