import React from "react";
import { ListItem } from "components/List";

type VerificationOptionSkeletonProps = {
  count?: number;
};

export const VerificationOptionSkeleton = ({
  count = 1,
}: VerificationOptionSkeletonProps) => (
  <>
    {Array.from({ length: count }).map((_, i) => (
      <ListItem label="" key={`option-skeleton-${i}`} />
    ))}
  </>
);
