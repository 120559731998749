import React from "react";
import SupportLink from "../SupportLink";

const ResponsibleGamblingLink = () => {
  return (
    <SupportLink
      label="Responsible Gambling"
      options={{ articleId: "115010094608" }}
    />
  );
};

export default ResponsibleGamblingLink;
