import { fetchWithAppCheck as fetch } from "utilities/api/fetchWithAppCheck";

export const createATicket = async ({
  token,
  payload,
}: {
  token: string;
  payload: {
    email: string;
    subject: string;
    description: string;
  };
}) => {
  try {
    const response = await fetch(
      process.env.GATSBY_API_URI + `/zendesk/create`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );

    const body = await response.json();
    if (response.ok) {
      return body;
    } else {
      throw body;
    }
  } catch (error) {
    throw error;
  }
};
