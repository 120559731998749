// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var buttons = "Empty-module--buttons--e6810";
export var container = "Empty-module--container--414d9";
export var emphasis = "Empty-module--emphasis--6bdea";
export var green = "#57d33b";
export var greenLink = "Empty-module--green-link--e06ef";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";