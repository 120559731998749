import { useEffect, useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import type {
  DocumentData,
  QuerySnapshot,
  FirestoreError,
  CollectionReference,
  Query,
} from "firebase/firestore";
import { useLoading } from "hooks/ui/useLoading";

const toData = <T>(
  snapshots: QuerySnapshot<T, DocumentData> | undefined,
  loading: boolean,
): [T[] | undefined, boolean] => {
  // do not return cached data
  if (snapshots?.metadata?.fromCache) return [undefined, true];

  return [snapshots?.docs.map((doc) => doc.data()) as T[] | undefined, loading];
};

const useCollectionData = <T = DocumentData>(
  query: CollectionReference<T> | Query<T> | undefined | null,
  key: string | undefined,
): [T[] | undefined, boolean, FirestoreError | undefined] => {
  const [startLoading, endLoading] = useLoading(key || "emptyKey");

  const [snapshots, loading, error] = useCollection<T>(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [value, loadingExcludingCache] = useMemo(
    () => toData<T>(snapshots, loading),
    [snapshots, loading],
  );

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      endLoading();
    }

    return () => {
      // stop loading when component is unmounted
      endLoading();
    };
  }, [loading, startLoading, endLoading]);

  return [value, loadingExcludingCache, error];
};

export { useCollectionData };
