import React, { useContext } from "react";
import cx from "classnames";
import type {
  RaceCompetitorType,
  RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";

import { BettingTabs } from "hooks";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import * as styles from "./SameRaceMulti.module.scss";
import {
  Competitor,
  RaceFilters,
  RaceTips,
  Silks,
} from "sections/Betting/Race/components";
import { SRMUnavailable } from "sections/Betting/Race/components/SameRaceMulti/SRMUnavailable";
import { CampaignPromotions } from "sections/Betting/CampaignPromotions";
import CompetitorSkeleton from "sections/Betting/Race/components/Competitor/CompetitorSkeleton";
import { useSameRaceMulti } from "./hooks/useSameRaceMulti";
import { CompetitorNumberAndName } from "sections/Betting/Race/components/CompetitorInfo/CompetitorInfo";
import { RaceContext } from "sections/Betting/Race/Race";
import { ComplexBetBuilder } from "sections/Betting/components";
import { Selections } from "sections/Betting/components/ComplexBetBuilder/components/SameEventMulti/Selections";

export type WinPlaceCompetitorType = RaceCompetitorType &
  Partial<RaceOutcomeType>;

export const SameRaceMulti = (_: RouteComponentProps) => {
  const { race, markets, competitors, loading, campaigns } =
    useContext(RaceContext);

  const {
    errorMessage,
    mappedCompetitors,
    messageToReplaceFooter,
    flatSelections,
    handleSelect,
    handleRemoveSelection,
    handleRemoveAllSelections,
    validCompetitorCount,
    errorMessageShouldAllowClear,
  } = useSameRaceMulti();

  return (
    <SRMUnavailable>
      <CampaignPromotions tab={BettingTabs.RACING_SEM} campaigns={campaigns} />
      <RaceFilters />
      <RaceTips />
      <div className={cx(styles.content)}>
        {loading ? (
          <CompetitorSkeleton displayType="Top4" />
        ) : (
          <>
            {mappedCompetitors?.map((competitor) => (
              <Competitor
                key={race?.number + competitor?.id}
                competitor={competitor}
                validCompetitorCount={validCompetitorCount}
                toggleSelectionOverride={handleSelect}
                customSelections={flatSelections}
                isWinPlace={false}
              />
            ))}
          </>
        )}
      </div>
      {!loading && (
        <ComplexBetBuilder
          errorMessageToReplaceFooter={messageToReplaceFooter}
          errorMessage={errorMessage}
          selections={flatSelections}
          handleRemoveSelection={handleRemoveSelection}
          handleRemoveAllSelections={handleRemoveAllSelections}
          event={race}
          markets={markets}
          competitors={competitors}
          type={"srm"}
          errorMessageShouldAllowClear={errorMessageShouldAllowClear}
        >
          <Selections
            selections={flatSelections}
            markets={markets}
            handleRemoveSelection={handleRemoveSelection}
            renderSelectionName={(outcomeId: string) => {
              const competitor = mappedCompetitors.find(
                (c) =>
                  c.id ===
                  markets.find((m) => m.outcomes[outcomeId])?.outcomes[
                    outcomeId
                  ]?.competitorId,
              );

              return (
                <div className={styles.competitor}>
                  <Silks
                    silksUrl={race.silksUrl}
                    competitorNumber={competitor?.number}
                    imageHeight={25}
                  />
                  <CompetitorNumberAndName
                    competitor={competitor}
                    race={race}
                  />
                </div>
              );
            }}
          />
        </ComplexBetBuilder>
      )}
    </SRMUnavailable>
  );
};
