import { useEffect, useMemo, useState } from "react";
import {
  collection,
  query,
  orderBy,
  type DocumentData,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  limit,
} from "firebase/firestore";
import { useFiatCurrency, useSelector } from "hooks";
import { PickemsTransforms } from "selectors";
import { getDefaultBettingPath } from "utilities/sharedBettingUtilities";
import usePreferences from "./usePreferences";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";

export type Notification = {
  id: string;
  message: string;
  date: string;
  isRead: boolean;
  link: string;
  externalType: string;
  externalId: string;
  type: string;
};

export const getLink = (objectType, objectId, type, defaultHub): string => {
  if (objectType === "PICKEMS_CONTEST") {
    return PickemsTransforms.getPlayLink(objectId);
  }

  const bettingPath = getDefaultBettingPath(defaultHub);

  switch (type) {
    case "WELCOME_BETTING":
      return bettingPath;
    case "WELCOME_FANTASY":
    case "WELCOME":
      return "/pickems";
    case "VERIFICATION_SUCCESS":
      return "/wallet/deposit";
    case "VERIFICATION_REMINDER":
      return "/account/verification";
    case "DEPOSIT":
    case "ADMIN_DEPOSIT":
    case "DEPOSIT_FAILED":
    case "DEPOSIT_REFUND":
    case "WITHDRAWAL_PROCESSED":
    case "WITHDRAWAL_DECLINED":
    case "WITHDRAWAL_PARTIALLY_DECLINED":
    case "SKRILL_WITHDRAWAL_CANCELLED":
    case "PROMOTION_PAID":
      return `/wallet/statement`;
    case "PROMOTION_CLAIM_FAILED":
      return "/bonus-offers";
    case "BET_CANCELLED":
    case "BET_WON":
    case "PROMOTION_BET_STAKE_REFUND":
      return "/entries/historical";
    case "BET_ROLLBACK":
    case "BET_LEG_VOIDED":
    case "BET_LEG_UNVOIDED":
      return "/entries/live";
    case "VERIFY_EMAIL":
      return "/account/verification";
    case "DEPOSIT_LIMIT_REMINDER":
    case "DEPOSIT_LIMIT_RAISED":
    case "DEPOSIT_LIMIT_RAISE_REQUEST_RECEIVED":
      return "/preferences/responsible-gambling";
    case "EMAIL_UNSUBSCRIBED":
      return "/account/notifications";
    case "CREDIT_CARD_VERIFICATION_REQUIRED":
      return `/wallet/withdraw`;
    default:
      return bettingPath;
  }
};

const getNotificationConverter = (fiatCurrency, defaultHub = "esports") => ({
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    const data = snapshot.data(options);

    const parseData: DocumentData = {
      ...data,
      createdAt: data.createdAt.toDate(),
    };

    const {
      createdAt: date,
      dismissed,
      externalReferenceType: externalType,
      externalReferenceId: externalId,
      message,
      type,
    } = parseData;

    return {
      date,
      externalType,
      externalId,
      id: snapshot.id,
      isRead: Boolean(dismissed),
      link: getLink(externalType, externalId, type, defaultHub),
      message,
      type,
    };
  },
});

const useNotifications = (
  page = 1,
  perPage: number,
): [Notification[], boolean] => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fiatCurrency = useFiatCurrency();
  const userId = useSelector((state) => state.auth?.userId);
  const [userPreferences] = usePreferences();

  const converter = useMemo(
    () => getNotificationConverter(fiatCurrency, userPreferences?.defaultHub),
    [fiatCurrency, userPreferences],
  );

  const notificationsRef = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "notifications",
      ).withConverter(converter)
    : undefined;

  const queryWithUserId = userId
    ? query(
        notificationsRef,
        orderBy("createdAt", "desc"),
        limit(perPage * page || perPage),
      )
    : undefined;

  const [notificationsRaw, notificationsLoading] = useCollectionData(
    queryWithUserId,
    notificationsRef?.path,
  );

  useEffect(() => {
    if (notificationsLoading) {
      setLoading(true);
      return;
    }

    setNotifications(notificationsRaw);
    setLoading(false);
  }, [notificationsRaw, notificationsLoading]);

  return [notifications ?? [], loading];
};

export const useNotificationsCount = (): number => {
  const [count, setCount] = useState(0);
  const userId = useSelector((state) => state.auth?.userId);

  const notificationsRef = userId
    ? collection(getFirestore(), "users", userId, "notifications")
    : undefined;
  const [notifications, loading] = useCollectionData(
    notificationsRef,
    notificationsRef?.path,
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    setCount(
      (notifications || []).filter(({ dismissed }) => !dismissed).length,
    );
  }, [notifications, loading]);

  return count;
};

export default useNotifications;
