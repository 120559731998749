import { useContext, useMemo } from "react";
import { FirebaseContext } from "context/Firebase";
import { RaceContext } from "sections/Betting/Race";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import { getOutcome } from "utilities/racingUtilities";

export const useRaceResult = () => {
  const { userPreferences } = useContext(FirebaseContext);
  const { race, competitors, markets, loading } = useContext(RaceContext);

  /* { "1": "5", "2": "9", "3": "1/6"} */
  // 1st place: 5
  // 2nd place: 9
  // 3rd place: 1, 6
  // resultPosition: { "5": 1, "9": 2, "1": 3, "6": 3 }
  const raceResults = useMemo(
    () =>
      Object.entries(race?.results || {}).reduce(
        (acc, [position, runnerNumber]) => {
          const keys = runnerNumber.split("/");
          keys.forEach((x) => (acc[x] = Number(position)));
          return acc;
        },
        {} as Record<string, number>,
      ),
    [race?.results],
  );

  const hasDeadHeat = useMemo(
    () =>
      Object.values(race?.results || {}).some((result) => result.includes("/")),
    [race?.results],
  );

  const numberOfValidCompetitors = useMemo(
    () => competitors?.filter(({ scratched }) => !scratched)?.length,
    [competitors],
  );

  const isValidMarketType = (
    marketType: RaceMarketTypeTypes,
    resultPosition: number,
  ) => {
    const positionLimits = {
      [RaceMarketTypeTypes.Win]: 1,
      [RaceMarketTypeTypes.Top2]: 2,
      [RaceMarketTypeTypes.Top3]: 3,
      [RaceMarketTypeTypes.Top4]: 4,
    };

    if (
      marketType in positionLimits &&
      resultPosition > positionLimits[marketType]
    )
      return false;
    if (
      marketType !== RaceMarketTypeTypes.Place &&
      numberOfValidCompetitors < positionLimits[marketType] + 2
    )
      return false;

    return true;
  };

  const sortedCompetitors = useMemo(
    () =>
      competitors
        ?.filter(({ number }) => raceResults[number])
        ?.map((competitor) => {
          const resultPosition = raceResults[competitor.number];
          const mappedMarkets = Object.values(RaceMarketTypeTypes)
            .filter((marketType) =>
              isValidMarketType(marketType, resultPosition),
            )
            .map((marketType) => ({
              ...getOutcome(competitor.id, marketType, markets, true),
              marketType,
            }));

          return { ...competitor, markets: mappedMarkets, resultPosition };
        })
        ?.sort((a, b) => a.resultPosition - b.resultPosition),
    [competitors, markets, raceResults, numberOfValidCompetitors],
  );

  return {
    sortedCompetitors,
    hasDeadHeat,
    userPreferences,
    loading,
    race,
  };
};
