import React from "react";
import * as styles from "./RaceResultSkeleton.module.scss";
import { Skeleton } from "components/Skeleton";

export const RaceResultSkeleton = () => {
  return (
    <div className={styles.resultsContainer}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div className={styles.box} key={`skeleton-${index}`}>
          <div className={styles.winnerInfo}>
            <div className={styles.silksContainer}>
              <Skeleton
                edge="hard"
                shape="rect"
                mode="light"
                className={styles.silks}
              />
            </div>
            <div className={styles.winnerNameContainer}>
              <Skeleton
                edge="hard"
                shape="rect"
                mode="light"
                className={styles.winnerName}
              />
            </div>
            <div className={styles.medalContainer}>
              <Skeleton
                edge="hard"
                shape="rect"
                mode="light"
                className={styles.medal}
              />
            </div>
          </div>
          <div className={styles.resultOutcomes}>
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className={styles.resultOutcome}>
                <Skeleton
                  edge="hard"
                  shape="rect"
                  mode="light"
                  className={styles.label}
                />
                <Skeleton
                  edge="hard"
                  shape="rect"
                  mode="light"
                  className={styles.odds}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
