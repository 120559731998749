import { ResponsibleGamblingLink, SupportLink } from "library";
import React, { useEffect, useState } from "react";

import { useIsBettingSection, useIsMobile, useSelector } from "hooks";
import cx from "classnames";
import {
  useEventListener,
  useIsClient,
  useSsr,
  useWindowSize,
} from "usehooks-ts";

import * as styles from "./TermsAndConditions.module.scss";

const TermsAndConditions = () => {
  const { isBrowser } = useSsr();
  const isClient = useIsClient();
  const isMobile = useIsMobile();

  const [hidden, setHidden] = useState(true);
  const [documentOffset, setDocumentOffset] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up");
  const activeMenu = useSelector((state) => state.utilities.ui.menu);
  const isBettingSection = useIsBettingSection();

  const { height } = useWindowSize();

  const footerContainsTerms = () => {
    if (isBrowser) {
      const footer = window?.document?.querySelector(
        "[class^='Footer-module--compact']",
      );

      if (footer) {
        return footer?.textContent?.includes("Terms");
      }
      return false;
    }
    return false;
  };

  const setIsVisible = () => {
    setScrollDirection(window.scrollY > documentOffset ? "down" : "up");
    setDocumentOffset(window.scrollY);
  };

  useEffect(() => {
    if (!isBrowser) return;

    const documentScrollHeight = window?.document?.body.scrollHeight;
    const windowInnerHeight = window?.innerHeight;

    const shouldShowWhenFooterContainsTerms = footerContainsTerms
      ? documentScrollHeight - documentOffset - windowInnerHeight > 200
      : true;

    if (
      documentOffset > height &&
      shouldShowWhenFooterContainsTerms &&
      activeMenu === "none" &&
      scrollDirection === "down"
    ) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [
    activeMenu,
    documentOffset,
    scrollDirection,
    footerContainsTerms,
    isBrowser,
  ]);

  useEventListener("scroll", setIsVisible);

  if (!isClient || !isMobile || !isBettingSection) {
    return null;
  }

  return (
    <div className={styles.termAndConditionsContainer}>
      <div
        className={cx(styles.termAndConditions, {
          [styles.inactive]: hidden,
        })}
      >
        <SupportLink label="Terms" options={{ articleId: "115009926727" }} />
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <ResponsibleGamblingLink />
      </div>
    </div>
  );
};

export default TermsAndConditions;
