import React from "react";
import YouTubePlayer from "react-player/youtube";

import { youtubePlayer, player } from "./BlogWidgets.module.scss";

const YoutubeVideo = ({ url }) => {
  return (
    <div className={youtubePlayer}>
      <YouTubePlayer
        className={player}
        url={url}
        playing={false}
        controls
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default YoutubeVideo;
