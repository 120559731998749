// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var description = "Event-module--description--1a78e";
export var emphasis = "Event-module--emphasis--05618";
export var eventCount = "Event-module--eventCount--fe9fc";
export var eventDetails = "Event-module--eventDetails--c830e";
export var game = "Event-module--game--7721b";
export var green = "#57d33b";
export var greenLink = "Event-module--green-link--04602";
export var grey = "var(--tone-mid)";
export var hasExotics = "Event-module--hasExotics--1ff50";
export var hasSgm = "Event-module--hasSgm--df0f5";
export var hasStarted = "Event-module--hasStarted--8816a";
export var icon = "Event-module--icon--c2954";
export var info = "Event-module--info--819c6";
export var infoContainer = "Event-module--infoContainer--ae4c6";
export var isSgmReady = "Event-module--isSgmReady--933d4";
export var isSuspended = "Event-module--isSuspended--1b61a";
export var matchLink = "Event-module--matchLink--d4f74";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outright = "Event-module--outright--385fa";
export var promotional = "Event-module--promotional--503f6";
export var red = "#ff2741";
export var repeat = "Event-module--repeat--8e991";
export var round = "Event-module--round--b3e58";
export var row = "Event-module--row--3cf45";
export var season = "Event-module--season--d3891";
export var starts = "Event-module--starts--42029";
export var statusRows = "Event-module--statusRows--7d23c";
export var streamButton = "Event-module--streamButton--b037a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";