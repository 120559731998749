import cx from "classnames";
import type { GatsbyLinkProps } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import { useDispatch } from "hooks";
import useLocale from "hooks/useLocale";
import React from "react";
import { useIsClient } from "usehooks-ts";
import { hasLocale } from "utilities/locale";
import { setMenu, setModal } from "utilities/UI/uiSlice";

import * as styles from "./Link.module.scss";

export type LinkProps = GatsbyLinkProps<any> & {
  nolang?: boolean;
  to: string;
  noStyles?: boolean;
};
export const Link = React.forwardRef<LinkProps, LinkProps>(
  (
    {
      to,
      noStyles = false,
      className,
      children,
      nolang = false,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const external = to && !to.startsWith("/");
    const currentLocale = useLocale();
    const isClient = useIsClient();

    if (external) {
      return (
        <a
          href={to}
          className={className}
          target={`_blank`}
          {...rest}
          ref={ref as any}
        >
          {children}
        </a>
      );
    }

    if (isClient && !nolang && !hasLocale(to, currentLocale)) {
      const prefix =
        currentLocale !== window.___defaultLocale ? `/${currentLocale}` : "";
      to = `${prefix}${to}`;
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <GatsbyLink
        // gatsby link does not support proper types for refs
        // https://github.com/gatsbyjs/gatsby/pull/37543
        ref={ref as any}
        className={cx(
          {
            [styles.link]: !noStyles,
          },
          className,
        )}
        to={to}
        onClick={(e) => {
          dispatch(setMenu("none"));
          dispatch(setModal(null));

          if (typeof onClick === "function") {
            onClick(e);
          }
        }}
        {...rest}
      >
        {children}
      </GatsbyLink>
    );
  },
);

export default Link;
