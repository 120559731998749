// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "HUDPanelNotification-module--button--eea0d";
export var buttons = "HUDPanelNotification-module--buttons--f3f35";
export var caution = "HUDPanelNotification-module--caution--62a08";
export var danger = "HUDPanelNotification-module--danger--746f6";
export var emphasis = "HUDPanelNotification-module--emphasis--fb089";
export var error = "HUDPanelNotification-module--error--e49a9";
export var green = "#57d33b";
export var greenLink = "HUDPanelNotification-module--green-link--e0b9c";
export var grey = "var(--tone-mid)";
export var hidden = "HUDPanelNotification-module--hidden--d6d5e";
export var locationIcon = "HUDPanelNotification-module--locationIcon--c3282";
export var notchGap = "32px";
export var notification = "HUDPanelNotification-module--notification--cb207";
export var notificationCta = "HUDPanelNotification-module--notificationCta--5b7d6";
export var notificationIcon = "HUDPanelNotification-module--notificationIcon--624ea";
export var notificationText = "HUDPanelNotification-module--notificationText--f1e09";
export var ok = "HUDPanelNotification-module--ok--53ecf";
export var orange = "#f8662b";
export var red = "#ff2741";
export var secondary = "HUDPanelNotification-module--secondary--97ee1";
export var success = "HUDPanelNotification-module--success--096bf";
export var waitingIcon = "HUDPanelNotification-module--waitingIcon--bde98";
export var warning = "HUDPanelNotification-module--warning--1d036";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";