import React from "react";
import { Icon } from "library";
import cx from "classnames";
import * as styles from "./ExpandChevron.module.scss";

const Chevron = ({
  expanded = false,
  text,
  onClick,
  direction = "down",
  className,
}: {
  expanded: boolean;
  text?: string;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  direction?: "right" | "down";
  className?: string;
}) => {
  return (
    <div
      className={cx(className, styles.expand, {
        [styles.open]: expanded,
        [styles.right]: direction && direction === "right",
      })}
      onClick={onClick}
    >
      {text} <Icon type="chevronDown" />
    </div>
  );
};

export default Chevron;
