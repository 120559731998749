import React from "react";
import cx from "classnames";
import * as styles from "./RaceIcon.module.scss";
import { useTitle } from "hooks/graphql/useTitles";
import { titleSportMapping } from "sections/Betting/Racing/components/RaceMeetings/RaceMeetings";
import { SmallPromoIcon } from "components/PromoIcon/SmallPromoIcon";
import { TitleIcon } from "components/TitleIcon";

export const RaceIcon = ({
  sport,
  promotionIsAvailable,
  className,
  number,
}: {
  sport?: string;
  promotionIsAvailable?: boolean;
  className?: string;
  number?: number;
}) => {
  const title = useTitle(titleSportMapping[sport] ?? "");

  return (
    <div className={cx(styles.icon, { [styles.number]: number }, className)}>
      {number ? <div>{`R${number}`}</div> : <TitleIcon title={title} svg />}
      {promotionIsAvailable && <SmallPromoIcon className={styles.promoIcon} />}
    </div>
  );
};
