import { getTimezoneFrom2LetterCountryCode } from "utilities/racingUtilities";

export type UseRaceTimezoneReturnType = {
  raceTimezone: string;
};

export const useRaceTimezone = ({
  state,
  country,
}: {
  state: string;
  country: string;
}) => getTimezoneFrom2LetterCountryCode(country, state);
