// extracted by mini-css-extract-plugin
export var active = "Carousel-Root-module--active--51565";
export var banner = "Carousel-Root-module--banner--59a11";
export var bar = "Carousel-Root-module--bar--4afe2";
export var bars = "Carousel-Root-module--bars--2affe";
export var blue = "#0eb3f2";
export var carousel = "Carousel-Root-module--carousel--58526";
export var carouselContainer = "Carousel-Root-module--carouselContainer--bd641";
export var dot = "Carousel-Root-module--dot--82ef8";
export var dots = "Carousel-Root-module--dots--d9ea4";
export var emphasis = "Carousel-Root-module--emphasis--77fe8";
export var green = "#57d33b";
export var greenLink = "Carousel-Root-module--green-link--9ce69";
export var grey = "var(--tone-mid)";
export var isDragging = "Carousel-Root-module--isDragging--d33d1";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";