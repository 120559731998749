// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Form-module--emphasis--ff7d3";
export var fixedWidth = "Form-module--fixedWidth--21db4";
export var form = "Form-module--form--5a977";
export var green = "#57d33b";
export var greenLink = "Form-module--green-link--9e0b2";
export var grey = "var(--tone-mid)";
export var noSpacing = "Form-module--noSpacing--22ee1";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var withGap = "Form-module--withGap--53191";
export var yellow = "#ffb100";