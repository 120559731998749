import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import cx from "classnames";
import { type AnnouncementCardType, useEvent } from "hooks";
import Carousel from "components/Carousel";
import { ErrorBoundary, navigate } from "library";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { PICKLEBET_DOMAINS } from "appConfig";

import * as styles from "./AnnouncementCards.module.scss";

const announcementCardVariants = cva(styles.announcementCard, {
  variants: {
    variant: {
      danger: styles.danger,
      warning: styles.warning,
      info: styles.info,
      success: styles.success,
      neutral: styles.neutral,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

type AnnouncementCardsProps = {
  className?: string;
  cards: AnnouncementCardType[];
};

type AnnouncementCardProps = VariantProps<typeof announcementCardVariants> & {
  card: AnnouncementCardType;
  hasDots?: boolean;
};

export const getDifferenceInTime = (scheduledStartTime: Date) => {
  const now = new Date();

  // Calculating the difference in seconds
  const totalSeconds = differenceInSeconds(scheduledStartTime, now);

  // If the difference is less than now (negative), show "Starting Soon"
  if (totalSeconds < 0) {
    return "Starting Soon";
  }

  // If the difference is less than 3 minutes, show in seconds
  if (totalSeconds < 180) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60; // Subtract minutes' worth of seconds
    return `${minutes}m ${seconds}s`;
  }

  // Else, compute hours and minutes difference. Don't show hours if 0
  const totalHours = differenceInHours(scheduledStartTime, now);
  const hours = Math.floor(totalHours);
  const minutes = Math.floor(
    differenceInMinutes(scheduledStartTime, now) - hours * 60,
  );
  return `${hours ? `${hours}h ` : ""}${minutes}m`;
};

const AnnouncementCard = ({
  variant,
  card,
  hasDots,
}: AnnouncementCardProps) => {
  const [event] = useEvent(card.eventId);
  const eventTime = event
    ? getDifferenceInTime(event.scheduledStartTime)
    : null;

  const isClickable = card.announcementUrl?.internal?.content ?? card.actionUrl;

  return (
    <div
      className={cx(announcementCardVariants({ variant }), {
        [styles.pointer]: isClickable,
        [styles.noDots]: !hasDots,
      })}
    >
      <div className={styles.content}>
        <div className={styles.title}>{card.title}</div>
        <div className={styles.message}>
          {card.message?.childMarkdownRemark?.html && (
            <div
              dangerouslySetInnerHTML={{
                __html: card.message?.childMarkdownRemark?.html,
              }}
            />
          )}
          {card.eventId && (
            <>
              {" "}
              {event?.eventName ? `${event?.eventName} ` : " "}
              {event?.scheduledStartTime && <span>&#x2022; </span>}
              {eventTime}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const AnnouncementCards = ({
  cards,
  className,
}: AnnouncementCardsProps) => {
  const handleClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    card: AnnouncementCardType,
  ) => {
    const target = e.target;

    if (!(target instanceof HTMLElement)) return;
    // if target is an anchor tag, don't navigate
    if (target?.tagName === "A") return;

    const actionUrl = card.announcementUrl?.internal?.content ?? card.actionUrl;
    if (!actionUrl) return;

    const url = new URL(actionUrl);
    PICKLEBET_DOMAINS.some((domain) => actionUrl.includes(domain))
      ? navigate(`${url.pathname}${url.search}${url.hash}`)
      : window.location.assign(actionUrl);
  };

  return (
    <ErrorBoundary>
      <div className={cx(styles.announcementCards, className)}>
        <Carousel.Root controlsType={"dots"}>
          {cards.map((card) => (
            <Carousel.Item
              onClick={(e) => {
                handleClick(e, card);
              }}
            >
              <AnnouncementCard
                variant={card.variant as any}
                card={card}
                hasDots={cards.length > 1}
              />
            </Carousel.Item>
          ))}
        </Carousel.Root>
      </div>
    </ErrorBoundary>
  );
};
