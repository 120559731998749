import { collection, CollectionReference } from "firebase/firestore";
import { useCollectionData, useFiatCurrency } from "hooks";
import { getFirestore } from "store/getFirebase";
import type { PromotionClaim } from "types/PromotionTypes";
import { useSelector } from "hooks";
import type { CurrencyType } from "types/BetTypes";
import type {
  Action,
  AutomationProgression,
  Requirement,
} from "hooks/useUserProgression";
import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { useMemo } from "react";
import { parseDate } from "utilities/dateUtilities";

type IPromotionClaimAutomation = PromotionClaim & {
  actions: Action[];
  requirements: Requirement[];
};

const createAutomationProgression = (
  currency: CurrencyType,
): FirestoreDataConverter<IPromotionClaimAutomation> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): IPromotionClaimAutomation => {
      const automation = snapshot.data(options) as AutomationProgression;

      const projectedRewardInfo = automation.actions?.find(
        (requirement) => requirement.type === "DEPOSIT_MATCH",
      )?.projectedRewardInfo;

      return {
        code: automation.progressionId,
        expireAt: automation.expiresAt?.toDate(),
        amount: projectedRewardInfo?.max || 0,
        currency,
        name: automation.name,
        matchPercentage: projectedRewardInfo?.percent || 0,
        purpose: "DEPOSIT_BONUS", // for now we are mapping to legacy until we can remove all legacy claims logic
        paidStatus: "PENDING", // for now we are mapping to legacy until we can remove all legacy claims logic
        actions: automation.actions,
        requirements: automation.requirements,
      };
    },
  };
};

const legacyClaimsConverter: FirestoreDataConverter<PromotionClaim> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): PromotionClaim => {
    const data = snapshot.data(options) as PromotionClaim;

    return {
      ...data,
      expireAt: data.expireAt ? parseDate(data.expireAt) : undefined,
    };
  },
};

const isDepositMatch = ({ actions }: IPromotionClaimAutomation) =>
  actions.length > 0 &&
  actions.some((action) => action.type === "DEPOSIT_MATCH");

const isDepositRequirement = ({ requirements }: IPromotionClaimAutomation) =>
  requirements.length > 0 &&
  requirements.some((requirement) => requirement.type === "DEPOSIT");

const useAutomationPromotionClaims = (): [PromotionClaim[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const currency = useFiatCurrency();

  const automationProgression = useMemo(
    () => createAutomationProgression(currency),
    [currency],
  );

  const currentAutomationsRef = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "currentAutomationProgressions",
      ).withConverter(automationProgression)
    : undefined;

  const [automationClaims, loadingAutomationClaims] = useCollectionData(
    currentAutomationsRef,
    currentAutomationsRef?.path,
  );

  const automation = automationClaims?.filter(
    (automation) =>
      isDepositMatch(automation) && isDepositRequirement(automation),
  );

  return [automation, loadingAutomationClaims];
};

const useLegacyPromotionClaims = (): [PromotionClaim[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);

  const ref = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "promotionClaims",
      ).withConverter(legacyClaimsConverter)
    : undefined;

  const [legacyClaims, loadingLegacyClaims] = useCollectionData(ref, ref?.path);

  return [legacyClaims, loadingLegacyClaims];
};

const usePromotionClaims = (): [PromotionClaim[], boolean] => {
  const [automationClaims, loadingAutomationClaims] =
    useAutomationPromotionClaims();

  const [legacyClaims, loadingLegacyClaims] = useLegacyPromotionClaims();

  const combinedClaims = (automationClaims ?? []).concat(legacyClaims ?? []);

  return [combinedClaims, loadingAutomationClaims || loadingLegacyClaims];
};

export default usePromotionClaims;
