import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "hooks";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import { BANNER } from "appConfig";
import { useDisplayAtMost } from "hooks/useDisplay";
import { navigate } from "../Link";
import { setModal } from "utilities/UI/uiSlice";

import type { Banner } from "hooks/graphql/useHubs";

import * as styles from "./Banner.module.scss";
import {
  BANNER_DISPLAY_SECONDS,
  useContentfulSetting,
} from "hooks/graphql/useContentfulSettings";

type BannersProps = {
  banners: Banner[];
};

export const Banners = ({ banners }: BannersProps) => {
  const contentfulDisplaySeconds = useContentfulSetting(BANNER_DISPLAY_SECONDS);
  const displaySeconds =
    parseInt(contentfulDisplaySeconds) || BANNER.displaySeconds;
  const dispatch = useDispatch();
  const isMobile = useDisplayAtMost("mobile");
  const [imageIndex, setImageIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const images = useMemo(
    () =>
      banners
        .map((banner) => {
          const media =
            isMobile && banner.mediaMobile ? banner.mediaMobile : banner.media;
          let url = media?.file ? media?.file?.url : media?.resolutions?.src;
          if (url && url.startsWith("//")) {
            url = "https:" + url;
          }

          if (!url) {
            console.warn("Banner missing image", banner);
            return null;
          }

          return url;
        })
        .filter(Boolean) as string[],
    [banners, isMobile],
  );

  const handleBannerClick = () => {
    if (isDragging) return;
    const banner = banners[imageIndex];

    // contentful is currently only setup to accept shortened Picklebet URLs (https://pkl.bet)
    // but figured leaving this to handle all https would be more future proof
    const isExternal = banner.href?.includes("https://");

    if (banner.href === "/login") {
      dispatch(setModal("signIn"));
    } else if (banner.href === "/signup") {
      dispatch(setModal("signUp"));
    } else if (isExternal) {
      window.location.href = banner.href;
    } else {
      navigate(banner.href);
    }
  };

  const handleControllerClick = (i) => {
    setDirection(i > imageIndex ? 1 : -1);
    setImageIndex(i);
  };

  const variants = {
    enter: { x: direction > 0 ? "100%" : "-100%", opacity: 0 },
    center: { zIndex: 1, x: 0, opacity: 1 },
    exit: { zIndex: 0, x: direction < 0 ? "100%" : "-100%", opacity: 0 },
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (_, { offset }) => {
    setIsDragging(false);
    if (offset.x > 100) {
      setImageIndex(imageIndex === 0 ? images.length - 1 : imageIndex - 1);
      setDirection(-1);
    } else if (offset.x < -100) {
      setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
      setDirection(1);
    }
  };

  useEffect(() => {
    if (!isDragging) {
      const timer = setTimeout(() => {
        setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
        setDirection(1);
      }, displaySeconds * 1000);
      return () => clearTimeout(timer);
    }
  }, [imageIndex, isDragging]);

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <div className={styles.banners}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={imageIndex}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onClick={handleBannerClick}
        />
        <div className={styles.controls}>
          {images.length &&
            images.map((x, i) => (
              <div
                key={i}
                className={cx(styles.control, {
                  [styles.active]: i === imageIndex,
                })}
                onClick={() => handleControllerClick(i)}
              />
            ))}
        </div>
      </AnimatePresence>
    </div>
  );
};
