import {
  collection,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import { useSelector } from "hooks/index";
import { getFirestore } from "store/getFirebase";
import { useCollectionData } from "hooks";

export type DepositLimit = {
  maximumDailyDepositAmount: number;
  minimumDepositAmount: number;
  dailyLimitDays: number;
  remainingDailyAmount: number;
  expiresAt?: string;
  //The amount the user will be able to deposit at the expiry date. This will be the amount of the earliest deposit within the limit period
  depositAmountAtExpiry?;
  pendingUpdateAt?: string;
  pendingUpdateDailyLimitAmount?: number;
  pendingUpdateDailyLimitDays?: number;
  currency: string;
};

const depositLimitsConverter: FirestoreDataConverter<any> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any {
    return {
      ...snapshot.data(options),
      currency: snapshot.id,
    };
  },
};

const useAllDepositLimits = (): [DepositLimit[] | undefined, boolean, any] => {
  const userId = useSelector((state) => state.auth?.userId);

  const depositLimitsRef = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "depositLimits",
      ).withConverter(depositLimitsConverter)
    : undefined;

  return useCollectionData<DepositLimit>(
    depositLimitsRef,
    depositLimitsRef?.path,
  );
};

export default useAllDepositLimits;
