import React from "react";
import cx from "classnames";
import { Typography } from "components/Typography";

import { SVG_ESIC, SVG_PLUS_18 } from "assets/icons";

import * as styles from "./Accreditation.module.scss";

export const Accreditation = ({
  className,
  showText = true,
}: {
  className?: string;
  showText?: boolean;
}) => (
  <div className={cx(styles.Accreditation, className)}>
    {showText && (
      <>
        <Typography variant="body">
          Licensed and regulated by Australia's Northern Territory Government.
        </Typography>
        <Typography>
          Puntaa Pty Ltd trading as Picklebet. Unit 1, 48-50 Smith Street Darwin
          NT 0800.
        </Typography>
      </>
    )}
    <div className={styles.logos}>
      <a href={`https://esic.gg/`} target="_blank" rel="noopener noreferrer">
        <SVG_ESIC />
      </a>
      <SVG_PLUS_18 />
    </div>
  </div>
);
