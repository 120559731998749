import * as uuid from "uuid";
import { FirebaseContext } from "context/Firebase";
import { useDispatch, useIsLoggedIn, usePermissions, useSelector } from "hooks";
import { RaceStatuses } from "sections/Betting/Race/hooks/RacingTypes";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  selectIsMultiOutcomeInBetslip,
  toggleSelection,
} from "sections/Betting/Betslip/betslipSlice";
import { SubOutcome } from "sections/Betting/Betslip/legacy/SubOutcome";
import Odds from "sections/Betting/Odds";
import { requestSameRaceMultiOdds } from "utilities/api";
import { mapRaceToEvent } from "utilities/racingUtilities";
import cx from "classnames";
import * as styles from "./SRMShare.module.scss";
import { PermissionReason } from "utilities/Auth/authSlice";
import { useRaceMarketsAndCompetitors } from "sections/Betting/Race/hooks/useRace";
import { Box } from "components/Box";
import { Logo } from "sections/Betting/components/ComplexBetBuilder/components/Logo";
import { OddsChanges } from "components/OddsChanges";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";
import { CompetitorNumberAndName } from "sections/Betting/Race/components/CompetitorInfo/CompetitorInfo";
import { Silks } from "sections/Betting/Race/components";

const SRMShare = ({
  subOutcomes,
  eventId,
  addToSlip = true,
  active,
}: {
  subOutcomes: {
    outcomeId: string;
    outcomeName: string;
  }[];
  eventId: string;
  addToSlip: boolean;
  active: boolean;
}) => {
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const [addedToBetSlip, setAddedToBetSlip] = useState(false);
  const { race, competitors, markets, raceLoading } =
    useRaceMarketsAndCompetitors(eventId);

  const { userPreferences } = useContext(FirebaseContext);

  const token = useSelector((state) => state?.auth?.token);

  const isLoggedIn = useIsLoggedIn();

  const [odds, setOdds] = useState(0);

  const [loadingOdds, setLoadingOdds] = useState(false);

  const isSRMBlocked =
    permissions?.submitSRMBet === PermissionReason.BLOCKED ||
    (permissions?.submitInternationalRacingBet === PermissionReason.BLOCKED &&
      race.region === "ROW");

  const selectIsCurrentSelectionInBetslip = useSelector(
    selectIsMultiOutcomeInBetslip,
  );
  const isCurrentSelectionInBetslip = selectIsCurrentSelectionInBetslip(
    subOutcomes.map((x) => x.outcomeId),
  );

  const srmCompetitors = subOutcomes.map((subOutcome) => {
    let competitorId;
    let marketType;
    let marketId;
    markets?.forEach((market) => {
      if (market.outcomes[subOutcome.outcomeId]) {
        competitorId = market.outcomes[subOutcome.outcomeId].competitorId;
        marketType = market.marketType;
        marketId = market.id;
      }
    });

    return {
      ...competitors?.find((competitor) => competitor.id === competitorId),
      outcomeId: subOutcome.outcomeId,
      marketId,
      marketType,
    };
  });

  const addToBetSlip = useCallback(() => {
    dispatch(
      toggleSelection(
        mapRaceToEvent({ event: race, competitors, markets }),
        {
          type: "race",
          outcomeId: uuid.v4(),
          sourceId: race.id,
          odds: odds,
          eventId: race.id,
          stake: 0,
          subSelections: subOutcomes.map((x) => {
            const market = markets.find(
              (market) => market.outcomes[x.outcomeId],
            );
            return {
              outcomeId: x.outcomeId,
              marketId: market?.id,
              name: market?.outcomes[x.outcomeId]?.name,
              marketName: market?.name,
            };
          }) as any,
        },
        "racing",
        true,
      ),
    );
  }, [markets, race, competitors, odds]);

  useEffect(() => {
    if (!addedToBetSlip && addToSlip && race?.status === RaceStatuses.OPEN) {
      if (
        !raceLoading &&
        markets.length > 0 &&
        competitors.length > 0 &&
        !isSRMBlocked &&
        odds > 0
      ) {
        addToBetSlip();
        setAddedToBetSlip(true);
      }
    }
  }, [
    raceLoading,
    markets,
    competitors,
    addedToBetSlip,
    addToSlip,
    race?.status,
    odds,
    isSRMBlocked,
  ]);

  const handleOddsChanged = useCallback(async () => {
    if (isLoggedIn) {
      if (token === null) return;

      if (srmCompetitors.length > 1 && race?.status === RaceStatuses.OPEN) {
        setLoadingOdds(true);
        try {
          const res = await requestSameRaceMultiOdds({
            token: token,
            payload: {
              bets: {
                one: {
                  selections: [
                    { outcomeIds: subOutcomes.map((x) => x.outcomeId) },
                  ],
                },
              },
            },
          });
          if (res?.bets?.one?.odds)
            setOdds(res?.bets?.one?.odds?.toFixed(2) as any);
        } catch (e) {
          console.log(e);
          setOdds(0);
        } finally {
          setLoadingOdds(false);
        }
      }
    } else {
      setOdds(0);
    }
  }, [isLoggedIn, token, srmCompetitors, race?.status, subOutcomes]);

  useEffect(() => {
    handleOddsChanged();
  }, [userPreferences, token, isLoggedIn, raceLoading]);

  if (raceLoading || markets.length === 0 || competitors.length === 0)
    return null;

  if (isSRMBlocked) return null;

  return (
    <Box hideHeader>
      <div className={styles.content}>
        <div className={styles.heading}>
          <div className={styles.logo}>
            <Logo type="srm" />
          </div>
          <div className={styles.title}>
            Same Race Multi ({srmCompetitors.length} legs)
          </div>
          <OddsChanges
            odds={Number(odds)}
            animationLength={3000}
            className={cx(styles.odds, {
              [styles.active]: isCurrentSelectionInBetslip,
              [styles.disabled]: !active || loadingOdds,
            })}
            onClick={addToBetSlip}
          >
            <div className={styles.text}>
              {!loadingOdds ? (
                <Odds
                  base={Number(odds)}
                  zero={`-`}
                  format={userPreferences.oddsFormat}
                />
              ) : (
                <div>...</div>
              )}
              <div className={styles.arrowUp}>
                <ArrowUp />
              </div>

              <div className={styles.arrowDown}>
                <ArrowDown />
              </div>
            </div>
          </OddsChanges>
        </div>
        <div className={styles.selections}>
          {srmCompetitors?.map((competitor) => (
            <div key={competitor.id}>
              <div className={styles.selection}>
                <div className={styles.silksContainer}>
                  <Silks
                    competitorNumber={competitor?.number}
                    imageHeight={40}
                    silksUrl={race?.silksUrl}
                  />
                </div>
                {competitor?.name && (
                  <div>
                    <CompetitorNumberAndName
                      competitor={competitor as any}
                      race={race}
                    />
                    <SubOutcome
                      subOutcome={{
                        marketId: competitor.marketId,
                        marketName: competitor.marketType,
                        name: competitor.name,
                        outcomeId: competitor.outcomeId,
                        marketStatus: "ACTIVE",
                      }}
                      eventId={eventId}
                      oddsChanged={handleOddsChanged}
                      sourceType={"race"}
                      showOutcomes={false}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default SRMShare;
