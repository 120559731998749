import React, { useContext } from "react";
import { RaceContext } from "sections/Betting/Race";

import { getValidCompetitorCount } from "utilities/racingUtilities";
import { Alert } from "components/Alert";

type RaceMessageProps = {
  className?: string;
};

export const RaceMessage = ({ className }: RaceMessageProps) => {
  const { competitors, route, race } = useContext(RaceContext);
  const validCompetitorsCount = getValidCompetitorCount(competitors);

  const getMessage = () => {
    if (route.tab === "top4" || route.tab === "same-race-multi") {
      if (validCompetitorsCount < 4) {
        return "No Top 2, Top 3 or Top 4 markets available (less than 4 runners)";
      }

      if (validCompetitorsCount < 5) {
        return "No Top 3 or Top 4 markets available (less than 5 runners)";
      }

      if (validCompetitorsCount < 6) {
        return "No Top 4 market available (less than 6 runners)";
      }
    }

    if (route.tab === "results" && race?.results) {
      return "SP Place markets are paid out on market odds indicated by (P)";
    }

    if (route.tab === "win-place") {
      if (validCompetitorsCount < 8 && validCompetitorsCount > 4) {
        return "No 3rd place paid (less than 8 runners)";
      }
      if (validCompetitorsCount <= 4) {
        return "No 2nd or 3rd place paid (less than 5 runners)";
      }
    }

    return "";
  };

  const message = getMessage();

  if (message === "") return null;

  return (
    <Alert className={className} variant="info" indicator="top" size="small">
      {message}
    </Alert>
  );
};
