import React from "react";
import * as styles from "./RaceOutcomes.module.scss";
import cx from "classnames";

const FlameIcon = () => <div>🔥</div>;

export const FlucsIcon = ({
  position,
  isFavorite,
}: {
  position: number;
  isFavorite: boolean;
}) => {
  if (!position || position > 3) return null;

  // The 4 here is to invert the numbers so the amount of flames correlate to the position
  // The lower the position (higher number) the less flames should be shown
  // 4 was chosen because the highest number the position can be is 3, which results in 1 flame
  const flameCount = 4 - position;

  return (
    <div
      className={cx(styles.flame, {
        [styles.isAlsoFav]: isFavorite,
      })}
    >
      {Array.from({ length: flameCount }, (_, index) => (
        <FlameIcon key={`flames-${index}`} />
      ))}
    </div>
  );
};
