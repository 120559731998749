// extracted by mini-css-extract-plugin
export var badge = "Badge-module--badge--df214";
export var blue = "#0eb3f2";
export var danger = "Badge-module--danger--b70d8";
export var emphasis = "Badge-module--emphasis--36f2a";
export var gray = "Badge-module--gray--6d613";
export var green = "#57d33b";
export var greenLink = "Badge-module--green-link--86b5e";
export var grey = "var(--tone-mid)";
export var info = "Badge-module--info--a2897";
export var loading = "Badge-module--loading--86297";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var success = "Badge-module--success--51b5f";
export var warning = "Badge-module--warning--a6c63";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";