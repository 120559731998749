import { Skeleton } from "components/Skeleton";
import React from "react";
import * as styles from "./RaceNumberTabSkeleton.module.scss";

export default () => {
  return (
    <div className={styles.raceNumbers}>
      {Array.from({ length: 8 }).map((_, index) => (
        <Skeleton
          key={`race-number-${index}`}
          shape="square"
          size="sm"
          className={styles.tab}
          mode="light"
        />
      ))}
    </div>
  );
};
