import React from "react";
import * as styles from "./DrawerLineItem.module.scss";

export type DrawerLineItemProps = {
  label: string;
  value: string | React.ReactNode;
};

export const DrawerLineItem = ({ label, value }: DrawerLineItemProps) => {
  return (
    <div className={styles.drawerLine}>
      <span className={styles.drawerLineLabel}>{label}</span>
      <span className={styles.drawerLineValue}>{value}</span>
    </div>
  );
};
