import React, { Component } from "react";
import cx from "classnames";

import * as styles from "./Validator.module.scss";

class Validator extends Component {
  constructor(props) {
    super(props);
    this.state = { tooltip: false };
  }

  componentDidUpdate() {
    const { issues } = this.props;
    const { tooltip } = this.state;
    // hide tooltip if needed
    if (issues && issues.length === 0 && tooltip) {
      this.setToolTip(false);
    }
  }

  setToolTip = (show) => {
    const { issues } = this.props;
    if ((issues && issues.length) || show === false) {
      this.setState({ tooltip: show });
    }
  };

  render() {
    const {
      children,
      issues = [],
      showTooltip = true,
      size = "medium",
      className: classNameProp,
    } = this.props;

    let errorClass = "";
    if (issues.length > 0) {
      errorClass = " " + styles.error;
    }

    let tooltip = "";
    if (this.state.tooltip) {
      let issueString = issues.join(", ");
      issueString = issueString.charAt(0).toUpperCase() + issueString.slice(1);
      tooltip = <div className={styles.tooltip}>{issueString}.</div>;
    }
    return (
      <div
        className={cx(
          {
            [styles.validator]: true,
            [errorClass]: true,
          },
          classNameProp,
        )}
      >
        {children}

        {showTooltip && issues.length > 0 ? (
          <button
            className={cx({
              [styles.bullet]: true,
              [styles.medium]: size === "medium",
              [styles.small]: size === "small",
            })}
            onMouseOver={() => this.setToolTip(true)}
            onMouseOut={() => this.setToolTip(false)}
            onClick={() => this.setToolTip(!this.state.tooltip)}
            type="button"
          >
            {issues.length}
          </button>
        ) : null}

        {tooltip}
      </div>
    );
  }
}

export default Validator;
