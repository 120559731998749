export const toOrdinal = (value: unknown): any => {
  const numberValue = Number(value);

  if (isNaN(numberValue) || numberValue === 0) {
    return value;
  }

  const flooredNumber = Math.floor(numberValue);
  if (flooredNumber === 0) {
    return "0";
  } else if (flooredNumber < 0) {
    return flooredNumber;
  }
  const lastDigit = flooredNumber % 10;
  const secondLastDigit = Math.floor((flooredNumber % 100) / 10);

  if (secondLastDigit === 1) {
    return flooredNumber + "th"; // Numbers ending in 11, 12, 13 are exceptions
  }

  switch (lastDigit) {
    case 1:
      return flooredNumber + "st";
    case 2:
      return flooredNumber + "nd";
    case 3:
      return flooredNumber + "rd";
    default:
      return flooredNumber + "th";
  }
};
