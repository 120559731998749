import React, { Suspense, lazy } from "react";
import { Input } from "components/Input";
import type { Country } from "react-phone-number-input/input-mobile";
import type { InputProps } from "components/Input";

const BasePhoneInput = lazy(
  () => import("react-phone-number-input/react-hook-form-input"),
);

type PhoneInputProps = {
  countryCode?: Country;
  control: any;
} & InputProps;

const PhoneInput = ({
  countryCode,
  control,
  name,
  ...props
}: PhoneInputProps) => {
  return (
    <Suspense fallback={null}>
      <BasePhoneInput
        control={control}
        name={name}
        inputComponent={Input}
        defaultCountry={countryCode || "AU"}
        {...(props as any)}
      />
    </Suspense>
  );
};

PhoneInput.displayName = "PhoneInput";

export { PhoneInput };
