import React from "react";
import { ReactComponent as BetIcon } from "./assets/bet.svg";
import { ReactComponent as CashIcon } from "./assets/cash.svg";
import { ReactComponent as CoinIcon } from "./assets/coin.svg";
import { ReactComponent as ErrorIcon } from "./assets/error.svg";
import { ReactComponent as MarketsIcon } from "./assets/markets.svg";
import { ReactComponent as BellIcon } from "./assets/bell.svg";
import { ReactComponent as TagIcon } from "./assets/tag.svg";
import { ReactComponent as ShieldIcon } from "./assets/shield.svg";

const getIconByType = (objectType: string): React.ReactNode => {
  switch (objectType) {
    case "PICKEMS_NEW_MARKETS":
    case "PICKEMS_MARKET_EXPIRING":
    case "PICKEMS_CONTEST_CANCELLED":
    case "PICKEMS_ADHOC":
    case "PICKEMS_PLACE":
      return <MarketsIcon />;
    case "BET_ROLLBACK":
    case "BET_CANCELLED":
    case "BET_LEG_VOIDED":
    case "BET_LEG_UNVOIDED":
      return <BetIcon />;
    case "VERIFICATION_SUCCESS":
    case "VERIFICATION_REMINDER":
    case "CLOSE_ACCOUNT":
    case "VERIFY_EMAIL":
    case "CREDIT_CARD_VERIFICATION_REQUIRED":
      return <ShieldIcon />;
    case "DEPOSIT":
    case "DEPOSIT_FAILED":
    case "ADMIN_DEPOSIT":
    case "WITHDRAWAL":
    case "WITHDRAWAL_PROCESSED":
    case "WITHDRAWAL_DECLINED":
    case "WITHDRAWAL_PARTIALLY_DECLINED":
    case "SKRILL_WITHDRAWAL_CANCELLED":
      return <CoinIcon />;
    case "PROMOTION_PAID":
      return <TagIcon />;
    case "PROMOTION_CLAIM_FAILED":
    case "PROMOTION_CLAIM_UNAUTHORIZED":
      return <ErrorIcon />;
    case "PICKEMS_WON_CONTEST":
    case "BET_WON":
    case "BET":
      return <CashIcon />;
    case "WELCOME":
    case "WELCOME_GEOBLOCK":
      return <BetIcon />;
    case "WELCOME_FANTASY":
      return <BellIcon />;
    case "WELCOME_BETTING":
      return <BetIcon />;
    default:
      return <BellIcon />;
  }
};

const getVariantByType = (
  type: string,
): "info" | "warning" | "danger" | "success" => {
  switch (type) {
    case "PICKEMS_ADHOC":
    case "WELCOME":
    case "WELCOME_BETTING":
      return "info";
    case "BET_ROLLBACK":
    case "BET_CANCELLED":
    case "BET_LEG_VOIDED":
    case "BET_LEG_UNVOIDED":
    case "CLOSE_ACCOUNT":
    case "VERIFY_EMAIL":
    case "CREDIT_CARD_VERIFICATION_REQUIRED":
    case "PICKEMS_NEW_MARKETS":
    case "PICKEMS_MARKET_EXPIRING":
    case "USER":
    case "VERIFICATION_REMINDER":
      return "warning";
    case "VERIFICATION_SUCCESS":
    case "ADMIN_DEPOSIT":
    case "DEPOSIT":
    case "WITHDRAWAL":
    case "WITHDRAWAL_PROCESSED":
    case "PROMOTION_PAID":
    case "PICKEMS_WON_CONTEST":
    case "BET_WON":
    case "BET":
    case "PICKEMS_CONTEST":
    case "WELCOME_GEOBLOCK":
    case "WELCOME_FANTASY":
      return "success";
    case "DEPOSIT_FAILED":
    case "WITHDRAWAL_DECLINED":
    case "WITHDRAWAL_PARTIALLY_DECLINED":
    case "SKRILL_WITHDRAWAL_CANCELLED":
    case "PICKEMS_CONTEST_CANCELLED":
    case "PROMOTION_CLAIM_FAILED":
    case "PROMOTION_CLAIM_UNAUTHORIZED":
      return "danger";
    default:
      return "info";
  }
};

export { getIconByType, getVariantByType };
