// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var countdownContainer = "RaceInfo-module--countdownContainer--479f9";
export var details = "RaceInfo-module--details--0e7fa";
export var emphasis = "RaceInfo-module--emphasis--a37ad";
export var eventName = "RaceInfo-module--eventName--2135a";
export var green = "#57d33b";
export var greenLink = "RaceInfo-module--green-link--fb585";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var raceInfo = "RaceInfo-module--raceInfo--6bd78";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";