import { useSelector } from "hooks";
import { useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import type { Card, ImageOnlyCard } from "store/Braze/BrazeContentCardTypes";

export const BRAZE_CARDS_CACHE_KEY_PREFIX = "brazeContentCards:";

export const useBrazeContentCards = () => {
  const cards = useSelector((state) => state.brazeContentCards.cards) || [];

  const userId = useSelector((state) => state.auth?.userId) ?? "anonymous";

  const [cachedCards, setCachedCards] = useLocalStorage<ImageOnlyCard[]>(
    `${BRAZE_CARDS_CACHE_KEY_PREFIX}${userId}`,
    [],
  );

  const brazeCards = useMemo(() => {
    // Use cachedCards if braze content cards aren't ready yet (these are set to localstorage in the reducer)
    const cardsToFilter = cards.length ? cards : cachedCards;
    return cardsToFilter.filter((card) => {
      if (card.isControl || card.extras?.type !== "banner") {
        return false;
      }
      const expirationDate = card.expiresAt ? new Date(card.expiresAt) : null;
      return !expirationDate || expirationDate > new Date();
    });
  }, [cards, cachedCards]);

  useEffect(() => {
    if (JSON.stringify(cachedCards) !== JSON.stringify(brazeCards)) {
      setCachedCards(brazeCards);
    }
  }, [brazeCards, cachedCards]);

  return brazeCards;
};
