import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAccountActions } from "hooks/firestore/user/useAccounts";
import { VerificationDocumentType } from "hooks";
import { navigate } from "library";
import { Typography } from "components/Typography";
import { Form } from "components/Form";
import { Link } from "components/Link";
import { Input } from "components/Input";
import { NativeSelect } from "components/NativeSelect";
import { DateInput } from "components/DateInput";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { getAllCountries } from "utilities/address";
import { FirebaseContext } from "context/Firebase";
import { userVerificationSchema } from "./schemas";
import { LEGAL_NAME } from "utilities/regex";

const codesWithoutAustralia = getAllCountries(true).filter(
  (country) => country.value !== "AU",
);

const schema = userVerificationSchema.shape({
  middleName: Yup.string().matches(LEGAL_NAME, "Invalid middle name"),
  number: Yup.string().required("Passport document number is required"),
  countryOfIssue: Yup.string().required("Country of issue is required"),
});

type FormValues = Yup.InferType<typeof schema>;

type AustralianVisaProps = {
  prefix?: string;
} & RouteComponentProps;

const AustralianVisa = ({ prefix = "/account" }: AustralianVisaProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { profile } = useContext(FirebaseContext);

  useEffect(() => {
    if (profile) {
      setValue("firstName", profile.firstName);
      setValue("lastName", profile.lastName);
      setValue("dateOfBirth", profile.dateOfBirth);
    }
  }, [profile]);

  const { doSubmitVerification } = useAccountActions();
  const consent = watch("consent", false);

  return (
    <>
      <Form
        onSubmit={handleSubmit(async ({ consent, ...fields }) => {
          const isSuccess = await doSubmitVerification({
            terms: consent,
            type: VerificationDocumentType.AUSTRALIAN_VISA,
            fields: {
              ...fields,
              number: fields.number.replace(/\s/g, ""),
            },
          });

          if (isSuccess) {
            navigate("/account/verification");
          }
        })}
        fixedWidth
      >
        <Typography variant="h4" noSpacing>
          Australian Visa
        </Typography>

        <Typography variant="body">
          Please enter your identity information, exactly as it appears on your
          Australian Visa (foreign passport).
        </Typography>

        <Typography variant={`body`}>
          Don’t have an Australian Visa? Select from a list of{" "}
          <Link to={`${prefix}/verification`}>Verification options</Link>.
        </Typography>

        <Input
          {...register("number")}
          label={`Passport Number`}
          errorMessage={errors?.number?.message}
          maxLength={14}
        />

        <NativeSelect
          {...register("countryOfIssue")}
          label={`Passport country of issue`}
          errorMessage={errors?.countryOfIssue?.message}
          options={codesWithoutAustralia}
        />

        <Input
          {...register("firstName")}
          label={`First Name`}
          errorMessage={errors?.firstName?.message}
        />

        <Input
          {...register("middleName")}
          label={`Middle Name`}
          errorMessage={errors?.middleName?.message}
        />

        <Input
          {...register("lastName")}
          label={`Last Name`}
          errorMessage={errors?.lastName?.message}
        />

        <Controller
          control={control}
          name={"dateOfBirth"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"dateOfBirth"}
              {...field}
              errorMessage={errors.dateOfBirth?.message}
              label={`Date of Birth`}
            />
          )}
        />

        <Controller
          control={control}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Checkbox
              id={`consent`}
              label={`I consent to Picklebet checking the information above with the
            issuer of Official Record Holder.`}
              checked={consent}
              onCheckedChange={(checked) => onChange?.(checked === true)}
              hasError={!!errors?.consent?.message}
              {...field}
            />
          )}
          name={`consent`}
        />

        <Button type={`submit`} loading={isSubmitting}>
          Verify Visa
        </Button>
      </Form>
    </>
  );
};

export { AustralianVisa };
