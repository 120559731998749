import React from "react";
import cx from "classnames";
import { Button } from "components/Button";
import type { ButtonProps } from "components/Button/Button";

import * as styles from "./IconButton.module.scss";

type IconButtonProps = { label?: string } & ButtonProps;

export const IconButton = ({ label, children, ...props }: IconButtonProps) => {
  return (
    <Button {...props} className={cx(styles.iconButton, props.className)}>
      <span
        className={cx({
          [styles.split]: !!label,
          [styles[props.variant || "primary"]]: !!label,
        })}
      >
        {children}
      </span>
      {label}
    </Button>
  );
};

export default IconButton;
