import * as React from "react";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { ReactComponent as CloseIcon } from "components/assets/close.svg";
import cx from "classnames";
import * as styles from "./Sheet.module.scss";
import { Typography } from "components/Typography";

const SIDE_DEFAULT = "bottom";

const sheetVariants = cva(styles.content, {
  variants: {
    side: {
      top: styles.top,
      right: styles.right,
      bottom: styles.bottom,
      left: styles.left,
    },
  },
  defaultVariants: {
    side: SIDE_DEFAULT,
  },
});

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

export type SheetProps = {
  icon?: React.ReactNode;
  title?: string;
  mainContent?: React.ReactNode;
  footer?: React.ReactNode;
  description?: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
} & SheetContentProps;

const Sheet = ({
  icon,
  title,
  description,
  onClose,
  children,
  isOpen,
  className,
  side,
}: SheetProps) => {
  const hasIcon = !!icon;

  return (
    <SheetPrimitive.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <SheetPrimitive.Portal>
        <SheetPrimitive.Overlay className={styles.overlay} />
        <SheetPrimitive.Content
          data-sheet-variant={side}
          className={cx(sheetVariants({ side }), className)}
        >
          {/* Header */}
          <div>
            <div className={styles.titleAndIcon}>
              {icon && <div className={styles.icon}>{icon}</div>}
              <SheetPrimitive.Title className={styles.title}>
                {title}
              </SheetPrimitive.Title>
            </div>
            <SheetPrimitive.Description asChild>
              <Typography
                className={cx(styles.description, {
                  [styles.hasIcon]: hasIcon,
                })}
                variant={`body`}
              >
                {description}
              </Typography>
            </SheetPrimitive.Description>
          </div>
          <div className={styles.children}>{children}</div>
          <SheetPrimitive.Close className={styles.closeButton}>
            <CloseIcon />
            <span className={styles.srOnly}>Close</span>
          </SheetPrimitive.Close>
        </SheetPrimitive.Content>
      </SheetPrimitive.Portal>
    </SheetPrimitive.Root>
  );
};

export { Sheet };
