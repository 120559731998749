// extracted by mini-css-extract-plugin
export var alignTop = "CompetitorSkeleton-module--alignTop--bb98d";
export var blue = "#0eb3f2";
export var box = "CompetitorSkeleton-module--box--cdca9";
export var clampMore = "CompetitorSkeleton-module--clampMore--47e07";
export var competitor = "CompetitorSkeleton-module--competitor--a45e8";
export var competitors = "CompetitorSkeleton-module--competitors--41ddd";
export var container = "CompetitorSkeleton-module--container--436b3";
export var desktop = "CompetitorSkeleton-module--desktop--7277d";
export var emphasis = "CompetitorSkeleton-module--emphasis--8822a";
export var flucsHistory = "CompetitorSkeleton-module--flucsHistory--5c9d2";
export var green = "#57d33b";
export var greenLink = "CompetitorSkeleton-module--green-link--0b123";
export var grey = "var(--tone-mid)";
export var info = "CompetitorSkeleton-module--info--cd3a0";
export var jockey = "CompetitorSkeleton-module--jockey--ecdb3";
export var mobile = "CompetitorSkeleton-module--mobile--b061f";
export var notchGap = "32px";
export var odds = "CompetitorSkeleton-module--odds--96e86";
export var oddsContainer = "CompetitorSkeleton-module--oddsContainer--e2bb8";
export var oddsLabel = "CompetitorSkeleton-module--oddsLabel--c6f6a";
export var orange = "#f8662b";
export var outcomes = "CompetitorSkeleton-module--outcomes--fd5e2";
export var red = "#ff2741";
export var runnerName = "CompetitorSkeleton-module--runnerName--37962";
export var silk = "CompetitorSkeleton-module--silk--b979a";
export var top4 = "CompetitorSkeleton-module--top4--408cc";
export var trainer = "CompetitorSkeleton-module--trainer--93453";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var win = "CompetitorSkeleton-module--win--af2a2";
export var yellow = "#ffb100";