import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  RaceMarketTypeTypes,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import { useDispatch, usePermissions } from "hooks";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";
import {
  getValidCompetitorCount,
  mapRaceToEvent,
} from "utilities/racingUtilities";
import { RaceContext } from "sections/Betting/Race";
import { Competitor } from "../Competitor";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import {
  type useRace,
  useRaceMarketsAndCompetitors,
} from "sections/Betting/Race/hooks/useRace";

import * as styles from "./CompetitorRow.module.scss";
import { PermissionReason } from "utilities/Auth/authSlice";
import type { RaceMeetingType } from "sections/Betting/Race/hooks/useRaceMeetings";
import type { RaceRouteParams } from "sections/Betting/Racing/hooks/useRaceRoute";

export enum OrderColumn {
  number = "number",
  win = "win",
  place = "place",
}

type RunnerProps = {
  eventId: string;
  marketId: string;
  outcomeId: string;
  isPlace: boolean;
  showResulted: boolean;
};

const Racer = ({ marketId, outcomeId, showResulted }: RunnerProps) => {
  const { markets } = useContext(RaceContext);
  const permissions = usePermissions();

  const marketsRequired = [RaceMarketTypeTypes.Win];

  if (permissions.submitPlaceBet === "GRANTED") {
    marketsRequired.push(RaceMarketTypeTypes.Place);
  }

  if (permissions.submitTop4Bet === "GRANTED") {
    marketsRequired.push(
      RaceMarketTypeTypes.Top2,
      RaceMarketTypeTypes.Top3,
      RaceMarketTypeTypes.Top4,
    );
  }

  const { mappedCompetitors } = useSortedCompetitors({
    marketsRequired,
    showResulted: showResulted,
  });

  const outcome = useMemo(
    () =>
      markets?.find((market) => market?.id === marketId)?.outcomes[outcomeId],
    [markets],
  );

  const mappedCompetitor = useMemo(
    () => mappedCompetitors?.find((x) => x.id === outcome?.competitorId),
    [mappedCompetitors],
  );

  const validCompetitorCount = getValidCompetitorCount(mappedCompetitors);

  if (!markets) {
    return null;
  }

  return (
    <>
      <div className={styles.competitor}>
        {outcome && mappedCompetitor ? (
          <Competitor
            competitor={mappedCompetitor}
            validCompetitorCount={validCompetitorCount}
            showPlaceOnMultiOutcome={true}
            isWinPlace={false}
          />
        ) : (
          mappedCompetitors.map((competitor) => (
            <Competitor
              key={competitor.id}
              competitor={competitor}
              validCompetitorCount={validCompetitorCount}
              showPlaceOnMultiOutcome={true}
              isWinPlace={false}
            />
          ))
        )}
      </div>
    </>
  );
};

export const AddRaceContextData = (Component: any) => {
  return (props: {
    eventId: string;
    marketId: string;
    outcomeId: string;
    isPlace: boolean;
    addToSlip: boolean;
    showCompetitorDetails: boolean;
  }) => {
    const dispatch = useDispatch();
    const permissions = usePermissions();
    const [addedToBetSlip, setAddedToBetSlip] = useState(false);
    const { race, competitors, markets, raceLoading } =
      useRaceMarketsAndCompetitors(props.eventId);

    const marketType = markets?.find(
      (market) => market.id === props.marketId,
    )?.marketType;

    const isMarketBlocked =
      (permissions?.submitTop4Bet !== PermissionReason.GRANTED &&
        (marketType === RaceMarketTypeTypes.Top4 ||
          marketType === RaceMarketTypeTypes.Top3 ||
          marketType === RaceMarketTypeTypes.Top2)) ||
      (permissions?.submitPlaceBet !== PermissionReason.GRANTED &&
        marketType === RaceMarketTypeTypes.Place) ||
      (permissions?.submitInternationalRacingBet !== PermissionReason.GRANTED &&
        race?.region === "ROW");

    useEffect(() => {
      if (
        !raceLoading &&
        !addedToBetSlip &&
        props.addToSlip &&
        race?.status === RaceStatuses.OPEN
      ) {
        if (
          !raceLoading &&
          markets.length > 0 &&
          competitors.length > 0 &&
          !isMarketBlocked
        ) {
          handleToggleSelection(
            props.outcomeId,
            props.marketId,
            markets?.find((market) => market.id === props.marketId)?.outcomes?.[
              props.outcomeId
            ]?.odds,
          );
          setAddedToBetSlip(true);
        }
      }
    }, [
      raceLoading,
      markets,
      competitors,
      props.addToSlip,
      addedToBetSlip,
      race?.status,
      props.outcomeId,
    ]);

    const handleToggleSelection = (
      outcomeId: string,
      marketId: string,
      odds: string,
    ) => {
      dispatch(
        toggleSelection(
          mapRaceToEvent({ event: race, competitors, markets }),
          {
            type: "race",
            outcomeId: outcomeId,
            marketId: marketId,
            sourceId: props.eventId,
            eventId: props.eventId,
            odds: Number(odds),
            attributes: {
              raceNumber: race?.number?.toString(),
              region: race?.region,
            },
          },
          "racing",
        ),
      );
    };

    if (raceLoading || !props.showCompetitorDetails) return null;

    return (
      <RaceContext.Provider
        value={
          {
            race,
            competitors,
            markets,
            toggleRace: handleToggleSelection,
            raceMeeting: { sport: race.sport } as RaceMeetingType,
            selectedPills: [],
            route: {
              raceId: props.eventId,
              tab: "win-place",
            } as RaceRouteParams,
          } as ReturnType<typeof useRace>
        }
      >
        <Component {...props} showResulted={race.status !== "OPEN"} />
      </RaceContext.Provider>
    );
  };
};

export default AddRaceContextData(Racer);
