import { useEffect, useState } from "react";

const useHasSmartBannerScriptLoaded = (): boolean => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const checkSmartBannerScriptExists = () => {
    const smartBannerScript =
      typeof document !== "undefined"
        ? document.getElementById("appsflyer-smart-banner-script")
        : null;

    return smartBannerScript !== null;
  };

  useEffect(() => {
    // on a fresh load of the website this hook isn't re-rendered at a point when the appsflyer smart banner script has loaded, so we poll until it exists
    const interval = setInterval(() => {
      const smartBannerScriptExists = checkSmartBannerScriptExists();

      if (smartBannerScriptExists) {
        setScriptLoaded(true);
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return scriptLoaded;
};

export default useHasSmartBannerScriptLoaded;
