import { Skeleton } from "components/Skeleton";
import React from "react";
import * as originalStyles from "./RaceFilters.module.scss";
import * as styles from "./RaceFiltersSkeleton.module.scss";

export const RaceFiltersSkeleton = () => {
  return (
    <div className={originalStyles.container}>
      <div className={originalStyles.pills}>
        <Skeleton shape="rect" size="sm" mode="light" className={styles.tips} />
        <Skeleton
          shape="rect"
          size="sm"
          mode="light"
          className={styles.speedMap}
        />
        <Skeleton
          shape="rect"
          size="sm"
          mode="light"
          className={styles.flucs}
        />
      </div>
      <Skeleton shape="rect" size="sm" mode="light" className={styles.sort} />
    </div>
  );
};
