// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var drawer = "Drawer-module--drawer--5f4ed";
export var drawerContainer = "Drawer-module--drawerContainer--2b16b";
export var drawerLine = "Drawer-module--drawerLine--ba12b";
export var drawerLineLabel = "Drawer-module--drawerLineLabel--0beee";
export var drawerLineValue = "Drawer-module--drawerLineValue--bc859";
export var emphasis = "Drawer-module--emphasis--aa729";
export var green = "#57d33b";
export var greenLink = "Drawer-module--green-link--53092";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";