import React, { useContext } from "react";
import cx from "classnames";
import {
  type RaceCompetitorType,
  RaceMarketTypeTypes,
  type RaceOutcomeType,
  RaceOutcomeTypeTypes,
} from "sections/Betting/Race/hooks/RacingTypes";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import { Competitor } from "sections/Betting/Race/components/Competitor";
import { RaceContext } from "sections/Betting/Race";
import * as styles from "./Top4.module.scss";
import { RaceMessage } from "sections/Betting/Race/components/RaceMessage";
import { RaceFilters } from "sections/Betting/Race/components/RaceFilters";
import SPMessage from "sections/Betting/Race/components/SPMessage/SPMessage";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { RaceTips } from "sections/Betting/Race/components/RaceTips";
import { CampaignPromotions } from "sections/Betting/CampaignPromotions";
import { BettingTabs } from "hooks";
import CompetitorSkeleton from "sections/Betting/Race/components/Competitor/CompetitorSkeleton";
import { RaceCustomMarkets } from "components/RaceCustomMarkets";

export const sameRaceMultiLabelMap = {
  [RaceOutcomeTypeTypes.Win]: "Win",
  [RaceOutcomeTypeTypes.Top2]: "Top 2",
  [RaceOutcomeTypeTypes.Top3]: "Top 3",
  [RaceOutcomeTypeTypes.Top4]: "Top 4",
};

const marketsRequired = [
  RaceMarketTypeTypes.Win,
  RaceMarketTypeTypes.Top2,
  RaceMarketTypeTypes.Top3,
  RaceMarketTypeTypes.Top4,
];

export type WinPlaceCompetitorType = RaceCompetitorType &
  Partial<RaceOutcomeType>;

export const Top4 = ({
  onlyScratchings = false,
  showResulted = false,
}: RouteComponentProps<{
  onlyScratchings?: boolean;
  showResulted?: boolean;
}>) => {
  const { race, route, loading, raceMeetingLoading, races, campaigns } =
    useContext(RaceContext);

  const baseRace = races.find((baseRace) => baseRace.id === route.raceId);

  const { mappedCompetitors, isSP, validCompetitorCount } =
    useSortedCompetitors({
      marketsRequired,
      showResulted,
    });

  const filteredCompetitors = mappedCompetitors.filter((x) =>
    onlyScratchings ? x.scratched : true,
  );

  return (
    <>
      {!onlyScratchings && (
        <>
          <CampaignPromotions
            tab={
              route.tab === "same-race-multi"
                ? BettingTabs.RACING_SEM
                : BettingTabs.RACING_SINGLES
            }
            campaigns={campaigns}
            loading={loading && baseRace?.promotionIsAvailable}
          />
          <RaceFilters />
          <RaceTips />
          <RaceCustomMarkets />
        </>
      )}
      <div className={cx(styles.content)}>
        {loading || raceMeetingLoading ? (
          <CompetitorSkeleton displayType="Top4" />
        ) : (
          <>
            {filteredCompetitors?.map((competitor) => (
              <Competitor
                key={race?.number + competitor?.id}
                competitor={competitor}
                validCompetitorCount={validCompetitorCount}
                isWinPlace={false}
              />
            ))}
          </>
        )}
        <SPMessage className={styles.alert} isSP={isSP} />
        {!showResulted && <RaceMessage className={styles.alert} />}
      </div>
    </>
  );
};
