import React from "react";
import { useHasPermission } from "hooks";

import type { Permission } from "utilities/Auth/authSlice";

type PermissionsGateProps = {
  permissions: Permission[];
  children: React.ReactNode;
};

const PermissionsGate = ({ permissions, children }: PermissionsGateProps) => {
  const hasPermissions = useHasPermission(permissions);

  return hasPermissions ? <>{children}</> : null;
};

export { PermissionsGate };
