import React, { useCallback } from "react";
import { ButtonGroup, PermissionsRequired } from "library";

import { Helmet } from "react-helmet";
import { RaceMeetingDropDown } from "sections/Betting/components";
import { sortRacesByStatus } from "utilities/racingUtilities";
import { Page } from "components/Page";
import {
  RowTemplate,
  RaceRow,
} from "sections/Betting/Racing/components/RaceRow";
import { Box } from "components/Box";
import { useLocation } from "hooks/useLocation";
import { useRaceMeeting } from "sections/Betting/Race/hooks/useRaceMeeting";
import { NextToJumpSkeleton } from "sections/Betting/Racing/components/RaceRow/RaceRowsSkeleton";
import { Skeleton } from "components/Skeleton";
import * as styles from "./RaceMeeting.module.scss";
import { ReactComponent as RaceError } from "components/assets/racing-error.svg";
import { PageMessage } from "components/PageMessage";
import { LinkButton } from "components/LinkButton";
import { useIsMobile } from "hooks";
import { EventBlocked } from "sections/Betting/components/EventBlocked";

type RaceMeetingProps = {
  id?: string;
  titles: Record<any, any>;
  path?: string;
};

const RaceMeetingSkeleton = () => {
  return (
    <NextToJumpSkeleton
      count={7}
      icon={
        <Skeleton mode="light" shape="circle" className={styles.skeletonIcon} />
      }
      secondaryIcon={<> </>}
      longTitle
    />
  );
};

export default ({ id }: RaceMeetingProps) => {
  const [raceMeeting, races, loading] = useRaceMeeting(id);
  const isMobile = useIsMobile();

  const sortRaces = useCallback(sortRacesByStatus, [races]);

  if (!loading && !raceMeeting) {
    return (
      <PageMessage
        title="Race Meeting not found"
        image={<RaceError />}
        subTitle="There are currently no race meetings which match your filters"
      >
        <ButtonGroup>
          <LinkButton
            variant={`primary`}
            size={isMobile ? `md` : `default`}
            to="/racing/betting/"
          >
            Next to Jump
          </LinkButton>
        </ButtonGroup>
      </PageMessage>
    );
  }

  return (
    <EventBlocked filters={raceMeeting?.filters} loading={loading}>
      <PermissionsRequired
        permissionsRequired={[
          "viewRacingMarkets",
          "viewBettingMarkets",
          ...(raceMeeting?.region === "ANZ" || loading
            ? []
            : ["submitInternationalRacingBet"]),
        ]}
        skeleton={<RaceMeetingSkeleton />}
      >
        <Page
          variant={`full`}
          title={
            <RaceMeetingDropDown
              raceMeeting={
                raceMeeting ? { ...raceMeeting, linkTo: "meeting" } : null
              }
            />
          }
        >
          {loading ? (
            <RaceMeetingSkeleton />
          ) : (
            <>
              <Box hideHeader>
                {(races || []).sort(sortRaces).map((race) => (
                  <RaceRow
                    key={race.id}
                    {...race}
                    template={RowTemplate.RACE_MEETING}
                  />
                ))}
              </Box>
              <RaceMeetingSEO venue={raceMeeting?.venue} />
            </>
          )}
        </Page>
      </PermissionsRequired>
    </EventBlocked>
  );
};

const RaceMeetingSEO = ({ venue }: { venue: string }) => {
  const description =
    "Picklebet offers sports betting, including Racing betting. Place Racing bets with Australia's Picklebet now.";
  const location = useLocation();

  return (
    <Helmet>
      <link rel="canonical" href={`${location.origin}${location.pathname}`} />
      <title>{`${venue} | Racing, Sports and Esports Betting`}</title>
      <meta
        name="og:title"
        content={`${venue} | Racing, Sports and Esports Betting`}
      />
      <meta name="description" content={description} />
    </Helmet>
  );
};
