// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var dark = "Pacman-module--dark--2f0f8";
export var emphasis = "Pacman-module--emphasis--1b2c6";
export var green = "#57d33b";
export var greenLink = "Pacman-module--green-link--32675";
export var grey = "var(--tone-mid)";
export var light = "Pacman-module--light--bc074";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pacman = "Pacman-module--pacman--82b36";
export var pacmanBalls = "Pacman-module--pacman-balls--9db2c";
export var red = "#ff2741";
export var rotate_pacman_half_down = "Pacman-module--rotate_pacman_half_down--dbf12";
export var rotate_pacman_half_up = "Pacman-module--rotate_pacman_half_up--6c58a";
export var sizeDefault = "Pacman-module--sizeDefault--fb2fb";
export var sizeSm = "Pacman-module--sizeSm--f6144";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";