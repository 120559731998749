import React from "react";
import { useBasicBetSelection } from "hooks";
import { MultiOutcome } from "./MultiOutcome";
import { SingleOutcome } from "./SingleOutcome";
import { useUpdateSelectionOddsAndStatus } from "sections/Betting/Betslip/hooks/useSelection";

type OutcomeProps = {
  outcomeId: string;
  isLast: boolean;
};

const Outcome = ({ outcomeId, isLast }: OutcomeProps) => {
  const { selection } = useBasicBetSelection(outcomeId);
  useUpdateSelectionOddsAndStatus(outcomeId);

  if (selection.subOutcomes) {
    return <MultiOutcome outcomeId={outcomeId} isLast={isLast} />;
  }

  return <SingleOutcome outcomeId={outcomeId} isLast={isLast} />;
};

export { Outcome };
