import React from "react";
import { AnimatePresence, type HTMLMotionProps, motion } from "framer-motion";

type AnimatedListProps<T> = {
  list: T[];
  isAnimated: boolean;
  renderItem: (item: T, index: number) => React.ReactNode;
};

const getFramerProps = (shouldAnimate: boolean) => {
  if (shouldAnimate) {
    return {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      transition: { duration: 0.15 },
    } satisfies HTMLMotionProps<"div">;
  }
};

const AnimatedList = <T extends {}>({
  list: items,
  renderItem,
  isAnimated = true,
}: AnimatedListProps<T>) => {
  const Wrapper = isAnimated ? motion.div : "div";

  return (
    <AnimatePresence mode="popLayout">
      {items.map((item, index) => (
        <Wrapper key={index} {...getFramerProps(isAnimated)}>
          {renderItem(item, index)}
        </Wrapper>
      ))}
    </AnimatePresence>
  );
};

export default AnimatedList;
