import { actionTypeToString } from "./typeToString";
import { trackEvent } from "@redux-beacon/segment";

export default trackEvent((action, _, nextState) => {
  if (typeof action?.payload === "string") {
    return {
      name: actionTypeToString(action.type),
      properties: {
        result: action.payload,
        userId: nextState?.auth?.userId,
      },
    };
  } else {
    return {
      name: actionTypeToString(action.type),
      properties: {
        ...action.payload,
        value: action?.payload?.amount,
        userId: nextState?.auth?.userId,
      },
    };
  }
});
