const BASE = "PICKEMS/";

// Segment Actions

// User submits selections for first time in a contest creating their entry
export const ENTRY_SUBMITTED = BASE + "ENTRY_SUBMITTED";

// User submits an update to their selections for an existing entry in a contest
export const ENTRY_UPDATED = BASE + "ENTRY_UPDATED";

export const pickemsPageTitle =
  "Play Pick'ems | Racing, Sports and Esports Betting";
export const pickemsPageDescription =
  "Pick winning teams from LoL, Dota2, and CS GO matches. Compete against the community, casters, and pros from around the world, to win the loot!";
