import React, { useContext, useEffect } from "react";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import * as Yup from "yup";
import { isFuture, isValid, parseISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerificationDocumentType } from "hooks";
import { navigate } from "library";
import { useAccountActions } from "hooks/firestore/user/useAccounts";
import { Form } from "components/Form";
import { Typography } from "components/Typography";
import { Link } from "components/Link";
import { Input } from "components/Input";
import { DateInput } from "components/DateInput";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { FirebaseContext } from "context/Firebase";
import { userVerificationSchema } from "./schemas";
import { LEGAL_NAME } from "utilities/regex";

const schema = userVerificationSchema.shape({
  middleName: Yup.string().matches(LEGAL_NAME, "Invalid middle name"),
  number: Yup.string()
    .required("Passport document number is required")
    .min(7, "Valid Passport number is required")
    .max(9, "Valid Passport number is required"),
  expiryDate: Yup.string().test(
    "expiry",
    "Your expiry date is invalid or passport has expired",
    (dob) => {
      const date = parseISO(dob);
      return isValid(date) && isFuture(date);
    },
  ),
});

type FormValues = Yup.InferType<typeof schema>;

type NewZealandPassportProps = {
  prefix?: string;
} & RouteComponentProps;

const NewZealandPassport = ({ prefix }: NewZealandPassportProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { profile } = useContext(FirebaseContext);

  useEffect(() => {
    if (profile) {
      setValue("firstName", profile.firstName);
      setValue("lastName", profile.lastName);
      setValue("dateOfBirth", profile.dateOfBirth);
    }
  }, [profile]);

  const { doSubmitVerification } = useAccountActions();
  const consent = watch("consent", false);

  return (
    <>
      <Form
        onSubmit={handleSubmit(async ({ consent, ...fields }) => {
          const isSuccess = await doSubmitVerification({
            terms: consent,
            type: VerificationDocumentType.NEW_ZEALAND_PASSPORT,
            fields,
          });

          if (isSuccess) {
            navigate("/account/verification");
          }
        })}
        fixedWidth
      >
        <Typography variant="h4" noSpacing>
          New Zealand Passport
        </Typography>

        <Typography variant="body">
          Please enter your identity information, exactly as it appears on your
          New Zealand Passport.
        </Typography>

        <Typography variant={`body`}>
          Don’t have a Passport? Select from a list of{" "}
          <Link to={`${prefix}/verification`}>Verification options</Link>.
        </Typography>

        <Input
          {...register("number")}
          label={`Passport Number`}
          errorMessage={errors?.number?.message}
        />

        <Controller
          control={control}
          name={"expiryDate"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"expiryDate"}
              {...field}
              errorMessage={errors.expiryDate?.message}
              label={`Expiry Date`}
            />
          )}
        />

        <Input
          {...register("firstName")}
          label={`First Name`}
          errorMessage={errors?.firstName?.message}
        />

        <Input
          {...register("middleName")}
          label={`Middle Name`}
          errorMessage={errors?.middleName?.message}
        />

        <Input
          {...register("lastName")}
          label={`Last Name`}
          errorMessage={errors?.lastName?.message}
        />

        <Controller
          control={control}
          name={"dateOfBirth"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"dateOfBirth"}
              {...field}
              errorMessage={errors.dateOfBirth?.message}
              label={`Date of Birth`}
            />
          )}
        />

        <Controller
          control={control}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Checkbox
              id={`consent`}
              label={`I consent to Picklebet checking the information above with the
            issuer of Official Record Holder.`}
              checked={consent}
              onCheckedChange={(checked) => onChange?.(checked === true)}
              hasError={!!errors?.consent?.message}
              {...field}
            />
          )}
          name={`consent`}
        />

        <Button type={`submit`} loading={isSubmitting}>
          Verify Passport
        </Button>
      </Form>
    </>
  );
};

export { NewZealandPassport };
