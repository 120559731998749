import React from "react";
import { UnauthorizedAlerts } from "./UnauthorizedAlerts";
import { useBetslip, useIsMobile, usePermissions } from "hooks";
import {
  BetType,
  clearBetslipMessage,
  clearSelections,
  convertSelectionsToSRM,
  setBetType,
} from "sections/Betting/Betslip/betslipSlice";
import { Alert } from "./Alert";
import { useTranslation } from "react-i18next";
import { Clamp } from "components/Clamp";
import { useDispatch } from "hooks";
import { PermissionReason } from "utilities/Auth/authSlice";
import { navigate } from "library";
import { v4 } from "uuid";
import { setMenu } from "utilities/UI/uiSlice";

import * as styles from "./SingleCardAlert.module.scss";

const GlobalAlerts = () => {
  const {
    props: {
      betType,
      selectionsCount,
      semTypeIfSingleBetAndOnMultiTab,
      hasExoticMarkets,
      betslipMessage,
      canBeSRM,
      raceUrl,
      isAlmostSRM,
    },
  } = useBetslip();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMulti = betType === BetType.Multi;
  const { submitSRMBet } = usePermissions();
  const srmAllowed = submitSRMBet === PermissionReason.GRANTED;
  const isMobile = useIsMobile();

  // we need more multi legs to place a bet if matches following condition
  const requiresMoreSelections = selectionsCount <= 1 && isMulti;

  return (
    <>
      <UnauthorizedAlerts />
      {requiresMoreSelections && (
        <Alert variant={`warning`}>
          {t("Make 2+ valid selections to submit a Multi")}
        </Alert>
      )}
      {!hasExoticMarkets &&
      requiresMoreSelections &&
      semTypeIfSingleBetAndOnMultiTab ? (
        <Alert
          variant={`info`}
          clamp={
            <Clamp
              className={styles.clickable}
              variant={`info`}
              onClick={() => dispatch(setBetType(BetType.Single))}
            >
              Yes
            </Clamp>
          }
        >
          {`Trying to place this ${
            semTypeIfSingleBetAndOnMultiTab === "race" ? "SRM" : "SGM"
          } now?`}
        </Alert>
      ) : null}
      {betslipMessage && (
        <Alert
          variant={`danger`}
          clamp={
            <Clamp
              className={styles.clickable}
              variant={`danger`}
              onClick={() => dispatch(clearBetslipMessage())}
            >
              Got it
            </Clamp>
          }
        >
          {betslipMessage}
        </Alert>
      )}
      {selectionsCount > 1 && isAlmostSRM && !canBeSRM && srmAllowed ? (
        <Alert
          variant={`info`}
          clamp={
            <Clamp
              variant={`info`}
              className={styles.clickable}
              onClick={() => {
                dispatch(clearSelections());
                dispatch(setBetType(BetType.Single));
                navigate(raceUrl);
                if (!isMobile) {
                  dispatch(setMenu("right"));
                }
              }}
            >
              Build
            </Clamp>
          }
        >
          Are you trying to build a Same Race Multi?
        </Alert>
      ) : null}
      {canBeSRM && selectionsCount > 1 && srmAllowed ? (
        <Alert
          variant={`info`}
          clamp={
            <Clamp
              variant={`info`}
              className={styles.clickable}
              onClick={() => {
                navigate(raceUrl);
                dispatch(convertSelectionsToSRM(v4()));
                dispatch(setMenu("right"));
              }}
            >
              Create SRM
            </Clamp>
          }
        >
          Create a Same Race Multi using your selections
        </Alert>
      ) : null}
    </>
  );
};

export { GlobalAlerts };
