// extracted by mini-css-extract-plugin
export var active = "RaceCustomMarkets-module--active--fe2ea";
export var arrowDown = "RaceCustomMarkets-module--arrowDown--13233";
export var arrowUp = "RaceCustomMarkets-module--arrowUp--aef5b";
export var blue = "#0eb3f2";
export var content = "RaceCustomMarkets-module--content--b71ee";
export var disabled = "RaceCustomMarkets-module--disabled--8f6b6";
export var emphasis = "RaceCustomMarkets-module--emphasis--0e856";
export var green = "#57d33b";
export var greenLink = "RaceCustomMarkets-module--green-link--639a4";
export var grey = "var(--tone-mid)";
export var heading = "RaceCustomMarkets-module--heading--756e9";
export var label = "RaceCustomMarkets-module--label--c1ae2";
export var notchGap = "32px";
export var odds = "RaceCustomMarkets-module--odds--82d2e";
export var oddsContainer = "RaceCustomMarkets-module--oddsContainer--6f9e6";
export var orange = "#f8662b";
export var outcome = "RaceCustomMarkets-module--outcome--82fe6";
export var outcomes = "RaceCustomMarkets-module--outcomes--ab49e";
export var red = "#ff2741";
export var subTitle = "RaceCustomMarkets-module--subTitle--895ea";
export var title = "RaceCustomMarkets-module--title--8ca79";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";