import type {
  IBetPickPickems,
  IPickemsContestStats,
  PickemsStatus,
} from "types/PickemsTypes";
import has from "lodash/has";

import type { IBetPick, IMarket, IMatchEvent } from "types/BetTypes";
import { FireTransforms } from "utilities/FireTransforms";
import { BettingTransforms } from "./BettingTransforms";
import { titleCase } from "title-case";
import type { ButtonType } from "library/components/ButtonWithState/Button";
import type { PickemsContest } from "hooks/firestore/pickems/types";
import type { PickType } from "sections/Entries/types";
import type { Titles } from "hooks/graphql/useTitles";

export const getPlayAction = (
  contest: PickemsContest,
  entries?: any[],
): string => {
  if (contest.marketCounts.active < 1) {
    return "View Contest";
  }
  const contestEntries =
    entries?.filter((x) => x.contestId === contest.id) ?? [];
  if (
    contestEntries.length > 0 &&
    contestEntries.length < contest.marketCounts.total
  ) {
    return "Revise Picks";
  }
  return "Select Picks";
};

export const getButtonType = (contest: PickemsContest): ButtonType => {
  switch (contest?.status) {
    case "PENDING":
      return "success";
    default:
      return "primary";
  }
};

export const isContestDisabled = (contest: PickemsContest): boolean => {
  switch (contest?.status) {
    case "PENDING":
    case "LIVE":
      return false;
    default:
      return true;
  }
};

export const getContestLink = (contestId: string, tab = "pick") => {
  return `/pickems/contest/${contestId}/${tab}/`;
};

export const getPlayLink = (contestId: string) => {
  return getContestLink(contestId, "pick");
};

export const getLadderLink = (contestId: string) => {
  return getContestLink(contestId, "results");
};

export const getPlayerCount = (contest: PickemsContest) => {
  return contest?.entries?.total || 0;
};

export const getEntryFee = () => {
  return "FREE";
};

export const getWinnings = (contest: PickemsContest) => {
  return "US$" + Math.round(contest.prizePool[0] / 100);
};

export const getMarketsFromContest = (contest: PickemsContest) => {
  if (!contest.markets) {
    return [];
  }
  const markets: IMarket[] = contest.markets.slice();
  markets.sort((a, b) =>
    a.event.scheduledStartTime < b.event.scheduledStartTime ? -1 : 1,
  );
  return markets;
};

export const getStartTime = (contest: PickemsContest): Date => {
  const markets = getMarketsFromContest(contest);
  return markets[0]?.event?.scheduledStartTime;
};

export const parseOutcome = (data: Record<string, any>): Outcome => {
  if (!data) {
    return null;
  }
  return {
    ...data,
    odds: data.points,
    oddsFormat: "points",
  } as Outcome;
};

export const parseMarket = (data: Record<string, any>): IMarket => {
  if (!data) {
    return null;
  }
  const event = BettingTransforms.parseEvent(data.event);
  const outcomes = FireTransforms.unwrapSet(data.outcomes, parseOutcome);
  return {
    ...data,
    id: data.id,
    event,
    eventReferenceId: data.eventReferenceId,
    type: data.marketType,
    outcomes,
  } as IMarket;
};

export const sortMarket = (a: IMarket, b: IMarket) => {
  return a?.event?.scheduledStartTime < b?.event?.scheduledStartTime ? -1 : 1;
};

export const parseContest = (data: Record<string, any>): PickemsContest => {
  if (!data) {
    return null;
  }
  const markets = FireTransforms.unwrapSet<IMarket>(data.markets, parseMarket);
  markets.sort((a, b) => sortMarket(a, b));
  const scheduledStartTime = FireTransforms.parseDate(data.scheduledStartTime);
  data.createdAt = FireTransforms.parseDate(data.createdAt);
  data.markets = markets;
  data.scheduledStartTime = scheduledStartTime;
  data.contestNumber = Number(data.contestNumber);

  return data as PickemsContest;
};

export const parseContests = (data: Record<string, any>): PickemsContest[] => {
  const contests = FireTransforms.unwrapSet<PickemsContest>(data, parseContest);
  sortContests(contests);
  return contests;
};

export const isStatusDecided = (status: string) => {
  status = (status || "").toUpperCase();
  switch (status) {
    case "SETTLED":
    case "REJECTED":
    case "CANCELLED":
    case "VOID":
      return true;
  }
  return false;
};

export const parseContestStats = (
  contest: PickemsContest,
  selections: PickType[] = [],
  picks: IBetPickPickems[] = [],
): IPickemsContestStats => {
  const pendingPicks = picks.filter(
    (pick) =>
      !selections.find((selection) => pick.marketId === selection.eventId),
  );
  const marketCount = contest?.marketCounts?.total || 0;
  const pendingPickCount = pendingPicks.length;
  const remainingPickCount = pendingPicks.length + selections.length;
  return {
    contest,
    picks,
    pendingPicks,
    selections,
    marketCount,
    remainingPickCount,
    pendingPickCount,
    pointsEntered: parsePointsEntered(contest, selections, picks),
  };
};

export const parseContestStatus = (status: PickemsStatus) => {
  switch (status) {
    case "PENDING":
      return "UPCOMING";
  }
  return status as string;
};

export const parsePointsEntered = (
  contest: PickemsContest,
  selections: PickType[] = [],
  picks: IBetPickPickems[] = [],
) => {
  let result = 0;
  for (const selection of selections) {
    const newPick = picks.find(
      (x) => x.marketId === selection.marketId && x.sourceId === contest?.id,
    );
    if (!newPick?.odds) {
      result += Number(selection.odds) || 0;
    }
  }
  picks = picks.filter((x) => x.sourceId === contest?.id);
  for (const pick of picks) {
    result += Number(pick.odds) || 0;
  }
  return result;
};

export const parseEntry = (value: any, contestId: string) => {
  const [id, entry] = Object.entries(value)[0] as [string, IPickemsEntry];
  return {
    id,
    contestId,
    ...entry,
    createdAt: FireTransforms.parseDate(entry.createdAt),
    decided: isStatusDecided(entry?.status),
    payOut: entry?.paidOut || entry?.potentialPayout || 0,
  } as IPickemsEntry;
};

export const parseEntries = (data: Record<string, any>) => {
  return FireTransforms.unwrapSet<IPickemsEntry>(data, (value, contestId) => {
    return parseEntry(value, contestId);
  });
};

const sortContests = (contests: PickemsContest[]) => {
  contests.sort((a, b) =>
    a.scheduledStartTime < b.scheduledStartTime ? -1 : 1,
  );
  return contests;
};

export const mergeContestLists = (...contestLists: PickemsContest[][]) => {
  const result: PickemsContest[] = [];
  for (const contestList of contestLists) {
    for (const contest of contestList) {
      if (!result.find((x) => x.id === contest.id)) {
        result.push(contest);
      }
    }
  }
  sortContests(result);
  return result;
};

export const flatFilter = (
  data: any,
  op: (value: any, key: string) => boolean,
) => {
  const result: Record<string, any> = {};
  if (data && typeof data === "object") {
    for (const key in data) {
      if (has(data, key) === false) {
        continue;
      }
      const value = data[key];
      if (op(value, key)) {
        result[key] = value;
      } else if (typeof value === "object") {
        const subResult = flatFilter(value, op);
        if (subResult) {
          for (const subKey in subResult) {
            if (!has(subResult, subKey)) {
              continue;
            }
            result[subKey] = subResult[subKey];
          }
        }
      }
    }
  }
  return result;
};

export const flatFind = (
  data: any,
  op: (value: any, key: string) => boolean,
) => {
  return Object.values(flatFilter(data, op)[0] || []) || null;
};

export const getPickDetails = (pick: IBetPick, contest: PickemsContest) => {
  const market = contest?.markets.find((market) => market.id === pick.marketId);
  const outcome = market?.outcomes[pick.outcomeId];
  const marketName =
    (market?.event?.sport || "") + (market?.event?.eventName || "");
  const selectionName = outcome?.competitor?.name || titleCase(outcome?.type);
  const details: IBetPickPickems = {
    ...pick,
    contest,
    competitor: outcome?.competitor,
    eventName: market?.event.eventName,
    freshOdds: outcome?.odds,
    lockedOdds: pick?.odds,
    selectionName,
    marketName,
    accepted: false,
    scheduledStartTime: market?.event.scheduledStartTime,
    outcome,
  };
  return details;
};

export const getPrizePlaceDescription = (contest: PickemsContest) => {
  const count = contest?.prizePool?.length || 0;
  return count > 3
    ? "prizes for " + count + " places"
    : count === 3
      ? "prizes for first, second and third place"
      : count === 2
        ? "prizes for first and second place"
        : count === 1
          ? "prizes for first place only"
          : "no prizes";
};

export const getContestFrequencyDescription = () => {
  return "throughout the week";
};

export const getLogos = (sports: string[], titles: Titles) => {
  return (
    sports
      ?.map((x) => titles?.[x.toLowerCase()]?.logo?.file?.url)
      .filter(Boolean) || []
  );
};

export const getHeroes = (sports: string[], titles: Titles) => {
  return (
    sports
      ?.map((x) => titles?.[x.toLowerCase()]?.hero?.gatsbyImageData)
      .filter(Boolean) || []
  );
};

export const totalPrizePoolAsDouble = (values: number[]) => {
  let result = 0;
  if (values?.length) {
    for (const p of values) {
      result += p;
    }
    result /= 100;
  }
  return result;
};

export const totalPrizePool = (values: number[]) => {
  const double = totalPrizePoolAsDouble(values);
  return double ? "US$" + double : "-";
};

export const isContestOver = (status: PickemsStatus) => {
  switch (status) {
    case "COMPLETED":
    case "HISTORICAL":
    case "CANCELLED":
      return true;
  }
  return false;
};

export const isContestActive = (status: PickemsStatus) => {
  switch (status) {
    case "PENDING":
    case "LIVE":
      return true;
  }
  return false;
};

export const isPickAvailable = (market: IMarket, event: IMatchEvent) => {
  if (BettingTransforms.marketIsActive(market, "PICKEMS") === false) {
    return false;
  }
  const start = FireTransforms.parseDate(
    market?.nextBetStop || event?.scheduledStartTime,
  );
  return start > new Date();
};

export const PickemsTransforms = {
  getButtonType,
  getContestLink,
  getContestFrequencyDescription,
  getEntryFee,
  getHeroes,
  getLadderLink,
  getLogos,
  getMarketsFromContest,
  getPlayAction,
  getPlayLink,
  getPlayerCount,
  getPrizePlaceDescription,
  getStartTime,
  getWinnings,
  isContestActive,
  isContestDisabled,
  isContestOver,
  mergeContestLists,
  parseContest,
  parseContests,
  parseContestStats,
  parseContestStatus,
  parseEntry,
  parseEntries,
  parseMarket,
  parseOutcome,
  parsePointsEntered,
  sortMarket,
  totalPrizePool,
  totalPrizePoolAsDouble,
  isPickAvailable,
  getPickDetails,
  flatFind,
  flatFilter,
};
