import { useSelector } from "react-redux";
import { useActions } from "hooks";
import { setModal } from "utilities/UI/uiSlice";

export const useModal = () => {
  const modal = useSelector((state: any) => state.utilities.ui.modal);
  const dispatchSetModal = useActions(setModal);

  return [modal, dispatchSetModal];
};
