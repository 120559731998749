import { collection, type CollectionReference } from "firebase/firestore";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import type { SkrillDeposit } from "./types";

const useSkrillDeposits = (): [SkrillDeposit[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const ref = userId
    ? (collection(
        getFirestore(),
        "users",
        userId,
        "skrillDeposits",
      ) as CollectionReference<SkrillDeposit>)
    : undefined;
  const [deposits, loading] = useCollectionData(ref, ref?.path);

  return [deposits, loading];
};

export default useSkrillDeposits;
