// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "TipsCompetitorInfo-module--emphasis--0f0cb";
export var green = "#57d33b";
export var greenLink = "TipsCompetitorInfo-module--green-link--3f320";
export var grey = "var(--tone-mid)";
export var jockey = "TipsCompetitorInfo-module--jockey--994dc";
export var markets = "TipsCompetitorInfo-module--markets--953e0";
export var name = "TipsCompetitorInfo-module--name--2ad7f";
export var notchGap = "32px";
export var number = "TipsCompetitorInfo-module--number--bac35";
export var orange = "#f8662b";
export var red = "#ff2741";
export var silksContainer = "TipsCompetitorInfo-module--silksContainer--d9fe4";
export var tipsOutcome = "TipsCompetitorInfo-module--tipsOutcome--3d8ac";
export var trainer = "TipsCompetitorInfo-module--trainer--28aa3";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";