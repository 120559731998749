import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import { useMemo } from "react";
import {
  collection,
  type DocumentData,
  type FirestoreDataConverter,
  orderBy,
  query,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";

export type Withdrawal = {
  id: string;
  amount: number;
  cancellable: boolean;
  createdAt: string; // NOTE: should probably convert this to date in a transformer
  currency: string;
  status: string;
};

const withdrawalsConverter: FirestoreDataConverter<Withdrawal> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Withdrawal => {
    return {
      ...snapshot.data(options),
      id: snapshot.id,
    } as Withdrawal;
  },
};

const useWithdrawals = (): [any[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);

  const withdrawalsRef = userId
    ? collection(getFirestore(), "users", userId, "withdrawals").withConverter(
        withdrawalsConverter,
      )
    : undefined;

  const [withdrawals, loading] = useCollectionData<Withdrawal>(
    userId ? query(withdrawalsRef, orderBy("createdAt", "desc")) : undefined,
    withdrawalsRef?.path,
  );

  const filtered = useMemo(
    () =>
      (withdrawals || []).filter(
        (withdrawal) =>
          withdrawal?.status === "PENDING" && withdrawal?.cancellable !== false,
      ),
    [withdrawals, loading],
  );

  return [filtered, loading];
};

export default useWithdrawals;
