import * as React from "react";
import * as TogglePrimitives from "@radix-ui/react-switch";
import cx from "classnames";

import { Skeleton } from "components/Skeleton";

import * as styles from "./Toggle.module.scss";

type ToggleProps = {
  label?: string;
  noSpacing?: boolean;
  loading?: boolean;
  labelRight?: boolean;
} & TogglePrimitives.SwitchProps;

const Toggle = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<ToggleProps>>,
  ToggleProps
>(
  (
    {
      className,
      label,
      noSpacing,
      id,
      loading = false,
      labelRight = false,
      ...props
    },
    ref,
  ) => (
    <div
      className={cx(className, styles.container, {
        [styles.noSpacing]: noSpacing,
        [styles.reverse]: labelRight,
      })}
    >
      {labelRight && label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      {loading ? (
        <Skeleton className={cx(className, styles.skeleton)} />
      ) : (
        <TogglePrimitives.Root
          id={id}
          className={styles.track}
          {...props}
          ref={ref}
        >
          <TogglePrimitives.Thumb className={styles.thumb} />
        </TogglePrimitives.Root>
      )}
      {label && !labelRight && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
    </div>
  ),
);
Toggle.displayName = "Toggle";

export { Toggle };
