import React, { type ReactNode } from "react";
import { Box } from "components/Box";
import { Skeleton } from "components/Skeleton";
import cx from "classnames";
import * as styles from "./RaceRowsSkeleton.module.scss";

export const NextToJumpSkeleton = ({
  count = 20,
  // race meeting has a slightly different skeleton. This is used on the next to jump page, today, tomorrow etc
  icon = (
    <Skeleton shape="circle" mode="dark" className={styles.nextToJumpIcon} />
  ),
  secondaryIcon = <Skeleton mode="light" className={styles.raceNumberIcon} />,
  longTitle = false,
}: {
  count?: number;
  icon?: ReactNode;
  secondaryIcon?: ReactNode;
  longTitle?: boolean;
}) => {
  return (
    <Box hideHeader>
      {Array.from({ length: count }).map((_, index) => (
        <div key={`skeleton-${index}`} className={styles.row}>
          {icon}
          <div className={styles.runnerInfo} key={`skeleton-${index}`}>
            <div className={styles.runnerTitle}>
              {secondaryIcon}
              <Skeleton
                mode="light"
                className={cx(styles.runnerTitleText, {
                  [styles.longTitle]: longTitle,
                })}
              />
            </div>
            <Skeleton mode="light" className={styles.runnerSubText} />
          </div>
          <div className={styles.clampContainers}>
            <Skeleton
              shape="rect"
              size="xs"
              mode="dark"
              className={styles.clampStatus}
            />
            <Skeleton
              shape="rect"
              size="xs"
              mode="light"
              className={styles.clampMore}
            />
          </div>
        </div>
      ))}
    </Box>
  );
};
