import React from "react";
import type { Event as EventType } from "hooks/firestore/betting/useBetting";
import Match from "./Match";
import Outright from "./Outright";
import { ErrorBoundary } from "library";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";

export type EventProps = {
  event: EventType;
  title?: Record<any, any>;
  isRepeat?: boolean;
  selections?: string[];
  toggleSelection?: (pick: ToggleSelectionPayload) => void;
  oddsFormat?: string;
};

const Event = ({
  event,
  title,
  isRepeat,
  selections,
  toggleSelection,
  oddsFormat,
}: EventProps) => (
  <ErrorBoundary>
    {event.eventType === "MATCH" ? (
      <Match
        match={event}
        title={title}
        isRepeat={isRepeat}
        selections={selections}
        toggleSelection={toggleSelection}
        oddsFormat={oddsFormat}
        isDisabled={event.status !== "ACTIVE"}
        // hide the round for overwatch and starcraft temporarily -> https://puntaa.atlassian.net/browse/PKB-1586
        showRound={
          ["OVERWATCH", "STARCRAFT"].includes(event.sport) &&
          event.attributes?.matchMode === "bo1"
            ? false
            : true
        }
      />
    ) : (
      <Outright outright={event} title={title} />
    )}
  </ErrorBoundary>
);

export default Event;
