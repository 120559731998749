import React from "react";
import * as styles from "./SACodeOfPractice.module.scss";
import { Link } from "components/Link";

export const SACodeOfPractice = () => {
  return (
    <span className={styles.base}>
      For SA residents, we are governed by a &nbsp;
      <Link
        to="https://www.cbs.sa.gov.au/documents/FN_ABO-Gambling-Code-of-Practice.pdf"
        target="_blank"
      >
        Code of Practice
      </Link>
      .
    </span>
  );
};
