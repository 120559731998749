import type {
  CompetitorType,
  IMarket,
  IMatchEvent,
  ProductType,
} from "types/BetTypes";
import { FireTransforms } from "utilities/FireTransforms";
import { isValid, differenceInHours } from "date-fns";

export namespace BettingTransforms {
  export function getHoursUntil(date: Date) {
    date = FireTransforms.parseDate(date);
    if (isValid(date) === false) {
      return Number.POSITIVE_INFINITY;
    }
    return differenceInHours(date, new Date());
  }

  export function marketIsActive(
    market: IMarket,
    productType: ProductType = "BETTING",
  ) {
    if (!market) {
      return false;
    }
    if (market?.disabledProducts?.includes(productType)) {
      return false;
    }
    if (market.status !== "ACTIVE") {
      return false;
    }
    if (market.nextBetStop) {
      if (FireTransforms.parseDate(market.nextBetStop) < new Date()) {
        return false;
      }
    }
    return true;
  }

  export function parseEvent(entry: any) {
    if (!entry) {
      return null;
    }
    const competitors = entry.competitors as Record<string, CompetitorType>;
    const scheduledStartTime = FireTransforms.parseDate(
      entry.scheduledStartTime,
    );

    const result = {
      ...entry,
      scheduledStartTime,
      competitors,
    } as IMatchEvent;

    return result;
  }
}
