// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var content = "Promotions-module--content--fc304";
export var emphasis = "Promotions-module--emphasis--5461c";
export var green = "#57d33b";
export var greenLink = "Promotions-module--green-link--cd142";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var promoContainer = "Promotions-module--promoContainer--9786e";
export var red = "#ff2741";
export var skeleton = "Promotions-module--skeleton--a32db";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";