import React, { useId } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import cx from "classnames";
import { ReactComponent as Check } from "./tick.svg";

import * as styles from "./Checkbox.module.scss";

export type CheckboxProps = {
  label?: React.ReactNode;
  hasError?: boolean;
  noSpacing?: boolean;
  variant?: "secondary" | "primary";
  labelClassName?: string;
  children?: React.ReactNode;
} & CheckboxPrimitive.CheckboxProps;

const Checkbox = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<CheckboxProps>>,
  React.ComponentPropsWithoutRef<React.ForwardRefExoticComponent<CheckboxProps>>
>(
  (
    {
      className,
      noSpacing,
      labelClassName,
      label,
      id: idProp,
      disabled,
      hasError,
      variant = "primary", // primary will act as normal and fill, secondary is bordered.
      children,
      ...props
    },
    ref,
  ) => {
    const id = useId();
    return (
      <div
        className={cx(styles.container, {
          [styles.disabled]: disabled,
          [styles.error]: hasError,
          [styles.noSpacing]: noSpacing,
          [styles.border]: variant === "secondary",
          [styles.solidWithChildren]: variant === "primary" && children,
        })}
      >
        <CheckboxPrimitive.Root
          ref={ref}
          className={cx(styles.checkbox, className)}
          id={idProp ?? id}
          disabled={disabled}
          {...props}
        >
          {children || (
            <CheckboxPrimitive.Indicator
              className={cx({ [styles.iconContainer]: variant === "primary" })}
            >
              <Check className={styles.icon} />
            </CheckboxPrimitive.Indicator>
          )}
        </CheckboxPrimitive.Root>
        {label && (
          <label
            htmlFor={idProp ?? id}
            className={cx(styles.label, labelClassName)}
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
