import * as uuid from "uuid";
import isEmpty from "lodash/isEmpty";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";
import { getSubSelectionsFromOutcomes } from "utilities/sem";
import { useEvent } from "hooks";

import type { PickType } from "sections/Entries/types";

export const useSGMSelectionToggle = (pick: PickType) => {
  const [event] = useEvent(pick.eventId, "sport", "sgm");

  if (!event || isEmpty(pick.outcomes)) return;

  const subSelections = getSubSelectionsFromOutcomes(pick.outcomes);
  if (subSelections.length <= 1) return;

  return () =>
    toggleSelection(
      event,
      {
        type: "match",
        outcomeId: uuid.v4(),
        odds: pick.odds,
        marketId: pick.marketId,
        sourceId: event.eventId,
        eventId: event.eventId,
        subSelections,
      },
      "sports",
    );
};
