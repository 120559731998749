import { usePageContext } from "hooks/usePageContext";

const useLocale = () => {
  const { hasTranslations } = usePageContext();

  if (typeof window === "undefined") {
    return null;
  }

  if (!hasTranslations) {
    // if current page has no translations look up saved locale
    return window.___detectedLocale;
  }

  // this is global variable declared in gatsby-browser.js file
  return window.___currentLocale;
};

export const useLocalePrefix = (): string => {
  if (typeof window === "undefined") {
    return "";
  }

  const locale = useLocale();

  if (locale === window.___defaultLocale) {
    return ``;
  }

  return `/${locale}`;
};

export const useCountry = () => {
  if (typeof window === "undefined") {
    return null;
  }

  return window.___country;
};

export default useLocale;
