import { useCallback } from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { useActions } from "hooks";
import { usePermissions } from "./useHasPermission";
import { usePromotionClaims } from "./firestore";
import Route from "route-parser";
import * as walletSlice from "sections/Wallet/walletSlice";
import type { Permission } from "utilities/Auth/authSlice";

export const useWallet = () => {
  const [claims, claimsLoading] = usePromotionClaims();
  const permissions = usePermissions();
  const location = useLocation();

  const parsedRoute = new Route(
    "(/:regionCode)/wallet(/:section)(/:subsection)(/)",
  ).match(location.pathname) as {
    regionCode: string;
    section: string;
    subsection: string;
  };

  const section = parsedRoute?.section || "statement";
  const subsection = parsedRoute?.subsection || "";

  const authoriseDeposit = useCallback(
    (permissionName: Permission) => {
      return authoriseComponent(
        permissionName,
        "Deposits currently unavailable in your region.",
      );
    },
    [permissions],
  );

  const authoriseWithdrawal = useCallback(
    (permissionName: Permission) => {
      return authoriseComponent(
        permissionName,
        "Withdrawals coming soon to your region.",
      );
    },
    [permissions],
  );

  const authoriseComponent = useCallback(
    (
      permissionName: Permission,
      locationMessage: string,
    ): null | any | "CooldownError" | "VerificationError" => {
      const permission = permissions[permissionName];

      if (
        [
          "RESIDENTIAL_LOCATION_UNSUPPORTED",
          "PHYSICAL_LOCATION_UNSUPPORTED",
        ].includes(permission ?? "")
      ) {
        return locationMessage;
      }

      if (permission === "VERIFICATION_STATUS_UNSUPPORTED") {
        return "VerificationError";
      }
      if (permission === "COOLDOWN") {
        return "CooldownError";
      }
      return null;
    },
    [permissions],
  );

  return {
    tab: section,
    subsection,
    claims,
    loading: claimsLoading,
    permissions,
    authoriseWithdrawal,
    authoriseDeposit,
  };
};

export const useWalletActions = () => {
  const [
    addCashCardAttempt,
    addPicklebetCardAttempt,
    removeCashCardAttempt,
    removePicklebetCardAttempt,
    depositFundsAttempt,
    depositFundsFailure,
    firstTimeDeposit,
    attemptRemoveBankAccount,
    withdrawCashAttempt,
    addedBankDetails,
    buySkrAttempt,
    attemptRegisterAddress,
    updateBonusCode,
    setBonusState,
    setSkrAccount,
    cancelWithdrawal,
    doAddBank,
    doMWDeposit,
    doAddCard,
    doBeginVerification,
    doAttemptRemoveBankAccount,
    cancelPaymentIQTransaction,
    doCancelWithdrawal,
    doClearBonusState,
    doConfirmCard,
    doLoadDefaultBonus,
    doQueueLoadDefaultBonus,
    doRemoveCard,
    doRemoveBankAccount,
    doShowError,
    doWithdrawCash,
    doVerifyCard,
    doSkrillDeposit,
    doWithdrawInterac,
    generatePayID,
    setAppleDepositStatus,
    doGetDepositLimits,
    setGoogleDepositStatus,
  ] = useActions([
    walletSlice.addCashCardAttempt,
    walletSlice.addPicklebetCardAttempt,
    walletSlice.removeCashCardAttempt,
    walletSlice.removePicklebetCardAttempt,
    walletSlice.depositFundsAttempt,
    walletSlice.depositFundsFailure,
    walletSlice.firstTimeDeposit,
    walletSlice.attemptRemoveBankAccount,
    walletSlice.withdrawCashAttempt,
    walletSlice.addedBankDetails,
    walletSlice.buySkrAttempt,
    walletSlice.attemptRegisterAddress,
    walletSlice.updateBonusCode,
    walletSlice.setBonusState,
    walletSlice.setSkrAccount,
    walletSlice.cancelWithdrawal,
    walletSlice.doAddBank,
    walletSlice.doMWDeposit,
    walletSlice.doAddCard,
    walletSlice.doBeginVerification,
    walletSlice.doAttemptRemoveBankAccount,
    walletSlice.cancelPaymentIQTransaction,
    walletSlice.doCancelWithdrawal,
    walletSlice.doClearBonusState,
    walletSlice.doConfirmCard,
    walletSlice.doLoadDefaultBonus,
    walletSlice.doQueueLoadDefaultBonus,
    walletSlice.doRemoveCard,
    walletSlice.doRemoveBankAccount,
    walletSlice.doShowError,
    walletSlice.doWithdrawCash,
    walletSlice.doVerifyCard,
    walletSlice.doSkrillDeposit,
    walletSlice.doWithdrawInterac,
    walletSlice.generatePayID,
    walletSlice.setAppleDepositStatus,
    walletSlice.doGetDepositLimits,
    walletSlice.setGoogleDepositStatus,
  ]);

  return {
    addCashCardAttempt,
    addPicklebetCardAttempt,
    removeCashCardAttempt,
    removePicklebetCardAttempt,
    depositFundsAttempt,
    depositFundsFailure,
    firstTimeDeposit,
    attemptRemoveBankAccount,
    withdrawCashAttempt,
    addedBankDetails,
    buySkrAttempt,
    attemptRegisterAddress,
    updateBonusCode,
    setBonusState,
    setSkrAccount,
    cancelWithdrawal,
    doAddBank,
    doMWDeposit,
    doAddCard,
    doBeginVerification,
    doAttemptRemoveBankAccount,
    cancelPaymentIQTransaction,
    doCancelWithdrawal,
    doClearBonusState,
    doConfirmCard,
    doLoadDefaultBonus,
    doQueueLoadDefaultBonus,
    doRemoveCard,
    doRemoveBankAccount,
    doShowError,
    doWithdrawCash,
    doVerifyCard,
    doSkrillDeposit,
    doWithdrawInterac,
    generatePayID,
    doGetDepositLimits,
    setAppleDepositStatus,
    setGoogleDepositStatus,
  };
};
