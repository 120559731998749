import React, { type MouseEventHandler } from "react";
import cx from "classnames";

import { Icon, navigate, PacmanLoader } from "library";

import * as styles from "./Button.module.scss";

export type ButtonType =
  | "primary"
  | "ok"
  | "secondary"
  | "success"
  | "error"
  | "caution"
  | "prompt"
  | "form"
  | "information";

export type ButtonSize = "default" | "medium" | "small";

type ButtonProps = {
  className?: string; // parent classes will be merged in
  type?: ButtonType;
  outline?: boolean;
  disabled?: boolean;
  pullRight?: boolean; // will use add align-self on the element
  pullLeft?: boolean; // note that pull* props only work if positioned within flex,
  center?: boolean;
  loading?: boolean;
  isSubmit?: boolean;
  buttonSize?: ButtonSize;
  expand?: boolean;
  link?: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
  onContextMenu?: MouseEventHandler;
  onMouseDown?: () => void;
  id?: string;
  icon?: string;
  iconPosition?: "START" | "END";
  tabIndex?: number;
  title?: string;
  children: React.ReactNode;
} & IHasGutters;

export const Button = ({
  className: classNameProps,
  gutterBottom = false,
  gutterTop = false,
  gutterLeft = false,
  gutterRight = false,
  pullRight = false,
  pullLeft = false,
  children,
  type,
  outline = false,
  onClick,
  onContextMenu,
  onMouseDown,
  link = undefined,
  loading = false,
  isSubmit = false,
  center = false,
  buttonSize = "default",
  expand = false,
  icon,
  iconPosition,
  tabIndex = 0,
  title,
  ...rest
}: ButtonProps) => {
  const className = cx(
    {
      [styles.button]: true,
      [styles.gutterBottom]: gutterBottom,
      [styles.gutterTop]: gutterTop,
      [styles.gutterLeft]: gutterLeft,
      [styles.gutterRight]: gutterRight,
      [styles[type]]: !![styles[type]],
      [styles.outline]: outline,
      [styles.pullRight]: pullRight,
      [styles.pullLeft]: pullLeft,
      [styles.center]: center,
      [styles.small]: buttonSize === "small",
      [styles.medium]: buttonSize === "medium",
      [styles.expand]: expand,
    },
    classNameProps,
  );

  return (
    <button
      tabIndex={tabIndex}
      className={className}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        if (link) {
          navigate(link);
        }
      }}
      onContextMenu={(event) => {
        if (onContextMenu) {
          onContextMenu(event);
        }
      }}
      onMouseDown={onMouseDown}
      disabled={loading}
      type={isSubmit ? "submit" : "button"}
      title={title}
      {...rest}
    >
      {(icon && !iconPosition) ||
        (icon && iconPosition === "START" /*DEFAULT*/ && (
          <Icon
            inline
            type={icon}
            className={cx({ ["icon"]: true, ["leftIcon"]: true })}
          />
        ))}
      {loading ? <PacmanLoader size={buttonSize} /> : children}
      {icon && iconPosition && iconPosition === "END" && (
        <Icon
          inline
          type={icon}
          className={cx({ ["icon"]: true, ["rightIcon"]: true })}
        />
      )}
    </button>
  );
};

export default Button;
