// extracted by mini-css-extract-plugin
export var active = "MultiModal-module--active--60c0c";
export var blue = "#0eb3f2";
export var content = "MultiModal-module--content--5cda8";
export var emphasis = "MultiModal-module--emphasis--9bf7f";
export var green = "#57d33b";
export var greenLink = "MultiModal-module--green-link--02f69";
export var grey = "var(--tone-mid)";
export var modalPadding = "5px";
export var multiModal = "MultiModal-module--multiModal--a4cb1";
export var notchGap = "32px";
export var notification = "MultiModal-module--notification--5d23d";
export var notificationArea = "MultiModal-module--notificationArea--31051";
export var orange = "#f8662b";
export var overlay = "MultiModal-module--overlay--08895";
export var red = "#ff2741";
export var resizeIcon = "MultiModal-module--resizeIcon--7c9c0";
export var resizer = "MultiModal-module--resizer--b7ab7";
export var title = "MultiModal-module--title--baa5d";
export var titleHeight = "40px";
export var titleText = "MultiModal-module--titleText--11413";
export var totalAdditionalWindowHeight = "45px";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var windowIcon = "MultiModal-module--windowIcon--549b1";
export var windowIcons = "MultiModal-module--windowIcons--213e1";
export var yellow = "#ffb100";