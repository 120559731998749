import React, { useContext } from "react";
import cx from "classnames";
import * as styles from "./ShortForm.module.scss";
import { constructCurrency } from "common";
import { Pill } from "components/Pill";
import { Caret } from "components/Caret";
import { RaceContext } from "sections/Betting/Race/Race";
import { toTitleCaseWithExclusions } from "utilities/racingUtilities";
import SmoothHeightTransition from "components/SmoothHeightTransition";
import { toOrdinal } from "utilities/number";

const BREAK_STATUS_LABEL = "TODAY'S RUN (7TH UP+)";

type TrackConditionKey =
  | "s123Turf"
  | "s123Fast"
  | "s123Good"
  | "s123Slow"
  | "s123Heavy"
  | "s123Dirt"
  | "s123Synthetic"
  | "s123Firm";

const trackConditionMappings: Record<string, TrackConditionKey> = {
  HEAVY: "s123Heavy",
  SLOW: "s123Slow",
  GOOD: "s123Good",
  FIRM: "s123Fast",
  TURF: "s123Turf",
  SOFT: "s123Slow",
  DIRT: "s123Dirt",
  SYNTHETIC: "s123Synthetic",
};

const getTodaysGroundConditionsKey = (trackCondition: string): string => {
  if (!trackCondition) return "";

  // Normalize the input to handle cases like "GOOD 3" by splitting and taking the first part
  const primaryCondition = trackCondition.split(" ")[0];

  return trackConditionMappings[primaryCondition.toUpperCase()];
};

const calculateBreakStatus = (
  lastRuns: string,
  attributes: Record<string, string>,
) => {
  if (!lastRuns) {
    lastRuns = "";
  }
  // Reverse the string to process from most recent to oldest
  const runs = [...lastRuns].reverse();

  // Find the index of the first occurrence of 'x' from the most recent runs
  const breakIndex = runs.length > 0 ? runs.indexOf("x") : 0;

  switch (breakIndex) {
    case 0:
      return {
        label: "Today's run (1st Up)",
        value: attributes["s123FirstUp"],
      };
    case 1:
      return {
        label: "Today's run (2nd Up)",
        value: attributes["s123SecondUp"],
      };
    case 2:
      return {
        label: "Today's run (3rd Up)",
        value: attributes["s123ThirdUp"],
      };
    case 3:
      return {
        label: "Today's run (4th Up)",
        value: "N/A",
      };
    case 4:
      return {
        label: "Today's run (5th Up)",
        value: "N/A",
      };
    case 5:
      return {
        label: "Today's run (6th Up)",
        value: "N/A",
      };
    default:
      return {
        label: `Today's run (${toOrdinal(runs?.length + 1)} Up${
          runs?.length === 6 ? "+" : ""
        })`,
        value: "N/A",
      };
  }
};

const attributeLevels = {
  HORSE_RACING: {
    common: ["comments"],
    level1: [
      "finalRating",
      "settlingPosition",
      "s123Career",
      "s123Distance",
      "s123Course",
      "s123CourseDistance",
      "s123JockeyHorse",
      "todaysGround",
      "breakStatus",
      "gears",
      "trainerLocation",
    ],
    level2: [
      "s123Turf",
      "s123Fast",
      "s123Good",
      "s123Slow",
      "s123Heavy",
      "s123Dirt",
      "s123Synthetic",
      "s123FirstUp",
      "s123SecondUp",
      "s123ThirdUp",
      "racedDistancesRange",
      "wonDistancesRange",
      "age",
      "sex",
      "color",
      "careerPrizeMoney",
      "sire",
      "dam",
    ],
  },
  HARNESS_RACING: {
    common: ["comments"],
    level1: [
      "finalRating",
      "s123Career",
      "s123Course",
      "s123Distance",
      "s123CourseDistance",
      "bestMileRate",
      "gears",
    ],
    level2: [
      "racedDistancesRange",
      "wonDistancesRange",
      "age",
      "sex",
      "color",
      "careerPrizeMoney",
      "sire",
      "dam",
    ],
  },
  GREYHOUNDS: {
    common: ["comments"],
    level1: [
      "finalRating",
      "s123Career",
      "s123Course",
      "s123Distance",
      "s123CourseDistance",
      "fastestCrsDistTime",
    ],
    level2: ["wonDistancesRange", "color", "careerPrizeMoney", "sire", "dam"],
  },
};

const labelMapping = {
  comments: "Comments",

  speedMapPosition: "Settling Position",
  finalRating: "R&S Rating",
  s123Career: "All Runs",

  s123Distance: "Distance",
  s123Course: "Track",
  s123CourseDistance: "Track & Distance",

  s123JockeyHorse: "Jockey/Horse",
  todaysGround: "Today's Ground",
  breakStatus: BREAK_STATUS_LABEL,

  trainerLocation: "Trainer Location",
  gears: "Gear",

  s123Turf: "All Turf",
  s123Fast: "Firm",
  s123Good: "Good",

  s123Slow: "Soft",
  s123Heavy: "Heavy",
  s123Dirt: "Dirt",

  s123Synthetic: "Synthetic",
  s123FirstUp: "1st Up",
  s123SecondUp: "2nd Up",

  s123ThirdUp: "3rd Up",
  racedDistancesRange: "Raced Over",
  wonDistancesRange: "Won Over",

  age: "Age",
  sex: "Sex",
  color: "Colour",

  careerPrizeMoney: "Career Prize Money",
  dam: "Dam",
  sire: "Sire",

  bestMileRate: "Best Mile Rate",

  fastestCrsDistTime: "Best Time",
};

const colorMapping = {
  B: "BAY",
  BL: "BLACK",
  CH: "CHESTNUT",
  BR: "BROWN",
  GR: "GREY",
  RO: "ROAN",
  SOR: "SORREL",
  TAB: "TABIANO",
  AP: "APPALOOSA",
  FWN: "FAWN",
  BDL: "BRINDLE",
  WH: "WHITE",
  BLU: "BLUE",
  TAN: "DUN",
  IRON: "IRON",
  PA: "PALOMINO",
  RED: "RED",
  HAZ: "HAZELNUT",
  SK: "SKEWBALL",
  PIN: "PINTO",
  LIVER: "LIVER",
  TOBIANO: "TO",
};

const sexMapping = {
  // horses
  C: "Colt",
  H: "Horse",
  R: "Rig",
  G: "Gelding",
  F: "Filly",
  M: "Mare",
  // dogs
  B: "Bitch",
  D: "Dog",
};

export const speedMapPositionMap = {
  1: "Leader",
  2: "Pace",
  3: "Off-Pace",
  4: "Midfield",
  5: "Off-Midfield",
  6: "Backmarker",
};

export const wideProperties = ["comments"];

export type ShortFormState = "closed" | "comment" | "level1" | "level2";

const getDisplayAttributes = (
  sport: string,
  shortFormState: ShortFormState,
) => {
  const keysToShow =
    shortFormState === "level1"
      ? [...attributeLevels[sport].common, ...attributeLevels[sport].level1] // Level 1 + Comments
      : shortFormState === "level2"
        ? [
            ...attributeLevels[sport].common,
            ...attributeLevels[sport].level1,
            ...attributeLevels[sport].level2,
          ] // Level 1 + Level 2 + Comments
        : [...attributeLevels[sport].common]; // Just in case, show comments if there's another state besides "level1" or "level2"

  // Filter and order the attributes based on the levels defined
  const orderedAttributes = keysToShow
    .map((key) => ({
      key,
      label: labelMapping[key],
    }))
    .filter(({ label }) => label); // Ensures that only keys with existing labels are included

  return orderedAttributes;
};

const ShortForm = ({
  formAttributes,
  toggleShortForm,
  sex,
  age,
  comment,
  shortFormState,
  sport,
}: {
  formAttributes: Record<string, string>;
  toggleShortForm: () => void;
  sex: string;
  age: string;
  comment: string;
  shortFormState: ShortFormState;
  sport: string;
}) => {
  const { raceMeeting } = useContext(RaceContext);

  const displayAttributes = getDisplayAttributes(sport, shortFormState);

  const breakStatus = calculateBreakStatus(
    formAttributes.last6Runs,
    formAttributes,
  );

  const extendedFormAttributes = {
    ...formAttributes,
    speedMapPosition: speedMapPositionMap[formAttributes.speedMapPosition],
    sex: sexMapping[sex],
    age,
    color:
      formAttributes?.color?.indexOf("/") >= 0
        ? formAttributes?.color
            ?.split("/")
            ?.map((c) => colorMapping[c])
            ?.join("/")
        : colorMapping[formAttributes.color],
    comments:
      comment ??
      "Comments are currently unavailable for this runner. Check back soon.",
    careerPrizeMoney: constructCurrency(
      formAttributes.careerPrizeMoney
        ? Number(formAttributes.careerPrizeMoney) * 100
        : 0,
      { showDecimals: false },
    ),
    todaysGround:
      formAttributes[getTodaysGroundConditionsKey(raceMeeting?.trackCondition)],
    breakStatus: breakStatus?.value,
    trainerLocation: formAttributes.trainerLocation
      ? toTitleCaseWithExclusions(formAttributes.trainerLocation)
      : undefined,
    finalRating: formAttributes.finalRating
      ? Math.trunc(Number(formAttributes.finalRating))
      : undefined,
  };

  return (
    <SmoothHeightTransition>
      <div>
        {shortFormState !== "closed" && (
          <div className={styles.shortFormContainer}>
            <div className={styles.shortForm}>
              <div
                className={styles.formAttributes}
                onClick={() => toggleShortForm()}
              >
                {[...displayAttributes].map(({ key, label }) => (
                  <div
                    key={key}
                    className={cx(styles.attribute, {
                      [styles.wideProperty]: wideProperties.includes(key),
                    })}
                  >
                    <div className={styles.formLabel}>
                      {label === BREAK_STATUS_LABEL
                        ? breakStatus?.label ?? label
                        : label}
                    </div>
                    <div className={styles.formValue}>
                      {extendedFormAttributes[key] ?? "N/A"}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={cx(styles.toggle)}>
              <Pill onPressedChange={toggleShortForm} pressed={false}>
                {shortFormState === "level2" ? "Read Less" : "Read More"}
                <Caret
                  variant={shortFormState === "level2" ? "up" : "down"}
                  className={cx(styles.caret)}
                />
              </Pill>
            </div>
          </div>
        )}
      </div>
    </SmoothHeightTransition>
  );
};

export default ShortForm;
