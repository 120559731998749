import React from "react";
import { ReactComponent as SrmLogo } from "components/assets/srm-tag.svg";
import { ReactComponent as SgmLogo } from "components/assets/sgm-tag.svg";
import { ReactComponent as ExoticLogo } from "components/assets/exotic-logo.svg";

import type { ComplexBetType } from "../../ComplexBetBuilder";

export default ({ type }: { type: ComplexBetType }) => {
  switch (type) {
    case "srm":
      return <SrmLogo type="srmIcon" />;
    case "sgm":
      return <SgmLogo type="sgmIcon" />;
    case "exotic":
      return <ExoticLogo />;
    default:
      return null;
  }
};
