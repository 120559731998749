import type { ComponentType } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";

// Import Actions

import * as AccountSection from "sections/Account/accountSlice";
import * as WalletSection from "sections/Wallet/walletSlice";

import * as UserUtility from "utilities/User/Actions";
import * as LoadingUtility from "utilities/Loading/Actions";
import * as ValidationUtility from "./validation";

// Attach
// Takes a layout component, wraps it in this store and attaches this store's
// methods and state.

const attach = (Layout: ComponentType<any>) => {
  return connect(null, (dispatch) =>
    bindActionCreators(
      {
        ...(AccountSection as any),

        ...(WalletSection as any),

        ...(UserUtility as any),
        ...(LoadingUtility as any),
        ...(ValidationUtility as any),
      },
      dispatch,
    ),
  )(Layout);
};

export default attach;
