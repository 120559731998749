import React from "react";
import cx from "classnames";
import * as styles from "./CardFooter.module.scss";

type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

type SectionProps = {
  className?: string;
  label: string;
  children?: React.ReactNode;
  badge?: string;
  badgePosition?: "left" | "right";
};

const Container = ({ children, className }: ContainerProps) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

const Section = ({ className, label, children, badge }: SectionProps) => {
  return (
    <div className={className}>
      <span className={styles.label}>{label}</span>
      <span className={styles.content}>{children}</span>
      {badge ? <div className={styles.badge}>{badge}</div> : null}
    </div>
  );
};

const CardFooter = { Container, Section };

export { CardFooter };
