import React from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";

import { useDisplayAtMost } from "hooks/useDisplay";
import { Icon } from "../Icon";
import { setMenu } from "utilities/UI/uiSlice";
import { useIsClient } from "usehooks-ts";

import * as styles from "./HUDPanel.module.scss";

type HUDPanelProps = {
  title: string | JSX.Element;
  count?: number | string;
  contentHeader?: string | JSX.Element;
  contentBody?: string | JSX.Element;
  className?: string;
  canClose?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export const HUDPanel = ({
  contentHeader,
  contentBody,
  className = "",
  children,
  canClose = true,
  onClose,
}: HUDPanelProps) => {
  const isClient = useIsClient();
  const isTablet = useDisplayAtMost("tablet");
  const dispatch = useDispatch();
  const close = () => {
    if (onClose) {
      onClose();
    }

    dispatch(setMenu("none"));
  };
  return (
    <div className={cx(styles.HUDPanel, className)}>
      <div className={styles.HUDHeader}>
        <div>{contentHeader}</div>
        {isClient && isTablet && canClose && (
          <div className={styles.HUDClose} onClick={close}>
            <Icon type="chevronLeft" inline />
          </div>
        )}
      </div>
      <div className="hud-body">
        {children}
        {contentBody}
      </div>
    </div>
  );
};
