import React from "react";
import cx from "classnames";
import { Clamp } from "components/Clamp";

import * as styles from "./Live.module.scss";

const Live = () => {
  return <Clamp className={cx(styles.live)}>LIVE</Clamp>;
};

export { Live };
