import React from "react";
import type { PickemsEntry } from "hooks/firestore/pickems/types";
import { Box } from "components/Box";
import { ReactComponent as PickemIcon } from "components/assets/pickem.svg";
import { Clamp } from "components/Clamp";
import { Caret } from "components/Caret";
import { Link } from "components/Link";
import { PickemPick } from "./PickemPick";
import { PickemSummary } from "./PickemSummary";
import { PickemsTransforms } from "selectors";

import * as styles from "./PickemEntry.module.scss";

type PickemEntryProps = {
  entry: PickemsEntry;
};

const PickemEntry = ({ entry }: PickemEntryProps) => {
  const url = PickemsTransforms.getLadderLink(entry.contestId);

  return (
    <Box
      title={`Pick’em`}
      subTitle={entry.name}
      icon={<PickemIcon />}
      clampClassName={styles.topClamp}
      clamp={
        <div className={styles.clamp}>
          {entry.entryStatusText && (
            <div className={styles.status}>
              <Clamp variant={entry.entryStatusVariant as any}>
                {entry.entryStatusText}
              </Clamp>
            </div>
          )}
          <Clamp variant={`grey`} asChild>
            <Link to={url}>
              View Contest <Caret className={styles.caret} />
            </Link>
          </Clamp>
        </div>
      }
    >
      {entry.picks.map((pick, index) => {
        const isLast = index === entry.picks.length - 1;

        return (
          <PickemPick
            key={`${entry.id}-${pick.outcomeId}`}
            entryId={entry.id}
            pick={pick}
            isLast={isLast}
          />
        );
      })}

      <PickemSummary entry={entry} />
    </Box>
  );
};

export { PickemEntry };
