import { useState } from "react";

import type { ShareEntryActionProps } from "./ShareEntryAction";

export const useShareEntryModal = (initialOpen = false) => {
  const [isOpen, setOpen] = useState(initialOpen);

  const getModalProps = (props: Partial<ShareEntryActionProps>) =>
    ({
      isOpen,
      setOpen,
      className: "",
      referenceId: "",
      ...props,
    }) satisfies ShareEntryActionProps;

  return [setOpen, getModalProps] as const;
};
