// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var bonus = "Combinations-module--bonus--6c668";
export var card = "Combinations-module--card--1901a";
export var caret = "Combinations-module--caret--4ed85";
export var comboIcon = "Combinations-module--comboIcon--7e480";
export var count = "Combinations-module--count--ab77e";
export var description = "Combinations-module--description--76530";
export var drawer = "Combinations-module--drawer--fe737";
export var emphasis = "Combinations-module--emphasis--c633d";
export var footer = "Combinations-module--footer--e641d";
export var green = "#57d33b";
export var greenLink = "Combinations-module--green-link--08657";
export var grey = "var(--tone-mid)";
export var icon = "Combinations-module--icon--19af1";
export var infoIcon = "Combinations-module--infoIcon--b81d2";
export var isExpanded = "Combinations-module--isExpanded--dee25";
export var label = "Combinations-module--label--5a2d5";
export var notchGap = "32px";
export var options = "Combinations-module--options--63c95";
export var orange = "#f8662b";
export var red = "#ff2741";
export var sectionTitle = "Combinations-module--sectionTitle--e121b";
export var title = "Combinations-module--title--11f04";
export var type = "Combinations-module--type--b4c01";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";