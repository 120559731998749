import React from "react";
import * as styles from "./InputError.module.scss";

type InputErrorProps = {
  message: string;
};

const InputError = ({ message }: InputErrorProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.message}>{message}</span>
    </div>
  );
};

InputError.displayName = "InputError";

export { InputError };
