import React, { useEffect, useMemo } from "react";
import { usePermissions, useUserEventFilters } from "hooks";
import { PageLoader } from "library";
import { navigate } from "library";
import type { Permission } from "utilities/Auth/authSlice";
import { useIsClient } from "usehooks-ts";
import { PageMessage } from "components/PageMessage";
import { LinkButton } from "components/LinkButton";
import { ReactComponent as ErrorIcon } from "components/assets/account-error.svg";
import { useHomeUrl } from "hooks/firestore/user/usePreferences";

export default ({
  children,
  permissionsRequired,
  redirect,
  skeleton,
}: {
  children: React.ReactNode;
  permissionsRequired: Permission[] | string[];
  redirect?: string;
  skeleton?: React.ReactNode;
}) => {
  const isClient = useIsClient();
  const permissions = usePermissions();
  const userFilters = useUserEventFilters();

  const isSAUser = userFilters?.some((filter) => filter === "AU_SA_ALLOWED");
  const needsVieweSportsMarket =
    permissionsRequired?.includes("viewEsportsMarkets");

  const hasPermission = useMemo(
    () =>
      permissionsRequired.every(
        (permission) => permissions[permission] === "GRANTED",
      ),
    [permissions, permissionsRequired],
  );

  const homeUrl = useHomeUrl();

  useEffect(() => {
    if (!hasPermission && redirect) {
      navigate(redirect);
    }
  }, [hasPermission, redirect]);

  if (!isClient) {
    return skeleton || <PageLoader />;
  }

  if (isSAUser && needsVieweSportsMarket && !hasPermission) {
    return (
      <PageMessage
        image={<ErrorIcon />}
        title="SA Not Permitted"
        subTitle="As a South Australian resident you do not have permissions to view this page"
      >
        <LinkButton to={homeUrl}>Return Home</LinkButton>
      </PageMessage>
    );
  }

  return hasPermission ? (
    <>{children}</>
  ) : (
    <PageMessage
      image={<ErrorIcon />}
      title="Access Denied"
      subTitle="You do not have permission to view this page"
    >
      <LinkButton to={homeUrl}>Return Home</LinkButton>
    </PageMessage>
  );
};
