import { useContext } from "react";
import { usePageContext } from "hooks/usePageContext";
import { getCurrencyForRegion } from "common/regionCurrencies";
import { FirebaseContext } from "context/Firebase";
import type { CurrencyType } from "types/BetTypes";

export const useDefaultCurrency = () => {
  const pageContext = usePageContext();
  return getCurrencyForRegion(pageContext?.regionCode);
};

const useFiatCurrency = (): CurrencyType => {
  const { profile } = useContext(FirebaseContext);
  const defaultCurrency = useDefaultCurrency();

  return profile?.fiatCurrency || defaultCurrency;
};

export default useFiatCurrency;
