import { useMemo, useContext } from "react";
import { useSkrillDeposits } from "hooks";
import { FirebaseContext } from "context/Firebase";

const useSkrillEmails = (): [string[], boolean] => {
  const [deposits, depositsLoading] = useSkrillDeposits();

  const { profile } = useContext(FirebaseContext);

  const skrillEmails = useMemo(() => {
    if (!profile || !deposits) {
      return [];
    }

    return [
      ...new Set([
        profile.email,
        ...deposits.map((deposit: any) => deposit.skrillEmail),
      ]),
    ].filter(Boolean);
  }, [profile?.email, deposits]);

  return [skrillEmails, !profile || depositsLoading];
};

export default useSkrillEmails;
