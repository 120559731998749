import {
  format as doFormat,
  formatDistanceToNowStrict,
  isValid,
  isBefore,
  isPast,
  formatDistanceStrict,
  intervalToDuration,
  differenceInSeconds,
} from "date-fns";
import { format as formatTz } from "date-fns-tz";
import enAU from "date-fns/locale/en-AU";

import { FireTransforms } from "../utilities/FireTransforms";

export const storedFormat = "YYYY-MM-DD/THH:mm:ss";

/**
 * @name formatTimeRemaining
 * @description Returns a string representing the time remaining, or the status
 * if the time remaining has past or the contest has progressed from a countdown
 * state (be it to completion or cancellation).
 * @param {Object} contest - The generic contest object
 */
export function formatTimeRemaining(contest) {
  let remaining = "-";

  if (contest && contest.state) {
    switch (contest.state) {
      case "PENDING": {
        if (isBefore(contest.startTime, new Date())) {
          remaining = "Waiting...";
        } else {
          remaining = formatDistanceToNowAbbr(contest.startTime);
        }

        break;
      }
      case "LIVE": {
        remaining = "Live";
        break;
      }
      case "AWAITING_RESULT": {
        remaining = "Confirming";
        break;
      }
      case "COMPLETED": {
        remaining = "Completed";
        break;
      }
      case "CANCELLED": {
        remaining = "Cancelled";
        break;
      }
      default:
        remaining = "Error";
    }
  }

  return remaining;
}

/**
 * @description Return a description of the duration between now and datems, using our abbreviated unit format. E.g. 1D, 3H, 3Mo
 * @param datems the date
 */
export function formatDistanceToNowAbbr(datems, skipPrefix = false) {
  if (!datems) {
    return "-";
  }

  //"inspired" by https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089
  const duration = differenceInSeconds(datems, new Date());

  const prefix = duration <= 0 && !skipPrefix ? "-" : "";

  if (duration <= 60 && duration >= -60) {
    // for everything below minute or above -minute show seconds only
    return `${duration}S`;
  }

  if (
    (duration < 5 * 60 && duration > 1 * 60) ||
    (duration > -5 * 60 && duration <= -1 * 60)
  ) {
    const interval = intervalToDuration({ start: new Date(), end: datems });
    return `${prefix}${interval.minutes}M ${interval.seconds}S`;
  }

  return (
    prefix +
    formatDistanceToNowStrict(datems, {
      locale: {
        ...enAU,
        formatDistance: (token, count) => {
          return formatDistanceAbbr[token].replace("{{count}}", count);
        },
      },
    })
  );
}

/**
 * @description Return a description of the duration between date and given date, using our abbreviated unit format. E.g. 1D, 3H, 3Mo
 * @param date the date
 */
export function formatDistanceToDateFromNowAbbr(date, skipPrefix = false) {
  if (!date) {
    return "-";
  }

  const prefix = isPast(date) && !skipPrefix ? "-" : "";

  return (
    prefix +
    formatDistanceStrict(new Date(), date, {
      roundingMethod: "floor",
      locale: {
        ...enAU,
        formatDistance: (token, count) =>
          formatDistanceAbbr[token].replace("{{count}}", count),
      },
    })
  );
}

const formatDistanceAbbr = {
  lessThanXSeconds: "{{count}}S",
  xSeconds: "{{count}}S",
  halfAMinute: "30S",
  lessThanXMinutes: "{{count}}M",
  xMinutes: "{{count}}M",
  aboutXHours: "{{count}}H",
  xHours: "{{count}}H",
  xDays: "{{count}}D",
  aboutXWeeks: "{{count}}W",
  xWeeks: "{{count}}W",
  aboutXMonths: "{{count}}Mo",
  xMonths: "{{count}}Mo",
  aboutXYears: "{{count}}Y",
  xYears: "{{count}}Y",
  overXYears: "{{count}}Y",
  almostXYears: "{{count}}Y",
};

/**
 * @name formatDateTime
 * @description Returns a string representation of a datetime for human consumption
 * @param {String} dateTime - The ISD-8601 string representation of date to format
 * @param format
 */
export function formatDateTime(
  dateTime: Date | string,
  format = "do MMM, yy @ HH:mm:ss",
) {
  return doDateFormatting(dateTime, format);
}

export function formatDateTimeWithTz(
  dateTime: Date | string,
  format = "do MMM, yy @ HH:mm:ss z",
) {
  const local = FireTransforms.parseDate(dateTime);

  if (isValid(local) === false) {
    return null;
  }

  const timeZone =
    Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || "Australia/Brisbane";

  return formatTz(local, format, {
    timeZone,
    locale: enAU,
  });
}

/**
 * @name formatDate
 * @description Returns a string representation of a date for human consumption
 * @param {String} dateTime - The ISD-8601 string representation of date to format
 */
export function formatDate(dateTime: Date | string) {
  return doDateFormatting(dateTime, "do MMM, yy");
}

function doDateFormatting(dateTime: Date | string, format: string) {
  const local = FireTransforms.parseDate(dateTime);

  if (isValid(local) === false) {
    return null;
  }
  return doFormat(local, format);
}

/**
 * Returns an array of strings with each year dating back to the one provided
 */
export const getYearsBackTo = (
  endYear: number,
  startYear?: number,
): string[] => {
  if (!endYear) return [];

  if (startYear < endYear) return [String(startYear)];

  const currentYear = startYear ? startYear : new Date().getFullYear();

  const years = Array.from({ length: currentYear - endYear + 1 }).map(
    (_val, index) => String(currentYear - index),
  );

  return years;
};

/**
 * Converts seconds to miliseconds
 */
export const msFromSeconds = (seconds: number) => seconds * 1000;

/**
 * Converts timestamp seconds to a Date
 */
export const dateFromSeconds = (seconds: number) =>
  new Date(msFromSeconds(seconds));
