// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "PickemEntry-module--caret--0785a";
export var clamp = "PickemEntry-module--clamp--b0c99";
export var emphasis = "PickemEntry-module--emphasis--c535d";
export var green = "#57d33b";
export var greenLink = "PickemEntry-module--green-link--bed5f";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var status = "PickemEntry-module--status--81c34";
export var topClamp = "PickemEntry-module--topClamp--161df";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";