import {
  type RaceCompetitorType,
  type RaceEventType,
  RaceMarketTypeTypes,
  type RaceMarketsType,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import type { PillTypes } from "sections/Betting/Race/hooks/useRace";

export type TopFlucsType = {
  competitorId: string;
  position: number;
};

type useRaceDetailsType = {
  markets: RaceMarketsType[];
  competitors: RaceCompetitorType[];
  race: RaceEventType;
  loading: boolean;
  selectedPills: PillTypes[];
  setSelectedPills: (value: Array<PillTypes>) => void;
};

const hasSpeedMapRequirements = (c: RaceCompetitorType) =>
  !!c.startingPosition && c.formAttributes.speedMapPosition;

export const useRaceDetails = (props: useRaceDetailsType) => {
  const { markets, competitors, race, loading } = props;

  const calculateFavOdds = () => {
    if (loading) return "0";
    const favOutcomes = markets.find(
      (x) => x.marketType === "RACING_WINNER",
    )?.outcomes;
    return favOutcomes
      ? Object.values(favOutcomes).reduce((max, outcome) => {
          if (!outcome.active) return max;
          return Number(outcome.odds) < Number(max) ? outcome.odds : max;
        }, "10000")
      : "0";
  };

  const favOdds = calculateFavOdds();

  const calculateHasFlucs = () => {
    if (loading) return false;
    const winMarket = markets.find(
      (x) => x.marketType === RaceMarketTypeTypes.Win,
    );
    if (!winMarket) return false;

    const outcomes = winMarket?.outcomes || {};
    return Object.values(outcomes).every((x) => Number(x.openingOdds) !== 1);
  };

  const hasFlucs = calculateHasFlucs();

  const calculateHasDeductions = () => {
    if (loading) return false;
    return (
      race?.status !== RaceStatuses.OPEN &&
      competitors?.some((x) => x.scratched)
    );
  };

  const raceHasDeductions = calculateHasDeductions();

  const hasRaceComment = !loading && !!race?.comment;

  const hasSpeedMap = competitors?.every(hasSpeedMapRequirements)
    ? true
    : competitors?.some(hasSpeedMapRequirements)
      ? "partial"
      : false;

  const isCompetitorScratched = (competitorId) =>
    competitors?.find((x) => x.id === competitorId)?.scratched;

  const flucsResults = () =>
    Object.values(
      markets?.find((x) => x.marketType === RaceMarketTypeTypes.Win)
        ?.outcomes || {},
    )
      .map((x) => ({
        flucs: 100 / Number(x.odds) - 100 / Number(x.openingOdds),
        competitorId: x.competitorId,
      }))
      .filter((x) => x.flucs > 0 && !isCompetitorScratched(x.competitorId))
      .sort((a, b) => (b.flucs === a.flucs ? 0 : b.flucs > a.flucs ? 1 : -1))
      .splice(0, 3)
      .map((x, i) => ({ competitorId: x.competitorId, position: i + 1 }));

  const topFlucs = flucsResults();

  return {
    favOdds,
    raceHasDeductions,
    hasFlucs,
    hasRaceComment,
    hasSpeedMap,
    topFlucs,
  };
};
