import { getStore } from "./getStore";

export function getState(path?: string) {
  let result = getStore().getState();
  if (path) {
    const keys = path.split(".");
    while (keys.length > 0) {
      result = result[keys.shift() ?? ""];
      if (!result) {
        return null;
      }
    }
  }
  return result;
}
