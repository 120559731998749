import React, { useContext } from "react";

import {
  formatStartingPosition,
  getFormAttributes,
  getJockeyLabel,
} from "utilities/racingUtilities";
import { RaceContext } from "sections/Betting/Race";

import { CompetitorWeight, Silks } from "sections/Betting/Race/components";
import { format } from "date-fns";
import * as styles from "./CompetitorInfo.module.scss";
import cx from "classnames";
import type { RaceEventType } from "sections/Betting/Race/hooks/RacingTypes";
import type { MappedCompetitorType } from "sections/Betting/Race/hooks/useSortedCompetitors";

export const VACANT_BOX_MAGIC_STRING = "....";

type CompetitorNumberAndNameProps = {
  competitor: MappedCompetitorType;
  race: RaceEventType;
};

export const CompetitorNumberAndName = ({
  competitor,
  race,
}: CompetitorNumberAndNameProps) => {
  return (
    <div
      className={cx(styles.competitor, {
        [styles.scratched]: competitor?.scratched,
      })}
    >
      {`${competitor?.number}. ${
        competitor?.name === VACANT_BOX_MAGIC_STRING
          ? "Vacant Box"
          : competitor?.name
      } `}
      {race?.sport !== "GREYHOUNDS" && competitor?.startingPosition && (
        <div className={styles.startingPosition}>
          {formatStartingPosition(competitor?.startingPosition)}
        </div>
      )}
    </div>
  );
};

type CompetitorInfoProps = {
  competitor: MappedCompetitorType;
};

export const CompetitorInfo = ({ competitor }: CompetitorInfoProps) => {
  const jockeyClaim = getFormAttributes(competitor, "jockeyClaim");
  const { race } = useContext(RaceContext);

  return (
    <div className={styles.container}>
      <div className={styles.silkContainer}>
        {race?.silksUrl ? (
          <div>
            <Silks
              silksUrl={race?.silksUrl}
              competitorNumber={Number(competitor?.number)}
              imageHeight={40}
              key={competitor.id}
            />
          </div>
        ) : (
          <div className={styles.silkNumber}>{competitor.number}</div>
        )}
        {competitor?.formAttributes?.last6Runs && (
          <div className={styles.last6runs}>
            {getFormAttributes(competitor, "last6Runs")}
          </div>
        )}
      </div>
      <div className={styles.competitorInfoContainer}>
        <CompetitorNumberAndName competitor={competitor} race={race} />
        {(competitor.scratched || competitor.jockey) && (
          <div className={styles.secondaryInformation}>
            {competitor.scratched ? (
              <span>SCRATCHED</span>
            ) : (
              competitor.jockey && (
                <>
                  <span>
                    {getJockeyLabel(competitor?.jockey, race?.sport)}
                    {jockeyClaim && ` (a${jockeyClaim})`}
                  </span>{" "}
                  <CompetitorWeight
                    sport={race?.sport}
                    weight={competitor.weight}
                  />{" "}
                </>
              )
            )}
          </div>
        )}
        {competitor.scratchedAt ? (
          <div className={styles.secondaryInformation}>
            {format(competitor?.scratchedAt, "dd/LL, hh:mm aaa")}
          </div>
        ) : (
          <>
            {competitor.trainer && (
              <div className={styles.secondaryInformation}>
                T: {competitor.trainer}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
