import React from "react";
import cx from "classnames";
import * as styles from "./BetCard.module.scss";

export const BetCard: React.SFC<{
  message?: string | JSX.Element;
  hasErrors?: boolean;
  invalid?: boolean;
  className?: string;
}> = ({ message, hasErrors, invalid, className, children }) => (
  <div
    className={cx(styles.betCard, className, {
      [styles.invalid]: !!invalid,
      [styles.hasErrors]: !!hasErrors,
    })}
  >
    {message && <div>{message}</div>}
    <div className={styles.inner}>{children}</div>
  </div>
);
