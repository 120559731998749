import type {
  EventCounts,
  EventCountType,
} from "hooks/firestore/betting/useEventCounts";

export const getTotalCountForSports = (
  eventCounts: EventCounts,
  slugs: string[],
  eventTypesFilter: EventCountType[],
) => {
  return eventCounts
    ? Object.entries(eventCounts)
        .filter(([key]) => eventTypesFilter.includes(key as EventCountType))
        .reduce((count, [_key, value]) => {
          return (
            count + slugs.reduce((acc2, slug) => acc2 + (value[slug] || 0), 0)
          );
        }, 0)
    : 0;
};
