import React from "react";
import { PageMessage } from "components/PageMessage";
import { NextBettingButtons } from "components/layout/NextBettingButtons";
import { ReactComponent as ExpiredIcon } from "sections/Share/assets/expired.svg";
import { ReactComponent as EmptyIcon } from "sections/Share/assets/empty.svg";

import * as styles from "./Share.module.scss";

export const EmptySharePageMessage = () => {
  return (
    <PageMessage
      className={styles.pageMessage}
      image={<EmptyIcon />}
      title="Shared bet does not exist"
      subTitle={
        "Double check the URL, or place a bet to get amongst the action."
      }
    >
      <NextBettingButtons size="md" />
    </PageMessage>
  );
};

export const ExpiredSharePageMessage = () => {
  return (
    <PageMessage
      className={styles.pageMessage}
      image={<ExpiredIcon />}
      title="Shared bet is unavailable"
      subTitle={
        "The market for this shared bet has expired. Place a bet to get amongst the action."
      }
    >
      <NextBettingButtons size="md" />
    </PageMessage>
  );
};
