import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { LoaderIcon, Icon } from "library";
import type { StreamType } from "types/ContentTypes";
import { isDisplayMode } from "utilities/display";
import { streamHadError } from "utilities/UI/uiSlice";
import { dispatchNow } from "store";
import { useEventProperty } from "hooks/firestore/betting/useBetting";

import * as styles from "./StreamPlayerWithLoader.module.scss";

const PICKLE_DOMAINS = ["skrilla.com", "picklebet.com", "localhost"];

const DEFAULT_VOLUME = 0.5;

const STREAM_WAITING = "/liveStream-holding-compressed.mp4";

export const isPickleStream = (url: string) => {
  if (!url) return true;
  return PICKLE_DOMAINS.some((domain) => {
    return url?.indexOf(domain) >= 0;
  });
};

export const StreamPlayerWithLoader = ({ stream }: { stream: StreamType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [attemptedLoadCount, setAttemptedLoadCount] = useState(0);
  const [streamUri, loading] = useEventProperty(stream?.eventId, "streamUri");
  const url = stream.url ?? streamUri ?? STREAM_WAITING;

  const streamError = (error?: any) => {
    dispatchNow(streamHadError({ ...stream, error }));
    setIsLoading(true);
    setAttemptedLoadCount(attemptedLoadCount + 1);
  };

  const ready = () => {
    setAttemptedLoadCount(0);
    setIsLoading(false);
  };

  const reset = () => {
    setAttemptedLoadCount(0);
    setIsLoading(true);
  };

  const mustLoop = url === STREAM_WAITING;

  const renderStreamError = () => {
    return (
      <div className={styles.streamError}>
        <div className={styles.errorIcon}>
          <Icon type={"error"} />
        </div>
        <div className={styles.errorMessage}>
          There was an error loading the stream, or the stream has finished.
        </div>
        <div className={styles.streamErrorButton} onClick={reset}>
          Try again
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <div>
          <LoaderIcon />
        </div>
      </div>
    );
  };

  return (
    <div key={attemptedLoadCount} className={styles.stream}>
      {attemptedLoadCount < 4 ? (
        <>
          {isLoading && !loading && renderLoading()}
          <ReactPlayer
            key={stream.eventId}
            url={url}
            width={`100%`}
            height={`100%`}
            muted={stream.muted}
            volume={DEFAULT_VOLUME}
            playing={true}
            playsinline={true}
            loop={mustLoop}
            controls={isDisplayMode("mobile") ? true : !isPickleStream(url)}
            onReady={ready}
            onError={streamError}
            style={{
              display: isLoading ? "none" : "block",
            }}
          />
        </>
      ) : (
        renderStreamError()
      )}
    </div>
  );
};
