import React from "react";
import cx from "classnames";
import useRegions from "hooks/graphql/useRegions";
import useLocale from "hooks/useLocale";
import { useIsClient } from "usehooks-ts";

import * as styles from "./RegionMenu.module.scss";

export const RegionMenu = () => {
  const regions = useRegions();
  const locale = useLocale();

  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <div
      className={cx(styles.regionMenu, {
        [styles.disabled]: !window.___hasTranslations,
      })}
    >
      Region:
      {regions.map(({ code, languages, flagCode }) => {
        const isActive = locale.toLowerCase() === `${languages[0]}-${code}`;
        return (
          <div
            key={`language-${code}`}
            className={cx(styles.language, { [styles.active]: isActive })}
            onClick={() => {
              if (typeof window !== "undefined") {
                window.___changeLocale(
                  `${languages[0]}-${code}`,
                  window.location.pathname,
                );
              }
            }}
          >
            {flagCode}
          </div>
        );
      })}
    </div>
  );
};

export default RegionMenu;
