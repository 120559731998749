import { Icon } from "library";
import React from "react";

import * as styles from "./RaceWeather.module.scss";

const enum weatherIcons {
  fine = "weatherFine",
  heavyRain = "weatherHeavy",
  showers = "weatherShowers",
  overcast = "weatherOvercast",
}

const WeatherIcon = ({ weather }: { weather: string }) => {
  if (weather.includes("fine") || weather.includes("clear")) {
    return <Icon type={weatherIcons.fine} />;
  }
  if (weather.includes("heavy")) {
    return <Icon type={weatherIcons.heavyRain} />;
  }
  if (
    weather.includes("rain") ||
    weather.includes("showers") ||
    weather.includes("shwry")
  ) {
    return <Icon type={weatherIcons.showers} />;
  }
  if (
    weather.includes("clouds") ||
    weather.includes("cloudy") ||
    weather.includes("overcast") ||
    weather.includes("ocast")
  ) {
    return <Icon type={weatherIcons.overcast} />;
  }

  return <>{weather}</>;
};

export const RaceWeather = ({
  weather,
  trackCondition,
  trackRating,
}: {
  weather: string;
  trackCondition: string;
  trackRating: string;
}) => {
  if (!weather || weather === "" || weather === "n/a") {
    return null;
  }

  return (
    <div className={styles.weather}>
      <div>
        <WeatherIcon weather={weather.toLowerCase()} />
      </div>
      {trackCondition &&
        trackCondition !== "NA" &&
        trackCondition !== "unknown" && (
          <div className={styles.trackCondition}>{`${trackCondition} ${
            trackRating ?? ""
          }`}</div>
        )}
    </div>
  );
};
