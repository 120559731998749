import React from "react";
import cx from "classnames";
import Blockies from "react-blockies";

import * as styles from "./Avatar.module.scss";

const Avatar = ({
  user,
  className = undefined,
  profile,
  width = 30,
  size = 3,
}) => {
  if (!user) {
    return null;
  }

  let icon = (
    <Blockies seed={user} scale={size} size={width / size} bgColor="#fff" />
  );

  if (!!profile && profile && profile.avatarUri && profile.avatarUri !== "") {
    icon = (
      <img
        src={profile.avatarUri}
        width={width}
        height={width}
        alt={`Avatar`}
      />
    );
  }

  return (
    <div
      className={cx(styles.avatar, className)}
      style={{ width: `${width}px`, height: `${width}px` }}
    >
      {icon}
    </div>
  );
};

export default Avatar;
