// extracted by mini-css-extract-plugin
export var active = "SRMShare-module--active--a90e9";
export var arrowDown = "SRMShare-module--arrowDown--c9047";
export var arrowUp = "SRMShare-module--arrowUp--4e430";
export var blue = "#0eb3f2";
export var container = "SRMShare-module--container--564dc";
export var content = "SRMShare-module--content--98bb8";
export var disabled = "SRMShare-module--disabled--642a7";
export var emphasis = "SRMShare-module--emphasis--ba2fc";
export var green = "#57d33b";
export var greenLink = "SRMShare-module--green-link--df0fa";
export var grey = "var(--tone-mid)";
export var heading = "SRMShare-module--heading--b6737";
export var logo = "SRMShare-module--logo--9c5d4";
export var notchGap = "32px";
export var odds = "SRMShare-module--odds--42eda";
export var orange = "#f8662b";
export var red = "#ff2741";
export var selection = "SRMShare-module--selection--80930";
export var selections = "SRMShare-module--selections--a7364";
export var silksContainer = "SRMShare-module--silksContainer--bc9cc";
export var text = "SRMShare-module--text--14dad";
export var title = "SRMShare-module--title--d14db";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";