import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "hooks";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { setModal } from "utilities/UI/uiSlice";
import { SignInForm } from "./SignInForm";
import { lastSession } from "utilities/lastSession";

type SignInModalProps = {
  onComplete?: () => void;
};
const SignInModal = ({ onComplete }: SignInModalProps) => {
  const isOpen = useSelector((state) => state.utilities.ui.modal === "signIn");
  const dispatch = useDispatch();
  const [ignoreLastSession, setIgnoreLastSession] = useState(false);
  const existingSession = ignoreLastSession ? null : lastSession.get();

  useEffect(() => {
    if (!isOpen) {
      setIgnoreLastSession(false);
    }
  }, [isOpen]);

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={() => dispatch(setModal(null))}
      title={existingSession ? null : "Log In"}
    >
      <SignInForm
        onIgnoreLastSession={() => setIgnoreLastSession(!ignoreLastSession)}
        lastSession={existingSession}
        onComplete={onComplete}
      />
    </FullScreenModal>
  );
};

export { SignInModal };
