// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Stepper-module--emphasis--a9038";
export var first = "Stepper-module--first--3d952";
export var green = "#57d33b";
export var greenLink = "Stepper-module--green-link--bb99d";
export var grey = "var(--tone-mid)";
export var isActive = "Stepper-module--isActive--da77b";
export var isCompleted = "Stepper-module--isCompleted--f641f";
export var label = "Stepper-module--label--92089";
export var last = "Stepper-module--last--511e1";
export var notchGap = "32px";
export var number = "Stepper-module--number--0ae37";
export var orange = "#f8662b";
export var red = "#ff2741";
export var section = "Stepper-module--section--0016e";
export var stepper = "Stepper-module--stepper--e173e";
export var text = "Stepper-module--text--6767f";
export var thumb = "Stepper-module--thumb--5a62f";
export var track = "Stepper-module--track--a3523";
export var trackDanger = "Stepper-module--trackDanger--3448f";
export var trackInfo = "Stepper-module--trackInfo--7fa21";
export var trackPrimary = "Stepper-module--trackPrimary--4b0ff";
export var trackWarning = "Stepper-module--trackWarning--09eb8";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";