module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0_v7rmf7ii32m6uv6mqug3aljfke/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Picklebet","short_name":"Picklebet","start_url":"/","background_color":"#10181c","theme_color":"#10181c","display":"minimal-ui","icon":"src/assets/android-chrome-512x512.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d2fce426634278a044bdcf8cf8229599"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4._ens3da3gaz7n472fe54cfx2zbu/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"v955MjMXpvtfp03al191RJjvbDV3Pwfu","devKey":"k9dCR4Ek0e42cE3l1rRGr93spLpjap4j","trackPage":false,"manualLoad":true,"delayLoad":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0.7_encoding@0.1.13_esbuild@0.2_bz4x35p7pt7fc6y5hxetdmacqm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
