import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  // Selections
  selectBetslip,
} from "sections/Betting/Betslip/betslipSlice";

export const useBasicBetSelection = (outcomeId: string) => {
  const { selections } = useSelector(selectBetslip);
  const selection = useMemo(() => selections[outcomeId], [selections]);

  return { selection };
};
