import React from "react";
import Select, { type Props, type GroupBase } from "react-select";
import cx from "classnames";

import * as styles from "./Select.module.scss";

type SelectProps = {
  label?: string;
  containerClassName?: string;
};

const CustomSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  containerClassName,
  id,
  ...props
}: SelectProps & Props<Option, IsMulti, Group>) => {
  return (
    <div
      className={cx(styles.container, containerClassName)}
      data-testid={`select-container`}
    >
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        {...props}
        id={id}
        unstyled
        classNames={{
          control: (state) =>
            cx(styles.select, {
              [styles.focused]: state.isFocused,
            }),
          menu: () => styles.menu,
          option: (state) =>
            cx(styles.option, {
              [styles.selected]: state.isSelected,
              [styles.focused]: state.isFocused,
            }),
          loadingMessage: () => styles.loadingMessage,
        }}
      />
    </div>
  );
};

CustomSelect.displayName = "Select";

export { CustomSelect as Select };
