// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var content = "PageBody-module--content--471e6";
export var emphasis = "PageBody-module--emphasis--336a4";
export var green = "#57d33b";
export var greenLink = "PageBody-module--green-link--bdb20";
export var grey = "var(--tone-mid)";
export var loader = "PageBody-module--loader--fa18d";
export var loading = "PageBody-module--loading--1f4f6";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageBody = "PageBody-module--pageBody--ee847";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";