import { useLocation } from "./useLocation";

const includedPaths = [
  "/betting/",
  "/racing/betting/",
  "/activity-statement/",
  "/preferences/responsible-gambling/",
  "/preferences/cooldown/",
  "/offers/",
  "/land/",
  "/account/details/",
];

const excludedPaths = [
  "/race/",
  "/match/",
  "/outright/",
  "/meeting/",
  // shared betslip page
  "/betting/betslip/",
  "/preferences/betting/",
  "/bonus-offers/offers/",
];

export const isPathAllowed = (path: string): boolean => {
  if (path === "/") {
    return true;
  }

  const isIncluded = includedPaths.some((includedPath) =>
    path.includes(includedPath),
  );
  const isExcluded = excludedPaths.some((excludedPath) =>
    path.includes(excludedPath),
  );

  return isIncluded && !isExcluded;
};

/**
 *
 * @returns {boolean} true if the current path is in the betting section
 *
 * Examples:
 *
 *  * https://picklebet.com/en-au/wallet/deposit/ - false
 *  * https://picklebet.com/en-au/betting/ - true
 *  * https://picklebet.com/en-au/racing/betting/ - true
 *  * https://picklebet.com/en-au/activity-statement/ - true
 *  * https://picklebet.com/en-au/preferences/ - false
 *  * https://picklebet.com/en-au/pickems/ - false
 *
 */
export const useIsBettingSection = () => {
  const { pathnameWithoutLocale } = useLocation();

  return isPathAllowed(pathnameWithoutLocale);
};

export default useIsBettingSection;
