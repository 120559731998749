import React from "react";

import type { IFormFieldProps } from "./FormFieldTypes";
import Input, { type IInputProps } from "../Input";
import { FormFieldBase } from "./FormFieldBase";

export class FormFieldText<
  T extends IFormFieldProps = IFormFieldProps,
> extends FormFieldBase<T> {
  formFieldTextRef: React.RefObject<Input>;

  constructor(props: T) {
    super(props);
    this.formFieldTextRef = React.createRef<Input>();
    this.focus = this.focus.bind(this);
  }

  focus() {
    this.formFieldTextRef.current.focus();
  }

  render() {
    return this.renderTextInput();
  }

  protected renderTextInput(additionalInputProps: Partial<IInputProps> = {}) {
    const props = this.props;
    const { value } = this.getFieldState();

    const inputProps: IInputProps = {
      name: props.name,
      value,
      label: props.label,
      placeholder: props.placeholder,
      type: additionalInputProps.type ?? "text",
      className: props.className,
      disabled: props.disabled,
      onChange: (e) => this.handleChange(e),
      onBlur: (e) => this.handleBlur(e),
      onFocus: (e) => this.handleFocus(e),
      issue: this.getIssue(),
      maxLength: props.maxLength,
      icon: props.icon,
      gutterTop: props.gutterTop === true,
      gutterRight: props.gutterRight === true,
      gutterBottom: props.gutterBottom !== false,
      gutterLeft: props.gutterLeft === true,
      uppercase: props.uppercase,
      lowercase: props.lowercase,
      disableAutoComplete: props.disableAutoComplete,

      ...additionalInputProps,
    };

    return <Input {...inputProps} ref={this.formFieldTextRef} />;
  }
}
