import React, { useEffect } from "react";
import { Router, useLocation } from "@gatsbyjs/reach-router";
import {
  toast,
  useDispatch,
  useDisplayAtMost,
  useProfile,
  useSelector,
} from "hooks";
import { parse } from "query-string";
import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";
import useCalculateSmartBannerOffset from "hooks/useCalculateSmartBannerOffset";
import useIsLoggedIn from "hooks/firestore/user/useIsLoggedIn";
import useLockScroll from "hooks/useLockScroll";
import cx from "classnames";
import { onLogout } from "utilities/User/Actions";
import { setMenu, setModal } from "utilities/UI/uiSlice";

import {
  Footer,
  MainMenu,
  MobileNavigation,
  navigate,
  Navigation,
  Panel,
} from "library";
import { HelpCenter } from "../HelpCenter/HelpCenter";
import { HUDBase } from "hud/HUDBase";
import ErrorBoundary from "./ErrorBoundary";
import SmartBanner from "./SmartBanner";
import {
  SignUpModal,
  SignInModal,
  BetStopModalLogout,
  BetStopModalSignup,
} from "sections/Auth";
import TermsAndConditions from "sections/TermsAndConditions/Layout/TermsAndConditions";
import { Toaster } from "components/Toast";
import { LoadingProvider } from "context/LoadingContext";
import { ConfirmDialog } from "components/Dialog";
import { ReactComponent as LogoutIcon } from "components/assets/logout.svg";
import { ProfileCompletionModal } from "components/ProfileCompletionModal/ProfileCompletionModal";

import "../../styles/core.scss";
import * as styles from "./Section.module.scss";
import { OpenInAppDialog } from "../OpenInAppDialog/OpenInAppDialog";
import { CaptchaPuzzleModal } from "sections/Auth/CaptchaPuzzleModal";
import { WebBrazeInitializer } from "store/Braze";
import { SWRConfig } from "swr";
import { updateSession } from "utilities/Auth/authSlice";
import { useIsVerified } from "hooks/firestore/user/useIsVerified";
import { FullScreenModal } from "components/FullScreenModal";
import Verification from "sections/Account/Tabs/Verification";

const Section = ({ children }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.utilities.ui.menu);
  const modal = useSelector((state) => state.utilities.ui.modal);
  const { pathname } = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const smartBannerOffset = useCalculateSmartBannerOffset();
  const playerOffset = useCalculatePlayerOffset();
  const topOffset = smartBannerOffset + playerOffset;
  const isBetslipCollapsible = useDisplayAtMost("desktop");
  const shouldLockScroll = menu !== "none" && isBetslipCollapsible;
  const token = useSelector((state) => state.auth.token);
  const isVerified = useIsVerified();
  const [profile] = useProfile();
  const isAuOrNz = ["AU", "NZ"].includes(profile?.country);
  const isSignUpVisible = modal === "signUp";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const isLogin = pathname.includes("/login");
    const isSignUp = pathname.includes("/register");

    const affiliateLink =
      typeof window === "undefined" ? undefined : parse(location.search)["r"];

    if (isLogin || isSignUp) {
      navigate(affiliateLink ? `/?r=${affiliateLink}` : "/");

      if (!isLoggedIn) {
        const modal = isLogin ? "signIn" : "signUp";
        dispatch(setModal(modal));
      }
    }
  }, [pathname, isLoggedIn]);

  useLockScroll(shouldLockScroll);

  const fetcher = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      // if we are not authorized kill session
      if (res.status === 401 || res.status === 403) {
        dispatch(updateSession({ isGuest: true }));

        toast({
          description: "Your session has expired, please log in again.",
        });
      }

      return res.json();
    });

  // if we are not verified and in AU or NZ we need to show verification modal
  if (!isVerified && isLoggedIn && isAuOrNz && !isSignUpVisible) {
    return (
      <FullScreenModal
        contentClassName={styles.verificationModal}
        onClose={async () => {
          await dispatch(onLogout());
          await navigate("/");
        }}
        isOpen={true}
        withBackButton={true}
      >
        <Router primary={false} basepath={`/en-au/verification/`}>
          <Verification prefix={""} default />
        </Router>
        <HelpCenter />
      </FullScreenModal>
    );
  }

  return (
    <ErrorBoundary>
      <SWRConfig value={{ fetcher }}>
        <LoadingProvider>
          <HUDBase>
            <WebBrazeInitializer />
            <SmartBanner />
            <Navigation />
            <div
              className={styles.container}
              style={topOffset ? { marginTop: topOffset + 50 } : {}}
            >
              <MainMenu />
              <main className={styles.content}>{children}</main>
              <div
                className={cx(styles.overlay, {
                  [styles.visible]: menu !== "none",
                })}
                onClick={() => dispatch(setMenu("none"))}
              />
              <Panel />
            </div>
            <HelpCenter />
            <TermsAndConditions />
            <MobileNavigation />
            <div className={styles.footer}>
              <Footer />
            </div>
            <ConfirmDialog
              icon={<LogoutIcon />}
              title={`Logout`}
              description={`Are you sure you want to log out?`}
              onClose={() => dispatch(setModal(null))}
              isOpen={modal === "logout"}
              onConfirm={async () => await dispatch(onLogout())}
            />
            <SignInModal />
            <SignUpModal />
            <BetStopModalSignup />
            <BetStopModalLogout />
            <ProfileCompletionModal />
            <OpenInAppDialog />
            <CaptchaPuzzleModal />
            <Toaster />
          </HUDBase>
        </LoadingProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

export default Section;
