import cx from "classnames";
import { Button } from "components/Button";
import { Caret } from "components/Caret";
import { ReactComponent as TrashIcon } from "components/assets/trash.svg";
import { useDispatch } from "hooks";
import React from "react";
import { showLoginModal } from "utilities/Auth/authSlice";
import type { ComplexBetType } from "../../ComplexBetBuilder";

import * as styles from "./Slip.module.scss";

export const Trash = ({ onClick }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    className={styles.trash}
  >
    <TrashIcon />
  </div>
);

type SlipProps = {
  selectionsLength: number;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isLoggedIn: boolean;
  odds: number;
  loading: boolean;
  handleRemoveAllSelections: () => void;
  addToBetSlip: () => void;
  areAnySelectionsNotActive: boolean;
  renderSlipInfo?: () => React.ReactNode;
  requiredSelectionCount?: number;
  type: ComplexBetType;
};

export const Slip = ({
  type,
  selectionsLength,
  isOpen,
  setIsOpen,
  isLoggedIn,
  odds,
  loading,
  handleRemoveAllSelections,
  addToBetSlip,
  areAnySelectionsNotActive,
  renderSlipInfo,
  requiredSelectionCount = 2,
}: SlipProps) => {
  const dispatch = useDispatch();

  return (
    <div className={cx(styles.slip)} onClick={() => setIsOpen(!isOpen)}>
      <div
        className={cx(styles.showSelections)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Caret variant={isOpen ? "down" : "up"} className={styles.caret} />
      </div>

      <Trash onClick={handleRemoveAllSelections} />
      {renderSlipInfo()}
      <div className={styles.buttonContainer}>
        {isLoggedIn ? (
          <Button
            className={styles.addButton}
            loading={loading}
            variant="primary"
            size="sm"
            disabled={
              selectionsLength < requiredSelectionCount ||
              loading ||
              odds === 0 ||
              areAnySelectionsNotActive
            }
            onClick={(e) => {
              e.stopPropagation();
              addToBetSlip();
            }}
          >
            {areAnySelectionsNotActive
              ? "Selection Suspended"
              : selectionsLength < requiredSelectionCount
                ? `Add ${requiredSelectionCount - selectionsLength}+ ${type === "exotic" ? "Combo" : "Selection"}`
                : "Add to Betslip"}
          </Button>
        ) : (
          <Button
            className={styles.loginButton}
            loading={loading}
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              dispatch(showLoginModal());
            }}
          >
            Login to Bet
          </Button>
        )}
      </div>
    </div>
  );
};
