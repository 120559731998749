// extracted by mini-css-extract-plugin
export var active = "SGMShare-module--active--32af7";
export var blue = "#0eb3f2";
export var competitorContainer = "SGMShare-module--competitorContainer--f755a";
export var container = "SGMShare-module--container--1f294";
export var content = "SGMShare-module--content--e7bb7";
export var disabled = "SGMShare-module--disabled--ca28f";
export var emphasis = "SGMShare-module--emphasis--16e1d";
export var game = "SGMShare-module--game--84045";
export var green = "#57d33b";
export var greenLink = "SGMShare-module--green-link--740a1";
export var grey = "var(--tone-mid)";
export var heading = "SGMShare-module--heading--d5dc1";
export var icon = "SGMShare-module--icon--aefa4";
export var iconContainer = "SGMShare-module--iconContainer--787ba";
export var name = "SGMShare-module--name--620e2";
export var notchGap = "32px";
export var orange = "#f8662b";
export var price = "SGMShare-module--price--d5d3f";
export var red = "#ff2741";
export var selections = "SGMShare-module--selections--3cf6e";
export var silksContainer = "SGMShare-module--silksContainer--f2166";
export var subLabel = "SGMShare-module--subLabel--88ba3";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";