// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "Toggle-module--container--e4d48";
export var emphasis = "Toggle-module--emphasis--9587e";
export var green = "#57d33b";
export var greenLink = "Toggle-module--green-link--5b2ec";
export var grey = "var(--tone-mid)";
export var label = "Toggle-module--label--d8179";
export var noSpacing = "Toggle-module--noSpacing--a0fac";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var reverse = "Toggle-module--reverse--3fa65";
export var skeleton = "Toggle-module--skeleton--90c56";
export var thumb = "Toggle-module--thumb--8a35e";
export var track = "Toggle-module--track--b5933";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";