import React from "react";
import type { PickemsContest } from "hooks/firestore/pickems/types";
import { PickemsTransforms } from "selectors/PickemsTransforms";
import { useTitles } from "hooks/graphql/useTitles";
import ContestImage from "sections/Pickems/Play/ContestHeader/ContestImage";

import * as styles from "./PickemsContestSummary.module.scss";

type PickemsContestSummaryProps = {
  contest?: PickemsContest;
  marketCount?: number;
  remainingPickCount?: number;
  pointsEntered?: number;
};

export const PickemsContestSummary = ({
  contest,
  marketCount,
  remainingPickCount,
  pointsEntered,
}: PickemsContestSummaryProps) => {
  const titles = useTitles();

  return (
    <div className={styles.contestCard}>
      <ContestImage
        className={styles.cardImage}
        image={
          contest.cardImageUri ||
          PickemsTransforms.getLogos(contest?.sports, titles)
        }
      />
      <div className={styles.cardText}>
        <div>{contest.title}</div>
        <div className={styles.cardPicks}>
          {PickemsTransforms.parseContestStatus(contest.status)}:&nbsp;
          {remainingPickCount}/{marketCount} PICKS @&nbsp;
          {pointsEntered} PTS
        </div>
      </div>
    </div>
  );
};
