import React from "react";
import { motion } from "framer-motion";
import {
  useBetslip,
  useDispatch,
  useSelector,
  useUserEventFilters,
} from "hooks";
import { BetCard } from "./BetCard";
import { Empty } from "./Empty";
import { StakeAll } from "./StakeAll";
import { UnusedSelections } from "./UnusedSelections";
import { Combinations } from "./Combinations";
import {
  selectPossibleCombinations,
  selectSelectionsWithoutUnused,
  setStakeAllSingles,
} from "sections/Betting/Betslip/betslipSlice";
import { PermissionsGate } from "components/layout/PermissionsGate";

const Single = () => {
  const {
    props: {
      stakeAll,
      betslipIsConfirming,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
    },
  } = useBetslip();

  const userFilters = useUserEventFilters();

  const selections = useSelector(selectSelectionsWithoutUnused);
  const combinations = useSelector(selectPossibleCombinations);

  const dispatch = useDispatch();

  const canStakeAll =
    !betslipIsConfirming &&
    !betslipIsReviewingMessages &&
    !betslipIsSubmitting &&
    selections.length > 1;

  if (selections.length === 0 && combinations.length === 0) {
    return <Empty />;
  }

  return (
    <>
      {canStakeAll ? (
        <StakeAll
          label={`Stake All Singles`}
          onChange={(stake) => {
            dispatch(setStakeAllSingles({ stake, userFilters }));
          }}
          value={stakeAll}
        />
      ) : null}

      <motion.div>
        {selections.map((outcomeId) => (
          <BetCard key={`card-${outcomeId}`} outcomeIds={[outcomeId]} />
        ))}

        {combinations.length > 0 && (
          <PermissionsGate permissions={["submitComboMultiBet"]}>
            <Combinations />
          </PermissionsGate>
        )}

        <UnusedSelections />
      </motion.div>
    </>
  );
};

export { Single };
