import { useMemo } from "react";
import { getIsSRMAvailable } from "utilities/racingUtilities";
import { useContext } from "react";
import { RaceContext } from "../Race";
import { useSameRaceMulti } from "../components/SameRaceMulti/hooks/useSameRaceMulti";

const getMessage = ({
  validCompetitorCount,
  isSRMAvailable,
  raceMeeting,
  isSP,
}: {
  validCompetitorCount: number;
  isSRMAvailable: boolean;
  raceMeeting: { sport: string; country: string };
  isSP: boolean;
}): string => {
  if (validCompetitorCount < 4) {
    return "Unavailable for this event, as there are less than 4 runners";
  }

  if (!isSRMAvailable) {
    if (raceMeeting?.sport === "HORSE_RACING") {
      return raceMeeting?.country === "AU"
        ? "Available from 9am (local time) on the day of this event"
        : "Available one hour prior to the scheduled start time for this event";
    }
    return "Available 20 minutes before the scheduled start time for this event";
  }

  if (isSP) {
    return "Available as soon as fixed prices are available for this event";
  }

  return "";
};

const useIsSRMAvailable = () => {
  const { raceMeeting, raceTimezone, races, route, loading } =
    useContext(RaceContext);
  const { isSP, validCompetitorCount } = useSameRaceMulti();

  const race = useMemo(
    () => races?.find((r) => r.id === route.raceId),
    [route?.raceId, races],
  );

  const isAvailable = useMemo(
    () =>
      getIsSRMAvailable({
        scheduledStartTime:
          race?.sport === "HORSE_RACING"
            ? raceMeeting?.scheduledStartTime
            : race?.scheduledStartTime,
        raceTimezone,
        sport: race?.sport,
        semAvailableAtTs: race?.semAvailableAtTs,
      }),
    [race, raceTimezone, raceMeeting, loading],
  );

  const message = useMemo(
    () =>
      loading
        ? ""
        : getMessage({
            validCompetitorCount,
            isSRMAvailable: isAvailable,
            raceMeeting,
            isSP,
          }),
    [validCompetitorCount, isAvailable, raceMeeting, isSP, loading],
  );

  return [isAvailable, message];
};

export { useIsSRMAvailable };
