import { useEffect, useState } from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";

// determines if the Appsflyer smart banner is visible and returns the height of the banner
// because the appsflyer smart banner is attached as a child to the container element we need to listen for changes using a mutaiton observer and update the offset accordingly
const useCalculateSmartBannerOffset = () => {
  const [offset, setOffset] = useState<number>(0);

  const hasDocument = typeof document !== "undefined";
  const smartBannerContainerElement = hasDocument
    ? document.getElementById("smart-banner")
    : null;

  useEffect(() => {
    // ResizeObserverPolyfill is needed to support Safari v12 https://caniuse.com/?search=resizeobserver
    const resizeObserver = new ResizeObserverPolyfill(() => {
      if (smartBannerContainerElement) {
        setOffset(smartBannerContainerElement.clientHeight);
      }
    });

    if (smartBannerContainerElement) {
      resizeObserver.observe(smartBannerContainerElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [smartBannerContainerElement]);

  const isSmartBannerVisible =
    smartBannerContainerElement &&
    smartBannerContainerElement.children.length > 0;

  return isSmartBannerVisible ? offset : 0;
};

export default useCalculateSmartBannerOffset;
