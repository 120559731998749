// extracted by mini-css-extract-plugin
export var active = "Loader-module--active--27f28";
export var blue = "#0eb3f2";
export var emphasis = "Loader-module--emphasis--0f378";
export var green = "#57d33b";
export var greenLink = "Loader-module--green-link--93341";
export var grey = "var(--tone-mid)";
export var image = "Loader-module--image--cbc00";
export var loader = "Loader-module--loader--335a5";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageLoader = "Loader-module--pageLoader--6d215";
export var red = "#ff2741";
export var rotate = "Loader-module--rotate--4dc8a";
export var rotateAnimation = "Loader-module--rotateAnimation--76872";
export var rotating = "Loader-module--rotating--e5003";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";