// extracted by mini-css-extract-plugin
export var active = "RaceMeetingsFilter-module--active--146b1";
export var all = "RaceMeetingsFilter-module--all--18e19";
export var blue = "#0eb3f2";
export var emphasis = "RaceMeetingsFilter-module--emphasis--6193b";
export var filterIcon = "RaceMeetingsFilter-module--filterIcon--d682e";
export var green = "#57d33b";
export var greenLink = "RaceMeetingsFilter-module--green-link--2ea1f";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var raceMeetingFilters = "RaceMeetingsFilter-module--raceMeetingFilters--4c4df";
export var red = "#ff2741";
export var regionFilterGroup = "RaceMeetingsFilter-module--regionFilterGroup--ad939";
export var regionFilters = "RaceMeetingsFilter-module--regionFilters--7c9fd";
export var titleFilter = "RaceMeetingsFilter-module--titleFilter--4b297";
export var titleFilterItem = "RaceMeetingsFilter-module--titleFilterItem--39181";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";