// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ExoticDividends-module--emphasis--8d59e";
export var green = "#57d33b";
export var greenLink = "ExoticDividends-module--green-link--839d9";
export var grey = "var(--tone-mid)";
export var infoIcon = "ExoticDividends-module--infoIcon--b66b9";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var title = "ExoticDividends-module--title--b18de";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";