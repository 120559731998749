import React from "react";
import cx from "classnames";

import { Icon, ButtonWithState as Button } from "library";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectBetslipIsSubmitting } from "sections/Betting/Betslip/betslipSlice";

import * as styles from "./BetMessage.module.scss";

type BetMessageProps = {
  type?: "warning" | "error" | "info" | "success";
  icon?: string;
  message: string;
  className?: string;
  cta?: string;
  ctaCallback?: () => void;
  ctaType?: "secondary" | "primary";
};
export const BetMessage = (props: BetMessageProps) => {
  const isSubmitting = useSelector(selectBetslipIsSubmitting);
  const {
    type,
    message,
    className,
    cta,
    ctaCallback,
    ctaType = "secondary",
  } = props;

  let icon = props.icon;
  if (!icon) {
    switch (type) {
      case "error":
      case "warning":
        icon = "error";
        break;
      case "success":
        icon = "tickSmall";
        break;
    }
  }

  const { t } = useTranslation();

  return (
    <div className={cx(styles.message, styles[type], className)}>
      <div className={styles.icon}>
        <Icon type={icon} />
      </div>
      <div className={styles.content}>{t(message, message)}</div>
      {!isSubmitting && cta && ctaCallback ? (
        <div>
          <Button
            type={ctaType}
            className={styles.button}
            onClick={ctaCallback}
          >
            {cta}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
