import React from "react";
import cx from "classnames";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import { Caret } from "components/Caret";
import { Clamp } from "components/Clamp";

import * as styles from "./Calendar.module.scss";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Icon({ className }: { className?: string }) {
  return (
    <Clamp className={className} variant={`grey`}>
      <Caret className={styles.caret} variant={"right"} />
    </Clamp>
  );
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      formatters={{
        formatWeekdayName: (date, options) =>
          format(date, "iiiii", { locale: options?.locale }),
      }}
      showOutsideDays={showOutsideDays}
      className={cx(styles.calendar, className)}
      classNames={{
        months: styles.months,
        month: styles.month,
        caption: styles.caption,
        caption_label: styles.captionText,
        nav_button_previous: styles.previous,
        nav_button_next: styles.next,
        table: styles.table,
        head_row: styles.headRow,
        head_cell: styles.headCell,
        row: styles.row,
        cell: styles.cell,
        day: styles.day,
        day_selected: styles.daySelected,
        day_today: styles.dayToday,
        day_outside: styles.dayOutside,
        day_disabled: styles.dayDisabled,
        day_hidden: styles.dayInvisible,
        ...classNames,
      }}
      components={{
        IconLeft: () => <Icon className={styles.leftIcon} />,
        IconRight: () => <Icon />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
