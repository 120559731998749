// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "PageNotification-module--button--28874";
export var emphasis = "PageNotification-module--emphasis--9603f";
export var green = "#57d33b";
export var greenLink = "PageNotification-module--green-link--dfb22";
export var grey = "var(--tone-mid)";
export var icon = "PageNotification-module--icon--2b366";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageMessage = "PageNotification-module--pageMessage--348bd";
export var red = "#ff2741";
export var text = "PageNotification-module--text--3bf83";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";