// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "CompetitorIcon-module--emphasis--4c234";
export var flag = "CompetitorIcon-module--flag--92efb";
export var flagCompetitorName = "CompetitorIcon-module--flagCompetitorName--49b05";
export var flagOverlay = "CompetitorIcon-module--flagOverlay--db1c1";
export var franchise = "CompetitorIcon-module--franchise--18e5a";
export var green = "#57d33b";
export var greenLink = "CompetitorIcon-module--green-link--65a68";
export var grey = "var(--tone-mid)";
export var icon = "CompetitorIcon-module--icon--f6ded";
export var notchGap = "32px";
export var orange = "#f8662b";
export var player = "CompetitorIcon-module--player--623e1";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";