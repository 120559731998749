import React from "react";
import {
  type ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { message } from "utilities/message";
import type { IBetPickPickems, PickemsStatus } from "types/PickemsTypes";
import { BettingTransforms, PickemsTransforms } from "selectors";
import type { RootState } from "store/createStore";
import { setMenu, setPanel } from "utilities/UI/uiSlice";
import type {
  PickemsContest,
  PickemsEntry,
} from "hooks/firestore/pickems/types";
import { isDisplayAtMost } from "utilities/display";
import { getBettingChannel } from "utilities/bettingChannels";
import { toast } from "hooks/ui/useToast";
import { ToastAction } from "components/Toast";

export type PickslipState = {
  picks: IBetPickPickems[];
  contestId?: string;
  contestFilters: PickemsStatus[];
  contestFiltersSelected: PickemsStatus[];
  confirmContestId: string;
  isLoading?: boolean;
  isLoadingLists?: boolean;
  pickAcceptedId?: string;
  error?: string;
};

const initialState: PickslipState = {
  contestId: null,
  picks: [],
  contestFilters: ["PENDING", "LIVE"],
  contestFiltersSelected: ["PENDING", "LIVE"],
  confirmContestId: null,
  isLoading: false,
  pickAcceptedId: null,
  error: null,
};

export const submitPicks = createAsyncThunk(
  "Pickslip/submitPicks",
  async (
    { contest, entry }: { contest: PickemsContest; entry: PickemsEntry },
    { dispatch, getState },
  ) => {
    const state: { pickslip?: PickslipState; auth?: any } = getState();
    const { pickslip, auth } = state;

    if (pickslip.confirmContestId !== contest.id) {
      dispatch(updatePickslip({ confirmContestId: contest.id }));
      return;
    }

    const contestPicks = pickslip.picks.filter(
      (x) => x.sourceId === contest.id,
    );
    const selections = contestPicks.map((x) => ({
      outcomeId: x.outcomeId,
      points: Number(x.odds) || 0,
    }));

    const payload = {
      contestId: contest.id,
      selections,
      channel: getBettingChannel(),
    };

    const analyticsData = dispatch(createAnalyticsData(contest, entry));

    const [response, error] = await message(
      "pickems-submit-selection",
      payload,
    );

    if (error) {
      if (error.errors && error?.errors?.length) {
        throw error.errors[0];
      }

      throw error;
    }

    if (response.status === "SUCCESS") {
      analyticsData.payload.EntryId = response.data?.entryId;

      dispatch({
        type: analyticsData.eventType,
        payload: analyticsData.payload,
      });

      dispatch(updatePickslip({ picks: [] }));

      return response.data?.entryId;
    }
  },
);

export const createAnalyticsData =
  (contest: PickemsContest, entry: PickemsEntry) =>
  (_dispatch, getState: () => RootState) => {
    const state = getState();
    const { pickslip } = state;

    const BASE = "PICKEMS/";
    const ENTRY_SUBMITTED = BASE + "ENTRY_SUBMITTED";
    const ENTRY_UPDATED = BASE + "ENTRY_UPDATED";

    const scheduledStartTime = contest.scheduledStartTime;
    const hoursBeforeStart = Math.round(
      BettingTransforms.getHoursUntil(scheduledStartTime),
    );
    const payload: any = {
      ContestId: pickslip.contestId,
      EntryFee: 0,
      Currency: "USD",
      HoursBeforeStart: hoursBeforeStart,
      Title: contest.title,
      Sports: contest?.sports,
      ScheduledStartTime: scheduledStartTime,
      PrizePool: PickemsTransforms.totalPrizePoolAsDouble(contest?.prizePool),
      PayoutPlacesCount: contest.prizePool.length,
      MarketCount: contest.marketCounts.total,
      Competitors: pickslip.picks.map(
        (x: IBetPickPickems) => x.competitor?.name,
      ),
      PointsPotential: selectPointsPotential(state, contest, entry),
      SelectionCount: pickslip.picks.length,
      EntryCount: contest.entries.total,
    };
    // determine if this is the first submission for this user in the contest
    const eventType = Boolean(entry) ? ENTRY_UPDATED : ENTRY_SUBMITTED;
    return { payload, eventType };
  };

const submitPicksThunk = (builder: ActionReducerMapBuilder<PickslipState>) => {
  builder
    .addCase(submitPicks.pending, (state: PickslipState) => {
      state.isLoading = true;
    })
    .addCase(submitPicks.fulfilled, (state: PickslipState, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.confirmContestId = null;
        state.pickAcceptedId = action.payload;
      }
    })
    .addCase(submitPicks.rejected, (state: PickslipState, action) => {
      state.error = action.error.message;
      state.isLoading = false;
      state.pickAcceptedId = null;
      state.confirmContestId = null;
    });
};

const pickslipSlice = createSlice({
  name: "Pickslip",
  initialState,
  extraReducers: (builder) => {
    submitPicksThunk(builder);
  },
  reducers: {
    acceptPick(state, action: PayloadAction<IBetPickPickems>) {
      const pick = action.payload;
      const pickIndexToAccept = state.picks.findIndex(
        (p) => p.marketId === pick.marketId,
      );

      if (pickIndexToAccept < 0) {
        console.warn("Cannot find pick in state to accept");
        return;
      }

      const pickCopy = { ...state.picks[pickIndexToAccept] };
      pickCopy.accepted = true;
      pickCopy.odds = pickCopy.lockedOdds = pickCopy.freshOdds = pick.freshOdds;
      pickCopy.oddsChanged = false;

      state.picks.splice(pickIndexToAccept, 1, pickCopy);
    },
    togglePick(
      state,
      action: PayloadAction<{ pick: IBetPickPickems; contest: PickemsContest }>,
    ) {
      const { pick, contest } = action.payload;
      const pickDetails = PickemsTransforms.getPickDetails(pick, contest);

      const picks = state.picks;
      const existingPickIndex = picks.findIndex(
        (x) => x.sourceId === pick.sourceId && x.outcomeId === pick.outcomeId,
      );
      const isExistingPick = existingPickIndex > -1;
      const existingMarketPickIndex = picks.findIndex(
        (statePick) => statePick.marketId === pick.marketId,
      );
      const isExistingMarket = existingMarketPickIndex > -1;

      if (isExistingPick) {
        picks.splice(existingPickIndex, 1);
      } else if (isExistingMarket) {
        picks.splice(existingMarketPickIndex, 1, pickDetails);
      } else {
        picks.push(pickDetails);
      }

      state.picks = picks;
      state.confirmContestId = null;
    },
    clearPicks(state) {
      state.picks = [];
    },
    removePick(state, action: PayloadAction<Partial<IBetPickPickems>>) {
      const picksCopy = state.picks;

      const pickIndexToRemove = picksCopy.findIndex(
        (pick) => pick.marketId === action.payload.marketId,
      );

      if (pickIndexToRemove < 0) {
        return;
      }

      picksCopy.splice(pickIndexToRemove, 1);
      state.picks = picksCopy;
    },
    setSelectedContestFilters(state, action: PayloadAction<PickemsStatus[]>) {
      state.contestFiltersSelected = action.payload;
    },
    updatePickslip(state, action: PayloadAction<Partial<PickslipState>>) {
      const { payload } = action;

      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
});

export const selectPickslip = (state: RootState) => state.pickslip;

export const selectPicks = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.picks ?? [],
);

export const selectContestFilters = createSelector(
  selectPickslip,
  (pickslip) => ({
    contestFiltersSelected: pickslip?.contestFiltersSelected,
    contestFilters: pickslip?.contestFilters,
  }),
);

export const selectPickAcceptedId = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.pickAcceptedId,
);

export const selectContestId = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.contestId,
);

export const selectContestStats = (
  contests: PickemsContest[],
  entries: PickemsEntry[],
) => {
  const contestIds = entries?.map((entry) => entry.contestId) ?? [];
  const enteredContests = contestIds
    .map((contestId) => contests?.find((contest) => contest.id === contestId))
    .filter(Boolean);

  return enteredContests.map((contest) => {
    const entry = entries.find((e) => e.contestId === contest.id);
    return PickemsTransforms.parseContestStats(contest, entry.picks, []);
  });
};

export const selectNewContestPicks = (
  state: RootState,
  entry: PickemsEntry,
) => {
  if (!entry) return state.pickslip.picks;

  return state.pickslip.picks.filter(
    (pickInState) =>
      !entry.picks?.some(
        (pickInEntry) => pickInEntry.eventId === pickInState.eventId,
      ),
  );
};

export const selectPointsPotential = (
  state: RootState,
  contest: PickemsContest,
  entry: PickemsEntry,
) => {
  if (!contest) return 0;

  const selections = entry?.picks ?? [];
  const picks = state.pickslip?.picks;
  const points = PickemsTransforms.parsePointsEntered(
    contest,
    selections,
    picks,
  );

  return points;
};

export const selectContestPicks = createSelector(selectPickslip, (pickslip) => {
  return pickslip?.picks ?? [];
});

export const selectPickemsNotificationCount = createSelector(
  selectPickslip,
  (pickslip) => {
    return pickslip?.picks?.length ?? 0;
  },
);

export const selectConfirmContestId = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.confirmContestId,
);

export const selectPickslipError = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.error,
);

export const selectPickslipLoading = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.isLoading,
);

export const selectActiveOutcomes = createSelector(
  selectPickslip,
  (pickslip) => pickslip?.picks?.map((pick) => pick.outcomeId) ?? [],
);

export const togglePick =
  ({ pick, contest }: { pick: IBetPickPickems; contest: PickemsContest }) =>
  (dispatch, getState) => {
    const picks = getState().pickslip.picks;
    const existingPick = picks.find(
      (existingPick) =>
        existingPick.sourceId === pick.sourceId &&
        existingPick.outcomeId === pick.outcomeId,
    );
    const menu = (getState() as any).utilities.ui.menu;

    // we only want to show the notification on mobile or tablet views as the pickslip panel isn't focused
    if (menu !== "panel" && isDisplayAtMost("tablet") && !existingPick) {
      toast({
        description: "Pick added to Pickslip",
        action: (
          <ToastAction
            variant={`info`}
            onClick={() => {
              dispatch(setPanel("pickslip"));
              dispatch(setMenu("right"));
            }}
            altText={`Open Pickslip`}
          >
            Open
          </ToastAction>
        ),
      });
    }

    dispatch(pickslipSlice.actions.togglePick({ pick, contest }));
  };

export const {
  acceptPick,
  clearPicks,
  removePick,
  updatePickslip,
  setSelectedContestFilters,
} = pickslipSlice.actions;

export default pickslipSlice.reducer;
