import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { Logo } from "../Logo";
import { Trash } from "../Slip/Slip";
import type { ComplexBetType } from "../../ComplexBetBuilder";

import * as styles from "./ErrorMessage.module.scss";

const errorMessageVariants = cva(styles.errorMessage, {
  variants: {
    variant: {
      info: styles.info,
      error: styles.error,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

type errorMessageType = VariantProps<typeof errorMessageVariants> & {
  errorMessage: string;
  type: ComplexBetType;
  handleRemoveAllSelections?: () => void;
};

export default ({
  errorMessage,
  type,
  handleRemoveAllSelections,
  variant,
}: errorMessageType) => (
  <>
    {errorMessage ? (
      <div className={errorMessageVariants({ variant })}>
        <span className={styles.srmLogo}>
          <Logo type={type} />
        </span>
        <span className={styles.message}>{errorMessage}</span>
        {handleRemoveAllSelections && (
          <Trash onClick={handleRemoveAllSelections} />
        )}
      </div>
    ) : null}
  </>
);
