import React from "react";
import cx from "classnames";

import * as styles from "./ButtonGroup.module.scss";

type ButtonGroupProps = {
  className?: string;
  children: React.ReactNode;
};

const ButtonGroup = ({ children, className }: ButtonGroupProps) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

export { ButtonGroup };
