import React from "react";
import * as styles from "./SlideOut.module.scss";
import { motion } from "framer-motion";

type SlideOutProps = {
  children?: React.ReactNode;
  onClose?: () => void;
};

const SlideOut = ({ children, onClose }: SlideOutProps) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.overlay}
        onClick={() => onClose && onClose()}
      />
      <motion.div
        initial={{ bottom: "-100%" }}
        animate={{ bottom: 0 }}
        exit={{ bottom: "-100%" }}
        className={styles.container}
      >
        {children}
      </motion.div>
    </>
  );
};

export { SlideOut };
