import React from "react";
import * as ToggleGroupPrimitives from "@radix-ui/react-toggle-group";

type ToggleGroupRootProps =
  | ToggleGroupPrimitives.ToggleGroupSingleProps
  | ToggleGroupPrimitives.ToggleGroupMultipleProps;
const ToggleGroupRoot = React.forwardRef<HTMLDivElement, ToggleGroupRootProps>(
  ({ children, ...props }, ref) => {
    return (
      <ToggleGroupPrimitives.Root ref={ref} {...props}>
        {children}
      </ToggleGroupPrimitives.Root>
    );
  },
);

ToggleGroupRoot.displayName = "ToggleRoot";

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitives.Item>,
  ToggleGroupPrimitives.ToggleGroupItemProps
>(({ children, ...props }, ref) => {
  return (
    <ToggleGroupPrimitives.Item ref={ref} {...props}>
      {children}
    </ToggleGroupPrimitives.Item>
  );
});
ToggleGroupItem.displayName = "ToggleItem";

export { ToggleGroupItem, ToggleGroupRoot, ToggleGroupRootProps };
