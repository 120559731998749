import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  differenceInSeconds,
  format,
  isBefore as isDateBefore,
} from "date-fns";
import { useInterval } from "usehooks-ts";
import { formatDistanceToNowAbbr } from "utilities/datetime";

import * as styles from "./Countdown.module.scss";

type CountdownProps = {
  until?: Date;
  className?: string;
  prefix?: string;
  suffix?: string | JSX.Element;
  expired?: string;
  override?: boolean;
  onlyBiggest?: boolean;
  disabledMouseover?: boolean;
  alwaysUpdate?: boolean;
  onExpire?: () => void;
};

const MS_SECOND = 1000;
const MS_MINUTE = MS_SECOND * 60;

const minuteToSecond = (minute: number) => minute * 60;

const Countdown = ({
  className: classNameProp,
  disabledMouseover,
  prefix,
  suffix,
  until,
  alwaysUpdate,
  expired,
  onExpire,
}: CountdownProps) => {
  const [remaining, setRemaining] = useState(formatDistanceToNowAbbr(until));
  const [isExpired, setIsExpired] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const isBefore = isDateBefore(until, new Date());

  const diffInSeconds = differenceInSeconds(until, new Date());
  const isExpiring = diffInSeconds < minuteToSecond(2);
  const isEminent = diffInSeconds < minuteToSecond(10);
  const isSoon =
    diffInSeconds > minuteToSecond(10) && diffInSeconds < minuteToSecond(20);

  const updateInterval =
    diffInSeconds <= 5 * 60 || alwaysUpdate ? MS_SECOND : MS_MINUTE;

  useInterval(
    () => {
      setRemaining(formatDistanceToNowAbbr(until));
    },
    isExpired ? null : updateInterval,
  );

  useEffect(() => {
    if (expired === "LIVE") {
      setIsExpired(true);
      setIsLive(true);
      if (typeof onExpire === "function") {
        onExpire();
      }
    } else {
      setIsLive(false);
      setIsExpired(false);
    }
  }, [expired, until]);

  useEffect(() => {
    setRemaining(formatDistanceToNowAbbr(until));
  }, [until]);

  return (
    <div
      className={cx(
        {
          [styles.countdown]: true,
          [styles.mouseover]: !disabledMouseover,
          [styles.eminent]: isEminent,
          [styles.soon]: isSoon,
          [styles.expiring]: isExpiring,
          [styles.live]: isLive,
        },
        classNameProp,
      )}
    >
      <div className={styles.ticker}>
        {isBefore && prefix ? (
          <span className={styles.prefix}>{prefix}</span>
        ) : null}

        <span>{isExpired && expired ? expired : remaining}</span>

        {isBefore && suffix ? (
          <span className={styles.suffix}>{suffix}</span>
        ) : null}
      </div>
      <div className={styles.time}>
        {until ? format(until, "dd/MM/yy h:mma") : "-"}
      </div>
    </div>
  );
};

export default Countdown;
