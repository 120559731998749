import React from "react";
import { ButtonWithState } from "library";
import type { IFormHandle } from "middleware/forms";

export const FormButtonSubmit = ({
  form,
  label,
  children,
  pretouched = false,
  gutterTop = false,
  gutterRight = false,
  gutterBottom = false,
  gutterLeft = false,
  className,
  ...rest
}: {
  children?: React.ReactNode;
  form: IFormHandle;
  pretouched?: boolean;
  label?: string | JSX.Element;
  className?: string;
} & IHasGutters) => {
  const buttonChildren = label || children || "Submit";
  const isTouchSatisfied = pretouched || form.isTouched;
  const disabled = !!form.isSubmitting || !form.isValid || !isTouchSatisfied;
  return (
    <ButtonWithState
      isSubmit
      expand
      type="success"
      loading={form.isSubmitting}
      disabled={disabled}
      gutterTop={gutterTop}
      gutterRight={gutterRight}
      gutterBottom={gutterBottom}
      gutterLeft={gutterLeft}
      className={className}
      {...rest}
    >
      {buttonChildren}
    </ButtonWithState>
  );
};
