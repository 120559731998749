import { useDispatch } from "hooks";
import { useSelector } from "hooks/useSelector";
import { useEffect } from "react";
import { setIsGooglePayAvailable } from "sections/Wallet/walletSlice";
import { isWebView } from "utilities/display";

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const data = {
  ...baseRequest,
  environment: (process.env.GATSBY_SKRILLA_ENV === "production"
    ? "PRODUCTION"
    : "TEST") as google.payments.api.Environment,
};

const create = async (): Promise<boolean> => {
  const client: typeof google.payments.api.PaymentsClient =
    await loadGooglePayJS();

  const readyToPayRequest: google.payments.api.IsReadyToPayRequest = {
    ...baseRequest,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["CRYPTOGRAM_3DS"],
          //Limit to mastercard only as there is no liability shift for Visa over GPay event with CRYPTOGRAM_3DS
          allowedCardNetworks: ["MASTERCARD"],
          //to disable credit cards
          //see https://developers.google.com/pay/api/web/reference/request-objects#CardParameters
        },
      },
    ],
  };

  const paymentClient = new client(data);

  const response: google.payments.api.IsReadyToPayResponse =
    await paymentClient.isReadyToPay(readyToPayRequest);

  return response?.result;
};

const loadGooglePayJS = (): Promise<
  typeof google.payments.api.PaymentsClient
> => {
  return new Promise<typeof google.payments.api.PaymentsClient>((resolve) => {
    if (window?.google?.payments?.api?.PaymentsClient) {
      const google = window.google.payments.api.PaymentsClient;
      resolve(google);
      return;
    }

    const existing = document.getElementsByClassName("mw-gp");
    let script: HTMLScriptElement;

    if (existing.length > 0) {
      script = existing.item(0) as HTMLScriptElement;
    } else {
      script = document.createElement("script");
      script.src = "https://pay.google.com/gp/p/js/pay.js";
      script.type = "text/javascript";
      script.classList.add("mw-gp");
      document.head.appendChild(script);
    }

    script.addEventListener("load", () => {
      const google = window.google.payments.api.PaymentsClient;
      resolve(google);
      return;
    });
  });
};

export const useIsGooglePayAvailable = () => {
  const dispatch = useDispatch();
  const googlePayAvailable = useSelector(
    (state) => state.sections.wallet.appGooglePayAvailable,
  );

  useEffect(() => {
    (async () => {
      if (
        isWebView() ||
        googlePayAvailable ||
        process.env.GATSBY_ENABLE_GOOGLE_PAY === "false"
      )
        return;
      const response = await create();
      dispatch(setIsGooglePayAvailable(response));
    })();
  }, [googlePayAvailable]);

  return googlePayAvailable;
};

export default useIsGooglePayAvailable;
