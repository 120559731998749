import React, { useContext } from "react";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import type { MappedCompetitorType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";
import { ReactComponent as FlucsIcon } from "./assets/icon-flucs.svg";
import * as styles from "./FlucsHistory.module.scss";
import Odds from "sections/Betting/Odds";
import { FirebaseContext } from "context/Firebase";
import cx from "classnames";
import { RaceContext } from "sections/Betting/Race/Race";
import { AnimatePresence, motion } from "framer-motion";
import {
  type OddsChange,
  useUpdatedOddsArray,
} from "sections/Betting/Race/components/FlucsHistory/useUpdatedOddsArray";

type OddsHistoryChangeType = Omit<OddsChange, "id"> & {
  isOpeningOdds: boolean;
};

const OddsHistoryChange = React.forwardRef(
  (
    { odds, isOpeningOdds, direction }: OddsHistoryChangeType,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { userPreferences } = useContext(FirebaseContext);

    return (
      <motion.div
        ref={ref}
        className={cx(styles.oddsChange, {
          [styles.isOpeningOdds]: isOpeningOdds,
        })}
        layout
        animate={{ scale: 1, opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 20,
        }}
      >
        {isOpeningOdds && <span>OP:</span>}
        <Odds
          base={Number(odds)}
          format={userPreferences.oddsFormat}
          zero={`-`}
        />
        {!isOpeningOdds && !!direction && (
          <div className={cx(styles.arrow, styles[direction])}>
            {direction === "up" ? <ArrowUp /> : <ArrowDown />}
          </div>
        )}
      </motion.div>
    );
  },
);

const containerVariants = {
  inactive: {
    height: "0.1rem",
    padding: "0rem 1rem 0rem",
    paddingBottom: "2rem",
  },
  active: {
    height: "4.3rem",
    padding: "2.6rem 1rem 0.6rem",
    paddingBottom: "0rem",
  },
};

export const FlucsHistory = ({
  competitor,
}: {
  competitor: MappedCompetitorType;
}) => {
  const { selectedPills, hasFlucs } = useContext(RaceContext);

  const showFlucs = selectedPills.includes("flucs");

  const outcome = Object.values(competitor?.markets).find(
    (x) => x.marketType === RaceMarketTypeTypes.Win,
  )?.outcome;

  const currentOdds = useUpdatedOddsArray(
    outcome?.recentOdds ?? [],
    Number(outcome?.openingOdds),
  );

  if (!outcome?.openingOdds || competitor.scratched || !hasFlucs) return null;

  return (
    <motion.div
      className={styles.container}
      variants={containerVariants}
      animate={showFlucs ? "active" : "inactive"}
      initial={false}
    >
      <div className={styles.flucsIcon}>
        <FlucsIcon />
      </div>
      <AnimatePresence mode={"popLayout"} initial={false}>
        <OddsHistoryChange
          odds={Number(outcome.openingOdds)}
          isOpeningOdds={true}
          direction="up"
        />

        {currentOdds.map((changes) => (
          <OddsHistoryChange
            key={changes.id}
            odds={changes.odds}
            isOpeningOdds={false}
            direction={changes.direction}
          />
        ))}
      </AnimatePresence>
    </motion.div>
  );
};
