// extracted by mini-css-extract-plugin
export var active = "RegionMenu-module--active--25566";
export var blue = "#0eb3f2";
export var disabled = "RegionMenu-module--disabled--2d5c1";
export var emphasis = "RegionMenu-module--emphasis--25b1a";
export var green = "#57d33b";
export var greenLink = "RegionMenu-module--green-link--d3780";
export var grey = "var(--tone-mid)";
export var language = "RegionMenu-module--language--0b320";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var regionMenu = "RegionMenu-module--regionMenu--6d119";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";