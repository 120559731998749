import * as React from "react";
import { useDispatch, useSelector } from "hooks";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { setModal } from "utilities/UI/uiSlice";
import { Typography } from "components/Typography";
import { Link } from "components/Link";

export const BET_STOP_MODAL_ID_LOGOUT = "betStopLogout";

export const BetStopModalLogout = () => {
  const isOpen = useSelector(
    (state) => state.utilities.ui.modal === BET_STOP_MODAL_ID_LOGOUT,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"National Self-Exclusion Register"}
    >
      <Typography variant="body">
        Due to your details appearing on the BetStop National Self-Exclusion
        Register, we are required to close your betting account immediately, in
        accordance with the Interactive Gambling Act 2001.
      </Typography>

      <Typography variant="h4">What happens now?</Typography>

      <Typography variant="body">
        Any available balance in your closed account will be refunded to the
        nominated bank account you have provided for withdrawals. If there are
        no current withdrawal details nominated,{" "}
        <Link to="https://live-chat"> Support</Link> will be in contact to
        collect them. Pending bets will stand and be settled post their
        completion. Any winnings from these events will be withdrawn to your
        nominated bank account, post settlement.
      </Typography>
    </FullScreenModal>
  );
};
