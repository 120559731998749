import { ATTRIBUTION_KEY, ISLOCALHOST } from "appConfig";
import localObject from "utilities/localObject";
import { isWebView } from "./display";

let analyticsLoaded = false;

const excludedUserAgentFragments = [
  "HeadlessChrome",
  "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.82 Safari/537.36",
  "SnapchatAds",
  "Baiduspider",
  "Bingbot",
  "DuckDuckBot",
  "Exabot",
  "Googlebot",
  "Slurp",
  "SnapchatAds",
  "Sogou",
  "YandexBot",
  "facebookexternalhit",
  "facebot",
  "ia_archiver",
  "moatbot",
];

export function enableAnalytics() {
  //Note in gatsby-config that gatsby-plugin-segment-js has manualLoad set to true so it wont load until we get here
  if (typeof window !== "undefined") {
    if (analyticsLoaded) {
      console.info("Skipping analytics already loaded");
    } else if (ISLOCALHOST && !process.env.GATSBY_ENABLE_LOCAL_ANALYTICS) {
      // eslint-disable-next-line
      console.info("Skipping loading of analytics for localhost");
    } else if (
      navigator.userAgent &&
      excludedUserAgentFragments.some((str) =>
        navigator.userAgent.includes(str),
      )
    ) {
      //Skip loading of  analytics for bots
      // eslint-disable-next-line
      console.warn("Suspected bot with user agent: " + navigator.userAgent);
    } else {
      window?.analytics?.ready(() => {
        if (typeof window.ga === "function") {
          //Install ready callback into GA
          window.ga(function (tracker) {
            //extract the GA clientId from the cookie and store it for sending to server in sign up call
            const gaClientId = tracker.get("clientId");
            // eslint-disable-next-line @typescript-eslint/ban-types
            const [attribution] = localObject.getValue<Record<string, any>>(
              ATTRIBUTION_KEY,
              {},
            );
            localObject.setValue(ATTRIBUTION_KEY, {
              ...attribution,
              gaClientId,
            });
          });
        } else {
          console.log("GA not found");
        }

        /*
        This stores super property against current user identity
        It will only be saved once, first time user lands on any page
         */
        if (window.mixpanel) {
          window.mixpanel.people.set_once({
            "Initial Landing Url": window.location.href,
          });
        }

        console.log("Analytics.js ready");
      });
      const writeKey =
        window.___SEGMENT_WEBVIEW_WRITE_KEY || process.env.GATSBY_SEGMENT_PROD;
      writeKey && window?.analytics?.load(writeKey);
      analyticsLoaded = true;
      console.log("Analytics.js has been loaded.");
    }
  }
}
