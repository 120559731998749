// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "PromoIcon-module--emphasis--0a2b9";
export var green = "#57d33b";
export var greenLink = "PromoIcon-module--green-link--23816";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var promoIcon = "PromoIcon-module--promoIcon--f69c4";
export var red = "#ff2741";
export var tabPromoIcon = "PromoIcon-module--tabPromoIcon--1d1ba";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";