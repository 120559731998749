import React from "react";
import cx from "classnames";
import { Icon } from "../Icon";
import * as styles from "./RemoveButton.module.scss";

type RemoveButtonProps = {
  className?: string;
  onClick?: () => void;
};

export const RemoveButton = ({ className, onClick }: RemoveButtonProps) => (
  <button className={cx(styles.removeButton, className)} onClick={onClick}>
    <Icon type="close" />
  </button>
);
