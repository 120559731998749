import { useEffect, useState } from "react";
import { useDocumentData } from "hooks";
import { getFirestore } from "store/getFirebase";
import type { PublicProfile } from "hooks/firestore/user/types";
import { doc } from "firebase/firestore";
import { useLocalStorage } from "usehooks-ts";

const usePublicProfile = (userId): [PublicProfile | undefined, boolean] => {
  const [profile, setProfile] = useLocalStorage<PublicProfile>(
    `profile:${userId}`,
    undefined,
  );
  const [loading, setLoading] = useState(!profile);

  const ref = userId
    ? doc(getFirestore(), "publicUserProfiles", userId)
    : undefined;
  const [liveProfile, liveLoading] = useDocumentData<PublicProfile>(ref as any);

  useEffect(() => {
    if (liveLoading) {
      return;
    }

    setProfile(liveProfile);
    setLoading(false);
  }, [liveProfile, liveLoading]);

  return [profile, loading];
};

export default usePublicProfile;
