// extracted by mini-css-extract-plugin
export var active = "Banner-module--active--1105a";
export var banners = "Banner-module--banners--96d28";
export var blue = "#0eb3f2";
export var button = "Banner-module--button--bc4c3";
export var control = "Banner-module--control--305ba";
export var controls = "Banner-module--controls--f0362";
export var emphasis = "Banner-module--emphasis--e9425";
export var green = "#57d33b";
export var greenLink = "Banner-module--green-link--53af6";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";