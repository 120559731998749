// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var calendar = "Calendar-module--calendar--a486b";
export var caption = "Calendar-module--caption--1b21c";
export var captionText = "Calendar-module--captionText--50e92";
export var caret = "Calendar-module--caret--9da9c";
export var cell = "Calendar-module--cell--c3c84";
export var day = "Calendar-module--day--b3cc5";
export var dayDisabled = "Calendar-module--dayDisabled--951de";
export var dayInvisible = "Calendar-module--dayInvisible--f9346";
export var dayOutside = "Calendar-module--dayOutside--700db";
export var daySelected = "Calendar-module--daySelected--82125";
export var dayToday = "Calendar-module--dayToday--bfd84";
export var emphasis = "Calendar-module--emphasis--4bff1";
export var green = "#57d33b";
export var greenLink = "Calendar-module--green-link--1c630";
export var grey = "var(--tone-mid)";
export var headCell = "Calendar-module--headCell--451c6";
export var headRow = "Calendar-module--headRow--c0c88";
export var leftIcon = "Calendar-module--leftIcon--b7662";
export var month = "Calendar-module--month--94a0f";
export var months = "Calendar-module--months--71fe3";
export var next = "Calendar-module--next--987ab";
export var notchGap = "32px";
export var orange = "#f8662b";
export var previous = "Calendar-module--previous--8a584";
export var red = "#ff2741";
export var row = "Calendar-module--row--73a0e";
export var table = "Calendar-module--table--4b5c5";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";