import React from "react";
import { I18nextProvider } from "react-i18next";
import browserLang from "browser-lang";
import jstz from "jstz";
import { withPrefix } from "gatsby";
import { LOCALE_KEY, LOCALES, DEFAULT_LOCALE } from "../appConfig";
import { LocaleApplied } from "context/LocaleApplied";
import { Section } from "../library";
import { PageContext } from "../context/Page";
import getI18nInstance from "utilities/i18n";
import withScripts from "./withScripts";
import localObject from "utilities/localObject";
import type { i18n } from "i18next";

const withI18next =
  (i18n: i18n, needsRedirect: boolean) => (children: React.ReactNode) => {
    return (
      <LocaleApplied.Provider value={!needsRedirect}>
        <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
      </LocaleApplied.Provider>
    );
  };

const getTzLanguage = () => {
  const tz = jstz.determine().name();

  if (tz.startsWith("Australia")) {
    return "en-au";
  } else if (tz.startsWith("Canada")) {
    return "en-ca";
  } else if (tz.startsWith("America")) {
    return "en-us";
  }

  return null;
};

const changeLocale = (newLocale: string, pathname: string) => {
  if (typeof window !== "undefined") {
    if (!LOCALES.map((locale) => locale.toLowerCase()).includes(newLocale)) {
      //dont allow user to set locale to an unsupported value
      return;
    }

    // same locale
    if (newLocale === window.___currentLocale) {
      return;
    }

    let split = pathname.split("/");

    if (split[1].length === 5 && split[1].indexOf("-") === 2) {
      split = split.slice(2);
    }

    const newPath = split.filter(Boolean).join("/");

    localObject.setValue<string>(LOCALE_KEY, newLocale);
    window.___currentLocale = newLocale;

    if (!window.___hasTranslations) {
      // if there are no translations - no redirect required
      return;
    }

    if (newLocale === window.___defaultLocale) {
      window.___replace(`/${newPath}`);
    } else {
      window.___replace(`/${newLocale}/${newPath}`);
    }
  }
};

export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode;
  props: {
    pageContext: {
      hasTranslations: boolean;
      locale: string;
      section: string;
    };
    data: any;
    location: Location;
  };
}) => {
  const { hasTranslations, locale, section } = props.pageContext;

  const {
    data,
    location: { pathname, search = "" },
  } = props;
  const defaultLanguage = "en-US";
  const localeNodes = data?.locales?.edges || [];
  const i18n = getI18nInstance();
  let needsRedirect = false;

  if (typeof window !== "undefined" && locale) {
    // 1. check local storage for a saved value
    // 2. look at timezone and get locale from a country
    // 3. fallback to browser preferences
    const [storedLocale] = localObject.getValue<string>(LOCALE_KEY);
    const detected =
      storedLocale ||
      getTzLanguage() ||
      browserLang({
        languages: LOCALES.map((locale) => locale.toLowerCase()),
        fallback: DEFAULT_LOCALE.toLowerCase(),
      });

    window.___currentLocale = locale.toLowerCase();
    window.___detectedLocale = detected.toLowerCase();
    window.___changeLocale = changeLocale;
    const currentLanguage = locale.toLowerCase();
    window.___defaultLocale = defaultLanguage.toLowerCase();
    window.___defaultSection = "betting";
    window.___hasTranslations = hasTranslations;
    window.___country = locale.split("-")[1];

    needsRedirect =
      currentLanguage === defaultLanguage.toLowerCase() &&
      currentLanguage !== detected &&
      hasTranslations &&
      // Used for local testing. The is AppleWebview takes care of this in the app.
      pathname.indexOf("stream") === -1;

    if (needsRedirect) {
      const newUrl = withPrefix(`/${detected}${pathname}${search}`);

      // actually redirect user
      window.___replace(newUrl);
    }
  }

  i18n.init({
    lng: locale,
    defaultNS: "translation",
    fallbackNS: false,
    keySeparator: "<.>",
    react: {
      useSuspense: false,
    },
  });

  localeNodes.forEach(
    ({ node }: { node: { data: string; language: string; ns: string } }) => {
      const parsedData = JSON.parse(node.data);
      i18n.addResourceBundle(node.language, node.ns, parsedData);
    },
  );

  if (i18n.language !== locale) {
    i18n.changeLanguage(locale);
  }

  const excludedSections = ["stream", "process-mw"];

  const elementsWithScripts = withScripts(element);

  if (section && !excludedSections.includes(section)) {
    return withI18next(
      i18n,
      needsRedirect,
    )(
      <PageContext.Provider value={props.pageContext}>
        <Section {...props}>{elementsWithScripts}</Section>
      </PageContext.Provider>,
    );
  }

  return withI18next(i18n, needsRedirect)(elementsWithScripts);
};
