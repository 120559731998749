import React, { useEffect, useRef } from "react";
import Icon from "../Icon";
import cx from "classnames";
import { connectSearchBox } from "react-instantsearch-dom";
import type { SearchBoxProvided } from "react-instantsearch-core";

import { useSelector } from "react-redux";
import type { StateType } from "store/createStore";

import * as styles from "./SearchBox.module.scss";
import { isSafari } from "utilities/display";

const SearchBox = ({
  value,
  onChange,
  placeholder = "Search...",
  className,
}) => {
  const refInput = useRef(null);
  const panel = useSelector((state: StateType) => state?.utilities?.ui?.panel);
  const hudFocus = useSelector(
    (state: StateType) => state?.utilities?.ui?.hudFocus,
  );

  useEffect(() => {
    if (!isSafari() && panel === "search" && hudFocus === "panel") {
      // We have a delay for the panel to slide out.
      setTimeout(() => {
        refInput?.current?.focus();
      }, 400);
    }
  }, [refInput, panel, hudFocus]);

  return (
    <div
      className={cx({
        [styles.searchBox]: true,
        [className]: !!className,
      })}
    >
      <Icon className={cx(styles.icon)} type="search" svg />
      <input
        ref={refInput}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
      />
      {value && (
        <div onClick={() => onChange("")} className={styles.closeIcon}>
          <Icon type="clearStake" className="icon close" svg />
        </div>
      )}
    </div>
  );
};

export default connectSearchBox<
  Partial<SearchBoxProvided> & { className: string; placeholder: string }
>(({ refine, currentRefinement, className, placeholder }) => {
  return (
    <SearchBox
      className={className}
      placeholder={placeholder}
      value={currentRefinement}
      onChange={(value) => refine(value)}
    />
  );
});
