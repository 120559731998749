import React from "react";
import { Box } from "components/Box";
import { Skeleton } from "components/Skeleton";
import { ReactComponent as SingleIcon } from "components/assets/bet-single.svg";

import * as styles from "./EntrySkeleton.module.scss";

export const EntrySkeleton = ({ count = 1 }: { count?: number }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Box
          icon={<SingleIcon className={styles.icon} />}
          title={<Skeleton mode="light" size="xs" edge="hard" span="quart" />}
          subTitle={<Skeleton mode="light" size="xs" edge="hard" span="half" />}
          key={`entry-skeleton-${index}`}
        >
          <div className={styles.inner}>
            <Skeleton mode="light" size="xs" edge="hard" span="half" />
            <Skeleton mode="light" size="xs" edge="hard" span="third" />
            <Skeleton mode="light" size="xs" edge="hard" span="quart" />
          </div>
        </Box>
      ))}
    </>
  );
};
