import React, { useState, useContext, useEffect } from "react";
import SpeedMap from "../SpeedMap/SpeedMap";
import ShortForm, { type ShortFormState } from "../ShortForm/ShortForm";
import { getFormAttributes } from "utilities/racingUtilities";
import { RaceContext } from "sections/Betting/Race";
import { Box } from "components/Box";
import cx from "classnames";
import { CompetitorInfo, RaceOutcomes } from "sections/Betting/Race/components";
import { MoreInfo } from "components/MoreInfo";

import * as styles from "./Competitor.module.scss";
import type { MappedCompetitorType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { FlucsHistory } from "sections/Betting/Race/components/FlucsHistory";
import { useDisplayAtLeast } from "hooks";
import type { RaceOutcomesProps } from "../RaceOutcomes";

type CompetitorProps = {
  competitor: MappedCompetitorType;
  competitorOutcomes?: ({
    competitor,
  }: { competitor: MappedCompetitorType }) => React.ReactNode;
  validCompetitorCount: number;
  toggleSelectionOverride?: (outcomeId: string) => void;
  customSelections?: string[];
  showPlaceOnMultiOutcome?: boolean;
  isWinPlace?: boolean;
};

const CompetitorOutcomes = (
  props: RaceOutcomesProps & Pick<CompetitorProps, "competitorOutcomes">,
) => {
  const { competitor, competitorOutcomes } = props;

  return typeof competitorOutcomes === "function" ? (
    competitorOutcomes({ competitor })
  ) : (
    <RaceOutcomes {...props} />
  );
};

export const Competitor = ({
  competitor,
  competitorOutcomes,
  validCompetitorCount,
  toggleSelectionOverride,
  customSelections,
  showPlaceOnMultiOutcome = false,
  isWinPlace = true,
}: CompetitorProps) => {
  const { selectedPills, route, race, hasSpeedMap } = useContext(RaceContext);
  const isAtLeastTablet = useDisplayAtLeast("tablet");

  const showSpeedMap = selectedPills.includes("speedmap");
  const showTips = selectedPills.includes("comments");

  const shortFormAvailable = typeof competitor.formAttributes !== "undefined";
  const [shortFormState, setShortFormState] = useState<ShortFormState>(
    showTips ? "comment" : "closed",
  );

  useEffect(() => {
    if (showTips) {
      setShortFormState("comment");
    }
    if (!showTips && shortFormState === "comment") {
      setShortFormState("closed");
    }
  }, [showTips]);

  const clampShortFormToggle = () => {
    if (shortFormState === "closed") {
      setShortFormState("comment");
    } else {
      setShortFormState("closed");
    }
  };

  const handleShortFormToggle = () => {
    if (shortFormState === "comment") {
      setShortFormState("level1");
    } else if (shortFormState === "level1") {
      setShortFormState("level2");
    } else {
      setShortFormState("comment");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.competitorContainer}>
        <Box
          hideHeader
          className={cx(styles.competitor, {
            [styles.scratched]: competitor?.scratched,
          })}
        >
          <div onClick={() => clampShortFormToggle()}>
            <div
              className={cx(styles.heading, {
                [styles.isShowingShortForm]: shortFormState !== "closed",
                [styles.isWinTab]: route.tab === "win-place",
              })}
            >
              <div className={styles.competitorInfoContainer}>
                <CompetitorInfo competitor={competitor} />
              </div>
              {(isAtLeastTablet ||
                typeof competitorOutcomes === "function") && (
                <div className={styles.desktopOutcomes}>
                  <CompetitorOutcomes
                    competitor={competitor}
                    competitorOutcomes={competitorOutcomes}
                    validCompetitorCount={validCompetitorCount}
                    toggleSelectionOverride={toggleSelectionOverride}
                    customSelections={customSelections}
                    showLabels
                    showPlaceOnMultiOutcome={showPlaceOnMultiOutcome}
                    isWinPlace={isWinPlace}
                  />
                </div>
              )}

              <div
                className={cx(styles.clamp, {
                  [styles.clampShadow]: route.tab === "win-place",
                })}
              >
                <MoreInfo
                  direction={"vertical"}
                  isExpanded={shortFormState !== "closed"}
                  onClick={() =>
                    shortFormAvailable ? clampShortFormToggle() : undefined
                  }
                />
              </div>
            </div>
            {!isAtLeastTablet && typeof competitorOutcomes !== "function" && (
              <RaceOutcomes
                competitor={competitor}
                validCompetitorCount={validCompetitorCount}
                toggleSelectionOverride={toggleSelectionOverride}
                customSelections={customSelections}
                showLabels
                showPlaceOnMultiOutcome={showPlaceOnMultiOutcome}
                isShowingShortForm={shortFormState !== "closed"}
              />
            )}
          </div>
          <ShortForm
            toggleShortForm={handleShortFormToggle}
            formAttributes={competitor.formAttributes}
            sex={competitor.sex}
            age={competitor.age}
            comment={competitor.comment}
            shortFormState={shortFormState}
            sport={race?.sport}
          />
        </Box>

        <FlucsHistory competitor={competitor} />

        {showSpeedMap && hasSpeedMap && !competitor.scratched && (
          <SpeedMap
            speedMapPosition={getFormAttributes(competitor, "speedMapPosition")}
          />
        )}
      </div>
    </div>
  );
};
