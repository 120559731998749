import React from "react";
import { LinkButton } from "components/LinkButton";
import { useBetslip } from "hooks";
import { BetType } from "sections/Betting/Betslip/betslipSlice";

import * as styles from "./BetsFooter.module.scss";

const BetsFooter = () => {
  const {
    props: { betType: tab },
  } = useBetslip();

  return (
    <div className={styles.footer}>
      <LinkButton
        to={tab === BetType.Pending ? `/entries/live/` : `/entries/historical`}
        className={styles.button}
        size={`md`}
      >
        View All
      </LinkButton>
    </div>
  );
};

export { BetsFooter };
