// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clickable = "Input-module--clickable--7679c";
export var container = "Input-module--container--4d906";
export var emphasis = "Input-module--emphasis--d9911";
export var flag = "Input-module--flag--ab2b2";
export var green = "#57d33b";
export var greenLink = "Input-module--green-link--cd58b";
export var grey = "var(--tone-mid)";
export var gutterBottom = "Input-module--gutterBottom--27969";
export var gutterLeft = "Input-module--gutterLeft--edc9d";
export var gutterRight = "Input-module--gutterRight--c826a";
export var gutterTop = "Input-module--gutterTop--15a67";
export var hasErrors = "Input-module--hasErrors--d0a48";
export var icon = "Input-module--icon--acd82";
export var input = "Input-module--input--d71d3";
export var inputContainer = "Input-module--inputContainer--fb121";
export var issues = "Input-module--issues--04885";
export var label = "Input-module--label--0ee44";
export var labelContainer = "Input-module--labelContainer--a4553";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";