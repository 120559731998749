// extracted by mini-css-extract-plugin
export var addButton = "Slip-module--addButton--56a0f";
export var blue = "#0eb3f2";
export var buttonContainer = "Slip-module--buttonContainer--963db";
export var caret = "Slip-module--caret--3d954";
export var chevron = "Slip-module--chevron--41920";
export var emphasis = "Slip-module--emphasis--ae81d";
export var green = "#57d33b";
export var greenLink = "Slip-module--green-link--d43e7";
export var grey = "var(--tone-mid)";
export var loginButton = "Slip-module--loginButton--a9343";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var showSelections = "Slip-module--showSelections--c99bd";
export var slip = "Slip-module--slip--2e5a2";
export var trash = "Slip-module--trash--81e86";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";