import React from "react";
import cx from "classnames";

import { PageLoader } from "../Loader";

import { TabFilter, type IContentItem } from "../TabFilter";
import { selectLoadingPhrase } from "utilities/loadingDictionary";

import { selectRouteContent } from "utilities/route";

import * as styles from "./PageBody.module.scss";

interface IPageBodyProps {
  menu: IContentItem[][] | IContentItem[];
  activeLink?: string;
  loading?: any;
  className?: string;
  route?: string;
  children?: JSX.Element | JSX.Element[] | string;
}

export const PageBody = ({
  menu,
  activeLink,
  loading,
  children,
  className,
}: IPageBodyProps) => {
  let tabs = (
    menu.length === 1 && menu[0] instanceof Array && menu[0].length > 1
      ? menu[0]
      : menu
  ) as IContentItem[];

  tabs = tabs
    .filter((x) => Boolean(x && x.title))
    .map(
      (x) =>
        ({
          ...x,
          isActive: x.isActive || (x.link && x.link === activeLink),
        }) as IContentItem,
    );

  const activeTab = selectRouteContent(tabs);
  if (activeTab) {
    activeTab.isActive = true;
  }

  tabs = tabs.filter((x) => x.isHidden !== true || x.isActive);

  const isLoading =
    (loading &&
      (typeof loading === "boolean" || Object.values(loading).length > 0)) ||
    (activeTab && activeTab.isLoading);

  return (
    <div className={cx(styles.pageBody, className)}>
      <TabFilter tabs={tabs} gutterBottom />
      <div className={cx(styles.content, { [styles.loading]: !!loading })}>
        {children || (activeTab && activeTab.render && activeTab.render())}
      </div>
      {isLoading && (
        <PageLoader
          className={styles.loader + " pageLoader"}
          text={selectLoadingPhrase()}
        />
      )}
    </div>
  );
};

export default PageBody;
