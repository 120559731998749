import { dispatchNow, getStore } from "store";
import { updateSession } from "utilities/Auth/authSlice";

type Credentials = {
  userId: string;
  token: string;
};

const getUserCredentials = (): Credentials => {
  const authState = getStore().getState().auth;

  return {
    userId: authState.userId,
    token: authState.token,
  };
};

const revokeSession = async () => {
  return await dispatchNow(updateSession({ isGuest: false }));
};

export { getUserCredentials, revokeSession };
