// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var captchaPuzzle = "CaptchaPuzzleModal-module--captchaPuzzle--54423";
export var emphasis = "CaptchaPuzzleModal-module--emphasis--4973b";
export var green = "#57d33b";
export var greenLink = "CaptchaPuzzleModal-module--green-link--cabf1";
export var grey = "var(--tone-mid)";
export var invisible = "CaptchaPuzzleModal-module--invisible--b31cf";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var visible = "CaptchaPuzzleModal-module--visible--27883";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";