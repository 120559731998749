// extracted by mini-css-extract-plugin
export var arrow = "RaceMeetingDropDown-module--arrow--ef06f";
export var blue = "#0eb3f2";
export var caret = "RaceMeetingDropDown-module--caret--86b5a";
export var chevron = "RaceMeetingDropDown-module--chevron--243c3";
export var dropDownCountry = "RaceMeetingDropDown-module--dropDownCountry--5d509";
export var dropDownRow = "RaceMeetingDropDown-module--dropDownRow--f9b60";
export var dropDownStatus = "RaceMeetingDropDown-module--dropDownStatus--ba059";
export var dropDownVenueName = "RaceMeetingDropDown-module--dropDownVenueName--a86c8";
export var emphasis = "RaceMeetingDropDown-module--emphasis--6e25b";
export var green = "#57d33b";
export var greenLink = "RaceMeetingDropDown-module--green-link--a4d5c";
export var grey = "var(--tone-mid)";
export var icon = "RaceMeetingDropDown-module--icon--c6b7a";
export var indicator = "RaceMeetingDropDown-module--indicator--92bbc";
export var notchGap = "32px";
export var orange = "#f8662b";
export var popover = "RaceMeetingDropDown-module--popover--ebe97";
export var popoverBox = "RaceMeetingDropDown-module--popoverBox--1b3a9";
export var raceMeeting = "RaceMeetingDropDown-module--raceMeeting--3711d";
export var raceVenue = "RaceMeetingDropDown-module--raceVenue--7ffb9";
export var red = "#ff2741";
export var scrollArea = "RaceMeetingDropDown-module--scrollArea--f959e";
export var selected = "RaceMeetingDropDown-module--selected--4015e";
export var tabPromoIcon = "RaceMeetingDropDown-module--tabPromoIcon--6fd30";
export var tick = "RaceMeetingDropDown-module--tick--daf3d";
export var topRow = "RaceMeetingDropDown-module--topRow--7c517";
export var venue = "RaceMeetingDropDown-module--venue--f0f3d";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";