// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "BetCardAlert-module--container--8d8eb";
export var emphasis = "BetCardAlert-module--emphasis--6d414";
export var green = "#57d33b";
export var greenLink = "BetCardAlert-module--green-link--b3e4e";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";