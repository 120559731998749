// extracted by mini-css-extract-plugin
export var active = "RaceNumberTab-module--active--bd6a4";
export var arrowUp = "RaceNumberTab-module--arrowUp--e7f8b";
export var blue = "#0eb3f2";
export var emphasis = "RaceNumberTab-module--emphasis--ebec1";
export var green = "#57d33b";
export var greenLink = "RaceNumberTab-module--green-link--0142c";
export var grey = "var(--tone-mid)";
export var inactive = "RaceNumberTab-module--inactive--dab47";
export var indicator = "RaceNumberTab-module--indicator--a27e4";
export var notchGap = "32px";
export var orange = "#f8662b";
export var raceNumber = "RaceNumberTab-module--raceNumber--075b0";
export var red = "#ff2741";
export var tabPromoIcon = "RaceNumberTab-module--tabPromoIcon--f0cf3";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";