import React, { type ReactElement } from "react";
import cx from "classnames";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";

import * as styles from "./Typography.module.scss";

type UnorderedListProps = {
  className?: string;
  children: React.ReactNode;
};

type UnorderedListItem = {
  className?: string;
  children: React.ReactNode;
};

const UnorderedList = ({
  children,
  className,
  ...props
}: UnorderedListProps): ReactElement<HTMLUListElement> => {
  return (
    <ul className={cx(styles.ul, className)} {...props}>
      {children}
    </ul>
  );
};

const UnorderedListItem = ({
  children,
  className,
  ...props
}: UnorderedListItem): ReactElement<HTMLLIElement> => {
  return (
    <li className={cx(styles.li, className)} {...props}>
      <span className={styles.icon}>
        <ChevronDown />
      </span>
      {children}
    </li>
  );
};

export { UnorderedList, UnorderedListItem };
