// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "ComplexBetBuilder-module--container--3606f";
export var emphasis = "ComplexBetBuilder-module--emphasis--a9a19";
export var green = "#57d33b";
export var greenLink = "ComplexBetBuilder-module--green-link--4194f";
export var grey = "var(--tone-mid)";
export var greyLine = "ComplexBetBuilder-module--greyLine--86ba6";
export var isOpen = "ComplexBetBuilder-module--isOpen--de843";
export var isScrollMode = "ComplexBetBuilder-module--isScrollMode--411f2";
export var notchGap = "32px";
export var orange = "#f8662b";
export var panel = "ComplexBetBuilder-module--panel--85c5d";
export var red = "#ff2741";
export var refElement = "ComplexBetBuilder-module--refElement--66e44";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";