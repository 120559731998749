import { SupportLink } from "components/SupportLink";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { RaceContext } from "sections/Betting/Race";

import { ExoticMarketResults } from "./ExoticMarketResults";

import * as styles from "./ExoticDividends.module.scss";

export const ExoticDividends = () => {
  const { race } = useContext(RaceContext);

  if (isEmpty(race?.results)) return;

  return (
    <div>
      <SupportLink options={{ articleId: "6608123554063" }} hideChevron>
        <div className={styles.title}>
          Exotics
          <InfoIcon className={styles.infoIcon} />
        </div>
      </SupportLink>

      <ExoticMarketResults results={race?.exoticResults} />
    </div>
  );
};
