// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "RaceTabsSkeleton-module--emphasis--0abf5";
export var green = "#57d33b";
export var greenLink = "RaceTabsSkeleton-module--green-link--a7c68";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var raceTabs = "RaceTabsSkeleton-module--raceTabs--c3971";
export var red = "#ff2741";
export var srm = "RaceTabsSkeleton-module--srm--3fcc7";
export var tabItem = "RaceTabsSkeleton-module--tabItem--700ff";
export var top4 = "RaceTabsSkeleton-module--top4--4a519";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var winPlace = "RaceTabsSkeleton-module--winPlace--faf4f";
export var yellow = "#ffb100";