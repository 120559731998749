import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "hooks";
import { closeCaptchaPuzzle, setModal } from "utilities/UI/uiSlice";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";

import * as styles from "./CaptchaPuzzleModal.module.scss";

export const CaptchaPuzzleModal = () => {
  const captcha = useSelector((state) => state.utilities.ui.captcha);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeCaptchaPuzzle());
    dispatch(setModal(null));
  };

  return (
    <FullScreenModal
      className={cx({
        [styles.invisible]: !captcha.visible,
      })}
      isOpen={captcha.required}
      onClose={handleClose}
    >
      <div
        id="captcha-container"
        className={cx(styles.captchaPuzzle, {
          [styles.visible]: captcha.visible,
        })}
      />
    </FullScreenModal>
  );
};
