// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "InfoPopover-module--emphasis--7c3e3";
export var green = "#57d33b";
export var greenLink = "InfoPopover-module--green-link--8624d";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var popOver = "InfoPopover-module--popOver--61de6";
export var red = "#ff2741";
export var trigger = "InfoPopover-module--trigger--66777";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";