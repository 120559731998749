import React from "react";
import { ReactComponent as LogoOuter } from "assets/logo-outer.svg";
import { ReactComponent as LogoInner } from "assets/logo-inner.svg";
import { motion } from "framer-motion";
import cx from "classnames";
import { useIsClient } from "usehooks-ts";

import * as styles from "./Loader.module.scss";

export const LoaderIcon = ({
  className,
  loading = true,
}: {
  className?: string;
  loading?: boolean;
}) => {
  const isClient = useIsClient();

  if (!isClient) {
    return (
      <div className={className}>
        <LogoOuter />
        <div
          className={cx(styles.rotate, {
            [styles.rotating]: loading,
          })}
        >
          <LogoInner />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <LogoOuter />
      <motion.div
        key={loading ? "loading" : "loaded"}
        style={{
          position: "absolute",
          zIndex: 2,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        animate={{ rotate: loading ? 360 : 0 }}
        transition={{
          repeat: Infinity,
          ease: "easeInOut",
          duration: 0.8,
        }}
      >
        <LogoInner />
      </motion.div>
    </div>
  );
};
