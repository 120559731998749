import useHasPermission from "./useHasPermission";
import { useIsLoggedIn } from "hooks";

/**
 * Is the current user allowed to view promotional material
 */
export const useCanViewPromotions = () => {
  const loggedIn = useIsLoggedIn();
  //todo: Allowing logged in users to view promos so they are not hidden to a user with an existing session until they reauth. Remove default
  return useHasPermission("viewPromotion", loggedIn);
};

export default useCanViewPromotions;
