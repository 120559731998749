// extracted by mini-css-extract-plugin
export var animLoader = "HelpCenter-module--animLoader--7dbaf";
export var back = "HelpCenter-module--back--734fb";
export var backIcon = "HelpCenter-module--backIcon--860b0";
export var badge = "HelpCenter-module--badge--052e2";
export var blue = "#0eb3f2";
export var content = "HelpCenter-module--content--b4634";
export var emphasis = "HelpCenter-module--emphasis--fc0c1";
export var empty = "HelpCenter-module--empty--50306";
export var footer = "HelpCenter-module--footer--06418";
export var formContent = "HelpCenter-module--formContent--bd4ba";
export var green = "#57d33b";
export var greenLink = "HelpCenter-module--green-link--1c08c";
export var grey = "var(--tone-mid)";
export var header = "HelpCenter-module--header--7db50";
export var helpCenter = "HelpCenter-module--helpCenter--e5eb9";
export var icon = "HelpCenter-module--icon--8df30";
export var liveChat = "HelpCenter-module--liveChat--37b5e";
export var loader = "HelpCenter-module--loader--14325";
export var notchGap = "32px";
export var openInNewIcon = "HelpCenter-module--openInNewIcon--72203";
export var orange = "#f8662b";
export var red = "#ff2741";
export var result = "HelpCenter-module--result--f6bca";
export var results = "HelpCenter-module--results--7749e";
export var resultsTitle = "HelpCenter-module--resultsTitle--85def";
export var search = "HelpCenter-module--search--7777d";
export var searchInput = "HelpCenter-module--searchInput--39ee5";
export var textArea = "HelpCenter-module--textArea--50f6f";
export var title = "HelpCenter-module--title--989e8";
export var titleLeft = "HelpCenter-module--titleLeft--35b68";
export var titleRight = "HelpCenter-module--titleRight--a296e";
export var titleText = "HelpCenter-module--titleText--12cba";
export var unreadMessages = "HelpCenter-module--unreadMessages--1a644";
export var uploadDocuments = "HelpCenter-module--uploadDocuments--f5e06";
export var visible = "HelpCenter-module--visible--881d8";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";