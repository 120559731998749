import React from "react";
import { Clamp } from "components/Clamp";
import { Caret } from "components/Caret";

import * as styles from "./MoreInfo.module.scss";

type ExpandInfoProps = {
  isExpanded?: boolean;
  onClick?: () => void;
  direction?: "horizontal" | "vertical";
};

const MoreInfo = ({
  isExpanded,
  onClick,
  direction = "vertical",
}: ExpandInfoProps) => {
  return (
    <Clamp
      variant={`grey`}
      className={styles.caret}
      onClick={(e) => {
        if (onClick) {
          onClick();
          e.stopPropagation();
        }
      }}
    >
      {direction === "vertical" ? (
        <Caret variant={isExpanded ? "up" : "down"} />
      ) : (
        <Caret variant={isExpanded ? "left" : "right"} />
      )}
    </Clamp>
  );
};

export { MoreInfo };
