// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var box = "RaceResultSkeleton-module--box--21c43";
export var emphasis = "RaceResultSkeleton-module--emphasis--27b73";
export var green = "#57d33b";
export var greenLink = "RaceResultSkeleton-module--green-link--0fef9";
export var grey = "var(--tone-mid)";
export var label = "RaceResultSkeleton-module--label--56f41";
export var medal = "RaceResultSkeleton-module--medal--087cf";
export var medalContainer = "RaceResultSkeleton-module--medalContainer--d8560";
export var notchGap = "32px";
export var odds = "RaceResultSkeleton-module--odds--760b0";
export var orange = "#f8662b";
export var red = "#ff2741";
export var resultOutcome = "RaceResultSkeleton-module--resultOutcome--4b33c";
export var resultOutcomes = "RaceResultSkeleton-module--resultOutcomes--1bc0c";
export var resultsContainer = "RaceResultSkeleton-module--resultsContainer--fd5cf";
export var silks = "RaceResultSkeleton-module--silks--79d87";
export var silksContainer = "RaceResultSkeleton-module--silksContainer--9a830";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var winnerInfo = "RaceResultSkeleton-module--winnerInfo--4d64f";
export var winnerInfoContainer = "RaceResultSkeleton-module--winnerInfoContainer--a12bb";
export var winnerName = "RaceResultSkeleton-module--winnerName--ca18b";
export var winnerNameContainer = "RaceResultSkeleton-module--winnerNameContainer--2c24d";
export var yellow = "#ffb100";