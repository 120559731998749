import { useSelector } from "hooks";
import { useIsClient } from "usehooks-ts";

const useIsLoggedIn = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.loggedIn);
  const isClient = useIsClient();

  // make sure we are never logged in before the first mount
  return isClient ? isLoggedIn : false;
};

export default useIsLoggedIn;
