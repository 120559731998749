import React, { useState, useEffect, type ChangeEvent } from "react";
import { parse, isValid, format } from "date-fns";
import { Input } from "components/Input";
import { NativeSelect } from "components/NativeSelect";
import { InputError } from "components/InputError";
import cx from "classnames";

import * as styles from "./DateInput.module.scss";

type DateInputProps = {
  id?: string;
  value?: string;
  onChange?: (date: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  errorMessage?: string;
  label?: string;
  hideDays?: boolean;
  hideLabel?: boolean;
};

const DateInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  id,
  errorMessage,
  label,
  hideDays = false,
  hideLabel = false,
}: DateInputProps) => {
  // when we are hiding days just assume it's first of the month
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [initialised, setInitialised] = useState<boolean>(false);

  useEffect(() => {
    if (hideDays) {
      setDay("1");
    }
  }, [hideDays]);

  useEffect(() => {
    const parsedDate = value ? parse(value, "yyyy-MM-dd", new Date()) : null;

    if (parsedDate && isValid(parsedDate)) {
      setDay(format(parsedDate, "d"));
      setMonth(format(parsedDate, "M"));
      setYear(format(parsedDate, "yyyy"));
    }

    // this prevents first blank on change call
    if (!initialised) {
      setInitialised(true);
    }
  }, [value]);

  useEffect(() => {
    let parsedDate: Date | undefined;

    if (day && month && year && year.length === 4) {
      parsedDate = parse(`${month}/${day}/${year}`, "M/d/yyyy", new Date());

      if (!isValid(parsedDate)) {
        parsedDate = undefined;
      }
    } else {
      parsedDate = undefined;
    }

    const newValue = parsedDate ? format(parsedDate, "yyyy-MM-dd") : "";

    if (typeof onChange === "function" && initialised) {
      onChange(newValue);
    }
  }, [day, month, year, onChange]);

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDay(e.target.value);
  };

  const handleMonthChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value);
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={cx(styles.inputWrapper, {
          [styles.hideDays]: hideDays,
        })}
      >
        {!hideDays && (
          <Input
            id={id ? `${id}-day` : undefined}
            inputClassName={styles.input}
            containerClassName={styles.day}
            label={label || hideLabel ? undefined : "Day"}
            value={day}
            onChange={handleDayChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={`DD`}
            hasError={!!errorMessage}
            maxLength={2}
            inputMode={"tel"}
            pattern={"[0-9]*"}
            data-lpignore={true}
          />
        )}

        <NativeSelect
          id={id ? `${id}-month` : undefined}
          containerClassName={styles.month}
          label={label || hideLabel ? undefined : "Month"}
          options={[
            { value: "", label: "Month" },
            { value: "1", label: "January" },
            { value: "2", label: "February" },
            { value: "3", label: "March" },
            { value: "4", label: "April" },
            { value: "5", label: "May" },
            { value: "6", label: "June" },
            { value: "7", label: "July" },
            { value: "8", label: "August" },
            { value: "9", label: "September" },
            { value: "10", label: "October" },
            { value: "11", label: "November" },
            { value: "12", label: "December" },
          ]}
          value={month}
          onChange={handleMonthChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          hasError={!!errorMessage}
        />
        <Input
          id={id ? `${id}-year` : undefined}
          inputClassName={styles.input}
          containerClassName={styles.year}
          label={label || hideLabel ? undefined : "Year"}
          value={year}
          onChange={handleYearChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={`YYYY`}
          hasError={!!errorMessage}
          maxLength={4}
          inputMode={"tel"}
          pattern={"[0-9]*"}
          data-lpignore={true}
        />
      </div>
      {errorMessage && <InputError message={errorMessage} />}
    </div>
  );
};

DateInput.displayName = "DateInput";

export { DateInput };
