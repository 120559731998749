import React, { useState } from "react";
import { Form } from "components/Form";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressSelect } from "components/AddressSelect";
import { Button } from "components/Button";
import { NativeSelect } from "components/NativeSelect";
import { getAddressFromPlaceId, getAllCountries } from "utilities/address";
import { Input } from "components/Input";
import { validateProfile } from "utilities/api/picklebet/auth";
import { Typography } from "components/Typography";
import { useCountry } from "hooks/useLocale";

import * as styles from "../Auth.module.scss";

type FormValues = {
  unit: string;
  country: string;
  postCode: string;
  state: string;
  suburb: string;
  street: string;
  streetNumber: string;
};

type AddressProps = {
  onComplete: (values: FormValues) => void;
};

const schema = yup.object().shape({
  country: yup.string().required("Country is required"),
  postCode: yup.string().required("Postcode is required"),
  state: yup
    .string()
    .when("country", {
      is: "AU",
      then: (schema) => schema.required("State is required"),
    })
    .when("country", {
      is: "CA",
      then: (schema) =>
        schema.required("Please enter a valid province / territory"),
    }),
  suburb: yup.string().required("Suburb is required"),
  street: yup.string().required("Street name is required"),
  streetNumber: yup.string().required("Street number is required"),
});

const Address = ({ onComplete }: AddressProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const country = useCountry();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      country: country,
    },
  });

  return (
    <Form
      onSubmit={handleSubmit(async (values) => {
        const validation = await validateProfile("", "", values);

        if (validation.length > 0) {
          // if there are server validation errors
          validation.forEach((error) => {
            setError(
              (error.propertyPath.includes("profile")
                ? error.propertyPath.split(".")[1]
                : error.propertyPath) as keyof FormValues,
              {
                type: "manual",
                message: error.message,
              },
            );
          });

          return;
        }

        onComplete(values);
      })}
    >
      {isExpanded ? (
        <>
          <NativeSelect
            options={getAllCountries()}
            label={"Country"}
            errorMessage={errors?.country?.message}
            {...register("country")}
          />
          <Input
            id={"unit"}
            label={"Unit No."}
            {...register("unit")}
            errorMessage={errors?.unit?.message}
          />
          <Input
            id={"streetNumber"}
            label={"Street No."}
            {...register("streetNumber")}
            errorMessage={errors?.streetNumber?.message}
          />
          <Input
            id={"street"}
            label={"Street Name"}
            {...register("street")}
            errorMessage={errors?.street?.message}
          />
          <Input
            id={"suburb"}
            label={"Suburb / City"}
            {...register("suburb")}
            errorMessage={errors?.suburb?.message}
          />
          {watch("country") === "AU" && (
            <NativeSelect
              options={[
                { value: "ACT", label: "Australian Capital Territory" },
                { value: "NSW", label: "New South Wales" },
                { value: "NT", label: "Northern Territory" },
                { value: "QLD", label: "Queensland" },
                { value: "SA", label: "South Australia" },
                { value: "TAS", label: "Tasmania" },
                { value: "VIC", label: "Victoria" },
                { value: "WA", label: "Western Australia" },
              ]}
              label={"State / Territory"}
              {...register("state")}
              errorMessage={errors?.state?.message}
            />
          )}
          {watch("country") === "CA" && (
            <NativeSelect
              options={[
                { value: "AB", label: "Alberta" },
                { value: "BC", label: "British Columbia" },
                { value: "MB", label: "Manitoba" },
                { value: "NB", label: "New Brunswick" },
                { value: "NL", label: "Newfoundland and Labrador" },
                { value: "NS", label: "Nova Scotia" },
                { value: "ON", label: "Ontario" },
                { value: "PE", label: "Prince Edward Island" },
                { value: "QC", label: "Quebec" },
                { value: "SK", label: "Saskatchewan" },
                { value: "NT", label: "Northwest Territories" },
                { value: "NU", label: "Nunavut" },
                { value: "YT", label: "Yukon" },
              ]}
              label={"Province / Territory"}
              {...register("state")}
              errorMessage={errors?.state?.message}
            />
          )}
          {!["AU", "CA"].includes(watch("country")) && (
            <Input
              id={"state"}
              label={"State / Region"}
              {...register("state")}
              errorMessage={errors?.state?.message}
            />
          )}
          <Input
            id={"postCode"}
            label={"Postcode"}
            {...register("postCode")}
            errorMessage={errors?.postCode?.message}
          />
          <Button
            type={"submit"}
            variant={"primary"}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Continue
          </Button>
          <Typography component={`span`} variant="body">
            Wrong address?{" "}
            <Button variant={"link"} onClick={() => setIsExpanded(false)}>
              Look up different address
            </Button>
          </Typography>
        </>
      ) : (
        <AddressSelect
          autoFocus
          label={`Address`}
          placeholder={`Type residential address`}
          onChange={async ({ value: placeId }) => {
            const address = await getAddressFromPlaceId(placeId);
            Object.keys(address).forEach((key) => {
              setValue(key as keyof FormValues, address[key]);
            });
            setIsExpanded(true);
          }}
          noOptionsMessage={() => {
            return (
              <div className={styles.emptyAddress}>
                No address found.{" "}
                <Button variant={"link"} onClick={() => setIsExpanded(true)}>
                  Fill in manually
                </Button>
              </div>
            );
          }}
          region={country}
        />
      )}
    </Form>
  );
};

export { Address };
