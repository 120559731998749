import React from "react";
import cx from "classnames";
import { Typography } from "components/Typography";

import * as styles from "./FormControl.module.scss";

type FormControl = {
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  shouldCollapse?: boolean; // determines if form should stack vertically on mobile
  noSpacing?: boolean;
};

const FormControl = ({
  title,
  description,
  children,
  shouldCollapse = true,
  noSpacing,
}: FormControl) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.shouldNotCollapse]: !shouldCollapse,
        [styles.noSpacing]: noSpacing,
      })}
      data-testid={`form-control-container`}
    >
      <div className={styles.titleAndDescription}>
        {title && (
          <Typography variant="h4" noSpacing>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body" className={styles.description} noSpacing>
            {description}
          </Typography>
        )}
      </div>
      <div className={styles.input}>{children}</div>
    </div>
  );
};

export { FormControl };
