// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var category = "SearchBox-module--category--d1b9c";
export var closeIcon = "SearchBox-module--closeIcon--e9855";
export var countdown = "SearchBox-module--countdown--d0944";
export var coverageHit = "SearchBox-module--coverageHit--9848a";
export var details = "SearchBox-module--details--bf051";
export var emphasis = "SearchBox-module--emphasis--073cc";
export var empty = "SearchBox-module--empty--7cdc8";
export var eventName = "SearchBox-module--eventName--e9785";
export var eventNameDetail = "SearchBox-module--eventNameDetail--0b849";
export var green = "#57d33b";
export var greenLink = "SearchBox-module--green-link--41c18";
export var grey = "var(--tone-mid)";
export var icon = "SearchBox-module--icon--549a2";
export var index = "SearchBox-module--index--7ac77";
export var info = "SearchBox-module--info--3212b";
export var matchHit = "SearchBox-module--matchHit--1d957";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageHit = "SearchBox-module--pageHit--c2939";
export var pickemsHit = "SearchBox-module--pickemsHit--a390a";
export var red = "#ff2741";
export var searchBox = "SearchBox-module--searchBox--845a6";
export var searchResult = "SearchBox-module--searchResult--53f61";
export var sport = "SearchBox-module--sport--c588c";
export var sportHit = "SearchBox-module--sportHit--2e551";
export var starts = "SearchBox-module--starts--ced5f";
export var title = "SearchBox-module--title--c5ec4";
export var tournament = "SearchBox-module--tournament--11192";
export var type = "SearchBox-module--type--35158";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";