import type { NullableMaybe, Nullable } from "types/utilities";

export const hasLocale = (
  to: NullableMaybe<string>,
  currentLocale: Nullable<string>,
) => {
  // check if given link already has locale
  if (
    typeof window === "undefined" ||
    currentLocale === window.___defaultLocale ||
    !to
  ) {
    return true;
  }

  return to.startsWith(`/${currentLocale}`);
};
