import React from "react";
import { ListBadge } from "components/List";
import {
  VerificationReadableStatusByStatus,
  type VerificationStatus,
} from "hooks";

import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { ReactComponent as ClockIcon } from "components/assets/clock.svg";
import { ReactComponent as TickIcon } from "components/assets/tick.svg";
import { ReactComponent as SearchIcon } from "components/assets/search.svg";
import { ReactComponent as PlusIcon } from "components/assets/plus-circle.svg";

type VerificationBadgeProps = {
  status: VerificationStatus;
};

const statusToIcon: Record<VerificationStatus, React.ReactNode> = {
  UNVERIFIED: <PlusIcon />,
  IN_PROGRESS: <ClockIcon />,
  FAILED: <WarningIcon />,
  VERIFIED: <TickIcon />,
  VERIFIED_ADMIN: <TickIcon />,
  ERROR_OCCURRED: <WarningIcon />,
  PENDING_REVIEW: <SearchIcon />,
};

const statusToVariant: Record<VerificationStatus, any> = {
  UNVERIFIED: "neutral",
  IN_PROGRESS: "warning",
  ERROR_OCCURRED: "danger",
  FAILED: "danger",
  VERIFIED: "success",
  VERIFIED_ADMIN: "success",
  PENDING_REVIEW: "info",
};

const VerificationBadge = ({ status }: VerificationBadgeProps) => {
  const statusText = VerificationReadableStatusByStatus[status];

  return (
    <ListBadge variant={statusToVariant[status]} icon={statusToIcon[status]}>
      {statusText}
    </ListBadge>
  );
};

export { VerificationBadge };
