// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "PerkIcon-module--emphasis--69774";
export var green = "PerkIcon-module--green--157c1";
export var greenLink = "PerkIcon-module--green-link--a5e2c";
export var grey = "var(--tone-mid)";
export var icon = "PerkIcon-module--icon--538a1";
export var notchGap = "32px";
export var orange = "PerkIcon-module--orange--78f56";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "PerkIcon-module--yellow--6823d";