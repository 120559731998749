import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import cx from "classnames";
import { getIconByType, getVariantByType } from "./helpers";
import type { Notification as NotificationType } from "hooks/firestore/user/useNotifications";
import { formatDistanceToDateFromNowAbbr } from "utilities/datetime";
import { Typography } from "components/Typography";
import { Link } from "components/Link";

import * as styles from "./Notification.module.scss";

type NotificationProps = {
  notification: NotificationType;
  onDismissNotification: (id: string) => void;
};

export const badgeVariants = cva(styles.badge, {
  variants: {
    variant: {
      info: styles.info,
      warning: styles.warning,
      danger: styles.danger,
      success: styles.success,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

type BadgeProps = VariantProps<typeof badgeVariants>;

const Badge = ({ variant }: BadgeProps) => {
  return <div className={badgeVariants({ variant })} />;
};

const Notification = ({
  notification,
  onDismissNotification,
}: NotificationProps) => {
  const icon = getIconByType(notification.type);

  return (
    <Link
      to={notification.link}
      className={cx(styles.container, {
        [styles.isRead]: notification.isRead,
      })}
      onClick={() => onDismissNotification(notification.id)}
    >
      <span className={styles.icon}>
        {icon}
        {notification.isRead ? null : (
          <Badge variant={getVariantByType(notification.type)} />
        )}
      </span>
      <span className={styles.text}>
        <Typography component={"span"} variant={`body`} noSpacing>
          {notification.message}
        </Typography>
      </span>
      <span className={styles.time}>
        {formatDistanceToDateFromNowAbbr(notification.date, true)}
      </span>
    </Link>
  );
};

export { Notification };
