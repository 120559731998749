// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "HeroContainer-module--emphasis--67a70";
export var gradient = "HeroContainer-module--gradient--11a3d";
export var green = "#57d33b";
export var greenLink = "HeroContainer-module--green-link--c2853";
export var grey = "var(--tone-mid)";
export var heroContainer = "HeroContainer-module--heroContainer--39ee3";
export var image = "HeroContainer-module--image--3bc69";
export var images = "HeroContainer-module--images--decbd";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";