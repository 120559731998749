// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "Select-module--container--68911";
export var emphasis = "Select-module--emphasis--02a9c";
export var focused = "Select-module--focused--1cbf9";
export var green = "#57d33b";
export var greenLink = "Select-module--green-link--9dc67";
export var grey = "var(--tone-mid)";
export var hasError = "Select-module--hasError--81c77";
export var label = "Select-module--label--488f9";
export var loadingMessage = "Select-module--loadingMessage--fe886";
export var menu = "Select-module--menu--60a97";
export var notchGap = "32px";
export var option = "Select-module--option--1a376";
export var orange = "#f8662b";
export var red = "#ff2741";
export var select = "Select-module--select--e4a69";
export var selected = "Select-module--selected--d0cee";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";