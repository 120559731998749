// extracted by mini-css-extract-plugin
export var arrowLeft = "Icon-module--arrowLeft--a6040";
export var arrowRight = "Icon-module--arrowRight--5e168";
export var arrowUp = "Icon-module--arrowUp--70c94";
export var bagOfCash = "Icon-module--bagOfCash--e85dd";
export var bet = "Icon-module--bet--98da8";
export var betslip = "Icon-module--betslip--f1ab4";
export var blue = "Icon-module--blue--acc63";
export var cash = "Icon-module--cash--ccc1e";
export var contest = "Icon-module--contest--a6dea";
export var emphasis = "Icon-module--emphasis--89133";
export var entryId = "Icon-module--entryId--59702";
export var error = "Icon-module--error--9317d";
export var green = "Icon-module--green--c65ae";
export var greenLink = "Icon-module--green-link--1f863";
export var grey = "var(--tone-mid)";
export var iconFlex = "Icon-module--iconFlex--ec9d8";
export var iconInactive = "Icon-module--iconInactive--c6ebf";
export var iconInline = "Icon-module--iconInline--f0e51";
export var megaphone = "Icon-module--megaphone--490e2";
export var notchGap = "32px";
export var orange = "Icon-module--orange--adbac";
export var picklebet = "Icon-module--picklebet--ab576";
export var player = "Icon-module--player--ef83b";
export var players = "Icon-module--players--1a3ba";
export var red = "Icon-module--red--846c9";
export var stopwatch = "Icon-module--stopwatch--43ee9";
export var ticket = "Icon-module--ticket--b6070";
export var white = "Icon-module--white--4c528";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "Icon-module--yellow--bb8b0";