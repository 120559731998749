// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var box = "Box-module--box--904c0";
export var clamp = "Box-module--clamp--0863c";
export var emphasis = "Box-module--emphasis--44bf7";
export var green = "#57d33b";
export var greenLink = "Box-module--green-link--47eab";
export var grey = "var(--tone-mid)";
export var hasChildren = "Box-module--hasChildren--1c9e7";
export var header = "Box-module--header--c026f";
export var icon = "Box-module--icon--a2187";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var subtitle = "Box-module--subtitle--e4e45";
export var title = "Box-module--title--66ea3";
export var titleAndSubtitle = "Box-module--titleAndSubtitle--ba2d7";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";