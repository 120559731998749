import React from "react";
import cx from "classnames";
import { Icon, navigate } from "library";
import { usePermissions } from "hooks";
import { useIsClient } from "usehooks-ts";

import * as styles from "./Message.module.scss";

type MessageProps = {
  type: "success" | "cancelled" | "pending" | "failed" | "locked" | "betslip";
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  actions?: JSX.Element | JSX.Element[];
  icon?: JSX.Element;
  addVerticalMargin?: boolean;
};

const Message = ({
  type,
  actions,
  icon,
  subtitle,
  title,
  addVerticalMargin = true,
}: MessageProps) => {
  const permissions = usePermissions();
  const isClient = useIsClient();

  const iconMap = {
    success: <Icon type="verification" height={36} className={styles.icon} />,
    cancelled: (
      <Icon
        type="warning"
        height={36}
        className={cx(styles.icon, styles.yellow)}
      />
    ),
    failed: (
      <Icon type="error" height={36} className={cx(styles.icon, styles.red)} />
    ),
    pending: (
      <Icon
        type="clock"
        height={36}
        className={cx(styles.icon, styles.yellow)}
      />
    ),
    locked: (
      <Icon
        type="shield"
        height={36}
        className={cx(styles.icon, styles.blue)}
      />
    ),
    betslip: (
      <Icon
        type="betslip"
        height={36}
        className={cx(styles.icon, styles.light)}
      />
    ),
  };

  const defaultActions = isClient ? (
    <div>
      {permissions?.viewEsportsMarkets === "GRANTED" && (
        <button onClick={() => navigate("/betting")}>Next Esports</button>
      )}
      {permissions?.viewSportsMarkets === "GRANTED" && (
        <button onClick={() => navigate("/sports/betting")}>Next Sports</button>
      )}
      {permissions?.viewRacingMarkets === "GRANTED" && (
        <button onClick={() => navigate("/racing/betting")}>Next Race</button>
      )}
    </div>
  ) : null;

  return (
    <div
      className={cx(styles.container, {
        [styles.verticalMargin]: addVerticalMargin,
      })}
    >
      {icon ? icon : iconMap[type]}

      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <h3
            className={cx(styles.title, { [styles.hasSubtitle]: !!subtitle })}
          >
            {title}
          </h3>
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </div>

        <div className={styles.actions}>
          {actions ? { ...actions } : defaultActions}
        </div>
      </div>
    </div>
  );
};

export default Message;
