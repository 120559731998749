// extracted by mini-css-extract-plugin
export var badge = "QuickDeposit-module--badge--8c344";
export var blue = "#0eb3f2";
export var bonus = "QuickDeposit-module--bonus--d6343";
export var button = "QuickDeposit-module--button--5fe21";
export var emphasis = "QuickDeposit-module--emphasis--fed96";
export var footer = "QuickDeposit-module--footer--46a1a";
export var green = "#57d33b";
export var greenLink = "QuickDeposit-module--green-link--046a9";
export var grey = "var(--tone-mid)";
export var iconButton = "QuickDeposit-module--iconButton--111b7";
export var input = "QuickDeposit-module--input--008c5";
export var inputContainer = "QuickDeposit-module--inputContainer--9cf94";
export var notchGap = "32px";
export var option = "QuickDeposit-module--option--32513";
export var optionIcon = "QuickDeposit-module--optionIcon--27de4";
export var orange = "#f8662b";
export var quickInput = "QuickDeposit-module--quickInput--21fd6";
export var radio = "QuickDeposit-module--radio--39fa9";
export var red = "#ff2741";
export var title = "QuickDeposit-module--title--74469";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";