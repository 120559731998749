import React from "react";
import cx from "classnames";
import Link, { navigate } from "../Link";

import * as styles from "../ButtonWithState/Button.module.scss";

export const Button = ({
  children,
  disabled,
  onClick,
  to,
  href,
  newTab,
  type,
  size,
  className,
}: {
  children?: string | JSX.Element;
  disabled?: boolean;
  onClick?: (arg0: any) => void;
  to?: string;
  href?: string;
  newTab?: boolean;
  type?: string;
  size?: string;
  className?: string;
}): JSX.Element => {
  const onButtonClick = () => {
    if (!disabled) {
      if (onClick && !disabled) {
        onClick(children);
      } else if (!!to) {
        navigate(to);
      } else if (!!href) {
        if (typeof window !== "undefined") {
          if (newTab) {
            window.open(href, "_blank");
          } else {
            window.location.href = href;
          }
        }
      }
    }
  };

  const innerClassName = cx({
    [`${styles.button}`]: true,
    [`${styles[type]}`]: !!type,
    [`${styles[size]}`]: !!size,
    [`${className}`]: !!className,
  });

  if (to) {
    return (
      <Link className={innerClassName} to={to} onClick={onButtonClick}>
        {children}
      </Link>
    );
  }

  return (
    <div className={innerClassName} onClick={onButtonClick}>
      {children}
    </div>
  );
};

export default Button;
