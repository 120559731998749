import { ADD_TO_LIST, REMOVE_BY_KEY, REMOVE_BY_GROUP } from "./Constants";

// --
// Handlers

const ACTION_HANDLERS = {
  [ADD_TO_LIST]: (state, action) =>
    Object.assign({}, state, {
      [action.payload.key]: action.payload.group,
    }),
  [REMOVE_BY_KEY]: (state, action) => {
    const newState = Object.assign({}, state);
    delete newState[action.payload.key];
    return newState;
  },
  [REMOVE_BY_GROUP]: (state, action) => {
    const newState = Object.keys(state)
      .filter((key) => {
        return state[key] !== action.payload.group;
      })
      .reduce((newState, key) => {
        newState[key] = state[key];
        return newState;
      }, {});
    return newState;
  },
};

// --
// Reducer

const initialState = {};

const loadingReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default loadingReducer;
