import React, { useRef, useEffect, type ReactNode } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { PageLoader } from "library";

import * as styles from "./LoadMore.module.scss";

type LoadMoreProps = {
  next: () => void;
  hasMore: boolean;
  offset?: number;
  loader?: ReactNode;
};

export const LoadMore = ({
  next,
  hasMore,
  offset = 300,
  loader,
}: LoadMoreProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(divRef, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && hasMore) {
      next();
    }
  }, [isVisible]);

  const LoaderComponent = loader || <PageLoader className={styles.loader} />;

  return (
    <>
      <div
        className={styles.loadMore}
        style={{ bottom: offset }}
        ref={divRef}
        data-testid={"load-more"}
      />
      {isVisible && hasMore ? LoaderComponent : null}
    </>
  );
};
