import React from "react";
import cx from "classnames";
import { parseRecord, parseStat } from "./helpers";
import * as styles from "./BlogWidgets.module.scss";

const renderStat = (stat) => {
  const parsedStat = parseStat(stat);

  if (!parsedStat) {
    return stat;
  }

  const statStyle = cx({
    [styles.green]: parseInt(parsedStat.percentage) > 50,
    [styles.yellow]: parseInt(parsedStat.percentage) === 50,
    [styles.red]: parseInt(parsedStat.percentage) < 50,
  });

  return (
    <>
      {parsedStat.stat} (
      <span className={statStyle}>{parsedStat.percentage}%</span>)
    </>
  );
};

const CsgoTeam = ({
  "image-url": imageUrl,
  "team-name": teamName,
  record,
  "most-played-map": mostPlayedMap,
  "best-map": bestMap,
  "worst-map": worstMap,
}) => {
  const parsedRecord = parseRecord(record);
  return (
    <div className={styles.statWidget}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={styles.stats}>
        <div className={styles.name}>{teamName}</div>
        <div className={styles.stat}>
          <span>Record:</span>{" "}
          {parsedRecord ? (
            <>
              <span className={styles.green}>{parsedRecord.wins}</span> /{" "}
              <span className={styles.yellow}>{parsedRecord.draws}</span> /{" "}
              <span className={styles.red}>{parsedRecord.losses}</span>
            </>
          ) : (
            record
          )}
        </div>
        <div className={styles.stat}>
          <span>Most Played Map:</span> {renderStat(mostPlayedMap)}
        </div>
        <div className={styles.stat}>
          <span>Best Map:</span> {renderStat(bestMap)}
        </div>
        <div className={styles.stat}>
          <span>Worst Map:</span> {renderStat(worstMap)}
        </div>
      </div>
    </div>
  );
};

export default CsgoTeam;
