// extracted by mini-css-extract-plugin
export var alert = "Duplicate-module--alert--58957";
export var blue = "#0eb3f2";
export var button = "Duplicate-module--button--f6b36";
export var emphasis = "Duplicate-module--emphasis--07a52";
export var footer = "Duplicate-module--footer--4a676";
export var green = "#57d33b";
export var greenLink = "Duplicate-module--green-link--70066";
export var grey = "var(--tone-mid)";
export var iconButton = "Duplicate-module--iconButton--d978d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";