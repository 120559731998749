import { mapValues } from "lodash";
import type { defaultSrmSlip } from "sections/Betting/Race/components/SameRaceMulti/hooks/useSameRaceMulti";
import {
  RaceMarketTypeTypes,
  type RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";

type ValidateSelectionProps = {
  outcomeId: string;
  selections: typeof defaultSrmSlip;
  markets: RaceMarketsType[];
  flatSelections: string[];
  market: RaceMarketsType;
};

type ValidationResult = {
  updatedSelections: typeof defaultSrmSlip | null;
  errorMessage: string;
};

const getOutcome = (outcomeId: string, markets: RaceMarketsType[]) =>
  markets.map((x) => x.outcomes).find((x) => x?.[outcomeId])?.[outcomeId];

const selectionCount = (
  marketType: RaceMarketTypeTypes,
  selections: typeof defaultSrmSlip,
) => selections?.[marketType]?.length ?? 0;

export const validateSelection = ({
  outcomeId,
  selections,
  markets,
  flatSelections,
  market,
}: ValidateSelectionProps): ValidationResult => {
  if (!market) {
    return { updatedSelections: selections, errorMessage: "" };
  }

  let updatedSelections = selections;

  if (
    flatSelections.some(
      (id) =>
        getOutcome(id, markets).competitorId ===
        market.outcomes[outcomeId].competitorId,
    )
  ) {
    const outcomeCompetitorId = getOutcome(outcomeId, markets).competitorId;
    const newMarketType = market.marketType; // Assuming marketType is a key in selections corresponding to market types.

    // Remove the old selection for the competitor across all market types.
    updatedSelections = mapValues(selections, (selectionsByType) => {
      return selectionsByType.filter((id) => {
        return getOutcome(id, markets).competitorId !== outcomeCompetitorId;
      });
    });

    // Check if the market type exists in updatedSelections and is an array; if not, initialize it.
    if (!updatedSelections[newMarketType]) {
      updatedSelections[newMarketType] = []; // Initialize as an empty array if it doesn't exist.
    }

    // Now safe to add the new outcomeId as we've ensured the array exists.
    if (!updatedSelections[newMarketType].includes(outcomeId)) {
      updatedSelections[newMarketType].push(outcomeId);
    }
  } else {
    updatedSelections = {
      ...selections,
      [market.marketType]: [
        ...(selections[market.marketType] || []),
        outcomeId,
      ],
    };
  }

  // Calculating selection counts, including potential new selection.
  const selectionCounts = {
    win: selectionCount(RaceMarketTypeTypes.Win, updatedSelections),
    top2: selectionCount(RaceMarketTypeTypes.Top2, updatedSelections),
    top3: selectionCount(RaceMarketTypeTypes.Top3, updatedSelections),
    top4: selectionCount(RaceMarketTypeTypes.Top4, updatedSelections),
  };

  let errorMessage = "";
  if (selectionCounts.win > 1) {
    errorMessage = "Only 1 win selection is allowed";
  } else if (selectionCounts.win + selectionCounts.top2 > 2) {
    errorMessage = "No more than 2 runners allowed in the top 2";
  } else if (
    selectionCounts.win + selectionCounts.top2 + selectionCounts.top3 >
    3
  ) {
    errorMessage = "No more than 3 runners allowed in the top 3";
  } else if (
    selectionCounts.win +
      selectionCounts.top2 +
      selectionCounts.top3 +
      selectionCounts.top4 >
    4
  ) {
    errorMessage = "No more than 4 runners allowed in the top 4";
  }

  return {
    updatedSelections: errorMessage ? null : updatedSelections,
    errorMessage,
  };
};
