import * as dateFns from "date-fns";
export namespace FireTransforms {
  export function unwrapSet<T = any>(
    entries: Record<string, T>,
    transformer?: (entry: any, id?: string) => T,
  ): T[] {
    const result: T[] = [];
    if (entries instanceof Array) {
      console.warn("Already unwrapped.");
      return entries as T[];
    } else if (entries && typeof entries === "object") {
      for (const id in entries) {
        if (entries.hasOwnProperty(id)) {
          let entry = entries[id];
          if (typeof transformer === "function") {
            entry = transformer(entry, id);
          } else {
            entry = {
              ...entry,
              id: (typeof entry === "object" && (entry as any).id) || id,
            };
          }
          result.push(entry);
        }
      }
    }
    return result;
  }

  export function wrapSet<T>(
    array: T[],
    getKey?: (entry: T) => string,
  ): Record<string, T> {
    if (!getKey) {
      getKey = (x: any) => x.id;
    }
    const result: Record<string, T> = {};
    if (array) {
      for (const item of array) {
        if (!item) {
          continue;
        }
        const key = getKey(item);
        if (!key) {
          continue;
        }
        result[key] = item;
      }
    }
    return result;
  }

  export function combine(...sets: any[]) {
    const result: Record<string, any> = {};
    for (const set of sets) {
      if (!set || typeof set !== "object") {
        continue;
      }
      for (const key in set) {
        if (!set[key]) {
          continue;
        }
        if (!result[key]) {
          result[key] = set[key];
        }
      }
    }
    return result;
  }

  export function parseDate(date: string | Date): Date {
    if (!date) {
      return null;
    }
    if (typeof date === "string") {
      const hasTimezone = Boolean(date.match(/(Z|[\+\-][\d:]*)$/));
      if (hasTimezone !== true) {
        date += "Z";
      }
      date = dateFns.parseISO(date as string);
    }
    return dateFns.isValid(date) ? date : undefined;
  }

  export function parseBool(
    value: string | boolean | undefined,
    defaultValue?: boolean,
  ): boolean | undefined {
    if (typeof value === "boolean") {
      return value;
    }
    if (typeof value === "string") {
      value = value.toLowerCase().trim();
      if (value === "true") {
        return true;
      }
      if (value === "false") {
        return false;
      }
    }
    return defaultValue;
  }

  export function parseDecimal(
    value: string | number,
    places?: number,
  ): number {
    let result = typeof value === "string" ? parseFloat(value) : Number(value);
    if (isNaN(places) === false) {
      const unit = Math.pow(10, places);
      result = Math.round(result * unit) / unit;
    }
    return result;
  }
}
