import { useBetslip, useSelector } from "hooks";
import {
  selectUsedComboPromoAmount,
  selectUsedPromoAmount,
} from "sections/Betting/Betslip/betslipSlice";

const useRemainingPromoBalance = () => {
  const {
    props: { isMultiUsingPromo, multiStake, betType, availablePromoBalance },
  } = useBetslip();
  const isMulti = betType === "MULTI";
  const usedSinglePromoBalance = useSelector(selectUsedPromoAmount);
  const usedComboPromoBalance = useSelector(selectUsedComboPromoAmount);
  const usedMultiPromoBalance = isMultiUsingPromo ? multiStake : 0;
  const usedPromoBalance = isMulti
    ? usedMultiPromoBalance
    : usedSinglePromoBalance;

  return availablePromoBalance - usedPromoBalance - usedComboPromoBalance;
};

export { useRemainingPromoBalance };
