import React from "react";
import { Typography } from "components/Typography";
import cx from "classnames";

import * as styles from "./PageMessage.module.scss";

type PageMessageProps = {
  title: string;
  subTitle?: string | React.ReactNode;
  children?: React.ReactNode;
  image?: React.ReactNode;
  className?: string;
  contentClassName?: string;
};

const PageMessage = ({
  title,
  subTitle,
  children,
  image,
  className,
  contentClassName,
}: PageMessageProps) => {
  return (
    <div className={cx(styles.container, className)}>
      {image ? image : null}
      <Typography className={styles.title} variant={`h3`} noSpacing>
        {title}
      </Typography>
      {subTitle && typeof subTitle === "string" ? (
        <Typography className={styles.subTitle} variant={`body`} noSpacing>
          {subTitle}
        </Typography>
      ) : (
        <div className={styles.subTitle}>{subTitle}</div>
      )}
      <div className={cx(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export { PageMessage };
