// extracted by mini-css-extract-plugin
export var badge = "Tokens-module--badge--c4d41";
export var blue = "#0eb3f2";
export var emphasis = "Tokens-module--emphasis--27955";
export var green = "#57d33b";
export var greenLink = "Tokens-module--green-link--d9872";
export var grey = "var(--tone-mid)";
export var highlight = "Tokens-module--highlight--725fa";
export var icon = "Tokens-module--icon--f9e25";
export var item = "Tokens-module--item--cffe7";
export var label = "Tokens-module--label--54dc7";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var token = "Tokens-module--token--080a5";
export var tokenSelector = "Tokens-module--tokenSelector--428ee";
export var tokens = "Tokens-module--tokens--97199";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";