import React, { forwardRef } from "react";
import { useOddsChangeDirection } from "hooks/useOddsChangeDirection";

interface OddsAnimationProps extends React.HTMLAttributes<HTMLElement> {
  odds: number;
  animationLength?: number;
}

export const OddsChanges = forwardRef<HTMLDivElement, OddsAnimationProps>(
  ({ odds, children, animationLength = 3000, ...props }, ref) => {
    const oddsChangeDirection = useOddsChangeDirection(odds);

    return (
      <div ref={ref} {...props} data-status={oddsChangeDirection}>
        {children}
      </div>
    );
  },
);
