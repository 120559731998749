import React from "react";

function isWeightValid(weight: string) {
  return !!weight && weight !== "";
}

export default ({
  sport,
  weight,
}: {
  sport: "HORSE_RACING" | "GREYHOUNDS" | "HARNESS_RACING";
  weight: string;
}) => {
  if (sport === "HARNESS_RACING" || !isWeightValid(weight)) return;
  return <>({weight?.replace(".0", "")})</>;
};
