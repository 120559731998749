import { toast, useSelector } from "hooks";
import type { EventMarket } from "hooks/firestore/betting/useBetting";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import type { RaceMarketsType } from "sections/Betting/Race/hooks/RacingTypes";
import { setLoading } from "sections/Betting/components/ComplexBetBuilder/ComplexBetBuilderSlice";
import { requestSameRaceMultiOdds } from "utilities/api";

type UseFetchSameRaceMultiOddsProps = {
  isLoggedIn: boolean;
  selections: string[];
  markets?: null | RaceMarketsType[] | EventMarket[];
  rollBackSelectionChange?: () => void;
  setLatestValidSelections?: (selections: string[]) => void;
  removeSelection: (outcomeId: string) => void;
  suspendFetchingNewOdds?: boolean;
};

const getFullNameFromOutcomeId = (outcomeId: string, markets: any[]) => {
  const market = markets.find((market) => market.outcomes[outcomeId]);
  return market?.outcomes[outcomeId]?.name;
};

export const useFetchSameRaceMultiOdds = ({
  isLoggedIn,
  selections,
  markets,
  rollBackSelectionChange,
  setLatestValidSelections,
  removeSelection,
  suspendFetchingNewOdds = false,
}: UseFetchSameRaceMultiOddsProps) => {
  const dispatch = useDispatch();
  const [odds, setOdds] = useState(0);
  const token = useSelector((state) => state?.auth?.token);

  // error handling
  const handleErrors = (errors) => {
    const isRedundantSelection = errors.some(
      (error) => error.messageTemplate === "market.redundant.selection",
    );

    if (isRedundantSelection) {
      errors.forEach((error) => {
        const outcomeId = error.propertyPath.split(".")[3];
        toast({
          title: "Streamlined Selection",
          description: `'${getFullNameFromOutcomeId(
            outcomeId,
            markets,
          )}' removed. No odds impact`,
        });
        removeSelection(outcomeId);
      });
    } else {
      rollBackSelectionChange?.();
    }
  };

  // process the response
  const processResponse = (res) => {
    if (res?.bets.one?.errors?.length > 0) {
      handleErrors(res.bets.one.errors);
    } else if (!res?.bets.one?.odds) {
      rollBackSelectionChange?.();
    } else {
      setLatestValidSelections?.(selections);
      setOdds(res.bets.one.odds.toFixed(2));
    }
  };

  // fetch odds based on current selections
  const fetchOdds = async () => {
    // return early
    if (!isLoggedIn || token === null || selections.length <= 1) {
      setOdds(0);
      dispatch(setLoading(false));
      return;
    }

    dispatch(setLoading(true));
    try {
      const res = await requestSameRaceMultiOdds({
        token,
        payload: {
          bets: { one: { selections: [{ outcomeIds: selections }] } },
        },
      });
      processResponse(res);
    } catch (error) {
      const isMarketNotActive = error.errors?.some(
        (error?: { messageTemplate?: string }) =>
          error?.messageTemplate === "market-not-active",
      );

      if (isMarketNotActive) {
        setOdds(0);
      } else {
        rollBackSelectionChange?.();
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (suspendFetchingNewOdds) return;
    fetchOdds();
  }, [selections, markets, isLoggedIn, token, suspendFetchingNewOdds]);

  return odds;
};
