// extracted by mini-css-extract-plugin
export var base = "InfoPanel-module--base--54779";
export var blue = "#0eb3f2";
export var caret = "InfoPanel-module--caret--5503c";
export var content = "InfoPanel-module--content--dbbc2";
export var emphasis = "InfoPanel-module--emphasis--4b34f";
export var green = "#57d33b";
export var greenLink = "InfoPanel-module--green-link--9b30e";
export var grey = "var(--tone-mid)";
export var header = "InfoPanel-module--header--1b965";
export var headingText = "InfoPanel-module--headingText--98cb6";
export var icon = "InfoPanel-module--icon--50d03";
export var info = "InfoPanel-module--info--05f13";
export var neutral = "InfoPanel-module--neutral--04003";
export var notchGap = "32px";
export var orange = "#f8662b";
export var promo = "InfoPanel-module--promo--64987";
export var red = "#ff2741";
export var showTriangle = "InfoPanel-module--showTriangle--4d2d8";
export var toggleContainer = "InfoPanel-module--toggleContainer--51b90";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";