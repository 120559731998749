// extracted by mini-css-extract-plugin
export var attribute = "ShortForm-module--attribute--87bb3";
export var blue = "#0eb3f2";
export var caret = "ShortForm-module--caret--8dce7";
export var comment = "ShortForm-module--comment--13ab8";
export var emphasis = "ShortForm-module--emphasis--c8a3a";
export var formAttributes = "ShortForm-module--formAttributes--bb731";
export var formLabel = "ShortForm-module--formLabel--f883d";
export var formValue = "ShortForm-module--formValue--12b5f";
export var green = "#57d33b";
export var greenLink = "ShortForm-module--green-link--d281b";
export var grey = "var(--tone-mid)";
export var missing = "ShortForm-module--missing--672c9";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var shortForm = "ShortForm-module--shortForm--9985d";
export var shortFormContainer = "ShortForm-module--shortFormContainer--37cdf";
export var toggle = "ShortForm-module--toggle--14986";
export var wideProperty = "ShortForm-module--wideProperty--d7988";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";