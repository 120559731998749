import type { PromotionCode } from "types/PromotionTypes";
import useUser from "./useUser";

const usePromotionCode = (): [PromotionCode, boolean] => {
  const [user, loading] = useUser();

  return [user?.promotionCode, loading];
};

export default usePromotionCode;
