import React from "react";
import * as styles from "./Badge.module.scss";
import cx from "classnames";
import { cva, type VariantProps } from "class-variance-authority";
import { Skeleton } from "components/Skeleton";

const badgeVariant = cva(styles.badge, {
  variants: {
    variant: {
      gray: styles.gray,
      success: styles.success,
      warning: styles.warning,
      danger: styles.danger,
      info: styles.info,
    },
  },
  defaultVariants: {
    variant: "gray",
  },
});

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariant> & {
    text: string;
    loading?: boolean;
  };

export default ({ text, variant, loading, className }: BadgeProps) => {
  const Component = loading ? Skeleton : "div";

  return (
    <Component
      className={cx(
        badgeVariant({ variant }),
        { [styles.loading]: loading },
        className,
      )}
      mode={"light"}
    >
      {text}
    </Component>
  );
};
