// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var bottom = "Sheet-module--bottom--22ec2";
export var children = "Sheet-module--children--27b22";
export var closeButton = "Sheet-module--closeButton--da468";
export var content = "Sheet-module--content--433d1";
export var description = "Sheet-module--description--65acb";
export var emphasis = "Sheet-module--emphasis--b089a";
export var enter = "Sheet-module--enter--81014";
export var exit = "Sheet-module--exit--9510a";
export var green = "#57d33b";
export var greenLink = "Sheet-module--green-link--de232";
export var grey = "var(--tone-mid)";
export var hasIcon = "Sheet-module--hasIcon--86ebc";
export var icon = "Sheet-module--icon--31ead";
export var left = "Sheet-module--left--1f897";
export var notchGap = "32px";
export var orange = "#f8662b";
export var overlay = "Sheet-module--overlay--c0423";
export var overlayShow = "Sheet-module--overlayShow--3f2ef";
export var red = "#ff2741";
export var right = "Sheet-module--right--0d08e";
export var srOnly = "Sheet-module--srOnly--54a2d";
export var title = "Sheet-module--title--f313d";
export var titleAndIcon = "Sheet-module--titleAndIcon--8d29b";
export var top = "Sheet-module--top--f722a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";