import React from "react";
import { Link, SupportLink } from "library";
import type { Link as LinkType } from "sections/Account/Links";

import * as styles from "./Footer.module.scss";

type FooterLinkProps = {
  link: LinkType;
};

const FooterLink = ({ link }: FooterLinkProps) => {
  if (link.supportOptions) {
    return (
      <SupportLink
        options={link.supportOptions}
        className={styles.link}
        label={link.name}
      />
    );
  }

  if (link.href) {
    return (
      <a
        className={styles.link}
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.name}
      </a>
    );
  }

  if (link.to) {
    return (
      <Link className={styles.link} to={link.to} nolang={link.nolang}>
        {link.name}
      </Link>
    );
  }

  if (typeof link.action === "function") {
    return (
      <button className={styles.link} onClick={link.action}>
        {link.name}
      </button>
    );
  }

  return null;
};

export default FooterLink;
