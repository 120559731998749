import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import React, {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useState,
} from "react";
import type { PopoverTriggerProps } from "@radix-ui/react-popover";
import * as styles from "./InfoPopover.module.scss";

const InfoPopover = ({
  children,
  content,
  open,
  onOpenChange,
  triggerProps,
}: PropsWithChildren<{
  content: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  triggerProps?: PopoverTriggerProps;
}>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  const handleOpenChange = (open: boolean) => {
    if (onOpenChange) {
      onOpenChange(open);
    } else {
      setIsOpen(open);
    }
  };

  return (
    <Popover
      open={typeof open === "boolean" ? open : isOpen}
      onOpenChange={handleOpenChange}
    >
      <PopoverTrigger className={styles.trigger} {...triggerProps}>
        {children}
      </PopoverTrigger>
      <PopoverContent side={`top`} align={"center"} className={styles.popOver}>
        {content}
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopover;
