import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  MultiModal,
  Icon,
  StreamPlayerWithLoader,
  isPickleStream,
} from "library";
import type { StreamType } from "types/ContentTypes";
import { useDispatch } from "hooks";
import { useIsMobile } from "hooks/useDisplay";
import useWindowResize from "hooks/events/useWindowResize";
import {
  closeAllStreams,
  closeStream,
  toggleMuteStream,
} from "utilities/UI/uiSlice";
import * as styles from "./HUDMediaWindow.module.scss";
import useCalculateSmartBannerOffset from "hooks/useCalculateSmartBannerOffset";

const MATCH_URL_TWITCH_VIDEO = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const MATCH_URL_TWITCH_CHANNEL =
  /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/;

export interface IHUDMediaWindowState {
  streams: Array<StreamType>;
}

const DEFAULT_MEDIA_WIDTH = 420;

const isTwitchStream = (url) => {
  if (!url) return false;
  return url?.indexOf("twitch.tv") >= 0;
};

const TwitchStream = ({ streamUrl }) => {
  const isChannel = MATCH_URL_TWITCH_CHANNEL.test(streamUrl);
  const query = isChannel
    ? `channel=${streamUrl.match(MATCH_URL_TWITCH_CHANNEL)[1]}`
    : `video=${streamUrl.match(MATCH_URL_TWITCH_VIDEO)[1]}`;
  const url = `https://player.twitch.tv/?${query}&parent=${location.hostname}&muted=true&autoplay=true&theme=dark`;

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    // When the iframe has finished loading, set the state to true
    setIframeLoaded(true);
  };

  return (
    <iframe
      src={url}
      width={`100%`}
      height={`100%`}
      allowFullScreen={true}
      onLoad={handleIframeLoad}
      style={{ display: iframeLoaded ? "block" : "none", border: "none" }}
    />
  );
};

const MobileStream = ({ onClose, stream, offsetTop }) => {
  const { width: windowWidth } = useWindowResize();
  const safeWindowWidth = windowWidth || 0;
  const playerHeight = Math.floor((safeWindowWidth / 16) * 9);

  return (
    <div
      className={styles.mobileMediaWindow}
      style={{ top: offsetTop ?? 0, height: playerHeight }}
    >
      <div className={styles.inside}>
        <StreamPlayerWithLoader stream={stream} />
      </div>
      <Icon
        className={styles.closeTop}
        type="close"
        onClick={() => onClose(stream)}
      />
    </div>
  );
};

const DesktopStream = ({ onClose, streams }) => {
  const dispatch = useDispatch();

  return streams.map((stream) => {
    return (
      <div key={stream.eventId}>
        <MultiModal
          width={DEFAULT_MEDIA_WIDTH}
          title={stream.title}
          onClose={() => onClose(stream)}
          maxSize={isPickleStream(stream.url) ? 33 : 80}
          key={stream.eventId}
          ratio={{ width: 16, height: 9 }}
        >
          {isTwitchStream(stream.url) ? (
            <TwitchStream streamUrl={stream.url} />
          ) : (
            <div className={styles.baseMediaHolder}>
              {isPickleStream(stream.url) && (
                <div className={styles.customMediaControls}>
                  <Icon
                    className={styles.icon}
                    type={stream.muted ? "mute" : "unmute"}
                    onClick={() => dispatch(toggleMuteStream(stream))}
                  />
                </div>
              )}
              <StreamPlayerWithLoader stream={stream} />
            </div>
          )}
        </MultiModal>
      </div>
    );
  });
};

export const HUDMediaWindow = () => {
  const smartBannerOffset = useCalculateSmartBannerOffset();
  const isMobile = useIsMobile();
  const streams = useSelector((state: any) => state.utilities.ui.streams);
  const dispatch = useDispatch();

  const onClose = (stream) => {
    dispatch(closeStream(stream));
  };

  const onCloseMobile = () => {
    dispatch(closeAllStreams());
  };

  if (!streams.length) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileStream
        offsetTop={smartBannerOffset}
        onClose={onCloseMobile}
        stream={streams[0]}
      />
    );
  }

  return <DesktopStream onClose={onClose} streams={streams} />;
};
