import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import cx from "classnames";

import * as styles from "./Radio.module.scss";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cx(styles.group, className)}
      {...props}
      ref={ref}
    />
  );
});

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

type RadioGroupItemProps = {
  label?: string | React.ReactNode;
} & RadioGroupPrimitive.RadioGroupItemProps;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<RadioGroupItemProps>>,
  React.ComponentPropsWithoutRef<
    React.ForwardRefExoticComponent<RadioGroupItemProps>
  >
>(({ className, id, label, ...props }, ref) => {
  return (
    <div className={styles.itemContainer}>
      <RadioGroupPrimitive.Item
        id={id}
        ref={ref}
        className={cx(
          styles.item,
          {
            [styles.disabled]: props.disabled,
          },
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className={styles.indicator} />
      </RadioGroupPrimitive.Item>
      {label && (
        <label
          htmlFor={id}
          className={cx(styles.label, {
            [styles.disabled]: props.disabled,
          })}
        >
          {label}
        </label>
      )}
    </div>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
