import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useProfile, useSelector, useCollectionData } from "hooks";
import { collection, type CollectionReference } from "firebase/firestore";

export const VerificationPathByType: Record<VerificationDocumentType, string> =
  {
    AustralianDriversLicence: "australian-drivers-licence",
    AustralianPassport: "australian-passport",
    MedicareCard: "medicare",
    AustralianVisaCheck: "australian-visa",
    NewZealandDriversLicence: "nz-drivers-license",
    NewZealandPassport: "nz-passport",
    NationalId: "national-identification-system",
  };

export const VerificationReadableStatusByStatus: Record<
  VerificationStatus,
  string
> = {
  UNVERIFIED: "Verify",
  IN_PROGRESS: "In progress",
  FAILED: "Unsuccessful",
  ERROR_OCCURRED: "Unsuccessful",
  VERIFIED: "Verified",
  VERIFIED_ADMIN: "Verified",
  PENDING_REVIEW: "In review",
};

export enum VerificationDocumentType {
  AUSTRALIAN_DRIVERS_LICENCE = "AustralianDriversLicence",
  AUSTRALIAN_PASSPORT = "AustralianPassport",
  MEDICARE_CARD = "MedicareCard",
  AUSTRALIAN_VISA = "AustralianVisaCheck",
  NEW_ZEALAND_DRIVERS_LICENCE = "NewZealandDriversLicence",
  NEW_ZEALAND_PASSPORT = "NewZealandPassport",
  NATIONAL_ID = "NationalId",
}

export const VerificationOrder = Object.values(VerificationDocumentType);

export type VerificationStatus =
  | "UNVERIFIED"
  | "IN_PROGRESS"
  | "FAILED"
  | "VERIFIED"
  | "ERROR_OCCURRED"
  | "VERIFIED_ADMIN"
  | "PENDING_REVIEW";

export type Verification = {
  name: string;
  status: VerificationStatus;
  displayOrder: number | null;
  sufficient: boolean;
  type: VerificationDocumentType;
};

type VerificationCollection = {
  dataSources: Record<VerificationDocumentType, Verification>;
  id: string;
  status: string;
};

export const useVerifications = (): [
  Verification[] | undefined,
  boolean,
  Error | undefined,
] => {
  const userId = useSelector((state) => state.auth.userId);
  const ref = userId
    ? (collection(
        getFirestore(),
        "users",
        userId,
        "verifications",
      ) as CollectionReference<VerificationCollection>)
    : undefined;
  const [verificationCollection, loading, error] =
    useCollectionData<VerificationCollection>(ref, ref?.path);

  const verifications = useMemo(() => {
    return (
      Object.entries(verificationCollection?.[0]?.dataSources || {}) as Array<
        [VerificationDocumentType, Verification]
      >
    )
      .sort(([typeA, a], [typeB, b]) => {
        // We order overall result by order of VerificationDocumentType enum
        const orderA = VerificationOrder.indexOf(
          typeA as VerificationDocumentType,
        );
        const orderB = VerificationOrder.indexOf(
          typeB as VerificationDocumentType,
        );
        if (orderA !== orderB) {
          return orderA - orderB;
        }

        // If enum order is the same, use displayOrder for sorting
        if (a.displayOrder === null) return 1;
        if (b.displayOrder === null) return -1;
        return a.displayOrder - b.displayOrder;
      })
      .map(([type, verification]) => {
        return {
          ...verification,
          type,
        };
      });
  }, [verificationCollection]);

  return [verifications, loading, error];
};

export const useVerifyDocCount = () => {
  const [verifications] = useVerifications();
  const [profile] = useProfile();

  // if nothing loaded yet return 0
  if (!verifications || !profile) return 0;

  if (["AU", "NZ"].includes(profile.country)) {
    // for Australia and New Zealand, we need to verify 2 documents
    // if at least one verification has `sufficient` field set to true
    // that means we only need to verify one more document
    const sufficient = Object.values(verifications).some(
      (verification) => verification.sufficient === true,
    );

    return sufficient ? 1 : 2;
  }

  // for other countries we only need 1 document
  return 1;
};
