// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var box = "BlogWidgets-module--box--f15cf";
export var emphasis = "BlogWidgets-module--emphasis--745ef";
export var featuredBettingEvent = "BlogWidgets-module--featuredBettingEvent--94d64";
export var featuredMatch = "BlogWidgets-module--featuredMatch--f87f1";
export var franchiseWidget = "BlogWidgets-module--franchiseWidget--1b09f";
export var green = "BlogWidgets-module--green--d7b23";
export var greenLink = "BlogWidgets-module--green-link--0bfcb";
export var grey = "var(--tone-mid)";
export var icon = "BlogWidgets-module--icon--fd490";
export var image = "BlogWidgets-module--image--fab80";
export var name = "BlogWidgets-module--name--1d79b";
export var notchGap = "32px";
export var orange = "#f8662b";
export var player = "BlogWidgets-module--player--45b9e";
export var promoMarket = "BlogWidgets-module--promoMarket--26e5c";
export var red = "BlogWidgets-module--red--d401b";
export var stat = "BlogWidgets-module--stat--43ff2";
export var statWidget = "BlogWidgets-module--statWidget--c0e94";
export var stats = "BlogWidgets-module--stats--eb1cd";
export var teams = "BlogWidgets-module--teams--40680";
export var title = "BlogWidgets-module--title--f947b";
export var titleIconWidget = "BlogWidgets-module--titleIconWidget--76925";
export var tweetContainer = "BlogWidgets-module--tweetContainer--daa36";
export var vs = "BlogWidgets-module--vs--0133f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "BlogWidgets-module--yellow--7375a";
export var youtubePlayer = "BlogWidgets-module--youtubePlayer--33059";