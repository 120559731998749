import React, { useCallback, useState } from "react";
import { Dialog, type DialogProps } from "components/Dialog";
import { Button } from "components/Button";

import { confirmButtons } from "./Dialog.module.scss";

type ConfirmDialog = {
  onConfirm: () => void;
  onCancel?: () => void;
} & Omit<DialogProps, "children">;

const ConfirmDialog = ({
  icon,
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  title,
  description,
}: ConfirmDialog) => {
  const [loading, setLoading] = useState(false);
  const onConfirmInternal = useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }, [onConfirm]);

  const onCancelInternal = useCallback(() => {
    if (typeof onCancel === "function") {
      onCancel();
    }

    // close modal after cancelling
    onClose();
  }, [onCancel]);

  return (
    <Dialog
      icon={icon}
      title={title}
      description={description}
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className={confirmButtons}>
        <Button
          onClick={() => onConfirmInternal()}
          loading={loading}
          size={`md`}
          variant={`primary`}
        >
          Yes
        </Button>
        <Button
          onClick={() => onCancelInternal()}
          size={`md`}
          variant={`blurred`}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export { ConfirmDialog };
