// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "BetOddsWithDeductions-module--container--e105c";
export var content = "BetOddsWithDeductions-module--content--d5611";
export var deductions = "BetOddsWithDeductions-module--deductions--aa093";
export var emphasis = "BetOddsWithDeductions-module--emphasis--22903";
export var green = "#57d33b";
export var greenLink = "BetOddsWithDeductions-module--green-link--8d492";
export var grey = "var(--tone-mid)";
export var info = "BetOddsWithDeductions-module--info--86ce2";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";