import { useState, useMemo } from "react";

import { useSelector } from "hooks";
import type { Nullable } from "types/utilities";

const API_URL = process.env.GATSBY_API_URI;

export type HistoricClaim = {
  promoAmount: number;
  availableAmount: number;
  currency: string;
  dateClaimed: string;
  name: string;

  // todo: remove this at some point as the data is not used
  bonusCash: number;
};

export const useHistoricPromotionClaims = (
  page: number,
): [Nullable<HistoricClaim[]>, boolean, boolean] => {
  const limit = 20;

  const [claims, setClaims] = useState<HistoricClaim[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const token = useSelector((state) => state.auth.token);

  useMemo(() => {
    fetchClaims({ token, page, limit }, (data) => {
      setClaims((prev) => (prev ? prev.concat(data) : data));

      if (data?.length < limit) {
        setHasMore(false);
      }

      setLoading(false);
    });
  }, [token, page]);

  return [claims, loading, hasMore];
};

async function fetchClaims(
  { token, page, limit }: { token: string; page: number; limit: number },
  cb: (responseData: HistoricClaim[]) => void,
) {
  const response = await fetch(
    `${API_URL}/promotions/claims/historical?page=${page}&limit=${limit}&offset=${
      page * limit
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const claims = response.ok ? await response.json() : [];
  return cb(claims);
}
