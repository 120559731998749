import { getAppCheckToken } from "store/getFirebase";

type Options = RequestInit;

async function injectAppCheckTokenIntoOptions(
  options: Options,
  forceRefresh: boolean,
) {
  const token = await getAppCheckToken(forceRefresh);
  const { headers, ...extraOpts } = options;

  const tokenHeaders = token ? { "X-Firebase-AppCheck": token } : {};

  return {
    headers: {
      "Content-Type": "application/json",
      ...tokenHeaders,
      ...headers,
    },
    ...extraOpts,
  };
}

export const fetchWithAppCheck = async (url: string, options: Options = {}) => {
  const reqOptions = await injectAppCheckTokenIntoOptions(options, false);

  return fetch(url, reqOptions).then((response) => {
    const hasAppCheckChallenge = response.headers
      ?.get("WWW-Authenticate")
      ?.includes("X-Firebase-AppCheck");
    if (response.status === 401 && hasAppCheckChallenge) {
      //got a 401 with an app check challenge indicating the token is expired or invalid.
      //So get a new token and try again
      console.log("Invalid app check token. Refreshing token and retrying");
      return injectAppCheckTokenIntoOptions(options, true).then(
        (newOptions) => {
          return fetch(url, newOptions);
        },
      );
    } else {
      return response;
    }
  });
};
