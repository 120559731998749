import { Skeleton } from "components/Skeleton";
import React from "react";
import * as styles from "./RaceMeetingDropDownSkeleton.module.scss";

export default () => {
  return (
    <div className={styles.container}>
      <Skeleton
        shape="rect"
        edge="hard"
        mode="light"
        className={styles.clampLeft}
      />
      <div className={styles.text}>
        <Skeleton mode="light" size="sm" edge="hard" span="half" />
        <Skeleton mode="light" size="xs" edge="hard" span="quart" />
      </div>
      <Skeleton
        shape="rect"
        edge="hard"
        mode="light"
        className={styles.clampRight}
      />
    </div>
  );
};
