import React from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "components/Form";
import { Input } from "components/Input";
import { PasswordInput } from "components/PasswordInput";
import { DateInput } from "components/DateInput";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { setModal } from "utilities/UI/uiSlice";
import { useDispatch } from "hooks";
import { validateProfile } from "utilities/api/picklebet/auth";
import config from "appConfig";

import { links } from "../Auth.module.scss";
import { VALID_EMAIL } from "utilities/regex";

type DetailsProps = {
  onComplete: (values: FormValues) => void;
};

const schema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(VALID_EMAIL, "Please enter a valid email")
    .test(
      "Email.con",
      ".con is not a valid email domain, please check your email is correct",
      (value) =>
        !value?.toLowerCase().endsWith(".con") &&
        !value?.toLowerCase().endsWith(".con.au"),
    ),
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string().required("Password is required"),
  dateOfBirth: Yup.string().required("A valid date of birth is required"),
}).required();

export type FormValues = Yup.InferType<typeof schema>;

const Details = ({ onComplete }: DetailsProps) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateOfBirth: null,
    },
  });

  const isStaging = config.ENV === "staging";

  return (
    <>
      <Form
        onSubmit={handleSubmit(async (values: FormValues) => {
          const { email, password, ...profile } = values;
          const validation = await validateProfile(email, password, profile);

          if (validation.length > 0) {
            // if there are server validation errors
            validation.forEach((error) => {
              setError(
                (error.propertyPath.includes("profile")
                  ? error.propertyPath.split(".")[1]
                  : error.propertyPath) as keyof FormValues,
                {
                  type: "manual",
                  message: error.message,
                },
              );
            });

            return;
          }

          onComplete(values);
        })}
      >
        <Input
          autoFocus
          id={"email"}
          label="Email"
          type="email"
          placeholder="Email"
          {...register("email")}
          errorMessage={errors.email?.message}
        />
        <Input
          id={"firstName"}
          label="First Name"
          type="text"
          placeholder="Must match your ID"
          {...register("firstName")}
          errorMessage={errors.firstName?.message}
        />
        {isStaging && (
          <Input
            id={"middleName"}
            label="Middle Name"
            type="text"
            placeholder="Middle Name"
            {...register("middleName")}
            errorMessage={errors.middleName?.message}
          />
        )}
        <Input
          label="Last Name"
          type="text"
          placeholder="Must match your ID"
          {...register("lastName")}
          errorMessage={errors.lastName?.message}
        />
        <PasswordInput
          id={"password"}
          label="Password"
          placeholder="Password"
          {...register("password")}
          errorMessage={errors.password?.message}
        />
        <Controller
          control={control}
          name={"dateOfBirth"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"dateOfBirth"}
              label="When were you born?"
              {...field}
              errorMessage={errors.dateOfBirth?.message}
            />
          )}
        />
        <Button loading={isSubmitting} type={`submit`}>
          Continue
        </Button>
      </Form>
      <div className={links}>
        <Typography component={`span`} variant="body" noSpacing>
          Already have a Picklebet account?{" "}
          <Button variant={"link"} onClick={() => dispatch(setModal("signIn"))}>
            Log in
          </Button>
        </Typography>
      </div>
    </>
  );
};

export { Details };
