import { useSelector } from "hooks/useSelector";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import useProfile from "./useProfile";

export const useIsVerified = (): boolean => {
  const userId = useSelector((state) => state.auth?.userId);

  const [cachedVerificationStatus, setCachedVerificationStatus] =
    useLocalStorage<any>(
      `userVerificationStatus:${userId ?? `anonymous`}`,
      true,
    );

  const [profile, loading] = useProfile();

  useEffect(() => {
    if (loading || !profile) return;

    setCachedVerificationStatus(
      ["VERIFIED", "VERIFIED_ADMIN"].includes(profile?.verificationStatus),
    );
  }, [loading, profile?.verificationStatus]);

  return cachedVerificationStatus;
};
