import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Select } from "components/Select";
import type { GroupBase, Props } from "react-select";

type AddressSelectProps = {
  label?: string;
  onChange?: (value: { value: string; label: string }) => void;
  placeholder?: string;
  region?: string;
};

const AddressSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  onChange,
  placeholder,
  region = "AU",
  ...props
}: AddressSelectProps & Props<Option, IsMulti, Group>) => {
  const { init, setValue, suggestions, clearSuggestions } =
    usePlacesAutocomplete({
      initOnMount: false,
    });

  useEffect(() => {
    const loadPlaces = async () => {
      const loader = new Loader({
        apiKey: "AIzaSyDz1LlLLClq7hLYOXWdrIjxGk0nSyu6b_c",
        version: "weekly",
        libraries: ["places"],
        region,
      });

      await loader.importLibrary("places");
      init();
    };

    loadPlaces();
  }, []);

  return (
    <Select
      {...props}
      placeholder={placeholder}
      label={label}
      onInputChange={(newValue) => setValue(newValue)}
      options={
        suggestions.data.map((suggestion) => ({
          value: suggestion.place_id,
          label: suggestion.description,
        })) as any
      }
      onChange={(value) => onChange?.(value as any)}
      isLoading={suggestions.loading}
      // this disables the default filtering of options
      filterOption={() => true}
      onBlur={() => clearSuggestions()}
    />
  );
};

export { AddressSelect };
