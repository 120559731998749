import React from "react";
import cx from "classnames";
import * as styles from "./BadgeCount.module.scss";

import type { MessageType } from "types/ContentTypes";

type BadgeCountProps = {
  className?: string;
  count?: number | string;
  type?: MessageType;
};

export const BadgeCount = ({
  className,
  count,
  type = "default",
}: BadgeCountProps) => {
  if (!count || count.toString() === "0") return null;

  const typeStyles = type && styles[type];

  const classes = [styles.BadgeCount, className, "count", typeStyles];

  return <span className={cx(classes)}>{count}</span>;
};

export default BadgeCount;
