import React, { useEffect, useState } from "react";
import cx from "classnames";
import { ErrorBoundary, Icon } from "library";
import Event from "sections/Betting/Event/Event";
import Outcomes from "sections/Betting/Event/Outcomes";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";

import * as styles from "./BlogWidgets.module.scss";
import * as eventStyles from "sections/Betting/Event/Event.module.scss";
import { useSelections, useDispatch, useEvent, type SourceType } from "hooks";
import Racer from "sections/Betting/Race/components/CompetitorRow/CompetitorRow";
import { useTitles } from "hooks/graphql/useTitles";
import SRMShare from "sections/Betting/Event/SRMShare";
import { RaceCustomMarketsWithContext } from "components/RaceCustomMarkets/RaceCustomMarkets";
import type { Event as EventType } from "hooks/firestore/betting/useBetting";
import SGMShare from "sections/Betting/Event/SGMShare";
import { Box } from "components/Box";
import { RaceRow, RowTemplate } from "sections/Betting/Racing/components";
import { isMarketOpenForBets } from "utilities/markets";

type FeaturedBettingEventProps = {
  id: string;
  "market-id": string;
  eventLoaded?: boolean;
  setEventLoaded?: (event: string) => void;
  outcomeId?: string;
  stake?: number;
  type?: SourceType;
  addToSlip?: boolean;
  showEventDetails?: boolean;
  showCompetitorDetails?: boolean;
  subOutcomes?: {
    outcomeId: string;
    outcomeName: string;
  }[];
};

const FeaturedBettingEvent = ({
  id,
  "market-id": marketId,
  eventLoaded,
  setEventLoaded,
  outcomeId,
  type,
  addToSlip = false,
  showEventDetails = true,
  showCompetitorDetails = true,
  subOutcomes,
}: FeaturedBettingEventProps) => {
  const [event, loading, marketsLoading] = useEvent(
    id,
    type,
    subOutcomes ? "sgm" : "all",
  );
  const titles = useTitles();
  const [hasAddedToSlip, setHasAddedToSlip] = useState(false);

  const {
    props: { selections },
  } = useSelections();

  const dispatch = useDispatch();

  const selectedBettingMarket = marketId
    ? (event?.markets || []).find((market) => market.id === marketId)
    : null;

  const isOutright = ["SEASON", "TOURNAMENT"].includes(event?.eventType);

  const market = marketId
    ? event?.mainMarket?.id === marketId
      ? event?.mainMarket
      : (event?.markets || []).find((market) => market.id === marketId)
    : null;

  useEffect(() => {
    if (!loading && typeof setEventLoaded === "function" && !eventLoaded) {
      setEventLoaded(id);
    }
  }, [loading, setEventLoaded]);

  useEffect(() => {
    if (
      outcomeId &&
      !loading &&
      event &&
      addToSlip &&
      !selections.some((x) => x === outcomeId) &&
      !hasAddedToSlip &&
      // We add races to the betslip in the component as we need to know the details about the race, region and market type
      event?.eventType !== "RACE"
    ) {
      if (market) {
        const outcome = market?.outcomes[outcomeId];

        if (isMarketOpenForBets(market.status)) {
          dispatch(
            toggleSelection(
              event,
              {
                type: "match", //this is ignored
                sourceId: null,
                outcomeId: outcomeId,
                marketId: marketId,
                odds: outcome.odds,
                eventId: id,
              },
              event.hub,
            ),
          );
          setHasAddedToSlip(true);
        }
      }
    }
  }, [loading, event, selections, outcomeId, marketId, addToSlip, dispatch]);

  if (loading) {
    return null;
  }

  if (!loading && !event?.mainMarket) {
    return null;
  }

  if (!showCompetitorDetails && event?.eventType !== "RACE") {
    return null;
  }

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className={styles.featuredBettingEvent}>
        {showEventDetails && event?.eventType !== "RACE" && (
          <Event
            event={event}
            title={titles[event?.sport?.toLowerCase()]}
            selections={selections}
            toggleSelection={({
              type,
              outcomeId,
              marketId,
              sourceId,
              odds,
              eventId,
            }) => {
              dispatch(
                toggleSelection(
                  event,
                  {
                    type,
                    outcomeId,
                    marketId,
                    sourceId,
                    odds,
                    eventId,
                  },
                  event.hub,
                ),
              );
            }}
          />
        )}
        {showEventDetails && event?.eventType === "RACE" && (
          <Box hideHeader className={styles.box}>
            <RaceRow {...(event as any)} template={RowTemplate.NEXT_TO_JUMP} />
          </Box>
        )}
        {event?.eventType !== "RACE" && !subOutcomes ? (
          <>
            {selectedBettingMarket && (
              <div className={cx(eventStyles.row, styles.promoMarket)}>
                <div className={eventStyles.info}>
                  <div className={eventStyles.title}>
                    <div className={eventStyles.icon}>
                      <Icon type="ribbon" />
                    </div>
                  </div>
                  <div className={eventStyles.description}>
                    <div className={eventStyles.game}>
                      {selectedBettingMarket.name}
                    </div>
                  </div>
                </div>
                <Outcomes
                  marketCategory={selectedBettingMarket.category}
                  eventId={event.eventId}
                  marketId={selectedBettingMarket.id}
                  outcomes={selectedBettingMarket.outcomes}
                  marketType={selectedBettingMarket.marketType}
                  competitors={event.competitors}
                  selections={selections}
                  isOutright={isOutright}
                  isSuspended={selectedBettingMarket.status !== "ACTIVE"}
                  toggleSelection={({
                    type,
                    outcomeId,
                    marketId,
                    sourceId,
                    odds,
                    eventId: matchId,
                  }) => {
                    dispatch(
                      toggleSelection(
                        event,
                        {
                          type,
                          outcomeId,
                          marketId,
                          sourceId,
                          odds,
                          eventId: matchId,
                        },
                        event.hub,
                      ),
                    );
                  }}
                  status={selectedBettingMarket?.status}
                />
                <div />
              </div>
            )}
          </>
        ) : market?.marketType === "CUSTOM" ? (
          <RaceCustomMarketsWithContext
            eventId={id}
            marketId={marketId}
            outcomeId={outcomeId}
            addToSlip={addToSlip}
          />
        ) : !subOutcomes ? (
          <Racer
            eventId={id}
            marketId={marketId}
            outcomeId={outcomeId}
            isPlace={market?.name?.includes("Place") ?? false}
            addToSlip={addToSlip}
            showCompetitorDetails={showCompetitorDetails}
          />
        ) : event?.eventType === "RACE" ? (
          <SRMShare
            eventId={id}
            subOutcomes={subOutcomes}
            addToSlip={addToSlip}
            active={event.status === "OPEN"}
          />
        ) : (
          <SGMShare
            event={event as EventType}
            subOutcomes={subOutcomes}
            addToSlip={addToSlip}
            active={(event as EventType).status === "ACTIVE"}
            loading={loading || marketsLoading}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default FeaturedBettingEvent;
