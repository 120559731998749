import { useState, useEffect } from "react";
import { useDocumentData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import { convertToNumber } from "utilities/helpers";
import { doc } from "firebase/firestore";
import { useAccountBalances } from "hooks/firestore";

import type { WithdrawalSettings } from "hooks/firestore/user/types";

const useWithdrawalSettings = (
  currency: string,
): [WithdrawalSettings, boolean] => {
  const [combinedSettings, setCombinedSettings] = useState({
    amountToTurnover: 0,
    availableBalance: 0,
    withdrawableAmount: 0,
  });
  const [loading, setLoading] = useState(true);

  const userId = useSelector((state) => state.auth?.userId);

  const withdrawalSettingsRef = userId
    ? doc(getFirestore(), "users", userId, "withdrawalSettings", currency)
    : undefined;

  const [withdrawalSettings, withdrawalSettingsLoading] = useDocumentData(
    withdrawalSettingsRef,
  );

  const [accounts, accountsLoading] = useAccountBalances();

  useEffect(() => {
    if (withdrawalSettingsLoading || accountsLoading) {
      return;
    }

    const availableBalance =
      accounts?.[currency.toLowerCase()]?.available.balanceInCents || 0;
    const amountToTurnover = withdrawalSettings?.amountToTurnover || 0;

    setCombinedSettings({
      amountToTurnover,
      availableBalance,
      withdrawableAmount: Math.max(
        0,
        convertToNumber(availableBalance) - convertToNumber(amountToTurnover),
      ),
    });
    setLoading(false);
  }, [
    withdrawalSettings,
    withdrawalSettingsLoading,
    accounts,
    accountsLoading,
  ]);

  return [combinedSettings, loading];
};

export default useWithdrawalSettings;
