import type { BetEntry } from "sections/Entries/types";
import { createContext, useContext } from "react";
import type { NullableMaybe } from "types/utilities";

type SummaryContextType = {
  entry: BetEntry;
  currency: string;
  oddsFormat: string;
};
export const BetSummaryContext =
  createContext<NullableMaybe<SummaryContextType>>(undefined);

export const useBetSummaryContext = () => {
  const context = useContext(BetSummaryContext);

  if (!context) {
    throw new Error(
      "useBetSummaryContext must be within BetSummaryContextProvider",
    );
  }

  return context;
};
