import React, { type ReactNode } from "react";

import { HUDMediaWindow } from "./HUDMediaWindow";
import { AppToWebEvents } from "../utilities/AppWebMessagingBridge";

export const HUDBase = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <HUDMediaWindow />
      <AppToWebEvents />
    </>
  );
};
