import React from "react";
import { useBetslip, useDispatch } from "hooks";
import {
  setStakeMulti,
  updateBetslip,
} from "sections/Betting/Betslip/betslipSlice";
import { navigate } from "library";
import BigNumber from "bignumber.js";
import { Clamp } from "components/Clamp";
import { ReactComponent as AcceptIcon } from "sections/Betting/Betslip/assets/accepted.svg";
import { ShareEntryAction } from "components/layout/Entry/components/share/ShareEntryAction";
import { useShareEntryModal } from "components/layout/Entry/components/share/useShareEntryModal";
import { useEntry } from "hooks/firestore/betting/useEntries";
import { ReactComponent as ShareIcon } from "components/assets/share.svg";
import type { BetEntry } from "sections/Entries/types";

import { BetCardAlert } from "./BetCardAlert";
import * as styles from "./SingleCardAlert.module.scss";

const MultiCardAlert = () => {
  const {
    props: { multiAccepted, multiRejected, betId },
  } = useBetslip();
  const dispatch = useDispatch();

  const [setEntryShareModalOpen, getModalProps] = useShareEntryModal();
  const [entryToShare, isLoadingEntry] = useEntry<BetEntry>(betId);

  if (multiAccepted) {
    const hasEntryToShare = !!entryToShare;

    return (
      <BetCardAlert
        icon={<AcceptIcon />}
        variant={`info`}
        clamp={
          betId && hasEntryToShare ? (
            <>
              <Clamp
                onClick={() => setEntryShareModalOpen(true)}
                variant="info"
                className={styles.clickable}
                animateIn
              >
                <ShareIcon className={styles.clampIcon} />
                Share
              </Clamp>
              <ShareEntryAction
                {...getModalProps({
                  referenceId: betId,
                  entry: [entryToShare, isLoadingEntry],
                })}
              />
            </>
          ) : null
        }
      >
        {`Bet Accepted: B-${multiAccepted}`}
      </BetCardAlert>
    );
  }

  if (multiRejected) {
    let label = `Got It`;
    let action: () => void = () =>
      dispatch(
        updateBetslip({
          multiRejected: null,
        }),
      );

    if (multiRejected.reason === `insufficient.funds`) {
      label = `Deposit`;
      action = () => {
        dispatch(
          updateBetslip({
            multiRejected: null,
          }),
        );
        navigate(`/wallet/deposit`);
      };
    } else if (multiRejected.alternativeStake) {
      label = `Update`;
      action = () => {
        const newStake = new BigNumber(multiRejected.alternativeStake)
          .dividedBy(100)
          .toNumber();
        dispatch(
          updateBetslip({
            multiRejected: null,
          }),
        );
        dispatch(setStakeMulti({ stake: newStake }));
      };
    }

    return (
      <BetCardAlert
        variant={`danger`}
        clamp={
          action ? (
            <Clamp
              className={styles.clickable}
              onClick={action}
              variant={`danger`}
            >
              {label}
            </Clamp>
          ) : null
        }
      >
        {multiRejected.message}
      </BetCardAlert>
    );
  }

  return null;
};

export { MultiCardAlert };
