import { BET_SUBMITTED } from "../../sections/Betting/BettingConfig";

const TYPE_SURVEY = "survey";

const mapper = <T extends Record<string, any>>(action: T) => {
  switch (action.type) {
    case BET_SUBMITTED:
      return (action: any) => ({
        hitType: TYPE_SURVEY,
        name: action.meta.name,
        email: action.meta.email,
      });
    default:
      return () => null;
  }
};

export default mapper;
