import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";

import * as styles from "./Shadow.module.scss";

type ShadowProps = {
  position: "top" | "bottom" | "left" | "right";
  className?: string;
};

export const Shadow = ({ className, position }: ShadowProps) => {
  return (
    <motion.div
      className={cx(className, styles.shadow, {
        [styles.top]: position === "top",
        [styles.bottom]: position === "bottom",
        [styles.left]: position === "left",
        [styles.right]: position === "right",
      })}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.1 }}
    />
  );
};
