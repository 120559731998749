import { useStaticQuery, graphql } from "gatsby";

const useRegions = () => {
  const { allContentfulRegion } = useStaticQuery(graphql`
    {
      allContentfulRegion(filter: { code: { in: ["au", "ca", "us"] } }) {
        nodes {
          name
          code
          languages
          defaultSection
          flagCode
        }
      }
    }
  `);

  return allContentfulRegion.nodes;
};

export default useRegions;
