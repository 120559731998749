import React from "react";
import { getStore } from "store";
import FullScreenError from "../FullScreenError";
import { appCrashed } from "utilities/UI/uiSlice";
import * as Sentry from "@sentry/gatsby";

type ErrorBoundaryState = {
  crashed: boolean;
};

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryState = {
    crashed: false,
  };

  constructor(props) {
    super(props);
    this.state = { crashed: false };
  }

  static getDerivedStateFromError() {
    return { crashed: true };
  }

  componentDidCatch(error: Error) {
    if (typeof window === "undefined") return;
    getStore().dispatch(appCrashed());
    Sentry.captureException(error);
  }

  render() {
    if (this.state.crashed) {
      return (
        <FullScreenError
          title="Shoot!"
          message="Something went wrong and our developers are looking into it..."
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
