import React from "react";
import { Countdown, Icon, StatusPill } from "library";
import { isPast } from "date-fns";

type StatusAndCountdownProps = {
  status: string;
  scheduledStartTime: Date;
  className?: string;
};

export const getStatusColor = (status: string, hasStarted: boolean): string => {
  switch (status) {
    case "LIVE":
      return "yellow";
    case "SUSPENDED":
      return hasStarted ? "yellow" : "grey";
    case "SETTLED":
    case "CANCELLED":
    case "DEACTIVATED":
    case "RESULTED":
    case "JUMPED":
      return "blue";
    case "WIN":
    case "WON":
    case "FINAL":
    case "DONE":
      return "green";
    case "VOID":
    case "LOSE":
    case "LOST":
    case "INTERIM":
    case "PROTEST":
    case "CLOSED":
      return "red";
    default:
      return "grey";
  }
};

const getStatusIcon = (status: string, hasStarted: boolean) => {
  if (status === "SUSPENDED") {
    if (hasStarted) return null;
    return <Icon type="lock" />;
  }

  return null;
};

const getStatusText = (status: string, hasStarted: boolean): string => {
  if (status === "SETTLED" || status === "CANCELLED") {
    return "RESULTED";
  }

  if (status === "DONE") {
    return "FINAL";
  }

  if (status === "SUSPENDED" && hasStarted) {
    return "LIVE";
  }

  return status;
};

const StatusAndCountdown = ({
  status,
  scheduledStartTime,
  className: classNameProp,
}: StatusAndCountdownProps) => {
  const statuses = [
    "SUSPENDED",
    "LIVE",
    "SETTLED",
    "CANCELLED",
    "DEACTIVATED",
    "WON",
    "LOST",
    "VOID",
    "RESULTED",
  ];

  const racingStatuses = [
    "CLOSED",
    "INTERIM",
    "PROTEST",
    "FINAL",
    "DONE",
    "JUMPED",
    "ABANDONED",
  ];

  const shouldShowStatus = [...statuses, ...racingStatuses].includes(status);
  const hasStarted = isPast(scheduledStartTime);
  const statusIcon = getStatusIcon(status, hasStarted);

  return (
    <>
      {shouldShowStatus ? (
        <StatusPill
          className={classNameProp}
          color={getStatusColor(status, hasStarted) as any}
        >
          {statusIcon ? statusIcon : getStatusText(status, hasStarted)}
        </StatusPill>
      ) : (
        <Countdown
          className={classNameProp}
          onlyBiggest={true}
          until={scheduledStartTime}
        />
      )}
    </>
  );
};

export default StatusAndCountdown;
