import { useDispatch, useSelector } from "hooks/index";
import { useUser } from "hooks/firestore/index";
import type { FirestoreError } from "firebase/firestore";
import { logout } from "utilities/Auth/authSlice";
import type { Profile } from "hooks/firestore/user/types";

const useProfile = (): [Profile | undefined, boolean] => {
  const userId = useSelector((state: any) => state.auth?.userId);
  const isSigningOut = useSelector((state: any) => state.auth?.signingOut);

  const dispatch = useDispatch();

  const [user, loading, error] = useUser();

  if (!isSigningOut) {
    checkSession(error, userId, () => {
      dispatch(logout(null));
    });
  }

  return [user?.profile, loading];
};

export const useProfileAddress = (): [string | undefined, boolean] => {
  const [profile, loading] = useProfile();

  const address = profile
    ? [
        profile.unit,
        profile.unit ? "/" : "",
        profile.streetNumber,
        " ",
        profile.street,
        ", ",
        profile.state,
        ", ",
        profile.postCode,
        ", ",
        profile.country,
      ].join("")
    : "";

  return [address, loading];
};

const checkSession = (error: FirestoreError, userId, callback) => {
  if (!error || !userId) {
    return;
  }

  switch (error?.code) {
    case "permission-denied":
    case "unauthenticated":
      console.warn(
        `Got '${error.code}' error from firebase. Assuming firebase token is invalid so logging user '${userId}' out. Message:  ${error?.message}`,
      );

      callback && callback();
      break;
    default:
      console.warn(
        `Unknown error while fetching profile`,
        error?.code,
        error?.message,
      );
  }
};

export default useProfile;
