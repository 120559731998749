import React from "react";
import { motion } from "framer-motion";
import { Box } from "components/Box";
import cx from "classnames";

import * as styles from "./Card.module.scss";

type CardProps = {
  children?: React.ReactNode;
  className?: string;
};

const Card = ({ children, className }: CardProps) => {
  return (
    <motion.div className={cx(styles.cardWrapper, className)}>
      <Box className={styles.card} hideHeader>
        {children}
      </Box>
    </motion.div>
  );
};

export { Card };
