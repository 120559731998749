import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import {
  collection,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import type { CreditCard } from "types/UserTypes";
import { useEffect, useState } from "react";
import { isValid, parseISO, subYears } from "date-fns";

const creditCardConverter: FirestoreDataConverter<CreditCard> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): CreditCard => {
    return {
      ...snapshot.data(options),
      id: snapshot.id,
    } as CreditCard;
  },
};

export const useCreditCards = (): [CreditCard[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const [sortedCards, setSortedCards] = useState([]);
  const [sorted, setSorted] = useState(false);

  const creditCardsRef = userId
    ? collection(getFirestore(), "users", userId, "creditCards").withConverter(
        creditCardConverter,
      )
    : undefined;

  const [cards, loading] = useCollectionData<CreditCard>(
    creditCardsRef,
    creditCardsRef?.path,
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    const sorted = (cards || []).slice().sort((a, b) => {
      const parsedA = parseISO(a.lastSuccessfulDepositAt);
      const parsedB = parseISO(b.lastSuccessfulDepositAt);
      // note if there is no deposit date assume deposit date is impossibly old
      const oldDate = subYears(new Date(), 10);

      const aDate = isValid(parsedA) ? parsedA : oldDate;
      const bDate = isValid(parsedB) ? parsedB : oldDate;

      return bDate.getTime() - aDate.getTime();
    });

    setSortedCards(sorted);
    setSorted(true);
  }, [cards, loading]);

  // once sorted consider loaded
  return [sortedCards, !sorted];
};

export const useCreditCardWithDeposit = (): CreditCard | undefined => {
  const [cards, loading] = useCreditCards();

  if (loading) {
    return undefined;
  }

  return cards.find((card) => card.successfulDepositCount > 0);
};

export default useCreditCards;
