// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var deduction = "DeductionMarkets-module--deduction--8bbcb";
export var emphasis = "DeductionMarkets-module--emphasis--1a20d";
export var green = "#57d33b";
export var greenLink = "DeductionMarkets-module--green-link--1508d";
export var grey = "var(--tone-mid)";
export var label = "DeductionMarkets-module--label--d8751";
export var notchGap = "32px";
export var odds = "DeductionMarkets-module--odds--726db";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";