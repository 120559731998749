import React from "react";
import { Checkbox } from "components/Checkbox";
import cx from "classnames";

import * as styles from "./Filters.module.scss";

type FiltersProps = {
  values: Array<string | [string, string]>;
  onSelect: (option: string[]) => void;
  selected?: string[];
  className?: string;
  mustHaveOne?: boolean;
};

export const Filters = ({
  values,
  onSelect,
  selected = [],
  className = "",
  mustHaveOne = false,
}: FiltersProps) =>
  values && (
    <ul className={cx(styles.filters, className)}>
      {values.map((x: string | [string, string]) => {
        const value = x instanceof Array ? x[0] : (x as string);
        const title = x instanceof Array ? x[1] : (x as string);
        const checked = selected.includes(value);

        const handleChange = () => {
          const newSelected = selected.slice();
          if (!checked) {
            newSelected.push(value);
          } else if (!mustHaveOne || newSelected.length > 1) {
            const index = newSelected.indexOf(value);
            newSelected.splice(index, 1);
          }
          onSelect(newSelected);
        };

        return (
          <li key={value}>
            <Checkbox
              className={styles.check}
              label={title}
              value={value}
              checked={checked}
              onCheckedChange={handleChange}
              noSpacing
            />
          </li>
        );
      })}
    </ul>
  );
