import React, { useContext, useState } from "react";
import cx from "classnames";
import {
  COMPETITOR_SEPARATOR,
  useRaceTips,
} from "sections/Betting/Race/hooks/useRaceTips";
import { RaceContext } from "sections/Betting/Race/Race";
import { Box } from "components/Box";
import { Pill } from "components/Pill";
import { Caret } from "components/Caret";
import { TipsCompetitorInfo } from "./TipsCompetitorInfo";

import * as styles from "./RaceTips.module.scss";
import { Drawer } from "components/Drawer/Drawer";
import { formatStartingPosition } from "utilities/racingUtilities";
import { RaceTipsCompetitorsSkeleton } from "sections/Betting/Race/components/RaceTips/RaceTipsCompetitorsSkeleton";

const LIMIT = 4;

const RaceTips = () => {
  const { race, toggleRace, topFlucs, loading } = useContext(RaceContext);
  const [pressed, setPressed] = useState(false);
  const {
    showPrices,
    filteredCompetitors,
    parsedRaceComment,
    validCompetitorCount,
    visible,
  } = useRaceTips();

  const displayedCompetitors = filteredCompetitors
    ?.filter((x) => x && !x.scratched)
    ?.slice(0, LIMIT);

  return (
    <div className={cx(styles.arrow, { [styles.active]: visible })}>
      <Drawer isOpen={visible}>
        <Box
          hideHeader
          className={cx(styles.raceCommentsContainer, {
            [styles.active]: visible,
          })}
        >
          {loading ? (
            <RaceTipsCompetitorsSkeleton />
          ) : (
            <>
              {parsedRaceComment.comment && (
                <div className={cx(styles.competitors)}>
                  {displayedCompetitors.map((competitor) => (
                    <TipsCompetitorInfo
                      key={competitor.id}
                      competitor={competitor}
                      race={race}
                      showPrices={showPrices}
                      validCompetitorCount={validCompetitorCount}
                      toggleRace={toggleRace}
                      className={styles.competitor}
                      topFlucs={topFlucs}
                      showRunnerNumber={race.sport !== "GREYHOUNDS"}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          <Drawer isOpen={pressed}>
            <div className={styles.comment} onClick={() => setPressed(false)}>
              {parsedRaceComment?.comment
                ?.split(COMPETITOR_SEPARATOR)
                ?.map((commentPiece, index) => {
                  const competitor = filteredCompetitors[index];
                  if (!competitor) return commentPiece;
                  const startingPosition = competitor?.startingPosition
                    ? `(${formatStartingPosition(
                        competitor?.startingPosition,
                      )})`
                    : "";
                  return (
                    <span key={competitor.id}>
                      {commentPiece}
                      <span
                        className={cx({
                          [styles.bold]: true,
                          [styles.isScratched]: competitor?.scratched,
                        })}
                      >
                        {`${competitor?.number}. ${competitor?.name}  ${startingPosition}`.toUpperCase()}
                      </span>
                    </span>
                  );
                })}
            </div>
          </Drawer>

          <div
            className={cx(styles.toggle, {
              [styles.active]: pressed,
              [styles.showSkeleton]: loading,
            })}
          >
            <Pill onPressedChange={() => setPressed(!pressed)} pressed={false}>
              {pressed ? `Read Less` : `Read More`}
              <Caret
                variant={pressed ? "up" : "down"}
                className={cx(styles.caret)}
              />
            </Pill>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default RaceTips;
