import { useEffect, useState } from "react";
import { formatDistanceToNowAbbr } from "../utilities/datetime";
import { useInterval } from "usehooks-ts";
import { differenceInSeconds } from "date-fns";

const MS_SECOND = 1000;
const MS_MINUTE = MS_SECOND * 60;

const useCountdown = (until: Date): [string, number] => {
  const [remaining, setRemaining] = useState(formatDistanceToNowAbbr(until));

  const secondsToNow = differenceInSeconds(until, new Date());

  const updateInterval = secondsToNow <= 5 * 60 ? MS_SECOND : MS_MINUTE;

  useEffect(() => {
    setRemaining(formatDistanceToNowAbbr(until));
  }, [until]);

  useInterval(() => {
    setRemaining(formatDistanceToNowAbbr(until));
  }, updateInterval);

  return [remaining, secondsToNow];
};

export { useCountdown };
