import * as React from "react";
import { useDispatch, useSelector } from "hooks";
import FullScreenModal from "components/FullScreenModal/FullScreenModal";
import { setModal } from "utilities/UI/uiSlice";
import { Typography } from "components/Typography";
import { SupportLink } from "components/SupportLink";
import { Link } from "components/Link";

export const BET_STOP_MODAL_ID_SIGNUP = "BetStopSignup";

export const BetStopModalSignup = () => {
  const isOpen = useSelector(
    (state) => state.utilities.ui.modal === BET_STOP_MODAL_ID_SIGNUP,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"National Self-Exclusion Register"}
    >
      <Typography variant="body">
        Due to your details appearing on the BetStop National Self-Exclusion
        Register, you are prevented from creating a betting account with
        Picklebet. More information on Gambling help can be found in our{" "}
        <SupportLink options={{ articleId: "115010094608" }} hideChevron>
          Responsible Gambling Policy.
        </SupportLink>
      </Typography>

      <Typography variant="body">
        Customer Support and our Safer Gambling Team can also assist you with
        any of these options via{" "}
        <Link to="mailto:support@picklebet.com">email</Link> and{" "}
        <Link to="https://live-chat"> Live Chat</Link> .
      </Typography>
    </FullScreenModal>
  );
};
