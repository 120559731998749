// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Separator-module--emphasis--51c98";
export var green = "#57d33b";
export var greenLink = "Separator-module--green-link--4c237";
export var grey = "var(--tone-mid)";
export var lg = "Separator-module--lg--2ea81";
export var md = "Separator-module--md--a215d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var separator = "Separator-module--separator--3f73f";
export var sm = "Separator-module--sm--2aa79";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";