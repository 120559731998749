import React from "react";
import { TitleIcon as Icon } from "library/components/Icon/TitleIcon";
import * as styles from "./BlogWidgets.module.scss";
import { TitlesContext } from "./Contexts";
import { ErrorBoundary } from "library";

const TitleIcon = (props) => {
  const { title, size = "22px" } = props;
  return (
    <ErrorBoundary>
      <TitlesContext.Consumer>
        {(titleMap) => (
          <span
            className={styles.titleIconWidget}
            style={{ height: size, width: size }}
          >
            <Icon title={titleMap[title]} width={size} height={size} inline />
          </span>
        )}
      </TitlesContext.Consumer>
    </ErrorBoundary>
  );
};

export default TitleIcon;
