import React, {
  type RefObject,
  type CSSProperties,
  type ReactNode,
  type ReactElement,
} from "react";

export function addStyle(
  ref: RefObject<HTMLElement> | HTMLElement,
  style: CSSProperties,
) {
  const element = getRefElement(ref);
  if (!element || !element.style) {
    return;
  }
  for (const key in style) {
    if (style[key] && element.style[key] !== style[key]) {
      element.style[key] = style[key];
    }
  }
}

export function setStyle(
  ref: RefObject<HTMLElement> | HTMLElement,
  style: CSSProperties,
) {
  clearStyle(ref);
  addStyle(ref, style);
}

export function clearStyle(ref: RefObject<HTMLElement> | HTMLElement) {
  const element = getRefElement(ref);
  if (!element) {
    return;
  }
  element.removeAttribute("style");
}

export function getRefElement(
  refOrElement: RefObject<HTMLElement> | HTMLElement,
): HTMLElement | null {
  return (
    (refOrElement
      ? (((refOrElement as any).current || refOrElement) as HTMLElement)
      : null) || null
  );
}

export function findChildComponent(
  parent: ReactNode,
  condition: (child: ReactElement) => boolean,
  maxLevels = 10,
): ReactElement | null {
  if (parent && typeof parent === "object") {
    const parentObj = parent as any;
    if (parentObj.hasOwnProperty("0") && parentObj.hasOwnProperty("length")) {
      for (let i = 0; i < parentObj.length; i++) {
        const found = findChildComponent(
          parentObj[i],
          condition,
          maxLevels - 1,
        );
        if (found) {
          return found;
        }
      }
    } else {
      const props = parentObj.props;
      if (props) {
        if (condition(parent as ReactElement)) {
          return parent as ReactElement;
        }
        const children = props.children;
        if (maxLevels > 0 && children) {
          return findChildComponent(children, condition, maxLevels - 1);
        }
      }
    }
  }
  return null;
}

/**
 * Pass a loading state down a component tree, recursing over any fragments
 * */
export const renderChildrenWithLoading = (
  children: React.ReactNode,
  loading: boolean,
) => {
  return React.Children.map(
    children,
    (child: React.ReactElement<Record<string, unknown>>) => {
      if (React.isValidElement(child)) {
        if (React.Fragment === child.type) {
          // If child is a fragment, render its children recursively
          return renderChildrenWithLoading(child.props.children, loading);
        }
        // Clone the child with the loading prop
        return React.cloneElement(child, { loading, ...(child.props as any) });
      }
      return child;
    },
  );
};
