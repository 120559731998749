import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";
import cx from "classnames";
import * as styles from "./Pill.module.scss";

const toggleVariants = cva(styles.pillBase, {
  variants: {
    variant: {
      default: styles.pill,
      button: styles.button,
    },
    size: {
      default: styles.medium,
      large: styles.large,
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

const Pill = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cx(toggleVariants({ variant, size }), className)}
    {...props}
  />
));

Pill.displayName = "Pill";

export { Pill };
