import React, { useState, useMemo } from "react";
import { HUDScrollPanel, SearchBox, SearchResult } from "library";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import { TitlesContext } from "../BlogWidgets/Contexts";
import useMustHidePickems from "hooks/useMustHidePickems";
import * as styles from "./SearchMenu.module.scss";
import { usePermissions } from "hooks";

const SearchMenu = ({ titles }) => {
  const [query, setQuery] = useState();
  const hidePickems = useMustHidePickems();
  const permissions = usePermissions();

  const algoliaClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      ),
    [],
  );

  const searchClient = useMemo(
    () => ({
      ...algoliaClient,
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          });
        }

        return algoliaClient.search(requests);
      },
    }),
    [algoliaClient],
  );

  const searchIndices = [];

  if (
    permissions.viewSportsMarkets === "GRANTED" ||
    permissions.viewEsportsMarkets === "GRANTED"
  ) {
    searchIndices.push(
      { name: `matches`, title: `Matches` },
      { name: `outrights`, title: `Outrights` },
    );
  }

  if (permissions.viewRacingMarkets === "GRANTED") {
    searchIndices.push({ name: `races`, title: `Racing` });
  }

  searchIndices.push(
    { name: `sports`, title: `Sports` },
    { name: `pages`, title: `Pages` },
  );

  if (!hidePickems) {
    searchIndices.push({
      name: `pickems_upcoming`,
      title: `Upcoming Pick'ems`,
    });
    searchIndices.push({ name: `pickems_live`, title: `Live Pick'ems` });
  }

  const content = (
    <div className={styles.search}>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          className={styles.searchBox}
          placeholder="Enter a search term"
        />
        <TitlesContext.Provider value={titles}>
          <SearchResult query={query} indices={searchIndices} />
        </TitlesContext.Provider>
      </InstantSearch>
    </div>
  );

  return (
    <HUDScrollPanel
      title="Search"
      className={styles.container}
      tabs={[
        {
          title: "Search",
          isActive: true,
          badgeType: "default",
        },
      ]}
      contentBody={content}
    />
  );
};

export default SearchMenu;
