// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var bold = "form-module--bold--04b42";
export var bonus = "form-module--bonus--67ab4";
export var button = "form-module--button--b562d";
export var container = "form-module--container--0f31e";
export var controls = "form-module--controls--c5f2d";
export var custom = "form-module--custom--30495";
export var emphasis = "form-module--emphasis--2c10a";
export var field = "form-module--field--62238";
export var green = "#57d33b";
export var greenLink = "form-module--green-link--bdfc9";
export var grey = "var(--tone-mid)";
export var largeMargin = "form-module--largeMargin--134c8";
export var logo = "form-module--logo--b33a9";
export var name = "form-module--name--8cba7";
export var notchGap = "32px";
export var number = "form-module--number--43544";
export var orange = "#f8662b";
export var red = "#ff2741";
export var section = "form-module--section--12897";
export var submitButton = "form-module--submitButton--48d8e";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";