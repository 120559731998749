import React, { useContext } from "react";
import { BetOdds } from "library";
import type { OddsFormat } from "types/BetTypes";
import { OddsChanges } from "components/OddsChanges";
import { FirebaseContext } from "context/Firebase";

import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";

import * as styles from "./OddsWithChanges.module.scss";

type OddsWithChangesProps = {
  odds: number;
  animationLength?: number;
  prefix?: string;
  isSP?: boolean;
};

const OddsWithChanges = ({
  odds,
  animationLength,
  prefix,
  isSP,
}: OddsWithChangesProps) => {
  const {
    userPreferences: { oddsFormat },
  } = useContext(FirebaseContext);

  if (odds === 0) {
    return <span className={styles.odds}>-</span>;
  }

  if (isSP) {
    return <span className={styles.odds}>SP</span>;
  }

  return (
    <OddsChanges
      odds={odds}
      className={styles.odds}
      animationLength={animationLength}
    >
      <BetOdds
        prefix={prefix}
        base={odds || 0}
        format={oddsFormat as OddsFormat}
        zero={`1.00`}
      />
      <ArrowUp className={styles.arrowUp} />
      <ArrowDown className={styles.arrowDown} />
    </OddsChanges>
  );
};

export { OddsWithChanges };
