import { useMemo } from "react";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import {
  collection,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";

const campaignsConverter: FirestoreDataConverter<any> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ) => {
    return {
      ...snapshot.data(options),
      id: snapshot.id,
    };
  },
};

export const useCampaigns = (): [string[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);

  const campaignsRef = userId
    ? collection(getFirestore(), "users", userId, "campaigns").withConverter(
        campaignsConverter,
      )
    : undefined;

  const [codes, loading] = useCollectionData(campaignsRef, campaignsRef?.path);

  const filteredCodes = useMemo(
    () => (codes || []).filter((code) => code.active).map((code) => code.id),
    [codes, loading],
  );

  return [filteredCodes, loading];
};
