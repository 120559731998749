import {
  useLocation as useGatsbyLocation,
  type WindowLocation,
} from "@gatsbyjs/reach-router";

type LocationWithLocale<T> = {
  pathnameWithoutLocale: string;
} & WindowLocation<T>;

export const useLocation = <T>(): LocationWithLocale<T> => {
  const location = useGatsbyLocation();

  const pathnameWithoutLocale =
    typeof window === "undefined"
      ? location.pathname
      : location.pathname.replace(`/${window.___currentLocale}`, "");

  return {
    ...location,
    pathnameWithoutLocale,
  } as LocationWithLocale<T>;
};
