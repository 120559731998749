export const fetchWithAmznWaf = async (
  url: string,
  options: RequestInit = {},
) => {
  const fetchWithWaf = (window as any)?.AwsWafIntegration?.fetch;

  if (typeof fetchWithWaf === "function") {
    return fetchWithWaf(url, options);
  }

  return fetch(url, options);
};
