import * as React from "react";
import { Alert } from "components/Alert";
import { Button } from "components/Button";
import * as support from "utilities/support";
import { navigate } from "library";
import { useDispatch } from "hooks";
import { openChat as openHelpChat } from "utilities/HelpCenter/helpCenterSlice";
import { setModal } from "../../utilities/UI/uiSlice";

type ErrorsProps = {
  type: string;
  message: string;
};

const Errors = ({ type, message }: ErrorsProps) => {
  const dispatch = useDispatch();
  const openChat = () => {
    support.open();
    dispatch(openHelpChat());
  };

  let content = <>{message}</>;
  let actions = null;

  if (type === "user.closed") {
    content = (
      <>
        <>Your account has been closed.</>
      </>
    );
    actions = (
      <Button onClick={() => openChat()} variant="danger" size="sm">
        Contact Support
      </Button>
    );
  }

  if (type === "password.reset.required") {
    content = (
      <>
        Your account has been temporarily locked due to multiple failed login
        attempts.
      </>
    );

    actions = (
      <Button
        onClick={() => {
          dispatch(setModal(null));
          navigate("/forgot-password");
        }}
        variant="danger"
        size="sm"
      >
        Reset Password
      </Button>
    );
  }

  if (type === "captcha.puzzle.failed") {
    actions = (
      <Button onClick={() => openChat()} variant="danger" size="sm">
        Contact Support
      </Button>
    );
  }

  return (
    <Alert variant="danger" actions={actions}>
      {content}
    </Alert>
  );
};

export { Errors };
