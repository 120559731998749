import React from "react";
import { type MappedCompetitorType, useFiatCurrency } from "hooks";

import { constructCurrency } from "common";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import { raceMarketLabels } from "utilities/racingUtilities";
import * as styles from "./DeductionMarkets.module.scss";

export default ({
  isWinPlace,
  competitor,
}: {
  isWinPlace: boolean;
  competitor: MappedCompetitorType;
}) => {
  const currency = useFiatCurrency();

  const markets = [RaceMarketTypeTypes.Win];
  if (!isWinPlace) {
    markets.push(
      RaceMarketTypeTypes.Top2,
      RaceMarketTypeTypes.Top3,
      RaceMarketTypeTypes.Top4,
    );
  } else {
    markets.push(RaceMarketTypeTypes.Place);
  }
  return (
    <>
      {markets.map((marketType) => {
        return (
          <div key={marketType} className={styles.deduction}>
            {isWinPlace && (
              <span className={styles.label}>
                {raceMarketLabels[marketType]}
              </span>
            )}
            <span className={styles.odds}>
              {constructCurrency(competitor?.deductions[marketType] ?? 0, {
                currencyType: currency,
              })}
            </span>
          </div>
        );
      })}
    </>
  );
};
