import React from "react";
import type { UseFormRegister, FieldErrors } from "react-hook-form";
import { NativeSelect } from "components/NativeSelect";

type DepositLimitsProps = {
  isUpdate?: boolean;
  currencySymbol: string;
  loading?: boolean;
  register: UseFormRegister<
    any & {
      depositLimit: number;
      depositLimitPeriod: number;
    }
  >;
  errors?: FieldErrors<
    any & {
      depositLimit: number;
      depositLimitPeriod: number;
    }
  >;
};

const dollarAmounts = [
  10, 25, 50, 75, 100, 150, 200, 250, 500, 750, 1000, 2000, 5000, 10000, 25000,
  50000,
];

const dayMap = {
  1: "Daily",
  7: "Weekly (7 days)",
  14: "Fortnightly (14 days)",
  30: "Monthly (30 days)",
};

const DEFAULT_DEPOSIT_LIMIT = 50000;
const DEFAULT_DEPOSIT_LIMIT_IN_CENTS = DEFAULT_DEPOSIT_LIMIT * 100;

const DepositLimits = ({
  currencySymbol,
  isUpdate,
  register,
  errors,
  loading = false,
}: DepositLimitsProps) => {
  return (
    <>
      <NativeSelect
        placeholder={`${isUpdate ? "Update" : "Set"} your deposit limit`}
        options={[
          ...dollarAmounts.map((amount) => ({
            label: "" + currencySymbol + amount,
            value: (amount * 100).toString(),
          })),
        ]}
        {...register("depositLimit")}
        errorMessage={errors?.depositLimit?.message as string}
        loading={loading}
        defaultValue={DEFAULT_DEPOSIT_LIMIT_IN_CENTS.toString()}
      />
      <NativeSelect
        placeholder="Limit Period"
        options={Object.entries(dayMap).map(([value, label]) => ({
          label,
          value,
        }))}
        {...register("depositLimitPeriod")}
        errorMessage={errors?.depositLimitPeriod?.message as string}
        loading={loading}
      />
    </>
  );
};

DepositLimits.displayName = "DepositLimits";

export { DepositLimits };
