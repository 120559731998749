// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "MainMenu-module--emphasis--09f40";
export var green = "#57d33b";
export var greenLink = "MainMenu-module--green-link--05552";
export var grey = "var(--tone-mid)";
export var menu = "MainMenu-module--menu--b269e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scrollArea = "MainMenu-module--scrollArea--bc36a";
export var viewport = "MainMenu-module--viewport--3f302";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";