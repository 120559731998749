export const GROUP_DETAILS = "DETAILS";
export const GROUP_VERIFICATION = "VERIFICATION";
export const GROUP_DAILY = "DAILY";
export const GROUP_RAFFLES = "RAFFLES";
export const GROUP_ENTRIES = "ENTRIES";
export const GROUP_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GROUP_LOBBY = "LOBBY";
export const GROUP_LOGIN = "LOGIN";
export const GROUP_REGISTRATION = "REGISTRATION";
export const GROUP_WALLET = "WALLET";
export const GROUP_NOTIFICATIONS = "NOTIFICATIONS";
export const GROUP_SELL = "SELL";
export const GROUP_GEAR = "GEAR";
export const GROUP_BETTING = "BETTING";
export const GROUP_ONBOARDING = "GROUP_ONBOARDING";
export const GROUP_PROMOTIONS = "GROUP_PROMOTIONS";

export const ADD_TO_LIST = "ADD_TO_LIST";
export const REMOVE_BY_KEY = "REMOVE_BY_KEY";
export const REMOVE_BY_GROUP = "REMOVE_BY_GROUP";
