import { motion } from "framer-motion";
import React, {
  type PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";

export default ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState("auto");

  useEffect(() => {
    const firstChild = containerRef.current?.firstChild as HTMLElement;
    if (firstChild) {
      setMaxHeight(`${firstChild.scrollHeight}px`);
    }
  }, [children]);

  return (
    <motion.div
      ref={containerRef}
      animate={{
        height: maxHeight,
        opacity: maxHeight === "0px" ? 0 : 1,
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      style={{ overflow: "hidden" }}
      className={className}
      initial={false}
    >
      {children}
    </motion.div>
  );
};
