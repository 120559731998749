import { type AppDispatch, createStore } from "./createStore";

let store: ReturnType<typeof createStore>;

export function getStore() {
  if (!store) {
    store = createStore();
  }
  return store;
}

export function getDispatch(): AppDispatch {
  return getStore().dispatch;
}

export default getStore;
