// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "PageMessage-module--container--9b8a2";
export var content = "PageMessage-module--content--5c522";
export var emphasis = "PageMessage-module--emphasis--e42b6";
export var green = "#57d33b";
export var greenLink = "PageMessage-module--green-link--9f6c3";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var subTitle = "PageMessage-module--subTitle--be8a8";
export var title = "PageMessage-module--title--1366d";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";