import { useContext, useCallback } from "react";
import { LoadingContext } from "context/LoadingContext";

export type LoadingAction =
  | { type: "add"; payload: string }
  | { type: "remove"; payload: string };

type LoadingState = Set<string>;

const loadingReducer = (draft: LoadingState, action: LoadingAction): void => {
  switch (action.type) {
    case "add":
      draft.add(action.payload);
      break;
    case "remove":
      draft.delete(action.payload);
      break;
  }
};

const useLoading = (key: string): [() => void, () => void] => {
  const [, dispatch] = useContext(LoadingContext);

  // Function to start loading
  const startLoading = useCallback(() => {
    // dispatch({ type: "add", payload: key });
  }, [key, dispatch]);

  // Function to end loading
  const endLoading = useCallback(() => {
    // dispatch({ type: "remove", payload: key });
  }, [key, dispatch]);

  return [startLoading, endLoading];
};

const useIsLoading = (): boolean => {
  const [isLoading] = useContext(LoadingContext);
  return isLoading;
};

export { loadingReducer, useLoading, useIsLoading };
