import { useCallback } from "react";
import { sendMessage } from "utilities/sendMessage";
import type { Payloads } from "utilities/message";

export const useMessage = <T>(messageType: keyof Payloads) => {
  const requestMessage = useCallback(
    async <U>(payload: U): Promise<T> => {
      const [response, error] = await sendMessage(messageType, payload);

      if (error) {
        if (error.body && error.body?.errors?.length) {
          throw error.body.errors[0];
        }

        if (error instanceof Error) {
          throw error;
        }
      }

      if (response.status === "SUCCESS" && response.data) {
        return response.data;
      } else {
        if (response.errors && response.errors?.length) {
          throw response.errors[0];
        } else {
          throw new Error("Could not process your request");
        }
      }
    },
    [messageType],
  );

  return [requestMessage];
};
