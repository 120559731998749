import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useEvent } from "hooks";

const ExpiringContent = ({ at, children }) => {
  const [state, setState] = useState(undefined);

  const findFeaturedBettingEvent = useCallback(
    (children) => {
      // We stop processing at the first found id.
      if (state != undefined) return;

      children?.forEach((child) => {
        if (child?.props?.["market-id"] != undefined) {
          setState({
            id: child.props.id,
            marketId: child?.props?.["market-id"],
          });
          return;
        }

        if (child?.props?.children || child?.props?.children?.length !== 0)
          findFeaturedBettingEvent(child?.props?.children);
      });
    },
    [children],
  );

  useEffect(() => {
    findFeaturedBettingEvent(children);
  }, [children]);

  const [event, loading] = useEvent(state?.id);

  // when marketId is provided we use it to find the market otherwise fallback to main market
  const market = state?.marketId
    ? event?.markets?.find((market) => market.id === state.marketId)
    : event?.mainMarket;

  const hasExpired = at && moment.utc(at).isBefore();

  // If the event is not active or has expired, we don't render the children.
  if (loading || (event && market?.status !== "ACTIVE") || hasExpired) {
    return null;
  }

  return children;
};

export default ExpiringContent;
