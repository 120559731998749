import React, { useEffect } from "react";
import { isWebView } from "utilities/display";
import { ReactPortal } from "../ReactPortal";
import useHasSmartBannerScriptLoaded from "hooks/useHasSmartBannerScriptLoaded";
import { useLocation } from "hooks/useLocation";

const excludePaths = ["/offers/", "/betslip/"];

const SmartBanner = () => {
  const isWebview = isWebView();
  const smartBannerScriptLoaded = useHasSmartBannerScriptLoaded();
  const location = useLocation();

  const isExcludedPath = location?.pathname?.includes(
    excludePaths.find((path) => location?.pathname?.includes(path)),
  );

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.AF &&
      !isWebview &&
      !isExcludedPath &&
      smartBannerScriptLoaded
    ) {
      window.AF("banners", "showBanner", {
        bannerContainerQuery: "#smart-banner",
        // positions the smart banner below full screen modals (sign up/login etc)
        bannerZIndex: 498,
      });
    }
  }, [smartBannerScriptLoaded, isWebview, isExcludedPath]);

  return (
    <ReactPortal wrapperId="smart-banner-container">
      <div id="smart-banner" />
    </ReactPortal>
  );
};

export default SmartBanner;
