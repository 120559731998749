// extracted by mini-css-extract-plugin
export var abandoned = "RaceRow-module--abandoned--d12ec";
export var blue = "#0eb3f2";
export var cancelled = "RaceRow-module--cancelled--d18a4";
export var clamp = "RaceRow-module--clamp--342ed";
export var closed = "RaceRow-module--closed--ec419";
export var done = "RaceRow-module--done--660ba";
export var emphasis = "RaceRow-module--emphasis--5b964";
export var final = "RaceRow-module--final--60604";
export var green = "#57d33b";
export var greenLink = "RaceRow-module--green-link--41dec";
export var grey = "var(--tone-mid)";
export var heading = "RaceRow-module--heading--5f76b";
export var interim = "RaceRow-module--interim--3e2d0";
export var jumped = "RaceRow-module--jumped--cf3d4";
export var notchGap = "32px";
export var number = "RaceRow-module--number--e17cf";
export var open = "RaceRow-module--open--11875";
export var orange = "#f8662b";
export var protest = "RaceRow-module--protest--616a5";
export var raceRow = "RaceRow-module--raceRow--90a6c";
export var red = "#ff2741";
export var subHeading = "RaceRow-module--subHeading--2c10d";
export var title = "RaceRow-module--title--e7fdd";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";