import React from "react";
import { Tweet as ReactTweet } from "react-twitter-widgets";

import * as styles from "./BlogWidgets.module.scss";

const Tweet = ({ id, "hide-media": hideMedia = false }) => {
  return (
    <div className={styles.tweetContainer}>
      <ReactTweet
        tweetId={id}
        options={{
          theme: "dark",
          dnt: true,
          align: "center",
          linkColor: "green",
          width: "550",
          cards: hideMedia ? "hidden" : "visible",
        }}
      />
    </div>
  );
};

export default Tweet;
