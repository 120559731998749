// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "ScrollArea-module--container--9c1f8";
export var dark = "ScrollArea-module--dark--bcc32";
export var emphasis = "ScrollArea-module--emphasis--1b8fc";
export var green = "#57d33b";
export var greenLink = "ScrollArea-module--green-link--e08c8";
export var grey = "var(--tone-mid)";
export var horizontal = "ScrollArea-module--horizontal--6aa87";
export var invisible = "ScrollArea-module--invisible--877e3";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scrollBar = "ScrollArea-module--scrollBar--0c6d2";
export var thumb = "ScrollArea-module--thumb--85658";
export var vertical = "ScrollArea-module--vertical--d0264";
export var viewport = "ScrollArea-module--viewport--e2080";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";