import type { SupportOptions } from "library/components/SupportLink/SupportLink";
import { dispatchNow } from "store";
import { openChat } from "utilities/HelpCenter/helpCenterSlice";

export type Link = {
  name: string;
  action?: () => void;
  href?: string;
  supportOptions?: SupportOptions;
  to?: string;
  nolang?: boolean;
};

export const LegalLinks: Link[] = [
  {
    name: "Contest Rules",
    supportOptions: { articleId: "360001520216" },
  },
  {
    name: "Betting Rules",
    supportOptions: { articleId: "360000522236" },
  },
  {
    name: "Racing Rules",
    supportOptions: { articleId: "5611975149327" },
  },
  {
    name: "Customer Query, Dispute & Complaints Policy",
    supportOptions: { articleId: "115010094568" },
  },
  {
    name: "Promotion Terms",
    supportOptions: { articleId: "360000370235" },
  },
];

export const AboutLinks: Link[] = [
  {
    name: "FAQ",
    supportOptions: { labels: ["faq button"] },
  },
  {
    name: "Responsible Gambling Policy",
    supportOptions: { articleId: "115010094608" },
  },
  {
    name: "Contact Us",
    action: () => dispatchNow(openChat()),
  },
  {
    name: "About Us",
    to: "/account/about/about-us",
  },
];
