import React, { useEffect, useState } from "react";
import { useFirestoreOutcomeStatusAndOdds, useSelector } from "hooks";
import type { SourceType } from "hooks/firestore/betting/types";
import type { MarketStatus, SubOutcomesType } from "types/BetTypes";
import { selectCanGetOddsUpdates } from "sections/Betting/Betslip/betslipSlice";
import cx from "classnames";
import * as styles from "./SubOutcome.module.scss";
import { Icon } from "library";
import StatusAndCountdown from "sections/Betting/Event/StatusAndCountdown";

export type SubOutcomeProps = {
  subOutcome: SubOutcomesType;
  eventId: string;
  eventStatusChanged?: (status: MarketStatus) => void;
  marketStatusChanged?: (status: MarketStatus, outcomeId: string) => void;
  oddsChanged?: (odds: number) => void;
  sourceType: SourceType;
  showOutcomes?: boolean;
  sgm?: boolean;
  removeSelection?: (subOutcomeId: string) => void;
  canRemoveSelection?: boolean;
  eventStatus?: MarketStatus;
};

export const SubOutcome = ({
  subOutcome,
  eventStatusChanged,
  marketStatusChanged,
  oddsChanged,
  eventId,
  sourceType,
  showOutcomes = true,
  sgm = false,
  removeSelection,
  canRemoveSelection,
  eventStatus,
}: SubOutcomeProps) => {
  const [odds, setOdds] = useState<number>();

  const [liveOutcomeData, loading] = useFirestoreOutcomeStatusAndOdds(
    subOutcome.outcomeId,
    eventId,
    subOutcome.marketId,
    sourceType,
  );

  const canGetOddsUpdates = useSelector(selectCanGetOddsUpdates);

  useEffect(() => {
    if (!canGetOddsUpdates) return;
    if (liveOutcomeData && !loading) {
      if (subOutcome.marketStatus !== liveOutcomeData?.marketStatus) {
        marketStatusChanged?.(
          liveOutcomeData?.marketStatus as MarketStatus,
          subOutcome.outcomeId,
        );
      }

      if (eventStatus !== liveOutcomeData?.eventStatus) {
        eventStatusChanged?.(liveOutcomeData?.eventStatus as MarketStatus);
      }

      if (odds !== liveOutcomeData?.odds) {
        setOdds(liveOutcomeData?.odds);
        oddsChanged(liveOutcomeData?.odds);
      }
    }
  }, [
    liveOutcomeData.marketStatus,
    liveOutcomeData.eventStatus,
    subOutcome.marketStatus,
    eventStatus,
    odds,
    liveOutcomeData.odds,
  ]);

  if (!showOutcomes) return null;

  return (
    <div
      className={cx(styles.subOutcome, {
        [styles.sgm]: sgm,
        [styles.inactive]: subOutcome.marketStatus !== "ACTIVE",
      })}
      onClick={
        canRemoveSelection
          ? () => removeSelection(subOutcome.outcomeId)
          : undefined
      }
    >
      <div className={styles.spaceLeft}>
        {subOutcome.marketStatus !== "ACTIVE" && (
          <StatusAndCountdown
            status={"SUSPENDED"}
            scheduledStartTime={undefined}
          />
        )}
      </div>
      <div className={styles.marketName}>{subOutcome.marketName}</div>
      <div className={styles.name}>{subOutcome.name}</div>
      {canRemoveSelection && (
        <div className={styles.removeSelection}>
          <Icon type="close" />
        </div>
      )}
    </div>
  );
};
