// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var cardImage = "PickemsContestSummary-module--cardImage--7d747";
export var cardPicks = "PickemsContestSummary-module--cardPicks--e388b";
export var cardText = "PickemsContestSummary-module--cardText--4c1eb";
export var contestCard = "PickemsContestSummary-module--contestCard--8fcfb";
export var emphasis = "PickemsContestSummary-module--emphasis--fc868";
export var green = "#57d33b";
export var greenLink = "PickemsContestSummary-module--green-link--ea472";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";