import React from "react";
import Link from "../Link";
import { useIsMobile, usePermission } from "hooks";
import { useIsClient } from "usehooks-ts";

import * as styles from "./ResponsibleGambling.module.scss";
import {
  RESPONSIBLE_GAMBLING_MESSAGE,
  useContentfulSetting,
} from "hooks/graphql/useContentfulSettings";

const ResponsibleGambling = () => {
  const isMobile = useIsMobile();
  const isClient = useIsClient();
  const viewRSGMessages = usePermission("viewRSGMessages");
  const responsibleGamblingMessage = useContentfulSetting(
    RESPONSIBLE_GAMBLING_MESSAGE,
  );

  if (!isClient) {
    return null;
  }

  if (!viewRSGMessages || viewRSGMessages !== "GRANTED") {
    return null;
  }

  return (
    <div className={styles.responsibleGambling}>
      <h1>
        {responsibleGamblingMessage?.replace(
          isMobile ? "Set a deposit limit?" : "",
          "",
        )}
      </h1>
      {isMobile ? (
        <Link to="/preferences/responsible-gambling/">Set a deposit limit</Link>
      ) : (
        <>
          <div>
            For free and confidential support call 1800 858 858 or visit{" "}
            <Link to="https://gamblinghelponline.org.au/">
              gamblinghelponline.org.au
            </Link>
          </div>
        </>
      )}
      <div>
        Betstop - The national self-exclusion register. <br />
        Visit <Link to="https://betstop.gov.au">betstop.gov.au</Link>
      </div>
    </div>
  );
};

export default ResponsibleGambling;
