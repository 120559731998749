import React, { Component } from "react";

import Icon from "../Icon";
import { RemoveButton } from "../RemoveButton";

import * as styles from "./Card.module.scss";

/**
 * @name Card
 * @description Renders a rounded card containing credit card or bank account details.
 *              Clicking the red X button will remove the card.
 * @prop {Object} [creditCard]
 * @prop {Object} [bankAccount]
 * @prop {function} onRemoveClick - Handles clicking the red X button
 */
class Card extends Component {
  render() {
    const { bankAccount, creditCard, onRemoveClick } = this.props;

    let component;
    let icon;
    if (creditCard) {
      switch (creditCard.type) {
        case "Visa": {
          icon = <Icon className={styles.icon} type="visa" width={36} />;
          break;
        }
        case "Mastercard": {
          icon = <Icon className={styles.icon} type="mastercard" width={36} />;
          break;
        }
        default:
          break;
      }
      component = (
        <div className={styles.card}>
          {onRemoveClick && (
            <RemoveButton
              className={styles.removeButton}
              onClick={onRemoveClick}
            />
          )}
          {icon}
          <div className={styles.cardNumber}>{creditCard.number}</div>
          <div className={styles.expiry}>Exp. {creditCard.expiry}</div>
          <div className={styles.name}>{creditCard.name}</div>
        </div>
      );
    } else if (bankAccount) {
      icon = <Icon className={styles.icon} type="bank" width={24} />;
      component = (
        <div className={styles.card}>
          {onRemoveClick && (
            <RemoveButton
              className={styles.removeButton}
              onClick={onRemoveClick}
            />
          )}
          {icon}
          <div className={styles.bsb}>{bankAccount.bsb}</div>
          <div className={styles.accountNumber}>{bankAccount.number}</div>
          <div className={styles.name}>{bankAccount.name}</div>
        </div>
      );
    }

    return <div>{component}</div>;
  }
}

export default Card;
