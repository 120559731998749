// extracted by mini-css-extract-plugin
export var alert = "WinPlace-module--alert--94948";
export var alignCenterCenter = "WinPlace-module--alignCenterCenter--2b1e9";
export var backToTop4Button = "WinPlace-module--backToTop4Button--3d60e";
export var betOutcomesContainer = "WinPlace-module--betOutcomesContainer--4f9f7";
export var blue = "#0eb3f2";
export var clickable = "WinPlace-module--clickable--f2591";
export var content = "WinPlace-module--content--2c408";
export var desc = "WinPlace-module--desc--02c34";
export var emphasis = "WinPlace-module--emphasis--40ceb";
export var flucsContainer = "WinPlace-module--flucsContainer--a2d09";
export var green = "#57d33b";
export var greenLink = "WinPlace-module--green-link--1f196";
export var grey = "var(--tone-mid)";
export var hairline = "WinPlace-module--hairline--fe7e8";
export var heading = "WinPlace-module--heading--544f3";
export var icon = "WinPlace-module--icon--a2cfe";
export var inactive = "WinPlace-module--inactive--b01ea";
export var message = "WinPlace-module--message--39917";
export var multi = "WinPlace-module--multi--dd940";
export var noSilk = "WinPlace-module--noSilk--2a708";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pillInfo = "WinPlace-module--pillInfo--72762";
export var red = "#ff2741";
export var relative = "WinPlace-module--relative--df6f1";
export var row = "WinPlace-module--row--2007f";
export var scratchedRow = "WinPlace-module--scratchedRow--24f94";
export var scratching = "WinPlace-module--scratching--93edd";
export var shortFormArrow = "WinPlace-module--shortFormArrow--ae5d4";
export var shortFormArrowIcon = "WinPlace-module--shortFormArrowIcon--f0b8f";
export var title = "WinPlace-module--title--202b9";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";