// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var competitor = "SameRaceMulti-module--competitor--3adbe";
export var content = "SameRaceMulti-module--content--d3d96";
export var emphasis = "SameRaceMulti-module--emphasis--4e80a";
export var green = "#57d33b";
export var greenLink = "SameRaceMulti-module--green-link--97330";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";