// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var competitiorSemSlip = "Exotics-module--competitiorSemSlip--f8890";
export var competitorOdds = "Exotics-module--competitorOdds--cc678";
export var competitorOutcomes = "Exotics-module--competitorOutcomes--d368f";
export var emphasis = "Exotics-module--emphasis--565df";
export var exoticControls = "Exotics-module--exoticControls--f7baf";
export var green = "#57d33b";
export var greenLink = "Exotics-module--green-link--6e9fd";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var positionControl = "Exotics-module--positionControl--969cf";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";