// extracted by mini-css-extract-plugin
export var badge = "List-module--badge--687ba";
export var blue = "#0eb3f2";
export var danger = "List-module--danger--d9e26";
export var disabled = "List-module--disabled--3f79f";
export var emphasis = "List-module--emphasis--1b8ea";
export var green = "#57d33b";
export var greenLink = "List-module--green-link--8fcda";
export var grey = "var(--tone-mid)";
export var icon = "List-module--icon--a76e2";
export var info = "List-module--info--71490";
export var item = "List-module--item--b0e4b";
export var label = "List-module--label--d47a7";
export var list = "List-module--list--11b22";
export var neutral = "List-module--neutral--61252";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pointer = "List-module--pointer--e7fa9";
export var red = "#ff2741";
export var success = "List-module--success--06830";
export var value = "List-module--value--fa8be";
export var warning = "List-module--warning--6785c";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";