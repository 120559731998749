// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var embed = "SocialMedia-module--embed--8c146";
export var emphasis = "SocialMedia-module--emphasis--a550d";
export var green = "#57d33b";
export var greenLink = "SocialMedia-module--green-link--f24cb";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
export var youtube = "SocialMedia-module--youtube--1c564";