import fetch from "isomorphic-fetch";
import { toDate } from "date-fns-tz";
import appConfig from "appConfig";
import { convertAmountToCents } from "common";

const API_URL = process.env.GATSBY_PAYMENTIQ_API_URL;
const MERCHANT_ID = process.env.GATSBY_PAYMENTIQ_MERCHANT_ID;

export type DepositMethod = {
  type: string;
  accounts: [];
  isMaintenance: boolean;
  providerType: string;
  service: string;
};

export type DepositResponse = {
  transactionId: string;
  needsRedirect: boolean;
  redirect: {
    url: string;
    method: "POST" | "GET";
    parameters: any;
  };
};

export type WithdrawResponse = {
  transactionId: string;
  status: string;
  success: boolean;
  errorMessage?: string;
};

export type Transaction = {
  id: string;
  createdAt: Date;
  amount: number;
  currency: string;
  status: string;
};

export const getAvailableDepositMethods = async (
  userId: string,
  sessionId: string,
): Promise<Array<DepositMethod>> => {
  try {
    const response = await fetch(
      `${API_URL}/user/payment/method/${MERCHANT_ID}/${userId}?sessionId=${sessionId}&method=deposit`,
    );

    const json = await response.json();

    if (!json.success) {
      return [];
    }

    return json.methods.map((method) => {
      return {
        type: (method.txType || "").toLowerCase(),
        accounts: method.accounts,
        isMaintenance: !!method.maintenanceInfo?.maintenance,
        providerType: method.providerType.toLowerCase(),
        service: method.service.toLowerCase(),
      };
    });
  } catch (error) {
    console.warn("Could not retrieve payment methods", error);
  }
};

export const processWithdraw = async (
  method,
  userId,
  sessionId,
  amount,
  extraParams = {},
): Promise<WithdrawResponse> => {
  try {
    const response = await fetch(`${API_URL}/${method}/withdrawal/process`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        merchantId: MERCHANT_ID,
        userId,
        sessionId,
        amount,
        ...extraParams,
      }),
    });

    const json = await response.json();

    return {
      transactionId: json.txId,
      status: json.statusCode,
      success: json.success,
      errorMessage: json.errors?.[0]?.msg,
    };
  } catch (error) {
    console.log("Interac withdraw failed");
  }
};

export const processTransaction = async (url: string) => {
  try {
    const responseHTML = await fetch(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
    });

    return responseHTML;
  } catch (error) {
    console.log("Interac withdraw failed");
  }
};

export const processDeposit = async (
  method,
  userId,
  sessionId,
  amount,
  bonusCode,
  currency,
  extraParams = {},
): Promise<DepositResponse> => {
  try {
    const response = await fetch(`${API_URL}/${method}/deposit/process`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        merchantId: MERCHANT_ID,
        userId,
        sessionId,
        amount,
        attributes: {
          successUrl: `${appConfig.DOMAIN}/payment-iq?success`,
          failureUrl: `${appConfig.DOMAIN}/payment-iq?failure`,
          pendingUrl: `${appConfig.DOMAIN}/payment-iq?pending`,
          cancelUrl: `${appConfig.DOMAIN}/payment-iq?cancel`,
        },
        ...extraParams,
      }),
    });

    const json = await response.json();

    return {
      transactionId: json?.txId,
      needsRedirect: json?.txState === "WAITING_INPUT",
      redirect: {
        url: json?.redirectOutput?.url,
        method: json?.redirectOutput?.method,
        parameters: json?.redirectOutput?.parameters,
      },
    };
  } catch (error) {
    console.warn("Could not process deposit", error);
    return null;
  }
};

//Mapping from piq states to out statuses. Completed states not included
//See https://docs.paymentiq.io/europe/advancedguides/advanced-guides/states-and-status
const transactionStateMapping = {
  WAITING_APPROVAL: "PENDING",
  PROCESSING: "PROCESSING",
  WAITING_INPUT: "PROCESSING",
};

export const getTransactions = async (
  userId: string,
  sessionId: string,
  method: "deposit" | "withdrawal" = "deposit",
): Promise<Transaction[]> => {
  try {
    const response = await fetch(
      `${API_URL}/user/transaction/${MERCHANT_ID}/${userId}?sessionId=${sessionId}&method=${method}`,
    );

    const json = await response.json();

    if (json.success) {
      return json.transactions
        .filter((transaction) => transactionStateMapping[transaction.state])
        .map((transaction) => {
          const amountAndCurrency = transaction.amount.split(" ");
          return {
            id: transaction.transactionId,
            createdAt: toDate(transaction.created, {
              timeZone: "UTC",
            }),
            amount: convertAmountToCents(
              Math.abs(parseFloat(amountAndCurrency[0])),
            ),
            currency: amountAndCurrency[1],
            status: transactionStateMapping[transaction.state],
          };
        });
    }

    return [];
  } catch (error) {
    console.warn("Could not retrieve transactions", error);
  }
};

export const cancelTransaction = async (
  userId: string,
  sessionId: string,
  transactionId: string,
): Promise<boolean> => {
  try {
    const response = await fetch(
      `${API_URL}/user/transaction/${MERCHANT_ID}/${userId}/${transactionId}?sessionId=${sessionId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "DELETE",
      },
    );

    const json = await response.json();

    return json.success;
  } catch (error) {
    console.warn("Could not cancel transaction", error);
  }
};

export const getTransactionStatus = async (
  transactionId: string,
  userId: string,
  sessionId: string,
): Promise<
  "SUCCESSFUL" | "FAILED" | "CANCELLED" | "NOT_FOUND" | "WAITING_INPUT"
> => {
  try {
    const response = await fetch(
      `${API_URL}/user/transaction/${MERCHANT_ID}/${userId}/${transactionId}/status?sessionId=${sessionId}`,
    );

    const json = await response.json();
    return json.txState || "NOT_FOUND";
  } catch (error) {
    console.warn("Could not get transaction details", error);
  }
};
