import config from "appConfig";
import { useState } from "react";
import useLocale from "hooks/useLocale";
import { useMessage } from "hooks/useMessage";
import { useDispatch } from "hooks";
import { sharedEntryTracking } from "sections/Betting/Betslip/betslipSlice";

type EntryShareMessagePayload = {
  name: string;
  hideStake: boolean;
  referenceId: string;
};

type MessageApiResponse = {
  shareId: string;
};

export function useShareUrl(referenceId: string) {
  const [requestShareUrl] =
    useMessage<MessageApiResponse>("create-entry-share");

  const locale = useLocale();

  const [shareUrl, setShareUrl] = useState("");
  const dispatch = useDispatch();

  const getShareUrl = async ({ name, hideStake }: EntryShareMessagePayload) => {
    const payload = {
      referenceId,
      name,
      hideStake,
    };
    const response = await requestShareUrl(payload);
    const url = `${config.DOMAIN}/${locale}/share/${response.shareId}`;

    dispatch(sharedEntryTracking({ url, date: new Date(), ...payload }));
    setShareUrl(url);

    return url;
  };

  return [shareUrl, getShareUrl, setShareUrl] as const;
}
