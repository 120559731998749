import { useMemo } from "react";
import { useBetslip, useDisplayAtLeast } from "hooks";
import { use100vh } from "react-div-100vh";
import { useGetActiveFooterType } from "./useGetActiveFooterType";
import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";

const HEADER_HEIGHT = 80;
const TABS_HEIGHT = 60;
const DESKTOP_HEADER_HEIGHT = HEADER_HEIGHT + TABS_HEIGHT;
const SMALL_FOOTER_HEIGHT = 75;
const LARGE_FOOTER_HEIGHT = 125;

const useBetslipHeight = () => {
  const {
    props: { betslipIsReviewingMessages },
  } = useBetslip();
  const fullHeight = use100vh();
  const isMinDesktop = useDisplayAtLeast("desktop");
  const { betsFooter, betslipFooter } = useGetActiveFooterType();
  const playerOffset = useCalculatePlayerOffset();

  return useMemo(() => {
    // - when we are in desktop mode, betslip should be 100% minus header + tabs
    // - when we are in mobile mode, we are not showing header, so we need to account for that
    // - when media player is opened account for that
    const baseHeight =
      fullHeight -
      (isMinDesktop ? DESKTOP_HEADER_HEIGHT : TABS_HEIGHT) -
      (playerOffset || 0);

    // when we are on bets & entries tab OR reviewing messages, we display small footer
    // we need to reduce betslip height by additional number of pixels
    if (betsFooter || betslipIsReviewingMessages) {
      return baseHeight - SMALL_FOOTER_HEIGHT;
    }

    // when we are showing full sized footer, we need to reduce betslip size as well
    if (betslipFooter) {
      // betslip footer is larger than bets footer
      return baseHeight - LARGE_FOOTER_HEIGHT;
    }

    return baseHeight;
  }, [
    fullHeight,
    betsFooter,
    betslipFooter,
    isMinDesktop,
    betslipIsReviewingMessages,
    playerOffset,
  ]);
};

export { useBetslipHeight };
