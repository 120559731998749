import * as React from "react";
import { type VariantProps, cva } from "class-variance-authority";

import * as styles from "./Pacman.module.scss";

type PacmanProps = VariantProps<typeof pacmanVariants>;

const pacmanVariants = cva(styles.pacman, {
  variants: {
    variant: {
      light: styles.light,
      dark: styles.dark,
    },
    size: {
      default: styles.sizeDefault,
      sm: styles.sizeSm,
    },
  },
  defaultVariants: {
    variant: "light",
    size: "default",
  },
});

const Pacman = ({ variant, size }: PacmanProps) => {
  return (
    <div
      data-testid={`pacman`}
      data-variant={variant || "light"}
      data-size={size || "default"}
      className={pacmanVariants({ variant, size })}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Pacman.displayName = "Pacman";

export { Pacman };
