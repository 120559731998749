import React from "react";
import cx from "classnames";
import { LoaderIcon as Logo } from "library";
import { ReactComponent as LogoText } from "assets/logo-text.svg";

import * as styles from "./NavLogo.module.scss";

export const NavLogo = ({
  loading = false,
  className = "",
  includeTextOnMobile = false,
}) => {
  return (
    <div
      className={cx(
        {
          [styles.logo]: true,
        },
        className,
      )}
    >
      <div className={styles.spinner}>
        <Logo loading={loading} />
      </div>
      <div
        className={cx(styles.text, {
          [styles.showText]: includeTextOnMobile,
        })}
      >
        <LogoText />
      </div>
    </div>
  );
};
