import { useContext, useEffect } from "react";
import { FirebaseContext } from "context/Firebase";

import type { UserVerificationStatus } from "types/UserTypes";
import { usePreviousValue } from "hooks/usePreviousValue";

// unknown is used for cases where verification was already passed or failed
export type VerificationOutcome = "passed" | "failed" | "unknown";

const useOnTransitionedVerificationStatus = (
  onTransition: (outcome: VerificationOutcome) => void,
) => {
  const { profile } = useContext(FirebaseContext);
  const verificationStatus = profile?.verificationStatus;

  const previousVerificationStatus =
    usePreviousValue<UserVerificationStatus>(verificationStatus);

  useEffect(() => {
    // we went from UNVERIFIED to VERIFIED or VERIFIED_ADMIN
    // means we passed verification

    const isUnverified =
      previousVerificationStatus === "UNVERIFIED" ||
      typeof previousVerificationStatus === "undefined";

    const isVerified =
      typeof verificationStatus !== "undefined" &&
      verificationStatus !== "UNVERIFIED";

    if (
      isUnverified &&
      (verificationStatus === "VERIFIED" ||
        verificationStatus === "VERIFIED_ADMIN")
    ) {
      onTransition("passed");
    } else if (isUnverified && verificationStatus !== "UNVERIFIED") {
      // we went from UNVERIFIED to something else
      // means we failed verification
      onTransition("failed");
    } else if (isVerified) {
      // we are already attempted verification, nothing to do here
      onTransition("unknown");
    }
  }, [verificationStatus]);
};

export { useOnTransitionedVerificationStatus };
