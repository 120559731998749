import React from "react";
import { navigateBack } from "library/components/Link";
import { Icon } from "library";
import cx from "classnames";
import { useLocation } from "@gatsbyjs/reach-router";
import { AnimatePresence, motion } from "framer-motion";

import * as styles from "./BackButton.module.scss";

const optInPagesWithBackButton = [
  "coverage/",
  "match/",
  "outright/",
  "guides",
  "contest/",
  /wallet\/deposit\/[a-zA-Z0-9-_]+/,
  /racing\/betting\/race\/+/,
  /racing\/betting\/meeting\/+/,
  /account\/verification\/[a-zA-Z0-9-_]+/,
  /account\/about\/[a-zA-Z0-9-_]+/,
  /activity-statement\/+/,
];

const BackButtonContent = ({ className }: { className: string }) => {
  return (
    <button
      onClick={() => {
        navigateBack();
      }}
      className={className}
    >
      <Icon type="arrow-left" inline />
    </button>
  );
};

/**
 * Display a chevron for users to navigate back, optionally animate it in
 * @param className override for custom styles
 * @param isAllowedOnPath override the allowed routes to show the button by passing in a boolean
 * @param animatePresence when set to null (default), it will not animate in/out
 * @constructor
 */
const BackButton = ({
  className = "",
  isAllowedOnPath = false,
  animatePresence = null,
}: {
  className?: string;
  isAllowedOnPath?: boolean;
  animatePresence?: boolean | null;
}) => {
  const { key, pathname } = useLocation();

  const optInAllowedBackbuttonPaths = optInPagesWithBackButton.some((path) =>
    pathname.match(path),
  );
  const allowedOnPath =
    (optInAllowedBackbuttonPaths || isAllowedOnPath) && key !== "initial";

  // we won't be animating it in or out, check it's allowed on the path and show it
  if (animatePresence === null) {
    return allowedOnPath ? (
      <BackButtonContent
        className={cx({
          [styles.chevronLeft]: true,
          [styles.visible]: allowedOnPath,
          [className]: !!className,
        })}
      />
    ) : null;
  }
  const initialStyle = {
    opacity: 0,
    maxWidth: 0,
    minWidth: 0,
    height: "2.3rem",
    transform: "translate3d(2.6rem, 0, 0)",
  };

  return (
    <AnimatePresence>
      {allowedOnPath && animatePresence && (
        <motion.div
          initial={initialStyle}
          animate={{
            ...initialStyle,
            opacity: 1,
            maxWidth: "100%",
            transform: "translate3d(0, 0, 0)",
          }}
          exit={initialStyle}
        >
          <BackButtonContent
            className={cx({
              [styles.chevronLeft]: true,
              [styles.visible]: allowedOnPath,
              [className]: !!className,
            })}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BackButton;
