import {
  type AnyAction,
  createSlice,
  type PayloadAction,
  type ThunkDispatch,
} from "@reduxjs/toolkit";
import { clearField, clearForm, raiseIssue } from "store/validation";
import { convertErrorToToast } from "utilities/errorsParser";
import { onLogout } from "utilities/User/Actions";
import { isValid, parse } from "date-fns";
import type { VerificationSubmitValues } from "types/VerificationTypes";
import { logout, updateSession } from "utilities/Auth/authSlice";
import { toast } from "hooks/ui/useToast";
import { message } from "utilities/message";
import type { RootState } from "store/createStore";

// actions types for segment tracking
export const UPLOAD_AVATAR_ATTEMPT = "UPLOAD_AVATAR_ATTEMPT";
export const UPDATE_PASSWORD_ATTEMPT = "UPDATE_PASSWORD_ATTEMPT";
export const CHANGE_NOTIFICATION_SETTINGS = "CHANGE_NOTIFICATION_SETTINGS";
export const SUCCESS_RESENT = "SUCCESS_RESENT";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const SUBMIT_VERIFICATION_ATTEMPT = "SUBMIT_VERIFICATION_ATTEMPT";

const initialState = {
  details: {
    uploading: false,
    updatePasswordError: null,
    updatePasswordSubmitting: false,
  },
  promotions: {
    code: "",
    claimCode: "",
    copied: false,
  },
  verification: {
    identityDocumentData: {
      firstName: "",
      middleName: "",
      lastName: "",
      id: "",
      state: "",
      dateOfBirth: "",
      expiryDate: "",
      consent: false,
      number: "",
    },
    identityDocumentType: "",
    identityDocumentError: null,
    identityDocumentSubmitted: false,
    errorHighlights: {},
    resent: false,
  },
  limits: {
    depositLimitAmount: null,
    depositLimitAmountLoaded: false,
    depositLimitPeriod: null,
    depositLimitCurrency: null,
  },
};

const accountSlice = createSlice({
  name: "Account",
  initialState,
  reducers: {
    setUploading(state, action) {
      state.details.uploading = action.payload;
    },

    change(state, action) {
      state.promotions.code = action.payload;
    },

    changeClaimCode(state, action) {
      state.promotions.claimCode = action.payload;
    },

    setCopied(state, action) {
      state.promotions.copied = action.payload;
    },

    // TODO: check if this action is being used at all
    verificationError(state, action) {
      state.verification.identityDocumentError = action.payload;
    },

    // TODO: check if this action is being used
    clearIdentityDocumentErrors(state) {
      state.verification.identityDocumentError = null;
    },

    identityDocumentSubmitted(state, action: PayloadAction<string>) {
      state.verification.identityDocumentSubmitted = true;
      state.verification.identityDocumentType = action.payload;
    },

    onClearState(state) {
      state.verification = initialState.verification;
    },

    setResent(state, action) {
      state.verification.resent = action.payload;
    },

    requestLoadMore(state: any) {
      state.notifications.page += 1;
    },

    setUpdatePasswordSubmitting(state, action) {
      state.details.updatePasswordSubmitting = action.payload;
    },

    setDepositLimitAmount: {
      reducer(state, action) {
        state.limits.depositLimitAmount = action.payload.amount;
        state.limits.depositLimitPeriod = action.payload.limitPeriod;
        state.limits.depositLimitCurrency = action.payload.currency;
        state.limits.depositLimitAmountLoaded = true;
      },
      prepare(amount, limitPeriod, currency) {
        return {
          payload: { amount, limitPeriod, currency },
        } as any;
      },
    },
  },
});

// Thunks
export const submitPassword =
  (passwords: { password: string; newPassword: string }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(clearForm("changePassword"));

    const [response, error] = await message("update-password", passwords);

    if (error) {
      dispatch(trackEvent(UPDATE_PASSWORD_ATTEMPT, { outcome: "failure" }));
      dispatch(setUpdatePasswordSubmitting(false));

      return false;
    }

    dispatch(setUpdatePasswordSubmitting(true));
    dispatch(trackEvent(UPDATE_PASSWORD_ATTEMPT, { outcome: "success" }));
    toast({
      title: "Password updated!",
      variant: "success",
      description: "Password updated successfully.",
    });

    await dispatch(
      onLogout("/account", () => {
        dispatch(setUpdatePasswordSubmitting(false));
        // reset window position
        window.scrollTo(0, 0);
      }),
    );

    return true;
  };

export const uploadAvatar =
  (file: File) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [
      {
        data: { url, params },
      },
    ] = await message("generate-avatar-upload-url");

    const formData = new FormData();
    for (const param in params) {
      formData.append(param, params[param]);
    }
    formData.append("file", file); // Add file

    try {
      const uploadResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: formData,
      });
      const body = await uploadResponse.json();

      if (body.error) {
        throw new Error(body.error.message);
      }

      const [_, error] = await message("confirm-avatar-upload", body);

      if (error) {
        throw error;
      }

      dispatch(trackEvent(UPLOAD_AVATAR_ATTEMPT, { outcome: "success" }));
      // Give a little bit of time for the browser to download the image.
      setTimeout(() => {
        dispatch(setUploading(false));
      }, 1000);
    } catch (error) {
      dispatch(trackEvent(UPLOAD_AVATAR_ATTEMPT, { outcome: "failure" }));
      toast(
        convertErrorToToast(error, {
          title: "Unable to upload Avatar!",
        }),
      );
    }
  };

export const removeAvatar = () => async () => {
  return await message("confirm-avatar-delete");
};

export const claimPromo = (code: string) => async () => {
  const [response, error] = await message("claim-promotion-code", { code });

  if (error) {
    toast(
      convertErrorToToast(error, {
        title: "Unable to claim the code!",
      }),
    );

    return;
  }

  toast({
    title: "Success!",
    variant: "success",
    description: `Code ${code} was claimed successfully`,
  });
};

export const validate = (field) => async (dispatch, getState) => {
  const form = getState().sections.account.verification.identityDocumentData;
  const formName = "license";
  const data = form[field];

  dispatch(clearField(formName, field));

  // Check Not Empty
  if (["firstName", "lastName", "number", "state"].indexOf(field) > -1) {
    if (!data || data.trim() === "") {
      dispatch(raiseIssue(formName, field, "value must not be empty"));
    }
  }

  // Check Is Complete Date
  if (["dateOfBirth", "expiryDate"].indexOf(field) > -1) {
    if (!isValidDate(data)) {
      dispatch(
        raiseIssue(formName, field, "value must be a complete & valid date"),
      );
    }
  }

  // Consent Must Exist
  if (["consent"].indexOf(field) > -1) {
    if (!data) {
      dispatch(
        raiseIssue(formName, field, "consent must be given to continue"),
      );
    }
  }
};

export const doSubmitVerification =
  (payload: VerificationSubmitValues) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [_, error] = await message(
      "submit-verification-data-update",
      payload,
    );

    if (error) {
      dispatch(
        trackEvent(SUBMIT_VERIFICATION_ATTEMPT, {
          outcome: "failure",
          type: payload.type,
        }),
      );
      dispatch(
        verificationError(error?.errors.length ? error.errors[0] : error),
      );
      toast({
        title: "Unable to submit verification data",
        variant: "danger",
        description:
          "Something went wrong. Please contact support if the error persists",
      });

      return false;
    }

    dispatch(
      trackEvent(SUBMIT_VERIFICATION_ATTEMPT, {
        outcome: "success",
        type: payload.type,
      }),
    );

    return true;
  };

export const resendEmailVerification =
  () => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [_, error] = await message("resend-email-verification", {});

    if (error) {
      toast(
        convertErrorToToast(error, {
          title: "Unable to re-send verification email!",
          description:
            "Something went wrong. Please contact support if error persists",
        }),
      );
      return;
    }

    dispatch(trackEvent(SUCCESS_RESENT));
    dispatch(setResent(true));
    // reset button to initial state in 3 seconds
    setTimeout(() => {
      dispatch(setResent(false));
    }, 3000);
  };

export const loadMore =
  () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(requestLoadMore(null));
  };

export const setNotificationSettings =
  (updatedPreference: any) =>
  // TODO: Properly add preference types
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    await message("update-notification-preference", updatedPreference);

    dispatch(trackEvent(CHANGE_NOTIFICATION_SETTINGS));
  };

export const setEmailUnsubscribe =
  (currentValue: boolean) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    await message("update-email-subscription", {});

    dispatch(trackEvent(UNSUBSCRIBE, !currentValue));
  };

export const cooldown = (days: number) => async () => {
  const [response, error] = await message("user-cooldown", { days });

  if (error) {
    toast(convertErrorToToast(error, { title: "Unable to set Cooldown!" }));
    return;
  }

  toast({
    description: response.displayText,
    title: "Success!",
    variant: "success",
  });
};

export const closeAccount =
  () => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const [response, error] = await message("close-account", {});

    if (error) {
      toast(convertErrorToToast(error, { title: "Unable to close account!" }));
      return;
    }

    toast({
      description: response.displayText,
      title: "Success!",
      variant: "success",
    });

    await dispatch(
      logout(() => {
        // reset scroll position
        window.scrollTo(0, 0);
      }),
    );
  };

export const saveDepositLimit =
  (amount: number, limitPeriod: number) => async () => {
    const [response, error] = await message("update-deposit-limits", {
      maximumDailyDepositAmount: Number(amount),
      dailyLimitDays: Number(limitPeriod),
    });

    if (error) {
      toast(convertErrorToToast(error, { title: "Unable to update limits!" }));

      return;
    }

    toast({
      description: response.displayText,
      title: "Success!",
      variant: "success",
    });
  };

export const trackEvent =
  (type: string, payload = {}) =>
  (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch({ type, payload });
  };

export const onDismissAllNotifications = () => async () => {
  const [_, error] = await message("dismiss-all-notifications");

  if (error) {
    console.log("Could not dismiss all notifications", error);
    return;
  }
};

export const onDismissNotification = (id: string) => async () => {
  const [_, error] = await message("dismiss-notification", { id });

  if (error) {
    console.error(`Could not dismiss a notification`, error);
    return;
  }
};

export const onUpdateBettingPreferences =
  ({
    acceptOdds = null,
    oddsFormat = null,
    defaultHub = null,
    allowCancelWithdrawal = null,
  }) =>
  async () => {
    const [response, error] = await message("update-user-settings", {
      acceptOdds,
      oddsFormat,
      defaultHub,
      allowCancelWithdrawal,
    });

    if (error) {
      toast({
        description: "Unable to update settings",
        title: "Error!",
        variant: "danger",
      });
      return;
    }

    toast({
      description: response.displayText,
      title: "Success!",
      variant: "success",
    });
  };

export const doReopenAccount =
  ({ responsibleGamblingMessageAccepted, depositLimit, depositLimitPeriod }) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    const payload = depositLimit
      ? {
          maximumDailyDepositAmount: depositLimit,
          dailyLimitDays: depositLimitPeriod,
          responsibleGamblingMessageAccepted,
        }
      : { responsibleGamblingMessageAccepted };

    const [_, error] = await message("reopen-account", payload);

    if (error) {
      toast({
        description: "Unable to reopen your account",
        variant: "danger",
      });

      return false;
    }

    dispatch(updateSession({ isGuest: false }));
    toast({
      description: "Your account has been reopened",
    });

    return true;
  };

// Helpers
const isValidDate = (data) => {
  return isValid(parse(data, "dd/MM/yyyy", 0));
};

export const {
  setUploading,
  change,
  changeClaimCode,
  setCopied,
  verificationError,
  clearIdentityDocumentErrors,
  identityDocumentSubmitted,
  onClearState,
  setResent,
  requestLoadMore,
  setDepositLimitAmount,
  setUpdatePasswordSubmitting,
} = accountSlice.actions;

export default accountSlice.reducer;
