import { useBetslip, useEntries } from "hooks";
import { useStaticEntries } from "hooks/firestore/betting/useEntries";
import { useEffect } from "react";
import { BetType } from "sections/Betting/Betslip/betslipSlice";

const useGetActiveFooterType = (): {
  betslipFooter: boolean;
  betsFooter: boolean;
} => {
  const {
    props: { betType, unfilteredSelectionsCount },
  } = useBetslip();

  const { entries: settledEntries } = useStaticEntries(10);
  const [pendingEntries] = useEntries(["live", "upcoming"], 1, 10, "bet");

  const showBetslipFooter =
    unfilteredSelectionsCount > 0 &&
    [BetType.Single, BetType.Multi].includes(betType);

  return {
    betslipFooter: showBetslipFooter,
    betsFooter:
      (betType === BetType.Pending && pendingEntries?.length > 0) ||
      (betType === BetType.Settled && settledEntries?.length > 0),
  };
};

export { useGetActiveFooterType };
