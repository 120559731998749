import {
  doc,
  type DocumentReference,
  type FirestoreError,
} from "firebase/firestore";
import { useDocumentData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";

export type CommunicationChannel = { unsubscribed: boolean };

export const useEmailUnsubscribed = (): [boolean, boolean, FirestoreError] => {
  const userId = useSelector((state) => state.auth.userId);
  const emailChannelRef = userId
    ? (doc(
        getFirestore(),
        "users",
        userId,
        "communicationChannels",
        "EMAIL",
      ) as DocumentReference<CommunicationChannel>)
    : undefined;
  const [emailChannel, loading, error] = useDocumentData(emailChannelRef);

  return [emailChannel?.unsubscribed, loading, error];
};

export const useSMSUnsubscribed = (): [boolean, boolean, FirestoreError] => {
  const userId = useSelector((state) => state.auth.userId);
  const smsChannelRef = userId
    ? (doc(
        getFirestore(),
        "users",
        userId,
        "communicationChannels",
        "SMS",
      ) as DocumentReference<CommunicationChannel>)
    : undefined;
  const [smsChannel, loading, error] = useDocumentData(smsChannelRef);

  return [smsChannel?.unsubscribed, loading, error];
};
