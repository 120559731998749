import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { getStateAndCountry } from "utilities/location";
import { toTitleCaseWithExclusions } from "utilities/racingUtilities";
import type { RaceEventType } from "sections/Betting/Race/hooks/RacingTypes";

export default ({ races }: { races: Partial<RaceEventType>[] }) => {
  if (!races?.length) return null;
  // SEO - Use the next race for Google's event schema https://developers.google.com/search/docs/appearance/structured-data/event
  const nextRaceEventSchemaObject = useMemo(
    () => ({
      "@context": "https://schema.org",
      "@type": "Event",
      name: races?.[0]?.venue,
      startDate: races?.[0]?.scheduledStartTime,
      location: {
        "@type": "Place",
        name: toTitleCaseWithExclusions(races?.[0]?.name),
        address: getStateAndCountry(races?.[0]?.country, races?.[0]?.state),
      },
      image:
        "https://images.ctfassets.net/3j5geeapvvry/1ZBZV3Nqndq6ZgyJVojwDw/5f7df0e2f89c7f07fdb64d41fc33b0b6/Icon_-_Horse_Racing.svg",
      description: "Horse Racing",
    }),
    [races],
  );

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(nextRaceEventSchemaObject)}
      </script>
    </Helmet>
  );
};
