// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var dateSelect = "DateSelector-module--dateSelect--b6fc5";
export var emphasis = "DateSelector-module--emphasis--ff13b";
export var green = "#57d33b";
export var greenLink = "DateSelector-module--green-link--8a242";
export var grey = "var(--tone-mid)";
export var icon = "DateSelector-module--icon--419c3";
export var isOpen = "DateSelector-module--isOpen--bd3e5";
export var notchGap = "32px";
export var orange = "#f8662b";
export var popover = "DateSelector-module--popover--7093b";
export var red = "#ff2741";
export var trigger = "DateSelector-module--trigger--1ec37";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";