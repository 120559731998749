import React from "react";
import cx from "classnames";
import * as styles from "./Carousel.Item.module.scss";

export type ItemProps = React.HTMLProps<HTMLElement> & {
  className?: string;
  duration?: number;
};

export const Item = React.forwardRef<HTMLImageElement, ItemProps>(
  (props, ref) => {
    const { ...otherProps } = props;

    return (
      <div
        {...otherProps}
        className={cx(styles.item, props.className)}
        ref={ref}
      />
    );
  },
);

Item.displayName = "CarouselItem";
