// extracted by mini-css-extract-plugin
export var blue = "StatusPill-module--blue--7c06c";
export var emphasis = "StatusPill-module--emphasis--e5513";
export var green = "StatusPill-module--green--1f9d4";
export var greenLink = "StatusPill-module--green-link--e0483";
export var grey = "StatusPill-module--grey--7ec7f";
export var notchGap = "32px";
export var orange = "StatusPill-module--orange--d1c62";
export var pill = "StatusPill-module--pill--b0e32";
export var red = "StatusPill-module--red--9f3da";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "StatusPill-module--yellow--959ac";