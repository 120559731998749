import React from "react";
import { ReactComponent as Icon } from "components/assets/icon-promo-inside.svg";
import cx from "classnames";
import * as styles from "./SmallPromoIcon.module.scss";

export const SmallPromoIcon = ({ className }: { className: string }) => (
  <div className={styles.promoIconContainer}>
    <div className={cx(styles.promoIcon, className)}>
      <Icon />
    </div>
  </div>
);
