import { isValid, parseISO } from "date-fns";

export const parseDate = (date: string | Date): Date => {
  if (!date) {
    return null;
  }

  if (typeof date === "string") {
    const hasTimezone = Boolean(date.match(/(Z|[\+\-][\d:]*)$/));
    if (hasTimezone !== true) {
      date += "Z";
    }
    date = parseISO(date as string);
  }
  return isValid(date) ? date : undefined;
};
