import { useState, useMemo, useContext, useEffect } from "react";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import { RaceContext } from "sections/Betting/Race";
import { useSelector } from "hooks";
import { useTimeout } from "usehooks-ts";
import { selectIsMultiOutcomeInBetslip } from "sections/Betting/Betslip/betslipSlice";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { validateSelection } from "sections/Betting/Race/components/SameRaceMulti/utility/validateSelection";

export const footerReplacementErrorMessages = [
  "Selected SRM in your Betslip",
  "Add 2+ Selections",
];

export const defaultSrmSlip = {
  RACING_WINNER: [],
  RACING_TOP_2: [],
  RACING_TOP_3: [],
  RACING_TOP_4: [],
};

const marketsRequired = [
  RaceMarketTypeTypes.Win,
  RaceMarketTypeTypes.Top2,
  RaceMarketTypeTypes.Top3,
  RaceMarketTypeTypes.Top4,
];

export const ERROR_MESSAGE_TIME_DELAY = 5000;

export const useSameRaceMulti = () => {
  const { markets, route } = useContext(RaceContext);

  const [errorMessage, setErrorMessage] = useState<string>("Add 2+ Selections");

  const messageToReplaceFooter =
    footerReplacementErrorMessages.includes(errorMessage);

  const { mappedCompetitors, isSP, validCompetitorCount } =
    useSortedCompetitors({
      marketsRequired,
    });

  useEffect(() => {
    setSelections(defaultSrmSlip);
  }, [route.raceId]);

  useTimeout(
    () => {
      setErrorMessage(null);
    },
    !messageToReplaceFooter && errorMessage ? ERROR_MESSAGE_TIME_DELAY : null,
  );

  const [selections, setSelections] = useState(defaultSrmSlip);

  const flatSelections = useMemo(
    () => (selections ? Object.values(selections)?.flat() : []),
    [selections],
  );

  const selectIsCurrentSelectionInBetslip = useSelector(
    selectIsMultiOutcomeInBetslip,
  );
  const isCurrentSelectionInBetslip =
    selectIsCurrentSelectionInBetslip(flatSelections);

  useEffect(() => {
    setErrorMessage("");
    flatSelections.length === 0 && setErrorMessage("Add 2+ Selections");
    isCurrentSelectionInBetslip &&
      setErrorMessage("Selected SRM in your Betslip");
  }, [flatSelections, isCurrentSelectionInBetslip]);

  const handleSelect = (outcomeId: string) => {
    const market = markets.find((x) => x.outcomes?.[outcomeId]);
    if (flatSelections.includes(outcomeId)) {
      handleRemoveSelection(outcomeId);
    } else {
      const { updatedSelections, errorMessage } = validateSelection({
        market,
        outcomeId,
        selections,
        markets,
        flatSelections,
      });

      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }

      setSelections(updatedSelections);
    }
  };

  const handleRemoveSelection = (outcomeId: string) => {
    const market = markets.find((x) => x.outcomes?.[outcomeId]);
    const newSelections = { ...selections };
    newSelections[market?.marketType] = newSelections?.[
      market?.marketType
    ]?.filter((x: string) => x !== outcomeId);
    setSelections(newSelections);
  };

  const handleRemoveAllSelections = () => setSelections(defaultSrmSlip);

  const errorMessageShouldAllowClear =
    "Selected SRM in your Betslip" === errorMessage;

  return {
    errorMessage,
    selections,
    setSelections,
    mappedCompetitors,
    isSP,
    messageToReplaceFooter,
    flatSelections,
    handleSelect,
    handleRemoveSelection,
    handleRemoveAllSelections,
    validCompetitorCount,
    errorMessageShouldAllowClear,
  };
};
