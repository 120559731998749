import React from "react";
import { motion } from "framer-motion";
import { Typography } from "components/Typography";
import { List, ListItem } from "components/List";
import { VerificationBadge } from "./VerificationBadge";
import {
  useVerifications,
  useVerifyDocCount,
  VerificationPathByType,
} from "hooks";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import { ReactComponent as LockIcon } from "components/assets/lock.svg";
import { Alert } from "components/Alert";
import { Button } from "components/Button";
import { Separator } from "components/Separator";
import { RegionRestricted } from "./RegionRestricted";
import { PendingReview } from "./PendingReview";
import * as support from "utilities/support";
import { VerificationOptionSkeleton } from "./VerificationOptionSkeleton";

type VerificationOptionsProps = {
  prefix?: string;
} & RouteComponentProps;

const VerificationOptions = ({
  prefix = "/account",
}: VerificationOptionsProps) => {
  const formsToSubmit = useVerifyDocCount();
  const [verifications, loading] = useVerifications();

  return (
    <RegionRestricted>
      <PendingReview>
        <Typography variant={`h4`} noSpacing skeleton={{ loading }}>
          Verify Your Identity
        </Typography>
        <Typography variant={`body`} skeleton={{ loading }}>
          By law we are required to verify your identity. Please submit{" "}
          <em>
            {formsToSubmit} {formsToSubmit === 1 ? "form" : "forms"}
          </em>{" "}
          of ID to complete verification.
        </Typography>
        <List loading={loading}>
          {loading ? (
            <VerificationOptionSkeleton count={6} />
          ) : (
            verifications.map((verification) => {
              const isDisabled = verification.status !== "UNVERIFIED";
              const shouldLink = verification.status === "UNVERIFIED";
              const to = VerificationPathByType[verification.type];

              return (
                <ListItem
                  key={verification.type}
                  label={
                    <>
                      {verification.name}
                      {isDisabled && <LockIcon />}
                    </>
                  }
                  disabled={isDisabled}
                  to={shouldLink ? `${prefix}/verification/${to}` : undefined}
                >
                  <VerificationBadge status={verification.status} />
                </ListItem>
              );
            })
          )}
        </List>
        <Separator />
        {!loading && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Alert
              variant={`info`}
              actions={
                <Button
                  onClick={() => support.open({ articleId: "115000820932" })}
                  size={`md`}
                  variant={`info`}
                >
                  Contact Support
                </Button>
              }
            >
              None of the above options available? See the other forms of ID you
              can submit.
            </Alert>
          </motion.div>
        )}
      </PendingReview>
    </RegionRestricted>
  );
};

export { VerificationOptions };
