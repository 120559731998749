import React, { type ReactNode } from "react";
import cx from "classnames";
import { sameRaceMultiLabelMap } from "utilities/racingUtilities";
import type { EventMarket, Outcome } from "hooks/firestore/betting/useBetting";
import type {
  RaceMarketsType,
  RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";
import StatusAndCountdown from "sections/Betting/Event/StatusAndCountdown";
import * as styles from "./Selection.module.scss";

import { ReactComponent as CloseIcon } from "components/assets/close.svg";

export default ({
  outcomeId,
  market,
  outcome,
  handleRemoveSelection,
  renderSelectionName,
}: {
  outcomeId: string;
  market: EventMarket | RaceMarketsType;
  outcome: Outcome | RaceOutcomeType;
  handleRemoveSelection: (outcomeId: string) => void;
  renderSelectionName?: (outcomeId: string) => ReactNode;
}) => (
  <div
    key={outcomeId}
    className={cx(styles.selection, {
      [styles.selectionDisabled]: market?.status !== "ACTIVE",
    })}
  >
    <div className={styles.selectionName}>
      {renderSelectionName?.(outcomeId) ?? outcome?.name}
    </div>
    <div className={styles.outcomeType}>
      {sameRaceMultiLabelMap[outcome?.type]}
    </div>
    <div
      className={styles.closeIcon}
      onClick={() => handleRemoveSelection(outcomeId)}
    >
      <CloseIcon />
    </div>
    {market?.status !== "ACTIVE" && (
      <StatusAndCountdown status={"SUSPENDED"} scheduledStartTime={undefined} />
    )}
  </div>
);
