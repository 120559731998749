// extracted by mini-css-extract-plugin
export var affiliate = "Auth-module--affiliate--12c43";
export var avatar = "Auth-module--avatar--c1389";
export var blue = "#0eb3f2";
export var button = "Auth-module--button--a1561";
export var email = "Auth-module--email--d607b";
export var emphasis = "Auth-module--emphasis--2ea61";
export var emptyAddress = "Auth-module--emptyAddress--1095d";
export var green = "#57d33b";
export var greenLink = "Auth-module--green-link--ad9d6";
export var grey = "var(--tone-mid)";
export var input = "Auth-module--input--d90e6";
export var lastSession = "Auth-module--lastSession--86344";
export var link = "Auth-module--link--22e05";
export var links = "Auth-module--links--d4df3";
export var noSpacing = "Auth-module--noSpacing--ec925";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";