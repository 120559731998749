import React, { type ReactNode } from "react";
import { ReactSVG } from "react-svg";
import cx from "classnames";

import { ReactComponent as BagOfCashIcon } from "assets/icons/money-bag-dollar.svg";
import { ReactComponent as BankIcon } from "assets/icons/saving-bank-1.svg";
import { ReactComponent as CancelIcon } from "assets/icons/remove-circle.svg";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";
import { ReactComponent as CartIcon } from "assets/icons/shopping-cart-1.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-1.svg";
import { ReactComponent as CheckDoubleIcon } from "assets/icons/check-double.svg";
import { ReactComponent as ChipIcon } from "assets/icons/custom-chip.svg";
import { ReactComponent as ClockIcon } from "assets/icons/time-clock-circle.svg";
import { ReactComponent as ConstructionIcon } from "assets/icons/construction-sign.svg";
import { ReactComponent as ContestIcon } from "assets/icons/award-trophy-star.svg";
import { ReactComponent as CrossIcon } from "assets/icons/remove.svg";
import { ReactComponent as CrownIcon } from "assets/icons/vip-crown-queen-2.svg";
import { ReactComponent as CurrencyDollar } from "assets/icons/currency-dollar.svg";
import { ReactComponent as CurrencyEuro } from "assets/icons/currency-euro.svg";
import { ReactComponent as DoubleArrowLeft } from "assets/icons/arrow-button-left-1.svg";
import { ReactComponent as DoubleArrowRight } from "assets/icons/arrow-button-right-1.svg";
import { ReactComponent as EllipsisIcon } from "assets/icons/navigation-menu-horizontal.svg";
import { ReactComponent as EntryIdIcon } from "assets/icons/information-circle.svg";
import { ReactComponent as EnvelopeIcon } from "assets/icons/envelope.svg";
import { ReactComponent as ExternalIcon } from "assets/icons/hyperlink-3.svg";
import { ReactComponent as GiftIcon } from "assets/icons/gift-box.svg";
import { ReactComponent as GridIcon } from "assets/icons/layout-module-1.svg";
import { ReactComponent as HamburgerIcon } from "assets/icons/navigation-menu.svg";
import { ReactComponent as ListIcon } from "assets/icons/layout-bullets.svg";
import { ReactComponent as LocationBlock } from "assets/icons/style-two-pin-off-map.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-6.svg";
import { ReactComponent as MapIcon } from "assets/icons/maps-pin-1.svg";
import { ReactComponent as MastercardIcon } from "assets/icons/credit-card-mastercard.svg";
import { ReactComponent as IconProfile } from "assets/icons/single-neutral-circle.svg";
import { ReactComponent as IconDetails } from "assets/icons/icon-details.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil-1.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as PlayersIcon } from "assets/icons/multiple-neutral-2.svg";
import { ReactComponent as PlayerIcon } from "assets/icons/people-man-1.svg";
import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { ReactComponent as ReferAFriendIcon } from "assets/icons/multiple-actions-add.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/synchronize-arrows-1.svg";
import { ReactComponent as RibbonIcon } from "assets/icons/rating-star-ribbon.svg";
import { ReactComponent as ShieldIcon } from "assets/icons/shield-lock.svg";
import { ReactComponent as StackIcon } from "assets/icons/layers-back.svg";
import { ReactComponent as StopwatchIcon } from "assets/icons/stopwatch.svg";
import { ReactComponent as SubtractIcon } from "assets/icons/subtract.svg";
import { ReactComponent as TeamIcon } from "assets/icons/video-game-controller-team.svg";
import { ReactComponent as TicketIcon } from "assets/icons/ticket-1.svg";
import { ReactComponent as UploadIcon } from "assets/icons/common-file-upload.svg";
import { ReactComponent as VerificationIcon } from "assets/icons/check-circle-1.svg";
import { ReactComponent as VisaIcon } from "assets/icons/credit-card-visa.svg";
import { ReactComponent as WarningIcon } from "assets/icons/alert-triangle.svg";
import { ReactComponent as ModalDrag } from "assets/icons/modal-drag.svg";
import { ReactComponent as Soccer } from "assets/icons/icon-soccer.svg";
import { ReactComponent as Sports } from "assets/icons/icon-sports.svg";
import { ReactComponent as GameController } from "assets/icons/game-controller.svg";
import { ReactComponent as IconHigher } from "assets/icons/icon-higher.svg";
import { ReactComponent as IconLower } from "assets/icons/icon-lower.svg";
import { ReactComponent as IconOdd } from "assets/icons/icon-odd.svg";
import { ReactComponent as IconNo } from "assets/icons/icon-no.svg";
import { ReactComponent as IconYes } from "assets/icons/icon-yes.svg";
import { ReactComponent as IconEven } from "assets/icons/icon-even.svg";
import { ReactComponent as Racing } from "assets/icons/icon-racing.svg";
import { ReactComponent as IconDate } from "assets/icons/icon-date.svg";
import { ReactComponent as IconHorseRacing } from "assets/icons/icon-horse-racing.svg";
import { ReactComponent as IconGreyhounds } from "assets/icons/icon-greyhounds.svg";
import { ReactComponent as WeatherFine } from "assets/icons/racing/weather/picklebet-icon-fine.svg";
import { ReactComponent as WeatherHeavy } from "assets/icons/racing/weather/picklebet-icon-heavyrain.svg";
import { ReactComponent as WeatherOvercast } from "assets/icons/racing/weather/picklebet-icon-overcast.svg";
import { ReactComponent as WeatherShowers } from "assets/icons/racing/weather/picklebet-icon-showers.svg";

import { ReactComponent as IconShare } from "assets/icons/share.svg";
import { ReactComponent as SrmIcon } from "assets/icons/icon-srm-min.svg";
import { ReactComponent as SgmIcon } from "assets/icons/icon-sgm-min.svg";
import { ReactComponent as IconCoverage } from "assets/icons/icon-coverage.svg";
import { ReactComponent as IconHelp } from "assets/icons/icon-help.svg";
import { ReactComponent as IconBonus } from "assets/icons/icon-bonus.svg";
import { ReactComponent as IconHarnessRacing } from "assets/icons/icon-harness.svg";
import { ReactComponent as IconTerms } from "assets/icons/terms.svg";
import { ReactComponent as IconAppStore } from "assets/icons/appstore.svg";
import { ReactComponent as IconGooglePlay } from "assets/icons/googleplay.svg";
import { ReactComponent as IconOpenInNew } from "assets/icons/open-in-new.svg";

import {
  SVG_ICON_BETSLIP,
  SVG_ICON_ENTRIES,
  SVG_ICON_DISCONNECTED,
  SVG_ICON_LOGOUT,
  SVG_ICON_NOTIFICATIONS,
  SVG_ICON_PICK,
  SVG_ICON_PROMOS,
  SVG_ICON_SETTINGS,
  SVG_ICON_QUESTION,
  SVG_ICON_WALLET,
  SVG_ICON_FACEBOOK,
  SVG_ICON_INSTAGRAM,
  SVG_ICON_SEARCH,
  SVG_ICON_TRASH,
  SVG_ICON_TWITTER,
  SVG_ICON_VIDEO,
  SVG_ICON_PICKLEBET,
  SVG_ICON_MUTE,
  SVG_ICON_UNMUTE,
  SVG_ICON_MAXIMISE,
  SVG_ICON_MINIMISE,
  SVG_CLEAR_STAKE,
  SVG_ICON_SEARCH_REMOVE,
  SVG_PAGE_LINK,
  SVG_ICON_PROMOCASH,
  SVG_ICON_STAR,
} from "assets/icons";

import * as styles from "./Icon.module.scss";

type IconElementConstructor = InstanceType<any> | ReturnType<any>;
type IconProperties = string | [IconElementConstructor, string?];

const iconTypeMap: Record<string, IconProperties> = {
  all: [SVG_ICON_ENTRIES],
  "arrow-down": "chevronDown",
  "arrow-up": "chevronUp",
  "arrow-left": "chevronLeft",
  "arrow-right": "chevronRight",
  bonus: [IconBonus],
  chevronDown: [ChevronDown],
  chevronUp: [ChevronDown, "arrowUp"],
  chevronRight: [ChevronDown, "arrowRight"],
  chevronLeft: [ChevronDown, "arrowLeft"],
  coverage: [IconCoverage],
  bagOfCash: [BagOfCashIcon, "green"],
  bank: [BankIcon],
  bell: "notifications",
  betslip: [SVG_ICON_BETSLIP],
  chip: [ChipIcon, "green"],
  cash: "dollar",
  cancel: [CancelIcon],
  cart: [CartIcon, "yellow"],
  check: [CheckIcon, "white"],
  checkDouble: [CheckDoubleIcon, "white"],
  clock: [ClockIcon],
  cup: "contest",
  construction: [ConstructionIcon, "orange"],
  contest: [ContestIcon, "yellow"],
  close: [CrossIcon, "white"],
  crown: [CrownIcon],
  details: [IconDetails],
  disconnected: [SVG_ICON_DISCONNECTED, "white"],
  dollar: [CurrencyDollar, "yellow"],
  doubleArrowLeft: [DoubleArrowLeft, "white"],
  doubleArrowRight: [DoubleArrowRight, "white"],
  euro: [CurrencyEuro, "blue"],
  envelope: [EnvelopeIcon],
  entries: [SVG_ICON_ENTRIES],
  ellipsis: [EllipsisIcon, "white"],
  entryId: [EntryIdIcon, "blue"],
  error: "warning",
  external: [ExternalIcon],
  facebook: [SVG_ICON_FACEBOOK],
  gift: [GiftIcon, "orange"],
  grid: [GridIcon],
  hamburger: [HamburgerIcon, "white"],
  help: [IconHelp],
  info: [EntryIdIcon, "white"],
  instagram: [SVG_ICON_INSTAGRAM],
  lock: [LockIcon],
  list: [ListIcon],
  locationBlock: [LocationBlock],
  logout: [SVG_ICON_LOGOUT],
  map: [MapIcon, "orange"],
  mastercard: [MastercardIcon],
  maximise: [SVG_ICON_MAXIMISE],
  minimise: [SVG_ICON_MINIMISE],
  mute: [SVG_ICON_MUTE],
  notifications: [SVG_ICON_NOTIFICATIONS, "orange"],
  profile: [IconProfile],
  pencil: [PencilIcon],
  player: [PlayerIcon],
  players: [PlayersIcon, "blue"],
  phone: [PhoneIcon],
  pick: [SVG_ICON_PICK, "yellow"],
  plus: [PlusIcon],
  promoCash: [SVG_ICON_PROMOCASH, "yellow"],
  promos: [SVG_ICON_PROMOS, "yellow"],
  question: [SVG_ICON_QUESTION],
  refresh: [RefreshIcon],
  referAFriend: [ReferAFriendIcon],
  ribbon: [RibbonIcon],
  settings: [SVG_ICON_SETTINGS, "blue"],
  search: [SVG_ICON_SEARCH],
  searchRemove: [SVG_ICON_SEARCH_REMOVE],
  shield: [ShieldIcon, "blue"],
  star: [SVG_ICON_STAR],
  share: [IconShare, "white"],
  picklebet: [SVG_ICON_PICKLEBET],
  stack: [StackIcon],
  stopwatch: [StopwatchIcon, "red"],
  subtract: [SubtractIcon],
  team: [TeamIcon],
  ticket: [TicketIcon, "yellow"],
  tickSmall: "verification",
  trash: [SVG_ICON_TRASH, "white"],
  twitter: [SVG_ICON_TWITTER],
  upload: [UploadIcon],
  unmute: [SVG_ICON_UNMUTE],
  verification: [VerificationIcon],
  video: [SVG_ICON_VIDEO],
  videoRed: [SVG_ICON_VIDEO, "red"],
  visa: [VisaIcon],
  wallet: [SVG_ICON_WALLET, "yellow"],
  warningYellow: [WarningIcon, "yellow"],
  warning: [WarningIcon],
  modalDrag: [ModalDrag],
  soccer: [Soccer],
  sports: [Sports],
  gameController: [GameController],
  clearStake: [SVG_CLEAR_STAKE],
  pageLink: [SVG_PAGE_LINK],
  iconHigher: [IconHigher],
  iconLower: [IconLower],
  iconNo: [IconNo],
  iconYes: [IconYes],
  iconEven: [IconEven],
  iconOdd: [IconOdd],
  racing: [Racing],
  date: [IconDate],
  weatherFine: [WeatherFine],
  weatherHeavy: [WeatherHeavy],
  weatherOvercast: [WeatherOvercast],
  weatherShowers: [WeatherShowers],
  horseRacing: [IconHorseRacing],
  greyhounds: [IconGreyhounds],
  srmIcon: [SrmIcon],
  sgmIcon: [SgmIcon],
  harnessRacing: [IconHarnessRacing],
  terms: [IconTerms],
  appStore: [IconAppStore],
  googlePlay: [IconGooglePlay],
  openInNew: [IconOpenInNew, "white"],
};

export const renderIcon = (type: string, props: Record<string, any> = {}) => {
  let mappedType = iconTypeMap[type];
  if (typeof mappedType === "string") {
    mappedType = iconTypeMap[mappedType];
  }
  if (mappedType) {
    const [CompType, color] = mappedType;
    const className = [props.className || "", styles[color]].join(" ");
    props = {
      ...props,
      className,
    };
    return <CompType {...props} />;
  }
  return null;
};

export function renderImageIcon(
  src: string,
  isActive: boolean,
  title?: string,
  svg?: boolean,
  className?: string,
) {
  if (svg) {
    return <ReactSVG src={src} className={className} />;
  }

  return (
    <div
      style={{
        backgroundImage: `url("${src}")`,
      }}
      title={title}
      className={cx({ [styles.iconInactive]: !isActive })}
    />
  );
}

type IconProps = {
  className?: string;
  height?: number | string;
  width?: number | string;
  type: string;
  href?: string;
  inline?: boolean;
  svg?: boolean;
  inlineContent?: any;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  title?: string;
  src?: string;
  style?: Record<string, any>;
  isActive?: boolean;
  outerClassName?: string;
  onMouseDown?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

export const Icon = ({
  className,
  height,
  width,
  type,
  href,
  inline,
  svg,
  inlineContent,
  onClick,
  title,
  src,
  style: inStyles = {},
  isActive = true,
  outerClassName,
  onMouseDown,
  onMouseUp,
}: IconProps): JSX.Element | null => {
  const iconProps = {
    className,
    height,
    width,
    onClick,
    onMouseUp,
    onMouseDown,
  };
  const renderedIcon = src
    ? renderImageIcon(src, isActive, title, svg, className)
    : renderIcon(type, iconProps);

  const style: Record<string, any> = {
    ...inStyles,
    display: undefined,
    alignItems: undefined,
    justifyContent: undefined,
  };
  if (!inline) {
    style.flexBasis = width || "2em";
  }

  const outerClassNames = [
    inline ? styles.iconInline : styles.iconFlex,
    "icon",
    outerClassName,
    type,
  ].join(" ");

  let renderedComponent: ReactNode;

  if (inlineContent) {
    renderedComponent = svg ? (
      <span dangerouslySetInnerHTML={{ __html: inlineContent }} />
    ) : (
      <span
        className={outerClassNames}
        style={style}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        title={title}
        dangerouslySetInnerHTML={{ __html: inlineContent }}
      />
    );
  } else if (svg) {
    renderedComponent = renderedIcon;
  } else if (href) {
    renderedComponent = (
      <a
        className={outerClassNames}
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderedIcon}
      </a>
    );
  } else {
    renderedComponent = (
      <span
        className={outerClassNames}
        style={style}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        title={title}
      >
        {renderedIcon}
      </span>
    );
  }

  return renderedComponent;
};

export default Icon;
