import React from "react";
import cx from "classnames";

import * as styles from "./StatusPill.module.scss";

const StatusPill = ({
  children,
  color,
  className,
}: {
  children: JSX.Element | string;
  color: "red" | "green" | "blue" | "yellow" | "purple" | "orange" | "grey";
  className?: string;
}) => (
  <span
    className={cx({
      [styles.pill]: true,
      [className]: !!className,
      [styles[color]]: color,
    })}
  >
    {children}
  </span>
);

export default StatusPill;
