import slug from "slug";

export function toSlug(str: string) {
  return slug(str);
}

export function indexToPlace(value: number) {
  if (value < 0) {
    return "?";
  }
  const place = value + 1;
  const inHundred = place % 100;
  let suffix = "th";
  if (inHundred < 10 || inHundred > 20) {
    const inTen = inHundred % 10;
    switch (inTen) {
      case 3:
        suffix = "rd";
        break;
      case 2:
        suffix = "nd";
        break;
      case 1:
        suffix = "st";
        break;
    }
  }
  return place + suffix;
}

export const removeTrailingSlash = (string: string = "") => {
  if (string.length > 1 && string.endsWith("/")) {
    return string.replace(/\/+$/, "");
  }

  return string;
};
