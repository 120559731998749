import React, { type PropsWithChildren } from "react";
import cx from "classnames";
import { cva, type VariantProps } from "class-variance-authority";

import * as styles from "./Skeleton.module.scss";

const skeletonVariants = cva(styles.skeleton, {
  variants: {
    shape: {
      circle: styles.circle,
      square: styles.square,
      rect: styles.rect,
    },
    size: {
      xs: styles.xsmall,
      sm: styles.small,
      default: styles.sizeDefault,
      lg: styles.large,
    },
    mode: {
      light: styles.light,
      dark: styles.dark,
    },
    edge: { hard: styles.hard, soft: styles.soft },
    span: {
      quart: styles.quart,
      half: styles.half,
      third: styles.third,
    },
  },
  defaultVariants: { size: "default", shape: "rect", edge: "soft" },
});

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof skeletonVariants> & {
    className?: string;
  };

export function Skeleton({
  className,
  shape,
  size,
  span,
  edge,
  mode,
  children,
  ...rest
}: PropsWithChildren<SkeletonProps>) {
  return (
    <div
      data-testid="skeleton-loader"
      className={cx(
        skeletonVariants({ shape, size, edge, span, mode }),
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Skeleton;
