import { useCallback, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { parse, stringify, type StringifyOptions } from "query-string";
import Route from "route-parser";
import { useLocation } from "./useLocation";
import useLocale from "./useLocale";
import { isEqual } from "lodash";

type QueryKeyMapping = {
  routeKey: string;
  queryKey: string;
};

type QueryOptionsType = {
  replace?: boolean;
};

// Utility to remove empty values from an object
const removeEmpty = <T extends object>(obj: T): Partial<T> =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== "") {
        acc[key as keyof T] = value;
      }
      return acc;
    },
    {} as Partial<T>,
  );

// Extract keys for query string mapping from the route template
const extractQueryStringMappings = (routeTemplate: string): QueryKeyMapping[] =>
  (routeTemplate.match(/\(([^)]+)\)/g) || []).map((key) => {
    const [routeKey, queryKey] = key.replace(/[()]/g, "").split("=:");
    return { routeKey, queryKey };
  });

// Separate values into path and query string values based on query keys
const separateValues = <T extends object>(
  values: Partial<T>,
  queryKeys: QueryKeyMapping[],
): { pathValues: Partial<T>; queryStringValues: Partial<T> } => {
  const pathValues: Partial<T> = {};
  const queryStringValues: Partial<T> = {};

  Object.entries(values).forEach(([key, value]) => {
    const queryKeyInfo = queryKeys.find((qk) => qk.queryKey === key);
    if (queryKeyInfo) {
      (queryStringValues as any)[queryKeyInfo.routeKey] = value;
    } else {
      (pathValues as any)[key] = value;
    }
  });

  return { pathValues, queryStringValues };
};

const cleanPath = (path: string): string =>
  path.replace(/&+/g, "&").replace(/\?&/, "?").replace(/&$/, "");

/**
 * useRoute
 * @param pathTemplate The route template to use for the route
 * @param defaults Default values for the route
 * @param options Options for the route
 * @param queryOptions Options for the query string
 * @returns A tuple containing a function to set the route and the current route properties
 * @example
 * const [setRoute, routeProperties] = useRoute<{ id: string }>("/blog/:id");
 * setRoute({ id: "123" });
 * console.log(routeProperties.id); // "123"
 * console.log(routeProperties); // { id: "123" }
 * setRoute({ id: undefined });
 * console.log(routeProperties.id); // undefined
 * console.log(routeProperties); // {}
 * setRoute({ id: "123", page: 2 });
 *
 * // The following will navigate to /blog/123?page=2
 * setRoute({ id: "123", page: 2 });
 * Notes:
 * - A URL requires at least one non-optional part.
 *   E.g., "/betting(/:someVar)" is valid, but "(/betting)(/:someVar)" is not.
 * - Do NOT include the locale in the pathTemplate.
 *   E.g., "/:eventId" is valid, but "/:locale/:eventId" is not.
 *   Query Params names should be the same as the route params names. e.g. (page=:page) not (page=:p)
 **/
const useRoute = <T extends object>(
  pathTemplate: string,
  defaults: Partial<T>,
  options: QueryOptionsType = { replace: true },
  queryOptions: StringifyOptions = { arrayFormat: "index" },
): [(arg: Partial<T>) => void, T] => {
  const { pathnameWithoutLocale, search } = useLocation();
  const locale = useLocale();
  const [route] = useState(new Route<T>(pathTemplate));
  const queryKeys = useMemo(() => extractQueryStringMappings(pathTemplate), []);

  const navigateWithLocale = useCallback(
    (path: string) => {
      const pathWithLocale =
        locale?.toLowerCase() !== "en-us" ? `/${locale}${path}` : path;
      navigate(cleanPath(pathWithLocale), options);
    },
    [locale, options],
  );

  const navigateToNewRouteIfNecessary = (updatedValues: Partial<T>) => {
    // Override with updated values passed to the function
    const routeValues = {
      ...defaults,
      ...routeProperties,
      ...updatedValues,
    };

    // Separate into path and query string values
    const { pathValues, queryStringValues } = separateValues(
      routeValues,
      queryKeys,
    );

    // Generate the new path and query string
    const newPath = route.reverse(pathValues as T) || "";
    const newQueryString = stringify(
      removeEmpty(queryStringValues),
      queryOptions,
    );

    // Remove any existing query string from newPath
    const newPathWithoutQueryString = newPath.includes("?")
      ? newPath.split("?")[0]
      : newPath;

    // Construct the full new path by combining path and query string
    const newFullPath = cleanPath(
      `${newPathWithoutQueryString}${
        newQueryString ? `?${newQueryString}` : ""
      }`,
    );

    if (cleanPath(`${pathnameWithoutLocale}${search}`) !== newFullPath) {
      navigateWithLocale(newFullPath);
    }
  };

  const getRouteProperties = (): T => {
    const routeMatch = (route.match(pathnameWithoutLocale) as Partial<T>) || {};
    const searchParams = parse(search, queryOptions) as Partial<T>;
    const combined = { ...defaults, ...routeMatch, ...searchParams };
    const { pathValues, queryStringValues } = separateValues(
      combined,
      queryKeys,
    );

    return {
      ...removeEmpty(pathValues),
      ...removeEmpty(queryStringValues),
    } as T;
  };

  const [routeProperties, setRouteProperties] = useState<T>({
    ...defaults,
    ...getRouteProperties(),
  });

  const setRoute = useCallback(
    (updatedValues: Partial<T>) => navigateToNewRouteIfNecessary(updatedValues),

    [
      route,
      navigateWithLocale,
      queryKeys,
      queryOptions,
      pathnameWithoutLocale,
      search,
    ],
  );

  // Effect for responding to route changes
  useEffect(() => {
    const updatedRouteProperties = getRouteProperties();

    // Check if updatedRouteProperties contains at least the defaults
    const containsDefaultsProperties = Object.keys(defaults).every((key) =>
      updatedRouteProperties.hasOwnProperty(key),
    );

    // If not, send an update to the url with the missing defaults and the updated values
    if (!containsDefaultsProperties) {
      navigateToNewRouteIfNecessary(updatedRouteProperties);
      return;
    }

    if (!isEqual(updatedRouteProperties, routeProperties)) {
      setRouteProperties(updatedRouteProperties);
    }
  }, [pathnameWithoutLocale, search]);

  return [setRoute, routeProperties];
};

export default useRoute;
