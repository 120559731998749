// extracted by mini-css-extract-plugin
export var base = "Typography-module--base--f2af5";
export var blue = "#0eb3f2";
export var body = "Typography-module--body--1d093";
export var emphasis = "Typography-module--emphasis--21020";
export var extraSpacing = "Typography-module--extraSpacing--6a526";
export var green = "#57d33b";
export var greenLink = "Typography-module--green-link--0649b";
export var grey = "var(--tone-mid)";
export var h1 = "Typography-module--h1--97016";
export var h2 = "Typography-module--h2--a0907";
export var h3 = "Typography-module--h3--519e7";
export var h4 = "Typography-module--h4--b6b30";
export var h5 = "Typography-module--h5--64de7";
export var h6 = "Typography-module--h6--fffe8";
export var icon = "Typography-module--icon--e73c9";
export var lead = "Typography-module--lead--469c4";
export var li = "Typography-module--li--87118";
export var noSpacing = "Typography-module--noSpacing--3eb50";
export var notchGap = "32px";
export var orange = "#f8662b";
export var overflowHidden = "Typography-module--overflowHidden--31c85";
export var red = "#ff2741";
export var skeleton = "Typography-module--skeleton--d15c3";
export var ul = "Typography-module--ul--063fb";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";