import React from "react";
import { Icon } from "library/components/Icon";
import type { Title } from "hooks/graphql/useTitles";

type TitleIconProps = {
  title: Title;
  isActive?: boolean;
  svg?: boolean;
  className?: string;
  inline?: any;
};

export const TitleIcon = ({ title, ...otherProps }: TitleIconProps) => {
  if (!title) {
    return null;
  }

  if (title.icon && typeof title.icon === "string") {
    return <Icon type={title.icon} {...otherProps} />;
  } else if (title.icon?.file?.url) {
    return <Icon type={"title"} src={title.icon.file.url} {...otherProps} />;
  } else {
    return <Icon type="question" {...otherProps} />;
  }
};
