import React from "react";
import { type RouteComponentProps, Router } from "@gatsbyjs/reach-router";
import { PageContent } from "components/Page";
import { UnverifiedOnly } from "components/layout/UnverifiedOnly";
import { VerificationOptions } from "./components/VerificationOptions";
import { AustralianDriverLicence } from "./components/forms/AustralianDriverLicence";
import { AustralianPassport } from "./components/forms/AustralianPassport";
import { MedicareCard } from "./components/forms/MedicareCard";
import { AustralianVisa } from "./components/forms/AustralianVisa";
import { NewZealandDriverLicence } from "./components/forms/NewZealandDriverLicence";
import { NewZealandPassport } from "./components/forms/NewZealandPassport";

type VerificationProps = {
  prefix?: string;
} & RouteComponentProps;

const Verification = ({ prefix }: VerificationProps) => {
  return (
    <UnverifiedOnly>
      <PageContent>
        <Router primary={false}>
          <VerificationOptions path={`/`} default prefix={prefix} />
          <AustralianDriverLicence
            path={`/australian-drivers-licence`}
            prefix={prefix}
          />
          <AustralianPassport path={`/australian-passport`} prefix={prefix} />
          <MedicareCard path={`/medicare`} prefix={prefix} />
          <AustralianVisa path={`/australian-visa`} prefix={prefix} />
          <NewZealandDriverLicence
            path={`/nz-drivers-license`}
            prefix={prefix}
          />
          <NewZealandPassport path={`/nz-passport`} prefix={prefix} />
        </Router>
      </PageContent>
    </UnverifiedOnly>
  );
};

export default Verification;
