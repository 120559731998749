import { useStaticQuery, graphql } from "gatsby";
import { useMemo } from "react";

export type Title = {
  slug: string;
  name: string;
  icon: {
    file: {
      contentType: string;
      url: string;
    };
  };
  bettingMetaTitle?: string;
  bettingMetaDescription?: string;
  pickemsMetaTitle?: string;
  pickemsMetaDescription?: string;
  logo?: any;
  hero?: any;
};

export type Titles = {
  [slug: string]: Title;
};

export const useTitles = (): Titles => {
  const { allContentfulTitle } = useStaticQuery(graphql`
    {
      allContentfulTitle {
        nodes {
          name
          slug
          icon {
            ...IconImage
          }
          bettingMetaTitle
          bettingMetaDescription
          pickemsMetaTitle
          pickemsMetaDescription
          hero {
            ...HeroImage
          }
          logo {
            ...IconImage
          }
        }
      }
    }
  `);

  return useMemo(
    () =>
      allContentfulTitle.nodes
        .filter(({ slug }) => slug && slug !== "empty")
        .reduce((allTitles: Titles, title: Queries.ContentfulTitle) => {
          allTitles[title.slug] = {
            slug: title.slug,
            name: title.name,
            icon: title.icon,
            bettingMetaTitle: title.bettingMetaTitle,
            bettingMetaDescription: title.bettingMetaDescription,
            pickemsMetaTitle: title.pickemsMetaTitle,
            pickemsMetaDescription: title.pickemsMetaDescription,
            hero: title.hero,
            logo: title.logo,
          };
          return allTitles;
        }, {} as Titles),
    [allContentfulTitle],
  );
};

export const useTitle = (title: string): Title => {
  const titles = useTitles();

  const titleSlug = Object.keys(titles).find((slug) => slug === title) ?? "";

  return titles[titleSlug];
};
