// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var closeButton = "Dialog-module--closeButton--4b9bf";
export var confirmButtons = "Dialog-module--confirmButtons--849e6";
export var content = "Dialog-module--content--98f3a";
export var contentShow = "Dialog-module--contentShow--ba4f0";
export var description = "Dialog-module--description--fb293";
export var emphasis = "Dialog-module--emphasis--29b13";
export var green = "#57d33b";
export var greenLink = "Dialog-module--green-link--89cfd";
export var grey = "var(--tone-mid)";
export var hasIcon = "Dialog-module--hasIcon--4329f";
export var icon = "Dialog-module--icon--84e7f";
export var notchGap = "32px";
export var orange = "#f8662b";
export var overlay = "Dialog-module--overlay--98843";
export var overlayShow = "Dialog-module--overlayShow--37a6e";
export var red = "#ff2741";
export var title = "Dialog-module--title--d03cd";
export var titleAndIcon = "Dialog-module--titleAndIcon--96d32";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";