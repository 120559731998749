// extracted by mini-css-extract-plugin
export var active = "MobileNavigation-module--active--6dbb1";
export var badge = "MobileNavigation-module--badge--cebab";
export var blue = "#0eb3f2";
export var bounce = "MobileNavigation-module--bounce--d99d8";
export var container = "MobileNavigation-module--container--f3b1f";
export var emphasis = "MobileNavigation-module--emphasis--53b7e";
export var green = "#57d33b";
export var greenLink = "MobileNavigation-module--green-link--5b4ce";
export var grey = "var(--tone-mid)";
export var highlight = "MobileNavigation-module--highlight--7830e";
export var iconContainer = "MobileNavigation-module--iconContainer--2afae";
export var item = "MobileNavigation-module--item--34ef7";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";