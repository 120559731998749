import React, { useContext } from "react";
import { useBetslip, useDispatch, useFiatCurrency } from "hooks";
import { Alert } from "components/Alert";
import { SlideOut } from "./SlideOut";
import { Button } from "components/Button";
import { ReactComponent as TrashIcon } from "sections/Betting/Betslip/assets/trash.svg";
import { FirebaseContext } from "context/Firebase";
import { BetslipUIStates } from "sections/Betting/Betslip/betslipSlice";

import * as styles from "./Duplicate.module.scss";

const Duplicate = () => {
  const dispatch = useDispatch();
  const {
    props: { betslipIsSubmitting },
    actions: { setBetSlipState, submitBet, clearSelections },
  } = useBetslip();
  const currency = useFiatCurrency();
  const { profile, userPreferences } = useContext(FirebaseContext);

  return (
    <SlideOut>
      <Alert className={styles.alert} variant={`warning`}>
        Repeat your last bet?
      </Alert>
      <div className={styles.footer}>
        <Button
          onClick={() => dispatch(clearSelections())}
          variant={`blurred`}
          className={styles.iconButton}
        >
          <TrashIcon />
        </Button>
        <Button
          loading={betslipIsSubmitting}
          variant={`warning`}
          className={styles.button}
          onClick={() => {
            dispatch(setBetSlipState(BetslipUIStates.acceptDuplicate));
            dispatch(submitBet(currency, userPreferences?.acceptOdds, profile));
          }}
        >
          Continue
        </Button>
      </div>
    </SlideOut>
  );
};

export { Duplicate };
