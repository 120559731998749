// extracted by mini-css-extract-plugin
export var active = "CondensedMenuItem-module--active--139bc";
export var blue = "#0eb3f2";
export var container = "CondensedMenuItem-module--container--5907c";
export var emphasis = "CondensedMenuItem-module--emphasis--2f186";
export var green = "#57d33b";
export var greenLink = "CondensedMenuItem-module--green-link--67fa0";
export var grey = "var(--tone-mid)";
export var item = "CondensedMenuItem-module--item--a93b7";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";