import React from "react";

import { Icon } from "library";

import cx from "classnames";

import * as styles from "./HUDPanelEmpty.module.scss";

export const HUDPanelEmpty = ({
  icon,
  message,
  children,
  title,
}: {
  icon: string;
  message: string;
  children?: JSX.Element;
  title?: string;
}) => (
  <div className={styles.empty}>
    <Icon type={icon} />
    <span className={cx({ [styles.emptyTitle]: title })}>{title}</span>
    <span className={cx({ [styles.emptyTitle]: !title })}>{message}</span>
    {children}
  </div>
);
