import React, { type PropsWithChildren } from "react";
import CompetitorSkeleton from "../Competitor/CompetitorSkeleton";

type ExoticCompetitorSkeletonProps = PropsWithChildren<{
  isLoading: boolean;
}>;

export const ExoticCompetitorSkeleton = ({
  isLoading,
  children,
}: ExoticCompetitorSkeletonProps) => {
  if (isLoading) return <CompetitorSkeleton displayType="Win" />;
  return children;
};
