import React, { type PropsWithChildren } from "react";
import { TitleIcon } from "components/TitleIcon";
import type { Title } from "hooks/graphql/useTitles";
import { ScrollArea } from "components/ScrollArea";
import {
  ToggleGroupItem,
  ToggleGroupRoot,
} from "components/ToggleGroup/ToggleGroup";
import cx from "classnames";
import BadgeCount from "library/components/BadgeCount";
import { useIsMobile } from "hooks";
import { Skeleton } from "components/Skeleton";
import { useScrollBoundary } from "hooks/useScrollBoundary";
import { Shadow } from "components/Shadow";
import { AnimatePresence } from "framer-motion";

import * as styles from "./TitleFilter.module.scss";

type TitleCounts = {
  total?: number;
} & { [key: string]: number };

type TitleFilterBaseProps = PropsWithChildren<{
  selectionType: "single" | "multiple";
  className?: string;
  loading?: boolean;
}>;
interface TitleFilterSingleProps extends TitleFilterBaseProps {
  selectionType: "single";
  selected: string;
  onSelect: (selected: string) => void;
}
interface TitleFilterMultipleProps extends TitleFilterBaseProps {
  selectionType: "multiple";
  selected: string[];
  onSelect: (selected: string[]) => void;
}

type TitleFilterItemsProps = {
  titles: Title[];
  className?: string;
  iconClassName?: string;
  counts?: TitleCounts;
  hideCounts?: boolean;
};

const TitleFilterItems = ({
  className,
  iconClassName,
  titles,
  hideCounts,
  counts,
}: TitleFilterItemsProps) => {
  return titles.map((title) => (
    <ToggleGroupItem
      key={`title-${title.slug}`}
      value={title.slug}
      className={cx(styles.title, className)}
      title={title.name}
    >
      <TitleIcon
        title={title}
        svg
        className={cx(styles.titleIcon, iconClassName)}
      />
      {!hideCounts && (
        <BadgeCount
          className={cx(styles.titleCountBadge, {
            [styles.extended]: counts?.[title.slug] >= 100,
          })}
          count={counts[title.slug]}
        />
      )}
    </ToggleGroupItem>
  ));
};

type TitleFilterItemProps = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
}>;

const TitleFilterItem = ({
  children,
  onClick,
  className,
}: TitleFilterItemProps) => {
  return (
    <div className={cx(styles.title, className)} onClick={onClick}>
      {children}
    </div>
  );
};

const TitleFilterRoot = React.forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    const isMobile = useIsMobile();

    return (
      <ScrollArea
        viewPortRef={ref}
        hideVerticalScrollbar
        hideHorizontalScrollbar={isMobile}
        horizontalScrollBarClassName={styles.scrollBar}
        className={styles.scrollArea}
      >
        <div ref={ref} className={styles.titleFilter}>
          {children}
        </div>
      </ScrollArea>
    );
  },
);

const TitleFilterSingle = React.forwardRef<
  HTMLDivElement,
  TitleFilterSingleProps
>(({ children, className, onSelect, selected }, ref) => {
  const { viewportRef, isScrollable, isEnd, isStart } = useScrollBoundary();

  return (
    <TitleFilterRoot ref={viewportRef}>
      <ToggleGroupRoot
        type={"single"}
        value={selected}
        onValueChange={onSelect}
        className={cx(styles.titleFilter, className)}
        ref={ref}
      >
        {children}
      </ToggleGroupRoot>

      <AnimatePresence>
        {isScrollable && !isStart && <Shadow position="left" />}
      </AnimatePresence>
      <AnimatePresence>
        {isScrollable && !isEnd && <Shadow position="right" />}
      </AnimatePresence>
    </TitleFilterRoot>
  );
});

const TitleFilterMultiple = React.forwardRef<
  HTMLDivElement,
  TitleFilterMultipleProps
>(({ children, className, onSelect, selected }, ref) => {
  const { viewportRef, isScrollable, isEnd, isStart } = useScrollBoundary();

  return (
    <TitleFilterRoot ref={viewportRef}>
      <ToggleGroupRoot
        type={"multiple"}
        value={selected}
        onValueChange={onSelect}
        className={cx(styles.titleFilter, className)}
        ref={ref}
      >
        {children}
      </ToggleGroupRoot>

      <AnimatePresence>
        {isScrollable && !isStart && <Shadow position="left" />}
      </AnimatePresence>
      <AnimatePresence>
        {isScrollable && !isEnd && <Shadow position="right" />}
      </AnimatePresence>
    </TitleFilterRoot>
  );
});

const TitleFilter = React.forwardRef<
  HTMLDivElement,
  TitleFilterSingleProps | TitleFilterMultipleProps
>((props, ref) => {
  if (props.loading) {
    return (
      <TitleFilterRoot>
        <div className={cx(styles.titleFilter, props.className)}>
          {Array.from({ length: 12 }).map((_, index) => (
            <Skeleton
              key={`title-filter-skeleton-${index}`}
              className={styles.titleSkeleton}
              shape="circle"
            />
          ))}
        </div>
      </TitleFilterRoot>
    );
  }

  if (props.selectionType === "single") {
    return <TitleFilterSingle ref={ref} {...props} />;
  }

  if (props.selectionType === "multiple") {
    return <TitleFilterMultiple ref={ref} {...props} />;
  }

  return null;
});

TitleFilter.displayName = "TitleFilter";

export { TitleFilter, TitleFilterItem, TitleFilterItems };
