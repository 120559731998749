// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var bullet = "Validator-module--bullet--1e875";
export var emphasis = "Validator-module--emphasis--ce5fa";
export var error = "Validator-module--error--9640b";
export var green = "#57d33b";
export var greenLink = "Validator-module--green-link--f3875";
export var grey = "var(--tone-mid)";
export var medium = "Validator-module--medium--42151";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var small = "Validator-module--small--d49e6";
export var tooltip = "Validator-module--tooltip--f607e";
export var validator = "Validator-module--validator--46cd2";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";