import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "components/Input";
import { PasswordInput } from "components/PasswordInput";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { login, showProfileCompletionModal } from "utilities/Auth/authSlice";
import { useDispatch, useProfile, usePublicProfile } from "hooks";
import { Errors } from "./Errors";
import { Link } from "components/Link";
import { Typography } from "components/Typography";
import { setModal } from "utilities/UI/uiSlice";
import { links } from "./Auth.module.scss";
import type { ILastSession } from "utilities/lastSession";
import { Avatar } from "components/Avatar";
import { navigate } from "library";

import * as styles from "./Auth.module.scss";

type FormValues = {
  email: string;
  password: string;
};

type SignInFormProps = {
  onComplete?: () => void;
  onFail?: () => void;
  onRequiresReopen?: () => void;
  lastSession?: ILastSession;
  onIgnoreLastSession?: () => void;
};

const schema = yup
  .object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const SignInForm = ({
  onComplete,
  lastSession,
  onIgnoreLastSession,
}: SignInFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: lastSession?.email ?? "",
    },
  });
  const dispatch = useDispatch();

  const [publicProfile, loading] = usePublicProfile(lastSession?.uid);
  const [profile] = useProfile();
  const shouldUseLastSession = !!lastSession && publicProfile;
  const profileRef = useRef(profile);

  // all of this is done to make sure we don't treat people logged in till their profile is loaded
  useEffect(() => {
    profileRef.current = profile;
  }, [profile]);
  // this promise will resolve when the profile is loaded
  const waitForProfileToLoad = () => {
    return new Promise((resolve) => {
      const checkProfileLoaded = () => {
        if (profileRef.current) {
          resolve(true);
        }
      };
      const intervalId = setInterval(checkProfileLoaded, 100);

      return () => clearInterval(intervalId);
    });
  };

  useEffect(() => {
    if (!lastSession) {
      reset({ email: "" });
    }
  }, [lastSession]);

  if (loading) return null;

  return (
    <>
      <Form
        onSubmit={handleSubmit(async ({ email, password }: FormValues) => {
          const response = await dispatch(
            login({
              email,
              password,
            }),
          );

          if (response.meta.requestStatus === "fulfilled") {
            await waitForProfileToLoad();

            dispatch(setModal(null));

            const permissions = response?.payload?.permissions || {};

            if (permissions?.submitBet === "ACCOUNT_REOPEN_REQUIRED") {
              await navigate("/account/reopen");
            }

            if (permissions?.submitBet === "INCOMPLETE_PROFILE") {
              dispatch(showProfileCompletionModal());
            }

            if (onComplete) onComplete();
          } else if (response.meta.requestStatus === "rejected") {
            const {
              errors,
              message,
              messageTemplate,
            }: { errors: any; message: string; messageTemplate: string } =
              response.payload ?? {};

            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).forEach((key, index) => {
                setError(
                  key as any,
                  {
                    type: "manual",
                    message: errors[key],
                  },
                  // should focus on first error message
                  { shouldFocus: index === 0 },
                );
              });
            } else {
              setError("root.server", {
                type: messageTemplate,
                message,
              });
            }
          }
        })}
      >
        {shouldUseLastSession ? (
          <div className={styles.lastSession}>
            <Avatar
              className={styles.avatar}
              src={publicProfile?.avatarUri}
              alt={`${publicProfile?.username}'s Avatar`}
              size={120}
            />
            <Typography variant="h1" noSpacing>
              Hi {publicProfile?.username}
            </Typography>
            <Typography className={styles.email} variant="body" noSpacing>
              {lastSession.email}
            </Typography>
          </div>
        ) : null}
        {errors?.root?.server && (
          <Errors
            type={errors?.root?.server?.type as string}
            message={errors?.root?.server?.message}
          />
        )}
        <Input
          autoFocus={!shouldUseLastSession}
          label={`Email`}
          errorMessage={errors?.email?.message}
          type={shouldUseLastSession ? "hidden" : "email"}
          {...register("email")}
        />
        <PasswordInput
          autoFocus={!!shouldUseLastSession}
          id={`password`}
          label={`Password`}
          errorMessage={errors?.password?.message}
          {...register("password")}
        />
        <Button loading={isSubmitting} disabled={isSubmitting} type={`submit`}>
          Continue
        </Button>
      </Form>
      <div className={links}>
        <Link to={`/forgot-password`} state={{ reset: true }}>
          Forgot Password?
        </Link>
        {shouldUseLastSession ? (
          <Typography component={`span`} variant="body" noSpacing>
            <Button
              variant={"link"}
              onClick={() => {
                reset();
                onIgnoreLastSession();
              }}
            >
              Not {publicProfile?.username}?
            </Button>
          </Typography>
        ) : (
          <Typography component={`span`} variant="body" noSpacing>
            No Account?{" "}
            <Button
              variant={"link"}
              onClick={() => dispatch(setModal("signUp"))}
            >
              Sign up
            </Button>
          </Typography>
        )}
      </div>
    </>
  );
};

export { SignInForm };
