// --
// Constants

export const VALIDATION_RAISE_ISSUE = "VALIDATION_RAISE_ISSUE";
export const VALIDATION_RAISE_ISSUES = "VALIDATION_RAISE_ISSUES";
export const VALIDATION_CLEAR_FIELD = "VALIDATION_CLEAR_FIELD";
export const VALIDATION_CLEAR_FORM = "VALIDATION_CLEAR_FORM";
export const VALIDATION_CLEAR_ALL = "VALIDATION_CLEAR_ALL";

// --
// Actions

export function raiseIssue(form, field, issue) {
  return {
    type: VALIDATION_RAISE_ISSUE,
    payload: { form, field, issue },
  };
}

export function raiseIssues(form, issues) {
  return {
    type: VALIDATION_RAISE_ISSUES,
    payload: { form, issues },
  };
}

export function clearField(form, field) {
  return {
    type: VALIDATION_CLEAR_FIELD,
    payload: { form, field },
  };
}

export function clearForm(form) {
  return {
    type: VALIDATION_CLEAR_FORM,
    payload: { form },
  };
}

export function clearAll() {
  return {
    type: VALIDATION_CLEAR_ALL,
  };
}

// --
// Helpers

export function countIssues(formState, required) {
  if (formState) {
    let keys = Object.keys(formState);

    let issues = Object.values(formState).reduce((acc, f) => acc + f.length, 0);
    let missing = 0;
    if (required) {
      missing = required.reduce(
        (acc, f) => (keys.indexOf(f) === -1 ? acc + 1 : acc),
        0,
      );
    }

    return issues + missing;
  } else {
    return -1;
  }
}

// --
// Handlers

const ACTION_HANDLERS = {
  [VALIDATION_RAISE_ISSUE]: (state, action) => {
    let next = { ...state };
    let payload = action.payload;

    if (!next[payload.form]) {
      next[payload.form] = {};
    }

    if (!next[payload.form][payload.field]) {
      next[payload.form][payload.field] = [];
    }

    next[payload.form][payload.field].push(payload.issue);

    return next;
  },
  [VALIDATION_RAISE_ISSUES]: (state, action) => {
    let next = { ...state };
    let payload = action.payload;

    if (!next[payload.form]) {
      next[payload.form] = {};
    }

    Object.keys(payload.issues).forEach((field) => {
      if (!next[payload.form][field]) {
        next[payload.form][field] = [];
      }

      payload.issues[field].forEach((message) => {
        next[payload.form][field].push(message);
      });
    });

    return next;
  },
  [VALIDATION_CLEAR_FIELD]: (state, action) => {
    let next = { ...state };
    let payload = action.payload;

    if (!next[payload.form]) {
      next[payload.form] = {};
    }

    if (!next[payload.form][payload.field]) {
      next[payload.form][payload.field] = [];
    }

    // Deliberately leave behind empty list to track initial interaction
    next[payload.form][payload.field].length = 0;
    return next;
  },
  [VALIDATION_CLEAR_FORM]: (state, action) => {
    let next = { ...state };
    let payload = action.payload;

    if (next[payload.form]) {
      delete next[payload.form];
      return next;
    } else {
      return state;
    }
  },
  [VALIDATION_CLEAR_ALL]: (state, action) => {
    return {};
  },
};

// --
// Reducer

const initialState = {};

export default function validationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
