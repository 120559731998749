// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "InputError-module--container--b9458";
export var emphasis = "InputError-module--emphasis--10679";
export var green = "#57d33b";
export var greenLink = "InputError-module--green-link--a3cf3";
export var grey = "var(--tone-mid)";
export var message = "InputError-module--message--3cd97";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";