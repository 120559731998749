import React from "react";
import type { MenuItem } from "./types";
import cx from "classnames";
import { Icon, Link } from "library";
import { usePageContext } from "hooks/usePageContext";

import * as styles from "./Item.module.scss";
import SubItems from "./SubItems";
import { AnimatePresence } from "framer-motion";

type ItemProps = {
  item: MenuItem;
  expandedItem: string;
  setExpandedItem: (name: string) => void;
};

const ItemContainer = ({
  item,
  onClick,
  children,
}: {
  item: MenuItem;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  // item has "to" but has no children
  const isLink = !item.items?.length && item.to;
  // item has "onClick" handler and no children
  const isOnClick = !item.items?.length && item.onClick;

  if (isLink) {
    return (
      <Link className={styles.content} to={item.to} nolang={!!item.noLang}>
        <>{children}</>
      </Link>
    );
  }

  return (
    <div
      className={styles.content}
      onClick={() => {
        onClick();
        isOnClick && item.onClick();
      }}
    >
      {children}
    </div>
  );
};

const Item = ({ item, expandedItem, setExpandedItem }: ItemProps) => {
  const { section } = usePageContext();

  const isExpanded = item.name === expandedItem;

  return (
    <li
      className={cx(styles.item, {
        [styles.active]: (item.sections || []).includes(section),
      })}
    >
      <ItemContainer
        item={item}
        onClick={() => {
          if (item.items?.length) {
            setExpandedItem(isExpanded ? undefined : item.name);
          }
        }}
      >
        <span className={styles.icon}>{item.icon}</span>
        <span>{item.name}</span>
        {item.items ? (
          <span
            className={cx(styles.caret, {
              [styles.open]: isExpanded,
            })}
          >
            <Icon type="chevronDown" />
          </span>
        ) : null}
      </ItemContainer>
      {item.items?.length && isExpanded ? (
        <AnimatePresence>
          <SubItems items={item.items} />
        </AnimatePresence>
      ) : null}
    </li>
  );
};

export default Item;
