import React from "react";

import { Well } from "../Well";
import { Icon } from "../Icon";
import Button from "../Button";
import type { INotification } from "types/ContentTypes";
import cx from "classnames";

import * as styles from "./PageNotification.module.scss";

export const PageNotification = ({
  icon = "warning",
  type = "default",
  message,
  action,
  actionText = "OK",
  link,
  children,
  className,
}: INotification) => (
  <Well className={cx(styles.pageMessage, className)} type={type}>
    {(typeof icon === "string" ? (
      <Icon type={icon} className={styles.icon} />
    ) : (
      icon
    )) || null}
    <div className={styles.text}>
      {message ? <span>{message}</span> : children}
      {action ? (
        <Button
          newTab
          href={link || undefined}
          onClick={action}
          type="primary"
          size="small"
          className={styles.button}
        >
          {actionText}
        </Button>
      ) : null}
    </div>
  </Well>
);
