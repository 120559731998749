// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "BetMessage-module--button--6f572";
export var content = "BetMessage-module--content--512a2";
export var emphasis = "BetMessage-module--emphasis--0c2aa";
export var error = "BetMessage-module--error--95bcd";
export var green = "#57d33b";
export var greenLink = "BetMessage-module--green-link--aa943";
export var grey = "var(--tone-mid)";
export var hidden = "BetMessage-module--hidden--f657f";
export var icon = "BetMessage-module--icon--607f7";
export var info = "BetMessage-module--info--85bd8";
export var message = "BetMessage-module--message--9fa1e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var warning = "BetMessage-module--warning--4d07a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";