import React, { useState } from "react";
import { CurrencyInput, QuickInput } from "components/CurrencyInput";
import type { CurrencyInputProps } from "components/CurrencyInput";
import { useFiatCurrency } from "hooks";

import * as styles from "./StakeAll.module.scss";

type StakeAllProps = {
  value: number;
  onChange: (value: number) => void;
} & Omit<CurrencyInputProps, "onChange" | "value">;

const StakeAll = ({ value, onChange, ...props }: StakeAllProps) => {
  const [hasFocus, setHasFocus] = useState(false);
  const fiatCurrency = useFiatCurrency();

  const handleChange = (value: number) => {
    onChange(value);
  };

  return (
    <div className={styles.quickInputWrapper}>
      <CurrencyInput
        currency={fiatCurrency}
        containerClassName={styles.container}
        wrapperClassName={styles.wrapper}
        onChange={handleChange}
        value={value}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        {...props}
      />
      <QuickInput.Root
        isVisible={hasFocus}
        className={styles.quickInput}
        currencySymbol={`$`}
        size={"sm"}
      >
        <QuickInput.Option
          onChange={(amount) => onChange(amount + value)}
          value={5}
        />
        <QuickInput.Option
          onChange={(amount) => onChange(amount + value)}
          value={10}
        />
        <QuickInput.Option
          onChange={(amount) => onChange(amount + value)}
          value={50}
        />
        <QuickInput.Option
          onChange={(amount) => onChange(amount + value)}
          value={100}
        />
      </QuickInput.Root>
    </div>
  );
};

export { StakeAll };
