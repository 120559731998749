import { Skeleton } from "components/Skeleton";
import React, { useContext } from "react";
import * as styles from "./RaceTabsSkeleton.module.scss";
import { RaceContext } from "sections/Betting/Race/Race";
import CompetitorSkeleton from "sections/Betting/Race/components/Competitor/CompetitorSkeleton";
import { RaceTips } from "sections/Betting/Race/components/RaceTips";
import { RaceFiltersSkeleton } from "sections/Betting/Race/components/RaceFilters/RaceFiltersSkeleton";

export default () => {
  const { route } = useContext(RaceContext);

  return (
    <>
      <div className={styles.raceTabs}>
        <Skeleton shape="rect" size="sm" className={styles.top4} />
        <Skeleton shape="rect" size="sm" className={styles.srm} />
        <Skeleton shape="rect" size="sm" className={styles.winPlace} />
      </div>
      <RaceFiltersSkeleton />

      <RaceTips />

      <CompetitorSkeleton
        displayType={route?.tab === "win-place" ? "Win" : "Top4"}
      />
    </>
  );
};
