// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "NativeSelect-module--container--0a998";
export var emphasis = "NativeSelect-module--emphasis--15e84";
export var green = "#57d33b";
export var greenLink = "NativeSelect-module--green-link--78c34";
export var grey = "var(--tone-mid)";
export var hasError = "NativeSelect-module--hasError--47637";
export var label = "NativeSelect-module--label--4d6aa";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var select = "NativeSelect-module--select--cc4dc";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var wrapper = "NativeSelect-module--wrapper--82bd2";
export var yellow = "#ffb100";