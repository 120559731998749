import provinces from "provinces";
import countries from "country-list";
import { useEffect, useState } from "react";
import type { NullableMaybe } from "types/utilities";

const useCountryAndState = (
  countryCode: NullableMaybe<string>,
  stateCode: NullableMaybe<string>,
) => {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    if (!countryCode) return;

    const country = countries.getName(countryCode);
    if (country) {
      setCountry(country);
    }
  }, [countryCode]);

  useEffect(() => {
    const state = provinces.find((p) => p.short === stateCode);
    if (state) {
      setState(state.name);
    }
  }, [stateCode]);

  return [country ? country : countryCode, state ? state : stateCode];
};

export default useCountryAndState;
