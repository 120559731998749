import React from "react";
import * as styles from "./RaceNumberTab.module.scss";
import {
  type RaceEventType,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";

export type RaceStatusIndicatorType = RaceEventType & {
  currentRaceNumber: number;
};

export const RaceStatusIndicator = ({
  number,
  status,
  currentRaceNumber,
}: RaceStatusIndicatorType) => {
  let indicatorColor: string;

  if (
    status === RaceStatuses.INTERIM ||
    status === RaceStatuses.JUMPED ||
    status === RaceStatuses.CLOSED
  ) {
    indicatorColor = "#ff2741";
  }

  if (number === currentRaceNumber && status === "OPEN") {
    indicatorColor = "#ffb923";
  }

  if (status === RaceStatuses.OPEN && !indicatorColor) {
    indicatorColor = "#444d51";
  }

  if (!indicatorColor) {
    return null;
  }

  return (
    <div
      className={styles.indicator}
      style={{ backgroundColor: indicatorColor }}
    ></div>
  );
};
