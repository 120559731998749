import React, { useState } from "react";
import { omit } from "lodash";
import * as yup from "yup";
import type { Country } from "react-phone-number-input/input-mobile";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "components/Form";
import { Input } from "components/Input";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { PhoneInput } from "components/PhoneInput";
import { DepositLimits } from "components/DepositLimits/DepositLimits";
import { getCurrencyByCountry, getCurrencySymbol } from "common";
import { validateProfile } from "utilities/api/picklebet/auth";
import * as support from "utilities/support";
import { toast } from "hooks/ui/useToast";
import { useDispatch } from "hooks";
import { setModal } from "utilities/UI/uiSlice";

import * as styles from "../Auth.module.scss";
import { Verification } from "./Verification";
import { isValidPhoneNumber } from "utilities/helpers";
import type { StoredFormValues } from "sections/Auth/SignUpForm";

const SOUTH_AUSTRALIA_STATE_CODE = "SA";

type IdentityProps = {
  onComplete: (values: FormValues) => void;
  storedFormValues?: StoredFormValues;
  promotionCode?: string;
};

const schema = yup.object().shape({
  username: yup.string().required("Display name is required"),
  terms: yup
    .bool()
    .required("You must agree with terms and conditions")
    .oneOf([true], "You must agree with terms and conditions"),
  phone: yup
    .string()
    .required("Please enter a mobile number")
    .test("is-phone", "Please enter a valid phone number", (value) => {
      if (!value) return true;
      return isValidPhoneNumber(value);
    }),
  showLimits: yup.boolean(),
  depositLimit: yup.string().when("showLimits", {
    is: true,
    then: (schema) => schema.required("Deposit limit is required"),
  }),
  depositLimitPeriod: yup.string().when("showLimits", {
    is: true,
    then: (schema) => schema.required("Deposit limit period is required"),
  }),
  hasAffiliateCode: yup.boolean().optional(),
  promotionCode: yup.string().optional(),
  optInToMarketingCommunications: yup.boolean(),
});

type FormValues = yup.InferType<typeof schema>;

const Identity = ({
  onComplete,
  storedFormValues,
  promotionCode,
}: IdentityProps) => {
  const isSouthAustralia =
    storedFormValues?.address?.state === SOUTH_AUSTRALIA_STATE_CODE;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      terms: false,
      promotionCode,
      optInToMarketingCommunications: isSouthAustralia ? false : undefined,
    },
  });

  const countryCode = storedFormValues?.address?.country;

  const dispatch = useDispatch();

  const displayAffiliateCodeNotification = (type: "success" | "error") => {
    toast({
      description: `Affiliate Code "${watch("promotionCode")}" ${
        type === "success" ? "has been applied" : `is invalid`
      }`,
      variant: type === "success" ? "success" : "danger",
    });
  };

  const [awaitingVerification, setAwaitingVerification] = useState(false);
  const [validatingPromoCode, setValidatingPromoCode] = useState(false);

  const currencySymbol = getCurrencySymbol(
    getCurrencyByCountry(countryCode.toUpperCase()),
  );

  const showLimits = watch("showLimits", isSouthAustralia ? true : false);
  const hasAffiliateCode = watch("hasAffiliateCode", !!promotionCode?.length);
  const optInToMarketingCommunications = watch(
    "optInToMarketingCommunications",
    false,
  );
  const formValues = watch();

  const isAuOrNz = countryCode === "AU" || countryCode === "NZ";

  if (awaitingVerification) {
    return <Verification />;
  }

  return (
    <Form
      onSubmit={handleSubmit(async (values) => {
        const validation = await validateProfile("", "", values);

        if (validation.length > 0) {
          // if there are server validation errors
          validation.forEach((error) => {
            setError(
              // read error message
              (error.propertyPath.includes("profile")
                ? error.propertyPath.split(".")[1]
                : error.propertyPath) as keyof FormValues,
              {
                type: "manual",
                message: error.message,
              },
            );
          });

          return;
        }

        await onComplete(
          showLimits
            ? values
            : omit(values, ["depositLimit", "depositLimitPeriod"]),
        );

        if (isAuOrNz) {
          // for australian and nz users are going to confirm their verification status
          setAwaitingVerification(true);
        } else {
          // for everyone else, we can just close the modal
          dispatch(setModal(null));
        }
      })}
    >
      <Input
        autoFocus
        label="Display Name"
        maxLength={12}
        placeholder="Enter your display name"
        {...register("username")}
        errorMessage={errors.username?.message}
      />
      <PhoneInput
        countryCode={countryCode as Country}
        name={"phone"}
        control={control}
        label="Mobile Number"
        placeholder="Enter your mobile number"
        errorMessage={errors.phone?.message}
      />
      <Checkbox
        id={"showLimits"}
        label={`I want to set a deposit limit`}
        checked={showLimits}
        onCheckedChange={(checked) => setValue("showLimits", checked === true)}
      />
      {showLimits && (
        <DepositLimits
          currencySymbol={currencySymbol}
          register={register}
          errors={errors}
        />
      )}
      <Checkbox
        id={"hasAffiliateCode"}
        label={`I have an affiliate code`}
        checked={hasAffiliateCode}
        onCheckedChange={(checked) => {
          setValue("hasAffiliateCode", checked === true);
          !checked && unregister("promotionCode");
        }}
      />
      {isSouthAustralia && (
        <Checkbox
          id={"optInToMarketingCommunications"}
          label={
            <span>
              I agree to receive marketing and{" "}
              <span className="green-link">promotional offers</span> 💰
            </span>
          }
          checked={optInToMarketingCommunications}
          onCheckedChange={(checked) => {
            setValue("optInToMarketingCommunications", checked === true);
          }}
        />
      )}
      {hasAffiliateCode && (
        <div className={styles.affiliate}>
          <Input
            autoFocus
            containerClassName={styles.input}
            label="Affiliate Code"
            placeholder="Enter your affiliate code"
            {...register("promotionCode")}
            errorMessage={errors.promotionCode?.message}
          />
          <Button
            disabled={!formValues.promotionCode}
            className={styles.button}
            loading={validatingPromoCode}
            size={"md"}
            type="button"
            variant={"blurred"}
            onClick={async () => {
              setValidatingPromoCode(true);
              const validation = await validateProfile("", "", {
                promotionCode: watch("promotionCode", ""),
              });
              if (validation.length > 0) {
                displayAffiliateCodeNotification("error");
                setValue("promotionCode", "");
              } else {
                displayAffiliateCodeNotification("success");
              }
              setValidatingPromoCode(false);
            }}
          >
            Apply
          </Button>
        </div>
      )}
      <Controller
        control={control}
        name={"terms"}
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              id={"terms"}
              checked={value}
              label={
                <>
                  I have read and agree with the{" "}
                  <Button
                    variant={`link`}
                    onClick={() => support.open({ articleId: "115009926727" })}
                  >
                    Terms and Conditions
                  </Button>{" "}
                  and{" "}
                  <Button
                    variant={`link`}
                    onClick={() => support.open({ articleId: "115010094728" })}
                  >
                    Privacy Policy
                  </Button>
                </>
              }
              hasError={!!errors.terms?.message}
              onCheckedChange={(checked) => onChange(checked === true)}
              {...field}
            />
          );
        }}
      />
      <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
        Done
      </Button>
    </Form>
  );
};

export { Identity };
