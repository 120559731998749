import React from "react";

import cx from "classnames";

import { BadgeCount } from "../BadgeCount";

import type { MessageType } from "library";

import { navigate } from "../Link";

import { Icon } from "library";

import * as styles from "./TabFilterItem.module.scss";

export type TabFilterItemProps = {
  ref?: React.RefObject<HTMLLIElement>;
  label?: string;
  slug?: string;
  link?: string;
  count?: string | number;
  isActive?: boolean;
  isDisabled?: boolean;
  badgeType?: MessageType;
  onClick: (slug: string) => void;
  indicatorColor?: string;
  className?: string;
  customElement?: React.ReactNode;
};

export const TabFilterItem = ({
  ref,
  label,
  slug,
  link,
  count,
  isActive,
  isDisabled,
  badgeType,
  onClick,
  indicatorColor,
  customElement,
  className,
}: TabFilterItemProps) => (
  <li
    ref={ref}
    key={slug}
    className={cx(
      {
        [styles.tab]: true,
        [styles.tabActive]: !!isActive,
        [styles.tabDisabled]: !!isDisabled,
        [styles.showCount]: Boolean(count),
      },
      className,
    )}
    onClick={() => {
      if (link) {
        navigate(link);
      }
      if (onClick) {
        onClick(slug);
      }
    }}
  >
    {indicatorColor && (
      <div
        className={styles.indicator}
        style={{ backgroundColor: indicatorColor }}
      ></div>
    )}
    {customElement}
    <span className={styles.label}>{label}</span>
    {badgeType === "stream" ? (
      <div className={styles.streamButton}>
        <Icon svg type="video" />
      </div>
    ) : count && count !== "0" ? (
      <BadgeCount count={count} type={badgeType ? badgeType : "default"} />
    ) : null}
  </li>
);

export default TabFilterItem;
