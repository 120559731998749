import React from "react";
import classNames from "classnames";
import * as styles from "./Empty.module.scss";

import Icon from "../Icon";
import Button from "../Button/Button";

export const Empty = ({
  icon = undefined as JSX.Element | string,
  message = "" as JSX.Element | string,
  title = "" as string | JSX.Element,
  className = undefined as string,
  buttonText = undefined as string,
  buttonTo = undefined as string,
  buttonClick = undefined as () => void,
  buttons = undefined as JSX.Element | JSX.Element[],
  children = undefined as JSX.Element | JSX.Element[],
  hideIcon = false,
  afterButtonMessage = "" as JSX.Element | string,
}) => {
  // Wrap title if it exists
  if (title) {
    title = <h1>{title}</h1>;
  }

  // Include a default icon
  if (!icon || typeof icon === "string") {
    icon = <Icon type={icon || "warning"} className={styles.icon} />;
  }

  // Merge in custom className
  let button = null;

  if (buttonText && (buttonTo || buttonClick)) {
    button = (
      <Button
        className={styles.button}
        type="success"
        to={buttonTo}
        onClick={buttonClick}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <div
      className={classNames({
        [styles.empty]: true,
        [className]: !!className,
      })}
    >
      {hideIcon ? null : icon}
      {title}
      <div className={styles.message}>{message}</div>
      {button || children}
      {buttons}
      {afterButtonMessage && (
        <div className={styles.message}>{afterButtonMessage}</div>
      )}
    </div>
  );
};

export default Empty;
