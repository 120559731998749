import React from "react";
import cx from "classnames";

import * as styles from "./ContestImage.module.scss";

type ContestImageProps = {
  image?: string | string[];
  className?: string;
};

const ContestImage = ({ image, className }: ContestImageProps) => {
  const images = Array.isArray(image)
    ? image
    : typeof image === "string"
      ? [image]
      : [];

  const pcInset = 20;
  const pcSize = 90 - images.length * pcInset;

  return (
    <div className={cx(styles.contestImage, className)}>
      {images.map((x) => (
        <img
          key={x}
          src={x}
          style={{
            maxWidth: pcSize + "%",
            maxHeight: pcSize + "%",
          }}
        />
      ))}
    </div>
  );
};

export default ContestImage;
