// extracted by mini-css-extract-plugin
export var all = "PickSlip-module--all--9aa21";
export var blue = "#0eb3f2";
export var body = "PickSlip-module--body--c45ce";
export var container = "PickSlip-module--container--1a81a";
export var contestCard = "PickSlip-module--contestCard--6d30b";
export var controls = "PickSlip-module--controls--39651";
export var emphasis = "PickSlip-module--emphasis--9fb3e";
export var empty = "PickSlip-module--empty--2735e";
export var footer = "PickSlip-module--footer--4c8ef";
export var green = "#57d33b";
export var greenLink = "PickSlip-module--green-link--46c3b";
export var grey = "var(--tone-mid)";
export var makePick = "PickSlip-module--makePick--1358b";
export var newRounds = "PickSlip-module--newRounds--28743";
export var notchGap = "32px";
export var notifications = "PickSlip-module--notifications--df987";
export var orange = "#f8662b";
export var pick = "PickSlip-module--pick--b5272";
export var pickemsBody = "PickSlip-module--pickemsBody--29105";
export var red = "#ff2741";
export var remaining = "PickSlip-module--remaining--ee143";
export var round = "PickSlip-module--round--496ab";
export var stats = "PickSlip-module--stats--60df2";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";