import React from "react";
import { Icon } from "library";
import QRCode from "react-qr-code";

import * as styles from "./Footer.module.scss";

const DownloadApp = () => {
  return (
    <div className={styles.download}>
      <div className={styles.qr}>
        <QRCode
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          fgColor={"#737C81"}
          bgColor={"transparent"}
          value={"https://picklebet.onelink.me/8Ie4/309baad5"}
        />
      </div>
      <div className={styles.buttons}>
        <a
          href={`https://play.google.com/store/apps/details?id=com.picklebet`}
          className={styles.button}
        >
          <Icon type={"googlePlay"} />
          <span className={styles.buttonTextAndLabel}>
            <span className={styles.label}>Get it On</span>
            <span className={styles.brand}>Google Play</span>
          </span>
        </a>

        <a
          href={`https://apps.apple.com/au/app/picklebet-esports-betting-app/id1529988128`}
          className={styles.button}
        >
          <Icon type={"appStore"} />
          <span className={styles.buttonTextAndLabel}>
            <span className={styles.label}>Download on the</span>
            <span className={styles.brand}>App Store</span>
          </span>
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
