import React from "react";
import type { AutomationProgression } from "hooks/useUserProgression";
import type { BetEntry } from "sections/Entries/types";
import { BetPickAutomation } from "./BetPickAutomation";

type BetPickPromotionProps = {
  automationProgressions: Pick<AutomationProgression, "progressionId">[];
  isWon: boolean;
  entry: BetEntry;
};

export const BetPickPromotion = ({
  automationProgressions,
  isWon,
  entry,
}: BetPickPromotionProps) => {
  return (
    <>
      {automationProgressions.map(({ progressionId }) => (
        <BetPickAutomation
          key={`automation-${progressionId}`}
          isWon={isWon}
          entry={entry}
          progressionId={progressionId}
        />
      ))}
    </>
  );
};
