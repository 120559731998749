import React from "react";
import { useSelection, useSelectionAlert } from "../hooks/useSelection";
import { Button } from "components/Button";
import { Alert } from "components/Alert";

import * as styles from "./OutcomeAlert.module.scss";

type OutcomeAlertProps = {
  outcomeId: string;
};

const OutcomeAlert = ({ outcomeId }: OutcomeAlertProps) => {
  const selection = useSelection(outcomeId);
  const alert = useSelectionAlert(selection);

  if (!alert) {
    return null;
  }

  return (
    <Alert
      className={styles.alert}
      contentClassName={styles.content}
      variant={alert.variant}
      actions={
        alert.cta ? (
          <Button
            className={styles.button}
            size={`sm`}
            variant={alert.variant as any}
            onClick={alert.cta?.action}
          >
            {alert.cta?.label}
          </Button>
        ) : null
      }
    >
      {alert?.content}
    </Alert>
  );
};

export { OutcomeAlert };
