import type { ITitle } from "types/ContentTypes";
import React from "react";

/**
 * Need to use the Context API here to pass the titleMap we get from gatsby/contentful down to the markdown driven components that are only passed ids in the markdown.
 * At the point they are invoked they dont know what title the event id is for. So we give them a map of title slug to title info in the context so they
 * can look up the the title info
 */
export const TitlesContext = React.createContext<Record<string, ITitle>>(
  {}, // default value
);
