import React from "react";
import { HUDPanelNotification, navigate } from "library";
import { getRoute, routeParam } from "utilities/route";
import { PickSlipNoContest, PickSlipContest } from "./";
import { notifications } from "./PickSlip.module.scss";

export const getUnauthorisedMessageElement = (
  isLoggedIn: boolean,
  profileComplete: boolean,
  permission: string,
): JSX.Element | null => {
  const { message, action, actionText, icon } =
    getUnauthorisedMessage(isLoggedIn, profileComplete, permission) || {};

  if (message) {
    return (
      <div key="notifications" className={notifications}>
        <HUDPanelNotification
          key="unauthorised"
          icon={icon || "error"}
          type="error"
          text={message}
          action={action}
          actionText={actionText}
        />
      </div>
    );
  } else {
    return null;
  }
};

type UnauthorisedMessage = {
  message: string | JSX.Element;
  action?: () => void;
  actionText?: string;
  icon?: string;
};

export const getUnauthorisedMessage = (
  isLoggedIn,
  profileComplete,
  permission,
): UnauthorisedMessage => {
  if (!isLoggedIn) {
    return null;
  }

  let message;
  let action;
  let actionText;
  let icon;

  switch (permission) {
    case "GRANTED":
      return null;
    case "RESIDENTIAL_LOCATION_UNSUPPORTED":
      message = "Pickems coming soon to your region";
      break;
    case "PHYSICAL_LOCATION_UNSUPPORTED":
      message = "Pickems coming soon to your region";
      break;
    case "VERIFICATION_STATUS_UNSUPPORTED":
      message = "Your account requires verification";
      action = () => navigate("/account/verification/");
      actionText = "Verify";
      break;
    case "ACCOUNT_REOPEN_REQUIRED":
      message = "Your account requires reopening";
      action = () => navigate("/account/reopen/");
      actionText = "Reopen";
      icon = "lock";
      break;
    default:
      message = "Sorry you are unable to play Pickems at this time";
  }

  return {
    message,
    action,
    actionText,
    icon,
  };
};

const PickSlip = () => {
  const contestParam = routeParam(getRoute(), "contest", null);

  if (contestParam) {
    return <PickSlipContest />;
  } else {
    return <PickSlipNoContest />;
  }
};

export default PickSlip;
