// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var content = "Tabs-module--content--7b47d";
export var emphasis = "Tabs-module--emphasis--51cdc";
export var green = "#57d33b";
export var greenLink = "Tabs-module--green-link--7d474";
export var grey = "var(--tone-mid)";
export var list = "Tabs-module--list--9cc24";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var tabContainer = "Tabs-module--tabContainer--c8dfc";
export var trigger = "Tabs-module--trigger--1312d";
export var underline = "Tabs-module--underline--1694d";
export var underlineContainer = "Tabs-module--underlineContainer--53adc";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";