const config = {};

config.LOCALES = ["en-AU", "en-US", "en-CA"];

config.DEFAULT_LOCALE = "en-US";

config.LOCALE_KEY = "picklebet-language";

config.ATTRIBUTION_KEY = "picklebet-attribution";

config.BANNER = {
  displaySeconds: 3,
  sizeDesktop: [1000, 230],
  sizeMobile: [750, 340],
};

config.PLATFORM =
  (typeof PickleEnv !== "undefined" && PickleEnv.PLATFORM) ||
  (typeof process !== "undefined" && process.env.GATSBY_PLATFORM) ||
  "WEB";

config.ENV =
  (typeof PickleEnv !== "undefined" && PickleEnv.ENV) ||
  (typeof process !== "undefined" &&
    (process.env.GATSBY_SKRILLA_ENV || process.env.NODE_ENV)) ||
  "development";

config.ISLOCALHOST =
  typeof window !== "undefined" &&
  window.location &&
  window.location.host.indexOf("localhost") >= 0;

config.DOMAIN =
  (typeof PickleEnv !== "undefined" && PickleEnv.DOMAIN) ||
  (typeof process !== "undefined" && process.env.GATSBY_DOMAIN) ||
  // support for local dev
  (typeof location !== "undefined" &&
    location.hostname === "localhost" &&
    `${window.location.protocol}//${window.location.host}`) ||
  (config.ENV === "production" && "picklebet.com") ||
  (config.ENV === "staging" && "staging.picklebet.com") ||
  (config.ENV === "development" && "skrilla-staging-master.firebaseapp.com") ||
  "skrilla-" + config.ENV + ".firebaseapp.com";

if (config.DOMAIN.startsWith("http") === false) {
  config.DOMAIN = "https://" + config.DOMAIN;
}

config.VERSION =
  (typeof PickleEnv !== "undefined" && PickleEnv.VERSION) ||
  (typeof VERSION !== "undefined" && VERSION) ||
  "development";

config.FIREBASE_KEY = process.env.GATSBY_FIREBASE_KEY;

config.DYNAMIC_LINKS = {
  key: process.env.GATSBY_DYNAMIC_LINK_KEY,
  domain: process.env.GATSBY_DYNAMIC_LINK_DOMAIN,
};

config.PICKLEBET_DOMAINS = [
  "https://staging.picklebet.com",
  "https://picklebet.com",
];

module.exports = Object.freeze(config);
