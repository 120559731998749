import React from "react";
import { default as Icon } from "../Icon";
import { isWebView } from "utilities/display";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "utilities/AppWebMessagingBridge";
import { ReactComponent as PageErrorIcon } from "components/assets/page-error.svg";
import * as styles from "./FullScreenError.module.scss";
import { Typography } from "components/Typography";
import { ReactComponent as IconHome } from "components/assets/home-icon.svg";
import { ReactComponent as IconReload } from "components/assets/reload-icon.svg";
import { IconButton } from "components/IconButton";

const FullScreenError = ({ title, message }) => {
  const reloadPage = () => {
    if (isWebView()) {
      sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_RELOAD, {});
    } else {
      location.reload();
    }
  };

  const goHome = () => {
    location.href = "/";
  };

  return (
    <div className={styles.errorPage}>
      <header className={styles.logo} onClick={goHome}>
        <Icon className={`${styles.logo} logo`} type="picklebet" title="Home" />
      </header>

      <section>
        <div>
          <PageErrorIcon className={styles.img} />
          <Typography className={styles.centered} variant="h2">
            {title}
          </Typography>
          <Typography className={styles.centered}>{message}</Typography>

          <div className={styles.actionButtons}>
            <IconButton label="Reload" onClick={reloadPage}>
              <IconReload />
            </IconButton>
            <IconButton label="Go Home" variant="secondary" onClick={goHome}>
              <IconHome />
            </IconButton>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FullScreenError;
