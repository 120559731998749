import React from "react";
import * as styles from "./Promotions.module.scss";
import { InfoPanel } from "components/InfoPanel";
import { ReactComponent as PromoIcon } from "components/assets/promo-icon-1-color.svg";
import {
  type PromotionWithoutCurrency,
  defaultHelpLink,
} from "./CampaignPromotions";

export const Promotion = ({
  promotion,
}: {
  promotion: PromotionWithoutCurrency;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <InfoPanel
      title={promotion.name}
      open={open}
      onOpen={setOpen}
      variant="promo"
      icon={<PromoIcon />}
      className={styles.promoContainer}
    >
      <div className={styles.content}>
        {promotion.description}{" "}
        <a href={promotion.termsOfUse ?? defaultHelpLink}>View Terms</a>
      </div>
    </InfoPanel>
  );
};
