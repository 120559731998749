// extracted by mini-css-extract-plugin
export var actions = "Alert-module--actions--143ab";
export var alert = "Alert-module--alert--100bf";
export var blue = "#0eb3f2";
export var bottomIndicator = "Alert-module--bottomIndicator--20db2";
export var content = "Alert-module--content--624fb";
export var danger = "Alert-module--danger--616db";
export var emphasis = "Alert-module--emphasis--b5af6";
export var green = "#57d33b";
export var greenLink = "Alert-module--green-link--ae60f";
export var grey = "var(--tone-mid)";
export var icon = "Alert-module--icon--4291a";
export var iconWithContent = "Alert-module--iconWithContent--17b86";
export var info = "Alert-module--info--b7e3e";
export var large = "Alert-module--large--cf9d5";
export var neutral = "Alert-module--neutral--5e086";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var small = "Alert-module--small--e134d";
export var success = "Alert-module--success--f0c92";
export var title = "Alert-module--title--1d65e";
export var topAlign = "Alert-module--topAlign--41d82";
export var topIndicator = "Alert-module--topIndicator--7b63a";
export var warning = "Alert-module--warning--f131b";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";