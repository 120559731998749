exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-activity-statement-tsx": () => import("./../../../src/templates/activity-statement.tsx" /* webpackChunkName: "component---src-templates-activity-statement-tsx" */),
  "component---src-templates-betting-jsx": () => import("./../../../src/templates/betting.jsx" /* webpackChunkName: "component---src-templates-betting-jsx" */),
  "component---src-templates-entries-jsx": () => import("./../../../src/templates/entries.jsx" /* webpackChunkName: "component---src-templates-entries-jsx" */),
  "component---src-templates-forgot-password-tsx": () => import("./../../../src/templates/forgot-password.tsx" /* webpackChunkName: "component---src-templates-forgot-password-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-ios-stream-player-tsx": () => import("./../../../src/templates/ios-stream-player.tsx" /* webpackChunkName: "component---src-templates-ios-stream-player-tsx" */),
  "component---src-templates-landing-page-jsx": () => import("./../../../src/templates/landing-page.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */),
  "component---src-templates-landing-page-new-tsx": () => import("./../../../src/templates/landing-page-new.tsx" /* webpackChunkName: "component---src-templates-landing-page-new-tsx" */),
  "component---src-templates-payment-iq-tsx": () => import("./../../../src/templates/payment-iq.tsx" /* webpackChunkName: "component---src-templates-payment-iq-tsx" */),
  "component---src-templates-perks-tsx": () => import("./../../../src/templates/perks.tsx" /* webpackChunkName: "component---src-templates-perks-tsx" */),
  "component---src-templates-pickems-contest-tsx": () => import("./../../../src/templates/pickems-contest.tsx" /* webpackChunkName: "component---src-templates-pickems-contest-tsx" */),
  "component---src-templates-pickems-tsx": () => import("./../../../src/templates/pickems.tsx" /* webpackChunkName: "component---src-templates-pickems-tsx" */),
  "component---src-templates-preferences-tsx": () => import("./../../../src/templates/preferences.tsx" /* webpackChunkName: "component---src-templates-preferences-tsx" */),
  "component---src-templates-process-mw-tsx": () => import("./../../../src/templates/process-mw.tsx" /* webpackChunkName: "component---src-templates-process-mw-tsx" */),
  "component---src-templates-promotions-jsx": () => import("./../../../src/templates/promotions.jsx" /* webpackChunkName: "component---src-templates-promotions-jsx" */),
  "component---src-templates-share-tsx": () => import("./../../../src/templates/share.tsx" /* webpackChunkName: "component---src-templates-share-tsx" */),
  "component---src-templates-unsubscribed-jsx": () => import("./../../../src/templates/unsubscribed.jsx" /* webpackChunkName: "component---src-templates-unsubscribed-jsx" */),
  "component---src-templates-verification-tsx": () => import("./../../../src/templates/verification.tsx" /* webpackChunkName: "component---src-templates-verification-tsx" */),
  "component---src-templates-wallet-tsx": () => import("./../../../src/templates/wallet.tsx" /* webpackChunkName: "component---src-templates-wallet-tsx" */)
}

