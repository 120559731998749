import React from "react";
import { useBetslip, useSelector } from "hooks";
import { Empty } from "./Empty";
import { BetCard } from "./BetCard";
import { Combinations } from "./Combinations";
import { selectPossibleCombinations } from "../betslipSlice";
import { PermissionsGate } from "components/layout/PermissionsGate";

const Multi = () => {
  const {
    props: {
      selections,
      multiStake,
      betslipIsActive,
      betslipIsReviewingMessages,
    },
  } = useBetslip();

  const combinations = useSelector(selectPossibleCombinations);
  const shouldHideMultiBet =
    multiStake === 0 && (!betslipIsActive || betslipIsReviewingMessages);

  if (!selections.length) {
    return <Empty />;
  }

  return (
    <>
      {shouldHideMultiBet ? null : <BetCard outcomeIds={selections} />}
      {combinations.length > 0 && (
        <PermissionsGate permissions={["submitComboMultiBet"]}>
          <Combinations />
        </PermissionsGate>
      )}
    </>
  );
};

export { Multi };
