// --
// Get User Data
// Async function run on state change to preload user data.

import localObject from "utilities/localObject";

const KEY_PERSISTENT_DEVICE_ID = "pb-dvc";

/**
 * Get existing deviceId or generate a new one if required.
 *
 * We use our own persistent device id. The built-in mixpanel device Id is cleared on
 * reset and new one generated. We want to retain the device id
 * across sessions, so we can track when multiple users are sharing the
 * same device. This is indicative of a fraudulent user.
 */
export function getDeviceId(): string {
  let [deviceId] = localObject.getValue<string>(KEY_PERSISTENT_DEVICE_ID);
  if (!deviceId) {
    deviceId =
      KEY_PERSISTENT_DEVICE_ID +
      "_" +
      new Date().getTime() +
      "_" +
      (Math.random() + "").substring(2);
    localObject.setValue(KEY_PERSISTENT_DEVICE_ID, deviceId);
  }

  return deviceId;
}
