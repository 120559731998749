import React, { type ReactNode, type FormHTMLAttributes } from "react";
import cx from "classnames";
import * as styles from "./Form.module.scss";

type FormProps = FormHTMLAttributes<HTMLFormElement> & {
  children: ReactNode;
  noSpacing?: boolean;
  withGap?: boolean;
  className?: string;
  fixedWidth?: boolean;
};

const Form = ({
  children,
  noSpacing = false,
  withGap = false,
  fixedWidth = false,
  className,
  ...rest
}: FormProps) => {
  return (
    <form
      className={cx(
        styles.form,
        {
          [styles.noSpacing]: noSpacing,
          [styles.withGap]: withGap,
          [styles.fixedWidth]: fixedWidth,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </form>
  );
};

Form.displayName = "Form";

export { Form };
