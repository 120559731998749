import eventWithProperties from "./helpers/eventWithProperties";
import { AUTH_LOGGED_OUT_SUCCESS } from "utilities/User/Constants";
import {
  requestStream,
  streamHadError,
  messageTimedOut,
  duplicateBetAttempted,
  appCrashed,
} from "utilities/UI/uiSlice";
import {
  UPLOAD_AVATAR_ATTEMPT,
  UPDATE_PASSWORD_ATTEMPT,
  SUBMIT_VERIFICATION_ATTEMPT,
  CHANGE_NOTIFICATION_SETTINGS,
  SUCCESS_RESENT,
  UNSUBSCRIBE,
} from "sections/Account/accountSlice";
import {
  addCashCardAttempt,
  addPicklebetCardAttempt,
  removeCashCardAttempt,
  removePicklebetCardAttempt,
  depositFundsAttempt,
  depositFundsFailure,
  firstTimeDeposit,
  attemptRemoveBankAccount,
  withdrawCashAttempt,
  addedBankDetails,
  buySkrAttempt,
  attemptRegisterAddress,
  cancelWithdrawal,
} from "sections/Wallet/walletSlice";
import {
  BET_ADDED_TO_SLIP,
  BET_SUBMITTED,
  MATCH_PAGE_VISITED,
} from "sections/Betting/BettingConfig";
import { ENTRY_SUBMITTED, ENTRY_UPDATED } from "sections/Pickems/PickemsConfig";
import {
  trackLogin,
  trackSignUp,
  trackCompletedAddress,
  trackCompletedDetails,
  trackRequestToken,
  trackResetPassword,
  trackAttemptedVerification,
} from "utilities/Auth/authSlice";
import {
  sharedEntryTracking,
  viewedSharedEntry,
} from "sections/Betting/Betslip/betslipSlice";

const mapper = (action: any) => {
  switch (action.type) {
    case trackLogin.type:
    case trackSignUp.type:
    case trackCompletedAddress.type:
    case trackCompletedDetails.type:
    case trackRequestToken.type:
    case trackResetPassword.type:
    case AUTH_LOGGED_OUT_SUCCESS:
    case UPLOAD_AVATAR_ATTEMPT:
    case UPDATE_PASSWORD_ATTEMPT:
    case SUBMIT_VERIFICATION_ATTEMPT:
    case CHANGE_NOTIFICATION_SETTINGS:
    case SUCCESS_RESENT:
    case BET_SUBMITTED:
    case UNSUBSCRIBE:
    case ENTRY_UPDATED:
    case ENTRY_SUBMITTED:
    case requestStream.type:
    case streamHadError.type:
    case duplicateBetAttempted.type:
    case messageTimedOut.type:
    case appCrashed.type:
    case addCashCardAttempt.type:
    case removeCashCardAttempt.type:
    case depositFundsAttempt.type:
    case depositFundsFailure.type:
    case firstTimeDeposit.type:
    case attemptRemoveBankAccount.type:
    case withdrawCashAttempt.type:
    case addPicklebetCardAttempt.type:
    case removePicklebetCardAttempt.type:
    case buySkrAttempt.type:
    case attemptRegisterAddress.type:
    case cancelWithdrawal.type:
    case addedBankDetails.type:
    case sharedEntryTracking.type:
    case viewedSharedEntry.type:
    case BET_ADDED_TO_SLIP:
    case MATCH_PAGE_VISITED:
    case trackAttemptedVerification.type:
      return [eventWithProperties];
    default:
      return () => {
        // do nothing.
      };
  }
};

export default mapper;
