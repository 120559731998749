// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var countdown = "Countdown-module--countdown--cc592";
export var eminent = "Countdown-module--eminent--6683b";
export var emphasis = "Countdown-module--emphasis--1ad9f";
export var expiring = "Countdown-module--expiring--8eb12";
export var flashTimer = "Countdown-module--flash-timer--84409";
export var green = "#57d33b";
export var greenLink = "Countdown-module--green-link--f681d";
export var grey = "var(--tone-mid)";
export var live = "Countdown-module--live--228b7";
export var mouseover = "Countdown-module--mouseover--94269";
export var notchGap = "32px";
export var orange = "#f8662b";
export var prefix = "Countdown-module--prefix--bdaa7";
export var red = "#ff2741";
export var soon = "Countdown-module--soon--65a93";
export var suffix = "Countdown-module--suffix--9e2f0";
export var ticker = "Countdown-module--ticker--c1230";
export var time = "Countdown-module--time--58125";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";