import {
  type FormAttributesTypes,
  type RaceCompetitorType,
  type RaceEventType,
  type RaceMarketsType,
  RaceMarketTypeTypes,
  type RaceOutcomeType,
  RaceOutcomeTypeTypes,
  RaceStatuses,
  type ExoticMarketType,
} from "sections/Betting/Race/hooks/RacingTypes";

import type {
  Event,
  Outcome as OutcomeType,
  Competitor as CompetitorType,
} from "hooks/firestore/betting/useBetting";
import { differenceInMinutes, isSameDay } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import type { MappedCompetitorsType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import type { RaceSportTypes } from "sections/Betting/Race/hooks/useRacingEvents";

export const sameRaceMultiLabelMap = {
  [RaceOutcomeTypeTypes.Win]: "Win",
  [RaceOutcomeTypeTypes.Top2]: "Top 2",
  [RaceOutcomeTypeTypes.Top3]: "Top 3",
  [RaceOutcomeTypeTypes.Top4]: "Top 4",
};

export const raceMarketLabels = {
  [RaceMarketTypeTypes.Win]: "WIN",
  [RaceMarketTypeTypes.Top2]: "TOP 2",
  [RaceMarketTypeTypes.Top3]: "TOP 3",
  [RaceMarketTypeTypes.Top4]: "TOP 4",
  [RaceMarketTypeTypes.Place]: "PLACE",
};

export const getValidCompetitorCount = (competitors: RaceCompetitorType[]) =>
  competitors?.filter((x) => !x.scratched).length;

export const getFormAttributes = (
  competitor: RaceCompetitorType,
  formType: FormAttributesTypes,
) => competitor?.formAttributes?.[formType];

export const getOutcome = (
  competitorId: string,
  type: RaceMarketTypeTypes | ExoticMarketType,
  markets: RaceMarketsType[],
  includeInactive = false,
): {
  outcomeId: string;
  outcome: Partial<RaceOutcomeType>;
  marketId: string;
  type?: RaceMarketTypeTypes | ExoticMarketType;
  promotionIsAvailable?: boolean;
} => {
  let resolvedType = type;
  const emptyOutcome = {
    outcomeId: "",
    outcome: { odds: "0", name: "unavailable", active: false },
    marketId: "",
  };
  let market = markets?.find(
    (x) =>
      x.marketType === resolvedType &&
      (includeInactive || x.status === "ACTIVE"),
  );
  if (!market) {
    resolvedType = (type + "_SP") as RaceMarketTypeTypes;
    market = markets?.find(
      (x) =>
        x.marketType === resolvedType &&
        (includeInactive || x.status === "ACTIVE"),
    );
    if (!market) {
      return emptyOutcome;
    }
  }
  if (market?.outcomes) {
    const outcome = Object.entries(market.outcomes || {})
      .sort((a, b) => (a[1].type > b[1].type ? 1 : -1))
      .find(([_, value]) => {
        if (value.competitorId === competitorId && value.active) {
          return value;
        }
      });

    return outcome
      ? {
          outcomeId: outcome[0],
          outcome: outcome[1],
          marketId: market.id,
          type: resolvedType,
          promotionIsAvailable: market.promotionIsAvailable,
        }
      : emptyOutcome;
  }
  return emptyOutcome;
};

export const mapCompetitors = (
  competitors: RaceCompetitorType[],
  markets: RaceMarketsType[],
  marketsRequired: Array<RaceMarketTypeTypes | ExoticMarketType>,
  showResulted = false,
) => {
  return competitors.map((competitor) => {
    const competitorMarkets = marketsRequired.reduce((acc, marketType) => {
      const market = getOutcome(
        competitor.id,
        marketType,
        markets,
        showResulted,
      );
      acc[marketType] = { ...market, marketType };

      return acc;
    }, {});

    return {
      ...competitor,
      markets: competitorMarkets,
    } as RaceCompetitorType & {
      markets: Record<
        string,
        {
          outcomeId: string;
          outcome: Partial<RaceOutcomeType>;
          marketId: string;
          marketType: RaceMarketTypeTypes;
          promotionIsAvailable?: boolean;
        }
      >;
    };
  });
};

export const hasSPMarkets = (mappedCompetitors: MappedCompetitorsType) =>
  mappedCompetitors.some(
    (competitor) =>
      typeof Object.values(competitor.markets).find(
        ({ outcome }) =>
          outcome.type === RaceOutcomeTypeTypes.Win &&
          Number(outcome.odds) === 1,
      ) !== "undefined",
  );

export const mapRaceToEvent = ({
  event,
  competitors,
  markets,
}: {
  event: RaceEventType;
  competitors: RaceCompetitorType[];
  markets: RaceMarketsType[];
}) => {
  const mapRaceCompetitorToEventCompetitor = (): Record<
    string,
    CompetitorType
  > =>
    competitors.reduce(
      (acc, value) => {
        if (value) {
          acc[value.id] = {
            abbreviation: value.number.toString(),
            name: value.name,
            type: "RACER",
            number: Number(value.number),
            silksUrl: event?.silksUrl,
          };
        }
        return acc;
      },
      {} as Record<string, CompetitorType>,
    );

  const mapRaceOutcomeToEventOutcome = (
    outcomes: Record<string, RaceOutcomeType>,
    marketType: RaceMarketTypeTypes | ExoticMarketType,
    sport: RaceSportTypes,
  ): Record<string, OutcomeType> => {
    return Object.keys(outcomes).reduce(
      (acc, key) => {
        const value = outcomes[key];
        const competitor = competitors?.find(
          (x) => x.id === value.competitorId,
        );

        // Determine the starting position for the competitor.
        // If the sport is horse racing and the competitor's starting position is provided, format and include it.
        // Otherwise, leave the starting position as an empty string.
        const startingPosition =
          competitor?.startingPosition && sport !== "GREYHOUNDS"
            ? ` (${formatStartingPosition(competitor?.startingPosition)})`
            : ``;

        if (marketType === "CUSTOM") {
          acc[key] = {
            name: value.name,
            active: value.active,
            odds: Number(value.odds ?? 0),
            competitorId: value.competitorId,
            result: value.result,
            abbreviation: value?.name?.substring(0, 3),
            type: "CUSTOM",
          };
          return acc;
        }

        if (value) {
          acc[key] = {
            name: `${competitor?.number}. ${competitor?.name}${startingPosition}`,
            active: value.result != undefined,
            odds: Number(value.odds ?? 0),
            competitorId: value.competitorId,
            result: null,
            abbreviation: value?.name?.substring(0, 3),
            silksUrl: event?.silksUrl,
            number: Number(competitor?.number),
          };
        }

        return acc;
      },
      {} as Record<string, OutcomeType>,
    );
  };

  return {
    eventId: event.id,
    filters: event.filters,
    competitors: mapRaceCompetitorToEventCompetitor(),
    eventName: `${event.venue} - R${event.number}`,
    eventType: "RACE",
    parentEventName: event.venue,
    sport: event.sport,
    activeMarketCount: 0,
    attributes: {},
    scheduledStartTime: event.scheduledStartTime,
    nextBetStop: event.scheduledStartTime,
    status: event.status,
    hub: "racing",
    markets: markets.map((m) => ({
      id: m.id,
      bettingType: "Prematch",
      marketType: m.marketType,
      name: m.name,
      status: event.status,
      outcomes: mapRaceOutcomeToEventOutcome(
        m.outcomes,
        m.marketType,
        event.sport,
      ),
      sourceType: "race",
      promotional: false,
      specifiers: null,
    })),
  } as Event;
};

export const sortRacesByStatus = (
  a: Partial<RaceEventType>,
  b: Partial<RaceEventType>,
) => {
  if (a.status === b.status && a.status === RaceStatuses.OPEN) {
    if (a.scheduledStartTime > b.scheduledStartTime) {
      return 1;
    }
    if (a.scheduledStartTime < b.scheduledStartTime) {
      return -1;
    }

    return 0;
  }

  if (a.status === RaceStatuses.OPEN && b.status !== RaceStatuses.OPEN) {
    return -1;
  }
  if (b.status === RaceStatuses.OPEN && a.status !== RaceStatuses.OPEN) {
    return 1;
  }
  if (a.status === RaceStatuses.JUMPED && b.status !== RaceStatuses.JUMPED) {
    return -1;
  }
  if (b.status === RaceStatuses.JUMPED && a.status !== RaceStatuses.JUMPED) {
    return 1;
  }
  if (a.status === RaceStatuses.INTERIM && b.status !== RaceStatuses.INTERIM) {
    return -1;
  }
  if (b.status === RaceStatuses.INTERIM && a.status !== RaceStatuses.INTERIM) {
    return 1;
  }
  if (a.status === RaceStatuses.PROTEST && b.status !== RaceStatuses.PROTEST) {
    return -1;
  }
  if (b.status === RaceStatuses.PROTEST && a.status !== RaceStatuses.PROTEST) {
    return 1;
  }
  if (a.status === RaceStatuses.FINAL && b.status !== RaceStatuses.FINAL) {
    return -1;
  }
  if (b.status === RaceStatuses.FINAL && a.status !== RaceStatuses.FINAL) {
    return 1;
  }
  if (
    a.status === RaceStatuses.ABANDONED &&
    b.status !== RaceStatuses.ABANDONED
  ) {
    return 1;
  }
  if (
    b.status === RaceStatuses.ABANDONED &&
    a.status !== RaceStatuses.ABANDONED
  ) {
    return -1;
  }
  return 0;
};

const trackTypeRanking = {
  Metropolitan: 1,
  Provincial: 2,
  Country: 3,
};

const countryRanking = {
  AU: 1,
  NZ: 2,
  HK: 3,
  default: 2,
};

export const sortRaces = (
  a: Partial<RaceEventType>,
  b: Partial<RaceEventType>,
) => {
  // Bypass Track Rating sorting when international meeting
  if (a.region === "ROW" && b.region === "ROW") {
    if (a.country === "HK" && b.country !== "HK") {
      return -1;
    }

    if (a.country !== "HK" && b.country === "HK") {
      return 1;
    }

    return sortRacesByStatus(
      {
        scheduledStartTime: a.scheduledStartTime,
        status: a.status,
      },
      {
        scheduledStartTime: b.scheduledStartTime,
        status: b.status,
      },
    );
  }

  if (a.region === "ROW" && b.region !== "ROW") {
    return 1;
  }

  if (a.region !== "ROW" && b.region === "ROW") {
    return -1;
  }

  if (a.status === RaceStatuses.OPEN && b.status !== RaceStatuses.OPEN) {
    return -1;
  }

  if (a.status !== RaceStatuses.OPEN && b.status === RaceStatuses.OPEN) {
    return 1;
  }

  if (countryRanking[a.country] > countryRanking[b.country]) {
    return 1;
  }
  if (countryRanking[a.country] < countryRanking[b.country]) {
    return -1;
  }

  if (trackTypeRanking[a.trackType] > trackTypeRanking[b.trackType]) {
    return 1;
  }
  if (trackTypeRanking[a.trackType] < trackTypeRanking[b.trackType]) {
    return -1;
  }

  return sortRacesByStatus(
    {
      scheduledStartTime: a.scheduledStartTime,
      status: a.status,
    },
    {
      scheduledStartTime: b.scheduledStartTime,
      status: b.status,
    },
  );
  return 0;
};

export const formatStartingPosition = (startingPosition: string) => {
  if (!startingPosition || startingPosition === "0") {
    return "";
  }
  return `${startingPosition}`;
};

export const getTimezoneFrom2LetterCountryCode = (
  countryCode: string,
  stateCode: string,
) => {
  switch (countryCode) {
    case "AU":
      switch (stateCode) {
        case "NSW":
          return "Australia/Sydney";
        case "VIC":
          return "Australia/Melbourne";
        case "QLD":
          return "Australia/Brisbane";
        case "SA":
          return "Australia/Adelaide";
        case "WA":
          return "Australia/Perth";
        case "TAS":
          return "Australia/Hobart";
        case "NT":
          return "Australia/Darwin";
        default:
          return "Australia/Sydney";
      }
    case "NZ":
      return "Pacific/Auckland";
    case "GB":
    case "UK":
      return "Europe/London";
    case "IE":
      return "Europe/Dublin";
    case "ZA":
      return "Africa/Johannesburg";
    case "US":
      return "America/New_York";
    case "CA":
      return "America/Toronto";
    case "SG":
      return "Asia/Singapore";
    case "JP":
      return "Asia/Tokyo";
    case "KR":
      return "Asia/Seoul";
    case "HK":
      return "Asia/Hong_Kong";
    case "FR":
      return "Europe/Paris";
    case "ES":
      return "Europe/Madrid";
    case "BR":
      return "America/Sao_Paulo";
    case "AR":
      return "America/Argentina/Buenos_Aires";
    case "IN":
      return "Asia/Kolkata";
    default:
      return "Australia/Sydney";
  }
};

export const getIsSRMAvailable = ({
  scheduledStartTime,
  raceTimezone,
  sport,
  semAvailableAtTs,
}: {
  scheduledStartTime: Date;
  raceTimezone: string;
  sport: RaceSportTypes;
  semAvailableAtTs?: Timestamp;
}) => {
  if (semAvailableAtTs) {
    return semAvailableAtTs.toMillis() < Date.now();
  } else {
    const currentHourInTimeZone = Number(
      new Date().toLocaleString("en-AU", {
        hour: "numeric",
        hour12: false,
        timeZone: raceTimezone,
      }),
    );

    if (
      ["GREYHOUNDS", "HARNESS_RACING"].includes(sport) &&
      differenceInMinutes(scheduledStartTime, new Date()) < 20
    ) {
      return true;
    } else if (["GREYHOUNDS", "HARNESS_RACING"].includes(sport)) {
      return false;
    } else if (
      raceTimezone?.includes("Australia") &&
      (!isSameDay(scheduledStartTime, new Date()) || currentHourInTimeZone < 9)
    ) {
      return false;
    } else if (
      !raceTimezone?.includes("Australia") &&
      differenceInMinutes(scheduledStartTime, new Date()) > 60
    ) {
      return false;
    }

    return true;
  }
};

export const getJockeyLabel = (jockey?: string, sport?: RaceSportTypes) =>
  sport && jockey
    ? sport === "GREYHOUNDS"
      ? null
      : `${sport === "HARNESS_RACING" ? `D` : `J`}: ${jockey}`
    : "";

export const toTitleCaseWithExclusions = (input: string): string => {
  const shouldPreserveCase = (word: string): boolean => {
    return (
      word.length <= 2 && !commonTwoLetterWords.includes(word.toLowerCase())
    );
  };

  const isCommon2LetterWord = (word: string): boolean => {
    return (
      word.length <= 2 && commonTwoLetterWords.includes(word.toLowerCase())
    );
  };

  // Function to determine if a word is a domain name
  const isDomainName = (word: string): boolean => {
    return word.includes(".") && word.split(".").length > 1;
  };

  const isBenchMark = (word: string): boolean => {
    return /BM\d+/i.test(word);
  };

  const titleCased = input
    .split(" ")
    .map((word) => {
      if (word.includes("&")) {
        return word.toUpperCase();
      } else if (isBenchMark(word)) {
        return word.toUpperCase();
      } else if (racingAcronyms.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else if (isDomainName(word)) {
        return word.toLowerCase();
      } else if (isCommon2LetterWord(word)) {
        return word.toLowerCase();
      } else if (shouldPreserveCase(word)) {
        return word.toLowerCase();
      } else {
        // Title case the word, ensuring the first letter is uppercase and the rest are lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(" ");

  return titleCased;
};

const commonTwoLetterWords = [
  "if",
  "or",
  "an",
  "up",
  "at",
  "by",
  "in",
  "of",
  "on",
  "to",
  "as",
  "is",
  "am",
  "be",
  "do",
  "go",
  "he",
  "it",
  "me",
  "my",
  "no",
  "ok",
  "ow",
  "us",
  "we",
  "yo",
];

const racingAcronyms = [
  "EBF", // European Breeders Fund
  "MDN", // Maiden
  "HCP", // Handicap
  "G1",
  "G2",
  "G3", // Group 1, Group 2, Group 3 races
  "LR", // Listed Race
  "ALW", // Allowance
  "CLM", // Claiming
  "STK", // Stakes
  "GRD", // Graded
  "NW1",
  "NW2",
  "NW3", // Non-winners of one, two, three races other than maiden, claiming, or starter
  "TURF", // Turf course
  "DIRT", // Dirt course
  "AW", // All-weather
  "SPR", // Sprint
  "RT", // Route
  "MD", // Middle Distance
  "LD", // Long Distance
];

export const getRaceUrl = (
  type: "race" | "meeting",
  meetingId: string,
  raceId?: string,
): string =>
  type === "meeting"
    ? `/racing/betting/meeting/${meetingId}`
    : `/racing/betting/race/${meetingId}/${raceId}`;
