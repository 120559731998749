// request category constants
export const GROUP_DETAILS = "DETAILS";
export const GROUP_VERIFICATION = "VERIFICATION";
export const GROUP_DAILY = "DAILY";
export const GROUP_RAFFLES = "RAFFLES";
export const GROUP_ENTRIES = "ENTRIES";
export const GROUP_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GROUP_FANTASY = "GROUP_FANTASY";
export const GROUP_LOGIN = "LOGIN";
export const GROUP_REGISTRATION = "REGISTRATION";
export const GROUP_WALLET = "WALLET";
export const GROUP_NOTIFICATIONS = "NOTIFICATIONS";
export const GROUP_LIMITS = "LIMITS";
export const GROUP_SELL = "SELL";
export const GROUP_GEAR = "GEAR";
export const GROUP_BETTING = "BETTING";
export const GROUP_ONBOARDING = "GROUP_ONBOARDING";
export const GROUP_PROMOTIONS = "GROUP_PROMOTIONS";
export const GROUP_USER = "GROUP_USER";
export const GROUP_EMAIL_UNSUBSCRIBE = "GROUP_EMAIL_UNSUBSCRIBE";

const ADD_TO_LIST = "ADD_TO_LIST";
const REMOVE_BY_KEY = "REMOVE_BY_KEY";
const REMOVE_BY_GROUP = "REMOVE_BY_GROUP";

const DEFAULT_TIMEOUT = 10;
const timeouts = [];

export const requestStarted =
  (group, key, timeout = DEFAULT_TIMEOUT) =>
  (dispatch) => {
    // add given key to a list of running queries
    dispatch(addToList(group, key));

    // if there is an existing timeout queued for this key - clear it
    if (timeouts[key]) {
      clearTimeout(timeouts[key]);
    }

    // create a timeout function that will stop the request after given timeout
    timeouts[key] = setTimeout(() => {
      dispatch(requestTimedOut(key));
    }, timeout * 1000);
  };

export const requestFinished = (key) => (dispatch) => {
  clearTimeout(timeouts[key]);

  dispatch(removeByKey(key));
};

export const requestFinishedGroup = (group) => (dispatch, getState) => {
  const loading = getState().loading;

  // figure out what keys are in the given group and unset timeouts
  Object.keys(loading)
    .filter((key) => loading[key] === group)
    .forEach((key) => {
      clearTimeout(timeouts[key]);
    });

  dispatch(removeByGroup(group));
};

export const requestTimedOut = (key) => (dispatch) => {
  console.error(`Request timed out: ${key}`);

  dispatch(removeByKey(key));
};

export const removeByKey = (key) => ({
  type: REMOVE_BY_KEY,
  payload: { key },
});

export const removeByGroup = (group) => ({
  type: REMOVE_BY_GROUP,
  payload: { group },
});

export const addToList = (group, key) => ({
  type: ADD_TO_LIST,
  payload: {
    group,
    key,
  },
});

// --
// Handlers

const ACTION_HANDLERS = {
  [ADD_TO_LIST]: (state, action) =>
    Object.assign({}, state, {
      [action.payload.key]: action.payload.group,
    }),
  [REMOVE_BY_KEY]: (state, action) => {
    const newState = Object.assign({}, state);
    delete newState[action.payload.key];
    return newState;
  },
  [REMOVE_BY_GROUP]: (state, action) => {
    const newState = Object.keys(state)
      .filter((key) => {
        return state[key] !== action.payload.group;
      })
      .reduce((newState, key) => {
        newState[key] = state[key];
        return newState;
      }, {});
    return newState;
  },
};

// --
// Reducer

const initialState = {};

const loadingReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default loadingReducer;
