import React, { forwardRef, useMemo } from "react";
import { get } from "lodash";
import * as UrlParameters from "./BuildUrlParameters";
import { FORMAT, RESIZE, CROP, QUALITY, BACKGROUND_COLOR } from "./Constants";

export interface ContentfulImageProps {
  optimize?: boolean;
  quality?: number;
  image: string | unknown;
  backgroundColor?: string;
  cropRadius?: "max" | number;
  format?: "webp" | "jpg" | "png" | "progressive jpg" | "8bit png";
  resize?: {
    width?: number;
    height?: number;
    behavior?: "pad" | "fill" | "scale" | "crop" | "thumb";
    focusArea?:
      | "top"
      | "right"
      | "bottom"
      | "left"
      | "center"
      | "top_right"
      | "top_left"
      | "bottom_right"
      | "bottom_left"
      | "face"
      | "faces";
  };
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export const ContentfulImage = forwardRef(
  (
    {
      format = "webp",
      optimize = true,
      resize,
      cropRadius,
      quality,
      backgroundColor,
      image,
      ...imageProps
    }: ContentfulImageProps,
    ref: React.LegacyRef<HTMLImageElement>,
  ) => {
    const imageData = useMemo(() => {
      const originalUrl =
        typeof image === "string" ? image : get(image, "fields.file.url");

      if (!originalUrl) {
        return;
      }

      const urlParameters = [];
      let returnedMimeType;

      if (optimize) {
        const { url, mimeType } = UrlParameters.buildUrlParameters({
          key: FORMAT,
          value: format,
        }) as UrlParameters.BuildUrlParametersFormatResponse;
        urlParameters.push(...url);
        returnedMimeType = mimeType;
      }

      if (resize) {
        const url = UrlParameters.buildUrlParameters({
          key: RESIZE,
          value: resize,
        }) as UrlParameters.BuildUrlParametersResponse;
        urlParameters.push(...url);
      }

      if (cropRadius) {
        const url = UrlParameters.buildUrlParameters({
          key: CROP,
          value: cropRadius,
        }) as UrlParameters.BuildUrlParametersResponse;
        urlParameters.push(...url);
      }

      if (quality) {
        const url = UrlParameters.buildUrlParameters({
          key: QUALITY,
          value: quality,
        }) as UrlParameters.BuildUrlParametersResponse;
        urlParameters.push(...url);
      }

      if (backgroundColor) {
        const url = UrlParameters.buildUrlParameters({
          key: BACKGROUND_COLOR,
          value: backgroundColor,
        }) as UrlParameters.BuildUrlParametersResponse;
        urlParameters.push(...url);
      }

      const enhancedUrl = urlParameters.length
        ? `${originalUrl}?${urlParameters.join("&")}`
        : originalUrl;

      return {
        enhancedUrl,
        mimeType: returnedMimeType,
        originalUrl,
      };
    }, [image, format, resize, optimize, cropRadius, quality, backgroundColor]);

    if (!imageData) {
      console.error(
        "ContentfulImage: Could not retrieve an URL from the `image` prop. Please check your object structure.",
      );
      return null;
    }

    const { enhancedUrl, mimeType, originalUrl } = imageData;

    return (
      <picture data-testid="picture">
        {enhancedUrl !== originalUrl && (
          <source srcSet={enhancedUrl} type={mimeType} />
        )}
        <source srcSet={originalUrl} />
        <img {...imageProps} src={originalUrl} ref={ref} width="100%" />
      </picture>
    );
  },
);

ContentfulImage.displayName = "Contentful Image";
