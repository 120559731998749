// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "Caret-module--caret--0331b";
export var down = "Caret-module--down--ea4a2";
export var emphasis = "Caret-module--emphasis--14bea";
export var green = "#57d33b";
export var greenLink = "Caret-module--green-link--717a9";
export var grey = "var(--tone-mid)";
export var left = "Caret-module--left--4fd7b";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var right = "Caret-module--right--3fbc2";
export var up = "Caret-module--up--3b8b8";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";