// extracted by mini-css-extract-plugin
export var arrow = "BetPick-module--arrow--d5fb0";
export var blue = "#0eb3f2";
export var close = "BetPick-module--close--4e78a";
export var definition = "BetPick-module--definition--f076a";
export var description = "BetPick-module--description--1bd53";
export var detail = "BetPick-module--detail--8af2a";
export var down = "BetPick-module--down--c0de2";
export var emphasis = "BetPick-module--emphasis--624c9";
export var fadeInOut = "BetPick-module--fadeInOut--d71f0";
export var green = "#57d33b";
export var greenLink = "BetPick-module--green-link--7572d";
export var grey = "var(--tone-mid)";
export var inactive = "BetPick-module--inactive--23a80";
export var kind = "BetPick-module--kind--58628";
export var match = "BetPick-module--match--a479a";
export var matchIcon = "BetPick-module--matchIcon--e3907";
export var matchInfo = "BetPick-module--matchInfo--5d8c3";
export var notchGap = "32px";
export var odds = "BetPick-module--odds--09d74";
export var oddsChange = "BetPick-module--oddsChange--8143d";
export var oddsChangedButton = "BetPick-module--oddsChangedButton--76422";
export var orange = "#f8662b";
export var pick = "BetPick-module--pick--21910";
export var pickIcon = "BetPick-module--pickIcon--238c8";
export var red = "#ff2741";
export var starts = "BetPick-module--starts--a2f00";
export var up = "BetPick-module--up--92987";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";