import React, { useEffect, useState } from "react";
import cx from "classnames";

import * as styles from "./OddsChange.module.scss";
import { useTimeout } from "usehooks-ts";
import { getOddsChangeDirection } from "utilities/sharedBettingUtilities";
import { usePreviousValue } from "hooks";

export enum OddsChangeType {
  racingWinPlace,
  racingMultiOutcome,
  sports,
  sameRaceMultiSlip,
}

const ANIMATION_LENGTH = 3000;
// in milliseconds, ⬆️ Also set the length in the scss file

export const OddsChange = ({
  children,
  odds,
  className,
  onClick,
  oddChangeType = OddsChangeType.racingWinPlace,
}: {
  children: React.ReactNode;
  odds: number;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  oddChangeType?: OddsChangeType;
}) => {
  const previousOdds = usePreviousValue(odds);
  const [upOrDown, setUpOrDown] = useState<"up" | "down" | undefined>();

  useEffect(() => {
    if (previousOdds && previousOdds !== odds) {
      setUpOrDown(getOddsChangeDirection(previousOdds, odds));
    }
  }, [odds]);

  useTimeout(() => setUpOrDown(undefined), upOrDown ? ANIMATION_LENGTH : null);

  return (
    <div
      className={cx(
        styles.price,
        {
          [styles.sports]: oddChangeType === OddsChangeType.sports,
          [styles.racingWinPlace]:
            oddChangeType === OddsChangeType.racingWinPlace,
          [styles.racingMultiOutcome]:
            oddChangeType === OddsChangeType.racingMultiOutcome,
          [styles.sameRaceMultiSlip]:
            oddChangeType === OddsChangeType.sameRaceMultiSlip,
          [styles.priceUp]: upOrDown === "up",
          [styles.priceDown]: upOrDown === "down",
        },
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cx(styles.icon, styles.iconDown, {
          [styles.active]: upOrDown === "down",
        })}
      ></div>

      {children}

      <div
        className={cx(styles.icon, styles.iconUp, {
          [styles.active]: upOrDown === "up",
        })}
      ></div>
    </div>
  );
};
