import React from "react";
import { motion } from "framer-motion";
import type { MenuItem } from "./types";
import SubItem from "./SubItem";

import * as styles from "./SubItems.module.scss";

type SubItemsProps = {
  items: MenuItem[];
};

const SubItems = ({ items }: SubItemsProps) => {
  return (
    <motion.ul
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: "auto",
        opacity: 1,
        transition: {
          height: {
            duration: 0.18,
          },
          opacity: {
            duration: 0.1,
            delay: 0.15,
          },
        },
      }}
      exit={{
        height: 0,
        opacity: 0,
        transition: {
          height: {
            duration: 0.18,
          },
          opacity: {
            duration: 0.1,
          },
        },
      }}
      className={styles.items}
    >
      {items.map((item, index) => (
        <SubItem item={item} key={`${item.name}-${index}`} />
      ))}
    </motion.ul>
  );
};

export default SubItems;
