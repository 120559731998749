import React, { useContext } from "react";
import { useSelector } from "react-redux";
import {
  BetCard,
  HUDInstructions,
  HUDPanelEmpty,
  HUDScrollPanel,
  type IInstruction,
  Link,
} from "library";
import { FirebaseContext } from "context/Firebase";
import { PickemsTransforms } from "selectors";
import { useIsLoggedIn } from "hooks";
import { useLivePickemsEntries, usePickemsContests } from "hooks/firestore";
import { usePermission } from "hooks";
import { getUnauthorisedMessageElement } from "./PickSlip";
import { PickemsContestSummary } from "../components/PickemsContestSummary";
import { selectContestStats } from "./pickslipSlice";
import * as styles from "./PickSlip.module.scss";

const PickSlipNoContest = () => {
  const isLoggedIn = useIsLoggedIn();
  const [contests] = usePickemsContests();
  const [entries] = useLivePickemsEntries();
  const { profileComplete } = useContext(FirebaseContext);
  const contestStats = useSelector(() => selectContestStats(contests, entries));
  const permission = usePermission("submitFreeRoster");
  contestStats.filter((x) =>
    PickemsTransforms.isContestActive(x?.contest?.status),
  );
  const bodyElems = [];

  const unauthorisedMessage = getUnauthorisedMessageElement(
    isLoggedIn,
    profileComplete,
    permission,
  );

  const instructions: IInstruction[] = [];

  if (contestStats.length < 1) {
    instructions.push({
      text: "Select a Pick'ems contest to enter",
      selected: true,
    });
    bodyElems.push(
      <HUDPanelEmpty
        key="pickems"
        icon="pick"
        message="Welcome to Pick'ems!"
      />,
    );
  } else {
    instructions.push(
      {
        text: "Select a contest to revise your picks; or",
        selected: true,
      },
      {
        text: "Select a Pick'ems contest to enter",
        selected: true,
      },
    );
    bodyElems.push(
      ...contestStats.map((stats) => (
        <Link
          key={stats.contest.id}
          to={PickemsTransforms.getPlayLink(stats.contest.id)}
        >
          <div className={styles.pick}>
            <BetCard className={styles.contestCard}>
              <PickemsContestSummary
                contest={stats.contest}
                marketCount={stats.marketCount}
                remainingPickCount={stats.remainingPickCount}
                pointsEntered={stats.pointsEntered}
              />
            </BetCard>
          </div>
        </Link>
      )),
    );
  }

  bodyElems.push(
    <br key="br" />,
    <HUDInstructions key="instructions" instructions={instructions} />,
  );

  return (
    <HUDScrollPanel
      contentNotifications={unauthorisedMessage}
      title="Pick'ems Contests"
      tabs={[
        {
          title: "Pick'ems",
          isActive: true,
          count: contestStats?.length.toString() || "0",
          badgeType: "default",
        },
      ]}
      contentBody={<div className={styles.pickemsBody}>{bodyElems}</div>}
    />
  );
};

export default PickSlipNoContest;
