import { PageContent } from "components/Page";
import { Toggle } from "components/Toggle";
import React from "react";
import type { RaceOutcomeType } from "../../hooks/RacingTypes";
import { Competitor } from "../Competitor";
import { CompetitorsExoticOutcomes } from "./CompetitorsExoticOutcomes";
import { ExoticBetBuilder } from "./ExoticBetBuilder";
import { ExoticRunnersInPosition, ExoticSelections } from "./ExoticSelections";
import { ExoticCompetitorSkeleton } from "./ExoticSkeleton";
import type { ExoticComponentProps } from "./Exotics.types";
import {
  byNumericRunnerPosition,
  calculateExoticCombinations,
  filterByOutcomeType,
  getSelectedRunners,
  pickExoticMarkets,
} from "./Exotics.utils";
import {
  RunnerPositionToggle,
  RunnerPositionToggleGroup,
} from "./RunnerPositionToggle";
import { useExoticOutcomeSelections } from "./useExoticOutcomeSelections";
import { useExoticsMarketsAndOutcomes } from "./useExoticsMarketsAndOutcomes";

import * as styles from "./Exotics.module.scss";

const getExactaOutcomes = pickExoticMarkets("EXACTA");

export const Exacta = ({
  competitors: competitors,
  validCompetitorCount,
}: ExoticComponentProps) => {
  const {
    markets,
    getCompetitorOdds,
    outcomes,
    groupedOutcomes,
    race,
    loading,
  } = useExoticsMarketsAndOutcomes("EXACTA");

  const {
    selectedOutcomes,
    setSelectedOutcomes,
    isBoxed,
    setIsBoxed,
    isBoxedActive,
    onBoxedChange,
    handleSelectedOutcomeGroup,
  } = useExoticOutcomeSelections(groupedOutcomes, competitors);

  const getRunnerNumberFromOutcome = (outcomeType: RaceOutcomeType["type"]) =>
    getExactaOutcomes(markets, filterByOutcomeType(outcomeType)).flatMap(
      getSelectedRunners(selectedOutcomes),
    );

  const firstPositionRunners = getRunnerNumberFromOutcome("RUNNER_WIN");
  const secondPositionRunners = getRunnerNumberFromOutcome("RUNNER_POS_2");

  return (
    <PageContent noSpacing>
      <div className={styles.exoticControls}>
        <Toggle
          label="Box"
          noSpacing
          checked={isBoxed}
          onCheckedChange={setIsBoxed}
        />

        <RunnerPositionToggleGroup
          className={styles.positionControl}
          isBoxed={isBoxed}
          isBoxedActive={isBoxedActive}
          onBoxedChange={onBoxedChange}
        >
          <RunnerPositionToggle
            onClick={() => handleSelectedOutcomeGroup("RUNNER_WIN")}
            isActive={groupedOutcomes["RUNNER_WIN"]?.every((o) =>
              selectedOutcomes.includes(o.id),
            )}
          >
            1
          </RunnerPositionToggle>
          <RunnerPositionToggle
            onClick={() => handleSelectedOutcomeGroup("RUNNER_POS_2")}
            isActive={groupedOutcomes["RUNNER_POS_2"]?.every((o) =>
              selectedOutcomes.includes(o.id),
            )}
          >
            2
          </RunnerPositionToggle>
        </RunnerPositionToggleGroup>
      </div>

      <ExoticCompetitorSkeleton isLoading={loading}>
        {competitors.map((competitor) => {
          return (
            <Competitor
              key={competitor.id}
              competitor={competitor}
              validCompetitorCount={validCompetitorCount}
              competitorOutcomes={() => (
                <CompetitorsExoticOutcomes
                  isBoxed={isBoxed}
                  outcomes={outcomes
                    .filter((o) => o.competitorId === competitor.id)
                    .sort(byNumericRunnerPosition)}
                  currentSelections={selectedOutcomes}
                  setCurrentSelections={setSelectedOutcomes}
                  isScratched={competitor.scratched}
                  winMarketOdds={getCompetitorOdds(competitor.id)}
                />
              )}
            />
          );
        })}
      </ExoticCompetitorSkeleton>

      <ExoticBetBuilder
        event={race}
        markets={markets}
        competitors={competitors}
        selections={selectedOutcomes}
        setSelections={setSelectedOutcomes}
        combinations={calculateExoticCombinations([
          firstPositionRunners,
          secondPositionRunners,
        ])}
        errorMessage="Add 1+ selection per column, on 2+ runners"
      >
        <ExoticSelections>
          <ExoticRunnersInPosition
            position={1}
            outcomes={firstPositionRunners}
          />
          <ExoticRunnersInPosition
            position={2}
            outcomes={secondPositionRunners}
          />
        </ExoticSelections>
      </ExoticBetBuilder>
    </PageContent>
  );
};
