import React, { useContext } from "react";
import { Box } from "components/Box";
import { Skeleton } from "components/Skeleton";
import * as styles from "./CompetitorSkeleton.module.scss";
import cx from "classnames";
import { RaceContext } from "sections/Betting/Race";

const Outcomes = ({
  className,
  displayType,
}: {
  className?: string;
  displayType?: DisplayType;
}) => (
  <div className={cx(styles.outcomes, className)}>
    {Array.from({ length: displayType === "Win" ? 2 : 4 }).map((_, index) => (
      <div key={`outcome-${index}`} className={styles.oddsContainer}>
        <Skeleton mode="light" edge="hard" className={styles.oddsLabel} />
        <Skeleton mode="light" edge="hard" className={styles.odds} />
      </div>
    ))}
  </div>
);

const Competitor = ({ displayType }: { displayType: DisplayType }) => {
  const { selectedPills } = useContext(RaceContext);
  const showFlucs = selectedPills.includes("flucs");
  return (
    <div className={styles.container}>
      <Box hideHeader className={styles.box}>
        <div>
          <div className={styles.competitor}>
            <div className={styles.alignTop}>
              <Skeleton
                shape="square"
                edge="hard"
                className={styles.silk}
                mode="light"
              />
            </div>
            <div className={styles.info}>
              <Skeleton
                shape="rect"
                mode="light"
                edge="hard"
                className={styles.runnerName}
              />
              <Skeleton
                mode="light"
                shape="rect"
                edge="hard"
                className={styles.jockey}
              />
              <Skeleton
                mode="light"
                shape="rect"
                edge="hard"
                className={styles.trainer}
              />
            </div>
            <Outcomes
              className={cx({
                [styles.desktop]: displayType === "Top4",
                [styles.win]: displayType === "Win",
              })}
              displayType={displayType}
            />
            <div>
              <Skeleton
                shape="rect"
                size="xs"
                mode="light"
                className={cx(styles.clampMore, {
                  [styles.top4]: displayType === "Top4",
                })}
              />
            </div>
          </div>
          {displayType === "Top4" && <Outcomes className={styles.mobile} />}
        </div>
      </Box>
      {showFlucs && (
        <Skeleton shape="rect" mode="light" className={styles.flucsHistory} />
      )}
    </div>
  );
};

type DisplayType = "Win" | "Top4";

export default ({ displayType = "Top4" }: { displayType: DisplayType }) => (
  <div className={styles.competitors}>
    {Array.from({ length: 10 }).map((_, index) => (
      <Competitor key={`competitor-${index}`} displayType={displayType} />
    ))}
    ;
  </div>
);
