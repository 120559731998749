import React from "react";
import type { ComponentProps } from "rehype-react";
import * as display from "utilities/display";
import { ContentfulImage } from "library";

type ImageComponantProps = ComponentProps & { src: string };

const BasicImage = (props: ImageComponantProps) => {
  return <img {...props} />;
};

const ContentfulImageBridge = (props: ImageComponantProps) => {
  try {
    const url = new URL(props.src);
    const cleanUrl = `${url.origin}${url.pathname}`;

    return (
      <ContentfulImage
        quality={98}
        resize={{ width: display.isDisplayAtMost("mobile") ? 760 : 1300 }}
        image={cleanUrl}
        {...props}
      />
    );
  } catch (err) {
    return <BasicImage {...props} />;
  }
};

const DualAttributeImage = (props: ImageComponantProps) => {
  const desktopSrcUrl = props["src-wide"] as string;
  const mobileProps = display.isDisplayAtMost("mobile")
    ? { ...props }
    : { ...props, src: desktopSrcUrl };

  return <ContentfulImageBridge {...mobileProps} />;
};

const ResponsiveImage = (props: ImageComponantProps) => {
  if (typeof window == undefined) return <BasicImage {...props} />;

  if (props["src-wide"]) return <DualAttributeImage {...props} />;

  return <ContentfulImageBridge {...props} />;
};

export default ResponsiveImage;
