import React, { useRef } from "react";
import cx from "classnames";

import TabFilterItem, { type TabFilterItemProps } from "./TabFilterItem";
import type { IContentItem } from "types/ContentTypes";
import { navigate } from "../Link";
import { ScrollArea } from "components/ScrollArea";
import { useIsMobile } from "hooks";

import * as styles from "./TabFilter.module.scss";

export type TabFilterProps = {
  className?: string;
  tabs: (IContentItem[] & TabFilterItemProps[]) | IContentItem[];
  selected?: string;
  gutterBottom?: boolean;
  onSelect?: (slug: string) => void;
  children?: React.ReactNode;
};

export const TabFilter = (props: TabFilterProps) => {
  const { className, tabs, onSelect, selected, gutterBottom, children } = props;

  const isMobile = useIsMobile();

  const refTabs = useRef<HTMLUListElement>();

  const selectTab = (tab: IContentItem | number) => {
    if (typeof tab === "number") {
      tab = filteredTabs[tab - 1];
    }
    if (!tab || tab.isDisabled) {
      return;
    }
    if (tab.onClick) {
      tab.onClick();
    }
    if (tab.isActive) {
      return;
    }
    if (tab.link) {
      navigate(tab.link);
    }
    if (onSelect && tab.slug) {
      onSelect(tab.slug);
    }
  };

  const filteredTabs = (tabs || [])
    .filter((x) => Boolean(x?.title || x?.slug))
    .map((x) => ({
      ...x,
      slug: x.slug || x.title,
      label: x.shortTitle || x.title || x.slug,
    }));

  if (filteredTabs.length < 1) {
    return null;
  }

  return (
    <div
      className={cx(
        styles.tabs,
        { [styles.gutterBottom]: !!gutterBottom },
        className,
      )}
    >
      <ScrollArea hideScrollbars={isMobile} className={styles.scrollArea}>
        <ul className={styles.tabsList} ref={refTabs}>
          {filteredTabs.map((tab) => (
            <TabFilterItem
              key={tab.slug}
              {...tab}
              isActive={
                typeof tab.isActive === "boolean"
                  ? tab.isActive
                  : selected === tab.slug
              }
              isDisabled={tab.isDisabled}
              onClick={() => selectTab(tab)}
            />
          ))}
          {children && <div>{children}</div>}
        </ul>
      </ScrollArea>
    </div>
  );
};

export default TabFilter;
