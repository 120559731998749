// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var card = "Card-module--card--9bd2b";
export var cardWrapper = "Card-module--cardWrapper--cefe6";
export var emphasis = "Card-module--emphasis--37ab6";
export var green = "#57d33b";
export var greenLink = "Card-module--green-link--67122";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";