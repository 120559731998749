import useWindowResize from "./events/useWindowResize";
import { useIsMobile } from "./useDisplay";
import { useSelector } from "./useSelector";

const useCalculatePlayerOffset = () => {
  const isMobile = useIsMobile();
  const streams = useSelector((state: any) => state.utilities.ui.streams);
  const { width: windowWidth } = useWindowResize();
  const playerHeight = Math.floor((windowWidth / 16) * 9);
  const isMobilePlayerOpen = isMobile && streams.length > 0;

  return isMobilePlayerOpen ? playerHeight : 0;
};

export default useCalculatePlayerOffset;
