// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "Pill-module--button--67dbd";
export var emphasis = "Pill-module--emphasis--c8809";
export var green = "#57d33b";
export var greenLink = "Pill-module--green-link--2ad40";
export var grey = "var(--tone-mid)";
export var large = "Pill-module--large--5249b";
export var medium = "Pill-module--medium--93719";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pill = "Pill-module--pill--7edf1";
export var pillBase = "Pill-module--pillBase--24b35";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";