import countryData from "country-list";
import { getGeocode } from "use-places-autocomplete";
import getCountryISO3 from "country-iso-2-to-3";

const countryCodeList = countryData.getCodeList();

const getAllCountries = (isISO3 = false) => {
  return Object.keys(countryCodeList)
    .sort((a, b) => countryCodeList[a].localeCompare(countryCodeList[b]))
    .map((code) => ({
      value: isISO3 ? getCountryISO3(code.toUpperCase()) : code.toUpperCase(),
      label: countryCodeList[code],
    }));
};

const parseGoogleAddressComponents = (components: any) => {
  // Map Google address components to a custom format
  const componentMapping = {
    subpremise: { label: "unit", field: "long_name" },
    street_number: { label: "streetNumber", field: "long_name" },
    route: { label: "street", field: "long_name" },
    locality: { label: "suburb", field: "long_name" },
    administrative_area_level_1: { label: "state", field: "short_name" },
    postal_code: { label: "postCode", field: "long_name" },
    country: { label: "country", field: "short_name" },
  };

  const formattedAddress = components.reduce((address, component) => {
    component.types.forEach((type) => {
      const currentMapping = componentMapping[type];
      if (!currentMapping) {
        return;
      }
      address[currentMapping.label] = component[currentMapping.field];
    });
    return address;
  }, {});

  if (formattedAddress.country) {
    const matchedCountry = getAllCountries().find(
      (country) =>
        country &&
        country.value &&
        country.value.toUpperCase() === formattedAddress.country.toUpperCase(),
    );
    if (matchedCountry) {
      formattedAddress.country = matchedCountry.value.toUpperCase();
    }
  }

  return formattedAddress;
};

const getAddressFromPlaceId = async (placeId: string) => {
  const geocodedPlaces = await getGeocode({ placeId });

  if (geocodedPlaces && geocodedPlaces.length > 0) {
    return parseGoogleAddressComponents(
      geocodedPlaces[0].address_components || [],
    );
  }
  return {};
};

export { getAllCountries, parseGoogleAddressComponents, getAddressFromPlaceId };
