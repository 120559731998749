import { doc, type Timestamp } from "firebase/firestore";
import { constructCurrency } from "common";
import { useDocumentData, useFiatCurrency, useSelector } from "hooks";
import type { CampaignsType } from "hooks";
import type { BetEntry } from "sections/Entries/types";
import { getFirestore } from "store/getFirebase";
import useSWR from "swr";

type ProjectedRewardInfo = {
  max: number;
  percent: number;
  isPromo: boolean;
};

export type ActionTypes =
  | "STAKE_REFUND"
  | "DEPOSIT_MATCH"
  | "GIVE_PROMOTION"
  | "WINNINGS_BOOST";

export type Action = {
  info: string;
  projectedRewardInfo: ProjectedRewardInfo;
  status: string;
  type: ActionTypes;
};

type RewardInfo = {
  fiatCashRewarded: number;
  promoCashRewarded: number;
  projectedFiatCashReward: number;
  projectedPromoCashReward: number;
};

type EntityTypes = "BET" | "DEPOSIT" | "WITHDRAWAL";

type Entity = {
  info: string;
  rewardInfo: RewardInfo;
  status: string;
  type: EntityTypes;
  entityId: string;
};

type RequirementTypes =
  | "SIGNUP"
  | "DEPOSIT"
  | "SEM_BET_STAKE"
  | "SEM_BET_SETTLE"
  | "NON_SEM_BET_STAKE"
  | "NON_SEM_BET_SETTLE"
  | "NON_SEM_BET_FINALISE";

export type Requirement = {
  info: string;
  projectedRewardInfo?: ProjectedRewardInfo;
  status: string;
  type: RequirementTypes;
};

type StatusType =
  | "ACTIVE"
  | "FULFILLED"
  | "FAILED"
  | "FAILED_TO_ACTION"
  | "EXPIRED"
  | "CANCELLED";

export type AutomationProgression = {
  actions: Action[];
  campaign: CampaignsType;
  entities: Entity[];
  requirements: Requirement[];
  status: StatusType;
  automationId: string;
  progressionId: string;
  name: string;
  expiresAt?: Timestamp;
};

export const useHistoricalUserProgression = (
  progressionId: string,
  shouldFetch: boolean,
): [AutomationProgression | undefined, boolean, any] => {
  const { data, error } = useSWR(
    shouldFetch
      ? `${process.env.GATSBY_API_URI}/automations/progressions/${progressionId}`
      : null,
  );

  if (!shouldFetch) {
    return [undefined, false, null];
  }

  return [data, !error && !data, error];
};

export const useLiveUserProgression = (
  progressionId: string,
  shouldFetch: boolean,
): [AutomationProgression | undefined, boolean] => {
  const userId = useSelector((state) => state.auth?.userId);

  const userProgressionRef =
    userId && progressionId && shouldFetch
      ? doc(
          getFirestore(),
          "users",
          userId,
          "currentAutomationProgressions",
          progressionId,
        )
      : null;

  const [progression, loading] = useDocumentData(userProgressionRef);

  return [progression as AutomationProgression | undefined, loading];
};

export const useUserProgressionData = (
  progressionId: string,
  isSettled: boolean,
) => {
  // We should only fetch live progression if the entry is not settled
  const shouldFetchLive = !isSettled;
  const [progression, loading] = useLiveUserProgression(
    progressionId,
    shouldFetchLive,
  );

  // We should only fetch historical progression if the live progression is not available
  const shouldFetchHistorical = !loading && !progression;
  const [historicalProgression, loadingHistorical] =
    useHistoricalUserProgression(progressionId, shouldFetchHistorical);

  return {
    progression: progression || historicalProgression,
    loading: loading || loadingHistorical,
  };
};

export const useUserProgression = (
  progressionId: string,
  entry: BetEntry,
): {
  campaign?: CampaignsType;
  isComplete?: boolean;
  isFailed?: boolean;
  isPromo?: boolean;
  loading: boolean;
  displayPayout?: string;
  automationId?: string;
  progression?: AutomationProgression;
  hide?: boolean;
} => {
  const usersCurrency = useFiatCurrency();
  const { status: entryStatus } = entry;
  const isSettled = entryStatus === "SETTLED";

  const { progression, loading } = useUserProgressionData(
    progressionId,
    isSettled,
  );

  if (loading || !progression) {
    return {
      loading,
      progression: undefined,
      hide: false,
    };
  }

  const { status, campaign, entities, automationId } = progression;

  const rewardInfo = (entities || []).find(
    (entity) => entity.entityId === entry.referenceId,
  )?.rewardInfo;

  if (!rewardInfo) {
    return {
      loading,
      progression,
      hide: true,
    };
  }

  const actualPayout =
    rewardInfo?.fiatCashRewarded > 0
      ? rewardInfo?.fiatCashRewarded
      : rewardInfo?.promoCashRewarded ?? 0;

  const isComplete =
    actualPayout > 0 || (status !== "ACTIVE" && status !== "FAILED_TO_ACTION");
  const isFailed = actualPayout === 0 && isComplete;
  const isPromo = rewardInfo.projectedPromoCashReward > 0;

  const payout = isComplete
    ? actualPayout
    : rewardInfo.projectedFiatCashReward > 0
      ? rewardInfo.projectedFiatCashReward
      : rewardInfo.projectedPromoCashReward;

  const displayPayout = `${constructCurrency(payout, {
    usersCurrency,
  })} ${isPromo ? "Bonus Cash" : "Cash"}`;

  return {
    progression,
    loading,
    isFailed,
    displayPayout,
    isComplete,
    isPromo,
    campaign,
    automationId,
    hide: false,
  };
};
