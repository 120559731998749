import React, { useContext } from "react";
import * as styles from "./RaceTipsCompetitorsSkeleton.module.scss";
import { Skeleton } from "components/Skeleton";
import { RaceContext } from "sections/Betting/Race/Race";
import { useIsMobile } from "hooks";
import cx from "classnames";

export const RaceTipsCompetitorsSkeleton = () => {
  const { route } = useContext(RaceContext);
  const isMobile = useIsMobile();

  const isSameRaceMulti = route?.tab === "same-race-multi";
  return (
    <div className={styles.competitors}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={`race-tips-${index}`} className={styles.competitor}>
          <div
            className={cx(styles.competitorInfo, {
              [styles.sgm]: isSameRaceMulti,
              [styles.mobile]: isMobile,
            })}
          >
            <div className={styles.silksContainer}>
              <Skeleton shape="rect" className={styles.silks} />

              <Skeleton
                shape="circle"
                mode="light"
                className={styles.runnerNumber}
              />
            </div>
            {!isMobile && (
              <>
                <Skeleton
                  shape="rect"
                  mode="light"
                  className={styles.runnerName}
                  edge="hard"
                />
                <Skeleton shape="rect" mode="light" className={styles.jockey} />
                <Skeleton
                  shape="rect"
                  mode="light"
                  className={styles.trainer}
                />
              </>
            )}
            {!isSameRaceMulti && (
              <div className={styles.odds}>
                <Skeleton
                  mode="light"
                  shape="rect"
                  className={styles.oddsValue}
                  edge="hard"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
