import { Activity } from "@ivliu/react-offscreen";
import { Separator } from "components/Separator";
import { Tab, TabContent, Tabs, TabsList } from "components/Tabs";
import React, {
  type PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from "react";
import {
  RaceMarketTypeTypes,
  type RaceMarketsType,
  exoticMarketTypes,
  type ExoticMarketType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { Exacta } from "../Exotics/Exacta";
import { FirstFour } from "../Exotics/FirstFour";
import { Quinella } from "../Exotics/Quinella";
import { Trifecta } from "../Exotics/Trifecta";
import { RaceFilters } from "../RaceFilters";
import { RaceCustomMarkets } from "components/RaceCustomMarkets";
import { RaceTips } from "../RaceTips";
import { PermissionsRequired } from "library";
import { RaceContext } from "sections/Betting/Race";
import { CampaignPromotions } from "sections/Betting/CampaignPromotions";
import { BettingTabs } from "hooks";
import { TabPromoIcon } from "components/PromoIcon/TabPromoIcon";

const FIRST_FOUR_REQUIREMENT = 5;
const TRIFECTA_REQUIREMENT = 4;

const hasExoticMarket = (
  markets: RaceMarketsType[],
  marketName: ExoticMarketType,
) => {
  return (
    markets?.filter((market) => market.marketType === marketName)?.length > 0
  );
};

const ConditionalTab = ({
  condition,
  children,
}: PropsWithChildren<{ condition: boolean }>) => {
  if (condition) return children;
  return null;
};

const getDefaultExoticTab = (
  runnerCount: number,
  minimum: number,
): ExoticMarketType => {
  if (runnerCount === 0) return "TRIFECTA";
  return runnerCount <= minimum ? "QUINELLA" : "TRIFECTA";
};

export const ExoticTabs = () => {
  const { markets, campaigns } = useContext(RaceContext);

  const { mappedCompetitors, validCompetitorCount } = useSortedCompetitors({
    // win markets are required for odds & flucs history
    marketsRequired: [...exoticMarketTypes, RaceMarketTypeTypes.Win],
  });

  const [activeTab, setActiveTab] = useState<ExoticMarketType>(
    getDefaultExoticTab(validCompetitorCount, TRIFECTA_REQUIREMENT),
  );

  useEffect(() => {
    setActiveTab(
      getDefaultExoticTab(validCompetitorCount, TRIFECTA_REQUIREMENT),
    );
  }, [validCompetitorCount]);

  const exoticProps = { competitors: mappedCompetitors, validCompetitorCount };

  return (
    <PermissionsRequired permissionsRequired={["submitRacingExoticBet"]}>
      <Tabs
        value={activeTab}
        onValueChange={(tab: ExoticMarketType) => setActiveTab(tab)}
      >
        <TabsList>
          <ConditionalTab
            condition={
              validCompetitorCount > TRIFECTA_REQUIREMENT &&
              hasExoticMarket(markets, "TRIFECTA")
            }
          >
            <Tab value={`TRIFECTA`}>
              Trifecta
              <TabPromoIcon
                tab={BettingTabs.RACING_TRIFECTA}
                campaigns={campaigns}
              />
            </Tab>
          </ConditionalTab>
          <ConditionalTab condition={hasExoticMarket(markets, "QUINELLA")}>
            <Tab value={`QUINELLA`}>
              Quinella
              <TabPromoIcon
                tab={BettingTabs.RACING_QUINELLA}
                campaigns={campaigns}
              />
            </Tab>
          </ConditionalTab>
          <ConditionalTab
            condition={
              validCompetitorCount > FIRST_FOUR_REQUIREMENT &&
              hasExoticMarket(markets, "FIRST_4")
            }
          >
            <Tab value={`FIRST_4`}>
              First Four
              <TabPromoIcon
                tab={BettingTabs.RACING_FIRST_4}
                campaigns={campaigns}
              />
            </Tab>
          </ConditionalTab>
          <ConditionalTab condition={hasExoticMarket(markets, "TRIFECTA")}>
            <Tab value={`EXACTA`}>
              Exacta
              <TabPromoIcon
                tab={BettingTabs.RACING_EXACTA}
                campaigns={campaigns}
              />
            </Tab>
          </ConditionalTab>
        </TabsList>
        {BettingTabs[`RACING_${activeTab}`] && (
          <CampaignPromotions
            tab={BettingTabs[`RACING_${activeTab}`]}
            campaigns={campaigns}
          />
        )}
        <RaceFilters />
        <RaceTips />
        <RaceCustomMarkets />
        <Separator size="sm" />
        <ConditionalTab condition={validCompetitorCount > TRIFECTA_REQUIREMENT}>
          <Activity mode={activeTab === `TRIFECTA` ? `visible` : `hidden`}>
            <TabContent value={`TRIFECTA`}>
              <Trifecta {...exoticProps} />
            </TabContent>
          </Activity>
        </ConditionalTab>
        <ConditionalTab condition={hasExoticMarket(markets, "QUINELLA")}>
          <Activity mode={activeTab === `QUINELLA` ? `visible` : `hidden`}>
            <TabContent forceMount value={`QUINELLA`}>
              <Quinella {...exoticProps} />
              <TabPromoIcon
                tab={BettingTabs.RACING_QUINELLA}
                campaigns={campaigns}
              />
            </TabContent>
          </Activity>
        </ConditionalTab>
        <ConditionalTab
          condition={
            validCompetitorCount > FIRST_FOUR_REQUIREMENT &&
            hasExoticMarket(markets, "FIRST_4")
          }
        >
          <Activity mode={activeTab === `FIRST_4` ? `visible` : `hidden`}>
            <TabContent forceMount value={`FIRST_4`}>
              <FirstFour {...exoticProps} />
            </TabContent>
          </Activity>
        </ConditionalTab>
        <ConditionalTab condition={hasExoticMarket(markets, "EXACTA")}>
          <Activity mode={activeTab === `EXACTA` ? `visible` : `hidden`}>
            <TabContent forceMount value={`EXACTA`}>
              <Exacta {...exoticProps} />
            </TabContent>
          </Activity>
        </ConditionalTab>
      </Tabs>
    </PermissionsRequired>
  );
};
