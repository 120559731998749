import React from "react";
import { Alert as UnstyledAlert, type AlertProps } from "./Alert";

import * as styles from "./BetCardAlert.module.scss";

type BetCardAlertProps = AlertProps;

const BetCardAlert = (props: BetCardAlertProps) => {
  return <UnstyledAlert className={styles.container} {...props} />;
};

export { BetCardAlert };
