// extracted by mini-css-extract-plugin
export var appLink = "MenuHeader-module--appLink--44fb4";
export var blue = "#0eb3f2";
export var container = "MenuHeader-module--container--8e504";
export var emphasis = "MenuHeader-module--emphasis--28547";
export var green = "#57d33b";
export var greenLink = "MenuHeader-module--green-link--16294";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";