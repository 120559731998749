import React from "react";

import Icon from "../Icon";

import * as styles from "./CooldownError.module.scss";

class CooldownError extends React.Component {
  render() {
    return (
      <div className={styles.error}>
        <Icon type="shield" />
        <p>
          <strong>Your account is in Cooldown.</strong>
        </p>
        <p>During the Cooldown period you are not permitted to Deposit.</p>
      </div>
    );
  }
}

export default CooldownError;
