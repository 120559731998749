import { isAndroid, isAppleWebView } from "./display";

export const enum Channel {
  ios = "ios",
  android = "android",
  web = "web",
}

export const getBettingChannel = () => {
  return isAppleWebView()
    ? Channel.ios
    : isAndroid()
      ? Channel.android
      : Channel.web;
};
