import React from "react";
import { LinkButton } from "components/LinkButton";
import cx from "classnames";
import { buttonVariants } from "components/Button";
import { useIsMobile } from "hooks";

type PicklebetButtonProps = {
  to: string;
  children: JSX.Element;
  type?: string;
};

type TypeOption =
  | "primary"
  | "secondary"
  | "blurred"
  | "danger"
  | "warning"
  | "info"
  | "link";

const typeToVariantMap = {
  secondary: "secondary",
  form: "secondary",
  error: "danger",
  caution: "warning",
  prompt: "info",
  information: "info",
};

const getVariantFromContentfulType = (type: string): TypeOption => {
  return type && typeToVariantMap[type] ? typeToVariantMap[type] : "primary";
};

const PicklebetButton = ({
  to,
  children,
  type,
  ...props
}: PicklebetButtonProps) => {
  const variant = getVariantFromContentfulType(type);
  const isMobile = useIsMobile();
  const size = isMobile ? `md` : `default`;

  if (to?.startsWith("/")) {
    return (
      <LinkButton to={to} {...props} variant={variant} size={size}>
        {children}
      </LinkButton>
    );
  }
  return (
    <a
      href={to}
      target={"_blank"}
      className={cx(buttonVariants({ variant, size }))}
      {...props}
    >
      {children}
    </a>
  );
};

export default PicklebetButton;
