// extracted by mini-css-extract-plugin
export var action = "Summary-module--action--4b3e6";
export var blue = "#0eb3f2";
export var bonus = "Summary-module--bonus--0fa34";
export var circle = "Summary-module--circle--c65aa";
export var emoji = "Summary-module--emoji--450ae";
export var emphasis = "Summary-module--emphasis--a547c";
export var green = "#57d33b";
export var greenLink = "Summary-module--green-link--94123";
export var grey = "var(--tone-mid)";
export var isCompact = "Summary-module--isCompact--70b21";
export var isPromo = "Summary-module--isPromo--4e4d3";
export var isWon = "Summary-module--isWon--12034";
export var label = "Summary-module--label--5f893";
export var notchGap = "32px";
export var odds = "Summary-module--odds--f3138";
export var oddsOnly = "Summary-module--oddsOnly--0da9b";
export var orange = "#f8662b";
export var red = "#ff2741";
export var stakeHidden = "Summary-module--stakeHidden--bdb16";
export var summary = "Summary-module--summary--317e7";
export var value = "Summary-module--value--a7dc0";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";