import React from "react";
import * as styles from "./Pacman.module.scss";

const Pacman = () => {
  return (
    <div className={styles.pacman}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Pacman;
