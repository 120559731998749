import { fetchWithAppCheck as fetch } from "utilities/api/fetchWithAppCheck";

export type srmPayload = {
  bets: Record<
    string,
    {
      selections: { outcomeIds: string[] }[];
    }
  >;
};

export type srmError = {
  message: string;
  messageTemplate: "market.redundant.selection" | string;
  propertyPath: string;
};

export type srmResponse = {
  bets: Record<string, { odds: number; errors: srmError[] }>;
};

export const requestSameRaceMultiOdds = async ({
  token,
  payload,
}: {
  token: string;
  payload: srmPayload;
}): Promise<srmResponse> => {
  try {
    const response = await fetch(process.env.GATSBY_API_URI + `/betting/odds`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const body = await response.json();
    if (response.ok) {
      return body;
    } else {
      throw body;
    }
  } catch (error) {
    throw error;
  }
};
