import React from "react";
import cx from "classnames";

import * as styles from "./Well.module.scss";

export const Well = ({
  children,
  type = "default",
  className: classNameProps,
  gutterBottom = false,
  gutterTop = false,
  gutterLeft = false,
  gutterRight = false,
}: {
  children: React.ReactNode;
  type: "default" | "success" | "warning" | "error" | "stream" | "info";
  className?: string;
  gutterBottom?: boolean;
  gutterTop?: boolean;
  gutterLeft?: boolean;
  gutterRight?: boolean;
}) => {
  const className = cx(
    {
      [styles.well]: true,
      [styles.warning]: type === "warning",
      [styles.error]: type === "error",
      [styles.info]: type === "info",
      [styles.gutterBottom]: gutterBottom,
      [styles.gutterTop]: gutterTop,
      [styles.gutterLeft]: gutterLeft,
      [styles.gutterRight]: gutterRight,
    },
    classNameProps,
  );

  return <div className={className}>{children}</div>;
};

export default Well;
