import React from "react";

import oddslib from "utilities/odds";

// Available Formats:
// - decimal
// - fractional
// - moneyline (american)
// - impliedProbability (50%)
// - hongKong
// - malay
// - indonesian

export const formatOdds = (base: number, format: string, prefix: string) => {
  if (format === "points") {
    return base + " PTS";
  }

  if (format === "american") {
    format = "moneyline";
  }

  let baseFormatted = base
    ? oddslib.from("decimal", base).to(format, {
        precision: ["decimal"].indexOf(format) >= 0 ? 2 : null,
      })
    : "";

  if (format === "decimal") {
    baseFormatted = baseFormatted.toFixed(2);
    if (base > 1000) {
      const digits = Number(baseFormatted.split(".")[1]) > 0 ? 2 : 0;
      // add in commas, choosing to stick with en-AU here for consistency, but we could open this up to users locale
      baseFormatted = new Intl.NumberFormat("en-AU", {
        style: "decimal",
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(baseFormatted);
    }
  }

  if (format === "moneyline") {
    const sign = baseFormatted > 0 ? "+" : "";
    baseFormatted = `${sign}${baseFormatted.toFixed(0)}`;
  }

  return prefix ? prefix + " " + baseFormatted : baseFormatted;
};

type OddsProps = {
  base?: number;
  format?: string;
  className?: string;
  prefix?: string;
  zero?: string;
  wrap?: boolean;
};

const Odds = ({
  base,
  format = "decimal",
  className: classNameProp,
  prefix,
  zero = "-",
}: OddsProps) => {
  const formattedOdds =
    base <= 1 && zero ? zero : formatOdds(base, format, prefix);

  return (
    <div title={formattedOdds} className={classNameProp}>
      {formattedOdds}
    </div>
  );
};

export default Odds;
