import React from "react";
import { useDispatch, useIsLoggedIn, useSelector } from "hooks";
import { useEntry } from "hooks/firestore/betting/useEntries";
import type { BetEntry } from "sections/Entries/types";
import { BetCardAlert } from "./BetCardAlert";
import { Clamp } from "components/Clamp";
import { ShareEntryAction } from "components/layout/Entry/components/share/ShareEntryAction";
import { useShareEntryModal } from "components/layout/Entry/components/share/useShareEntryModal";
import { ReactComponent as AcceptIcon } from "sections/Betting/Betslip/assets/accepted.svg";
import { ReactComponent as ShareIcon } from "components/assets/share.svg";
import {
  clearRejectedCombo,
  setCombinationTotalStake,
  syncCombinationsTotalStake,
} from "sections/Betting/Betslip/betslipSlice";
import { navigate } from "library";
import BigNumber from "bignumber.js";
import { combinations as calculateCombinations } from "mathjs";

import * as styles from "./SingleCardAlert.module.scss";
import { useIsPermittedToBet } from "sections/Betting/Betslip/hooks/useIsPermittedToBet";

type ComboCardAlertProps = {
  type: string;
};

const ComboCardAlert = ({ type }: ComboCardAlertProps) => {
  const dispatch = useDispatch();
  const selections = useSelector((state) => state.betslip.selections);
  const selectionsCount = Object.keys(selections).length;
  const combinations = useSelector(
    (state) => state.betslip.stakePerCombination,
  );
  const combination = combinations[type];

  const [entryToShare, isLoadingEntry] = useEntry<BetEntry>(combination?.betId);
  const [setShareEntryModalOpen, getModalProps] = useShareEntryModal();

  const isPermittedToBet = useIsPermittedToBet();
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn && !isPermittedToBet) {
    return (
      <BetCardAlert variant="warning">
        You are not permitted to bet on this event
      </BetCardAlert>
    );
  }

  if (combination?.betId) {
    const hasEntryToShare = !!entryToShare;

    return (
      <BetCardAlert
        variant={`info`}
        icon={hasEntryToShare ? <AcceptIcon /> : null}
        clamp={
          hasEntryToShare ? (
            <Clamp
              className={styles.clickable}
              onClick={() => setShareEntryModalOpen(true)}
              variant={`info`}
              animateIn
            >
              <ShareIcon className={styles.clampIcon} />
              Share
            </Clamp>
          ) : null
        }
      >
        {`Bet Accepted: B-${combination.entryId}`}
        {hasEntryToShare && (
          <ShareEntryAction
            {...getModalProps({
              entry: [entryToShare, isLoadingEntry],
              referenceId: combination.betId,
            })}
          />
        )}
      </BetCardAlert>
    );
  }

  if (combination?.rejected) {
    let label = "Got It";
    let action: () => void = () => dispatch(clearRejectedCombo(type));

    if (combination.rejected.reason === "insufficient.funds") {
      label = "Deposit";
      action = () => {
        dispatch(clearRejectedCombo(type));
        navigate(`/wallet/deposit`);
      };
    } else if (combination.rejected.alternativeStake) {
      const combinations = calculateCombinations(selectionsCount, Number(type));
      label = "Update";
      action = () => {
        const newStake = new BigNumber(combination.rejected.alternativeStake)
          .dividedBy(100)
          .toNumber();
        dispatch(
          setCombinationTotalStake({
            type,
            stake: newStake,
            combinations,
          }),
        );
        dispatch(
          syncCombinationsTotalStake({
            type,
            combinations,
          }),
        );
      };
    }

    return (
      <BetCardAlert
        variant={`danger`}
        clamp={
          <Clamp
            className={styles.clickable}
            onClick={action}
            variant={`danger`}
          >
            {label}
          </Clamp>
        }
      >
        {combination.rejected.message}
      </BetCardAlert>
    );
  }

  return null;
};

export { ComboCardAlert };
