import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import * as styles from "./Separator.module.scss";
import cx from "classnames";

const separatorVariants = cva(null, {
  variants: {
    size: {
      default: styles.lg,
      md: styles.md,
      sm: styles.sm,
    },
  },
  defaultVariants: {
    size: "default",
  },
});

type SeparatorProps = {
  className?: string;
} & VariantProps<typeof separatorVariants>;

const Separator = ({ className, size }: SeparatorProps) => {
  return (
    <div
      data-testid={`separator`}
      className={cx(styles.separator, separatorVariants({ size }), className)}
    />
  );
};

Separator.displayName = "Separator";

export { Separator };
