// extracted by mini-css-extract-plugin
export var arrowDown = "SameEventMultiSummary-module--arrowDown--7a99c";
export var arrowUp = "SameEventMultiSummary-module--arrowUp--27fca";
export var blue = "#0eb3f2";
export var emphasis = "SameEventMultiSummary-module--emphasis--ba581";
export var green = "#57d33b";
export var greenLink = "SameEventMultiSummary-module--green-link--69793";
export var grey = "var(--tone-mid)";
export var label = "SameEventMultiSummary-module--label--2ece5";
export var line = "SameEventMultiSummary-module--line--df00e";
export var notchGap = "32px";
export var odds = "SameEventMultiSummary-module--odds--cbdb7";
export var oddsChanging = "SameEventMultiSummary-module--oddsChanging--25bf8";
export var orange = "#f8662b";
export var red = "#ff2741";
export var text = "SameEventMultiSummary-module--text--c67e5";
export var textValue = "SameEventMultiSummary-module--textValue--7b1c2";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";