import React, { useContext } from "react";
import cx from "classnames";
import {
  type RaceEventType,
  RaceOutcomeTypeTypes,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import {
  formatStartingPosition,
  getJockeyLabel,
} from "utilities/racingUtilities";
import { RaceOutcome, Silks } from "sections/Betting/Race/components";
import * as styles from "./TipsCompetitorInfo.module.scss";
import type { MappedCompetitorType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { RaceContext } from "sections/Betting/Race/Race";
import { Fav } from "sections/Betting/Race/components/RaceOutcomes/Fav";
import { FlucsIcon } from "sections/Betting/Race/components/RaceOutcomes/FlucsIcon";

export type CompetitorProps = {
  competitor: MappedCompetitorType;
  race: RaceEventType;
  showPrices: boolean;
  validCompetitorCount: number;
  toggleRace: (outcomeId: string, marketId: string, odds: number) => void;
  className?: string;
  topFlucs?: { competitorId: string; position: number }[];
  showRunnerNumber?: boolean;
};

export const TipsCompetitorInfo = ({
  competitor,
  race,
  showPrices,
  validCompetitorCount,
  toggleRace,
  className,
  topFlucs,
  showRunnerNumber,
}: CompetitorProps) => {
  const { favOdds } = useContext(RaceContext);

  const isFavorite = Object.values(competitor?.markets || {}).some(
    (x) =>
      x.outcome.type === RaceOutcomeTypeTypes.Win && x.outcome.odds === favOdds,
  );

  const flucsPosition = topFlucs?.find(
    (x) => x.competitorId === competitor.id,
  )?.position;

  return (
    <div key={competitor.id} className={className}>
      <div className={styles.silksContainer}>
        {showRunnerNumber && (
          <div className={styles.number}>{competitor.number}</div>
        )}
        <Silks
          competitorNumber={competitor.number}
          imageHeight={40}
          silksUrl={race?.silksUrl}
        />
      </div>
      {competitor.name && (
        <div className={styles.name}>{`${competitor.name} ${
          showRunnerNumber && competitor.startingPosition
            ? `(${formatStartingPosition(competitor.startingPosition)})`
            : ""
        }`}</div>
      )}
      {competitor.jockey && (
        <div className={styles.jockey}>
          {getJockeyLabel(competitor.jockey, race?.sport)}
        </div>
      )}
      {competitor.trainer && (
        <div className={styles.trainer}>T: {competitor.trainer}</div>
      )}
      <div>&nbsp;</div>
      {showPrices && (
        <div className={cx(styles.markets)}>
          {Object.values(competitor.markets || {}).map((m) => (
            <RaceOutcome
              key={m.outcomeId}
              handleToggleSelection={() =>
                toggleRace(m.outcomeId, m.marketId, Number(m.outcome.odds))
              }
              marketType={m.marketType}
              disabled={race?.status !== RaceStatuses.OPEN}
              validCompetitorCount={validCompetitorCount}
              {...m}
              className={styles.tipsOutcome}
            />
          ))}
          {isFavorite ? <Fav /> : null}
          {flucsPosition && (
            <FlucsIcon position={flucsPosition} isFavorite={isFavorite} />
          )}
        </div>
      )}
    </div>
  );
};
