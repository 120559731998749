import { isEqual } from "lodash";
import { useRef } from "react";
import {
  type Direction,
  getOddsChangeDirection,
} from "utilities/sharedBettingUtilities";
import { v4 } from "uuid";

export type OddsChange = {
  odds: number;
  direction: Direction;
  id: string;
};

export const useUpdatedOddsArray = (
  updatedOdds: number[],
  openingOdds: number,
) => {
  const prevOdds = useRef<OddsChange[]>([]);

  if (
    isEqual(
      prevOdds.current.map((odds) => odds.odds),
      updatedOdds,
    )
  ) {
    return prevOdds.current;
  }

  const newOdds = updatedOdds.map((odds, index) => {
    // when there are less than 4 items this means the index would not have shifted. We double check this by comparing the odds
    const shiftIndex =
      prevOdds.current[index]?.odds === odds && prevOdds.current.length < 4
        ? 0
        : 1;

    // if the index exists in the previous odds array we use the same id, otherwise we generate a new one
    const id = prevOdds.current[index + shiftIndex]?.id ?? v4();

    const previousUpdatedOdd =
      index === 0 ? openingOdds : updatedOdds[index - 1];

    const direction = getOddsChangeDirection(previousUpdatedOdd, odds);

    return {
      odds,
      direction,
      id: id,
    } satisfies OddsChange;
  });

  prevOdds.current = newOdds;

  return newOdds;
};
