import { useIsomorphicLayoutEffect, useLockedBody } from "usehooks-ts";
import { useIsMobile } from "hooks";
import useScrollbarSize from "react-scrollbar-size";

const useLockScroll = (shouldLock: boolean) => {
  const [_, setLocked] = useLockedBody(false);
  const isMobile = useIsMobile();

  const { width } = useScrollbarSize();

  useIsomorphicLayoutEffect(() => {
    setLocked(shouldLock);
  }, [shouldLock, setLocked]);

  useIsomorphicLayoutEffect(() => {
    const body = document.body;

    // fix body on mobile
    if (shouldLock) {
      body.style.setProperty("padding-right", `${width}px`);

      if (isMobile) {
        body.style.setProperty("position", "fixed");
      }
    } else {
      body.style.removeProperty("position");
      body.style.removeProperty("padding-right");
    }
  }, [shouldLock]);
};

export default useLockScroll;
