import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import {
  type RacingRouteParams,
  RegionType,
} from "sections/Betting/Racing/hooks/useRacingRoute";
import { getFirestore } from "store/getFirebase";
import { getDateForSelectedTab } from "sections/Betting/Race/hooks/useRaceMeetings";

const UPDATE_INTERVAL = 600000; // 10 minutes

type CountResult = {
  harness_racing: number;
  horse_racing: number;
  greyhounds: number;
};

export const useRaceCounts = (route: Partial<RacingRouteParams>) => {
  const [titleCounts, setTitleCounts] = useState<CountResult>({
    harness_racing: 0,
    horse_racing: 0,
    greyhounds: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchCounts = async () => {
    setLoading(true);
    const db = getFirestore();
    const countsRef = collection(db, "raceMeetings");
    const sportCodes = ["harness_racing", "horse_racing", "greyhounds"];
    const regionCode =
      route.region.length === 1 && route.region.includes(RegionType.au)
        ? "ANZ"
        : route.region.length === 1 && route.region.includes(RegionType.world)
          ? "ROW"
          : null;

    const newCounts: CountResult = {
      harness_racing: 0,
      horse_racing: 0,
      greyhounds: 0,
    };

    for (const sport of sportCodes) {
      const queries = [where("sport", "==", sport.toUpperCase() as any)];

      const dateForSelectedTab = getDateForSelectedTab(route.tab);

      queries.push(
        where(
          "currentRace.scheduledStartTimeTs",
          ">=",
          dateForSelectedTab.start,
        ),
      );
      queries.push(
        where("currentRace.scheduledStartTimeTs", "<=", dateForSelectedTab.end),
      );

      if (regionCode) {
        if (route.region.length === 1 && route.region.includes(RegionType.au)) {
          queries.push(where("region", "==", "ANZ"));
        } else if (
          route.region.length === 1 &&
          route.region.includes(RegionType.world)
        ) {
          queries.push(where("region", "==", "ROW"));
        }
      }

      const snapshot = await getCountFromServer(query(countsRef, ...queries));
      newCounts[sport as keyof CountResult] = snapshot.data().count;
    }

    setTitleCounts(newCounts);
    setLoading(false);
  };

  useEffect(() => {
    fetchCounts();
    const intervalId = setInterval(fetchCounts, UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [route?.region?.length, route?.tab]);

  return { titleCounts, loading };
};
