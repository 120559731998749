import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "store/createStore";
import type { NullableMaybe, Nullable } from "types/utilities";
import { fetchWithAppCheck as fetch } from "utilities/api/fetchWithAppCheck";

const API_URL = process.env.GATSBY_API_URI;

export const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type GraphPreviousMonth = {
  first: string;
  second: number;
};

export type GraphData = {
  previousMonth1: GraphPreviousMonth;
  previousMonth2: GraphPreviousMonth;
  previousMonth3: GraphPreviousMonth;
  previousMonth4: GraphPreviousMonth;
  previousMonth5: GraphPreviousMonth;
  previousMonth6: GraphPreviousMonth;
  sixMonthAggregate: number;
  twelveMonthDifference: number;
};

export type ActivityStatementGraphData = {
  netChangeData: GraphData;
  totalSpendData: GraphData;
};

export type Statement = {
  availableSpend: number;
  closingBalance: number;
  depositSum: number;
  id: string;
  lostBetCount: number;
  netChange: number;
  netGain: number;
  netLoss: number;
  nonRejectedBetCount: number;
  openingBalance: number;
  promotionSpend: number;
  reportId: string;
  totalSpend: number;
  userId: string;
  withdrawalSum: number;
  wonBetCount: number;
};

export type ActivityStatementData = {
  graph: ActivityStatementGraphData;
  statement: Statement;
  startDate: string;
  endDate: string;
};

/**
 * Fetches the activity statement data at the endpoint provided.
 *
 * The endpoint can either be a UUID or an ISO date. e.g "date/01-02-22" or "d9ef1c9e-909f-4b8c-9cb7-9580163406c5"
 */
export const useActivityStatement = (endpoint: string) => {
  const auth = useSelector((state: RootState) => state?.auth);
  const [activityStatement, setActivityStatement] =
    useState<Nullable<ActivityStatementData>>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Nullable<{ message: string }>>(null);

  useMemo(() => {
    if (!endpoint) return;
    const fetchActivityStatement = async () => {
      const url = `${API_URL}/activityStatement/${endpoint}`;

      setLoading(true);

      try {
        const res = await fetch(url, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        if (res.status === 404) {
          setActivityStatement(null);
          setError({ message: "No activity statement for the given period" });
          setLoading(false);
          return;
        }

        const data = await res.json();

        if (res.status !== 200) {
          setActivityStatement(null);
          setError(data.errors?.[0]);
          setLoading(false);
          return;
        }

        setActivityStatement(data);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchActivityStatement();
  }, [endpoint, auth.userId]);

  return [activityStatement, loading, error] as const;
};

const parseActivityStatementDates = (
  isoStrings: string[],
): { [key: string]: string[] } => {
  const dataOut: Record<string, string[]> = {};

  isoStrings.sort().forEach((isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = date.getMonth();

    if (dataOut[String(year)]) {
      dataOut[String(year)].push(allMonths[month]);
    } else {
      dataOut[String(year)] = [allMonths[month]];
    }
  });

  return dataOut;
};

/**
 * Returns a list of months with available activity statements for an authed user, e.g ["1 Jul 2021", "1 Oct 2022", ...]
 */
export const useActivityStatementMonths = () => {
  const auth = useSelector((state: any) => state?.auth);
  const [months, setMonths] =
    useState<Nullable<Record<string, string[]>>>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<NullableMaybe<string>>(undefined);

  useMemo(() => {
    const fetchActivityStatementMonths = async () => {
      const url = `${API_URL}/activityStatement/months`;

      setLoading(true);

      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status === 404) {
        setMonths(null);
        setLoading(false);
        return;
      }

      const data: { result: string[]; errors?: string[] } = await res.json();

      if (res.status !== 200) {
        setMonths(null);
        setError(data.errors?.[0]);
        setLoading(false);
        return;
      }

      const isoStrings = data.result ?? [];

      setMonths(parseActivityStatementDates(isoStrings));
      setLoading(false);
    };

    fetchActivityStatementMonths();
  }, []);

  return [months, loading, error] as const;
};

export const useActivityStatementCount = () => {
  const [months] = useActivityStatementMonths();

  return Object.keys(months ?? {}).length;
};
