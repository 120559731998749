// extracted by mini-css-extract-plugin
export var badge = "CardFooter-module--badge--efcfb";
export var blue = "#0eb3f2";
export var container = "CardFooter-module--container--8fc01";
export var content = "CardFooter-module--content--d22dd";
export var emphasis = "CardFooter-module--emphasis--2acc2";
export var green = "#57d33b";
export var greenLink = "CardFooter-module--green-link--3151a";
export var grey = "var(--tone-mid)";
export var label = "CardFooter-module--label--64f0e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";