import React from "react";
import { PageMessage } from "components/PageMessage";
import { NextBettingButtons } from "components/layout/NextBettingButtons";
import { useIsMobile } from "hooks";
import { ReactComponent as EmptyIcon } from "../assets/empty.svg";

import * as styles from "./Empty.module.scss";

const Empty = () => {
  const isMobile = useIsMobile();
  const btnVariant = isMobile ? "primary" : "blurred";

  return (
    <PageMessage
      className={styles.container}
      image={<EmptyIcon />}
      title={`Your Betslip is Empty`}
      subTitle={`Check out today’s action to make a bet`}
    >
      <NextBettingButtons
        className={styles.buttons}
        variant={btnVariant}
        size="md"
      />
    </PageMessage>
  );
};

export { Empty };
