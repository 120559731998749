import * as Yup from "yup";
import { differenceInHours, isValid, parseISO, sub } from "date-fns";

import { LEGAL_NAME } from "utilities/regex";

export const userVerificationSchema = Yup.object().shape({
  firstName: Yup.string().matches(LEGAL_NAME, "Invalid first name"),
  lastName: Yup.string().matches(LEGAL_NAME, "Invalid last name"),
  dateOfBirth: Yup.string()
    .required("A valid date of birth is required")
    .test("age", "You must be at least 18 years old", (dob) => {
      const date = parseISO(dob);
      return (
        isValid(date) &&
        differenceInHours(sub(new Date(), { years: 18 }), date) >= 0
      );
    }),
  consent: Yup.bool()
    .required("You must consent to Picklebet checking the information above.")
    .oneOf(
      [true],
      "You must consent to Picklebet checking the information above.",
    ),
});
