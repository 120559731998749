import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  type ForwardRefExoticComponent,
} from "react";
import cx from "classnames";
import { Link } from "components/Link";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { Router, useLocation } from "@gatsbyjs/reach-router";
import { useLocalePrefix } from "hooks/useLocale";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollArea } from "components/ScrollArea";
import escapeRegExp from "lodash/escapeRegExp";
import { v4 as uuidv4 } from "uuid";
import { Badge } from "components/Badge";
import { Shadow } from "components/Shadow";
import { useScrollBoundary } from "hooks/useScrollBoundary";

import * as styles from "./Tabs.module.scss";

type Tab = {
  to?: string;
  value?: string;
  badge?: string | React.ReactNode;
} & Omit<React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>, "value">;

type TabsProps = {
  basePath?: string;
  isValueDerivedFromRouter?: boolean; // determines if tab value is provided or derived from router
} & React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>;

type TabsContentProps = {
  children: React.ReactNode;
};

type TabListProps = {
  widget?: React.ReactNode;
  containerClassName?: string;
} & React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>;

export const PathContext = createContext<string>("/");
export const SelectedTabContext = createContext<string | null>(null);
export const UniqueIdContext = createContext<string | null>(null);

export const ScrollContext = createContext<(x: number) => void>((_) => {
  // do nothing
});

/**
 * Extracts the part of the pathname that comes after the prefix and basePath.
 *
 * @param {string} prefix - The prefix of the pathname (e.g., "/en-au").
 * @param {string} basePath - The base path of the pathname (e.g., "/account").
 * @param {string} pathname - The full pathname from which to extract the part.
 *
 * @returns {string | null} The part of the pathname that comes after the prefix and basePath,
 *                          or null if no such part is found.
 */
const parseValueFromPathname = (
  prefix: string,
  basePath: string,
  pathname: string,
): string | null =>
  pathname.match(
    new RegExp(`^${escapeRegExp(prefix + basePath)}/([\\w-]*)`),
  )?.[1] || "";

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  TabsProps
>(
  (
    { basePath = "", isValueDerivedFromRouter = false, value, ...props },
    ref,
  ) => {
    const prefix = useLocalePrefix();
    const { pathname } = useLocation();
    const [uniqueId] = useState(uuidv4());

    const parsedValue = isValueDerivedFromRouter
      ? parseValueFromPathname(prefix, basePath, pathname)
      : value;

    const valueWithBasePath = isValueDerivedFromRouter
      ? `${basePath}/${parsedValue}`
      : parsedValue;

    return (
      <UniqueIdContext.Provider value={uniqueId}>
        <PathContext.Provider value={basePath || "/"}>
          <SelectedTabContext.Provider value={valueWithBasePath || null}>
            <TabsPrimitive.Root
              value={valueWithBasePath || undefined}
              ref={ref}
              {...props}
            />
          </SelectedTabContext.Provider>
        </PathContext.Provider>
      </UniqueIdContext.Provider>
    );
  },
);

Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  TabListProps
>(({ className, containerClassName, widget, ...props }, ref) => {
  const { viewportRef, isScrollable, isStart, isEnd } = useScrollBoundary();

  return (
    <TabsPrimitive.List ref={ref} className={containerClassName} {...props}>
      <ScrollArea
        viewPortRef={viewportRef}
        hideScrollbars
        className={styles.tabContainer}
      >
        <ScrollContext.Provider
          value={(x) => {
            const scroller = viewportRef.current;
            if (!scroller || scroller.scrollWidth <= scroller.offsetWidth)
              return;
            // smoothly scroll to x
            viewportRef.current.scrollTo({
              left: x - 40,
              behavior: "smooth",
            });
          }}
        >
          <div className={cx(styles.list, className)}>{props.children}</div>
          <AnimatePresence>
            {!isStart && isScrollable && <Shadow position="left" />}
          </AnimatePresence>
          <AnimatePresence>
            {!isEnd && isScrollable && <Shadow position="right" />}
          </AnimatePresence>
        </ScrollContext.Provider>
      </ScrollArea>
      {widget}
    </TabsPrimitive.List>
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const Tab = React.forwardRef<React.Ref<typeof TabsPrimitive.Trigger>, Tab>(
  (
    { className, to = "", value = "", badge, children, onClick, ...props },
    ref,
  ) => {
    const basePath = useContext(PathContext);
    const selectedTab = useContext(SelectedTabContext);
    const scrollLeft = useContext(ScrollContext);
    const innerRef = useRef<HTMLButtonElement>(null);
    const { pathname } = useLocation();
    // there is a slight delay on first refocus, so we need to account for that
    const [isFirstRefocus, setIsFirstRefocus] = useState(true);
    const isActive =
      value === selectedTab || (selectedTab && to)
        ? selectedTab.endsWith(to)
        : false;

    useImperativeHandle(ref, () => ({
      get current() {
        // need to revisit this, tabs need to migrate away from the legacy
        // React.Children api perhaps that's a better time to reshuffle the
        // structure and remove the imperative handle
        return innerRef.current as unknown as ForwardRefExoticComponent<
          TabsPrimitive.TabsTriggerProps &
            React.RefAttributes<HTMLButtonElement>
        >;
      },
    }));

    const scrollIntoView = () => {
      if (!innerRef.current) return;
      scrollLeft(innerRef.current.offsetLeft);
      setIsFirstRefocus(false);
    };

    useEffect(() => {
      // make sure active tab is visible one component is mounted
      // we determine if tab is active by checking data-state=active attribute
      if (!innerRef.current) return;

      if (innerRef.current.getAttribute("data-state") === "active") {
        // timeout is here to make sure layout is rendered, and we can accurately calculate offsetLeft
        setTimeout(scrollIntoView, isFirstRefocus ? 1000 : 0);
      }
    }, [pathname, selectedTab]);

    if (props.hidden) {
      return null;
    }

    if (to) {
      return (
        <TabsPrimitive.Trigger
          ref={innerRef}
          className={cx(styles.trigger, className)}
          value={`${basePath}${to}`}
          asChild
          onClick={(e) => {
            scrollIntoView();
            onClick?.(e);
          }}
          {...props}
        >
          {/* reach router types are weird, had to override styles */}
          <Link to={`${basePath}${to}`}>
            <>
              {children}
              {typeof badge === "string" ? <Badge text={badge} /> : badge}
            </>
            <Underline isActive={isActive} />
          </Link>
        </TabsPrimitive.Trigger>
      );
    }

    return (
      <TabsPrimitive.Trigger
        ref={innerRef}
        className={cx(styles.trigger, className)}
        value={value}
        {...props}
      >
        {children}
        {typeof badge === "string" ? <Badge text={badge} /> : badge}
        <Underline isActive={isActive} />
      </TabsPrimitive.Trigger>
    );
  },
);
Tab.displayName = TabsPrimitive.Trigger.displayName;

const Underline = ({ isActive }: { isActive: boolean }) => {
  const uniqueId = useContext(UniqueIdContext);
  if (!isActive) return null;

  return (
    <motion.div layout layoutRoot className={styles.underlineContainer}>
      <motion.div
        className={styles.underline}
        layoutId={`underline-${uniqueId}`}
        transition={{ duration: 0.15 }}
      />
    </motion.div>
  );
};

const TabsContent = ({ children }: TabsContentProps) => {
  const basePath = useContext(PathContext);
  const prefix = useLocalePrefix();

  // we need to use reach router here because radix tabs doesn't support nested routes
  return (
    <Router primary={false} basepath={`${prefix}${basePath}`}>
      {React.Children.map(
        children as React.ReactElement<{ path: string }>[],
        (child) => {
          if (!React.isValidElement(child)) return null;
          return React.cloneElement(
            child,
            child.props,
            <TabContent value={child.props.path} />,
          );
        },
      )}
    </Router>
  );
};

const TabContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Content
      ref={ref}
      className={cx(styles.content, className)}
      {...props}
    />
  );
});
TabContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, Tab, TabContent, TabsContent };
