import React from "react";
import { useSelector } from "react-redux";
import { selectUnusedSelections } from "sections/Betting/Betslip/betslipSlice";
import { Separator } from "components/Separator";
import { BetCard } from "./BetCard";

import * as styles from "./UnusedSelections.module.scss";

const UnusedSelections = () => {
  const selections = useSelector(selectUnusedSelections);

  if (selections.length === 0) {
    return null;
  }

  return (
    <>
      <Separator className={styles.separator} />
      <div className={styles.text}>Unused Selections</div>
      {selections.map((outcomeId) => (
        <BetCard key={`card-${outcomeId}`} outcomeIds={[outcomeId]} />
      ))}
    </>
  );
};

export { UnusedSelections };
