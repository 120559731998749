import React from "react";
import cx from "classnames";
import * as styles from "./Loader.module.scss";

export const Loader = ({
  text = "Loading",
  className = undefined as string,
  children = undefined as JSX.Element,
}) => (
  <div className={cx(styles.pageLoader, className)}>
    <div className={styles.loader}>
      <div className={styles.image}>{children}</div>
      <h3>{text}</h3>
    </div>
  </div>
);

export default Loader;
