// extracted by mini-css-extract-plugin
export var arrowDown = "OddsWithChanges-module--arrowDown--6a865";
export var arrowUp = "OddsWithChanges-module--arrowUp--9cf33";
export var blue = "#0eb3f2";
export var emphasis = "OddsWithChanges-module--emphasis--19c24";
export var green = "#57d33b";
export var greenLink = "OddsWithChanges-module--green-link--dda8f";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var odds = "OddsWithChanges-module--odds--7437f";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";