// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var circle = "Speedmap-module--circle--a31e1";
export var emphasis = "Speedmap-module--emphasis--d3b8c";
export var green = "#57d33b";
export var greenLink = "Speedmap-module--green-link--58599";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var position = "Speedmap-module--position--b70e4";
export var red = "#ff2741";
export var speedmap = "Speedmap-module--speedmap--a8813";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";