import { useEffect } from "react";
import * as pickslipSlice from "sections/Pickems/PickSlip/pickslipSlice";
import type { PickemsContest, PickemsEntry } from "./types";
import usePickemsContests from "./usePickemsContests";
import { useLivePickemsEntries } from "./usePickemsEntries";

const usePickemsLobby = (): [
  entries: PickemsEntry[],
  contests: PickemsContest[],
  loading: boolean,
] => {
  useEffect(() => {
    pickslipSlice.updatePickslip({ contestId: null });
  }, []);

  const [contests, contestsLoading] = usePickemsContests();
  const [entries, entriesLoading] = useLivePickemsEntries();

  return [entries, contests, contestsLoading || entriesLoading];
};

export default usePickemsLobby;
