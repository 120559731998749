import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Validator from "../Validator/Validator";
import * as styles from "./Checkbox.module.scss";
import cx from "classnames";
import Icon from "../Icon";

class Checkbox extends PureComponent {
  static propTypes = {
    issues: PropTypes.object,
    label: PropTypes.string,
    text: PropTypes.node,
    onChange: PropTypes.func,
    gutterBottom: PropTypes.bool,
    gutterTop: PropTypes.bool,
    gutterLeft: PropTypes.bool,
    gutterRight: PropTypes.bool,
    className: PropTypes.string,
  };
  render() {
    const {
      issues,
      label,
      text,
      onChange,
      gutterBottom,
      gutterTop,
      gutterLeft,
      gutterRight,
      checked = false,
      className: classNameProps,
    } = this.props;

    const containerClassName = cx(
      {
        [styles.container]: true,
        [styles.gutterBottom]: gutterBottom,
        [styles.gutterTop]: gutterTop,
        [styles.gutterLeft]: gutterLeft,
        [styles.gutterRight]: gutterRight,
      },
      classNameProps,
    );
    return (
      <div>
        <label className={containerClassName}>
          {label ? <div className={styles.label}>{label}</div> : null}
          <Validator
            className={styles.validator}
            issues={issues}
            showTooltip={false}
          >
            <input
              className={styles.input}
              type="checkbox"
              onChange={() => onChange(!checked)}
              checked={checked}
            />
            <span
              className={cx({
                [styles.fakeInput]: true,
                [styles.checked]: checked,
              })}
            >
              {checked ? <Icon type="check" width={15} height={15} /> : null}
            </span>
            <span className={styles.text}>{text}</span>
          </Validator>
        </label>
      </div>
    );
  }
}

export default Checkbox;
