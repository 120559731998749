import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import * as styles from "./Select.module.scss";
import cx from "classnames";
import Validator from "../Validator/Validator";

const fontStyles = {
  fontSize: styles.fontSizeInput,
  color: styles.colorWhite,
  opacity: styles.inputFontOpacity,
  fontWeight: styles.inputFontWeight,
  fontFamily: styles.fontFamily,
};

class Select extends PureComponent {
  static propTypes = {
    gutterBottom: PropTypes.bool,
    gutterTop: PropTypes.bool,
    gutterLeft: PropTypes.bool,
    gutterRight: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    onPressEnter: PropTypes.func,
    issue: PropTypes.array,
    disableKeyboardNavigation: PropTypes.bool,
    extraStyles: PropTypes.object,
  };

  static defaultProps = {
    disableKeyboardNavigation: false,
  };

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  focus = () => {
    this.selectRef.current.focus();
  };

  onKeyDown = (event) => {
    const { onPressEnter, onKeyPress, disableKeyboardNavigation } = this.props;
    if (event.which === 13 && onPressEnter) {
      onPressEnter(event.target.value);
    } else if (
      disableKeyboardNavigation &&
      ["ArrowUp", "ArrowDown"].includes(event.key)
    ) {
      event.preventDefault();
    }

    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  render() {
    const {
      className: classNameProps,
      gutterBottom = false,
      gutterTop = false,
      gutterLeft = false,
      gutterRight = false,
      label,
      issue,
      disableKeyboardNavigation,
      extraStyles = {},
      ...props
    } = this.props;

    const hasIssues = issue && issue.length > 0;

    const customStyles = Object.assign(
      {},
      {
        container: (base) => ({
          ...base,
          color: styles.colorWhite,
          ":focus": {
            outline: 0,
          },
          padding: 0,
        }),
        control: (base) => ({
          ...base,
          borderRadius: styles.borderRadius,
          backgroundColor: hasIssues
            ? styles.backgroundColorError
            : styles.backgroundColor,
          color: styles.colorWhite,
          borderWidth: 0,
          boxShadow: "none",
          ":hover": {
            outline: 0,
            borderWidth: 0,
          },
          padding: 0,
        }),
        valueContainer: (base) => ({
          ...fontStyles,
          padding: styles.controlPadding,
        }),
        input: () => {
          return {
            fontSize: styles.fontSizeInput,
            margin: 0,
            padding: 0,
            color: styles.colorWhite,
            opacity: styles.inputFontOpacity,
            fontWeight: styles.inputFontWeight,
          };
        },
        indicatorSeparator: (base) => ({
          margin: 0,
          width: 0,
          backgroundColor: "transparent",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingRight: hasIssues ? "32px" : "8px",
        }),
        menu: (base) => ({
          ...base,
          borderRadius: styles.borderRadius,
          backgroundColor: styles.backgroundColor,
          color: styles.colorWhite,
          borderWidth: 0,
          boxShadow: "none",
        }),
        singleValue: (base) => {
          return {
            ...base,
            ...fontStyles,
          };
        },
        option: (base, { isSelected, isFocused }) => ({
          ...base,
          ":active": {
            backgroundColor: styles.backgroundColorHover,
          },
          ":hover": {
            backgroundColor: styles.backgroundColorHover,
          },
          backgroundColor: isSelected
            ? styles.backgroundColorSelected
            : isFocused
              ? disableKeyboardNavigation
                ? styles.backgroundColor
                : styles.backgroundColorFocus
              : styles.backgroundColor,
          paddingLeft: styles.optionPadding,
          paddingRight: styles.optionPadding,
          ...fontStyles,
        }),
      },
      extraStyles,
    );

    const className = cx(
      {
        [styles.gutterBottom]: gutterBottom,
        [styles.gutterTop]: gutterTop,
        [styles.gutterLeft]: gutterLeft,
        [styles.gutterRight]: gutterRight,
      },
      classNameProps,
    );

    const select = label ? (
      <label className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
        <Validator issues={issue}>
          <ReactSelect
            className={styles.defaultSelect}
            styles={customStyles}
            ref={this.selectRef}
            onKeyDown={this.onKeyDown}
            autoFocus={false}
            {...props}
          />
        </Validator>
      </label>
    ) : (
      <ReactSelect
        className={styles.defaultSelect}
        styles={customStyles}
        ref={this.selectRef}
        {...props}
        onKeyDown={this.onKeyDown}
      />
    );

    return <div className={className}>{select}</div>;
  }
}

export default Select;
