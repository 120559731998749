import React from "react";
import Icon from "../Icon";

export const TitleIcon: React.FC<{
  title?: Record<any, any>;
  isActive?: boolean;
  svg?: boolean;
  className?: string;
  inline?: any;
}> = ({ title, ...otherProps }) => {
  if (!title) {
    return null;
  }

  if (title?.icon && typeof title?.icon === "string") {
    return <Icon type={title.icon} {...otherProps} />;
  } else if (title?.icon?.file?.url) {
    return <Icon type={"title"} src={title.icon?.file?.url} {...otherProps} />;
  } else {
    return <Icon type="question" {...otherProps} />;
  }
};
