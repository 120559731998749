// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var box = "RaceResult-module--box--adefd";
export var deadHeat = "RaceResult-module--deadHeat--1d633";
export var emphasis = "RaceResult-module--emphasis--57721";
export var firstPlace = "RaceResult-module--firstPlace--7f0b0";
export var fourthPlace = "RaceResult-module--fourthPlace--8d9d1";
export var green = "#57d33b";
export var greenLink = "RaceResult-module--green-link--b0860";
export var grey = "var(--tone-mid)";
export var label = "RaceResult-module--label--7be29";
export var medal = "RaceResult-module--medal--2582b";
export var notchGap = "32px";
export var odds = "RaceResult-module--odds--a0d9e";
export var orange = "#f8662b";
export var red = "#ff2741";
export var resultOutcome = "RaceResult-module--resultOutcome--4b0c9";
export var resultOutcomes = "RaceResult-module--resultOutcomes--2be5b";
export var resultsContainer = "RaceResult-module--resultsContainer--7236f";
export var secondPlace = "RaceResult-module--secondPlace--6b9c7";
export var secondaryInformation = "RaceResult-module--secondaryInformation--439f7";
export var thirdPlace = "RaceResult-module--thirdPlace--b4cc5";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var winnerInfo = "RaceResult-module--winnerInfo--37fcd";
export var winnerName = "RaceResult-module--winnerName--be6e7";
export var yellow = "#ffb100";