// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var box = "Competitor-module--box--9d3ce";
export var clamp = "Competitor-module--clamp--b154c";
export var clampShadow = "Competitor-module--clampShadow--bd773";
export var competitor = "Competitor-module--competitor--df9af";
export var competitorContainer = "Competitor-module--competitorContainer--b12da";
export var competitorInfoContainer = "Competitor-module--competitorInfoContainer--27708";
export var container = "Competitor-module--container--ddecc";
export var desktopOutcomes = "Competitor-module--desktopOutcomes--80e47";
export var emphasis = "Competitor-module--emphasis--42a0f";
export var flucs = "Competitor-module--flucs--0ff39";
export var green = "#57d33b";
export var greenLink = "Competitor-module--green-link--4e300";
export var grey = "var(--tone-mid)";
export var heading = "Competitor-module--heading--1a820";
export var isShowingShortForm = "Competitor-module--isShowingShortForm--ffafe";
export var isWinTab = "Competitor-module--isWinTab--d34d2";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scratched = "Competitor-module--scratched--2ee2f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";