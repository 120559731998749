// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var displayNone = "Outcomes-module--displayNone--e04b1";
export var emphasis = "Outcomes-module--emphasis--47eb7";
export var green = "#57d33b";
export var greenLink = "Outcomes-module--green-link--8d984";
export var grey = "var(--tone-mid)";
export var hidden = "Outcomes-module--hidden--35eed";
export var lastAndEven = "Outcomes-module--lastAndEven--cdd45";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcome = "Outcomes-module--outcome--0e2a9";
export var outcomes = "Outcomes-module--outcomes--0abda";
export var outright = "Outcomes-module--outright--c3650";
export var outrightOutcome = "Outcomes-module--outrightOutcome--fbb08";
export var red = "#ff2741";
export var vs = "Outcomes-module--vs--aa603";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";