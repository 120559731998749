import {
  collection,
  type CollectionReference,
  doc,
  type DocumentData,
  type DocumentReference,
  type FirestoreDataConverter,
  type FirestoreError,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import { useCollectionData, useDocumentData } from "hooks";
import { useMemo } from "react";
import { FireTransforms } from "utilities/FireTransforms";
import { getFirestore } from "store/getFirebase";
import type { PickemsContest } from "./types";
import { transformContest } from "./helpers";
import usePickemsMarkets from "./usePickemsMarkets";

const contestConverter: FirestoreDataConverter<PickemsContest> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    const contest = snapshot.data(options);
    return {
      ...contest,
      id: snapshot.id,
      scheduledStartTime: FireTransforms.parseDate(contest.scheduledStartTime),
    };
  },
};

export const usePickemsContest = (
  contestId: string,
): [PickemsContest, boolean, FirestoreError] => {
  const contestRef = contestId
    ? (doc(getFirestore(), "pickemsContests", contestId).withConverter(
        contestConverter,
      ) as DocumentReference<PickemsContest>)
    : undefined;
  const [contest, contestLoading, contestError] = useDocumentData(contestRef);
  const [markets, marketsLoading, marketsError] = usePickemsMarkets(contestId);

  const transformedContest = useMemo(() => {
    if (contestLoading || marketsLoading) return;

    return transformContest(contest, markets);
  }, [contest, markets]);

  return [
    transformedContest,
    marketsLoading || contestLoading,
    contestError || marketsError,
  ];
};

const usePickemsContests = (): [PickemsContest[], boolean, FirestoreError] => {
  const contestsRef = collection(
    getFirestore(),
    "pickemsContests",
  ).withConverter(contestConverter) as CollectionReference<PickemsContest>;
  const [contests, loading, error] = useCollectionData(
    contestsRef,
    contestsRef?.path,
  );

  return [contests ?? [], loading, error];
};

export default usePickemsContests;
