// noinspection JSUnusedGlobalSymbols
import queryString from "query-string";
import * as Sentry from "@sentry/gatsby";
import {
  ATTRIBUTION_KEY,
  PLATFORM,
  ISLOCALHOST,
  VERSION,
} from "./src/appConfig";
import localObject from "utilities/localObject";
import { isWebView, isAppleWebView, isAndroidWebView } from "utilities/display";
import { wrapApp } from "./src/wrapApp";
import "gatsby-plugin-segment-js";
import { getStore } from "./src/store";
import { requestFinished, requestStarted } from "./src/store/loadingList";
import "@fontsource/plus-jakarta-sans";
import "@fontsource/plus-jakarta-sans/500.css";
import "@fontsource/plus-jakarta-sans/700.css";
import "@fontsource/plus-jakarta-sans/800.css";
import "@fontsource/dm-mono";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const onClientEntry = () => {
  //Log here so that the version is forwarded to full story and visible in the dev tool section
  console.log("Client Version: " + VERSION);
  console.log("Client Platform: " + PLATFORM);

  try {
    if (window.localStorage.getItem("userPreferences") === "undefined") {
      console.log("Erasing corrupted user preferences");
      window.localStorage.removeItem("userPreferences");
    }
  } catch {
    console.warn("Failed to erase corrupted user preferences");
  }


  if (isAppleWebView()) {
    Sentry.setTag("mobile-web-view", "Apple");
  }

  if (isAndroidWebView()) {
    Sentry.setTag("mobile-web-view", "Android");
  }
};

let routeJustChangedMarker = null;

export const onRouteUpdate = ({ location, prevLocation }) => {
  /*
    Gatsby fires onRouteUpdate twice on first page load
    this hack makes sure that any route change that happened
    in last 500ms is not being tracked
   */
  if (!routeJustChangedMarker) {
    routeJustChangedMarker = setTimeout(() => {
      routeJustChangedMarker = null;
    }, 500);
  } else {
    return;
  }

  getStore().dispatch(requestFinished("GATSBY", location.pathname));

  const queryParams = queryString.parse(location.search);
  const referralCode = queryParams.r || null;

  if (typeof window !== "undefined") {
    const attribution = {};
    Object.entries(queryParams).forEach(([k, v]) => {
      //cid and clickId are parameters used by affiliate tracking software
      if (k.startsWith("utm_")) {
        attribution[k] = v;
      }
      if (k === "cid" || k.toLowerCase() === "clickid") {
        //normalise the various case combinations to clickId
        attribution["clickId"] = v;
      }
      if (k.toLowerCase() === "btag") {
        attribution["btag"] = v;
      }
    });

    if (Object.keys(attribution).length > 0) {
      localObject.setValue(ATTRIBUTION_KEY, attribution);
    }

    // we only want to track "real" route changes
    const shouldTrackPageEvent = location?.pathname !== prevLocation?.pathname;

    // Segment.js
    if (
      window.analytics &&
      !ISLOCALHOST &&
      !isWebView() &&
      shouldTrackPageEvent
    ) {
      /* global analytics */
      analytics.page(
        null,
        null,
        {
          referralCode,
          clientVersion: VERSION,
        },
        null,
      );
    }
  }
};

export const onRouteUpdateDelayed = ({ location }) => {
  getStore().dispatch(requestStarted("GATSBY", location.pathname));
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  // List of pages where scroll will be restored manually
  const noScrollPages = [
    /^\/$/, // main page
    /^\/en-au\/?$/, // main page with the language prefix
    /\/match\/?/, // match page
    /\/betting\/?/, // betting pages
  ];

  const shouldScroll = !noScrollPages.some((regex) =>
    regex.test(location.pathname),
  );

  const previousPathname = prevRouterProps?.location?.pathname;

  // restore scroll to 0 if we are navigating to a new page
  if (previousPathname !== location.pathname) {
    // Ref. https://github.com/gatsbyjs/gatsby/issues/38201
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);
  }

  if (!shouldScroll) {
    return false;
  }

  return true;
};

export const wrapRootElement = wrapApp;

export const registerServiceWorker = () => true;

export { wrapPageElement } from "./src/gatsby/wrapPageElement";
