import React from "react";
import cx from "classnames";
import { StreamButton } from "components/layout/StreamButton";
import type { PickType } from "sections/Entries/types";

import { Live } from "./Live";

import * as styles from "./LiveClampWithStream.module.scss";

type ILiveClampWithStream = {
  isLive: boolean;
  pick: PickType;
  className?: string;
};

export const LiveClampWithStream = (props: ILiveClampWithStream) => {
  const { isLive, pick, className } = props;

  return (
    <>
      {isLive && pick.streamUri ? (
        <div className={cx(styles.root, className)}>
          <StreamButton
            title={pick.eventName}
            sport={pick.sport}
            url={pick.streamUri}
            startTime={pick.scheduledStartTime}
            status={pick.statusText}
            eventId={pick.eventId}
            isPlaceholder={pick.isPlaceholder}
          />
          <Live />
        </div>
      ) : (
        isLive && !pick.streamUri && <Live />
      )}
    </>
  );
};

LiveClampWithStream.displayName = "LiveClampWithStream";
