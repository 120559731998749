import React from "react";

import ContentColumn from "../ContentColumn";

import * as styles from "./Title.module.scss";

type TitleProps = {
  children?: React.ReactNode;
  aux?: string;
  title?: string | JSX.Element;
  subtitle?: string;
  crumbs?: any; // TODO: deprecate once moved to a new page
};

export const Title = ({ children, aux, title, subtitle }: TitleProps) => (
  <div className={styles.title}>
    <ContentColumn>
      <div className={styles.text}>
        {typeof title === "string" ? (
          <h1 className={styles.title}>{title}</h1>
        ) : (
          title || children
        )}
        {subtitle ? <h4 className={styles.subtitle}>{subtitle}</h4> : null}
      </div>
      {aux ? <div className={styles.aux}>{aux}</div> : null}
    </ContentColumn>
  </div>
);

export default Title;
