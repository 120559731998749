import React, { useState } from "react";
import { useTimeout } from "usehooks-ts";
import * as styles from "./Speedmap.module.scss";
import { speedMapPositionMap } from "../ShortForm/ShortForm";

export default ({ speedMapPosition }: { speedMapPosition: string }) => {
  const [finalSpeedMapPosition, setFinalSpeedMapPosition] = useState(7);

  const speedmapIsUnavailable = !speedMapPosition;

  const lineStyle = {
    width: `${Math.floor(((7 - finalSpeedMapPosition) / 7) * 86)}%`,
  };

  const iconStyle = {
    left: `${Math.floor(((7 - finalSpeedMapPosition) / 7) * 86)}%`,
    marginLeft: speedmapIsUnavailable ? "0" : "-9px",
  };

  useTimeout(() => {
    speedMapPosition && setFinalSpeedMapPosition(Number(speedMapPosition));
  }, 1);

  if (speedmapIsUnavailable) return null;

  return (
    <>
      <div style={lineStyle} className={styles.speedmap}></div>
      <div style={iconStyle} className={styles.position}>
        {speedMapPositionMap[speedMapPosition]}
        <div className={styles.circle}></div>
      </div>
    </>
  );
};
