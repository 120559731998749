// extracted by mini-css-extract-plugin
export var back = "FullScreenModal-module--back--75db9";
export var blue = "#0eb3f2";
export var close = "FullScreenModal-module--close--07987";
export var container = "FullScreenModal-module--container--fe9f3";
export var content = "FullScreenModal-module--content--d4ba0";
export var emphasis = "FullScreenModal-module--emphasis--240d4";
export var green = "#57d33b";
export var greenLink = "FullScreenModal-module--green-link--d985f";
export var grey = "var(--tone-mid)";
export var header = "FullScreenModal-module--header--4cb70";
export var headerContent = "FullScreenModal-module--headerContent--43425";
export var headerLeft = "FullScreenModal-module--headerLeft--b6c82";
export var modal = "FullScreenModal-module--modal--b805d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";