import React from "react";
import cx from "classnames";
import * as styles from "./BlogWidgets.module.scss";

const DotaTeam = ({
  "dpc-position": dpcPosition,
  "dpc-points": dpcPoints,
  "image-url": imageUrl,
  "team-name": teamName,
}) => {
  const colors = {
    [styles.red]: parseFloat(dpcPosition) >= 13,
    [styles.yellow]:
      parseFloat(dpcPosition) >= 6 && parseFloat(dpcPosition) < 13,
    [styles.green]: parseFloat(dpcPosition) < 6,
  };

  return (
    <div className={styles.statWidget}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={styles.stats}>
        <div className={styles.name}>{teamName}</div>
        <div className={cx(styles.stat, colors)}>
          <span>DPC Position:</span> {dpcPosition}
        </div>
        <div className={cx(styles.stat, colors)}>
          <span>DPC Points:</span> {dpcPoints}
        </div>
      </div>
    </div>
  );
};

export default DotaTeam;
