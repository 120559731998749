// extracted by mini-css-extract-plugin
export var active = "RaceTips-module--active--5a634";
export var arrow = "RaceTips-module--arrow--3e85d";
export var blue = "#0eb3f2";
export var bold = "RaceTips-module--bold--070af";
export var caret = "RaceTips-module--caret--621b7";
export var comment = "RaceTips-module--comment--a408f";
export var competitor = "RaceTips-module--competitor--f6348";
export var competitors = "RaceTips-module--competitors--05143";
export var emphasis = "RaceTips-module--emphasis--7ceb8";
export var green = "#57d33b";
export var greenLink = "RaceTips-module--green-link--3fe1e";
export var grey = "var(--tone-mid)";
export var isScratched = "RaceTips-module--isScratched--18853";
export var notchGap = "32px";
export var orange = "#f8662b";
export var raceCommentsContainer = "RaceTips-module--raceCommentsContainer--0645a";
export var red = "#ff2741";
export var showSkeleton = "RaceTips-module--showSkeleton--5faaf";
export var space = "RaceTips-module--space--6fe8e";
export var toggle = "RaceTips-module--toggle--bfe7e";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";