import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { createMiddleware } from "redux-beacon";
import Segment from "@redux-beacon/segment";
import segmentEventsMapper from "./tracking/segmentEventsMapper";
import delightedEventsMapper from "./tracking/delightedEventsMapper";
import { WebToAppEvents } from "utilities/AppWebMessagingBridge/WebToAppEvents";
import { Delighted } from "./tracking/targets/delighted";

// region Section Reducers
import accountReducer from "sections/Account/accountSlice";
import walletReducer from "sections/Wallet/walletSlice";
// endregion
// region Utility Reducers
import uiReducer from "utilities/UI/uiSlice";
import loadingReducer from "utilities/Loading/Reducers";
import validationReducer from "./validation";
// endregion
import authReducer, { persistAuth } from "utilities/Auth/authSlice";
import betslipReducer from "sections/Betting/Betslip/betslipSlice";
import pickslipReducer from "sections/Pickems/PickSlip/pickslipSlice";
import helpCenterReducer from "utilities/HelpCenter/helpCenterSlice";
import sameEventMultiSlipReducer from "sections/Betting/components/ComplexBetBuilder/ComplexBetBuilderSlice";
import brazeContentCardsSliceReducer from "store/Braze/brazeContentCardsSlice";

const rootReducer = combineReducers({
  sections: combineReducers({
    account: accountReducer,
    wallet: walletReducer,
  }),
  utilities: combineReducers({
    ui: uiReducer,
    loading: loadingReducer,
    validation: validationReducer,
  }),
  auth: authReducer,
  betslip: betslipReducer,
  pickslip: pickslipReducer,
  helpCenter: helpCenterReducer,
  sameEventMulti: sameEventMultiSlipReducer,
  brazeContentCards: brazeContentCardsSliceReducer,
});

export const createStore = () =>
  configureStore({
    devTools: {
      name: "Picklebet",
      trace: true,
      traceLimit: 25,
    },
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO: we currently store raw dates in state, e.g. betslip selections
        serializableCheck: false,
      }).concat([
        persistAuth.middleware,
        createMiddleware(segmentEventsMapper, Segment()),
        createMiddleware(delightedEventsMapper, Delighted()),
        WebToAppEvents,
      ]),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore["dispatch"];
