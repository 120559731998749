// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var dropdown = "TabFilter-module--dropdown--13c9a";
export var emphasis = "TabFilter-module--emphasis--ef139";
export var fadeLeft = "TabFilter-module--fadeLeft--671d4";
export var fadeRight = "TabFilter-module--fadeRight--09577";
export var green = "#57d33b";
export var greenLink = "TabFilter-module--green-link--b0ebe";
export var grey = "var(--tone-mid)";
export var gutterBottom = "TabFilter-module--gutterBottom--d68c6";
export var icon = "TabFilter-module--icon--8916d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scrollArea = "TabFilter-module--scrollArea--eff29";
export var tabs = "TabFilter-module--tabs--1c084";
export var tabsList = "TabFilter-module--tabsList--e935d";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";