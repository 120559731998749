import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { Link } from "library/components/Link";
import { Icon } from "library";
import { useSelector, useDispatch, useDisplayMode } from "hooks";
import { selectUnfilteredSelectionsCount } from "sections/Betting/Betslip/betslipSlice";
import { toggleBetslip, toggleMenu } from "utilities/UI/uiSlice";
import { usePermissions } from "hooks";
import { usePendingBetsCount } from "hooks/firestore/betting/useEntries";
import { usePageContext } from "hooks/usePageContext";
import { useIsClient } from "usehooks-ts";

import * as styles from "./MobileNavigation.module.scss";

type Item = {
  icon: string;
  isActive: boolean;
  permissions?: string[];
  badge?: string | number;
  to?: string;
  onClick?: () => void;
  highlightBadge?: boolean;
};

const MobileNavigationItem = ({ item }: { item: Item }) => {
  const [changing, setChanging] = useState(false);
  const ItemComponent = item.to ? Link : "span";
  const itemProps = item.to ? { to: item.to } : { onClick: item.onClick };

  // Effect to trigger animation on badge count change
  useEffect(() => {
    if (item.badge) {
      setChanging(true);
      const timeout = setTimeout(() => setChanging(false), 300); // 800ms for animation duration
      return () => clearTimeout(timeout);
    }
  }, [item.badge]);

  return (
    <li
      className={cx(styles.item, {
        [styles.active]: item.isActive,
      })}
    >
      <ItemComponent {...(itemProps as any)}>
        <div className={styles.iconContainer}>
          <Icon type={item.icon} />
          {item.badge ? (
            <span
              className={cx(styles.badge, {
                [styles.highlight]: item.highlightBadge,
                [styles.bounce]: changing, // Add the bounce class when changing is true
              })}
            >
              {item.badge}
            </span>
          ) : null}
        </div>
      </ItemComponent>
    </li>
  );
};

const MobileNavigation = () => {
  const isClient = useIsClient();
  const menu = useSelector((state) => state.utilities.ui.menu);
  const panel = useSelector((state) => state.utilities.ui.panel);
  const { section } = usePageContext();
  const pendingBetsCount = usePendingBetsCount();
  const selectionsCount = useSelector(selectUnfilteredSelectionsCount);
  const userPermissions = usePermissions();
  const dispatch = useDispatch();
  const displayMode = useDisplayMode();

  const items = useMemo<Item[]>(() => {
    return [
      {
        icon: "hamburger",
        isActive: menu === "left",
        onClick: () => dispatch(toggleMenu()),
      },
      {
        icon: "gameController",
        isActive: menu === "none" && section === "betting",
        permissions: ["viewBettingMarkets", "viewEsportsMarkets"],
        to: "/betting",
      },
      {
        icon: "sports",
        isActive: menu === "none" && section === "sports/betting",
        permissions: ["viewBettingMarkets", "viewSportsMarkets"],
        to: "/sports/betting",
      },
      {
        icon: "racing",
        isActive: menu === "none" && section === "racing/betting",
        permissions: ["viewBettingMarkets", "viewRacingMarkets"],
        to: "/racing/betting",
      },
      {
        icon: "betslip",
        isActive: menu === "right" && panel === "betslip",
        badge: selectionsCount > 0 ? selectionsCount : pendingBetsCount,
        onClick: () => dispatch(toggleBetslip()),
        highlightBadge: selectionsCount > 0,
      },
    ].filter((item) =>
      (item.permissions || []).every(
        (permission) => userPermissions[permission] === "GRANTED",
      ),
    );
  }, [menu, section, selectionsCount, pendingBetsCount, userPermissions]);

  if (!isClient || !["mobile", "tablet"].includes(displayMode)) {
    return null;
  }

  return (
    <ul className={styles.container}>
      {items.map((item) => (
        <MobileNavigationItem item={item} key={item.icon} />
      ))}
    </ul>
  );
};

export default MobileNavigation;
