import * as React from "react";
import cx from "classnames";
import { cva, type VariantProps } from "class-variance-authority";
import type { Step } from "./Stepper";

import * as styles from "./Stepper.module.scss";

const trackVariants = cva(null, {
  variants: {
    variant: {
      primary: styles.trackPrimary,
      danger: styles.trackDanger,
      warning: styles.trackWarning,
      info: styles.trackInfo,
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

type TrackProps = {
  steps: React.ReactElement<typeof Step>[];
  activeStep: string;
} & VariantProps<typeof trackVariants>;

const Track = ({ steps, activeStep, variant }: TrackProps) => {
  // when we need to draw step indicators it's always 1 less than the total number of steps
  // e.g. O------O------O------O 4 steps = 3 sections
  const sectionsCount = steps.length - 1;

  const currentStepIndex = steps.findIndex(
    (step: React.ReactElement<typeof Step>) => step.props.name === activeStep,
  );

  return (
    <div className={styles.track}>
      {Array.from({ length: sectionsCount }).map((_, index) => {
        const sectionName = steps[index].props.name;
        const isActive = sectionName === activeStep;
        const isCompleted = index < currentStepIndex;
        const isLastSection = index === sectionsCount - 1;
        const isFirstStep = activeStep === steps[0].props.name;
        const isLastStep = activeStep === steps[steps.length - 1].props.name;
        const stepNumber = index + 1;

        return (
          <div
            key={sectionName}
            className={cx(styles.section, trackVariants({ variant }))}
            data-active={isActive}
            data-completed={isCompleted}
          >
            <div
              className={cx(styles.thumb, {
                [styles.isActive]: isActive,
                [styles.isCompleted]: isCompleted,
              })}
            >
              {isActive && (
                <div
                  className={cx(styles.label, {
                    [styles.first]: isFirstStep,
                  })}
                >
                  <span className={styles.number}>{stepNumber}</span>
                  <span className={styles.text}>
                    {steps[currentStepIndex].props.name}
                  </span>
                </div>
              )}
            </div>
            {isLastSection && (
              <div
                className={cx(styles.thumb, {
                  [styles.last]: isLastSection,
                  [styles.isActive]: isLastStep,
                })}
              >
                {isLastStep && (
                  <div className={cx(styles.label, styles.last)}>
                    <span className={styles.number}>{steps.length}</span>
                    <span className={styles.text}>
                      {steps[steps.length - 1].props.name}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export { Track, trackVariants };
