import { useSelector } from "hooks";
import { useMemo } from "react";

export const useUserAttributes = () => {
  const attributes = useSelector((state) => state.auth?.attributes);
  return attributes;
};

export const useUserAttribute = (name: string) => {
  const attributes = useUserAttributes();
  return attributes?.[name];
};

export const useUserEventFilters = (): string[] => {
  const filters = useUserAttribute("eventFilters");

  // this is memo'd because returning a new empty array when filters is
  // empty would trigger an effect on each render where this value
  // reference was used
  const memoizedFilters = useMemo(() => {
    return filters ?? [];
  }, [filters]);

  return memoizedFilters;
};
