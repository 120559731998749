import React from "react";
import type { IFormHandle } from "middleware/forms";

export const StdForm: React.FC<{
  form: IFormHandle;
  className?: string;
  id?: string;
}> = ({ children, form, className, id }) => (
  <form
    id={id}
    className={[className || ""].join(" ")}
    onSubmit={(e) => {
      form.submitForm();
      e.preventDefault();
    }}
  >
    {children}
  </form>
);
