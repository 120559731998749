import React from "react";
import { BetOdds } from "library";
import type { BetOddsProps } from "library";
import { Link } from "components/Link";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { removeTrailingSlash } from "utilities/strings";

import * as styles from "./BetOddsWithDeductions.module.scss";
import { InfoPopover } from "components/InfoPopover";

type BetOddsWithDeductionsProps = {
  deductions?: number;
  deadHeatFactor?: number;
  isVoid?: boolean;
  pickUrl?: string;
} & BetOddsProps;

const BetOddsWithDeductions = ({
  deductions,
  deadHeatFactor,
  isVoid,
  ...props
}: BetOddsWithDeductionsProps) => {
  const hasDeadHeat = deadHeatFactor && deadHeatFactor !== 1;
  const hasDeductions = !!deductions;
  const hasDeductionsOrDeadHeat = hasDeadHeat || hasDeductions;

  const pickUrl = removeTrailingSlash(props.pickUrl);

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <BetOdds isVoid={isVoid} {...props} />
      <span className={styles.deductions}>
        {hasDeductionsOrDeadHeat && !isVoid && (
          <InfoPopover
            content={
              <div className={styles.content}>
                {hasDeadHeat && (
                  <span>
                    {(100 - deadHeatFactor * 100).toFixed(2)}% Odds reduction
                    due to a{" "}
                    <Link className="green-link" to={`${pickUrl}/win-place`}>
                      Dead Heat
                    </Link>
                  </span>
                )}
                {hasDeductions && (
                  <span>
                    {(deductions * 100).toFixed(2)}% Odds reduction due to{" "}
                    <Link className="green-link" to={`${pickUrl}/deductions`}>
                      Deductions
                    </Link>
                  </span>
                )}
              </div>
            }
          >
            <div className={styles.info}>
              <InfoIcon />
            </div>
          </InfoPopover>
        )}
      </span>
    </div>
  );
};

export { BetOddsWithDeductions };
