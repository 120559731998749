import React from "react";
import { constructCurrency } from "common";
import { useFiatCurrency } from "hooks";

type PayoutProps = {
  amount: number | string;
};

/**
 * Display TBD, $x, or format currency from cents if a number is provided
 * Note: Payout should not show SP
 */
const Payout = ({ amount }: PayoutProps) => {
  const currency = useFiatCurrency();

  return (
    <div>
      {typeof amount === "number"
        ? constructCurrency(amount || 0, {
            currencyType: currency,
          })
        : amount}
    </div>
  );
};

export { Payout };
