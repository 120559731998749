import { getDispatch } from "store";
import {
  openHelpCenter,
  setHelpCenterArticleId,
  setHelpCenterSearch,
  setHelpCenterLabels,
  openChat,
} from "./HelpCenter/helpCenterSlice";

export const SUPPORT_EMAIL = {
  to: "support@picklebet.com",
  subject: "Help me, picklebet!",
  body: "Dear picklebet,\n\n",
};

export const supportPanic = () => {
  alert("Please email " + SUPPORT_EMAIL.to);
};

export const composeSupportEmail = () => {
  if (typeof location !== "undefined") {
    location.href = `mailto:${SUPPORT_EMAIL.to}?subject=${encodeURIComponent(
      SUPPORT_EMAIL.subject,
    )}&body=${encodeURIComponent(SUPPORT_EMAIL.body)}`;
  } else supportPanic();
};

// document the function
/**
 * Opens the help center with the given search query and labels.
 * @param options - the options to open the help center with
 * @param options.search - the search query
 * @param options.labels - the labels to filter by, these are set in the help center against each article
 * @param options.articleId - the article id to open. e.g. 360001041876 in https://help.picklebet.com/hc/en-us/articles/360001041876-Withdrawal-Issues
 * @example
 * open({ search: "how to bet", labels: ["betting"] })
 * @example
 * open({ search: "how to bet" })
 */
export const open = (
  options: { search?: string; labels?: Array<string>; articleId?: string } = {},
) => {
  const dispatch = getDispatch();

  if (typeof zE !== "undefined") {
    if (options.search) dispatch(setHelpCenterSearch(options.search));
    if (options.labels) dispatch(setHelpCenterLabels(options.labels));
    if (options.articleId) dispatch(setHelpCenterArticleId(options.articleId));
    dispatch(openHelpCenter());
  } else {
    supportPanic();
  }
};

export const openWithChat = (
  options: { search?: string; labels?: Array<string>; articleId?: string } = {},
) => {
  const dispatch = getDispatch();
  open(options);
  dispatch(openChat());
};
