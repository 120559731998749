import * as Sentry from "@sentry/gatsby";

const dsn = process.env.GATSBY_SENTRY_DSN;
const environment = process.env.GATSBY_SKRILLA_ENV;

(() => {
  if (!dsn) return;

  Sentry.init({
    dsn,
    environment,
    allowUrls: [/https?:\/\/([a-zA-Z0-9-]+\.)?picklebet\.com/],
    integrations: [Sentry.replayIntegration()],

    // Capture 20% of transactions for performace monitoring
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    /**
     * Regex is an exact match, strings are partial matches.
     * https://docs.sentry.io/platforms/javascript/guides/gatsby/configuration/integrations/inboundfilters/#ignoreerrors
     */
    ignoreErrors: [
      "/Blocked a frame with origin/",
      "/The network connection was lost./",
      "/Cannot read properties of undefined (reading 'prototype')/",
      "/undefined is not an object (evaluating 'k.i')/",
      "/Client is offline/",
      "/Connection to Indexed Database server lost. Refresh the page to try again/",
      "/undefined is not an object (evaluating 'h.l')/",
      "/cancelled/",
      "/网络连接已中断/",
      "/Failed to Fetch/",
      "Load failed",
      "Not rendering React",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: cancelled",

      // firebase network issues
      "auth/network-request-failed",
      "Connection failed.",
      "/Missing or invalid authentication./",

      // gatsby
      "We couldn't load \"/page-data",

      /* eslint-disable quotes */
      '/Permission denied to access property "CSSStyleSheet" on cross-origin object/',
      '/SecurityError: Blocked a frame with origin "https://picklebet.com" from accessing a cross-origin frame/',
      /* eslint-enable quotes */
    ],
  });
})();
