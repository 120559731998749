// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "FormControl-module--container--342a8";
export var description = "FormControl-module--description--7d216";
export var emphasis = "FormControl-module--emphasis--74319";
export var green = "#57d33b";
export var greenLink = "FormControl-module--green-link--b8a66";
export var grey = "var(--tone-mid)";
export var input = "FormControl-module--input--b5813";
export var noSpacing = "FormControl-module--noSpacing--62571";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var shouldNotCollapse = "FormControl-module--shouldNotCollapse--58ba3";
export var titleAndDescription = "FormControl-module--titleAndDescription--a9079";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";