// extracted by mini-css-extract-plugin
export var active = "StreamButton-module--active--3fdde";
export var blue = "#0eb3f2";
export var disabled = "StreamButton-module--disabled--8d8ac";
export var emphasis = "StreamButton-module--emphasis--d54a5";
export var green = "#57d33b";
export var greenLink = "StreamButton-module--green-link--3e1a0";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";