import React, { useMemo } from "react";

export const Silks = ({
  silksUrl,
  competitorNumber,
  imageHeight,
}: {
  silksUrl: string;
  competitorNumber: number;
  imageHeight: number;
}) => {
  const silkStyle = useMemo(
    () => ({
      width: `${imageHeight}px`,
      height: `${imageHeight}px`,
      backgroundImage: `url(${silksUrl})`,
      backgroundSize: `cover`,
      backgroundPosition: `${(competitorNumber - 1) * -imageHeight}px 0px`,
      backgroundRepeat: `no-repeat`,
    }),
    [silksUrl, competitorNumber],
  );

  if (!silksUrl) {
    return null;
  }

  return <div style={silkStyle} />;
};
