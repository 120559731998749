import React from "react";
import has from "lodash/has";
import pluralize from "pluralize";
import isEmpty from "lodash/isEmpty";
import { Clamp } from "components/Clamp";
import { Box } from "components/Box";
import type { BetEntry as BetEntryType } from "sections/Entries/types";
import { ReactComponent as SingleIcon } from "components/assets/bet-single.svg";
import { ReactComponent as MultiIcon } from "components/assets/bet-multi.svg";
import { ReactComponent as ComboIcon } from "components/assets/bet-combo.svg";
import { formatDateTime } from "utilities/datetime";
import { SmallPromoIcon } from "components/PromoIcon/SmallPromoIcon";
import { BetPickPromotion } from "components/layout/Entry/components/BetPickPromotion";
import { isMarketOpenForBets } from "utilities/markets";
import { BetType, setBetType } from "sections/Betting/Betslip/betslipSlice";
import { setMenu } from "utilities/UI/uiSlice";
import { useDispatch } from "hooks";

import { BetPick } from "./BetPick";
import {
  BetOddsSummary,
  BetPayout,
  BetStake,
  BetSummary,
  BetSummaryAction,
  BetStakePerCombo,
} from "./BetSummary";

import * as styles from "./BetEntry.module.scss";

type EntryProps = {
  entry: BetEntryType;
  isCompact: boolean;
  footer?: React.ReactNode;
  isPreview?: boolean;
};

const BetEntry = ({ entry, isCompact, isPreview, footer }: EntryProps) => {
  const dispatch = useDispatch();

  const eventName =
    entry.picks[0].eventType === "RACE"
      ? entry.picks[0].parentName
      : entry.picks[0].eventName;

  const description = entry.isCombo
    ? `${entry.combinationsCount === 1 ? `All` : `Any`} ${entry.selectionsRequiredToWin} Win / ${entry.combinationsCount} ${entry.combinationsCount > 1 ? `Combinations` : `Combination`}`
    : entry.legsIncludingSubOutcomes > 1
      ? `(${entry.legsIncludingSubOutcomes} legs)`
      : eventName;

  // we show multi icon for SGMs, SRMs and normal multis
  const isMultiSelection = entry.isSGM || entry.isSRM || entry.picks.length > 1;

  const icon = entry.isCombo ? (
    <ComboIcon />
  ) : isMultiSelection || entry.isExotic ? (
    <MultiIcon />
  ) : (
    <SingleIcon />
  );

  const clamp = entry.decided ? (
    <Clamp variant={entry.entryStatusVariant as any}>
      {entry.entryStatusText}
    </Clamp>
  ) : (
    <Clamp>{formatDateTime(entry.createdAt, "do MMM @ HH:mm")}</Clamp>
  );

  const isEntryWon = entry.entryStatusText === "WON";
  const isShareEntry = has(entry, "shareId");

  const hasSEMSelection = entry.picks.some((pick) => !isEmpty(pick.outcomes));
  const activeSelections = entry.picks.filter((pick) =>
    isMarketOpenForBets(pick.marketStatus),
  );

  const onPickAddedToBetslip = () => {
    dispatch(
      setBetType(
        entry.isSRM || entry.isSGM || !isMultiSelection
          ? BetType.Single
          : activeSelections.length > 1 || hasSEMSelection
            ? BetType.Multi
            : BetType.Single,
      ),
    );

    dispatch(setMenu("right"));
  };

  return (
    <Box
      title={entry.name}
      subTitle={
        entry.isExotic
          ? pluralize("Combo", entry.picks[0]?.exoticCombinations ?? 0, true)
          : description
      }
      icon={
        <>
          {!(isShareEntry || isPreview) &&
            entry.automationProgressions?.length! > 0 && (
              <SmallPromoIcon className={styles.promoIcon} />
            )}
          {icon}
        </>
      }
      clamp={clamp}
    >
      {entry.picks.map((pick, index) => {
        const isFirst = index === 0;
        const isLast = index === entry.picks.length - 1;
        const outcomes = Object.keys(pick.outcomes || {});
        // NOTE: Multiple entries can have same outcome id, therefore we need to
        // create a unique id for each pick that includes the entry id

        // fix for duplicate key error message where we have SGMs. SGMs do not have pick.outcomeId
        const pickId = `${entry.id}-${
          outcomes?.length > 1 ? outcomes.join("-") : pick.outcomeId
        }`;

        return (
          <BetPick
            entryId={entry.id}
            key={pickId}
            pick={pick}
            isFirst={isFirst}
            isLast={isLast}
            isWon={isEntryWon}
            isDecided={entry.decided}
            isShare={isShareEntry}
            isPreview={isPreview}
            onPickAddedToBetslip={onPickAddedToBetslip}
            isCombo={entry.isCombo}
            isExotic={entry.isExotic}
          />
        );
      })}
      {!(isShareEntry || isPreview) &&
        entry.automationProgressions &&
        entry.automationProgressions?.length > 0 && (
          <BetPickPromotion
            entry={entry}
            isWon={isEntryWon}
            automationProgressions={entry.automationProgressions}
          />
        )}
      <BetSummary entry={entry} isCompact={isCompact} isWon={isEntryWon}>
        {footer ? (
          footer
        ) : (
          <>
            <BetStake
              separator={<div>&times;</div>}
              label={entry.isCombo || entry.isExotic ? "Total Stake" : "Stake"}
            />
            {entry.isCombo || entry.isExotic ? (
              <BetStakePerCombo separator={<div>=</div>} />
            ) : (
              <BetOddsSummary separator={<div>=</div>} />
            )}
            <BetPayout />

            <BetSummaryAction />
          </>
        )}
      </BetSummary>
    </Box>
  );
};

export { BetEntry };
