// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "TermsAndConditions-module--emphasis--05ca2";
export var green = "#57d33b";
export var greenLink = "TermsAndConditions-module--green-link--8793a";
export var grey = "var(--tone-mid)";
export var inactive = "TermsAndConditions-module--inactive--0a7e3";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var termAndConditions = "TermsAndConditions-module--termAndConditions--2de05";
export var termAndConditionsContainer = "TermsAndConditions-module--termAndConditionsContainer--825ff";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";