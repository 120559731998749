// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var dividendAmount = "ExoticMarketResults-module--dividendAmount--67ab6";
export var emphasis = "ExoticMarketResults-module--emphasis--8893c";
export var green = "#57d33b";
export var greenLink = "ExoticMarketResults-module--green-link--c5bb5";
export var grey = "var(--tone-mid)";
export var iconAndInfo = "ExoticMarketResults-module--iconAndInfo--b6b7b";
export var marketName = "ExoticMarketResults-module--marketName--5830d";
export var marketResult = "ExoticMarketResults-module--marketResult--44c51";
export var nameAndRunners = "ExoticMarketResults-module--nameAndRunners--11642";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var results = "ExoticMarketResults-module--results--b2be2";
export var runners = "ExoticMarketResults-module--runners--9a4da";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";