import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData } from "hooks";
import {
  collection,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  type DocumentData,
  doc,
} from "firebase/firestore";
import useHasPermission from "hooks/useHasPermission";

export type EventCountsByType = {
  prematch: number;
  live: number;
  sport: string;
};

export enum EventCountType {
  matches = "matches",
  outrights = "outrights",
  contests = "contests",
  racing = "racing",
}
export type EventCounts = Record<EventCountType, Record<string, number>>;

const countsConverter: FirestoreDataConverter<EventCountsByType> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): EventCountsByType => {
    const data = snapshot.data(options);
    const sport = snapshot.id;

    return {
      prematch: data.Prematch || 0,
      live: data.Live || 0,
      sport: sport.toLowerCase(),
    } as EventCountsByType;
  },
};

const reduceTitleCounts = (
  counts: EventCountsByType[] | undefined,
  canViewLiveBets: boolean,
) => {
  return (counts || []).reduce<{ [key: string]: number; total: number }>(
    (result, countsBySport) => {
      const currentTotal = result.total;
      const totalForSport = canViewLiveBets
        ? countsBySport.prematch + countsBySport.live
        : countsBySport.prematch;

      return {
        ...result,
        total: currentTotal + totalForSport,
        [countsBySport.sport]: totalForSport,
      };
    },
    { total: 0 },
  );
};

export const useEventCounts = (): [EventCounts, boolean] => {
  const matchesCountsRef = doc(
    getFirestore(),
    "bettingMarketEventCounts",
    "matches",
  );
  const outrightsCountsRef = doc(
    getFirestore(),
    "bettingMarketEventCounts",
    "outrights",
  );

  const racesCountsRef = doc(
    getFirestore(),
    "bettingMarketEventCounts",
    "races",
  );

  const canViewLiveBets = useHasPermission("viewLiveMarkets");

  const matchesSportsRef = collection(matchesCountsRef, "sports").withConverter(
    countsConverter,
  );
  const outrightsSportsRef = collection(
    outrightsCountsRef,
    "sports",
  ).withConverter(countsConverter);

  const racesSportsRef = collection(racesCountsRef, "sports").withConverter(
    countsConverter,
  );

  const [matchesCounts, matchesCountsLoading] = useCollectionData(
    matchesSportsRef,
    matchesSportsRef?.path,
  );

  const [outrightsCounts, outrightsCountsLoading] = useCollectionData(
    outrightsSportsRef,
    outrightsSportsRef?.path,
  );

  const [racesCounts, racesCountsLoading] = useCollectionData(
    racesSportsRef,
    racesSportsRef?.path,
  );

  const eventCounts = useMemo(() => {
    return {
      matches: reduceTitleCounts(matchesCounts, canViewLiveBets),
      outrights: reduceTitleCounts(outrightsCounts, canViewLiveBets),
      racing: reduceTitleCounts(racesCounts, canViewLiveBets),
      contests: { total: 0 },
    } satisfies EventCounts;
  }, [matchesCounts, outrightsCounts, racesCounts, canViewLiveBets]);

  return [
    eventCounts,
    matchesCountsLoading || outrightsCountsLoading || racesCountsLoading,
  ];
};
