import { getCurrencySymbol } from "common";

const formatValue = (
  value: string,
  currency: Intl.NumberFormatOptions["currency"],
  isDirty: boolean,
  locale: string,
) => {
  if ((!isDirty && value === "0") || value.length === 0) {
    // when we are on 0 and nothing changed yet keep the input empty
    // this will allow us to input a new value and keep a placeholder
    return "";
  }

  const prefix = getCurrencySymbol(currency);

  const [integerPart, decimalPart] = value.split(".");

  let formattedValue = Intl.NumberFormat(locale, {
    currency,
  }).format(Number(integerPart));

  if (decimalPart !== undefined) {
    formattedValue += "." + decimalPart.substring(0, 2);
  }

  return `${prefix}${formattedValue}`;
};

const stripNonNumeric = (value: string) => {
  if (value === ".") {
    return "0.";
  }

  const [integerPart, decimalPart] = value.replace(/[^0-9.]/g, "").split(".");

  let safeValue = integerPart;
  if (decimalPart !== undefined) {
    safeValue += "." + decimalPart.substring(0, 2);
  }

  return safeValue;
};

export { formatValue, stripNonNumeric };
