import { useMemo } from "react";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import { collection } from "firebase/firestore";

export type BankAccount = {
  id: string;
  active: boolean;
  bsb: string;
  createdAt: string; // TODO: maybe convert to date in future
  name: string;
  number: string;
  updatedAt: string;
  userId: string;
};

const useBankAccount = (): [BankAccount, boolean] => {
  const userId = useSelector((state) => state.auth.userId);

  const bankAccountsRef = userId
    ? collection(getFirestore(), "users", userId, "bankAccounts")
    : undefined;

  const [bankAccounts, loading] = useCollectionData<any>(
    bankAccountsRef,
    bankAccountsRef?.path,
  );

  const bankAccount = useMemo(
    () => (bankAccounts || []).find((bankAccount) => bankAccount.active),
    [bankAccounts, loading],
  );

  return [bankAccount, loading];
};

export default useBankAccount;
