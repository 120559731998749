import React from "react";
import { Alert } from "components/Alert";
import { SupportLink } from "components/SupportLink";

export default ({ isSP, className }: { isSP: boolean; className?: string }) => {
  return isSP ? (
    <Alert className={className} variant="info" indicator="top" size="small">
      <span>
        SP - indicates a bet can be placed at{" "}
        <SupportLink options={{ articleId: "5351471094799" }} hideChevron>
          Starting Price
        </SupportLink>
        , the final fixed price available at race close
      </span>
    </Alert>
  ) : null;
};
