// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var blurred = "IconButton-module--blurred--fa824";
export var danger = "IconButton-module--danger--9fe0a";
export var emphasis = "IconButton-module--emphasis--3155b";
export var green = "#57d33b";
export var greenLink = "IconButton-module--green-link--ffe6b";
export var grey = "var(--tone-mid)";
export var iconButton = "IconButton-module--iconButton--03ee9";
export var info = "IconButton-module--info--68400";
export var notchGap = "32px";
export var orange = "#f8662b";
export var primary = "IconButton-module--primary--2d788";
export var red = "#ff2741";
export var secondary = "IconButton-module--secondary--83437";
export var split = "IconButton-module--split--a3a95";
export var warning = "IconButton-module--warning--b1be1";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";