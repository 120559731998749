// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "SubOutcome-module--emphasis--dde1e";
export var green = "#57d33b";
export var greenLink = "SubOutcome-module--green-link--a83ff";
export var grey = "var(--tone-mid)";
export var inactive = "SubOutcome-module--inactive--cb53a";
export var marketName = "SubOutcome-module--marketName--c67e4";
export var name = "SubOutcome-module--name--84ea8";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var removeSelection = "SubOutcome-module--removeSelection--f0d5b";
export var sgm = "SubOutcome-module--sgm--7246f";
export var spaceLeft = "SubOutcome-module--spaceLeft--6e159";
export var subOutcome = "SubOutcome-module--subOutcome--70be4";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";