import React from "react";

import { PageMessage } from "components/PageMessage";
import { ReactComponent as VerifiedIcon } from "components/assets/verified.svg";
import { useIsVerified } from "hooks/firestore/user/useIsVerified";
import NextBettingButtons from "components/layout/NextBettingButtons/NextBettingButtons";

type UnverifiedOnlyProps = {
  children: React.ReactNode;
};

const UnverifiedOnly = ({ children }: UnverifiedOnlyProps) => {
  const verified = useIsVerified();

  if (verified) {
    return (
      <PageMessage
        title={`Account Verified`}
        image={<VerifiedIcon />}
        subTitle={`Your account has already been verified!`}
      >
        <NextBettingButtons />
      </PageMessage>
    );
  }

  return <>{children}</>;
};

export { UnverifiedOnly };
