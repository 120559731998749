import React, { type ReactNode } from "react";
import { Caret } from "components/Caret";
import { Drawer } from "components/Drawer/Drawer";
import { type VariantProps, cva } from "class-variance-authority";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import cx from "classnames";

import * as styles from "./InfoPanel.module.scss";

const infoPanelVariants = cva(styles.base, {
  variants: {
    variant: {
      info: styles.info,
      promo: styles.promo,
      neutral: styles.neutral,
    },
  },
  defaultVariants: {
    variant: "neutral",
  },
});

type InfoPanelProps = React.HTMLAttributes<HTMLElement> & {
  title: ReactNode;
  icon?: ReactNode;
  open: boolean;
  onOpen: (open: boolean) => void;
  showArrow?: boolean;
} & VariantProps<typeof infoPanelVariants>;

export default ({
  title,
  icon,
  open,
  onOpen,
  children,
  variant,
  showArrow,
  className,
}: InfoPanelProps) => {
  return (
    <div
      className={cx(
        infoPanelVariants({ variant }),
        {
          [styles.showTriangle]: showArrow,
        },
        className,
      )}
    >
      <div onClick={() => onOpen(!open)} className={styles.header}>
        <div className={styles.icon}>{icon ? icon : <InfoIcon />}</div>
        <div className={styles.headingText}>{title}</div>
        <div className={styles.toggleContainer}>
          <Caret variant={open ? "up" : "down"} className={styles.caret} />
        </div>
      </div>
      <Drawer isOpen={open}>
        <div className={styles.content}>{children}</div>
      </Drawer>
    </div>
  );
};
