// extracted by mini-css-extract-plugin
export var active = "OddsChange-module--active--52a41";
export var blue = "#0eb3f2";
export var emphasis = "OddsChange-module--emphasis--93328";
export var green = "#57d33b";
export var greenLink = "OddsChange-module--green-link--0d086";
export var grey = "var(--tone-mid)";
export var icon = "OddsChange-module--icon--241b0";
export var iconDown = "OddsChange-module--iconDown--b185b";
export var iconUp = "OddsChange-module--iconUp--248a9";
export var notchGap = "32px";
export var orange = "#f8662b";
export var price = "OddsChange-module--price--22eeb";
export var priceDown = "OddsChange-module--priceDown--4f99c";
export var priceUp = "OddsChange-module--priceUp--d3221";
export var racingMultiOutcome = "OddsChange-module--racingMultiOutcome--c8f04";
export var racingWinPlace = "OddsChange-module--racingWinPlace--4b16b";
export var red = "#ff2741";
export var sameRaceMultiSlip = "OddsChange-module--sameRaceMultiSlip--c76db";
export var sports = "OddsChange-module--sports--f6a73";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";