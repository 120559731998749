import React from "react";
import cx from "classnames";
import * as support from "utilities/support";
import ButtonWithState from "../ButtonWithState";
type SearchSupport = {
  search: string;
  labels?: never;
  articleId?: never;
};

type LabelsSupport = {
  search?: never;
  labels: string[];
  articleId?: never;
};

type ArticleIdSupport = {
  search?: never;
  labels?: never;
  articleId: string;
};

export type SupportOptions = SearchSupport | LabelsSupport | ArticleIdSupport;

type SupportLinkProps = {
  className?: any;
  type?: "text" | "button";
  children?: React.ReactChild;
  label?: string;
  options?: SupportOptions;
};

const SupportLink = ({
  children,
  className,
  label,
  options,
  type = "text",
}: SupportLinkProps) => {
  if (type === "button") {
    return (
      <ButtonWithState type="success" onClick={() => support.open(options)}>
        {children ?? label ?? ""}
      </ButtonWithState>
    );
  }

  return (
    <a
      className={cx({ "green-link": true, [className]: !!className })}
      onClick={() => support.open(options)}
    >
      {children ?? label ?? ""}
    </a>
  );
};

export default SupportLink;
