// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clamp = "Clamp-module--clamp--ee85a";
export var danger = "Clamp-module--danger--31148";
export var dangerSoft = "Clamp-module--dangerSoft--93493";
export var disabled = "Clamp-module--disabled--7ebb6";
export var emphasis = "Clamp-module--emphasis--82fd1";
export var green = "#57d33b";
export var greenLink = "Clamp-module--green-link--a8855";
export var grey = "Clamp-module--grey--23fc4";
export var info = "Clamp-module--info--f447f";
export var light = "Clamp-module--light--cccae";
export var neutral = "Clamp-module--neutral--1ee7f";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var shapeDefault = "Clamp-module--shapeDefault--10975";
export var shapeRound = "Clamp-module--shapeRound--ac57c";
export var success = "Clamp-module--success--f5457";
export var warning = "Clamp-module--warning--e02af";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";