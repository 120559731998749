import { compose } from "@reduxjs/toolkit";
import {
  type FormikProps,
  type FormikState,
  type FormikValues,
  withFormik,
  type WithFormikConfig,
  type FormikHelpers,
} from "formik";
import type { NullableMaybe } from "types/utilities";

export type ComposedFormProps<
  OuterProps,
  Payload extends FormikValues,
> = OuterProps & FormikProps<Payload> & FormikState<Payload> & IHasForm;

export interface IFormHandle {
  values: Record<string, string>;
  getValue: (key: string) => string;
  getTouched: (key: string) => boolean;
  getError: (key: string) => string;
  hasField: (key: string) => boolean;
  isValid?: boolean;
  isTouched?: boolean;
  isSubmitting?: boolean;
  submitForm: () => void;
  setFieldError: (key: string, error: string) => void;
  setFieldValue?: (key: string, value: string) => void;
  setFieldTouched: (key: string, isTouched: boolean) => void;
  setErrors: (errors: string[]) => void;
  handleBlur?: (e: Event) => void;
  resetForm: () => void;
}

export interface IHasForm {
  form?: IFormHandle;
}

export function wrapFormProps<
  OuterProps,
  Payload extends Record<string, NullableMaybe<{}>>,
>(props: OuterProps & FormikProps<Payload>): IFormHandle {
  return {
    values: props.values as any,
    getValue: (key: string) => String(props.values[key] || ""),
    getTouched: (key: string) => Boolean(props.touched[key] || false),
    getError: (key: string) => String(props.errors[key] || ""),
    hasField: (key: string) => props.values.hasOwnProperty(key),
    isValid: props.isValid,
    isSubmitting: props.isSubmitting,
    isTouched: Object.values(props.touched).length > 0,
    submitForm: props.submitForm,
    setErrors: props.setErrors as any,
    setFieldValue: props.setFieldValue,
    setFieldTouched: props.setFieldTouched,
    setFieldError: props.setFieldError,
    handleBlur: props.handleBlur,
    resetForm: props.resetForm,
  };
}

export function composeForm<
  Any,
  Values extends FormikValues = FormikValues,
  Payload extends Record<string, NullableMaybe<{}>> = Values,
>(config: WithFormikConfig<Any, Values, Payload>) {
  return compose<React.ComponentType<Any>>(
    // eslint-disable-next-line @typescript-eslint/ban-types
    withFormik(config as unknown as WithFormikConfig<object, Values, Payload>),
    (comp: any) => (inProps: FormikProps<Payload>) =>
      comp({
        ...inProps,
        form: wrapFormProps(inProps),
      }),
  );
}

export function composeLegacyForm<
  OuterProps,
  Values extends FormikValues = FormikValues,
  Payload extends Record<string, NullableMaybe<{}>> = Values,
>(config: WithFormikConfig<OuterProps, Values, Payload>) {
  return compose(composeForm(config), (comp: any) => (props: any) => {
    const legacyIssues = {
      ...(props.issues || {}),
      ...(props.validation || {}),
    };
    if (legacyIssues) {
      for (const key in legacyIssues) {
        if (!legacyIssues[key]) {
          continue;
        }
        const value = legacyIssues[key];
        const firstIssue =
          value instanceof Array ? value[value.length - 1] : value;

        if (!props.errors[key]) {
          props.errors[key] = firstIssue;
          if (props.issues) {
            props.issues[key] = "";
          }
          if (props.validation) {
            props.validation[key] = "";
          }
          props.touched[key] = true;
        }
      }
    }
    for (const key in props.data) {
      if (props.hasOwnProperty(key) === false) {
        continue;
      }
      props.values[key] = props.data[key];
    }
    delete props.issues;
    if (props.onChange) {
      props.form._setFieldValue = props.form.setFieldValue;
      props.form.setFieldValue = (key: string, value: any) => {
        props.onChange(key, value);
        props.form._setFieldValue(key, value);
      };
    }
    return comp(props);
  });
}

export const makeHandleAsyncSubmit =
  <Values>(
    callback: (values: Values, bag: FormikHelpers<Values>) => any,
    {
      onSuccess,
      onFailure,
      onComplete,
    }: {
      onSuccess?: (bag: FormikHelpers<Values>, response: any) => void;
      onFailure?: (bag: FormikHelpers<Values>, response: any) => void;
      onComplete?: (bag: FormikHelpers<Values>, response: any) => void;
    } = {},
  ) =>
  async (values: Values, bag: FormikHelpers<Values>) => {
    const { setErrors, setSubmitting } = bag;
    setSubmitting(true);
    const response = await callback(values, bag);

    // success
    if (response.meta.requestStatus === "fulfilled") {
      onSuccess && onSuccess(bag, response);
    }

    // failure
    if (response.meta.requestStatus === "rejected") {
      const {
        errors,
        message,
        messageTemplate,
      }: { errors: any; message: string; messageTemplate: string } =
        response.payload ?? {};

      if (errors && Object.keys(errors).length > 0) {
        setErrors(errors);
      } else {
        setErrors({ message, messageTemplate } as any);
      }

      onFailure && onFailure(bag, response);
    }

    onComplete && onComplete(bag, response);
    setSubmitting(false);
  };

// /**
//  * TODO: Shorten form decalaration
//  */
// export type ISchema<T> = Yup.Schema<any> & { defaultValue: T };
// export interface IFormFieldProps {
//   defaultValue?: string,
//   validation?: Yup.Schema<any>
// }
// export function withForm(
//   displayName: string,
//   fields: Record<string, IFormFieldProps>,
//   {
//     onSuccess,
//     onFailure,
//     onComplete,
//     validateOnChange = true
//   }: {
//     onSuccess?: (form?: IFormHandle) => void,
//     onFailure?: (form?: IFormHandle) => void,
//     onComplete?: (form?: IFormHandle) => void,
//     validateOnChange?: boolean
//   } = {}
// ) {
//   return composeForm({
//     mapPropsToValues: () => objectMap(fields, x => x.defaultValue || ""),
//     validationSchema: Yup.object().shape(
//       objectMap(fields, x => x.validation) as any
//     ),
//     handleSubmit: (payload, formikBag) => {
//       const {
//         setSubmitting,
//         setErrors,
//         props: {
//           verifyFields
//         },
//       } = formikBag as FormikBag<any, any>;
//       const form = wrapFormProps(formikBag as any);
//       setSubmitting(true);
//       verifyFields(
//         payload,
//         setErrors,
//         () => onSuccess && onSuccess(form),
//         () => onFailure && onFailure(form),
//         () => {
//           setSubmitting(false);
//           if (onComplete) {
//             onComplete(form);
//           }
//         }
//       );
//     },
//     validateOnChange,
//     displayName,
//   });
// }
