import cx from "classnames";
import React, { type PropsWithChildren } from "react";

import { ReactComponent as PlaceMedals1 } from "sections/Betting/Race/components/assets/icon-medal1.svg";
import { ReactComponent as PlaceMedals2 } from "sections/Betting/Race/components/assets/icon-medal2.svg";
import { ReactComponent as PlaceMedals3 } from "sections/Betting/Race/components/assets/icon-medal3.svg";
import { ReactComponent as PlaceMedals4 } from "sections/Betting/Race/components/assets/icon-medal4.svg";
import type { SubOutcome } from "sections/Entries/types";
import AnimatedList from "./AnimatedList";

import * as styles from "./ExoticSelections.module.scss";

type ExoticRunnersInPositionProps = {
  outcomes: SubOutcome[];
  position: number;
  isAnimated?: boolean;
};

type ExoticSelectionsProps = PropsWithChildren<{ className?: string }>;

export const ExoticSelections = ({
  className,
  children,
}: ExoticSelectionsProps) => {
  return (
    <div className={cx(styles.exoticSelections, className)}>{children}</div>
  );
};

const icon = {
  1: <PlaceMedals1 />,
  2: <PlaceMedals2 />,
  3: <PlaceMedals3 />,
  4: <PlaceMedals4 />,
};

export const ExoticRunnersInPosition = ({
  outcomes,
  position,
  isAnimated = true,
}: ExoticRunnersInPositionProps) => {
  const sortedRunners = outcomes.sort(
    (a, b) =>
      parseInt(a.attributes.runnerNumber) - parseInt(b.attributes.runnerNumber),
  );

  return (
    <div className={styles.exoticRunners}>
      <div>{icon[position]}</div>
      <div className={styles.runnersList}>
        <AnimatedList
          list={sortedRunners}
          isAnimated={isAnimated}
          renderItem={(outcome, i) => {
            return (
              <div className={styles.runnerNumber}>
                <div
                  className={cx({
                    [styles.winner]: outcome.outcomeResult === "WIN",
                  })}
                >
                  {outcome.attributes.runnerNumber}
                </div>
                {i === sortedRunners.length - 1 ? null : ","}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
