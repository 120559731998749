import React, { PureComponent } from "react";
import cx from "classnames";
import * as styles from "./Input.module.scss";
import Icon from "../Icon";

export interface IInputProps extends IHasGutters {
  name?: string;
  type: "text" | "password" | "email" | "number" | "tel";
  disableAutoComplete?: boolean;
  enableClear?: boolean;
  className?: string;
  inputMode?: string;
  maxLength?: number;
  label?: string;
  icon?: string;
  onIconClick?: () => unknown;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: any;
  issue?: string | string[];
  isWholeNumber?: boolean;
  disabled?: boolean;
  pattern?: string;
  Component?: any;
  uppercase?: boolean;
  lowercase?: boolean;
  readOnly?: boolean;
  showIssues?: boolean;

  [key: string]: any;
}

export class Input extends PureComponent<IInputProps> {
  inputRef: React.RefObject<HTMLInputElement & any>;

  constructor(props: IInputProps) {
    super(props);
    this.inputRef = React.createRef();
    this.focus = this.focus.bind(this);
  }

  setCaretPosition = (element: HTMLInputElement, pos: number): void => {
    // Modern browsers
    if (element.setSelectionRange) {
      element.setSelectionRange(pos, pos);
      element.focus();
    }
  };

  // Manually triggered from external component
  focus() {
    const element = this.props.Component
      ? this?.inputRef?.current?.inputElement
      : this?.inputRef?.current;
    element.focus();

    if (this.props.inputMode === "number") {
      setTimeout(() => {
        if (element?.current?.inputElement !== undefined) {
          this.setCaretPosition(element, element.value?.length);
        }
      }, 50);
    }
  }

  detectEnterPress = (event: any) => {
    const { onPressEnter, onKeyPress } = this.props;
    if (event.which === 13 && onPressEnter) {
      onPressEnter(event.target.value);
    }
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  render() {
    const {
      name,
      className: classNameProps,
      gutterBottom = false,
      gutterTop = false,
      gutterLeft = false,
      gutterRight = false,
      type = "text",
      placeholder,
      pattern,
      disabled,
      label,
      onFocus,
      onBlur,
      onClear,
      value,
      issue,
      icon,
      onIconClick,
      isWholeNumber = false,
      Component,
      uppercase,
      lowercase,
      maxLength,
      readOnly = false,
      disableAutoComplete = false,
      showIssues = true,
      ...props
    } = this.props;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!props.onChange) return;

      let value = e.target.value || "";
      if (uppercase) {
        value = value.toUpperCase();
      } else if (lowercase) {
        value = value.toLowerCase();
      }
      const maxLengthNum = Number(maxLength);
      if (maxLengthNum && value.length > maxLengthNum) {
        value = value.substr(0, maxLengthNum);
      }
      e.target.value = value;
      props.onChange(e);
    };

    const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    };

    const containerClassName = cx(
      {
        [styles.container]: true,
        [styles.gutterBottom]: gutterBottom,
        [styles.gutterTop]: gutterTop,
        [styles.gutterLeft]: gutterLeft,
        [styles.gutterRight]: gutterRight,
      },
      classNameProps,
    );

    const className = cx(
      {
        [styles.input]: true,
      },
      label,
    );

    const inputPattern = pattern ? pattern : isWholeNumber ? "d*" : ".*";
    const inputType = type ? type : isWholeNumber ? "tel" : "text";
    const inputMode = props.inputMode
      ? props.inputMode
      : isWholeNumber
        ? "number"
        : "text";

    const inputProps = {
      ...props,
    };
    delete inputProps.gutterTop;
    delete inputProps.gutterRight;
    delete inputProps.gutterBottom;
    delete inputProps.gutterLeft;
    delete inputProps.issue;
    delete inputProps.Component;

    const firstIssue = issue instanceof Array ? issue[0] : String(issue || "");

    const renderElement = () => (
      <>
        <div
          className={cx(styles.inputContainer, {
            [styles.hasErrors]: !!issue,
          })}
        >
          {Component ? (
            <Component
              {...inputProps}
              name={name}
              value={value}
              type={inputType}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={onBlur}
              onKeyPress={this.detectEnterPress}
              className={className}
              pattern={inputPattern}
              disabled={disabled}
              formNoValidate={isWholeNumber}
              inputMode={inputMode}
              readOnly={readOnly}
              ref={this.inputRef}
              autoComplete={disableAutoComplete ? "off" : "on"}
            />
          ) : (
            <input
              name={name}
              value={value}
              type={inputType}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={onBlur}
              onKeyPress={this.detectEnterPress}
              className={className}
              pattern={inputPattern}
              disabled={disabled}
              formNoValidate={isWholeNumber}
              inputMode={inputMode as any}
              readOnly={readOnly}
              ref={this.inputRef}
              autoComplete={disableAutoComplete ? "off" : "on"}
            />
          )}

          {icon && (!onClear || (onClear && value === "" && !disabled)) && (
            <div
              className={cx(styles.icon, {
                [styles.clickable]: !!onIconClick,
              })}
              onClick={() => onIconClick && onIconClick()}
            >
              <Icon type={icon} />
            </div>
          )}
          {onClear &&
            value !== "" &&
            !disabled && ( // Clear Button
              <Icon
                type="clearStake"
                svg
                className={cx(styles.icon, styles.clickable)}
                onClick={onClear}
              />
            )}
        </div>
        {firstIssue && showIssues && (
          <div className={styles.issues}>{firstIssue}</div>
        )}
      </>
    );

    return (
      <div className={containerClassName}>
        {label ? (
          <label className={styles.labelContainer}>
            <div className={styles.label}>{label}</div>
            {renderElement()}
          </label>
        ) : (
          <div className={styles.labelContainer}>{renderElement()}</div>
        )}
      </div>
    );
  }
}

export default Input;
