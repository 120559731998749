import React, { useState, useEffect, type ReactElement } from "react";
import { type DragHandlers, motion } from "framer-motion";
import * as styles from "./Carousel.Root.module.scss";
import cx from "classnames";
import type { ItemProps } from "./Carousel.Item";

type RootProps = {
  children: ReactElement<ItemProps>[];
  className?: string;
  displaySeconds?: number;
  controlsType?: "dots" | "bars";
};

export const Root = React.forwardRef<HTMLDivElement, RootProps>(
  ({ children, displaySeconds, controlsType = "bars" }, ref) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [direction, setDirection] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
      setIsFirstRender(false);
    }, []);

    const variants = {
      enter: { x: direction > 0 ? "100%" : "-100%" },
      center: { x: 0, opacity: 1 },
      exit: { x: direction < 0 ? "100%" : "-100%" },
    };

    const handleDragStart = () => {
      setIsDragging(true);
    };

    const handleDragEnd: DragHandlers["onDragEnd"] = (_, { offset }) => {
      setIsDragging(false);
      if (offset.x > 100) {
        setImageIndex(imageIndex === 0 ? children.length - 1 : imageIndex - 1);
        setDirection(-1);
      } else if (offset.x < -100) {
        setImageIndex(imageIndex === children.length - 1 ? 0 : imageIndex + 1);
        setDirection(1);
      }
    };

    useEffect(() => {
      // see if this child has a custom duration, or use the prop
      const duration = children[imageIndex]?.props?.duration ?? displaySeconds;
      if (!isDragging && duration) {
        const timer = setTimeout(() => {
          setImageIndex(
            imageIndex === React.Children.count(children) - 1
              ? 0
              : imageIndex + 1,
          );
          setDirection(1);
        }, duration * 1000);
        return () => clearTimeout(timer);
      }
    }, [imageIndex, isDragging, children]);

    return (
      <div className={styles.carouselContainer}>
        <div ref={ref} className={styles.carousel}>
          <motion.div
            key={imageIndex}
            custom={direction}
            variants={variants}
            initial={isFirstRender ? "none" : "enter"}
            animate="center"
            exit="exit"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onClick={(e) =>
              !isDragging && children[imageIndex]?.props?.onClick?.(e)
            }
            className={cx(styles.banner)}
          >
            {children[imageIndex]}
          </motion.div>
          {children.length > 1 && controlsType === "bars" && (
            <div className={styles.bars}>
              {children.map((_, i) => (
                <div
                  data-testid={`control-${i}`}
                  key={i}
                  className={cx(styles.bar, {
                    [styles.active]: i === imageIndex,
                  })}
                  onClick={() => {
                    setDirection(i > imageIndex ? 1 : -1);
                    setImageIndex(i);
                  }}
                />
              ))}
            </div>
          )}
          {children.length > 1 && controlsType === "dots" && (
            <div className={styles.dots}>
              {children.map((_, i) => (
                <div
                  data-testid={`control-${i}`}
                  key={i}
                  className={cx(styles.dot, {
                    [styles.active]: i === imageIndex,
                  })}
                  onClick={() => {
                    setDirection(i > imageIndex ? 1 : -1);
                    setImageIndex(i);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  },
);
