// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "EventStatus-module--emphasis--3d00d";
export var green = "#57d33b";
export var greenLink = "EventStatus-module--green-link--0763e";
export var grey = "var(--tone-mid)";
export var live = "EventStatus-module--live--f6189";
export var lock = "EventStatus-module--lock--f98f3";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pulse = "EventStatus-module--pulse--0c5ab";
export var pulseAnimation = "EventStatus-module--pulseAnimation--292ea";
export var red = "#ff2741";
export var tick = "EventStatus-module--tick--8b86d";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";