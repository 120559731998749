import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  chatOpen: false,
  search: "",
  labels: ["support"],
  articleId: "",
  unreadCount: 0,
};

export type helpCenterState = typeof initialState;

const helpCenterSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openHelpCenter(state) {
      state.visible = true;
    },
    closeHelpCenter(state) {
      state.visible = false;
      state.search = "";
      state.labels = [];
      state.articleId = "";
    },
    closeChat(state) {
      state.chatOpen = false;
      state.search = "";
      state.labels = [];
      state.articleId = "";
    },
    openChat(state) {
      state.chatOpen = true;
    },
    setHelpCenterSearch(state, action) {
      state.chatOpen = false;
      state.labels = [];
      state.articleId = "";
      state.search = action.payload;
    },
    setHelpCenterLabels(state, action) {
      state.chatOpen = false;
      state.search = "";
      state.articleId = "";
      state.labels = action.payload;
    },
    setHelpCenterArticleId(state, action) {
      state.chatOpen = false;
      state.articleId = action.payload;
      state.search = "";
      state.labels = [];
    },
    setUnreadCount(state, action) {
      state.unreadCount = action.payload;
    },
  },
});

export const {
  openHelpCenter,
  setHelpCenterLabels,
  setHelpCenterSearch,
  setHelpCenterArticleId,
  closeHelpCenter,
  closeChat,
  openChat,
  setUnreadCount,
} = helpCenterSlice.actions;

export default helpCenterSlice.reducer;
