import { groupBy } from "lodash";
import { useContext } from "react";
import { RaceContext } from "sections/Betting/Race";
import {
  RaceMarketTypeTypes,
  type RaceMarketsType,
  type ExoticMarketType,
  type RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";

const getOutcomesFromMarket = (markets: RaceMarketsType[]): RaceOutcomeType[] =>
  Object.entries(markets?.[0]?.outcomes ?? {}).flatMap(
    ([outcomeId, outcome]) =>
      outcome.active ? ({ ...outcome, id: outcomeId } as const) : [],
  );

export const useExoticsMarketsAndOutcomes = (exoticType: ExoticMarketType) => {
  const { markets: allMarkets, race, loading } = useContext(RaceContext);

  const exoticMarket = allMarkets?.filter((m) => m.marketType === exoticType);
  const outcomes = getOutcomesFromMarket(exoticMarket);
  const groupedOutcomes = groupBy(outcomes, (o) => o.type);

  // win outcomes are needed to surface odds against a competitor
  const winMarkets = allMarkets?.filter(
    (m) => m.marketType === RaceMarketTypeTypes.Win,
  );
  const winMarketOutcomes = getOutcomesFromMarket(winMarkets);
  const getCompetitorOdds = (competitorId: string) =>
    winMarketOutcomes.filter((o) => o.competitorId === competitorId)[0]?.odds;

  return {
    markets: exoticMarket,
    outcomes,
    groupedOutcomes,
    race,
    loading,
    getCompetitorOdds,
  };
};
