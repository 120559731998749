// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ErrorMessage-module--emphasis--4b8be";
export var error = "ErrorMessage-module--error--be12a";
export var errorMessage = "ErrorMessage-module--errorMessage--45755";
export var green = "#57d33b";
export var greenLink = "ErrorMessage-module--green-link--8fb58";
export var grey = "var(--tone-mid)";
export var info = "ErrorMessage-module--info--24963";
export var message = "ErrorMessage-module--message--3775d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var srmLogo = "ErrorMessage-module--srmLogo--a4a80";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";