import React, { useState } from "react";
import { useIsClient } from "usehooks-ts";
import type { MenuItem } from "./types";
import Item from "./Item";
import { usePageContext } from "hooks/usePageContext";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

import * as styles from "./Items.module.scss";

type ItemsProps = {
  items: MenuItem[];
};
const Items = ({ items }: ItemsProps) => {
  const [hasExpanded, setHasExpanded] = useState(false);
  const [expandedItem, setExpandedItem] = useState(undefined);
  const { section } = usePageContext();
  const isClient = useIsClient();

  useIsomorphicLayoutEffect(() => {
    // only expand on client, if not already expanded and if there is a section
    if (!isClient || hasExpanded || expandedItem) return;
    const defaultExpandedItem = items.find((item) =>
      item.sections?.includes(section),
    );

    if (defaultExpandedItem && defaultExpandedItem.items?.length > 1) {
      setExpandedItem(defaultExpandedItem.name);

      // prevent menu expanding twice
      setHasExpanded(true);
    }
  }, [section, items, hasExpanded]);

  return (
    <ul className={styles.items}>
      {items.map((item, index) => (
        <Item
          key={`${item.name}-${index}`}
          item={item}
          expandedItem={expandedItem}
          setExpandedItem={setExpandedItem}
        />
      ))}
    </ul>
  );
};

export default Items;
