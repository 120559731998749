// extracted by mini-css-extract-plugin
export var HUDClose = "HUDPanel-module--HUDClose--e7316";
export var HUDHeader = "HUDPanel-module--HUDHeader--8cfc5";
export var HUDPanel = "HUDPanel-module--HUDPanel--54268";
export var blue = "#0eb3f2";
export var emphasis = "HUDPanel-module--emphasis--9ff97";
export var green = "#57d33b";
export var greenLink = "HUDPanel-module--green-link--4f4fa";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";