import React from "react";
import cx from "classnames";
import * as styles from "./BlogWidgets.module.scss";

const Player = ({ alias, team, kd, hsp, assists, "image-url": imageUrl }) => {
  return (
    <div className={styles.statWidget}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={styles.stats}>
        <div className={styles.name}>{alias}</div>
        <div className={styles.stat}>
          <span>Team:</span> {team}
        </div>
        <div
          className={cx(styles.stat, {
            [styles.green]: parseFloat(kd) >= 1,
            [styles.red]: parseFloat(kd) < 1,
          })}
        >
          <span>K/D Ratio:</span> {kd}
        </div>
        <div
          className={cx(styles.stat, {
            [styles.green]: parseFloat(hsp) >= 50,
            [styles.yellow]: parseFloat(hsp) >= 40 && parseFloat(hsp) < 50,
            [styles.red]: parseFloat(hsp) < 40,
          })}
        >
          <span>Headshot Percentage:</span> {hsp}
        </div>
        <div
          className={cx(styles.stat, {
            [styles.green]: parseFloat(assists) >= 0.14,
            [styles.yellow]:
              parseFloat(assists) >= 0.1 && parseFloat(assists) < 0.14,
            [styles.red]: parseFloat(assists) < 0.1,
          })}
        >
          <span>Assists / Round:</span> {assists}
        </div>
      </div>
    </div>
  );
};

export default Player;
