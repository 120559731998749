// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var competitor = "RaceTipsCompetitorsSkeleton-module--competitor--a1a23";
export var competitorInfo = "RaceTipsCompetitorsSkeleton-module--competitorInfo--0c00b";
export var competitors = "RaceTipsCompetitorsSkeleton-module--competitors--1ecde";
export var emphasis = "RaceTipsCompetitorsSkeleton-module--emphasis--d4c19";
export var green = "#57d33b";
export var greenLink = "RaceTipsCompetitorsSkeleton-module--green-link--851a9";
export var grey = "var(--tone-mid)";
export var jockey = "RaceTipsCompetitorsSkeleton-module--jockey--461a2";
export var mobile = "RaceTipsCompetitorsSkeleton-module--mobile--9d826";
export var notchGap = "32px";
export var odds = "RaceTipsCompetitorsSkeleton-module--odds--68955";
export var oddsValue = "RaceTipsCompetitorsSkeleton-module--oddsValue--e6cbd";
export var orange = "#f8662b";
export var red = "#ff2741";
export var runnerName = "RaceTipsCompetitorsSkeleton-module--runnerName--0312c";
export var runnerNumber = "RaceTipsCompetitorsSkeleton-module--runnerNumber--5f01d";
export var sgm = "RaceTipsCompetitorsSkeleton-module--sgm--566fb";
export var silks = "RaceTipsCompetitorsSkeleton-module--silks--dcfb1";
export var silksContainer = "RaceTipsCompetitorsSkeleton-module--silksContainer--6741d";
export var trainer = "RaceTipsCompetitorsSkeleton-module--trainer--16840";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";