import i18next, { type i18n } from "i18next";

let instance: i18n;

const getI18nInstance = () => {
  if (!instance) {
    instance = i18next.createInstance({
      nsSeparator: "::",
    });
  }

  return instance;
};

export default getI18nInstance;
