import {
  type FormikConfig,
  type FormikValues,
  useFormik as unwrappedUseFormik,
  type FormikProps,
} from "formik";
import { type IFormHandle, wrapFormProps } from "../middleware/forms";

export const useFormik = (
  config: FormikConfig<FormikValues>,
): [IFormHandle, FormikProps<any>] => {
  // we wrap formik fields with our helper methods
  const form = unwrappedUseFormik(config);
  return [wrapFormProps(form), form];
};
