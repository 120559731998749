// extracted by mini-css-extract-plugin
export var arrow = "FlucsHistory-module--arrow--303b0";
export var blue = "#0eb3f2";
export var container = "FlucsHistory-module--container--dffdd";
export var emphasis = "FlucsHistory-module--emphasis--7367f";
export var flucsIcon = "FlucsHistory-module--flucsIcon--5e718";
export var green = "#57d33b";
export var greenLink = "FlucsHistory-module--green-link--266f8";
export var grey = "var(--tone-mid)";
export var isOpeningOdds = "FlucsHistory-module--isOpeningOdds--54576";
export var notchGap = "32px";
export var oddsChange = "FlucsHistory-module--oddsChange--6a8cb";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";