// extracted by mini-css-extract-plugin
export var Page = "Page-module--Page--06040";
export var blue = "#0eb3f2";
export var emphasis = "Page-module--emphasis--9d408";
export var green = "#57d33b";
export var greenLink = "Page-module--green-link--355e5";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageBanner = "Page-module--pageBanner--989f8";
export var pageBody = "Page-module--pageBody--c1798";
export var pageMain = "Page-module--pageMain--36dd3";
export var pageSide = "Page-module--pageSide--87c14";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";