import { get } from "lodash";
import BigNumber from "bignumber.js";

const IF_REQUIRED = "IF_REQUIRED";

const countryCurrencies = {
  AU: "AUD",
  CA: "CAD",
  NZ: "NZD",
  US: "USD",
};
const currencySymbols = {
  AUD: "AU$",
  CAD: "CA$",
  EUR: "€",
  GBP: "£GB",
  NZD: "NZ$",
  USD: "US$",
};

// Currency overrides do not have the currency code. They are used when locale is matching user"s one
const currencySymbolsOverrides = {
  AUD: "$",
  CAD: "$",
  EUR: "€",
  GBP: "£",
  NZD: "$",
  USD: "$",
};

/**
 * @name constructCurrency
 * @description Returns a formatted currency string
 * @param {number} amount - The amount of currency
 * @param {Object} options - An Object containing options
 * @param {string} [options.currencyType=dollar] - Currency type AUD/USD/CAD
 * @param {string} [options.userCurrency=dollar] - Current user fiat currency
 * @param {any} [options.showDecimals=true] - Either A boolean determining whether to show decimal
 *   point values or the special value IF_REQUIRED that will add the decimals if the amount contains cents
 * @param {any} [options.showOperator=IF_REQUIRED] - Determines whether to return '+' or
 *   '-' at the start of the string. A boolean or the special value IF_REQUIRED that will add the operator if the amount is negative
 * @param {boolean} [options.showCurrencySymbol=true] - Whether to show the currency symbol,
 *   eg. '$'
 */

const parseLocaleNumber = (localeNumber, format = "CENTS" /*| "DECIMAL"*/) => {
  if (typeof localeNumber !== "string") {
    localeNumber = localeNumber.toString();
  }
  if (!localeNumber || localeNumber === "") return localeNumber;
  const cents = parseFloat(localeNumber.replace(",", ""));

  if (format === "CENTS") {
    return cents * 100;
  }

  return cents;
};

const constructCurrency = (amount = 0, options = {}) => {
  const defaultOptions = {
    currencyType: "USD",
    showOperator: IF_REQUIRED,
    showCurrencySymbol: true,
  };

  let {
    currencyType: currency,
    showCurrencySymbol,
    userCurrency,
  } = Object.assign({}, defaultOptions, options);

  if (!userCurrency) {
    userCurrency = currency;
  }

  // we need to keep this to display historical skr values
  if (currency === "SKR") {
    return convertSkr(amount, options);
  }

  const showDecimals = shouldShowDecimals(amount, options);

  const windowLocale = typeof window !== "undefined" && window.___currentLocale;
  const locale = windowLocale
    ? windowLocale === "en-eu" // en-eu is not a valid locale fix it here
      ? "en-105"
      : windowLocale
    : "en-us";

  // clean string values and replace separators if required
  const cleanAmount =
    typeof amount === "string"
      ? parseFloat(amount.replace(",", ""))
      : parseFloat(amount);

  const isNegative = cleanAmount < 0;
  const showOperator = shouldShowOperator(isNegative, options);

  let formatted = new Intl.NumberFormat(locale, {
    style: "decimal",
    // currency,
    // currencyDisplay: "symbol",
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
  }).format(Math.abs(cleanAmount / 100));

  //Only show currency symbol if the user"s currency does not match the currency of the amount being formatted.
  const currencySymbol =
    userCurrency === currency
      ? get(currencySymbolsOverrides, currency, currency)
      : get(currencySymbols, currency, currency);

  if (showCurrencySymbol) {
    formatted = `${currencySymbol}${formatted}`;
  }

  if (showOperator && cleanAmount > 0) {
    formatted = "+" + formatted;
  }

  if (showOperator && isNegative) {
    formatted = "-" + formatted;
  }

  return formatted;
};

const shouldShowOperator = (isNegative = false, options = {}) => {
  switch (options.showOperator) {
    case IF_REQUIRED:
      return isNegative;
    case undefined:
      return isNegative;
    default:
      return options.showOperator;
  }
};

export const fixedWithoutRounding = (v, decimalPlaces) => {
  const intPart = Math.trunc(v).toString();
  const fractionPart = v.toString().slice(v.toString().indexOf(".") + 1);
  if (fractionPart.length > decimalPlaces) {
    return Number(intPart.concat(".", fractionPart.slice(0, decimalPlaces)));
  } else {
    const padded = intPart.concat(".", fractionPart.padEnd(decimalPlaces, "0"));
    return parseFloat(padded);
  }
};

const shouldShowDecimals = (amount = 0, options = {}) => {
  switch (options.showDecimals) {
    case IF_REQUIRED:
      return amount % 100 > 0;
    case undefined:
      return true;
    default:
      return options.showDecimals;
  }
};

const convertSkr = (amount = 0, options = {}) => {
  const showDecimals =
    options.showDecimals !== undefined ? options.showDecimals : true;
  const showOperator =
    options.showOperator !== undefined ? options.showOperator : false;
  const showCurrencySymbol =
    options.showCurrencySymbol !== undefined
      ? options.showCurrencySymbol
      : true;

  let currency =
    (parseInt(amount) / 1000000).toLocaleString(undefined, {
      maximumFractionDigits: 6,
    }) + " SKR";
  if (!showCurrencySymbol) {
    currency = currency.replace(" SKR", "");
  }

  // Remove decimals at the end of the string, eg. '.00'
  if (!showDecimals) {
    currency = currency.replace(/\.\d\d$/, "");
  }
  // Add operator
  if (showOperator && amount > 0) currency = "+" + currency;

  return currency;
};

/**
 * @name convertAmountToCents
 * @description Returns the currency amount in cents.
 *   If the amount in cents has decimals, round it down.
 * @param {string | number} amount
 */
const convertAmountToCents = (amount = 0) => {
  if (typeof amount === "string") {
    amount = amount.replace(",", "");
  }

  return new BigNumber(amount).multipliedBy(100).toNumber();
};
/**
 * @name convertCentsToAmount
 * @description Returns the cents amount as a monetary amount.
 * @param {string | number} amount
 */
const convertCentsToAmount = (amount = 0) => {
  return new BigNumber(amount)
    .dividedBy(100)
    .decimalPlaces(2, BigNumber.ROUND_DOWN)
    .toNumber();
};

/**
 * @name convertSKRToInt
 * @description Similar to the convertAmountToCents method, turns a human
 * readable SKR number into its integer representation.
 */
const convertSKRToInt = (amount = 0) => {
  if (typeof amount === "string") {
    amount = amount.replace(",", "");
  }
  const currencyInt = Math.floor(parseFloat(amount) * 1000000);
  return currencyInt;
};

const currencyToIconType = (currency) => {
  if (currency === "EUR") {
    return "euro";
  }

  if (currency === "PROMO") {
    return "promoCash";
  }

  return "dollar";
};

const getCurrencySymbol = (currency) => {
  return currencySymbolsOverrides[currency] || "$";
};

export const euroCountries = [
  "AL",
  "AD",
  "AT",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CZ",
  "GB",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "VA",
  "HU",
  "IS",
  "IE",
  "IT",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "MC",
  "ME",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SM",
  "RS",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "UA",
];

const getCurrencyByCountry = (country) => {
  if (euroCountries.includes(country)) {
    return "EUR";
  }
  return countryCurrencies[country] || "USD";
};

export {
  parseLocaleNumber,
  constructCurrency,
  convertAmountToCents,
  convertCentsToAmount,
  convertSKRToInt,
  currencyToIconType,
  getCurrencySymbol,
  getCurrencyByCountry,
  IF_REQUIRED,
};
