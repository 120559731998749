import { startCase } from "lodash";
import React from "react";
import type {
  ExoticDividend,
  ExoticMarketType,
  RaceEventType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { ReactComponent as ExactaIcon } from "./assets/icon-exacta.svg";
import { ReactComponent as FirstFourIcon } from "./assets/icon-first-four.svg";
import { ReactComponent as QuinellaIcon } from "./assets/icon-quinella.svg";
import { ReactComponent as TrifectaIcon } from "./assets/icon-trifecta.svg";

import * as styles from "./ExoticMarketResults.module.scss";

const icons: Record<ExoticMarketType, React.ReactNode> = {
  EXACTA: <ExactaIcon />,
  QUINELLA: <QuinellaIcon />,
  TRIFECTA: <TrifectaIcon />,
  FIRST_4: <FirstFourIcon />,
};

type DividendRowProps = {
  icon: React.ReactNode;
  market: string;
  runners: string;
  dividend: string;
};

const DividendRow = ({ icon, market, runners, dividend }: DividendRowProps) => {
  return (
    <div className={styles.marketResult}>
      <div className={styles.iconAndInfo}>
        <div>{icon}</div>

        <div className={styles.nameAndRunners}>
          <div className={styles.marketName}>{market}</div>
          <div className={styles.runners}>{runners}</div>
        </div>
      </div>

      <div className={styles.dividendAmount}>{dividend}</div>
    </div>
  );
};

type ResultProps = {
  market: ExoticMarketType;
  results?: ExoticDividend;
};

const Result = ({ market, results }: ResultProps) => {
  const marketName = startCase(market.toLowerCase()).replace("4", "Four");
  const icon = icons[market];

  return !results ? (
    <DividendRow icon={icon} market={marketName} runners={""} dividend={"-"} />
  ) : (
    Object.values(results).map((result) => {
      return (
        <DividendRow
          key={`${market}-results-${result.runners}`}
          icon={icon}
          market={marketName}
          runners={`${result.runners?.join(", ")}`}
          dividend={result.dividend.toFixed(2)}
        />
      );
    })
  );
};

type ExoticMarketResultsProps = {
  results?: RaceEventType["exoticResults"];
};

export const ExoticMarketResults = ({ results }: ExoticMarketResultsProps) => {
  return (
    <div className={styles.results}>
      <Result market="QUINELLA" results={results?.QUINELLA} />
      <Result market="EXACTA" results={results?.EXACTA} />
      <Result market="TRIFECTA" results={results?.TRIFECTA} />
      <Result market="FIRST_4" results={results?.FIRST_4} />
    </div>
  );
};
