import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useIsMobile } from "hooks";
import type {
  Outcome as OutcomeType,
  Outcomes as OutcomesType,
  Competitors as CompetitorsType,
} from "hooks/firestore/betting/useBetting";
import Outcome from "./Outcome";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";
import { groupBy } from "lodash";

import * as styles from "./Outcomes.module.scss";
import type {
  OutcomeWithMarketType,
  OutcomesWithMarketType,
} from "../Match/MarketGroup";
import { sortPairedOutcomes } from "utilities/sharedBettingUtilities";
import { sortOutcomes } from "utilities/sharedBettingUtilities";

export const OUTCOME_NAMES = [
  "2:0",
  "0:2",
  "1:1",
  "2:1",
  "1:2",
  "3:0",
  "0:3",
  "3:1",
  "1:3",
  "3:2",
  "2:3",
];

const excludedHeadToHeadMarkets = [
  // regex list contains DOUBLECHANCE
  /DOUBLECHANCE/,
];

type OutcomesProps = {
  eventId: string;
  marketId?: string;
  outcomes: OutcomesType | OutcomesWithMarketType;
  competitors: CompetitorsType;
  selections: any[];
  className?: string;
  oddsFormat?: string;
  toggleSelection: (pick: ToggleSelectionPayload) => void;
  lightBackground?: boolean;
  isSuspended?: boolean;
  isOutright?: boolean;
  marketType?: string;
  marketCategory?: string;
  showOdds?: boolean;
  status?: string;
  fullWidth?: boolean;
};

export type OutcomeTypeWithId = OutcomeType & { id: string; value?: string };

const Outcomes = ({
  outcomes = {},
  competitors,
  className: classNameProp,
  oddsFormat,
  toggleSelection,
  eventId,
  marketId: marketIdProp,
  lightBackground = false,
  isSuspended = false,
  isOutright = false,
  showOdds = false,
  marketType: marketTypeProp,
  marketCategory: marketCategoryProp,
  selections = [],
  status,
  fullWidth = false,
}: OutcomesProps) => {
  const isMobile = useIsMobile();

  const getMarketDetails = () => {
    const market = (Object.values(outcomes || {})?.[0] as OutcomeWithMarketType)
      ?.market;

    return {
      id: market?.id,
      marketType: market?.marketType,
      category: market?.category,
    };
  };

  const market = getMarketDetails();
  const [marketId, setMarketId] = useState(marketIdProp ?? market?.id);
  const [marketType, setMarketType] = useState(
    marketTypeProp ?? market?.marketType,
  );
  const [marketCategory, setMarketCategory] = useState(
    marketCategoryProp ?? market?.category,
  );

  useEffect(() => {
    if (!marketId) {
      const market = getMarketDetails();
      setMarketId(market?.id);
      setMarketType(market?.marketType);
      setMarketCategory(market?.category);
    }
  }, [marketId, marketIdProp, outcomes]);

  const homeOutcomeId = useMemo(
    () =>
      Object.keys(outcomes).find((outcomeId) =>
        outcomes[outcomeId].type.endsWith("_HOME"),
      ),
    [outcomes],
  );
  const awayOutcomeId = useMemo(
    () =>
      Object.keys(outcomes).find((outcomeId) =>
        outcomes[outcomeId].type.endsWith("_AWAY"),
      ),
    [outcomes],
  );
  const drawOutcomeId = useMemo(
    () =>
      Object.keys(outcomes).find(
        (outcomeId) => outcomes[outcomeId].type === "DRAW",
      ),
    [outcomes],
  );

  const sortedOutcomes = useMemo(() => {
    if (marketType === undefined) return [];
    const sorted = (
      marketType?.includes("HANDICAP") &&
      !marketType?.includes("HANDICAPTHREEWAY")
        ? sortPairedOutcomes(outcomes, ["HOME", "AWAY"], false)
        : Object.values(outcomes || {}).some(
              (outcome) => outcome.type === "OVER" || outcome.type === "UNDER",
            )
          ? sortPairedOutcomes(outcomes, ["OVER", "UNDER"], true)
          : sortOutcomes(outcomes, isOutright, marketType)
    ).filter((outcome) => {
      if (isOutright && !outcome.active) {
        return false;
      }

      return true;
    });

    return sorted;
  }, [outcomes, marketType]);

  const renderHeadToHeadRow = (
    homeOutcomeId: string,
    awayOutcomeId: string,
    drawOutcomeId?: string,
  ) => {
    const drawOutcome = drawOutcomeId ? outcomes[drawOutcomeId] : null;
    if (drawOutcome) {
      drawOutcome.type = "DRAW";
    }
    return (
      <React.Fragment key={`${homeOutcomeId}-${awayOutcomeId}`}>
        <Outcome
          id={homeOutcomeId}
          eventId={eventId}
          marketId={marketId}
          competitor={
            competitors
              ? competitors[outcomes[homeOutcomeId]?.competitorId]
              : null
          }
          className={styles.outcome}
          align="right"
          outcome={outcomes[homeOutcomeId]}
          oddsFormat={oddsFormat}
          isSelected={selections.includes(homeOutcomeId)}
          toggleSelection={toggleSelection}
          lightBackground={lightBackground}
          isSuspended={isSuspended}
          isPunted={outcomes[homeOutcomeId].punted}
          isSelectedAgainst={outcomes[homeOutcomeId].selectedAgainst}
          showOdds={showOdds}
          status={status}
        />
        {drawOutcomeId ? (
          <Outcome
            id={drawOutcomeId}
            isDraw
            eventId={eventId}
            marketId={marketId}
            competitor={null}
            className={styles.outcome}
            align="center"
            outcome={drawOutcome}
            oddsFormat={oddsFormat}
            isSelected={selections.includes(drawOutcomeId)}
            toggleSelection={toggleSelection}
            lightBackground={lightBackground}
            isSuspended={isSuspended}
            isPunted={outcomes[drawOutcomeId].punted}
            isSelectedAgainst={outcomes[drawOutcomeId].selectedAgainst}
            showOdds={showOdds}
            status={status}
          />
        ) : (
          <div className={styles.vs}>vs</div>
        )}
        <Outcome
          id={awayOutcomeId}
          eventId={eventId}
          marketId={marketId}
          competitor={
            competitors
              ? competitors[outcomes[awayOutcomeId]?.competitorId]
              : null
          }
          className={styles.outcome}
          align="left"
          outcome={outcomes[awayOutcomeId]}
          oddsFormat={oddsFormat}
          isSelected={selections.includes(awayOutcomeId)}
          toggleSelection={toggleSelection}
          lightBackground={lightBackground}
          isSuspended={isSuspended}
          isPunted={outcomes[awayOutcomeId].punted}
          isSelectedAgainst={outcomes[awayOutcomeId].selectedAgainst}
          showOdds={showOdds}
          status={status}
        />
      </React.Fragment>
    );
  };

  const isPromo = marketCategory === "Promotions";
  const isSameGameMulti = marketCategory === "Same Game Multis";
  const isCorrectScore = marketType?.includes("CORRECT_SCORE");
  const isFullWidth = fullWidth || isPromo || (isMobile && isCorrectScore);

  const isHeadToHead =
    homeOutcomeId &&
    awayOutcomeId &&
    Object.keys(outcomes).length <= 3 &&
    !excludedHeadToHeadMarkets.some((regex) => regex.test(marketType));

  if (isHeadToHead && !isFullWidth) {
    return (
      <div className={cx(styles.outcomes, classNameProp)}>
        {renderHeadToHeadRow(homeOutcomeId, awayOutcomeId, drawOutcomeId)}
      </div>
    );
  }

  const isWinningMargin = marketType?.includes("WINNING_MARGIN");

  if (isWinningMargin) {
    const groupedOutcomes = groupBy(outcomes, "abbreviation");
    // draw outcome doesn't have an abbreviation so it will be grouped as undefined in the object map
    // this changes the key from undefined to draw
    delete Object.assign(groupedOutcomes, {
      ["draw"]: groupedOutcomes["undefined"],
    })["undefined"];

    // sort the outcomes by their abbreviation
    const orderedGroupOutcomes = Object.keys(groupedOutcomes)
      .sort(new Intl.Collator("en", { numeric: true }).compare)
      .reduce((obj, key) => {
        obj[key] = groupedOutcomes[key];
        return obj;
      }, {});

    return (
      <div className={cx(styles.outcomes, classNameProp)}>
        {Object.keys(orderedGroupOutcomes).map((abbreviation) => {
          const abbreviationOutcomes = orderedGroupOutcomes[abbreviation];

          if (!abbreviationOutcomes) return;

          const drawOutcome = abbreviationOutcomes.find(
            (outcome) => outcome.type === "DRAW",
          );
          const drawId = Object.keys(outcomes).find(
            (outcomeId) => outcomes[outcomeId] === drawOutcome,
          );

          // draw should have its own outcome row
          if (abbreviation === "draw") {
            return (
              <Outcome
                className={cx(styles.outcome, styles.lastAndEven)}
                align={"right"}
                key={drawId}
                id={drawId}
                eventId={eventId}
                marketId={marketId}
                outcome={drawOutcome}
                competitor={
                  drawOutcome.competitorId
                    ? competitors[drawOutcome.competitorId]
                    : null
                }
                toggleSelection={toggleSelection}
                lightBackground={lightBackground}
                isSuspended={isSuspended || !drawOutcome.active}
                isOutright={isOutright}
                isSelected={selections.includes(drawId)}
                isPunted={outcomes[drawId].punted}
                isSelectedAgainst={outcomes[drawId].selectedAgainst}
                showOdds={showOdds}
                status={status}
              />
            );
          }

          const homeOutcome = abbreviationOutcomes.find((outcome) =>
            outcome.type.endsWith("_HOME"),
          );
          const awayOutcome = abbreviationOutcomes.find((outcome) =>
            outcome.type.endsWith("_AWAY"),
          );
          const homeId = Object.keys(outcomes).find(
            (outcomeId) => outcomes[outcomeId] === homeOutcome,
          );
          const awayId = Object.keys(outcomes).find(
            (outcomeId) => outcomes[outcomeId] === awayOutcome,
          );

          if (homeId && awayId) {
            return renderHeadToHeadRow(homeId, awayId);
          }
        })}
      </div>
    );
  }

  return (
    <div className={cx(styles.outcomes, classNameProp)}>
      {sortedOutcomes.map((outcome, index) => {
        const isEven = index % 2 === 0;
        const isLast = index === sortedOutcomes.length - 1;

        return (
          <React.Fragment key={`outcome-${outcome.id}`}>
            <Outcome
              className={cx(styles.outcome, {
                [styles.outrightOutcome]: isOutright,
                [styles.lastAndEven]:
                  isEven && isLast && !isOutright && !isFullWidth,
              })}
              align={isOutright ? "left" : isEven ? "right" : "left"}
              id={outcome.id}
              marketId={marketId}
              eventId={eventId}
              outcome={outcome}
              competitor={
                competitors
                  ? outcome.competitorId
                    ? competitors[outcome.competitorId]
                    : null
                  : null
              }
              toggleSelection={toggleSelection}
              lightBackground={lightBackground}
              isSuspended={isSuspended || !outcome.active}
              isOutright={isOutright}
              isSelected={selections.includes(outcome.id)}
              isPunted={outcome.punted}
              isSelectedAgainst={outcome.selectedAgainst}
              fullWidth={isFullWidth}
              showOdds={showOdds}
              oddsFormat={oddsFormat}
              status={status}
            />
            {isEven && !isOutright && !isFullWidth ? (
              <div
                className={cx(styles.vs, styles.hidden, {
                  [styles.displayNone]: isSameGameMulti,
                })}
              >
                vs
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Outcomes;
