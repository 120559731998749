import { useEffect, useState } from "react";
import { useSelector } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useEmailUnsubscribed } from "hooks/firestore";
import {
  collection,
  type CollectionReference,
  type FirestoreError,
} from "firebase/firestore";
import { useCollectionData } from "hooks";

export enum NotificationPreferenceType {
  VERIFICATION_SUCCESS = "Verification Success",
  VERIFICATION_REMINDER = "Verification Reminder",
  CONTEST_STARTING_REMINDER = "Contest Starting Reminder",
  WON_CONTEST = "Won Contest",
  WALLET_VERIFIED = "Wallet Address Verified",
  WALLET_REMOVED = "Wallet Address Removed",
  PLACE_CONTEST = "Place Contest",
  CANCELLED_CONTEST = "Cancelled Contest",
  DRAFT_ROSTER_REMINDER = "Draft Roster Reminder",
  CSGO_DAILY_ROSTER_CHANGE_REQUIRED = "CSGO Roster Change Required",
  LOL_DAILY_ROSTER_CHANGE_REQUIRED = "LoL Roster Change Required",
  AUD_DEPOSIT = "AUD Deposit",
  AUD_WITHDRAWAL = "AUD Withdrawal",
  AUD_PROMOTIONS = "AUD Promotions",
  CLOSE_ACCOUNT = "Close Account",
  EWALLET_TRANSACTIONS = "Wallet Transactions",
  CONTEST_CHANGES = "Contest Changes",
  BET_OUTCOMES = "Bet Outcomes",
  CONTEST_OUTCOMES = "Contest Outcomes",
  CONTEST_START = "Contest Start",
  DEPOSIT_LIMITS = "Deposit Limits",
  PROMOTIONS = "Promotions",
}

export type NotificationPreferences = {
  preferencesByType: {
    [key in NotificationPreferenceType]: NotificationPreference;
  };
  emailsUnsubscribed: boolean;
};

export type NotificationPreference = {
  emailAllowed: boolean;
  id: string;
  type: NotificationPreferenceType;
  webAllowed: boolean;
  humanName: string;
};

const getPreferenceName = (
  type: string,
): NotificationPreferenceType | string => {
  return NotificationPreferenceType[type] || type;
};

export const useDefaultNotificationPreferences = (): [
  NotificationPreference[],
  boolean,
  FirestoreError,
] => {
  const defaultNotificationPreferencesRef = collection(
    getFirestore(),
    "defaultNotificationPreferences",
  ) as CollectionReference<NotificationPreference>;
  const [defaultNotificationPreferences, loading, error] = useCollectionData(
    defaultNotificationPreferencesRef,
    defaultNotificationPreferencesRef?.path,
  );

  return [defaultNotificationPreferences, loading, error];
};

export const useUserNotificationPreferences = (): [
  NotificationPreference[],
  boolean,
  FirestoreError,
] => {
  const userId = useSelector((state) => state.auth?.userId);
  const notificationPreferencesRef = userId
    ? (collection(
        getFirestore(),
        "users",
        userId,
        "notificationPreferences",
      ) as CollectionReference<NotificationPreference>)
    : undefined;
  const [notificationPreferences, loading, error] = useCollectionData(
    notificationPreferencesRef,
    notificationPreferencesRef?.path,
  );

  return [notificationPreferences, loading, error];
};

const useNotificationPreferences = (): [NotificationPreferences, boolean] => {
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({
    preferencesByType: {},
    emailsUnsubscribed: false,
  });

  const [defaultPreferences, loadingDefaultPreferences] =
    useDefaultNotificationPreferences();
  const [emailsUnsubscribed, loadingEmailUnsubscribed] = useEmailUnsubscribed();
  const [userPreferences, loadingUserPreferences] =
    useUserNotificationPreferences();

  useEffect(() => {
    if (
      loadingDefaultPreferences ||
      loadingEmailUnsubscribed ||
      loadingUserPreferences
    ) {
      return;
    }

    const preferencesByType = defaultPreferences
      .sort((a, b) => a.type.localeCompare(b.type))
      .reduce((preferencesObject, preference) => {
        const type = preference.type;

        if (type) {
          preferencesObject[type] = {
            id: preference.id,
            emailAllowed: preference.emailAllowed,
            webAllowed: preference.webAllowed,
            humanName: getPreferenceName(type),
          };
        }

        return preferencesObject;
      }, {});

    userPreferences.forEach((preference) => {
      const type = preference.type;
      preferencesByType[type] = {
        ...preferencesByType[type],
        emailAllowed: preference.emailAllowed,
        webAllowed: preference.webAllowed,
      };
    });

    setPreferences({
      preferencesByType,
      emailsUnsubscribed: !!emailsUnsubscribed,
    });

    setLoading(false);
  }, [
    defaultPreferences,
    loadingDefaultPreferences,
    emailsUnsubscribed,
    loadingEmailUnsubscribed,
    userPreferences,
    loadingUserPreferences,
  ]);

  return [preferences as NotificationPreferences, loading];
};

export default useNotificationPreferences;
