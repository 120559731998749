import React, { useEffect } from "react";
import { List, ListItem } from "components/List";
import { getCardType } from "react-credit-cards-2/lib/utils/cardHelpers";
import { ReactComponent as PayIdIcon } from "sections/Wallet/Tabs/assets/payid.svg";
import { ReactComponent as ApplePayIcon } from "sections/Wallet/Tabs/assets/apple-pay.svg";
import { ReactComponent as GooglePayIcon } from "sections/Wallet/Tabs/assets/gpay-white.svg";
import { ReactComponent as MasterCardIcon } from "sections/Wallet/Tabs/assets/mastercard.svg";
import { ReactComponent as VisaIcon } from "sections/Wallet/Tabs/assets/visa.svg";
import { QuickDepositOption } from "./types";

import * as styles from "./QuickDeposit.module.scss";

type DepositOptionProps = {
  icon: React.ReactNode;
  isActive?: boolean;
  hideRadio?: boolean;
};

type OptionsProps = {
  options: QuickDepositOption[];
  cardNumberFirst: string;
  cardNumberLast: string;
  selectedOption: QuickDepositOption;
  setSelectedOption: (option: QuickDepositOption) => void;
};

const DepositOptionIcon = ({
  icon,
  isActive,
  hideRadio = false,
}: DepositOptionProps) => {
  return (
    <div className={styles.optionIcon}>
      {!hideRadio && <div data-active={isActive} className={styles.radio} />}
      {icon}
    </div>
  );
};

const Options = ({
  options,
  cardNumberFirst,
  cardNumberLast,
  setSelectedOption,
  selectedOption,
}: OptionsProps) => {
  useEffect(() => {
    // enable first option by default once available
    if (options.length > 1 && !selectedOption) {
      setSelectedOption(options[0]);
    }
  }, [options]);

  return (
    <List>
      {options.map((option) => {
        switch (option) {
          case QuickDepositOption.APPLEPAY:
            return (
              <ListItem
                key={option}
                onClick={() => setSelectedOption(option)}
                className={styles.option}
                label={
                  <DepositOptionIcon
                    isActive={selectedOption === option}
                    icon={<ApplePayIcon />}
                  />
                }
              >
                Apple Pay
              </ListItem>
            );
          case QuickDepositOption.GOOGLEPAY:
            return (
              <ListItem
                key={option}
                onClick={() => setSelectedOption(option)}
                className={styles.option}
                label={
                  <DepositOptionIcon
                    isActive={selectedOption === option}
                    icon={<GooglePayIcon />}
                  />
                }
              >
                Google Pay
              </ListItem>
            );
          case QuickDepositOption.CREDITDEBIT: {
            const cardType = getCardType(cardNumberFirst);
            const icon =
              cardType === "visa" ? <VisaIcon /> : <MasterCardIcon />;
            return (
              <ListItem
                key={option}
                onClick={() => setSelectedOption(option)}
                className={styles.option}
                label={
                  <DepositOptionIcon
                    isActive={selectedOption === option}
                    icon={icon}
                  />
                }
              >
                **** {cardNumberLast}
              </ListItem>
            );
          }
          case QuickDepositOption.OTHER:
            return (
              <ListItem
                key={option}
                to={`/wallet/deposit`}
                className={styles.option}
                label={
                  <DepositOptionIcon
                    isActive={selectedOption === option}
                    icon={<PayIdIcon />}
                    hideRadio
                  />
                }
              >
                Other
              </ListItem>
            );
        }
      })}
    </List>
  );
};

export { Options };
