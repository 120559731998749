import React, { useContext } from "react";
import * as styles from "./RaceInfo.module.scss";
import { RaceContext } from "sections/Betting/Race";
import RaceInfoSkeleton from "sections/Betting/Race/components/RaceInfo/RaceInfoSkeleton";
import { toTitleCaseWithExclusions } from "utilities/racingUtilities";
import { EventStatus, type StatusEnum } from "components/layout/EventStatus";

export default () => {
  const { races, route } = useContext(RaceContext);
  const race = races?.find((r) => r.id === route?.raceId);

  if (!race) {
    return <RaceInfoSkeleton />;
  }

  return (
    <div className={styles.raceInfo} title={race?.name}>
      <div className={styles.eventName}>
        {toTitleCaseWithExclusions(race?.name)}
      </div>
      <div className={styles.details}>
        {race?.distance}
        <EventStatus
          shape="rounded"
          // TODO: convert to a common enum, currently used in multiple places and not a part of betslip refactor
          status={race?.status as unknown as StatusEnum}
          scheduledStartTime={race?.scheduledStartTime}
        />
      </div>
    </div>
  );
};
