import React from "react";
import cx from "classnames";
import * as styles from "./HUDPanelNotification.module.scss";

import { Icon, ButtonWithState as Button } from "library";
import { useTranslation } from "react-i18next";

export const HUDPanelNotification: React.FC<{
  type: "default" | "success" | "info" | "warning" | "secondary" | "error";
  text: string | JSX.Element;
  action?: () => void;
  actionText?: string | JSX.Element;
  actionDisabled?: boolean;
  icon?: string;
  iconClass?: string;
  isLoading?: boolean;
  className?: string;
  id?: string;
}> = ({
  type,
  text,
  action,
  actionText = "Got It",
  actionDisabled = false,
  icon,
  iconClass,
  isLoading,
  className,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx({
        notification: true,
        [styles.notification]: true,
        [styles[type]]: !!styles[type],
        [className]: !!className,
      })}
      id={id}
    >
      <div className="notification-inner">
        {icon ? (
          <div className={"notification-icon " + styles.notificationIcon}>
            <Icon
              className={cx(styles.notificationIcon, iconClass)}
              type={icon}
            />
          </div>
        ) : null}
        <div className={"notification-text " + styles.notificationText}>
          {typeof text === "string" ? t(text) : text}
        </div>
        {action ? (
          <div className={"notification-action " + styles.notificationCta}>
            <Button
              disabled={actionDisabled}
              loading={isLoading}
              onClick={action}
              buttonSize="small"
              type="secondary"
              className={styles.button}
            >
              {actionText}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
