import { useState, useEffect, useContext } from "react";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import { useCollectionData } from "hooks";
import {
  collection,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import { FirebaseContext } from "../../../context/Firebase";
import { constructCurrency } from "../../../common";

type Accounts = {
  [key: string]: {
    available: {
      balanceInCents: number;
    };
    holding: {
      balanceInCents: number;
    };
    promo: {
      balanceInCents: number;
    };
  };
};

type MoneyAvailable = {
  availableAsNumber: number;
  available: string;
  promoAsNumber: number;
  promo: string;
  isLoading: boolean;
};

const converter: FirestoreDataConverter<any> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    return {
      ...snapshot.data(options),
      currency: snapshot.id,
    };
  },
};

const useAccountBalances = (): [Accounts | undefined, boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const [accounts, setAccounts] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const ref = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "accountBalances",
      ).withConverter(converter)
    : null;

  const [rawBalances, rawLoading] = useCollectionData(ref, ref?.path);

  useEffect(() => {
    if (rawLoading || !rawBalances) {
      return;
    }

    setAccounts(
      rawBalances.reduce((result, account) => {
        result[account.currency.toLowerCase()] = {
          available: {
            balanceInCents: account.AVAILABLE,
          },
          promo: {
            balanceInCents: account.PROMOTION_USER,
          },
          holding: {
            balanceInCents: account.HOLDING,
          },
        };

        return result;
      }, {}),
    );

    setLoading(false);
  }, [rawBalances, rawLoading]);

  return [accounts, loading];
};

export const useAvailable = () => {
  const [accounts, isLoading] = useAccountBalances();
  const { profile } = useContext(FirebaseContext);

  const currency = profile?.fiatCurrency || "AUD";

  const account =
    accounts && currency
      ? accounts[currency.toLowerCase()]
      : { available: { balanceInCents: 0 }, promo: { balanceInCents: 0 } };

  const available = account?.available?.balanceInCents ?? 0;
  const promo = account?.promo?.balanceInCents ?? 0;

  return {
    currency,
    available,
    promo,
    isLoading,
  };
};

export const useCashAvailable = () => {
  const { currency, available, promo, isLoading } = useAvailable();

  return {
    available: constructCurrency(available, {
      currencyType: currency,
      showDecimals: true,
      showCurrencySymbol: true,
    }),
    promo: constructCurrency(promo, {
      currencyType: currency,
      showDecimals: true,
      showCurrencySymbol: true,
    }),
    isLoading,
  };
};

export default useAccountBalances;
