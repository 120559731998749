import { ConfirmDialog } from "components/Dialog";
import React from "react";
import { ReactComponent as LogoutIcon } from "components/assets/logout.svg";
import { useDispatch, useSelector } from "hooks";
import { setModal } from "utilities/UI/uiSlice";
import { useLocation } from "hooks/useLocation";

export const OpenInAppDialog = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.utilities.ui.modal);
  const { href } = useLocation();

  return (
    <ConfirmDialog
      icon={<LogoutIcon />}
      title={`Open in App`}
      description={`Would you like to view this content in the app?`}
      onClose={() => dispatch(setModal(null))}
      isOpen={modal === "openApp"}
      onConfirm={async () => window.open(href, "_blank")}
    />
  );
};
