// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "BetPick-module--caret--8eacb";
export var clamp = "BetPick-module--clamp--5e0dc";
export var clickable = "BetPick-module--clickable--5ebc5";
export var drawer = "BetPick-module--drawer--4b478";
export var emphasis = "BetPick-module--emphasis--a6322";
export var exoticOutcomes = "BetPick-module--exoticOutcomes--4af04";
export var green = "#57d33b";
export var greenLink = "BetPick-module--green-link--0f541";
export var grey = "var(--tone-mid)";
export var icon = "BetPick-module--icon--c4d2c";
export var iconAndDescription = "BetPick-module--iconAndDescription--0b9f7";
export var info = "BetPick-module--info--5bda4";
export var isCombo = "BetPick-module--isCombo--29a9e";
export var isFirst = "BetPick-module--isFirst--68527";
export var isLast = "BetPick-module--isLast--b3ad5";
export var isVoid = "BetPick-module--isVoid--cb660";
export var isWon = "BetPick-module--isWon--b92f4";
export var marketName = "BetPick-module--marketName--fb708";
export var multiOutcomeOddsContainer = "BetPick-module--multiOutcomeOddsContainer--64186";
export var noMouseEvents = "BetPick-module--noMouseEvents--843f6";
export var notchGap = "32px";
export var odds = "BetPick-module--odds--f7ac9";
export var oddsAndIcon = "BetPick-module--oddsAndIcon--609ac";
export var orange = "#f8662b";
export var outcomeIcon = "BetPick-module--outcomeIcon--2ec3c";
export var outcomeName = "BetPick-module--outcomeName--344fc";
export var pick = "BetPick-module--pick--d44ea";
export var pickIcon = "BetPick-module--pickIcon--490d7";
export var red = "#ff2741";
export var teamNames = "BetPick-module--teamNames--e0435";
export var vs = "BetPick-module--vs--a2832";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";