// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "ExpandingContent-module--caret--1c918";
export var content = "ExpandingContent-module--content--52381";
export var emphasis = "ExpandingContent-module--emphasis--0639e";
export var green = "#57d33b";
export var greenLink = "ExpandingContent-module--green-link--4150b";
export var grey = "var(--tone-mid)";
export var group = "ExpandingContent-module--group--bcc34";
export var heading = "ExpandingContent-module--heading--19cf4";
export var icon = "ExpandingContent-module--icon--c301a";
export var info = "ExpandingContent-module--info--357f8";
export var notchGap = "32px";
export var open = "ExpandingContent-module--open--e499b";
export var orange = "#f8662b";
export var red = "#ff2741";
export var resetStyles = "ExpandingContent-module--resetStyles--e322e";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";