// extracted by mini-css-extract-plugin
export var base = "SACodeOfPractice-module--base--3557a";
export var blue = "#0eb3f2";
export var emphasis = "SACodeOfPractice-module--emphasis--8003b";
export var green = "#57d33b";
export var greenLink = "SACodeOfPractice-module--green-link--53332";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";