import {
  collection,
  type CollectionReference,
  type DocumentData,
  type FirestoreDataConverter,
  query,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  where,
} from "firebase/firestore";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import { convertPickemEntry } from "../betting/useEntries";
import type { PickemsEntry } from "./types";

const converter: FirestoreDataConverter<PickemsEntry> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    const entry = snapshot.data(options);

    return convertPickemEntry(entry, snapshot.id);
  },
};

export const useAllPickemsEntries = (): [PickemsEntry[], boolean] => {
  const userId = useSelector((state) => state.auth?.userId);
  const entriesRef = userId
    ? (collection(getFirestore(), "users", userId, "entries").withConverter(
        converter,
      ) as CollectionReference<PickemsEntry>)
    : undefined;
  const [entries, loading] = useCollectionData(
    query(entriesRef, where("entryType", "==", "PICKEM")),
    entriesRef?.path,
  );

  return [entries, loading];
};

export const useLivePickemsEntries = (): [PickemsEntry[], boolean] => {
  const userId = useSelector((state) => state.auth?.userId);
  const entriesRef = userId
    ? (collection(getFirestore(), "users", userId, "entries").withConverter(
        converter,
      ) as CollectionReference<PickemsEntry>)
    : undefined;
  const queryWithUserId = userId
    ? query(
        entriesRef,
        where("entryType", "==", "PICKEM"),
        where("status", "in", ["live", "upcoming"]),
      )
    : undefined;
  const [entries, loading] = useCollectionData(
    queryWithUserId,
    entriesRef?.path,
  );

  return [entries ?? [], loading];
};
