import type { IMarket, IMatchEvent } from "types/BetTypes";
import type { PickemsStatus } from "types/PickemsTypes";
import { FireTransforms } from "utilities/FireTransforms";
import type { PickemsContest } from "./types";
import cloneDeep from "lodash/cloneDeep";

export const getBetStatus = (marketStatus: PickemsStatus) => {
  const status = (marketStatus || "").toUpperCase() as string;
  switch (status) {
    case "DEACTIVATED":
      return "LIVE";
    case "SETTLED":
    case "CANCELLED":
      return "RESULTED";
    case "ACTIVE":
      // this will be shown if a market has passed its scheduled start time but has not been deactivated yet
      return "WAITING";
    default:
      return status;
  }
};

export const transformEvent = (
  eventData: any,
  markets: IMarket[],
): IMatchEvent => {
  if (!eventData) {
    return null;
  }

  const competitors = {};
  markets.forEach((market) => {
    if (!Object.keys(market.outcomes ?? {}).length) {
      return;
    }

    Object.keys(market.outcomes ?? {}).forEach((outcomeId) => {
      const outcome = market.outcomes[outcomeId];
      outcome.odds = outcome.points;
      competitors[outcome.competitorId] = outcome.competitor;
    });
  });

  const event = {
    ...eventData,
    competitors,
    scheduledStartTime: FireTransforms.parseDate(eventData.scheduledStartTime),
  };

  return event;
};

export const transformMarkets = (marketsToTransform: IMarket[]): IMarket[] => {
  if (!marketsToTransform) return [];

  // we make a deep clone of the markets array as we need to update read-only values later on
  const markets = cloneDeep(marketsToTransform);

  markets?.map((market) => {
    market.event = transformEvent(market.event, markets);
    market.betStatus = getBetStatus(market.status as PickemsStatus);
  });

  markets.sort((a, b) =>
    a?.event?.scheduledStartTime < b?.event?.scheduledStartTime ? -1 : 1,
  );

  return markets;
};

export const transformContest = (
  contest: PickemsContest,
  markets: IMarket[],
): PickemsContest => {
  if (!contest) {
    return null;
  }

  const transformedMarkets = transformMarkets(markets);

  return {
    ...contest,
    scheduledStartTime: FireTransforms.parseDate(contest.scheduledStartTime),
    contestNumber: Number(contest.contestNumber),
    markets: transformedMarkets,
  };
};
