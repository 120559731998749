import { useSelector } from "react-redux";
import * as pickslipSlice from "sections/Pickems/PickSlip/pickslipSlice";
import { togglePick as togglePickThunk } from "sections/Pickems/PickSlip/pickslipSlice";
import { useActions } from "hooks";

export const usePickems = () => {
  const contestId = useSelector(pickslipSlice.selectContestId);
  const pickslipError = useSelector(pickslipSlice.selectPickslipError);
  const pickAcceptedId = useSelector(pickslipSlice.selectPickAcceptedId);
  const confirmContestId = useSelector(pickslipSlice.selectConfirmContestId);
  const contestFilters = useSelector(pickslipSlice.selectContestFilters);
  const isPickslipLoading = useSelector(pickslipSlice.selectPickslipLoading);
  const selectedOutcomes = useSelector(pickslipSlice.selectActiveOutcomes);

  const [
    acceptPick,
    clearPicks,
    removePick,
    submitPicks,
    setSelectedContestFilters,
    updatePickslip,
    createAnalyticsData,
    togglePick,
  ] = useActions([
    pickslipSlice.acceptPick,
    pickslipSlice.clearPicks,
    pickslipSlice.removePick,
    pickslipSlice.submitPicks,
    pickslipSlice.setSelectedContestFilters,
    pickslipSlice.updatePickslip,
    pickslipSlice.createAnalyticsData,
    togglePickThunk,
  ]);

  return {
    props: {
      contestId,
      contestFiltersSelected: contestFilters.contestFiltersSelected,
      contestFilters: contestFilters.contestFilters,
      pickAcceptedId,
      confirmContestId,
      error: pickslipError,
      isLoading: isPickslipLoading,
      selectedOutcomes,
    },
    actions: {
      acceptPick,
      clearPicks,
      createAnalyticsData,
      removePick,
      submitPicks,
      setSelectedContestFilters,
      togglePick,
      updatePickslip,
    },
  };
};

export default usePickems;
