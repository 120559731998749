// extracted by mini-css-extract-plugin
export var alert = "Alert-module--alert--dd5a2";
export var blue = "#0eb3f2";
export var clamp = "Alert-module--clamp--c25b7";
export var content = "Alert-module--content--1798c";
export var emphasis = "Alert-module--emphasis--e8dd8";
export var green = "#57d33b";
export var greenLink = "Alert-module--green-link--c1f0a";
export var grey = "var(--tone-mid)";
export var hasClamp = "Alert-module--hasClamp--45f0a";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var text = "Alert-module--text--f452c";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";