// extracted by mini-css-extract-plugin
export var active = "RaceMeetings-module--active--b2265";
export var blue = "#0eb3f2";
export var dateSelect = "RaceMeetings-module--dateSelect--1bf2b";
export var emphasis = "RaceMeetings-module--emphasis--27073";
export var filters = "RaceMeetings-module--filters--9c54e";
export var green = "#57d33b";
export var greenLink = "RaceMeetings-module--green-link--56e61";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var page = "RaceMeetings-module--page--f4ebc";
export var raceMeetings = "RaceMeetings-module--raceMeetings--4d106";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";