// extracted by mini-css-extract-plugin
export var active = "RaceOutcome-module--active--2a893";
export var arrowDown = "RaceOutcome-module--arrowDown--f3593";
export var arrowUp = "RaceOutcome-module--arrowUp--1fef5";
export var blue = "#0eb3f2";
export var disabled = "RaceOutcome-module--disabled--57422";
export var disabledOdds = "RaceOutcome-module--disabledOdds--defca";
export var emphasis = "RaceOutcome-module--emphasis--56cc8";
export var green = "#57d33b";
export var greenLink = "RaceOutcome-module--green-link--8b5e8";
export var grey = "var(--tone-mid)";
export var label = "RaceOutcome-module--label--e3e98";
export var notchGap = "32px";
export var odds = "RaceOutcome-module--odds--5c7e8";
export var oddsText = "RaceOutcome-module--oddsText--17a50";
export var orange = "#f8662b";
export var outcome = "RaceOutcome-module--outcome--90def";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";