import cx from "classnames";
import { useBetslip } from "hooks";
import React from "react";
import { BonusBadge } from "./BonusBadge";
import { CardFooter } from "./CardFooter";
import { Stake } from "./Stake";
import type { SummaryProps } from "./Summary";
import { rounded, safeExoticStake } from "utilities/sharedBettingUtilities";

import * as styles from "./Summary.module.scss";

export const ExoticBetCardSummary = ({
  exoticCombinations = 1,
  stake,
  onSetStake,
  isPromo,
  isActive,
}: SummaryProps) => {
  const {
    props: { betslipIsReviewingMessages },
  } = useBetslip();

  const syncExoticStakePerCombo = (stake: number) => {
    onSetStake(safeExoticStake(stake, exoticCombinations));
  };

  return (
    <CardFooter.Container>
      <CardFooter.Section
        className={cx({
          [styles.isPromo]: isPromo,
          [styles.stake]: !stake && !betslipIsReviewingMessages && isActive,
        })}
        label="Total Stake"
      >
        <Stake
          isActive={isActive}
          amount={stake}
          isPromo={isPromo}
          onChange={(stake) => onSetStake(rounded(stake))}
          onBlur={() => syncExoticStakePerCombo(stake)}
        />
        {isPromo && <BonusBadge />}
      </CardFooter.Section>
      <CardFooter.Section label="Stake/Combo">
        <Stake
          isActive={isActive}
          onChange={(stake) => onSetStake(rounded(stake * exoticCombinations))}
          onBlur={() => syncExoticStakePerCombo(stake)}
          amount={stake / exoticCombinations}
          isPromo={isPromo}
          quickInputPosition={"middle"}
        />
      </CardFooter.Section>
      <CardFooter.Section label="Payout">TBD</CardFooter.Section>
    </CardFooter.Container>
  );
};
