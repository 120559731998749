import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import React from "react";
import cx from "classnames";
import * as styles from "./HeadingClamp.module.scss";

const variants = cva(styles.headingClamp, {
  variants: {
    direction: {
      left: styles.left,
      right: styles.right,
    },
  },
  defaultVariants: {
    direction: "left",
  },
});

export type HeadingClampProps = VariantProps<typeof variants> & {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
  asChild?: boolean;
};

export default ({
  children,
  direction,
  onClick,
  className,
  asChild = false,
}: HeadingClampProps) => {
  const Component = asChild ? Slot : "div";

  return (
    <Component
      onClick={onClick}
      className={cx(variants({ direction }), className)}
    >
      {children}
    </Component>
  );
};
