// extracted by mini-css-extract-plugin
export var aux = "Title-module--aux--0136b";
export var blue = "#0eb3f2";
export var emphasis = "Title-module--emphasis--40d4b";
export var green = "#57d33b";
export var greenLink = "Title-module--green-link--d0a02";
export var grey = "var(--tone-mid)";
export var icon = "Title-module--icon--ab640";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var subtitle = "Title-module--subtitle--ddf65";
export var text = "Title-module--text--47acf";
export var title = "Title-module--title--02d2e";
export var titleContainer = "Title-module--titleContainer--cef2a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";