import cx from "classnames";
import { noop } from "lodash";
import React, {
  type PropsWithChildren,
  type ButtonHTMLAttributes,
} from "react";
import * as styles from "./RunnerPositionToggle.module.scss";
import { Checkbox } from "components/Checkbox";
import type { CheckboxProps } from "components/Checkbox/Checkbox";

export type RunnerPositionToggleGroupProps = PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;

  /** refers to the parent level switch being activated */
  isBoxed?: boolean;

  /** refers to the physical toggle being active */
  isBoxedActive?: boolean;
  onBoxedChange?: (isActive: boolean) => void;
}>;

export type RunnerPositionToggleProps = PropsWithChildren<{
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}> &
  CheckboxProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled" | "aria-disabled">;

export const RunnerPositionToggle = ({
  onClick = noop,
  isActive = false,
  isDisabled = false,
  children,
  ...props
}: RunnerPositionToggleProps) => {
  return (
    <Checkbox
      {...props}
      noSpacing
      checked={isActive}
      disabled={isDisabled}
      onCheckedChange={onClick}
      variant="secondary"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Checkbox>
  );
};

export const RunnerPositionToggleGroup = ({
  className,
  children,
  isBoxed,
  isBoxedActive,
  onBoxedChange,
  isDisabled,
}: RunnerPositionToggleGroupProps) => {
  return (
    <div className={cx(styles.toggleGroup, className)}>
      {isBoxed ? (
        <RunnerPositionToggle
          isDisabled={isDisabled}
          isActive={isBoxedActive}
          onClick={() => onBoxedChange(!isBoxedActive)}
        />
      ) : (
        children
      )}
    </div>
  );
};
