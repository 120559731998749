// extracted by mini-css-extract-plugin
export var actions = "Message-module--actions--22381";
export var blue = "Message-module--blue--1ecdd";
export var container = "Message-module--container--848ae";
export var emphasis = "Message-module--emphasis--4c1aa";
export var gameController = "Message-module--gameController--0632a";
export var green = "#57d33b";
export var greenLink = "Message-module--green-link--e1eef";
export var grey = "var(--tone-mid)";
export var hasSubtitle = "Message-module--hasSubtitle--59292";
export var heading = "Message-module--heading--dd4ee";
export var icon = "Message-module--icon--940a3";
export var light = "Message-module--light--9e063";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "Message-module--red--aba75";
export var subtitle = "Message-module--subtitle--a14d2";
export var title = "Message-module--title--0e785";
export var verticalMargin = "Message-module--verticalMargin--478d7";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var wrapper = "Message-module--wrapper--203f4";
export var yellow = "Message-module--yellow--5decd";