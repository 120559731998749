// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var competitor = "CompetitorInfo-module--competitor--c786d";
export var competitorInfoContainer = "CompetitorInfo-module--competitorInfoContainer--bfc8c";
export var container = "CompetitorInfo-module--container--9e84b";
export var emphasis = "CompetitorInfo-module--emphasis--d06c4";
export var green = "#57d33b";
export var greenLink = "CompetitorInfo-module--green-link--73ac9";
export var grey = "var(--tone-mid)";
export var last6runs = "CompetitorInfo-module--last6runs--a5d8e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scratched = "CompetitorInfo-module--scratched--7f463";
export var secondaryInformation = "CompetitorInfo-module--secondaryInformation--5dff7";
export var silkContainer = "CompetitorInfo-module--silkContainer--b8c22";
export var silkNumber = "CompetitorInfo-module--silkNumber--fdc04";
export var startingPosition = "CompetitorInfo-module--startingPosition--71b7a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";