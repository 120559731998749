// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "StakeAll-module--container--b85f5";
export var emphasis = "StakeAll-module--emphasis--7904d";
export var green = "#57d33b";
export var greenLink = "StakeAll-module--green-link--1b236";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var quickInput = "StakeAll-module--quickInput--6a7d7";
export var quickInputWrapper = "StakeAll-module--quickInputWrapper--4117e";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var wrapper = "StakeAll-module--wrapper--790e0";
export var yellow = "#ffb100";