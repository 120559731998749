// extracted by mini-css-extract-plugin
export var baseMediaHolder = "HUDMediaWindow-module--baseMediaHolder--52c10";
export var blue = "#0eb3f2";
export var closeTop = "HUDMediaWindow-module--closeTop--0653f";
export var customMediaControls = "HUDMediaWindow-module--customMediaControls--c5cbb";
export var emphasis = "HUDMediaWindow-module--emphasis--12292";
export var green = "#57d33b";
export var greenLink = "HUDMediaWindow-module--green-link--2ecdc";
export var grey = "var(--tone-mid)";
export var icon = "HUDMediaWindow-module--icon--b5ad1";
export var inside = "HUDMediaWindow-module--inside--9c769";
export var mobileMediaWindow = "HUDMediaWindow-module--mobileMediaWindow--17716";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";