// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var button = "Empty-module--button--1d39f";
export var emphasis = "Empty-module--emphasis--a9e3b";
export var empty = "Empty-module--empty--2e171";
export var green = "#57d33b";
export var greenLink = "Empty-module--green-link--00e10";
export var grey = "var(--tone-mid)";
export var icon = "Empty-module--icon--4c196";
export var message = "Empty-module--message--34bd0";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";