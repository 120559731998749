import React from "react";
import { useBetslip, useDispatch } from "hooks";
import { OutcomeIcon } from "components/layout/OutcomeIcon";
import { Clamp } from "components/Clamp";
import { useTitle } from "hooks/graphql/useTitles";
import cx from "classnames";
import { ReactComponent as CloseIcon } from "components/assets/close.svg";
import { useSelection } from "sections/Betting/Betslip/hooks/useSelection";
import { OutcomeAlert } from "../OutcomeAlert";
import { OddsWithChanges } from "../OddsWithChanges";
import { StatusWithStream } from "../StatusWithStream";
import { TitleIcon } from "components/TitleIcon";

import * as styles from "./shared.module.scss";
import { getCurrentOdds } from "utilities/sharedBettingUtilities";

type SingleOutcomeProps = {
  outcomeId: string;
  isLast: boolean;
};

const SingleOutcome = ({ outcomeId, isLast }: SingleOutcomeProps) => {
  const {
    props: { betType, betslipIsSubmitting, multiAccepted },
    actions: { removeSelection },
  } = useBetslip();
  const selection = useSelection(outcomeId);
  const isMulti = betType === "MULTI";
  const currentOdds = getCurrentOdds(selection);
  const title = useTitle(selection?.sport?.toLowerCase());
  const dispatch = useDispatch();

  if (!selection) return null;

  const showOutcomeAlert = isMulti && !multiAccepted;
  return (
    <div
      className={cx(styles.pick, {
        [styles.isLast]: isLast,
      })}
    >
      {showOutcomeAlert && <OutcomeAlert outcomeId={outcomeId} />}
      <div className={styles.header}>
        <div className={styles.icon}>
          <TitleIcon title={title} svg />
        </div>
        <div className={styles.title}>
          <div className={styles.selectionName}>
            <OutcomeIcon
              className={styles.outcomeIcon}
              outcome={selection?.outcome as any}
              silksUrl={selection?.outcome?.competitor?.silksUrl}
            />
            {selection?.selectionName}
          </div>
          <div className={styles.marketName}>
            {selection?.market}
            {isMulti && selection.isSP ? ` @ Starting Price` : null}
            {isMulti && !selection.isSP ? (
              <>
                {" "}
                <OddsWithChanges
                  prefix={`@`}
                  odds={currentOdds}
                  animationLength={selection?.changes?.newOdds ? null : 3000}
                />
              </>
            ) : null}
          </div>
          <div className={styles.eventName}>{selection?.eventName}</div>
        </div>
        <div className={styles.clamp}>
          <Clamp
            variant={`grey`}
            onClick={() => dispatch(removeSelection(selection?.outcomeId))}
            className={cx(styles.close, {
              [styles.hidden]: betslipIsSubmitting,
            })}
          >
            <CloseIcon />
          </Clamp>

          <StatusWithStream
            status={selection?.eventStatus}
            scheduledStartTime={selection?.startTime}
            title={selection.eventName}
            url={selection?.streamUri}
            eventId={selection?.eventId}
            isPlaceholder={false}
          />
        </div>
      </div>
    </div>
  );
};

export { SingleOutcome };
