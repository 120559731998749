import React from "react";
import { StreamButton } from "components/layout/StreamButton";
import { EventStatus } from "components/layout/EventStatus";
import type { EventStatusProps } from "components/layout/EventStatus";
import type { StreamButtonProps } from "components/layout/StreamButton";
import type { StreamType } from "types/ContentTypes";
import * as styles from "./StatusWithStream.module.scss";
import { useCountdown } from "hooks/useCountdown";

type StatusWithStreamProps = EventStatusProps &
  Omit<StreamButtonProps<StreamType>, "startTime" | "status">;

function StatusWithStream({
  title,
  url,
  sport,
  scheduledStartTime,
  status,
  eventId,
  isPlaceholder,
}: StatusWithStreamProps) {
  const [_, secondsToNow] = useCountdown(scheduledStartTime);
  const earlyBirdStream = secondsToNow <= 30 * 60; // 30 mins
  return (
    <div className={styles.container}>
      {url && earlyBirdStream && (
        <StreamButton
          title={title}
          sport={sport}
          url={url}
          startTime={scheduledStartTime}
          status={status}
          eventId={eventId}
          isPlaceholder={isPlaceholder}
        />
      )}
      <EventStatus status={status} scheduledStartTime={scheduledStartTime} />
    </div>
  );
}

export { StatusWithStream };
