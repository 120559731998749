import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { getPixelValue } from "utilities/display";
import cx from "classnames";

import { Icon } from "library";
import * as styles from "./MultiModal.module.scss";

const PORTAL_ROOT =
  typeof document !== `undefined`
    ? document.getElementById("portal")
    : (null as HTMLElement);

const DEFAULT_HEIGHT = 480;

const DEFAULT_PRIVATE_STATE = {
  isDragging: false,
  isResizing: false,
  rel: {
    x: 0,
    y: 0,
  },
  notification: "",
  zIndex: 1000,
};

const totalAdditionalWindowHeight = getPixelValue(
  styles.totalAdditionalWindowHeight,
);
const modalPadding = getPixelValue(styles.totalAdditionalWindowHeight);

interface ratio {
  width: number;
  height: number;
}

// For Desktop top use only. Supports Resize, Drag-Move
const MultiModal = ({
  children,
  width = 852 + modalPadding,
  height = DEFAULT_HEIGHT,
  x = window.innerWidth / 2 - width / 2,
  y = window.innerHeight / 2 - height / 2,
  title,
  maxSize = 80,
  minWidth = 320,
  minHeight = 180,
  ratio,
  onClose,
}: {
  // See Defaults Above
  children: React.ReactNode;
  title: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  maxSize?: number;
  minWidth?: number;
  minHeight?: number;
  ratio?: ratio;
  onClose: () => void;
}) => {
  const el = useRef(document.createElement("div"));
  const refModal = useRef(null);

  const getRatioBasedHeight = (width: number) => {
    return (width / ratio.width) * ratio.height;
  };

  const getEventXandY = (e: any): { x: number; y: number } => {
    let x = 0;
    let y = 0;
    if (
      e.type == "touchstart" ||
      e.type == "touchmove" ||
      e.type == "touchend" ||
      e.type == "touchcancel"
    ) {
      const touch = e.touches[0] || e.changedTouches[0];
      x = touch.pageX;
      y = touch.pageY;
    } else if (
      e.type == "mousedown" ||
      e.type == "mouseup" ||
      e.type == "mousemove" ||
      e.type == "mouseover" ||
      e.type == "mouseout" ||
      e.type == "mouseenter" ||
      e.type == "mouseleave"
    ) {
      x = e.clientX;
      y = e.clientY;
    }

    return { x, y };
  };
  // State
  const [state, setState] = useState({
    ...DEFAULT_PRIVATE_STATE,
    width,
    height:
      (ratio ? getRatioBasedHeight(width) : height) +
      totalAdditionalWindowHeight,
    x,
    y,
    title,
    maxSize,
    minWidth,
    minHeight,
  });

  const getZIndex = (element: any) => {
    const z = window.document.defaultView
      .getComputedStyle(element)
      .getPropertyValue("z-index");
    if (z === "auto") return getZIndex(element.parentNode);
    return z;
  };

  const findHighestZIndex = (): number => {
    const elems = document.querySelectorAll("[class*=MultiModal]");
    let highest = 0;
    for (let i = 0; i < elems.length; i++) {
      const zindex = getZIndex(elems[i]);
      if (zindex > highest) {
        highest = zindex;
      }
    }

    return highest * 1 + 1;
  };

  // Methods

  const setIsResizing = () => {
    if (!(state.isResizing && state.isDragging))
      setState({ ...state, isResizing: true, zIndex: findHighestZIndex() });
  };

  const setIsDragging = (e: any) => {
    if (!(state.isResizing && state.isDragging)) {
      const { x, y } = getEventXandY(e);

      setState({
        ...state,
        isDragging: true,
        rel: {
          x: x - refModal.current.offsetLeft,
          y: y - refModal.current.offsetTop,
        },
        zIndex: findHighestZIndex(),
      });
    }
  };

  const onUp = () => {
    setState({
      ...state,
      isResizing: false,
      isDragging: false,
    });
  };

  const isLessThanMaxSize = (width: number, height: number) => {
    const windowSize = window.innerWidth * window.innerHeight;
    const modalSize = width * height;

    return (modalSize / windowSize) * 100 < maxSize;
  };

  const canMoveX = (newX: number) => {
    if (newX < 0) return false;
    if (newX + state.width > window.innerWidth) return false;
    return true;
  };

  const canMoveY = (newY: number) => {
    if (newY < 0) return false;
    if (newY + state.height > window.innerHeight) return false;
    return true;
  };

  const onMove = (e: any) => {
    const { x, y } = getEventXandY(e);

    if (state.isDragging) {
      setState({
        ...state,
        x: canMoveX(x - state.rel.x) ? x - state.rel.x : state.x,
        y: canMoveY(y - state.rel.y) ? y - state.rel.y : state.y,
        notification: "",
      });

      e.stopPropagation();
      e.preventDefault();
    } else if (state.isResizing) {
      const width = x - refModal.current.offsetLeft;
      const height = y - refModal.current.offsetTop;
      const ratioHeight = getRatioBasedHeight(width);

      if (isLessThanMaxSize(width, height)) {
        if (width > state.minWidth && height >= state.minHeight) {
          if (ratio) {
            setState({
              ...state,
              width: width,
              height: ratioHeight + totalAdditionalWindowHeight,
              notification: "",
            });
          } else {
            setState({
              ...state,
              width: width,
              height: height,
              notification: "",
            });
          }
          e.stopPropagation();
          e.preventDefault();
        } else {
          setState({
            ...state,
            width: state.width + 20,
            height: state.height + 20,
            notification: "Min modal screen size reached!",
            isResizing: false,
            isDragging: false,
          });
        }
      } else {
        setState({
          ...state,
          notification: "Max modal screen size reached!",
          isResizing: false,
          isDragging: false,
        });
      }
    }
  };

  // Add Event Listener
  useEffect(() => {
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);

    document.addEventListener("touchmove", onMove, { passive: false });
    document.addEventListener("touchend", onUp, { passive: false });

    return () => {
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);

      document.removeEventListener("touchmove", onMove);
      document.removeEventListener("touchend", onUp);
    };
  });

  // Add Modal to Portal Dom
  useEffect(() => {
    const current = el.current;
    PORTAL_ROOT?.appendChild(current);

    return () => {
      PORTAL_ROOT?.removeChild(current);
    };
  }, []);

  const ModalFrame = (): React.ReactNode => {
    return (
      <div
        style={{
          width: `${state.width}px`,
          height: `${state.height}px`,
          left: `${state.x}px`,
          top: `${state.y}px`,
          zIndex: state.zIndex,
        }}
        className={styles.multiModal}
        ref={refModal}
      >
        <div
          className={styles.title}
          title={title}
          onMouseDown={setIsDragging}
          onTouchStart={setIsDragging}
        >
          <div className={styles.titleText}>{title}</div>
          <div className={styles.windowIcons}>
            <Icon
              type="close"
              className={styles.windowIcon}
              onClick={onClose}
            ></Icon>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        {state.isResizing && <div className={styles.overlay}></div>}
        <div
          className={styles.resizer}
          onMouseDown={setIsResizing}
          onTouchStart={setIsResizing}
        >
          <Icon type="modalDrag" className={styles.resizeIcon}></Icon>
        </div>
        <div
          className={cx(styles.notificationArea, {
            [styles.active]: state.notification !== "",
          })}
        >
          <div className={styles.notification}>{state.notification}</div>
        </div>
      </div>
    );
  };

  return createPortal(ModalFrame(), el.current);
};

export default MultiModal;
