import { type RaceMeetingType, useDispatch } from "hooks";
import { useCallback, useMemo } from "react";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";
import { mapRaceToEvent } from "utilities/racingUtilities";
import type {
  RaceCompetitorType,
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";

type UseToggleRaceProps = {
  race: RaceEventType;
  competitors: RaceCompetitorType[];
  markets: RaceMarketsType[];
  raceMeeting: RaceMeetingType;
  raceTimezone: string;
};

export const useToggleRace = ({
  race,
  competitors,
  markets,
  raceMeeting,
  raceTimezone,
}: UseToggleRaceProps) => {
  const dispatch = useDispatch();

  const event = useMemo(
    () =>
      race &&
      competitors &&
      markets &&
      mapRaceToEvent({ event: race, competitors, markets }),
    [race, competitors, markets],
  );

  const toggleRace = useCallback(
    (outcomeId: string, marketId: string, odds: number | string): void => {
      dispatch(
        toggleSelection(
          event,
          {
            type: "race",
            outcomeId,
            marketId,
            sourceId: raceMeeting.id,
            odds: Number(odds),
            eventId: race.id,
            timezone: raceTimezone,
            attributes: {
              raceMeetingId: raceMeeting?.id,
              raceNumber: race?.number?.toString(),
              region: raceMeeting?.region,
            },
          },
          "racing",
        ),
      );
    },
    [dispatch, event, raceMeeting, race],
  );

  return toggleRace;
};
