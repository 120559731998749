import { useState, useEffect } from "react";
import { isPast, parseISO } from "date-fns";
import { useWalletActions } from "hooks/index";
import useAllDepositLimits from "hooks/firestore/useAllDepositLimits";
import type { DepositLimit } from "hooks/firestore/useAllDepositLimits";

export const useDepositLimits = (
  currency: string,
): [DepositLimit | undefined, boolean, any] => {
  const [depositLimits, loading] = useAllDepositLimits();
  const [isReloading, setIsReloading] = useState(false);
  const { doGetDepositLimits } = useWalletActions();

  const depositLimit = (depositLimits || []).find(
    (limit) => limit.currency === currency,
  );

  useEffect(() => {
    const reload = async () => {
      setIsReloading(true);
      doGetDepositLimits(() => setIsReloading(false));
    };
    if (
      depositLimit &&
      depositLimit.expiresAt &&
      isPast(parseISO(depositLimit.expiresAt)) &&
      !isReloading
    ) {
      reload();
    }
  }, [depositLimit]);

  return [depositLimit, isReloading || loading, undefined];
};
