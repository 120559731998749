import React from "react";
import PerfectScrollbar, { type ScrollBarProps } from "react-perfect-scrollbar";
import { iOSVersion, isAppleDevice, isAndroidDevice } from "utilities/display";
import cx from "classnames";
import { useIsClient } from "usehooks-ts";

import * as styles from "./ScrollArea.module.scss";

type IScrollAreaProps = {
  isMobile?: boolean;
  absolute?: boolean;
  wheelPropagation?: boolean;
  contentClassName?: string;
  verticalContainerStyle?: any;
  verticalScrollbarStyle?: any;
  smoothScrolling?: boolean;
  horizontal?: any;
};

export const ScrollArea = ({
  children,
  className,
  style,
  wheelPropagation,
  contentClassName,
  verticalContainerStyle,
  verticalScrollbarStyle,
  smoothScrolling,
  options,
}: IScrollAreaProps & ScrollBarProps) => {
  const refNaturalScrollingBox = React.createRef<HTMLDivElement>();
  const isClient = useIsClient();
  const isMobile = isClient ? isAppleDevice() || isAndroidDevice() : false;

  if ((isAppleDevice() && iOSVersion() > 12) || isAndroidDevice()) {
    return (
      <div
        key={"scroll-" + isMobile ? "mobile" : "desktop"}
        className={cx(className, {
          [styles.ScrollArea]: true,
        })}
        ref={refNaturalScrollingBox}
      >
        {children}
      </div>
    );
  }

  const psOptions = {
    ...(options || {}),
    suppressScrollX: true,
    wheelPropagation,
    contentClassName,
    verticalContainerStyle,
    verticalScrollbarStyle,
    smoothScrolling,
  };
  return (
    <PerfectScrollbar
      className={contentClassName}
      style={style}
      options={psOptions}
    >
      {children}
    </PerfectScrollbar>
  );
};

export default ScrollArea;
