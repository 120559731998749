import { Entry } from "components/layout/Entry";

import React, { useContext } from "react";
import { RaceContext } from "sections/Betting/Race/Race";
import * as styles from "./MyBets.module.scss";

export default () => {
  const { myBets } = useContext(RaceContext);

  return (
    <div className={styles.myBets}>
      {myBets?.map((entry) => (
        <Entry key={entry.id} entry={entry} isCompact={true} />
      ))}
    </div>
  );
};
