import countries from "country-list";
import provinces from "provinces";

export const countryMap: Record<string, string> = {
  AU: "Australia",
  US: "United States",
  GB: "United Kingdom",
  UK: "United Kingdom",
  IT: "Italy",
  MX: "Mexico",
  JP: "Japan",
  NZ: "New Zealand",
  KR: "South Korea",
  ZA: "South Africa",
  FR: "France",
  IE: "Ireland",
  CA: "Canada",
  SG: "Singapore",
  BR: "Brazil",
  HK: "Hong Kong",
  MO: "Macau",
  MY: "Malaysia",
  IN: "India",
  PE: "Peru",
  AR: "Argentina",
  CL: "Chile",
  DE: "Germany",
  MU: "Mauritius",
  AE: "United Arab Emirates",
  SE: "Sweden",
  FI: "Finland",
  NO: "Norway",
  DK: "Denmark",
  VN: "Vietnam",
  SA: "Saudi Arabia",
};

export const ausStatesMap: Record<string, string> = {
  WA: "Western Australia",
  NT: "Northern Territory",
  SA: "South Australia",
  QLD: "Queensland",
  NSW: "New South Wales",
  TAS: "Tasmania",
  VIC: "Victoria",
  ACT: "Australian Capital Territory",
};

export const getStateAndCountry = (countryCode: string, stateCode: string) => {
  if (!countryCode && !stateCode) return "";

  stateCode = countryCode === "AU" ? ausStatesMap[stateCode] : stateCode;

  const country = countries.getName(countryCode || "") ?? "";
  const province = provinces.find((province) => province.short === stateCode);

  if (province && country) {
    return `${province.name ?? stateCode}, ${country}`;
  } else if (province) {
    return province?.name ?? stateCode;
  } else if (country) {
    return country;
  }
};
