// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var bonus = "BetCard-module--bonus--43492";
export var emphasis = "BetCard-module--emphasis--d92da";
export var footer = "BetCard-module--footer--857f3";
export var green = "#57d33b";
export var greenLink = "BetCard-module--green-link--86349";
export var grey = "var(--tone-mid)";
export var label = "BetCard-module--label--62f06";
export var notchGap = "32px";
export var options = "BetCard-module--options--824b5";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";