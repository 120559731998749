// extracted by mini-css-extract-plugin
export var badge = "Notification-module--badge--674c5";
export var blue = "#0eb3f2";
export var container = "Notification-module--container--75657";
export var danger = "Notification-module--danger--ff354";
export var emphasis = "Notification-module--emphasis--b47d5";
export var green = "#57d33b";
export var greenLink = "Notification-module--green-link--263f6";
export var grey = "var(--tone-mid)";
export var icon = "Notification-module--icon--88bd1";
export var iconSkeleton = "Notification-module--iconSkeleton--ffd22";
export var info = "Notification-module--info--a9fe7";
export var isRead = "Notification-module--isRead--6deff";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var success = "Notification-module--success--1220a";
export var text = "Notification-module--text--51991";
export var time = "Notification-module--time--ab7dd";
export var warning = "Notification-module--warning--9a974";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";