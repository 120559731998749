// extracted by mini-css-extract-plugin
export var betCard = "BetCard-module--betCard--e78d1";
export var blue = "#0eb3f2";
export var emphasis = "BetCard-module--emphasis--d015c";
export var green = "#57d33b";
export var greenLink = "BetCard-module--green-link--51473";
export var grey = "var(--tone-mid)";
export var hasErrors = "BetCard-module--hasErrors--3bb56";
export var inner = "BetCard-module--inner--9d080";
export var invalid = "BetCard-module--invalid--0effa";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";