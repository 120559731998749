import { useEffect } from "react";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";
import {
  doc,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import { useDocumentData } from "hooks";
import { useIsClient, useLocalStorage } from "usehooks-ts";

export const ACCEPT_ODDS_MANUAL = "manual";
export const ACCEPT_ODDS_AUTO = "auto";
export const ACCEPT_ODDS_AUTO_HIGHER = "auto_higher";

export type Preferences = {
  acceptOdds: string;
  oddsFormat: string;
  defaultHub: "sports" | "esports" | "racing";
  allowCancelWithdrawal: boolean;
};

export const defaultPreferences: Preferences = {
  acceptOdds: ACCEPT_ODDS_AUTO_HIGHER,
  oddsFormat: "decimal",
  defaultHub: "racing",
  allowCancelWithdrawal: true,
};

const preferencesConverter: FirestoreDataConverter<Preferences> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Preferences => {
    const userData = snapshot.data(options);
    const preferences = userData.settings ?? {};

    return <Preferences>Object.keys(preferences || {}).reduce((result, key) => {
      if (typeof preferences[key] === "string") {
        result[key] = (preferences[key] || "").toLowerCase();
      } else if (typeof preferences[key] === "boolean") {
        result[key] = preferences[key];
      }
      return result;
    }, defaultPreferences);
  },
};

const usePreferences = (): [Preferences, boolean] => {
  const userId = useSelector((state) => state.auth?.userId);
  const [cachedPreferences, setCachedPreferences] =
    useLocalStorage<Preferences>(
      `userPreferences:${userId ?? `anonymous`}`,
      defaultPreferences,
    );

  const userRef = userId
    ? doc(getFirestore(), "users", userId).withConverter(preferencesConverter)
    : undefined;

  const [preferences, loading] = useDocumentData(userRef);

  useEffect(() => {
    if (loading || !preferences) {
      return;
    }

    setCachedPreferences(preferences);
  }, [
    loading,
    // using each property of preferences here to force a re-save when they change
    preferences?.allowCancelWithdrawal,
    preferences?.defaultHub,
    preferences?.acceptOdds,
    preferences?.oddsFormat,
  ]);

  return [cachedPreferences, loading];
};

export const useHomeUrl = (): string => {
  const [preferences] = usePreferences();
  const isClient = useIsClient();

  if (isClient) {
    if (preferences?.defaultHub === "sports") {
      return `/sports/betting`;
    } else if (preferences?.defaultHub === "racing") {
      return `/racing/betting`;
    }
  }

  return `/betting`;
};

export default usePreferences;
