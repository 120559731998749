// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var countdown = "Countdown-module--countdown--65cc7";
export var emphasis = "Countdown-module--emphasis--844dc";
export var green = "#57d33b";
export var greenLink = "Countdown-module--green-link--e65c8";
export var grey = "var(--tone-mid)";
export var isEminent = "Countdown-module--isEminent--c5637";
export var isSoon = "Countdown-module--isSoon--db4ba";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var root = "Countdown-module--root--212af";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";