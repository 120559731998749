import React from "react";
import { NavLogo } from "../Navigation";
import { Link } from "../Link";
import { isAppAvailable } from "utilities/display";
import useLocale from "hooks/useLocale";
import { useIsClient } from "usehooks-ts";

import * as styles from "./MenuHeader.module.scss";

const MenuHeader = () => {
  const locale = useLocale();
  const isClient = useIsClient();

  // TODO: hook up loading state in future
  return (
    <div className={styles.container}>
      <Link to="/">
        <NavLogo loading={false} includeTextOnMobile />
      </Link>
      {locale === "en-au" && isAppAvailable() && isClient ? (
        <div className={styles.appLink}>
          <a href="https://picklebet.onelink.me/8Ie4/309baad5">Use App</a>
        </div>
      ) : null}
    </div>
  );
};

export default MenuHeader;
