// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "PasswordInput-module--emphasis--149eb";
export var green = "#57d33b";
export var greenLink = "PasswordInput-module--green-link--771e5";
export var grey = "var(--tone-mid)";
export var icon = "PasswordInput-module--icon--14f71";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";