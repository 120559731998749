import localObject from "./localObject";

export interface ILastSession {
  uid?: string;
  email?: string;
}

class LastSession {
  private static KEY = "last-user-id";

  set(lastSession?: ILastSession) {
    localObject.setValue(LastSession.KEY, lastSession);
  }

  get(): ILastSession {
    const [lastSession] = localObject.getValue<ILastSession>(LastSession.KEY);
    return lastSession;
  }
}

export const lastSession = new LastSession();
export default lastSession;
