import type { PickType } from "sections/Entries/types";
import { useAutoAddPickToBetslip } from "components/layout/Entry/components/share/useAutoAddPickToBetslip";

type ShareAddToBetslipProps = {
  onPickAddedToBetslip: () => void;
  pick: PickType;
};

const ShareAddToBetslip = ({
  onPickAddedToBetslip,
  pick,
}: ShareAddToBetslipProps) => {
  useAutoAddPickToBetslip(pick, true, onPickAddedToBetslip);

  return null;
};

export { ShareAddToBetslip };
