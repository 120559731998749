import {
  collection,
  type CollectionReference,
  type DocumentData,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import { useCollectionData } from "hooks";
import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks";

const STATUSES = [
  "Failed",
  "InProgress",
  "Pending",
  "Complete",
  "ManuallyVerified",
  "UnderThreshold",
];

export type CreditCardVerificationStatus =
  | "Failed"
  | "InProgress"
  | "Pending"
  | "Complete"
  | "ManuallyVerified"
  | "UnderThreshold";

export type CreditCardVerification = {
  id: string;
  status: CreditCardVerificationStatus;
  remainingAttempts: number;
  name: string;
  numberFirst: string;
  numberLast: string;
  expiryMonth: string;
  expiryYear: string;
};

const converter: FirestoreDataConverter<CreditCardVerification> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    return { ...snapshot.data(options), id: snapshot.id };
  },
};

const useCreditCardVerifications = (): [CreditCardVerification[], boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const smsChannelRef = userId
    ? (collection(
        getFirestore(),
        "users",
        userId,
        "creditCardVerifications",
      ).withConverter(converter) as CollectionReference<CreditCardVerification>)
    : undefined;
  const [verifications, loading] = useCollectionData(
    smsChannelRef,
    smsChannelRef?.path,
  );

  const sorted = useMemo<CreditCardVerification[]>(() => {
    return (
      verifications
        ?.filter((verification) => STATUSES.includes(verification.status))
        .sort((a, b) => {
          const aOrder = STATUSES.indexOf(a.status);
          const bOrder = STATUSES.indexOf(b.status);
          return aOrder - bOrder;
        }) ?? []
    );
  }, [verifications]);

  return [sorted, loading];
};

export default useCreditCardVerifications;
