import React from "react";
import { Icon } from "library";
import cx from "classnames";

import * as styles from "./ExpandingContent.module.scss";

// Some pages may want to show more than just a chevron on the right. e.g. Match Page
// There is a default chevron on the right and a custom implementation is optional.
const ExpandingContent = ({
  headingIcon,
  headingTitle,
  headingRight,
  children,
  isOpen,
  onOpen,
  contentClassName,
  infoClassName,
  unstyled = false,
  className,
}: {
  headingTitle: JSX.Element | string;
  children: React.ReactNode;
  isOpen: boolean;
  onOpen: (arg0: boolean) => void;
  headingIcon?: JSX.Element;
  headingRight?: JSX.Element;
  infoClassName?: string;
  contentClassName?: string;
  unstyled?: boolean;
  className?: string;
}) => {
  if (!headingIcon && !unstyled) {
    headingIcon = <Icon className={styles.icon} type="ribbon" />;
  }

  if (!headingRight) {
    headingRight = (
      <div className={cx(styles.caret, { [styles.open]: isOpen })}>
        <Icon type="chevronDown" />
      </div>
    );
  }
  const toggleExpanded = () => onOpen(!isOpen);

  return (
    <div
      className={cx(styles.group, className, {
        [styles.resetStyles]: unstyled,
        [styles.open]: isOpen,
      })}
    >
      <div className={cx(styles.info, infoClassName)} onClick={toggleExpanded}>
        <div className={styles.heading}>
          {headingIcon}
          {headingTitle}
        </div>

        <div>{headingRight}</div>
      </div>
      {isOpen ? (
        <div className={cx(styles.content, contentClassName)}>{children}</div>
      ) : null}
    </div>
  );
};

export default ExpandingContent;
