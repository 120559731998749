// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var blurred = "Button-module--blurred--c7190";
export var button = "Button-module--button--b1113";
export var danger = "Button-module--danger--17fb1";
export var emphasis = "Button-module--emphasis--83223";
export var green = "#57d33b";
export var greenLink = "Button-module--green-link--4c852";
export var grey = "var(--tone-mid)";
export var gutterTop = "Button-module--gutterTop--de2f5";
export var hasIcon = "Button-module--hasIcon--9137f";
export var icon = "Button-module--icon--4fa19";
export var info = "Button-module--info--30e3d";
export var link = "Button-module--link--ce317";
export var maxContent = "Button-module--maxContent--a6ef0";
export var notchGap = "32px";
export var orange = "#f8662b";
export var primary = "Button-module--primary--b8701";
export var red = "#ff2741";
export var secondary = "Button-module--secondary--9bc82";
export var sizeDefault = "Button-module--sizeDefault--fc5a7";
export var sizeMd = "Button-module--sizeMd--e8bd2";
export var sizeSm = "Button-module--sizeSm--b1458";
export var sizeXs = "Button-module--sizeXs--991b7";
export var skeleton = "Button-module--skeleton--28c33";
export var warning = "Button-module--warning--9d83f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";