// extracted by mini-css-extract-plugin
export var active = "TitleFilter-module--active--3263e";
export var blue = "#0eb3f2";
export var emphasis = "TitleFilter-module--emphasis--de379";
export var extended = "TitleFilter-module--extended--e1dd6";
export var green = "#57d33b";
export var greenLink = "TitleFilter-module--green-link--eda27";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scrollArea = "TitleFilter-module--scrollArea--d380b";
export var scrollBar = "TitleFilter-module--scrollBar--e6b13";
export var title = "TitleFilter-module--title--08604";
export var titleCountBadge = "TitleFilter-module--titleCountBadge--a4d84";
export var titleFilter = "TitleFilter-module--titleFilter--bfe10";
export var titleIcon = "TitleFilter-module--titleIcon--5255f";
export var titleSkeleton = "TitleFilter-module--titleSkeleton--e287a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";