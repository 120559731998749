import React from "react";
import cx from "classnames";
import { Helmet } from "react-helmet";
import { useIsClient } from "usehooks-ts";
import Title from "../Title";

import { Banners } from "../Banner";
import { PageBody } from "./PageBody";
import type { IContentItem } from "../TabFilter";
import { selectRouteContent } from "utilities/route";

import ErrorBoundary from "../ErrorBoundary";
import SEO from "library/components/Section/SEO";

import * as styles from "./Page.module.scss";

type PageProps = {
  ref?: any;
  children?: React.ReactNode;
  className?: string;
  isMobile?: boolean;
  title?: string;
  subtitle?: string;
  displayTitle?: JSX.Element | string;
  description?: string;
  crumbs?: any;
  metaMap?: Record<string, string>;
  banners?: any;
  content?: IContentItem[];
};

export const Page = ({
  ref,
  className,
  children,
  title,
  subtitle,
  displayTitle,
  description,
  crumbs,
  metaMap,
  banners,
  content,
}: PageProps) => {
  const isClient = useIsClient();

  metaMap = {
    description:
      description ||
      "Picklebet is the Next Gen Betting App. Australian owned & operated, try our Top 4 Betting, Same Race Multi, Same Game Multi, Pick'ems competitions & more today. Bet now!",
    "og:title": title,
    "og:description": description,
    ...(metaMap || {}),
  };

  let onPageTitle = displayTitle !== undefined ? displayTitle : title;
  let headTitle = title || displayTitle;
  if ((headTitle + "").indexOf("Picklebet") < 0) {
    headTitle += " | Racing, Sports and Esports Betting";
  }

  let activeLink: string;
  if (content?.length > 0) {
    const activeContent =
      content?.length > 0 ? selectRouteContent(content) : null;
    if (activeContent) {
      activeLink = activeContent.link;
      onPageTitle = activeContent.displayTitle || activeContent.title;
      headTitle = activeContent.title;
      subtitle = activeContent.description;
      if (activeContent.banners) {
        banners = activeContent.banners;
      }
    }
  }

  return (
    <>
      <SEO />
      <div ref={ref} className={cx(styles.Page, className)}>
        {title && (
          <Helmet defer={false}>
            <title>{headTitle}</title>
            {metaMap &&
              Object.entries(metaMap).map(([key, value]) => (
                <meta key={key} name={key} content={value} />
              ))}
          </Helmet>
        )}
        {banners && banners.length > 0 && (
          <div className={styles.pageBanner}>
            <ErrorBoundary>
              {isClient && <Banners banners={banners} />}
            </ErrorBoundary>
          </div>
        )}
        <div className={styles.pageMain}>
          {onPageTitle && (
            <Title title={onPageTitle} subtitle={subtitle} crumbs={crumbs} />
          )}
          {isClient &&
            (children ||
              (content && <PageBody menu={content} activeLink={activeLink} />))}
        </div>
      </div>
    </>
  );
};
