import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";

import * as styles from "./Drawer.module.scss";

type DrawerProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  className?: string;
};

const drawerVariants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: {
    opacity: 0,
    height: 0,
  },
};

export const Drawer = ({ children, isOpen, className }: DrawerProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={cx(styles.drawerContainer, className)}
          variants={drawerVariants}
          animate={isOpen ? "open" : "closed"}
          initial={"closed"}
          exit={"closed"}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
