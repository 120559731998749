// extracted by mini-css-extract-plugin
export var clampContainers = "RaceRowsSkeleton-module--clampContainers--afc2b";
export var clampMore = "RaceRowsSkeleton-module--clampMore--ee2ef";
export var clampStatus = "RaceRowsSkeleton-module--clampStatus--47eae";
export var longTitle = "RaceRowsSkeleton-module--longTitle--1220a";
export var nextToJumpIcon = "RaceRowsSkeleton-module--nextToJumpIcon--5875c";
export var raceNumberIcon = "RaceRowsSkeleton-module--raceNumberIcon--93602";
export var row = "RaceRowsSkeleton-module--row--3d394";
export var runnerInfo = "RaceRowsSkeleton-module--runnerInfo--61f27";
export var runnerSubText = "RaceRowsSkeleton-module--runnerSubText--632bb";
export var runnerTitle = "RaceRowsSkeleton-module--runnerTitle--6ac37";
export var runnerTitleText = "RaceRowsSkeleton-module--runnerTitleText--65e53";