import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { Card } from "./BrazeContentCardTypes";
import { isWebView } from "utilities/display";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "utilities/AppWebMessagingBridge";

type BrazeContentCardsState = {
  cards: Card[];
  initialized: boolean;
};

const initialState: BrazeContentCardsState = {
  cards: [],
  initialized: false,
};

const brazeContentCardsSlice = createSlice({
  name: "brazeContentCards",
  initialState,
  reducers: {
    setCards: (state, action: PayloadAction<Card[]>) => {
      state.cards = action.payload;
      state.initialized = true;
    },
    setInitialized(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload;
    },
    addCard: (state, action: PayloadAction<Card>) => {
      state.cards.push(action.payload);
    },
    clearCards: (state) => {
      state.cards = [];
    },
  },
});

export const logContentCardImpression = (card: Card) => async () => {
  // Web only accept an array of cards to track impressions. RN only accepts a single card id.
  // We will always only have 1 impression at a time. Based on as the banner or card is displayed or scrolls into view.
  if (isWebView()) {
    sendAppMessage(
      WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_LOG_CONTENT_CARD_IMPRESSION,
      { cardId: card.id },
    );
  } else {
    window?.braze?.logContentCardImpressions([card]);
  }
};

export const logContentCardClick = (card: Card) => async () => {
  // Web only accept a card to track impressions. RN only accepts a single card id.
  if (isWebView()) {
    sendAppMessage(
      WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_LOG_CONTENT_CARD_CLICK,
      { cardId: card.id },
    );
  } else {
    window?.braze?.logContentCardClick(card);
  }
};

export const { setCards, addCard, clearCards, setInitialized } =
  brazeContentCardsSlice.actions;

export default brazeContentCardsSlice.reducer;
