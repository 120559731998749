import React from "react";
import cx from "classnames";
import {
  InstagramEmbed,
  YouTubeEmbed,
  FacebookEmbed,
  TikTokEmbed,
} from "react-social-media-embed";
import * as styles from "./SocialMedia.module.scss";

type SocialMediaProps = {
  url: string;
};

enum EmbedSource {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
}

const getEmbedSource = (url: string): EmbedSource => {
  if (url.includes("instagram.com")) {
    return EmbedSource.INSTAGRAM;
  } else if (url.includes("youtube.com")) {
    return EmbedSource.YOUTUBE;
  } else if (url.includes("facebook.com")) {
    return EmbedSource.FACEBOOK;
  } else if (url.includes("tiktok.com")) {
    return EmbedSource.TIKTOK;
  }
};

const SocialMedia = (props: SocialMediaProps) => {
  const embedSource = getEmbedSource(props.url);

  return (
    <div
      className={cx(styles.embed, {
        [styles[embedSource.toLowerCase()]]: true,
      })}
    >
      {embedSource === EmbedSource.INSTAGRAM && (
        <InstagramEmbed url={props.url} />
      )}
      {embedSource === EmbedSource.FACEBOOK && (
        <FacebookEmbed url={props.url} />
      )}
      {embedSource === EmbedSource.YOUTUBE && (
        <YouTubeEmbed url={props.url} width={"100%"} />
      )}
      {embedSource === EmbedSource.TIKTOK && <TikTokEmbed url={props.url} />}
    </div>
  );
};

export default SocialMedia;
