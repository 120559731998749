import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export type SameEventMultiSlipState = typeof initialState;

const sameEventMultiBuilderSlice = createSlice({
  name: "SameEventMultiSlip",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = sameEventMultiBuilderSlice.actions;

export default sameEventMultiBuilderSlice.reducer;
