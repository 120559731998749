// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var circle = "Skeleton-module--circle--a66cf";
export var dark = "Skeleton-module--dark--4912d";
export var emphasis = "Skeleton-module--emphasis--cde4a";
export var green = "#57d33b";
export var greenLink = "Skeleton-module--green-link--a3c38";
export var grey = "var(--tone-mid)";
export var half = "Skeleton-module--half--26257";
export var hard = "Skeleton-module--hard--68127";
export var large = "Skeleton-module--large--cede1";
export var light = "Skeleton-module--light--f71dd";
export var notchGap = "32px";
export var orange = "#f8662b";
export var quart = "Skeleton-module--quart--c380e";
export var rect = "Skeleton-module--rect--7d84d";
export var red = "#ff2741";
export var shimmer = "Skeleton-module--shimmer--d81f3";
export var sizeDefault = "Skeleton-module--sizeDefault--59e82";
export var skeleton = "Skeleton-module--skeleton--df9c1";
export var small = "Skeleton-module--small--e8d1f";
export var soft = "Skeleton-module--soft--62251";
export var square = "Skeleton-module--square--e0ee8";
export var third = "Skeleton-module--third--b8cbd";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var xsmall = "Skeleton-module--xsmall--38d3e";
export var yellow = "#ffb100";