import React, { useEffect } from "react";
import cx from "classnames";
import { type RacingRouteParams, RegionType } from "../../hooks/useRacingRoute";
import { usePageContext } from "hooks/usePageContext";
import { useHub } from "hooks/graphql/useHubs";
import { usePermissions } from "hooks";
import { PermissionReason } from "utilities/Auth/authSlice";
import {
  TitleFilter,
  TitleFilterItem,
  TitleFilterItems,
} from "sections/Betting/TitleFilter";
import { Icon } from "library";
import {
  ToggleGroupItem,
  ToggleGroupRoot,
} from "components/ToggleGroup/ToggleGroup";
import { isEqual } from "lodash";

import { ReactComponent as Australia } from "sections/Betting/Racing/components/assets/icon-australia.svg";
import { ReactComponent as World } from "sections/Betting/Racing/components/assets/icon-world.svg";

import * as styles from "./RaceMeetingsFilter.module.scss";

type TitleCountsType = Record<string, number>;

type RacesFilterProps = {
  titleCounts: TitleCountsType;
  route: Partial<RacingRouteParams>;
  setRouteValue: (value: Partial<RacingRouteParams>) => void;
};

export const RaceMeetingsFilter = ({
  titleCounts,
  setRouteValue,
  route,
}: RacesFilterProps) => {
  const { hub: hubName } = usePageContext();
  const hubData = useHub(hubName);
  const routeSports = route.sports ?? [];
  const sportsTitles = hubData?.titles ?? [];

  const permissions = usePermissions();

  useEffect(() => {
    const canSubmitInternationalRacingBet =
      permissions?.submitInternationalRacingBet === PermissionReason.GRANTED;

    if (
      route?.region.includes(RegionType.world) &&
      !canSubmitInternationalRacingBet
    ) {
      setRouteValue({ region: [RegionType.au] });
    }
  }, [permissions, route]);

  const handleTitleSelect = (sports: string[]) => {
    setRouteValue({ sports });
  };

  const isAllActive = routeSports.length === 0;
  const canSubmitIntlRacingBet =
    permissions?.submitInternationalRacingBet === PermissionReason.GRANTED;

  return (
    <div className={styles.raceMeetingFilters}>
      <TitleFilter
        selectionType={"multiple"}
        selected={route.sports || []}
        onSelect={handleTitleSelect}
        className={styles.titleFilter}
      >
        <TitleFilterItem
          onClick={() => setRouteValue({ sports: undefined })}
          className={cx(styles.titleFilterItem, {
            [styles.active]: isAllActive,
          })}
        >
          <Icon
            type={isAllActive ? "all" : "close"}
            className={cx(styles.filterIcon, { [styles.all]: isAllActive })}
          />
        </TitleFilterItem>

        <TitleFilterItems
          className={styles.titleFilterItem}
          iconClassName={styles.filterIcon}
          titles={sportsTitles}
          counts={titleCounts}
          hideCounts={route.tab == "next-to-jump"}
          alwaysShowTitles
        />
      </TitleFilter>

      <div className={styles.regionFilters}>
        <ToggleGroupRoot
          type="multiple"
          className={styles.regionFilterGroup}
          value={route.region ?? []}
          onValueChange={(value: RegionType[]) => {
            if (
              (route.region?.length === 1 && !isEqual(route.region, value)) ||
              route.region?.length === 2
            ) {
              setRouteValue({ region: value });
            }
          }}
        >
          <ToggleGroupItem value="au" className={styles.titleFilterItem}>
            <div className={styles.filterIcon}>
              <Australia />
            </div>
          </ToggleGroupItem>
          {canSubmitIntlRacingBet && (
            <ToggleGroupItem value="world" className={styles.titleFilterItem}>
              <div className={styles.filterIcon}>
                <World />
              </div>
            </ToggleGroupItem>
          )}
        </ToggleGroupRoot>
      </div>
    </div>
  );
};
