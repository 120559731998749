export const loadPhrases = [
  "Finding 1s and 0s...",
  "Making sure all the j's have dots...",
  "Cracking military-grade encryption...",
  "Swearing it's almost done...",
  "Waiting while little elves draw your map...",
  "...at least you're not on hold...",
  "Computing the secret to life, the universe, and everything...",
  "Installing Updater...",
  "Updating installer...",
  "Being distracted by cat gifs...",
  "Downloading more RAM...",
  "Computing chance of success...",
  "Loading previous save...",
  "Loading a new loading line...",
  "Drinking a healing potion...",
  "Switching sides...",
  "Rushing B...",
  "Pressing random buttons...",
  "Optimizing the optimizer...",
  "Carbonating soda...",
  "Displaying witty text...",
  "Sharpening Pencils...",
  "Automating Processes",
  "Backing Kickstarters...",
  "Searching Bing...",
  "Vomiting Redbull...",
  "Injecting Caffeine...",
  "Emulating Playstation...",
  "Attracting Venture Capital...",
  "Retrying Konami Code...",
  "Waiting on Half Life 3...",
  "Rushing Zergs...",
  "Rescuing Hostages...",
  "Choosing Pikachu...",
  "Blowing in cartridge...",
  "Ordering Pizza...",
  "Your driver is on its way...",
  "Your princess is in another castle...",
  "Recycling memes...",
  "Spawning Bosses...",
  "Skipping cutscenes...",
  "Customizing characters...",
  "Corrupting save files...",
  "Punching trees...",
  "Promoting synergy...",
  "Farming dog residue...",
  "Streaming NBA2K to Ciaran...",
  "Washing hands...",
  '"Working" from home...',
  "Learning the Gek word for 'frustration'...",
  "Completing side quest...",
  "Collecting collectibles...",
  "Counter-terrorising terrorists...",
  "Rerouting the encyption to the mainframe...",
  "Putting cloud data into the datacloud...",
  "Installing EAC into the kernel...",
  "Liking and subscribing...",
  "Triangulating the source of the phonecall...",
  "Locating Princess' castle...",
  "Flipping the Battle-Cars back over...",
  "Breaching the door...",
  "Pressing F to pay respects...",
  "'Loading...'",
  "Purchasing all office furniture...",
  "Tallying all the FIFA points...",
  "Griefing private servers...",
  "Reticulating splines...",
  "Cooking chicken dinners...",
  "Ripping out LAN cables...",
  "Initialising modem...",
  "Dialing ISP...",
  "Connecting via Fibre-To-The-Suburb...",
  "Jungling NPCs...",
  "Generating random numbers...",
  "Pouring salt...",
  "Stacking nades...",
  "Running with knives...",
  "Finding out how to quick-scope...",
  "Repeatedly cratering...",
  "Waiting in lobby...",
  "Takin' aggro...",
  "Muting channel...",
  "Starting Windows 95...",
  "Customising Geforce Experience...",
  "Downloading 92+ updates...",
  "Increasing DPI...",
  "Booting from CD-ROM (Press Esc to skip)...",
  "Raytracing global illumination...",
  "Reading from Drive A:\\...",
  "Performing digital turnip arbitrage...",
  "Providing players with a sense of pride and accomplishment...",
  "Acquiring more Vespene gas...",
];

let nextIndex = Math.floor(Math.random() * loadPhrases.length);
let lastTime = 0;

export function selectLoadingPhrase() {
  const now = new Date().getTime();
  if (now - lastTime > 2000) {
    nextIndex++;
    if (nextIndex >= loadPhrases.length) {
      nextIndex = 0;
    }
  }
  lastTime = now;
  return loadPhrases[nextIndex];
}

export default selectLoadingPhrase;
