// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Pacman-module--emphasis--e53bf";
export var green = "#57d33b";
export var greenLink = "Pacman-module--green-link--b1242";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pacman = "Pacman-module--pacman--bf0ff";
export var pacmanBalls = "Pacman-module--pacman-balls--334b3";
export var red = "#ff2741";
export var rotate_pacman_half_down = "Pacman-module--rotate_pacman_half_down--b123f";
export var rotate_pacman_half_up = "Pacman-module--rotate_pacman_half_up--7f7ea";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";