import React from "react";
import { useStaticEntries } from "hooks/firestore/betting/useEntries";
import { Entry } from "components/layout/Entry";
import { EntrySkeleton } from "components/layout/Entry/EntrySkeleton";
import { Typography } from "components/Typography";
import { ButtonGroup } from "components/ButtonGroup";
import { LinkButton } from "components/LinkButton";
import { PageMessage } from "components/PageMessage";
import { ReactComponent as SettledIcon } from "sections/Entries/assets/empty/settled.svg";

import * as styles from "./Betslip.module.scss";

const SettledBets = () => {
  const { entries, isLoading } = useStaticEntries(10);

  if (isLoading) {
    return <EntrySkeleton />;
  }

  if (!entries?.length) {
    return (
      <PageMessage
        className={styles.entryMessage}
        image={<SettledIcon />}
        title={`No Settled Entries`}
        subTitle={
          <>
            <Typography variant={`body`} noSpacing>
              Check out today's action to make a bet
            </Typography>
          </>
        }
      >
        <ButtonGroup className={styles.entryButtons}>
          <LinkButton size={`md`} to={`/betting`}>
            Next Esports
          </LinkButton>
          <LinkButton size={`md`} to={`/sports/betting`}>
            Next Sports
          </LinkButton>
          <LinkButton size={`md`} to={`/racing/betting`}>
            Next Race
          </LinkButton>
        </ButtonGroup>
      </PageMessage>
    );
  }

  return (
    <div className={styles.entries}>
      {entries.map((entry) => (
        <Entry key={entry.id} entry={entry} isCompact={true} />
      ))}
    </div>
  );
};

export default SettledBets;
