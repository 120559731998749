import type { ButtonProps } from "components/Button/Button";
import { ButtonGroup } from "components/ButtonGroup";
import { LinkButton } from "components/LinkButton";
import { useIsMobile, usePermissions } from "hooks";
import React from "react";

type NextBettingButtonsProps = Partial<{
  className: string;
  size: ButtonProps["size"];
  variant: ButtonProps["variant"];
}>;

export default ({
  className,
  size = "default",
  variant,
}: NextBettingButtonsProps) => {
  const isMobile = useIsMobile();
  const btnSize = isMobile ? "md" : size;

  const permissions = usePermissions();

  return (
    <ButtonGroup className={className}>
      {permissions.viewEsportsMarkets === "GRANTED" && (
        <LinkButton variant={variant} size={btnSize} to={`/betting`}>
          Next Esports
        </LinkButton>
      )}
      {permissions.viewSportsMarkets === "GRANTED" && (
        <LinkButton variant={variant} size={btnSize} to={`/sports/betting`}>
          Next Sports
        </LinkButton>
      )}
      {permissions.viewRacingMarkets === "GRANTED" && (
        <LinkButton variant={variant} size={btnSize} to={`/racing/betting`}>
          Next Race
        </LinkButton>
      )}
    </ButtonGroup>
  );
};
