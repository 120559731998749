import { useFiatCurrency } from "hooks/firestore";
import { usePermissions } from "hooks/useHasPermission";
import { useMemo } from "react";

export const useHasDepositPermissions = () => {
  const permissions = usePermissions();

  return useMemo(
    () =>
      Object.entries(permissions).some(
        ([key, value]) => key.includes("deposit") && value === "GRANTED",
      ),
    [permissions],
  );
};
