import { Icon } from "library";
import React from "react";
import cx from "classnames";
import * as styles from "./PromoIcon.module.scss";

export const PromoIcon = ({ className }: { className?: string }) => (
  <Icon
    title="Has promotions"
    className={cx(styles.promoIcon, className)}
    type="star"
  />
);
