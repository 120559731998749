import { useEffect } from "react";
import { useRaceCounts } from "sections/Betting/Racing/hooks/useRacingTitleCounts";
import type { RaceMeetingsProps } from "sections/Betting/Racing/components/RaceMeetings/RaceMeetings";

export const useRaceMeetings = (props: RaceMeetingsProps) => {
  const { setRouteValue, route } = props;

  const selectedTitles = route.sports ?? [];

  useEffect(() => {
    if (route?.sport && !selectedTitles.includes(route?.sport)) {
      setRouteValue({
        sports: [route?.sport],
      });
    }
  }, [route.sport]);

  const { titleCounts, loading: badgeLoading } = useRaceCounts(route);

  return {
    route,
    titleCounts,
    badgeLoading,
  };
};
