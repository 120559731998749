import React from "react";
import cx from "classnames";

import * as styles from "./ContentColumn.module.scss";

const ContentColumn = ({ children = undefined, className = undefined }) => (
  <div className={cx({ [styles.column]: true, [className]: !!className })}>
    {children}
  </div>
);

export default ContentColumn;
