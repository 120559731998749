import { useMemo } from "react";
import {
  collection,
  type CollectionReference,
  type FirestoreError,
} from "firebase/firestore";
import { useCollectionData } from "hooks";
import { getFirestore } from "store/getFirebase";
import type { PickemsLeaderboardEntry } from "./types";

const usePickemsLeaderboard = (
  contestId: string,
): [PickemsLeaderboardEntry[], boolean, FirestoreError] => {
  const leaderboardRef = contestId
    ? (collection(
        getFirestore(),
        "pickemsContests",
        contestId,
        "leaderboard",
      ) as CollectionReference<PickemsLeaderboardEntry>)
    : undefined;
  const [leaderboard, loading, error] = useCollectionData(
    leaderboardRef,
    leaderboardRef?.path,
  );

  const sortedEntries = useMemo(() => {
    return (
      leaderboard?.sort((entryA, entryB) =>
        entryA.points > entryB.points ? -1 : 1,
      ) ?? []
    );
  }, [leaderboard]);

  return [sortedEntries, loading, error];
};

export default usePickemsLeaderboard;
