import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "./AppWebMessagingBridge";
import { isAndroidDevice, isAndroidWebView } from "./display";

export function hasNavigatorShare() {
  if (typeof window === "undefined") return false;
  return typeof navigator.share === "function";
}

export const handleShareNatively = async (shareData: ShareData) => {
  if (isAndroidWebView()) {
    // android does not implement navigator.share natively so we send to
    // react-native to handle that case
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_SHARE_CONTENT, shareData);
    return;
  } else if (isAndroidDevice()) {
    new Promise((resolve, reject) => {
      // due to long outstanding issues with Android devices we have to manually
      // resolve or reject navigator.share or we will forever hang in limbo
      // https://issues.chromium.org/issues/40480042
      navigator.share(shareData).then(resolve).catch(reject);
    });
    return;
  }

  return await navigator.share(shareData);
};

export const isExpectedError = (name: string) => {
  // we dont surface errors if the user cancelled the native dialog
  return name === "AbortError";
};
