import React from "react";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./HeroContainer.module.scss";

export const HeroContainer = ({
  images = [] as IGatsbyImageData[],
  children,
  className = "",
}) => (
  <div className={[styles.heroContainer, className].join(" ")}>
    <div className={styles.images}>
      {images?.map((x, index) => (
        <div
          key={`${x.images.sources.toString()}-${index}`}
          className={styles.image}
        >
          <GatsbyImage image={x} alt="Pickems Heading" />
        </div>
      ))}
    </div>
    <div className={styles.gradient} />
    <div>{children}</div>
  </div>
);
