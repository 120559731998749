// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clampLeft = "RaceMeetingDropDownSkeleton-module--clampLeft--33765";
export var clampRight = "RaceMeetingDropDownSkeleton-module--clampRight--cea8f";
export var container = "RaceMeetingDropDownSkeleton-module--container--9af01";
export var emphasis = "RaceMeetingDropDownSkeleton-module--emphasis--785a9";
export var green = "#57d33b";
export var greenLink = "RaceMeetingDropDownSkeleton-module--green-link--eff7c";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var text = "RaceMeetingDropDownSkeleton-module--text--9b661";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";