import React from "react";

import { PageLoader } from "./PageLoader";
import { Empty } from "../Empty";
import { Icon } from "../Icon";
import type { LoadStatus } from "types/ContentTypes";

import * as styles from "./RemoteContentLoader.module.scss";

interface IRemoteContentLoaderProps {
  loadStatus: LoadStatus;
  isConnected: boolean;
}

export class RemoteContentLoader extends React.Component<
  IRemoteContentLoaderProps,
  IRemoteContentLoaderProps
> {
  state: IRemoteContentLoaderProps = {
    loadStatus: "",
    isConnected: false,
  };

  static getDerivedStateFromProps(
    props: IRemoteContentLoaderProps,
    state: IRemoteContentLoaderProps,
  ) {
    const { loadStatus, isConnected } = props;
    const newState = { loadStatus, isConnected };
    for (const key in newState) {
      if (state[key] !== newState[key]) {
        return newState;
      }
    }
    return null;
  }

  render() {
    const { loadStatus, isConnected } = this.state;
    const { children } = this.props;

    if (loadStatus === "loaded" || loadStatus === undefined) {
      return children;
    }

    if (isConnected === false) {
      return (
        <Empty
          key="load-disconnected"
          className={styles.disconnected}
          icon={<Icon type="disconnected" />}
          message={
            <>
              Picklebet requires internet access.
              <br />
              Please turn on your network connection.
            </>
          }
        />
      );
    }

    if (loadStatus === "error") {
      return (
        <Empty
          key="load-error"
          className={styles.disconnected}
          icon={<Icon type="disconnected" />}
          message={<>We couldn't load the information you requested.</>}
        />
      );
    }

    return <PageLoader key="loading" />;
  }
}
