import React from "react";
import { Link } from "library";
import { useLocation } from "hooks/useLocation";
import cx from "classnames";
import type { MenuItem } from "./types";
import { usePageContext } from "hooks/usePageContext";
import has from "lodash/has";

import * as styles from "./SubItem.module.scss";

type SubItemProps = {
  item: MenuItem;
};

const SubItemContent = ({ item }: { item: MenuItem }) => {
  return (
    <li>
      <span className={styles.icon}>{item.icon}</span>
      <span>{item.name}</span>
      {item.badge ? <span className={styles.badge}>{item.badge}</span> : null}
    </li>
  );
};

const SubItem = ({ item }: SubItemProps) => {
  const { pathnameWithoutLocale: pathname } = useLocation();
  const { title } = usePageContext();

  const isActivePath = pathname.startsWith(item.to);
  const isActiveTitle = item.title ? item.title === title : false;

  const isActive = has(item, "isActive") // if menu items has own active state do not override it
    ? item.isActive
    : isActivePath || isActiveTitle;

  if (item.onClick) {
    return (
      <div
        className={cx(styles.item, {
          [styles.active]: isActive,
        })}
        onClick={item.onClick}
      >
        <SubItemContent item={item} />
      </div>
    );
  }

  return (
    <Link
      className={cx(styles.item, {
        [styles.active]: isActive,
      })}
      to={item.to}
    >
      <SubItemContent item={item} />
    </Link>
  );
};

export default SubItem;
