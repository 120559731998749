// extracted by mini-css-extract-plugin
export var alert = "Intercepted-module--alert--ba24f";
export var blue = "#0eb3f2";
export var button = "Intercepted-module--button--0d4ae";
export var emphasis = "Intercepted-module--emphasis--230f1";
export var footer = "Intercepted-module--footer--b638c";
export var green = "#57d33b";
export var greenLink = "Intercepted-module--green-link--84f9d";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";