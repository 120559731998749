import { usePreviousValue } from "hooks";
import { difference, mapValues } from "lodash";
import { useEffect, useState } from "react";
import type { RaceOutcomeType } from "sections/Betting/Race/hooks/RacingTypes";
import type { MappedCompetitorsType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { useBoolean } from "usehooks-ts";

const toOutcomeIdsList = (o: RaceOutcomeType[]) =>
  o.map((outcome) => outcome.id);

export const useExoticOutcomeSelections = (
  groupedOutcomes: Record<string, RaceOutcomeType[]>,
  competitors: MappedCompetitorsType,
  isQuinella = false,
) => {
  const [selectedOutcomes, setSelectedOutcomes] = useState<string[]>([]);
  const previouslySelectedOutcomes = usePreviousValue(selectedOutcomes);

  const previousOutcomeState = usePreviousValue(groupedOutcomes);
  useEffect(() => {
    // this effect ensures that any competitor data changes upstreams forces the
    // exotic pages to recompute the available outcomes. If any outcomes have been
    // removed from the markets we make that change here so all downstream
    // components stay synchronised
    if (!previousOutcomeState || !groupedOutcomes) return;

    const outcomesToRemove = difference(
      Object.values(previousOutcomeState).flatMap(toOutcomeIdsList),
      Object.values(groupedOutcomes).flatMap(toOutcomeIdsList),
    );

    if (outcomesToRemove.length > 0) {
      setSelectedOutcomes((prev) =>
        prev.filter((id) => !outcomesToRemove.includes(id)),
      );
    }
  }, [competitors]);

  const groupedOutcomeIds = mapValues(groupedOutcomes, (outcomes) =>
    outcomes.map((o) => o.id),
  );

  const handleSelectedOutcomeGroup = (type: string) => {
    const outcomes = groupedOutcomeIds[type];

    if (outcomes.some((id) => selectedOutcomes.includes(id))) {
      setSelectedOutcomes((p) => p.filter((id) => !outcomes.includes(id)));
      return;
    }

    setSelectedOutcomes((p) => p.concat(outcomes));
  };

  const outcomes = Object.values(groupedOutcomes).flat();

  // this edge case is a symptom of only supporting boxed quinellas
  const quinellaOutcomes = outcomes.flatMap((outcome) =>
    outcome.type === "RUNNER_TOP_2" ? outcome : [],
  );

  const { value: isBoxed, setValue: setIsBoxed } = useBoolean();
  const isBoxedActive = isQuinella
    ? quinellaOutcomes.every((o) => selectedOutcomes.includes(o.id))
    : outcomes.every((o) => selectedOutcomes.includes(o.id));

  const onBoxedChange = (isActive: boolean) =>
    isActive
      ? setSelectedOutcomes(
          outcomes.flatMap((o) =>
            isQuinella ? (o.type === "RUNNER_TOP_2" ? o.id : []) : o.id,
          ),
        )
      : setSelectedOutcomes([]);

  return {
    selectedOutcomes,
    setSelectedOutcomes,
    isBoxed,
    setIsBoxed,
    isBoxedActive,
    onBoxedChange,
    handleSelectedOutcomeGroup,
    previouslySelectedOutcomes,
  } as const;
};
