import React from "react";
import { navigate } from "library/components/Link";

import * as styles from "./BlogWidgets.module.scss";
import Button from "../ButtonWithState";

const getImageForTitle = (title) => {
  switch (title) {
    case "csgo":
      return "/images/csgo-daily-fantasy.png";
    case "dota2":
      return "/images/dota2-daily-fantasy.png";
    case "lol":
      return "/images/lol-daily-fantasy.png";
    default:
      return null;
  }
};

const FeaturedMatch = ({
  "background-image": backgroundImage,
  title,
  team1,
  team2,
  "button-text": buttonText,
  "button-url": buttonUrl,
}) => {
  const imageUrl = backgroundImage ? backgroundImage : getImageForTitle(title);
  return (
    <div
      className={styles.featuredMatch}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className={styles.title}>Featured Match</div>
      <div className={styles.teams}>
        <span>{team1}</span>
        <span className={styles.vs}>VS</span>
        <span>{team2}</span>
      </div>
      <Button type={"success"} onClick={() => navigate(buttonUrl)}>
        {buttonText}
      </Button>
    </div>
  );
};

export default FeaturedMatch;
