import React from "react";
import { useDispatch, useIsLoggedIn } from "hooks";
import { Button } from "components/Button";
import { setModal } from "utilities/UI/uiSlice";
import { PageMessage } from "components/PageMessage";

import { ReactComponent as WarningIcon } from "components/assets/login-warning.svg";
import { useIsClient } from "usehooks-ts";

type RequiresAuthProps = {
  children: React.ReactNode;
};

const RequiresAuth = ({ children }: RequiresAuthProps) => {
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  const isClient = useIsClient();

  if (!isClient) return null;

  if (!isLoggedIn) {
    return (
      <PageMessage
        title={`You must log in to continue`}
        image={<WarningIcon />}
      >
        <Button onClick={() => dispatch(setModal("signIn"))}>Log In</Button>
      </PageMessage>
    );
  }

  return <>{children}</>;
};

export { RequiresAuth };
