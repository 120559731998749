// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var close = "Betslip-module--close--99e22";
export var container = "Betslip-module--container--6d56d";
export var content = "Betslip-module--content--cd6f2";
export var emphasis = "Betslip-module--emphasis--ba554";
export var entries = "Betslip-module--entries--f7706";
export var entryButtons = "Betslip-module--entryButtons--05727";
export var entryMessage = "Betslip-module--entryMessage--4cdf7";
export var green = "#57d33b";
export var greenLink = "Betslip-module--green-link--67075";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var quickBalance = "Betslip-module--quickBalance--53b0d";
export var red = "#ff2741";
export var scrollArea = "Betslip-module--scrollArea--5b732";
export var tab = "Betslip-module--tab--3c5af";
export var tabList = "Betslip-module--tabList--efce4";
export var tabListContainer = "Betslip-module--tabListContainer--3922e";
export var tabs = "Betslip-module--tabs--667a9";
export var viewport = "Betslip-module--viewport--60251";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";