// extracted by mini-css-extract-plugin
export var active = "Item-module--active--23af9";
export var blue = "#0eb3f2";
export var caret = "Item-module--caret--98186";
export var content = "Item-module--content--00149";
export var emphasis = "Item-module--emphasis--699c6";
export var green = "#57d33b";
export var greenLink = "Item-module--green-link--7fb70";
export var grey = "var(--tone-mid)";
export var icon = "Item-module--icon--0ae39";
export var item = "Item-module--item--a8373";
export var notchGap = "32px";
export var open = "Item-module--open--d62cf";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";