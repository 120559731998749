import { useEffect } from "react";

const useDisableNativeScrollRestoration = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      history.scrollRestoration = "manual";
    }

    return () => {
      history.scrollRestoration = "auto";
    };
  }, []);
};

export default useDisableNativeScrollRestoration;
