// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ShareEntryPreview-module--emphasis--25177";
export var green = "#57d33b";
export var greenLink = "ShareEntryPreview-module--green-link--aaec5";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var preview = "ShareEntryPreview-module--preview--1c552";
export var red = "#ff2741";
export var scrollArea = "ShareEntryPreview-module--scrollArea--b069b";
export var shadow = "ShareEntryPreview-module--shadow--e708a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";