const regionToCurrencyMapping = {
  au: "AUD",
  us: "USD",
  nz: "NZD",
  ca: "CAD",
} as const;
type RegionCurrency = keyof typeof regionToCurrencyMapping;

export function getCurrencyForRegion(regionCode: RegionCurrency) {
  return regionToCurrencyMapping[regionCode] || "USD";
}
