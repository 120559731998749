import React from "react";
import cx from "classnames";
import { InputError } from "components/InputError";
import { Typography } from "components/Typography";

import * as styles from "./NativeSelect.module.scss";
import { Skeleton } from "components/Skeleton";

type NativeSelectProps = {
  label?: string;
  containerClassName?: string;
  selectClassName?: string;
  errorMessage?: string;
  options: { value: string; label: string }[];
  hasError?: boolean;
  loading?: boolean;
  placeholder?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const NativeSelect = React.forwardRef<HTMLSelectElement, NativeSelectProps>(
  (
    {
      id,
      errorMessage,
      label,
      containerClassName,
      selectClassName,
      options,
      hasError,
      placeholder,
      loading = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cx(styles.container, containerClassName)}
        data-testid={`select-container`}
      >
        {label && (
          <Typography
            component="label"
            className={styles.label}
            skeleton={{ loading }}
            htmlFor={id}
            noSpacing
          >
            {label}
          </Typography>
        )}
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <div className={styles.wrapper}>
              <select
                id={id}
                className={cx(
                  styles.select,
                  {
                    [styles.hasError]: !!errorMessage || hasError,
                  },
                  selectClassName,
                )}
                ref={ref}
                {...props}
              >
                {placeholder && (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                )}
                {options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.value === ""}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {errorMessage && <InputError message={errorMessage} />}
          </>
        )}
      </div>
    );
  },
);

NativeSelect.displayName = "NativeSelect";

export { NativeSelect };
