import React from "react";
import cx from "classnames";
import { Alert as UnstyledAlert } from "components/Alert";
import type { AlertProps as UnstyledAlertProps } from "components/Alert";

import * as styles from "./Alert.module.scss";

// this component just wraps default alert
// and adds extra styles that are only relevant to betslip

export type AlertProps = {
  clamp?: React.ReactNode;
} & UnstyledAlertProps;

const Alert = ({ clamp, children, className, ...props }: AlertProps) => {
  return (
    <UnstyledAlert
      className={cx(
        styles.alert,
        {
          [styles.hasClamp]: !!clamp,
        },
        className,
      )}
      contentClassName={styles.content}
      {...props}
    >
      <span className={styles.text}>{children}</span>
      {clamp && <div className={styles.clamp}>{clamp}</div>}
    </UnstyledAlert>
  );
};

export { Alert };
