import {
  collection,
  type CollectionReference,
  type FirestoreError,
} from "firebase/firestore";
import { useCollectionData } from "hooks";
import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import type { IMarket } from "types/BetTypes";
import { transformMarkets } from "./helpers";

const usePickemsMarkets = (
  contestId: string,
): [IMarket[], boolean, FirestoreError] => {
  const contestMarketsRef = contestId
    ? (collection(
        getFirestore(),
        "pickemsContests",
        contestId,
        "markets",
      ) as CollectionReference<IMarket>)
    : undefined;
  const [markets, marketsLoading, marketsError] = useCollectionData(
    contestMarketsRef,
    contestMarketsRef?.path,
  );

  const transformedMarkets = useMemo(() => {
    if (!markets || marketsLoading) return [];

    return transformMarkets(markets);
  }, [markets]);

  return [transformedMarkets, marketsLoading, marketsError];
};

export default usePickemsMarkets;
