import React from "react";
import { Helmet } from "react-helmet";
import { usePageContext } from "hooks/usePageContext";
import appConfig from "appConfig";

export const COVER_IMAGE_URL =
  "https://images.ctfassets.net/3j5geeapvvry/6aS3QtBsutTwLnRhVZGLnG/26c2fa5221d29b5918242d95f087794c/cover.jpg";

type SEOProps = {
  description?: string;
  className?: string;
};

const SEO = ({ description }: SEOProps) => {
  const pageContext = usePageContext();
  let title = "Picklebet | Racing, Sports and Esports Betting";

  if (title?.indexOf("Picklebet") < 0) {
    title = "Picklebet: " + title;
  }

  if (!description) {
    description =
      "Australian owned, Picklebet offers racing betting, plus sports and esports markets. Experience the Picklebet app with Same Race Multi, Top 4 Betting & more.";
  }

  const organisationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Picklebet",
    alternateName: "Skrilla",
    url: "https://picklebet.com/",
    logo: "https://picklebet.com/images/logo-small.png",
    sameAs: [
      "https://www.facebook.com/picklebet",
      "https://twitter.com/picklebet",
      "https://www.instagram.com/picklebet/",
      "https://www.twitch.tv/picklebet",
      "https://www.youtube.com/channel/UCYuX3-CVRjNEeo0BzBSMZtg",
    ],
  };

  const androidMobileApplicationAppStructuredData = {
    "@context": "https://schema.org",
    "@type": "MobileApplication",
    name: "Picklebet",
    applicationCategory: "Online Betting",
    applicationSubCategory: "Entertainment",
    description:
      "Your home for esports betting on Android. Our Android App offers pick'ems & esports and sports betting all in one place. Get started today!",
    operatingSystem: "Android 9+",
    installUrl: "https://picklebet.page.link/app",
    creator: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Picklebet",
      url: "https://picklebet.com/",
      logo: "https://picklebet.com/images/logo-small.png",
    },
    sourceOrganization: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Picklebet",
      url: "https://picklebet.com/",
      logo: "https://picklebet.com/images/logo-small.png",
    },
  };

  const iOSMobileApplicationAppStructuredData = {
    "@context": "https://schema.org",
    "@type": "MobileApplication",
    name: "Picklebet",
    applicationCategory: "Online Betting",
    applicationSubCategory: "Entertainment",
    description:
      "Your home for esports betting on iOS. Our iOS App offers pick'ems & esports and sports betting all in one place. Get started today!",
    operatingSystem: "iOS 12+",
    installUrl: "https://picklebet.page.link/app",
    creator: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Picklebet",
      url: "https://picklebet.com/",
      logo: "https://picklebet.com/images/logo-small.png",
    },
    sourceOrganization: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Picklebet",
      url: "https://picklebet.com/",
      logo: "https://picklebet.com/images/logo-small.png",
    },
  };

  return (
    <Helmet>
      <html lang={`${pageContext?.locale}`} />

      <title>{title}</title>

      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:image"
        content={COVER_IMAGE_URL + "?w=300&h=300&fm=png&fit=thumb"}
      />

      <meta
        name="og:image"
        content={COVER_IMAGE_URL + "?w=1200&h=630&q=90&fm=jpg"}
      />

      <meta name="og:title" content={title} />

      {pageContext.canonical ? (
        <meta name="og:url" content={pageContext.canonical} />
      ) : null}

      {appConfig?.ENV === "production" ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta name="msapplication-navbutton-color" content="#10181C" />
      <meta name="msapplication-TileColor" content="#10181C" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#10181C" />
      <meta name="theme-color" content="#10181C" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600"
        rel="stylesheet"
      />

      {pageContext.canonical ? (
        <link rel="canonical" href={pageContext.canonical} />
      ) : null}

      <link rel="dns-prefetch" href="//static.zdassets.com" />
      <link rel="dns-prefetch" href="//cdn.ravenjs.com" />
      <link rel="dns-prefetch" href="//assets.zendesk.com" />
      <link rel="dns-prefetch" href="//ekr.zdassets.com" />
      <link rel="dns-prefetch" href="//res.cloudinary.com" />
      <link rel="dns-prefetch" href="//v2.zopim.com" />

      <script type="application/ld+json">
        {JSON.stringify(organisationStructuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(iOSMobileApplicationAppStructuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(androidMobileApplicationAppStructuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;
