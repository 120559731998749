import { useContext, useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, useDocumentData, usePermissions } from "hooks";
import { collection, query, where, doc } from "firebase/firestore";
import { createRacingEventsConverter } from "./useRace";
import type { RaceEventType } from "./RacingTypes";
import { FirebaseContext } from "context/Firebase";
import {
  type RaceMeetingType,
  createRaceMeetingsConverter,
} from "sections/Betting/Race/hooks/useRaceMeetings";

export const useRaceMeeting = (
  meetingId?: string,
): [RaceMeetingType, RaceEventType[], boolean] => {
  const db = getFirestore();
  const { campaigns: userCampaigns } = useContext(FirebaseContext);
  const permissions = usePermissions();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, permissions),
    [userCampaigns, permissions],
  );
  const raceEventsConverter = useMemo(
    () => createRacingEventsConverter(userCampaigns, permissions),
    [userCampaigns, permissions],
  );

  const raceMeetingsRef = meetingId
    ? doc(db, "raceMeetings", meetingId).withConverter(raceMeetingsConverter)
    : null;
  const racesEventsRef = meetingId
    ? collection(db, "racingEvents").withConverter(raceEventsConverter)
    : null;

  const [raceMeeting, raceMeetingLoading] = useDocumentData(raceMeetingsRef);
  const [races, racesLoading] = useCollectionData(
    meetingId
      ? query(racesEventsRef, where("meetingId", "==", meetingId))
      : null,
    racesEventsRef?.path,
  );

  return [
    raceMeeting,
    races?.sort((a, b) => a.number - b.number),
    raceMeetingLoading || racesLoading,
  ];
};
