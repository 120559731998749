import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

import { Pill } from "../Pill";

import * as styles from "./RowDrawer.module.scss";

type RowDrawerProps = {
  className?: string;
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  children: React.ReactNode;
};

export const RowDrawer = (props: RowDrawerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen || false);
  const [noOpen, setNoOpen] = useState<boolean>(false);
  const [isCollapsing, setIsCollapsing] = useState<boolean>(false);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen && !isCollapsing) {
      childrenRef.current.scrollTo(0, 0);
    }
  }, [isOpen, isCollapsing]);

  useEffect(() => {
    if (childrenRef.current) {
      setNoOpen(true);
      const delay = setTimeout(
        () => {
          const childrenHeight = (
            childrenRef.current.firstChild as HTMLDivElement
          ).offsetHeight;
          const thresholdHeight = childrenRef.current.offsetHeight;

          if (childrenHeight > thresholdHeight) {
            setNoOpen(false);
          } else {
            setNoOpen(true);
          }

          setIsCollapsing(false);
        },
        isCollapsing ? 350 : 0,
      ); // Delay just a bit longer than the transition

      return () => clearTimeout(delay);
    }
  }, [isOpen, props.children]);

  // fixes a bug where, when tourns are expanded, and then tourns are cleared. The it does not auto collapse
  useEffect(() => {
    if (
      childrenRef.current?.querySelector(".children")?.children?.length === 0
    ) {
      setIsOpen(false);
    }
  }, [props.children]);

  const setOpen = props.setOpen || ((open: boolean) => setIsOpen(open));

  const handleOpen = (open: boolean) => {
    setIsCollapsing(true);
    setOpen(open);
  };

  return (
    <div
      ref={childrenRef}
      className={cx(styles.rowDrawer, props.className, "drawer", {
        [styles.open]: isOpen,
        [styles.collapsing]: isCollapsing,
      })}
    >
      <div className="children">{props.children}</div>
      {(!noOpen || isOpen || isCollapsing) && (
        <div className={styles.pillContainer}>
          <Pill
            className={styles.expand}
            iconType={isOpen ? "arrow-up" : "arrow-down"}
            onClick={() => handleOpen(!isOpen)}
          />
        </div>
      )}
    </div>
  );
};

export default RowDrawer;
