import { ADD_TO_LIST, REMOVE_BY_KEY, REMOVE_BY_GROUP } from "./Constants";

const DEFAULT_TIMEOUT = 10;

if (typeof window !== "undefined" && !window.timeouts) {
  window.timeouts = {};
}

// --
// Create Stored Timeout
// Adds a new timeout to the window timeouts collection for a given key. Defends
// against the window object not existing (during SSR) and returns true if the
// timeout was actually created so we don't create multiple timeouts for a given
// key.

const createStoredTimeout = (group, key, timeout, dispatch) => {
  if (typeof window !== "undefined") {
    if (!window.timeouts[key]) {
      window.timeouts[key] = setTimeout(() => {
        delete window.timeouts[key];
        dispatch(requestTimedOut(key));
      }, timeout * 1000);

      return true;
    }
  }

  return false;
};

// --
// Clear Stored Timeout
// Clears and removes a timeout from the store attached to window, and returns
// true if that timeout was removed in this call.

const clearStoredTimeout = (key) => {
  if (typeof window !== "undefined") {
    if (window.timeouts.hasOwnProperty(key)) {
      clearTimeout(window.timeouts[key]);
      delete window.timeouts[key];

      return true;
    }
  }

  return false;
};

export const requestStarted =
  (group, key, timeout = DEFAULT_TIMEOUT) =>
  (dispatch) => {
    // add given key to a list of running queries
    dispatch(addToList(group, key));

    // Create a timeout function that will stop the request after given timeout
    if (createStoredTimeout(group, key, timeout, dispatch)) {
      // Only increment the loader if this was actually added this time around
    }
  };

export const requestFinished = (key) => (dispatch) => {
  if (clearStoredTimeout(key)) {
    dispatch(removeByKey(key));
  }
};

export const requestFinishedGroup = (group) => (dispatch, getState) => {
  const loading = getState().utilities.loading;

  // figure out what keys are in the given group and unset timeouts
  Object.keys(loading)
    .filter((key) => loading[key] === group)
    .forEach((key) => {
      if (clearStoredTimeout(key)) {
        dispatch(removeByKey(key));
      }
    });
};

export const requestTimedOut = (key) => (dispatch) => {
  console.warn(`Request timed out: ${key}`);

  // We remove the key anyway even though the timeout is dead to keep things
  // clean.
  dispatch(removeByKey(key));
};

export const removeByKey = (key) => ({
  type: REMOVE_BY_KEY,
  payload: { key },
});

export const removeByGroup = (group) => ({
  type: REMOVE_BY_GROUP,
  payload: { group },
});

export const addToList = (group, key) => ({
  type: ADD_TO_LIST,
  payload: {
    group,
    key,
  },
});
