// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var closeIcon = "Selection-module--closeIcon--13773";
export var emphasis = "Selection-module--emphasis--1f884";
export var green = "#57d33b";
export var greenLink = "Selection-module--green-link--e09f2";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcomeType = "Selection-module--outcomeType--53a75";
export var red = "#ff2741";
export var selection = "Selection-module--selection--374d9";
export var selectionDisabled = "Selection-module--selectionDisabled--27000";
export var selectionName = "Selection-module--selectionName--6361f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";