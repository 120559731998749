import { PRIMARY_MESSAGE_TYPES, WEB_TO_APP_MESSAGE_TYPES } from "./";

import { sendAppMessage } from "./Actions";
import { AUTH_LOGGED_OUT_SUCCESS } from "utilities/User/Constants";

import {
  requestStream,
  streamHadError,
  messageTimedOut,
  duplicateBetAttempted,
  appCrashed,
} from "../UI/uiSlice";
import {
  UPLOAD_AVATAR_ATTEMPT,
  UPDATE_PASSWORD_ATTEMPT,
  SUBMIT_VERIFICATION_ATTEMPT,
  CHANGE_NOTIFICATION_SETTINGS,
  SUCCESS_RESENT,
  UNSUBSCRIBE,
} from "sections/Account/accountSlice";
import {
  addCashCardAttempt,
  addPicklebetCardAttempt,
  removeCashCardAttempt,
  removePicklebetCardAttempt,
  depositFundsAttempt,
  depositFundsFailure,
  firstTimeDeposit,
  attemptRemoveBankAccount,
  withdrawCashAttempt,
  addedBankDetails,
  buySkrAttempt,
  attemptRegisterAddress,
  cancelWithdrawal,
} from "sections/Wallet/walletSlice";
import {
  BET_ADDED_TO_SLIP,
  BET_SUBMITTED,
  MATCH_PAGE_VISITED,
} from "sections/Betting/BettingConfig";
import { ENTRY_SUBMITTED, ENTRY_UPDATED } from "sections/Pickems/PickemsConfig";
import { actionTypeToString } from "../../store/tracking/helpers/typeToString";

import { isWebView } from "utilities/display";
import {
  trackLogin,
  trackSignUp,
  trackRequestToken,
  trackResetPassword,
  trackCompletedAddress,
  trackCompletedDetails,
  trackAttemptedVerification,
} from "utilities/Auth/authSlice";
import {
  sharedEntryTracking,
  viewedSharedEntry,
} from "sections/Betting/Betslip/betslipSlice";

const getPath = () => {
  return window && window.location && window.location.pathname;
};

const postAnalyticsToApp = (type: string, payload: any) => {
  // Wrap some extra data here that you want added to all analytics types.
  const finalPayload = {
    [actionTypeToString(type)]: {
      ...payload,
      path: getPath(),
      title: document.title,
    },
  };
  sendAppMessage(
    WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_ANALYTICS_EVENT,
    finalPayload,
  );
};

export const WebToAppEvents =
  ({ getState }) =>
  (next) =>
  (action: { type: string; payload: any; meta: any }) => {
    if (isWebView()) {
      const userId = getState()?.auth?.userId;
      switch (action.type) {
        case trackLogin.type:
        case trackSignUp.type:
        case trackRequestToken.type:
        case trackResetPassword.type:
        case trackCompletedAddress.type:
        case trackCompletedDetails.type:
        case BET_ADDED_TO_SLIP:
        case MATCH_PAGE_VISITED:
        case AUTH_LOGGED_OUT_SUCCESS:
        case UPLOAD_AVATAR_ATTEMPT:
        case UPDATE_PASSWORD_ATTEMPT:
        case SUBMIT_VERIFICATION_ATTEMPT:
        case CHANGE_NOTIFICATION_SETTINGS:
        case SUCCESS_RESENT:
        case BET_SUBMITTED:
        case UNSUBSCRIBE:
        case ENTRY_UPDATED:
        case ENTRY_SUBMITTED:
        case requestStream.type:
        case streamHadError.type:
        case duplicateBetAttempted.type:
        case messageTimedOut.type:
        case appCrashed.type:
        case addCashCardAttempt.type:
        case removeCashCardAttempt.type:
        case depositFundsAttempt.type:
        case depositFundsFailure.type:
        case firstTimeDeposit.type:
        case attemptRemoveBankAccount.type:
        case withdrawCashAttempt.type:
        case addPicklebetCardAttempt.type:
        case removePicklebetCardAttempt.type:
        case buySkrAttempt.type:
        case attemptRegisterAddress.type:
        case cancelWithdrawal.type:
        case addedBankDetails.type:
        case sharedEntryTracking.type:
        case viewedSharedEntry.type:
        case trackAttemptedVerification.type:
          if (typeof action.payload === "string") {
            postAnalyticsToApp(action.type, {
              result: action?.payload,
              userId,
            });
          } else {
            postAnalyticsToApp(action.type, {
              ...action?.payload,
              amount: action?.payload?.amount?.toString(),
              value: action?.payload?.amount?.toString(),
              userId,
            });
          }
          break;
        case PRIMARY_MESSAGE_TYPES.WEB_TO_APP_MESSAGE:
          // All the above gets formatted and packaged and sent to this middleware action.
          // The reason is messaging to the APP is shared for more than analytics.
          (window as any)?.ReactNativeWebView?.postMessage(action.payload);
          break;
        default:
          break;
      }
    }

    return next(action);
  };
