// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var chevronLeft = "BackButton-module--chevronLeft--ec7f1";
export var emphasis = "BackButton-module--emphasis--ff4bc";
export var green = "#57d33b";
export var greenLink = "BackButton-module--green-link--f1d05";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var visible = "BackButton-module--visible--b1987";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";