import React from "react";
import cx from "classnames";

import { ReactComponent as ReturnIcon } from "sections/Betting/Betslip/assets/return.svg";
import { ReactComponent as WinningBoostIcon } from "sections/Betting/Betslip/assets/winnings.svg";

import type { ActionType } from "hooks/firestore/user/useTokens";

import * as styles from "./PerkIcon.module.scss";

type PerkIconProps = {
  type: ActionType;
  className?: string;
};

const typeToIconMap = {
  WINNINGS_BOOST: <WinningBoostIcon />,
  STAKE_REFUND: <ReturnIcon />,
};

const typeToColorMap = {
  WINNINGS_BOOST: "orange",
  STAKE_REFUND: "green",
};

const PerkIcon = ({ type, className }: PerkIconProps) => {
  const icon = typeToIconMap[type];

  return (
    <span
      className={cx(
        styles.icon,
        {
          [styles.yellow]: typeToColorMap[type] === "yellow",
          [styles.green]: typeToColorMap[type] === "green",
          [styles.orange]: typeToColorMap[type] === "orange",
        },
        className,
      )}
    >
      {icon}
    </span>
  );
};

export { PerkIcon };
