import { useDispatch, useIsLoggedIn, useSelector } from "hooks";
import type {
  Competitor,
  EventMarket,
  Event as EventType,
} from "hooks/firestore/betting/useBetting";
import { toast } from "hooks/ui/useToast";
import { useDisplayAtMost } from "hooks/useDisplay";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  selectIsMultiOutcomeInBetslip,
  toggleSelection,
} from "sections/Betting/Betslip/betslipSlice";
import type {
  RaceCompetitorType,
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { useFetchSameRaceMultiOdds } from "sections/Betting/components/ComplexBetBuilder/hook/useFetchSameRaceMultiOdds";
import { useEventListener, useWindowSize } from "usehooks-ts";
import type { ComplexBetBuilderProps } from "../ComplexBetBuilder";
import { complexBetConfig } from "../config";

export const useComplexBuilderProps = ({
  selections,
  handleRemoveSelection,
  handleRemoveAllSelections,
  errorMessage,
  errorMessageToReplaceFooter,
  event,
  markets,
  competitors,
  rollBackSelectionChange,
  dependencies,
  setLatestValidSelections,
  latestValidSelections,
  type,
  ...props
}: ComplexBetBuilderProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const isAtMostTablet = useDisplayAtMost("tablet");
  const [isOpen, setIsOpen] = useState(false);
  const [slipHeight, setSlipHeight] = useState(0);
  const srmElement = useRef<HTMLDivElement>(null);
  const [isScrollMode, setIsScrollMode] = useState(true);
  const loading = useSelector((state) => state.sameEventMulti.loading);
  const { height } = useWindowSize();

  const calculateSlipProperties = () => {
    const top = srmElement.current?.getBoundingClientRect().top;
    const isVisible = top > height - 90;
    setIsScrollMode(isVisible);

    const slipHeight =
      (srmElement.current?.firstChild as HTMLElement)?.offsetHeight ?? 0;

    setSlipHeight(slipHeight);
  };

  useEventListener("scroll", calculateSlipProperties);

  const selectIsCurrentSelectionInBetslip = useSelector(
    selectIsMultiOutcomeInBetslip,
  );

  useEffect(() => {
    calculateSlipProperties();

    // Handle toast messages based on error messages and display mode.
    if (
      !isAtMostTablet &&
      errorMessage &&
      !isScrollMode &&
      !errorMessageToReplaceFooter
    ) {
      toast({ description: errorMessage });
    }

    // Remove unavailable selections
    selections.forEach((outcomeId: string) => {
      if (
        !isEmpty(markets) &&
        !markets.some(
          (market: { outcomes: { [x: string]: any } }) =>
            market?.outcomes[outcomeId],
        )
      ) {
        // if we are here it means the outcomeId for the selection didn't exist
        // on the market level data. This is expected in SEM's/exotics using this
        // complex builder as they generate their own id. So before removing and
        // notifying we explicity check that the selection exists in the betslip
        // first.
        if (selectIsCurrentSelectionInBetslip([outcomeId])) {
          toast({
            description: "A selection was removed as it is no longer available",
          });
          handleRemoveSelection(outcomeId);
        }
      }
    });
  }, [
    isOpen,
    selections,
    markets,
    height,
    errorMessage,
    errorMessageToReplaceFooter,
    isAtMostTablet,
    isScrollMode,
    dependencies,
  ]);

  const areAnySelectionsNotActive = useMemo(
    () =>
      selections.some((outcomeId) => {
        const market = markets?.find((market) =>
          Object.hasOwnProperty.call(market.outcomes, outcomeId),
        );
        return market?.status === "SUSPENDED";
      }),
    [selections, markets],
  );

  // Logic to handle event listeners and other effects

  useEffect(() => {
    if (areAnySelectionsNotActive) {
      toast({
        description: "One or more selections are no longer active",
      });
      setIsOpen(true);
    }
  }, [areAnySelectionsNotActive]);

  const odds = useFetchSameRaceMultiOdds({
    selections,
    markets,
    isLoggedIn,
    rollBackSelectionChange,
    setLatestValidSelections,
    removeSelection: handleRemoveSelection,
    // when the user has selected a SRM in the betslip, we should not fetch new odds
    suspendFetchingNewOdds:
      errorMessage === "Selected SRM in your Betslip" || type === "exotic",
  });

  const addToBetSlip = useCallback(() => {
    if (loading) return;

    const finalSelections = latestValidSelections ?? selections;
    // Assuming config and toggleSelection are available in this context
    const betslipEvent = complexBetConfig[type].eventMapper({
      event,
      competitors,
      markets,
    } as any);

    dispatch(
      toggleSelection(
        betslipEvent,
        complexBetConfig[type].pickMapper({
          markets: markets as RaceMarketsType[] & EventMarket[],
          odds,
          event: event as RaceEventType & EventType,
          selections: finalSelections,
          competitors: competitors as RaceCompetitorType[] & Competitor[],
        }),
        type === "srm" || type === "exotic" ? "racing" : "sports",
      ),
    );
  }, [selections, odds, loading, latestValidSelections]);

  const slipStyles = {
    width: isAtMostTablet ? "100%" : srmElement?.current?.offsetWidth ?? 0,
  };

  return {
    isOpen,
    setIsOpen,
    isScrollMode,
    srmElement,
    isAtMostTablet,
    isLoggedIn,
    odds: type === "exotic" ? 1 : odds,
    loading,
    handleRemoveSelection,
    handleRemoveAllSelections,
    addToBetSlip,
    selections,
    slipHeight,
    slipStyles,
    areAnySelectionsNotActive,
    ...props,
  };
};
