import React, { useState } from "react";

import { useDispatch } from "hooks";
import { Icon } from "library";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import { onDismissAllNotifications } from "sections/Account/accountSlice";
import { Button } from "components/Button";
import { List, ListItem } from "components/List";

import { ReactComponent as TickIcon } from "components/assets/tick.svg";
import { ReactComponent as SettingsIcon } from "components/assets/settings.svg";
import { ReactComponent as OpenIcon } from "components/assets/open.svg";

import { container, button } from "./ContextMenu.module.scss";

type ContextMenuProps = {
  hideOpen?: boolean;
};

export const ContextMenu = ({ hideOpen = false }: ContextMenuProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <PopoverTrigger asChild>
        <Button className={button} variant={`secondary`} size={`sm`}>
          <Icon type={`ellipsis`} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={-1}
        alignOffset={-15}
        align={"end"}
        className={container}
      >
        <List>
          <ListItem
            onClick={async () => {
              dispatch(onDismissAllNotifications());
              setIsOpen(false);
            }}
            icon={<TickIcon />}
            label={`Mark all as read`}
          />
          <ListItem
            to={`/preferences/notifications`}
            icon={<SettingsIcon />}
            label={`Notification Settings`}
          />
          {!hideOpen && (
            <ListItem
              to={`/account/notifications`}
              icon={<OpenIcon />}
              label={`Open Notifications`}
            />
          )}
        </List>
      </PopoverContent>
    </Popover>
  );
};
