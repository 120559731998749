// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "TabFilterItem-module--emphasis--53f41";
export var green = "#57d33b";
export var greenLink = "TabFilterItem-module--green-link--05566";
export var grey = "var(--tone-mid)";
export var indicator = "TabFilterItem-module--indicator--7ac27";
export var label = "TabFilterItem-module--label--74a6c";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var showCount = "TabFilterItem-module--showCount--b2bbb";
export var streamButton = "TabFilterItem-module--streamButton--13c1d";
export var tab = "TabFilterItem-module--tab--647ad";
export var tabActive = "TabFilterItem-module--tabActive--7e819";
export var tabDisabled = "TabFilterItem-module--tabDisabled--9f392";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";