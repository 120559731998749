// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Well-module--emphasis--f4c6d";
export var error = "Well-module--error--d4f9f";
export var green = "#57d33b";
export var greenLink = "Well-module--green-link--f2c6f";
export var grey = "var(--tone-mid)";
export var gutterBottom = "Well-module--gutterBottom--b04ad";
export var gutterLeft = "Well-module--gutterLeft--d94d0";
export var gutterRight = "Well-module--gutterRight--bc6fb";
export var gutterTop = "Well-module--gutterTop--5dca6";
export var info = "Well-module--info--47a77";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var warning = "Well-module--warning--748bf";
export var well = "Well-module--well--766a2";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";