// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var blurred = "CurrencyInput-module--blurred--2e906";
export var clear = "CurrencyInput-module--clear--8b8a1";
export var danger = "CurrencyInput-module--danger--3b005";
export var emphasis = "CurrencyInput-module--emphasis--6dc6b";
export var green = "#57d33b";
export var greenLink = "CurrencyInput-module--green-link--bdc59";
export var grey = "var(--tone-mid)";
export var info = "CurrencyInput-module--info--d2e2f";
export var notchGap = "32px";
export var options = "CurrencyInput-module--options--9dca9";
export var orange = "#f8662b";
export var primary = "CurrencyInput-module--primary--e7952";
export var quickInput = "CurrencyInput-module--quickInput--21312";
export var red = "#ff2741";
export var secondary = "CurrencyInput-module--secondary--d6086";
export var warning = "CurrencyInput-module--warning--9e6c7";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";