import {
  isPast,
  isFuture,
  parseISO,
  formatDistanceToNowStrict,
} from "date-fns";
import { constructCurrency } from "common/currency";
import type { DepositLimit } from "hooks/firestore/useAllDepositLimits";

const adjustLimitMessage = "Adjust your limits in your Preferences";

export const getMaximumDepositAmount = (limit: DepositLimit) => {
  if (limit) {
    const expiry = limit.expiresAt && parseISO(limit.expiresAt);
    return expiry && isFuture(expiry)
      ? limit.remainingDailyAmount
      : limit.maximumDailyDepositAmount;
  } else {
    return null;
  }
};

export const validateDepositAmount = (
  value: any,
  limit: DepositLimit,
  loading: boolean,
  currency: string,
  shortMessage?: boolean,
) => {
  if (limit && !loading) {
    const amount = parseFloat(value) * 100;
    const expiry = parseISO(limit.expiresAt ?? "");
    const maximumAmount = getMaximumDepositAmount(limit);
    const depositAmountAtExpiry =
      limit.depositAmountAtExpiry || limit.maximumDailyDepositAmount;

    if (expiry && isPast(expiry)) {
      return "Loading...";
    } else if (maximumAmount) {
      if (maximumAmount < limit.minimumDepositAmount) {
        return `You can deposit ${constructCurrency(depositAmountAtExpiry, {
          currencyType: currency,
        })} again in ${formatDistanceToNowStrict(expiry)}${
          shortMessage ? `` : `. ${adjustLimitMessage}`
        }`;
      } else if (maximumAmount < amount) {
        return `Deposit Amount must be ${constructCurrency(maximumAmount, {
          currencyType: currency,
        })}, or less`;
      }
    } else if (limit.minimumDepositAmount > amount) {
      return (
        "Deposit Amount needs to be " +
        constructCurrency(limit.minimumDepositAmount, {
          currencyType: currency,
        }) +
        ", or greater"
      );
    }
  }
  return true;
};
